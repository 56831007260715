import { Global } from "../service/Global";
import { Command } from "./Command";
import { Company } from "../service/Company";

export class BillCustomersCmd extends Command {

	/**
	 * Check companies to see which ones need to be billed and bill them.  Recursive since DynamoDb may not return all rows on first query.
	 */
	do(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			super.send( this.constructor.name, null, null )
			.then( results => {
				resolve();
			})
			.catch( err => reject( err ) );
		});
	}
}
