import { Command } from "./Command";
import { Site } from "../service/Site";

export class DeleteSiteCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: Site ): Promise<any> {
		return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
	}

}
