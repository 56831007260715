import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { ActivitySignUpComponent } from "../activity-signup/activity-signup.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Editor } from "../../service/Editor";
import { Activity } from '../../service/Activity';
import { ActivityType } from '../../service/ActivityType';
import { ActivitySignUp } from '../../service/ActivitySignUp';
import { ActivitySignUpList } from '../../service/ActivitySignUpList';
import { Property } from '../../service/Property';
import { PutActivitySignUpListCmd } from "../../command/PutActivitySignUpListCmd";
import { GetActivitySignUpListCmd } from "../../command/GetActivitySignUpListCmd";
import { GetSignUpListsForActiveSchedulesCmd } from "../../command/GetSignUpListsForActiveSchedulesCmd";
import { GetActiveSchedulesCmd } from "../../command/GetActiveSchedulesCmd";
import { GetAllActivityTypesCmd } from "../../command/GetAllActivityTypesCmd";

export class Attribute {
	constructor(
    	public name: string,
		public value: string
	) {}
}

@Component({
    selector: 'activity-signup-list',
    templateUrl: './activity-signup-list.html',
	styleUrls: ['./activity-signup-list.css'],
})
export class ActivitySignUpListComponent extends Editor<ActivitySignUp> implements OnInit, AfterViewInit {

	/** Currently selected property. */
	private propertyId;

	// Timezone for the property
	private timeZone;

	private activityTypes: ActivityType[] = [];
	
	/** Currently selected activity type. */
	private selectedActivityType: string;

	private activities: Activity[] = [];
	private signUpLists: ActivitySignUpList[] = [];
	private signUpList: ActivitySignUpList = new ActivitySignUpList();

	/** Date/time of the currently selected sign up list. */
	private activityDateTimeMillis: string;

	@ViewChild(ActivitySignUpComponent) private detailComponent: ActivitySignUpComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-ship fa-fw"></i> Activity Sign Up Lists' );
	}

	initialize() {
		// Set selected property to the first one in the list
		this.propertyId = this.cache.getFirstPropertyId();
		this.switchProperty( null );
	}

	private switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		try {
			this.timeZone = this.cache.getTimeZone( this.propertyId );
			this.detailComponent.timeZone = this.timeZone;
			if (this.propertyId == null) {
				this.selectedActivityType = null;
				this.switchActivityType( null );
			} else {
				new GetAllActivityTypesCmd().do( this.cache.currentCompany.companyId, this.propertyId )
				.then( activityTypes => {
					// console.log( 'Got activityTypes '+JSON.stringify(activityTypes,null,2));
					this.activityTypes = activityTypes;
					this.selectedActivityType = this.activityTypes.length > 0 ? this.activityTypes[0].activityTypeId.toString() : null;
					this.switchActivityType( null );
				})
				.catch( error => {
					Global.logError('Error loading data.', error );
					this.errorMessage = 'Sorry, we had a problem loading the activity types.  Please try again.';
				})
			}
		} catch( error ) { this.handleError( error, false ); }
	}

	private switchActivityType( event ) {
		try {
			if (this.selectedActivityType == null) {
				this.activityDateTimeMillis = null;
				this.switchSignUpList( null );
			} else {
				new GetActiveSchedulesCmd().do( this.cache.currentCompany.companyId, this.propertyId, Number.parseInt( this.selectedActivityType ) )
				.then( activeSchedules => {
					// console.log( 'Got activeSchedules '+JSON.stringify(activeSchedules,null,2));
					this.activities = activeSchedules.activities;
					new GetSignUpListsForActiveSchedulesCmd().do( this.cache.currentCompany.companyId, activeSchedules, 0, this.timeZone )
					.then( signUpLists => {
						// console.log( 'Got signUpLists '+JSON.stringify(signUpLists,null,2));
						this.signUpLists = signUpLists;
						this.activityDateTimeMillis = this.signUpLists.length > 0 ? this.signUpLists[0].activityDateTime.getTime().toString() : '0';
						this.switchSignUpList( null );
					})
					.catch( error => {
						Global.logError('Error loading data.', error );
						this.errorMessage = 'Sorry, we had a problem loading the property selector.  Please try again.';
					})
				})
				.catch( error => {
					Global.logError('Error loading data.', error );
					this.errorMessage = 'Sorry, we had a problem loading the property selector.  Please try again.';
				})
			}
		} catch( error ) { this.handleError( error, false ); }
	}

	switchSignUpList( event ) {
		try {
			// console.log('switchSignUpList');
			this.errorMessage = null;
			this.detectChanges();
			this.loadRows();
		} catch( error ) { this.handleError( error, false ); }
	}

	createNewRowToEdit(): ActivitySignUp {
		return new ActivitySignUp();
	}

	getDetailComponent(): ActivitySignUpComponent {
		return this.detailComponent;
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	getRowLoader(): Promise<ActivitySignUp[]> {
		return new Promise<ActivitySignUp[]>( (resolve,reject) => {
			try {
				if (!this.activityDateTimeMillis) {
					this.signUpList = new ActivitySignUpList();
					this.changeDetectorRef.detectChanges();
					// No time selected so no rows are loaded
					resolve( this.signUpList.guests );
				} else {
					let found = false;
					for (let i=0; i<this.signUpLists.length; i++) {
						if (this.signUpLists[i].activityDateTime.getTime().toString() === this.activityDateTimeMillis) {
							found = true;
							// Get latest version of signUpList to edit
							new GetActivitySignUpListCmd().do( this.signUpLists[i] )
							.then( signUpList => {
								if (signUpList) {
									this.signUpLists[i] = signUpList;
								}
								this.signUpList = this.signUpLists[i];
								this.changeDetectorRef.detectChanges();
								// Make a copy of the list of guest rows for the editor to manage
								// let guests: ActivitySignUp[] = [];
								// this.signUpList.guests.forEach( guest => guests.push( guest ) );
								resolve( this.signUpList.guests );
							})
							.catch( error => {
								Global.logError('Error loading data.', error );
								this.errorMessage = 'Sorry, we had a problem loading the data.  Please try again.';
								reject( error );
							})
							break;
						}
					}
					if (!found) {
						// No sign up list found for the selected time, create one
						this.signUpList = new ActivitySignUpList();
						this.changeDetectorRef.detectChanges();
						resolve( this.signUpList.guests );
					}
				}
			} catch( error ) {
				reject( error );
			}
		});
	}

	prepareRowToInsert( newRow: ActivitySignUp ) {
	}

	getRowInserter( row: ActivitySignUp ): Promise<ActivitySignUp> {
		return new Promise<ActivitySignUp>( (resolve,reject) => {
			// Remove the row the Editor class added from the list so command can add it
			this.signUpList.removeGuestInfo( row.roomNumber );
			new PutActivitySignUpListCmd().do( this.signUpList, null, row )
			.then( savedRow => {
				this.signUpList = savedRow;
				this.rows = this.signUpList.guests;
				resolve( row );
			})
			.catch( error => reject( error ) );
		})
	}

	getRowUpdater( index: number, row: ActivitySignUp ): Promise<ActivitySignUp> {
		return new Promise<ActivitySignUp>( (resolve,reject) => {
			// this.signUpList.removeGuestInfo( row.roomNumber );
			new PutActivitySignUpListCmd().do( this.signUpList, this.signUpList.guests[index], row )
			.then( savedRow => {
				this.signUpList = savedRow;
				this.rows = this.signUpList.guests;
				// Resolve null so editor does not try to re-update the row list at the given index
				// since the index might be wrong now due to other user's updates to the sign up list.
				resolve();
			})
			.catch( error => reject( error ) );
		})
	}

	getRowDeleter( index: number, row: ActivitySignUp ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			let oldRow = this.getDetailComponent().getOriginalData();
			// Put the row the Editor class deleted back in the list so command can delete it
			this.signUpList.guests.splice( index, 0, row );
			new PutActivitySignUpListCmd().do( this.signUpList, row, null )
			.then( savedRow => {
				this.signUpList = savedRow;
				this.rows = this.signUpList.guests;
				resolve();
			})
			.catch( error => reject( error ) );
		})
	}

	private getActivityName( activityId: number ) {
		let name = '';
		if (this.activities) {
			for (let i=0; i<this.activities.length; i++) {
				if (this.activities[i].activityId == activityId) {
					name = this.activities[i].name;
					break;
				}
			}
		}
		return name;
	}

	private getDateTimeString( date: Date ): string {
		return Global.getDateTime12String( date, this.timeZone );
	}

	private getSignUpListTime( list: ActivitySignUpList ) {
		if (!list || !list.activityDateTime)
			console.log( 'list='+JSON.stringify(list,null,2));
		return list ? list.activityDateTime.getTime().toString() : null;
	}

	private getSignUpListDateTimeString( date: Date ): string {
		let localDate = Global.adjustDateByTimeZone( date, this.timeZone );
		var options = { weekday: 'long', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
		return localDate.toLocaleString( undefined, options );
	}

}
