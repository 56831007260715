import 'tracking/build/tracking-min';
import 'tracking/build/data/face';
import { Global } from './Global';
import { RekognitionService } from './rekognition.service';
// Can't add cacheService, it creates a circular reference I think through the title-nav component but not sure.
// import { CacheService } from './cache.service';

/** TrackingJS face detection library. */
declare var tracking: any;

/**
 * Image that can be added to list of images used to test various face detection settings.
 */
export class TestImage {
	constructor(
		public bucketName: string,
		public imageName: string,
		public dataUrl: string,
		public selected: boolean
	){}
}

export class FaceDetector {

	/** List of image dataURL's saved for testing face detection. */
	private testImages: TestImage[] = [];
	
	/** Amount to scale image before detecting faces to improve performance. */
	private testImageScale = .4;

	/** Map where test results from multiple images are collected.  Keyed by image name. */
	private testResultsMap = new Map<string,any>();

	constructor( 
		// public cache: CacheService
	) {}

	detectFaces( canvas: HTMLCanvasElement, imageScale: number = .4, initialScale=1.5, stepSize=2.0, edgeDensity=0.3 ): Promise<any> {
		return new Promise<any>( (resolve,reject) => {
			let startTime = Date.now();
			var tracker = new tracking.ObjectTracker('face');
			// scale=4 step=2 took ~400ms
			// scale 2 step 2 ~350ms
			// scale 4 step 1.3 ~425ms
			tracker.setInitialScale( initialScale ); // Default is 1
			tracker.setStepSize( stepSize ); // Default is 1.5
			tracker.setEdgesDensity( edgeDensity ); // Default is 0.2
			// console.log( 'detectFaces( imageScale='+imageScale+', initialScale='+initialScale+', stepSize='+stepSize+', edgeDensity='+edgeDensity);

			let scaledCanvas = canvas;
			if (imageScale > 0 && imageScale < 1) {
				// Detect faces on a scaled-down canvas to make it faster
				scaledCanvas = document.createElement('canvas');
				scaledCanvas.width = Math.round( canvas.width * imageScale );
				scaledCanvas.height = Math.round( canvas.height * imageScale );
				let scaledCanvasContext = scaledCanvas.getContext('2d');
				scaledCanvasContext.drawImage( canvas, 0, 0, scaledCanvas.width, scaledCanvas.height );
			} else {
				// User did not provide a valid scale factor between 0 and 1 so use 1.
				imageScale = 1;
			}

			let context = canvas.getContext('2d');
			tracker.on('track', event => {
				let elapsedTime = Date.now()-startTime;
				// console.log( 'detectFaces in '+(Date.now()-createCanvasTime)+'ms., create canvas took '+(createCanvasTime-startTime)+'ms. found '+event.data.length+' faces.');
				// console.log('track event ' + JSON.stringify(event));
				let rectangles = [];
				event.data.forEach(scaledRect => {
					// console.log('Face of ' + Math.round(((rect.width * rect.height) / (canvas.width * canvas.height)) * 100) + '%, rect=' + JSON.stringify(rect) + ' took ' + (Date.now() - startTime) + 'ms.');
					let rect = { x: scaledRect.x/imageScale, y: scaledRect.y/imageScale, width: scaledRect.width/imageScale, height: scaledRect.height/imageScale, total: scaledRect.total };
					rectangles.push( rect );
					context.lineWidth = 1;
					context.strokeStyle = 'rgba(255, 0, 255, 1.0)';
					context.strokeRect( rect.x, rect.y, rect.width, rect.height );
				});
				// resolve( { elapsedTime: elapsedTime, rectangles: event.data } );
				resolve( { elapsedTime: elapsedTime, rectangles: rectangles } );
			});
			tracking.track( scaledCanvas, tracker );
		});
	}

	public addTestImage( testImage: TestImage ) {
		this.testImages.push( testImage );
	}

	public getTestImages(): TestImage[] {
		return this.testImages;
	}

	public clearTestImages() {
		this.testImages = [];
	}

	public testFaceDetection( testImageScale: number ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			this.testImageScale = testImageScale;
			var promises: Promise<void>[] = [];
			this.testImages.forEach( image => {
				console.log('testFaceDetection selected='+image.selected+' image '+image.imageName);
				if (image.selected) {
					promises.push( this.testFaceDetectionForImage( image.bucketName, image.imageName, image.dataUrl ) );
				}
			});
			Global.log( 'Testing face detection on '+promises.length+' images.' );
			Promise.all( promises )
			.then( values => {
				// Log test results
				// Global.log( 'Face Detection Test for ' + this.testImages.length + ' images on ' + new Date().toISOString() );
				// this.testResultsMap.forEach( (value,key,map) => {
					// results += '\n  ' + 'image ' + key + ' AWS percent ' + value.awsPercent;
					// results += '\n  ' + 'Face detected with the following settings:';
					// value.detectResults.forEach( (value,key,map) => {
						// results += '\n    ' + key + ' percent ' + value;
					// });
				// });
				// Global.log( results );
				resolve();
			})
			.catch( error => {
				Global.logError( 'Error indexing faces during face detection test.', error );
				reject( error );
			});
		});
	}
	
	private testFaceDetectionForImage( bucketName: string, imageName: string, dataUrl: string ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			let startTime = Date.now();
			let image = new Image();
			image.onload = () => {
				// Global.log('Image '+imageName+' loaded in '+(Date.now()-startTime)+'ms.');
				let canvas = document.createElement('canvas');
				canvas.width = image.width;
				canvas.height = image.height;
				let context2d = canvas.getContext('2d');
				context2d.drawImage( image, 0, 0, image.width, image.height );
				let awsPercent=0;
				new RekognitionService().detectFacesInS3ImageFile( bucketName, imageName )
				.then( data => {
					if (data.FaceDetails && data.FaceDetails.length > 0) {
						let box = data.FaceDetails[0].BoundingBox;
						awsPercent = Math.round( box.Height * 100);
					}
					return this.testFaceDetectionConfigurations( canvas );
				})
				.then( resultMap => {
					let resultString = 'TEST RESULTS for face detection on image '+imageName;
					if (resultMap.size == 0) {
						resultString += '\nAWS percent '+awsPercent+', NO faces detected by trackingjs';
					} else {
						resultString += '\nAWS%\tface%\tscale\tiScale\tstep\tedge\tdetectionTime';
						resultMap.forEach( (value,key,map) => {
							resultString += '\n'+awsPercent+'%\t\t'+value.totalPercent + '%\t\t' + key+'\t\t'+value.totalTime+'ms.';
						});
					}
					Global.log( resultString );
					this.testResultsMap.set( imageName, { 
						awsPercent: awsPercent,
						detectResults: resultMap
					});
				})
				.catch( error => Global.logError( 'Error indexing faces during face detection test.', error ) );
				resolve();
			};
			image.src = dataUrl;
		});
	}

	private testFaceDetectionConfigurations( canvas: HTMLCanvasElement ): Map<string,any> {
		// Global.log( 'face%\tscale\tiScale\tstep\tedge\tdetectionTime' );
		let testConfigurationsMap = new Map<string,any>();
		let scale = this.testImageScale;
		// for (let scale=.5; scale<=.5; scale+=.5) {
			for (let initialScale=1; initialScale<=2; initialScale+=.5) {
				Global.log( 'testing initialScale ' + initialScale );
				for (let stepSize=1; stepSize<=3; stepSize+=.5) {
					for (let edgeDensity=.1; edgeDensity<=.3; edgeDensity+=.1) {
						let startTime = Date.now();
						this.detectFaces( canvas, scale, initialScale, stepSize, edgeDensity )
						.then( data => {
							// let keyString = 'scale:'+scale+', initialScale:'+initialScale+', stepSize:'+stepSize+', edgeDensity:'+edgeDensity;
							let keyString = scale+'\t'+initialScale+'\t\t'+stepSize+'\t\t'+Math.round(edgeDensity*10)/10;
							let results = testConfigurationsMap.get( keyString );
							if (!results) {
								results = { count: 0, totalPercent: 0, totalTime: 0 };
							}
							if (data.rectangles && data.rectangles.length > 0) {
								let widthRatio = (data.rectangles[0].width) / canvas.width;
								let heightRatio = (data.rectangles[0].height) / canvas.height;
								let percent = Math.round( heightRatio * 100);
								// console.log( percent+'%\t\t' + keyString+'\t\t'+data.elapsedTime+'ms.' );
								// console.log( 'r.w='+data.rectangles[0].width+', c.w='+canvas.width+', wRatio='+widthRatio+' r.h='+data.rectangles[0].height+', c.h='+canvas.height+', hRatio='+heightRatio );
								results.count++;
								results.totalPercent += percent;
								results.totalTime += data.elapsedTime;
								testConfigurationsMap.set( keyString, results );
							}
							// else console.log( '-%\t\t' + keyString+'\t\t'+data.elapsedTime+'ms.' );
						})
					}
				}
			}
		// }
		return testConfigurationsMap;
	}
	
}