import { TableHandler, TableDocumentObject } from './TableHandler'
import { Visit } from './Visit';

/**
 * Saves a record each time a person visits.
 * If it is more than 2 hours between face detections, it is considered a new visit.
 */
export class VisitTable extends TableHandler<Visit> {

	/** Local secondary index use to query visits ordered by startTime. */
	private startTimeIndex = 'companyId_siteId-startTime-index';

    public constructor() {
		super( 'faceTrackerVisit' );
	}
	
	public fromDataItem( item: any ): Visit {
		return new Visit().fromDataItem( item );
	}

    /**
     * Get data for a date range for reporting.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param date Earliest date to keep, all older detections will be deleted.
     */
    getMostRecentVisit( visit: Visit ): Promise<Visit> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and begins_with( personId_startTime, :personId )";
			let expressionAttributeValues = {
				":companyId_siteId": visit.companyId + '_' + visit.siteId,
				":personId": visit.personId + '_',
			};
			this.query( keyConditionExpression, null, expressionAttributeValues, null, null, null, 1, false )
			.then( result => {
				// console.log( 'Found ' + (rows ? rows.length : 0) + ' visit rows.' );
				if (result.objects.length > 0) {
					resolve( result.objects[0] );
				} else {
					resolve( null );
				}
			})
			.catch( err => {
				reject( err );
			})
        });
    }

    /**
     * Get most recent visits that are less than 2 hours old up to the given max number of visits.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
	 * @param maxVisitsToGet Max number of visits to return.
     */
    getMostRecentVisits( companyId: number, siteId: number, maxVisitsToGet: number ): Promise<Visit[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and startTime > :startTime";
			let expressionAttributeValues = {
				':companyId_siteId': companyId + '_' + siteId,
				':startTime': new Date( Date.now() - (2 * 60 * 60 * 1000) ).toISOString()
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.startTimeIndex, maxVisitsToGet, false )
			.then( visits => {
				// console.log( 'VisitTable.getMostRecentVisits returned ' + visits.length + ' visits.' );
				resolve( visits );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

    /**
     * Get visit records that started after the given date.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param date Look for visits that started after this date.
	 * @param maxVisitsToGet Max number of visits to return.
     */
    getLaterVisits( companyId: number, siteId: number, date: Date, maxVisitsToGet: number ): Promise<Visit[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and startTime > :date";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
				":date": date.toISOString(),
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.startTimeIndex, maxVisitsToGet )
			.then( visits => {
				resolve( visits );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

    /**
     * Get visit records that started before the given date.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param date Look for visits that started after this date.
	 * @param maxVisitsToGet Max number of visits to return.
     */
    getEarlierVisits( companyId: number, siteId: number, date: Date, maxVisitsToGet: number ): Promise<Visit[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and startTime < :date";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
				":date": date.toISOString(),
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.startTimeIndex, maxVisitsToGet, false )
			.then( visits => {
				resolve( visits );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

    /**
     * Delete old visit records that are no longer needed since data has been summarized.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param date Earliest date to keep, all older detections will be deleted.
     */
    purgeVisits( companyId: number, siteId: number, oldestDateToKeep: Date, exclusiveStartKey = null ): Promise<number> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and startTime < :oldestDateToKeep";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
				":oldestDateToKeep": oldestDateToKeep.toISOString(),
			};
			this.deleteAll( keyConditionExpression, expressionAttributeValues, null, this.startTimeIndex )
			.then( deleteCount => {
				console.log( 'VisitTable.purgeVisits purged ' + deleteCount + ' visits.' );
				resolve( deleteCount );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	// convertToV2() {
	// 	let newTableName = this.getTableName()+'_V2';
	// 	let params: DynamoDB.CreateTableInput = {
	// 		TableName: newTableName,
	// 		AttributeDefinitions: [
	// 			{ AttributeName: "companyId_siteId", AttributeType: "S" }, 
	// 			{ AttributeName: "personId_startTime", AttributeType: "S" },
	// 			{ AttributeName: "startTime_personId", AttributeType: "S" }
	// 		], 
	// 		KeySchema: [
	// 			{ AttributeName: "companyId_siteId", KeyType: "HASH" }, 
	// 			{ AttributeName: "personId_startTime", KeyType: "RANGE" }
	// 		], 
	// 		ProvisionedThroughput: { ReadCapacityUnits: 1, WriteCapacityUnits: 1 }, 
	// 		LocalSecondaryIndexes: [
	// 			{
	// 				IndexName: 'companyId_siteId-startTime_personTime-index',
	// 				KeySchema: [
	// 					{ AttributeName: "companyId_siteId", KeyType: "HASH" }, 
	// 					{ AttributeName: "startTime_personId", KeyType: "RANGE" }
	// 				], 
	// 				Projection: { ProjectionType: 'ALL' }
	// 			},
	// 		],
	// 	};

	// 	this.createTable( params )
	// 	.then( () => {
	// 		console.log( 'successfully created table '+newTableName);
	// 	})
	// 	.catch( err => {
	// 		console.log( 'failed to create table '+newTableName+'.  '+ err.message );
	// 	});
	// }

}
