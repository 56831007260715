import { Command } from "./Command";
import { Activity } from "../service/Activity";

export class GetAllActivitiesCmd extends Command {

	/**
	 * Get list of activity types for the given property.
	 * @param companyId ID of the company.
	 * @param propertyId ID of the property.
	 */
	public do( companyId: number, propertyId: number ): Promise<Activity[]> {
		return new Promise<Activity[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: Activity[] = [];
				results.data.forEach( item => rows.push( new Activity().fromDataItem( item ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
