import { TableHandler, TableDocumentObject } from './TableHandler'

/**
 * Tracks URL's of SQS queues used to notify dashboards when a new visit has started.
 */
export class SiteUsage extends TableDocumentObject {

	/**
	 * 
	 * @param companyId ID of company (partition key = companyId_siteId)
	 * @param siteId ID of site (partition key = companyId_siteId)
	 * @param usageDate Date this usage data is for.
	 * @param visitImages Total number of images uploaded to the guest photo bucket
	 * @param newVisitImages Number of images that resulted in recording a new visit.
	 * @param dupVisitImages Number of images that were found to be another picture of a guest on the same visit.
	 * @param noFaceVisitImages Number of images that were uploaded but no face was detected.
	 */
	constructor(
		public companyId: number = null,
		public siteId: number = 0,
		public usageDate: Date = new Date(),
		public visitImages: number = null,
		public newVisitImages: number = null,
		public dupVisitImages: number = null,
		public noFaceVisitImages: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): SiteUsage {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_siteId'] = this.companyId + '_' + this.siteId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "usageDate": this.usageDate.toISOString(),
        };
    }

}

/**
 * Contains records for queues created when a user opens a dashboard that needs to be updated when 
 * a new visit starts so we can update the dashboard in real time when a visit starts.
 */
export class SiteUsageTable extends TableHandler<SiteUsage> {

    public constructor() {
		super( 'bookcliffsoftware-SiteUsage' );
	}
	
	public fromDataItem( item: any ): SiteUsage {
		return new SiteUsage().fromDataItem( item );
	}

	updateUsage( siteUsage: SiteUsage ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			// Make sure time values of usageDate are all 0
			siteUsage.usageDate.setUTCHours( 0, 0, 0, 0 );
			this.get( siteUsage )
			.then( usage => {
				if (usage) {
					// We already have a usage record so add the existing values to the given record
					siteUsage.visitImages += usage.visitImages;
					siteUsage.newVisitImages += usage.newVisitImages;
					siteUsage.dupVisitImages += usage.dupVisitImages;
					siteUsage.noFaceVisitImages += usage.noFaceVisitImages;
				}

				this.put( siteUsage )
				.then( () => resolve() )
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		})
	}
}
