import { TableHandler, TableDocumentObject } from './TableHandler';

/**
 * Possible time punch types.  Also uses for shift status. CLOCK_OUT (0) means the shift is
 * complete, any other status means the shift is open.
 */
export class PunchTypes {
	public static CLOCK_OUT = 0;
	public static CLOCK_IN = 1;
	public static EOD_OUT = 2;
	public static EOD_IN = 3;
	public static PAID_BREAK_OUT = 4;
	public static PAID_BREAK_IN = 5;
	public static UNPAID_BREAK_OUT = 6;
	public static UNPAID_BREAK_IN = 7;
	
	private static names = [
		'clockOut',
		'clockIn',
		'eodClockOut',
		'eodClockIn',
		'paidBreakOut',
		'paidBreakIn',
		'unpaidBreakOut',
		'unpaidBreakIn',
	]

	static getName( value: number ) {
		if (value < 0 || value >= this.names.length) {
			throw new Error('Invalid punch type value, must be between 0 and '+this.names.length );
		}
		return this.names[value];
	}
}

/**
 * A single time punch event such as a clock in or clock out event.
 */
export class TimePunch extends TableDocumentObject {

	constructor(
		public punchTime: Date = null, // Date and time the employee clocked in or out
		public punchType: number = null, // One of the PunchType constants
    ) { super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): TimePunch {
		this.copyPropertiesFromObject( item );
		return this;
	}


    /**
     * Convert object into data item that can be stored in DynamoDb as the Item property.
     */
    toDataItem(): any {
        return {
            "punchTime": this.punchTime.toISOString(),
            "punchType": this.punchType, 
        };
    }

	getKey() {
		return null;
	}

}

/**
 * A shift for which the employee has clocked in. Clocking out and back in from breaks and the
 * final clock out are all included in the list of punches.  The last type of event, such as
 * 'paidBreakIn' or 'clockOut' is the shiftStatus.
 */
export class WorkedShift extends TableDocumentObject {
	constructor(
		public companyId?: number, // ID of company (partition key = companyId_siteId)
		public siteId?: number, // ID of site (partition key = companyId_siteId)
		public employeeId?: string, // Unique employeeId address of employee (sort key = employeeId_shiftStartTime)
		public shiftStartTime?: Date, // Date and time the employee clocked in or out
		public shiftStatus?: number, // Type of the last time punch of the shift (local secondary index = employeeId_shiftStatus)
		public punches?: TimePunch[], // Time punches recorded as part of this shift
    ) { super(); }

	/**
	 * Adds a time punch to this worked shift sorted by time.
	 * If it is the latest time punch then also set the shift status to the punch type.
	 * 
	 * @param timePunch Time punch to add.
	 */
	addTimePunch( timePunch: TimePunch ): WorkedShift {
		// Find index to insert punch time in order to keep them sorted by date/time
		let index = 0;
		for(; index < this.punches.length; index++) {
			if (timePunch.punchTime < this.punches[index].punchTime) {
				break;
			}
		}

		// If this is the list punch for the shift, set the shift status to the punch type
		if (index == this.punches.length) {
			this.shiftStatus = timePunch.punchType;
		}

		// Insert the time punch into the list of time punches for this shift
		this.punches.splice( index, 0, timePunch );

		return this;
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): WorkedShift {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.punches = null;
		if (item['punches']) {
			this.punches = [];
			item['punches'].forEach( childItem => this.punches.push( new TimePunch().fromDataItem( childItem ) ) );
		}
		return this;
	}

	toDataItem(): any {
		let punchItems = [];
		this.punches.forEach( timePunch => {
			punchItems.push( timePunch.toDataItem() );
		});
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId,
            "employeeId_shiftStartTime": this.employeeId + '_' + this.shiftStartTime.toISOString(),
            "employeeId_shiftStatus": this.employeeId + '_' + this.shiftStatus,
            "companyId": this.companyId,
            "siteId": this.siteId, 
            "employeeId": this.employeeId, 
            "shiftStartTime": this.shiftStartTime.toISOString(), 
            "shiftStatus": this.shiftStatus, 
            "punches": punchItems
        };
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "employeeId_shiftStartTime": this.employeeId + '_' + this.shiftStartTime.toISOString()
        }
    }

}

export class WorkedShiftTable extends TableHandler<WorkedShift> {

    /** Local secondary index using shiftStatus as the sort key so we can find shifts where employees haven't clocked out. */
    private secondaryIndexName: string = "companyId_siteId-employeeId_shiftStatus-index";

    public constructor() {
		super( 'bookcliffsoftware-WorkedShift' );
	}
	
	public fromDataItem( item: any ): WorkedShift {
		return new WorkedShift().fromDataItem( item );
	}

    /**
     * Get the shifts for which the employee has clocked in but not clocked out.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param employeeId employeeId address used to identify the employee.
     */
    getOpenShifts( companyId: number, siteId: number, employeeId: string ): Promise<WorkedShift[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and begins_with( employeeId_shiftStatus, :employeeId )";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
				":employeeId": employeeId
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.secondaryIndexName )
			.then( shifts => {
				let openShifts: WorkedShift[] = [];
				shifts.forEach( shift => {
					if (shift.shiftStatus != PunchTypes.CLOCK_OUT) {
						openShifts.push( shift );
					}
				});
				// console.log( this.tableName + '.getOpenShifts returns ' + JSON.stringify( shifts, null, 2 ) );
				resolve( openShifts );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

    /**
     * Get the shifts for which the employee has clocked in but not clocked out.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param employeeId employeeId address used to identify the employee.
     */
    getMostRecentOpenShift( companyId: number, siteId: number, employeeId: string ): Promise<WorkedShift> {
    	return new Promise( (resolve, reject) => {
			this.getOpenShifts( companyId, siteId, employeeId )
			.then( shifts => {
				let foundShift: WorkedShift = null;
				if (shifts) {
					shifts.forEach( shift => {
						if (foundShift == null || shift.shiftStartTime.getTime() > foundShift.shiftStartTime.getTime()) {
							foundShift = shift;
						}
					})
				}
				resolve( foundShift );
			}).catch( err => {
				reject( err );
			})
		});
    }

}
