import { TableDocumentObject } from './TableHandler'
import { Global } from './Global'

/** Monthly record of how often each ad was played. */
export class AdPlayByMonth extends TableDocumentObject {

	/**
	 * @param companyId ID of the advertising company (partition key)
	 * @param propertyId ID of the advertising property (partition key)
	 * @param siteId ID of advertising site (sort key)
	 * @param adYear Year ad was played
	 * @param adMonth Month as was played
	 * @param adCategoryId ID of category of ad that played
	 * @param atCompanyId Company that played the ad
	 * @param atPropertyId Property where ad was played
	 * @param playCounts List of ad categories and the number of times the ad for that category was played.
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public siteId: number = null,
		public adYear: number = null,
		public adMonth: number = null,
		public adCategoryId: number = null,
		public adCategoryName: string = null,
		public atCompanyId: number = null,
		public atCompanyName: string = null,
		public atPropertyId: number = null,
		public atPropertyName: string = null,
		public playCount: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): AdPlayByMonth {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId_siteId'] = this.companyId + '_' + this.propertyId + '_' + this.siteId;
		item['adYear_adMonth_atCompanyId_atPropertyId'] =  this.adYear + '_' + Global.getDigitString( this.adMonth, 2 ) + '_' + this.atCompanyId + '_' + this.atPropertyId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId_siteId": this.companyId + '_' + this.propertyId + '_' + this.siteId,
			"adYear_adMonth_atCompanyId_atPropertyId": this.adYear + '_' + Global.getDigitString( this.adMonth, 2 ) + '_' + this.atCompanyId + '_' + this.atPropertyId,
		};
    }

}

