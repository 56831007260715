import { TableHandler } from './TableHandler'
import { Global } from './Global';
import { Activity } from "./Activity";
import { ActivitySchedule } from "./ActivitySchedule";
import { ActivitySchedules } from "./ActivitySchedules";
import { ActivitySignUp } from "./ActivitySignUp";
import { ActivitySignUpList } from "./ActivitySignUpList";
import { RoomUsageTable } from './RoomUsageTable';

/** Activities for which guests may sign up. */
export class ActivitySignUpListTable extends TableHandler<ActivitySignUpList> {

	/** Local secondary index use to query activity signup sheets by date/time. */
	private activityDateTimeIndex = 'companyId_propertyId-activityDateTime-index';
	
	public constructor() {
		super( 'bookcliffsoftware-ActivitySignUpList' );
	}
	
	public fromDataItem( item: any ): ActivitySignUpList {
		return new ActivitySignUpList().fromDataItem( item );
	}

	public getDateRange( companyId: number, propertyId: number, startTime: Date, endTime: Date ): Promise<ActivitySignUpList[]> {
    	return new Promise<ActivitySignUpList[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and activityDateTime BETWEEN :startTime AND :endTime";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
				":startTime": startTime.toISOString(),
				":endTime": endTime.toISOString(),
			};
			// console.log('Get HK logs: '+JSON.stringify(expressionAttributeValues,null,2));
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.activityDateTimeIndex )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Returns a list of distinct room numbers that accessed the system between the given start and end date.
	 * @param companyId ID of the company
	 */
	public getActiveRoomNumbers( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<Set<string>> {
		return new Promise<Set<string>>( (resolve, reject) => {
			let roomNumbers: Set<string> = new Set();
			this.getDateRange( companyId, propertyId, startDate, endDate )
			.then( lists => {
				lists.forEach( list => {
					list.guests.forEach( guest => roomNumbers.add( guest.roomNumber ) );
				});
				resolve( roomNumbers );
			})
			.catch( error => reject( error ) );
		});
	}
	
	// public getActivitySignUpsForCompany( companyId: number ): Promise<ActivitySignUpList[]> {
    // 	return new Promise<ActivitySignUpList[]>( (resolve, reject) => {
	// 		let filterExpression = "companyId = :companyId";
	// 		let expressionAttributeValues = { ":companyId": companyId };
	// 		this.scanAll( filterExpression, expressionAttributeValues )
	// 		.then( rows => resolve( rows ) )
	// 		.catch( error => reject( error ) );
	// 	});
	// }

	// public getActivitySignUpsForDateRange( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<ActivitySignUpList[]> {
    // 	return new Promise<ActivitySignUpList[]>( (resolve, reject) => {
	// 		let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and ActivitySignUpDate BETWEEN :startDate AND :endDate";
	// 		let expressionAttributeValues = {
	// 			":companyId_propertyId": companyId + '_' + propertyId,
	// 			":startDate": startDate.toISOString(),
	// 			":endDate": endDate.toISOString(),
	// 		};
	// 		this.queryAll( keyConditionExpression, expressionAttributeValues, null, this.ActivitySignUpDateIndex )
	// 		.then( rows => resolve( rows ) )
	// 		.catch( error => reject( error ) );
	// 	});
	// }

	/**
	 * Returns a list of sign up lists with available capacity for the given list of activity schedules.
	 * @param activeSchedules List of active activity schedules.
	 * @param persons Available capacity that a sign up list must have in order to be returned, 0 returns all lists.
	 * @param timeZone Time zone of the property.
	 */
	public getSignUpListsForActiveSchedules( activeSchedules: ActivitySchedules, persons: number, timeZone: string ): Promise<ActivitySignUpList[]> {
		return new Promise<ActivitySignUpList[]>( (resolve,reject) => {
			// console.log( "Getting sign up lists for activeSchedules "+JSON.stringify( activeSchedules, null, 2 ) );
			let filteredActiveSchedules = new ActivitySchedules();
			let promises: Promise<ActivitySignUpList>[] = [];
			let signUpLists: ActivitySignUpList[] = [];
			for (let i=0; i<activeSchedules.activities.length; i++) {
				let activity = activeSchedules.activities[i];
				let scheduleIndex = activeSchedules.scheduleIndexes[i];
				let schedule = activity.schedules[ scheduleIndex ];
				// if (schedule.frequency === 'once') {
				// 	promises.push( this.getSignUpListForDayOfWeek( schedule.startDate.getUTCDay(), activity, schedule ) )
				// } else {
					for (let dayNumber=0; dayNumber<7; dayNumber++) {
						promises.push( this.getSignUpListForDayOfWeek( dayNumber, activity, schedule, timeZone ) )
					}					
				// }
			}
			Promise.all( promises )
			.then( resultArray => {
				resultArray.forEach( signUpList => {
					if (signUpList && (persons == 0 || signUpList.capacity >= (signUpList.getGuestCount() + persons))) {
						let dateTime = signUpList.activityDateTime.getTime();
						// Insert signUpList in result array in date/time order
						for (let j=0; j<signUpLists.length; j++) {
							if (dateTime < signUpLists[j].activityDateTime.getTime()) {
								// Insert the new sign up list before the one that comes later
								signUpLists.splice( j, 0, signUpList );
								signUpList = null;
								break;
							}
						}
						if (signUpList) {
							// We didn't find any later lists so add the new one at the end
							signUpLists.push( signUpList );
						}
					}
				})
				resolve( signUpLists );
			})
			.catch( error => reject( error ) );
		})
	}

	/**
	 * Returns the number of people that can be added to the sign up list for the given activity schedule and day of week.
	 * @param dayOfWeek Day of weeek, only used for 'weekly' schedules, one-time schedules check the actual start date.
	 * @param activity Activity whose capacity is being checked.
	 * @param schedule Schedule whose capacity is being checked.
	 * @param timeZone Time zone of the property.
	 */
	public getSignUpListForDayOfWeek( dayOfWeek: number, activity: Activity, schedule: ActivitySchedule, timeZone: string ): Promise<ActivitySignUpList> {
		return new Promise<ActivitySignUpList>( (resolve,reject) => {
			let sevenDaysFromNow = Date.now() + (7 * 24 * 60 * 60 * 1000);
			if (schedule.capacity == 0) {
				// There is no capacity for the given activity schedule
				// console.log( 'NO sign up list, no capacity for dayOfWeek '+dayOfWeek+' activity '+activity.activityId+' schedule '+schedule.startDate.toISOString() );
				resolve( null );
			// } else if (schedule.frequency === 'once' && (schedule.startDate.getTime() < Date.now() || schedule.startDate.getTime() > sevenDaysFromNow) ) {
			// 	// This weekly activity is not scheduled on the given day of the week
			// 	// console.log( 'NO sign up list, not scheduled during next week, activity '+activity.activityId+' schedule '+schedule.startDate.toISOString() );
			// 	resolve( null );
			// } else if (schedule.frequency === 'weekly' && !schedule.getDayOfWeekFlag( dayOfWeek )) {
			} else if (!schedule.getDayOfWeekFlag( dayOfWeek )) {
				// This activity is not scheduled on the given day of the week
				// console.log( 'NO sign up list, not scheduled on dayOfWeek '+dayOfWeek+' activity '+activity.activityId+' schedule '+schedule.startDate.toISOString() );
				resolve( null );
			} else {
				// Figure out date from day of week
				let date = schedule.startDate;
				// Find the first future date with the given day of week and scheduled start time.
				date = new Date();
				date.setUTCHours( schedule.startDate.getUTCHours() );
				date.setUTCMinutes( schedule.startDate.getUTCMinutes() );
				date.setUTCSeconds( schedule.startDate.getUTCSeconds() );
				date.setUTCMilliseconds( schedule.startDate.getUTCMilliseconds() );
				while (date.getTime() < Date.now() || date.getUTCDay() != dayOfWeek) {
					// Increment date/time by 1 day until we get to the right day of the week in the future
					date = new Date( date.getTime() + Global.millisInOneDay );
				}

				if (!schedule.isOpenOnDate( date, timeZone )) {
					// This activity is not scheduled on the given date because it is on the list of closed dates.
					resolve( null );
				} else {
					let key = new ActivitySignUpList( activity.companyId, activity.propertyId, activity.activityId, date );
					new ActivitySignUpListTable().get( key )
					.then( signUpList => {
						if (!signUpList) {
							signUpList = key;
						}
						signUpList.capacity = schedule.capacity;
						// console.log( 'Got sign up list '+JSON.stringify(signUpList,null,2)+' for dayOfWeek '+dayOfWeek+' activity '+activity.activityId+' schedule '+schedule.startDate.toISOString() );
						resolve( signUpList );
					})
					.catch( error => reject( error ) );
				}
			}
		})
	}

	/** 
	 * Returns a string of day names for the given list of sign up lists which can be passed to a speech synthesizer.
	 * @param signUpLists Array of sign up lists.
	 */
	public getDayNames( signUpLists: ActivitySignUpList[] ): string {
		let dayNumbers: number[] = [];
		signUpLists.forEach( signUpList => {
			let dayNumber = signUpList.activityDateTime.getUTCDay();
			if (dayNumbers.indexOf( dayNumber ) == -1) {
				dayNumbers.push( dayNumber );
			}
		})

		let dayNames = '';
		let dayCount = dayNumbers.length;
		let daysAdded = 0;
		dayNumbers.forEach( dayNumber => {
			if (dayNames.length > 0) {
				if (daysAdded > 0 && daysAdded == (dayCount - 1)) {
					dayNames += ', and ';
				} else {
					dayNames += ', ';
				}
			}
			dayNames += Global.daysNames[dayNumber];
			daysAdded++;
		})
		return dayNames;
	}

	/** 
	 * Returns a string of times for the given list of sign up lists which can be passed to a speech synthesizer.
	 * @param signUpLists Array of sign up lists.
	 */
	public getTimeList( signUpLists: ActivitySignUpList[], timeZone: string ): string {
		let timeStrings = '';
		let count = signUpLists.length;
		let added = 0;
		signUpLists.forEach( signUpList => {
			if (timeStrings.length > 0) {
				if (added > 0 && added == (count - 1)) {
					timeStrings += ', and ';
				} else {
					timeStrings += ', ';
				}
			}
			let timeString = Global.getHourMinuteString( signUpList.activityDateTime, timeZone );
			timeStrings += Global.get12HourTimeSpeech( timeString );
			added++;
		})
		return timeStrings;
	}

	/**
	 * Returns the sign up lists where the given date matches the activity date or the same day next
	 * week to handle the case of signing up for snorkelling on Friday but today is Friday and 
	 * snorkelling has already occurred so you mean next Friday even though Alexa interprets 'Friday'
	 * as today.
	 * @param date Date to check.
	 */
	public getSignUpListsForDate( date: Date, persons: number, signUpLists: ActivitySignUpList[] ): ActivitySignUpList[] {
		let listsForDate: ActivitySignUpList[] = [];
		let requestedDate = Global.getUTCDateString( date );
		let sameDayNextWeek = Global.getUTCDateString( new Date( date.getTime() + Global.millisInOneWeek ) );
		for (let i=0; i<signUpLists.length; i++) {
			let list = signUpLists[i];
			let availableCapacity = list.getAvailableCapacity();
			let activityDate = Global.getUTCDateString( list.activityDateTime );
			if (availableCapacity > persons && (activityDate === requestedDate || activityDate === sameDayNextWeek)) {
				listsForDate.push( list );
			}
		}
		return listsForDate;
	}

	/**
	 * Returns the sign up lists where the given time matches the activity time (the date is ignored).
	 * @param time Time string with hours and minutes in 24-hour format (ie. 13:45) in the given time zone.
	 */
	public getSignUpListsForTime( timeString: string, timeZone: string, numberOfPeople: number, signUpLists: ActivitySignUpList[] ): ActivitySignUpList[] {
		let lists: ActivitySignUpList[] = [];
		for (let i=0; i<signUpLists.length; i++) {
			let capacity = signUpLists[i].capacity - signUpLists[i].getGuestCount();
			if (timeString === Global.getHourMinuteString( signUpLists[i].activityDateTime, timeZone ) && capacity >= numberOfPeople) {
				lists.push( signUpLists[i] );
			}
		}
		return lists;
	}

	public addGuestsToListVoice( companyId: number, propertyId: number, activityId: number, activityDateTime: Date, roomNumber: string, activityType: string , numberOfPeople: number, timeZone: string ): Promise<number> {
		return new Promise<number>( (resolve,reject) => {
			this.get( new ActivitySignUpList( companyId, propertyId, activityId, activityDateTime, numberOfPeople ))
			.then( signUpList => {
				this.addGuestsToList( signUpList, new ActivitySignUp( roomNumber, null, numberOfPeople, new Date() ) )
				.then( availableOpenings => resolve( availableOpenings ) )
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
		// // Check capacity
		// let availableOpenings = signUpList.capacity - signUpList.getGuestCount();
		// if (numberOfPeople > availableOpenings) {
		// 	// List is already full, we can't sign the guest up.
		// 	let timeSpeech = Global.get12HourTimeSpeech( Global.getUTCHourMinuteString( signUpList.activityDateTime ) );
		// 	let daySpeech = Global.getDayOfWeekMonthDaySpeech(  Global.getUTCDateString( signUpList.activityDateTime ) );
		// 	let availableSpeech = 'the sign up list is full';
		// 	if (availableOpenings == 1) {
		// 		availableSpeech = 'there is only room for 1 more person';
		// 	} else if (availableOpenings > 1) {
		// 		availableSpeech = 'there is only room for ' + availableOpenings + ' more people';
		// 	}
		// 	let response = 'Oh I\'m sorry, ' + availableSpeech + ' for ' + activityType + ' on ' + daySpeech + ' at ' + timeSpeech;
		// 	this.speakResponse( response );
		// } else {
		// 	// Add guests to sign up list
		// 	signUpList.guests.push( new ActivitySignUp( roomNumber, null, numberOfPeople ) );
		// 	new ActivitySignUpListTable().put( signUpList )
		// 	.then( () => {
		// 		let timeSpeech = Global.get12HourTimeSpeech( Global.getHourMinuteString( signUpList.activityDateTime, timeZone ) );
		// 		let daySpeech = Global.getDayOfWeekMonthDaySpeech(  Global.getUTCDateString( signUpList.activityDateTime ) );
		// 			let response = 'Ok, I have added ' + numberOfPeople + ' for ' + activityType + ' on ' + daySpeech + ' at ' + timeSpeech;
		// 		Global.log( response );
		// 		this.speakResponse( response );
		// 	})
		// 	.catch( error => this.handleError( error ) );
		// }
	}

	/**
	 * Adds guests to a sign up list if there is sufficient available capacity.
	 * Update only succeeds if the version number of the signup list matches the one we read when
	 * we loaded the list, if it fails it will re-read the row and retry the update up to 5 times.
	 * We don't use upsertVersionedRow function because when retrying an update, there may not be
	 * enough capacity even though there was when we first attempted the update.
	 */
	public addGuestsToList( signUpList: ActivitySignUpList, activitySignUp: ActivitySignUp, retryCount: number = 0 ): Promise<number> {
		return new Promise<number>( (resolve,reject) => {
			// Check capacity
			let availableOpenings = Math.max( 0, signUpList.capacity - signUpList.getGuestCount() );
			if (activitySignUp.persons > availableOpenings) {
				// There is not enough capacity for the requested number of people
				resolve( availableOpenings );
			} else {
				// Add guests to sign up list
				signUpList.guests.push( activitySignUp );

				// Save updated list
				let conditionExpression = null;
				let expressionAttributeValues = null;
				if (signUpList.version == 1) {
					// This a new row, save it as version 1 on the condition it does not exist
					conditionExpression = "attribute_not_exists(version)";
				} else {
					// Increment version number and do a conditional write to prevent overwriting data
					conditionExpression = "version = :version";
					expressionAttributeValues = { ":version": signUpList.version };
					signUpList.version++;
				}
				new ActivitySignUpListTable().put( signUpList, conditionExpression, expressionAttributeValues )
				.then( () => {
					resolve( availableOpenings );
				})
				.catch( error => { 
					if (error.code == 'ConditionalCheckFailedException') {
						// Record has been updated by another user since we read it
						if (retryCount == 5) {
							reject( new Error( 'Error updating activity signup list, too many retries.') )
						} else {
							this.get( signUpList)
							.then( signUpList => {
								this.addGuestsToList( signUpList, activitySignUp, retryCount+1 )
								.then( availableOpenings => resolve( availableOpenings ) )
								.catch( error => reject( error ) );
							})
							.catch( error => reject( error ) );
						}
					} else {
						reject( error );
					}
				});
			}
		});
	}

	public roomNumberAlreadyOnList = "roomNumberAlreadyOnList";
	public signUpListOnlyHasOneSlot = "signUpListOnlyHasOneSlot";
	public signUpListHasTooFewSlots = "signUpListHasTooFewSlots";
	public signUpListIsFull = "signUpListIsFull";

	/**
	 * Update the given sign up list by replacing the old row with the new row.
	 * Ensure that the same version of the row is read and written to prevent users from overwriting each other. 
	 * 
	 * @param list List to update.
	 * @param oldRow Old row in the list to be replaced, if null just insert the new row.
	 * @param newRow New row to add to list, if null just delete the old row.
	 * @returns Updated list.
	 */
	public updateSignUpList( list: ActivitySignUpList, oldRow: ActivitySignUp, newRow: ActivitySignUp ): Promise<ActivitySignUpList> {
		return new Promise<ActivitySignUpList>( (resolve,reject) => {
			if (oldRow == null) {
				// We are inserting a new sign up
				this.insertSignUp( list, newRow )
				.then( updatedRow => resolve( updatedRow ) )
				.catch( error => reject( error ) );
			} else if (newRow == null) {
				// We are deleting a signup
				this.deleteSignUp( list, oldRow )
				.then( updatedRow => resolve( updatedRow ) )
				.catch( error => reject( error ) );
			} else {
				// We are updating a signup
				this.updateSignUp( list, oldRow, newRow )
				.then( updatedRow => resolve( updatedRow ) )
				.catch( error => reject( error ) );
			}
		});
	}

	/**
	 * Insert sign up in list.
	 * Ensure that the same version of the row is read and written to prevent users from overwriting each other. 
	 * 
	 * @param list List to update.
	 * @param newRow New row to add to list.
	 * @returns Updated list.
	 */
	private insertSignUp( list: ActivitySignUpList, newRow: ActivitySignUp ): Promise<ActivitySignUpList> {
		return new Promise<ActivitySignUpList>( (resolve,reject) => {
			let guestInfo = list.getGuestInfo( newRow.roomNumber );
			if (guestInfo != null) {
				// Room number is already on the sign up list.
				reject( { "code": this.roomNumberAlreadyOnList, "message": 'Room number ' + newRow.roomNumber + ' has already signed up ' + guestInfo.persons + ' ' + (guestInfo.persons == 1 ? 'person' : 'people') } );
			} else  {
				// Check capacity
				if (newRow.persons > list.getAvailableCapacity()) {
					// List is already full, we can't sign the guest up.
					reject( this.getNotEnoughCapacityError( list, list.getAvailableCapacity() ) );
				} else {
					// Add guests to sign up list
					list.guests.push( newRow );
					new ActivitySignUpListTable().upsertVersionedRow( list, ( rowToUpdate ) => {
						if (newRow.persons > rowToUpdate.getAvailableCapacity()) {
							// List is already full, we can't sign the guest up.
							throw this.getNotEnoughCapacityError( rowToUpdate, rowToUpdate.getAvailableCapacity() );
						}
						rowToUpdate.guests.push( newRow );
						return rowToUpdate;
					})
					.then( updatedRow => resolve( updatedRow ) )
					.catch( error => reject( error ) );
				}
			}
		});
	}

	/**
	 * Update the given sign up list by replacing the old row with the new row.
	 * Ensure that the same version of the row is read and written to prevent users from overwriting each other. 
	 * 
	 * @param list List to update.
	 * @param oldRow Old row in the list to be replaced.
	 * @param newRow New row to add to list.
	 * @returns Updated list.
	 */
	public updateSignUp( list: ActivitySignUpList, oldRow: ActivitySignUp, newRow: ActivitySignUp ): Promise<ActivitySignUpList> {
		return new Promise<ActivitySignUpList>( (resolve,reject) => {
			let oldSignUpIndex = list.getGuestInfoIndex( oldRow.roomNumber );
			if (oldSignUpIndex == -1) {
				// Old row not found
				if (newRow.persons > list.getAvailableCapacity()) {
					// List doesn't have room for updated number of persons, we can't update the sign up.
					reject( this.getNotEnoughCapacityError( list, list.getAvailableCapacity() ) );
				} else {
					// Insert new row
					this.insertSignUp( list, newRow )
					.then( updatedRow => resolve( updatedRow ) )
					.catch( error => reject( error ) );
				}
			} else {
				// We found the old row, see if there is enough capacity to update it
				if ((newRow.persons - oldRow.persons) > list.getAvailableCapacity()) {
					// List doesn't have room for updated number of persons, we can't update the sign up.
					reject( this.getNotEnoughCapacityError( list, list.getAvailableCapacity() ) );
				} else {
					// Update sign up list
					list.guests.splice( oldSignUpIndex, 1, newRow );
					new ActivitySignUpListTable().upsertVersionedRow( list, ( rowToUpdate ) => {
						oldSignUpIndex = rowToUpdate.getGuestInfoIndex( oldRow.roomNumber );
						if (oldSignUpIndex == -1) {
							// Sign up is no longer in the list, add the updated sign up
							if (newRow.persons > rowToUpdate.getAvailableCapacity()) {
								// List is already full, we can't sign the guest up.
								throw this.getNotEnoughCapacityError( rowToUpdate, rowToUpdate.getAvailableCapacity() );
							}
							rowToUpdate.guests.push( newRow );
						} else {
							// Replace old sign up with updated sign up in the list
							if ((newRow.persons - oldRow.persons) > rowToUpdate.getAvailableCapacity()) {
								// List is already full, we can't sign the guest up.
								throw this.getNotEnoughCapacityError( rowToUpdate, rowToUpdate.getAvailableCapacity() );
							}
							rowToUpdate.guests.splice( oldSignUpIndex, 1, newRow );
						}
						return rowToUpdate;
					})
					.then( updatedRow => resolve( updatedRow ) )
					.catch( error => reject( error ) );
				}
			}
		});
	}

	/**
	 * Update the given sign up list by replacing the old row with the new row.
	 * Ensure that the same version of the row is read and written to prevent users from overwriting each other. 
	 * 
	 * @param list List to update.
	 * @param oldRow Old row in the list to be replaced.
	 * @returns Updated list.
	 */
	private deleteSignUp( list: ActivitySignUpList, oldRow: ActivitySignUp ): Promise<ActivitySignUpList> {
		return new Promise<ActivitySignUpList>( (resolve,reject) => {
			let oldSignUpIndex = list.getGuestInfoIndex( oldRow.roomNumber );
			if (oldSignUpIndex == -1) {
				// Old row not found, assume operation was successful
				resolve( list );
			} else {
				list.guests.splice( oldSignUpIndex, 1 );
				new ActivitySignUpListTable().upsertVersionedRow( list, ( rowToUpdate ) => {
					let oldSignUpIndex = rowToUpdate.getGuestInfoIndex( oldRow.roomNumber );
					if (oldSignUpIndex != -1) {
						// Remove old sign up
						rowToUpdate.guests.splice( oldSignUpIndex, 1 );
					}
					return rowToUpdate;
				})
				.then( updatedRow => resolve( updatedRow ) )
				.catch( error => reject( error ) );
			}
		});
	}

	private getNotEnoughCapacityError( signUpList: ActivitySignUpList, availableOpenings: number ): any {
		let timeSpeech = Global.get12HourTimeSpeech( Global.getUTCHourMinuteString( signUpList.activityDateTime ) );
		let daySpeech = Global.getDayOfWeekMonthDaySpeech(  Global.getUTCDateString( signUpList.activityDateTime ) );
		let error = null;
		if (availableOpenings == 1) {
			error = { "code": "SignUpListOnlyHasOneSlot", "message": 'There is only room for 1 more person' };
		} else if (availableOpenings > 1) {
			error = { "code": "signUpListHasTooFewSlots", "message": 'There is only room for ' + availableOpenings + ' more people' };
		} else {
			error = { "code": "signUpListIsFull", "message": 'The sign up list is full' };
		}
	}

}
