import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import {UserLoginService} from "../../service/user-login.service";
import {CacheService} from "../../service/cache.service";
import { Global } from '../../service/Global';
import { PropertyName } from '../../service/PropertyName';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";
import { AdProperty } from "../../service/AdProperty";

/** Edit activity details. */
@Component({
    selector: 'site-ad-property',
	templateUrl: './site-ad-property.html',
	styleUrls: [ 'site-ad-property.css' ]
})
export class SiteAdPropertyComponent extends EditorDetail<AdProperty> implements OnInit {

	/** Currently selected property */
	private propertyKey: string = null;

	/** List of activity types for this property. */
	public propertyNames: PropertyName[] = [];

	private bidAmount: string = '0';

	@ViewChild('siteAdPropertyModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private userService: UserLoginService,
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): AdProperty {
		return new AdProperty();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		if (this.editedRow.companyId != null && this.editedRow.propertyId != null) {
			this.propertyKey = this.editedRow.companyId + '_' + this.editedRow.propertyId;
		} else if (this.propertyNames) {
			this.propertyKey = this.propertyNames[0].getKeyString();
			this.detectChanges();
		}
		if (this.editedRow.bidCents != null) {
			this.bidAmount = '' + Math.floor( this.editedRow.bidCents / 100 );
		} else {
			this.bidAmount = '0';
		}
	}

	/** Return a deep copy of the given object. */
	clone( row: AdProperty ): AdProperty {
		return new AdProperty().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		this.errorMessage = null;

		if (this.propertyKey == null) {
			this.errorMessage = 'Please select a property.';
		}

		if (this.errorMessage == null) {
			let idStrings = this.propertyKey.split('_');
			this.editedRow.companyId = Number.parseInt( idStrings[0] );
			this.editedRow.propertyId = Number.parseInt( idStrings[1] );

			// Set bidCents from bidAmount
			try {
				this.editedRow.bidCents = Math.floor( Number.parseFloat( this.bidAmount ) * 100 );
			} catch( error ) {
				this.editedRow.bidCents = 0;
			}
		}

		return this.errorMessage == null;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete this advertising property?';
	}

}