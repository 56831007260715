import { Injectable } from "@angular/core";
import { AudioControlService } from "./audio-control.service"
import { LexService } from './lex.service';
import { Global } from './Global';

let messages = Object.freeze({
	PASSIVE: '',
	LISTENING: 'Listening...',
	SENDING: 'Processing...',
	SPEAKING: 'Speaking...'
});

@Injectable()
export class ConversationService {

	audioInput;
	audioOutput;
	private currentState;
	private listener: Function;
	private running = false;

	constructor( public audioControl: AudioControlService ) {}

	supportsAudio() : Promise<boolean> {
		return this.audioControl.supportsAudio();
	}

	start( listenerFunction: Function ) {
		console.log('ConversationService.start');
		this.listener = listenerFunction;
		this.currentState = new Initial(this);

		this.audioControl.supportsAudio()
		.then( supported => {
			if (supported) {
				console.log('ConversationService.start - audio capture is supported');
				this.running = true;
				this.updateMessage( this.currentState.message );
				this.advanceConversation();
			} else {
				Global.log('ConversationService.start - audio capture is not supported');
				this.updateMessage( 'Audio capture is not supported.' );
			}
		})
		.catch( error => {
			Global.logError( 'Error checking for audio support.', error );
		});
	}

	stop() {
		this.running = false;
	}

	updateMessage( message: string ) {
		if (this.running) {
			this.listener( { command: 'updateAudioMessage', message: message } );
		}
	}

	onSilence() {
		if (this.running) {
			console.log('Conversation.onSilence');
			this.audioControl.stopRecording();
			this.listener( { command: 'clear' } );
			this.currentState.advanceConversation();
		}
	};

	transition( newState: State ) {
		if (this.running) {
			this.currentState = newState;
			this.updateMessage( newState.message );
			if (newState.message === messages.SENDING) {
				this.currentState.advanceConversation();
			} else if (newState.message === messages.SPEAKING) {
				this.currentState.advanceConversation();
			}
		}
	};

	advanceConversation() {
		if (this.running) {
			this.currentState.advanceConversation();
		}
	};

	startRecording() {
		if (this.running) {
			console.log('Conversation.startRecording');
			this.listener( { command: 'listen' } );
			this.audioControl.startRecording( () => this.onSilence(), this.listener);
			this.transition( new Listening( this ) );
		}
	}

	callListener( data: any ) {
		if (this.running) {
			this.listener( data );
		}
	}

}

abstract class State {
	constructor( public conversation: ConversationService, public message: string ) {}
	abstract advanceConversation(): void;
}

class Initial extends State {

	constructor( conversation ) {
		super( conversation, messages.PASSIVE );
	}

	advanceConversation() {
		this.conversation.startRecording();
	}

}

class Listening extends State {

	constructor( conversation ) {
		super( conversation, messages.LISTENING );
	}

	advanceConversation() {
		this.conversation.updateMessage( messages.SENDING );
		this.conversation.audioControl.exportWAV( blob => {
			this.conversation.audioInput = blob;
			this.conversation.transition(new Sending( this.conversation ));
		});
	}

}

class Sending extends State {

	constructor( conversation ) {
		super( conversation, messages.SENDING );
	}
		
	advanceConversation() {
		// console.log( 'Sending recorded audio to Lex' );
		new LexService().postContent( 'TimeclockBot', 'userId', this.conversation.audioInput )
		.then( data => {
			try {
				if (data) {
					let dataStr = JSON.stringify( data, null, 2 );
					if (dataStr.length > 200) {
						dataStr = dataStr.substr( 0, 200 );
					}
					// console.log('Response from Lex: ' + dataStr );
					if ('Failed' == data.dialogState) {
						// Lex gave up trying to figure out what was going on after the retries
						console.log('Conversation failed.')
						this.conversation.transition( new Initial( this.conversation ) );
						this.conversation.callListener( { command: 'failed', data: data } );
					} else if ('ElicitIntent' == data.dialogState) {
						// Lex didn't understand the user's intent, ask again
						console.log('Lex could not recognize intent, ask again.')
						this.conversation.callListener( { command: 'elicitIntent' } );
					} else if ('ReadyForFulfillment' == data.dialogState) {
						// The conversation is over and ready to process
						this.conversation.transition( new Initial( this.conversation ) );
						this.conversation.callListener( { command: 'readyForFulfillment', data: data } );
					} else {
						// Lex wants to prompt the user for more information
						this.conversation.audioOutput = data;
						this.conversation.transition( new Speaking( this.conversation ) );
					}
				} else {
					console.log('Error getting response from LEX, no data in response.');
					this.conversation.callListener( { command: 'elicitIntent' } );
				}
			} catch( err ) {
				console.log('Error processing conversation: ' + err.message );
				this.conversation.callListener( { command: 'elicitIntent' } );
			}
		}).catch( err => {
			console.log('Error sending audio to LEX for processing: ' + err.message );
			this.conversation.callListener( { command: 'elicitIntent' } );
		});
		console.log( 'Async call to Lex sent, promise will handle response' );
	}
};

class Speaking extends State {

	constructor( conversation ) {
		super( conversation, messages.SPEAKING );
	}
		
	advanceConversation() {
		if (this.conversation.audioOutput.contentType === 'audio/mpeg') {
			this.conversation.audioControl.play( this.conversation.audioOutput.audioStream, () => {
				this.conversation.startRecording();
			});
		// } else if ( this.conversation.audioOutput.dialogState === 'ReadyForFulfillment') {
		// 	this.conversation.transition( new Initial( this.conversation ) );
		}
	}
};

