import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { CacheService } from "../../service/cache.service";
import { UserCompany } from "../../service/UserCompany";
import { Global } from '../../service/Global';
import { Role } from '../../service/Role';
import { UUIDService } from '../../service/uuid.service';
import { RoleSelectorComponent } from "../role-selector/role-selector.component";
import { GetRoleListCmd } from "../../command/GetRoleListCmd";
import { EditorDetail } from "../../service/EditorDetail";
import { ConfirmComponent } from "../confirm/confirm.component";

/** Editor for UserCompany records. */
@Component({
    selector: 'user',
	templateUrl: './user.html',
	styleUrls: [ 'user.css' ]
})
export class UserComponent extends EditorDetail<UserCompany> implements OnInit, OnDestroy {
	password: string = null;

	confirmPassword: string = null;

	/** List of rows that the currently logged-in user has. */
	public roles: Role[] = null;
	
	@ViewChild(RoleSelectorComponent) private roleSelectorComponent: RoleSelectorComponent;

	@ViewChild('userModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private cache: CacheService,
		private uuidService: UUIDService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): UserCompany {
		return new UserCompany();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		this.loadRoles()
		.then( () => {
			// this.loadUserRoles();
		})
		.catch( error => {
			this.errorMessage = 'Sorry, there was a problem loading the roles.  Please try again.';
		});
	}

	/** Return a deep copy of the given object. */
	clone( row: UserCompany ): UserCompany {
		return new UserCompany().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		let valid = true;
		if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name.';
			valid = false;
		} else if ((!this.editedRow.email) || this.editedRow.email.trim() === '') {
			this.errorMessage = 'Please enter an email address.';
			valid = false;
		} else if (this.isInsert && ((!this.password) || this.password.trim() === '')) {
			this.errorMessage = 'Please enter a password.';
			valid = false;
		} else if (this.isInsert && (this.password != this.confirmPassword)) {
			this.errorMessage = 'The password and confirmation password do not match.  Please re-enter them.';
			valid = false;
		}
		// Make sure the companyId is set
		this.editedRow.companyId = this.cache.currentCompany.companyId;
		return valid;
	}


	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete user ' + this.editedRow.name + ' from company ' + this.editedRow.companyId + '?';
	}

	private loadRoles(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			if (this.roles) {
				resolve();
			} else {
				new GetRoleListCmd().do( this.cache.currentCompany.companyId )
				.then( rows => {
					this.roles = rows;
					resolve();
				})
				.catch( error => reject( error ) );
			}
		});
	}

	/**
	 * Return list of roles that has all the roles the current user can assign except the ones
	 * the user being edited already has.
	 */
	private getListOfRolesUserDoesNotHave(): Role[] {
		// Create list of roles that has all the roles the current user can assign except the ones 
		// the user being edited already has.
		let selectList: Role[] = [];
		if (this.cache.currentRole.roleId == Role.SYSADMINID && !this.editedRow.hasSysAdminRole()) {
			// Current user has SysAdmin role and edited user does not so add it to the list
			selectList.push( new Role().setSysAdmin() );
		} else if (this.cache.currentRole.roleId == Role.COMPANYADMINID && this.editedRow.roleIds.indexOf( Role.COMPANYADMINID ) == -1) {
			// Current user has CompanyAdmin role and edited user does not so add it to the list
			selectList.push( new Role().setCompanyAdmin() );
		}
		// Loop thru roles that current user has that are found in role table (SysAdmin and CompanyAdmin roles are not in the table)
		for (let i=0; i<this.roles.length; i++) { 
			let role = this.roles[i];
			if (this.editedRow.roleIds.indexOf( role.roleId ) == -1) {
				selectList.push( role );
			}
		}
		return selectList;
	}

	getRole( roleId: number ): Role {
		let found = null;
		if (roleId == Role.SYSADMINID) {
			found = new Role().setSysAdmin();
		} else if (roleId == Role.COMPANYADMINID) {
			found = new Role().setCompanyAdmin();
		} else if (this.roles) {
			this.roles.forEach( role => {
				if (role.roleId == roleId) {
					found = role;
				}
			});
		}
		// console.log( 'getRole( '+roleId+') returns '+JSON.stringify(found,null,2));
		return found;
	}

	getRoleName( roleId: number ): string {
		let role = this.getRole( roleId );
		return role ? role.roleName : '';
	}

	private addRole() {
		this.roleSelectorComponent.roles = this.getListOfRolesUserDoesNotHave();
		let defaultRoleId: Number = new Number( null );
		if (this.roleSelectorComponent.roles.length > 0) {
			defaultRoleId = new Number( this.roleSelectorComponent.roles[0].roleId );
		}
		this.handleChildInsert( defaultRoleId, this.editedRow.roleIds, this.roleSelectorComponent );
	}

	private editRole( index: number ) {
		// Users can select the existing role or a role they do not already have
		this.roleSelectorComponent.roles = this.getListOfRolesUserDoesNotHave();
		this.roleSelectorComponent.roles.splice( 0, 0, this.getRole( this.editedRow.roleIds[index] ) );
		console.log( 'index='+index+', roleIds='+this.editedRow.roleIds);
		this.handleChildEdit( index, this.editedRow.roleIds, this.roleSelectorComponent );
	}

}