import * as DynamoDB from "aws-sdk/clients/dynamodb";
import { TableHandler, TableDocumentObject } from './TableHandler'
import { AdCategory } from './AdCategory';
import { DynamoDBService } from './ddb.service';
import { Global } from './Global';
import { System } from './System';
import { Product } from './Product';
import { VolumePrice } from './VolumePrice';

/** Object used to test creating and deleting tables. */
class TestRow extends TableDocumentObject {

	constructor(
		public id: number = null,
		public name: string = null,
	) { super(); }

    fromDataItem( item: any ): TestRow {
		this.copyPropertiesFromObject( item );
        return this;
    }

	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
    }

    getKey(): any {
        return {
			"id": this.id, 
        };
    }

}

/** Table object used to test creating and deleting tables. */
class TestTable extends TableHandler<TestRow> {

	public constructor() {
		super( 'bookcliffsoftware-Test' );
	}
	
	public fromDataItem( item: any ): TestRow {
		return new TestRow().fromDataItem( item );
	}

}

export class SystemTable extends TableHandler<System> {

    public constructor() {
		super( 'bookcliffsoftware-System' );
	}
	
	public fromDataItem( item: any ): System {
		return new System().fromDataItem( item );
	}

	/** 
	 * @returns The one and only set of data used to configure the entire system.
	 */
	public getSystemData(): Promise<System> {
		return new Promise<System>( (resolve,reject) => {
			this.getExisting( new System(1) )
			.then( system => resolve( system ) )
			.catch( error => reject( error ) );
		});
	}

	public incrementLastUsedCompanyId(): Promise<System> {
		return this.incrementValue( new System(1), 'lastUsedCompanyId' );
	}
	
	/** 
	 * @returns The global list of ad categories.
	 */
	public getAdCategories(): Promise<AdCategory[]> {
		return new Promise<AdCategory[]>( (resolve,reject) => {
			this.getSystemData()
			.then( system => resolve( system.adCategories ) )
			.catch( error => reject( error ) );
		});
	}
	
	/**
	 * Recursively convert the database to the current version if necessary.
	 * After converting to the next version or waiting for a conversion to complete, this function
	 * must be called again to see if any more conversions (or waiting) are necessary.
	 * 
	 * @param system Object read from the System table.
	 */
	public convertDatabaseIfNeeded(): Promise<System> {
		return new Promise<System>( (resolve,reject) => {
			this.getSystemData()
			.then( system => {
				this.convertDatabaseRecursively( system )
				.then( system => {
					Global.log( 'Database is at version '+system.dbVersion+', conversion check complete.' );
					resolve( system );
				})
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}
	
	/**
	 * Recursively convert the database to the current version if necessary.
	 * After converting to the next version or waiting for a conversion to complete, this function
	 * must be called again to see if any more conversions (or waiting) are necessary.
	 * 
	 * @param system Object read from the System table.
	 */
	private convertDatabaseRecursively( system: System ): Promise<System> {
		return new Promise<System>( (resolve,reject) => {
			if (system.isConversionRunning()) {
				
				this.waitForConversionToFinish( system )
				.then( system => resolve( system ) )
				.catch( error => reject( error ) );

			} else if (system.dbVersion == 0) {
				
				this.convertDbToVersion1( system )
				.then( system => resolve( system ) )
				.catch( error => reject( error ) );

			} else if (system.dbVersion == 1) {

				this.convertDbToVersion2( system )
				.then( system => resolve( system ) )
				.catch( error => reject( error ) );

			} else if (system.dbVersion == 2) {
				
				this.convertDbToVersion3( system )
				.then( system => resolve( system ) )
				.catch( error => reject( error ) );

			} else {
				resolve( system );
			}
		});
	}

	waitForConversionToFinish( system: System ): Promise<System> {
    	return new Promise<System>( (resolve, reject) => {
			Global.log( 'Waiting for database conversion from version '+system.dbVersion+' to finish.' );
			let millisBetweenChecks = 3000;
			this.getSystemData()
			.then( system => {
                if (!system.isConversionRunning()) {
					// Conversion is complete, see if we need more conversions
					this.convertDatabaseRecursively( system )
					.then( system => resolve( system ) )
					.catch( error => reject( error ) );
				} else if (system.hasConversionTimedOut()) {
					// Give up waiting for conversion
					reject( new Error( 'Timed out waiting for db conversion from version '+system.dbVersion+' to finish at '+new Date().toISOString()+', conversion started at '+new Date(system.dbConversionStartTime).toISOString() ) );
				} else {
					// Keep waiting
					Global.log( 'Converting database from version '+system.dbVersion+', conversion started '+((Date.now()-system.dbConversionStartTime)/1000)+' seconds ago, keep waiting' );
					setTimeout( () => {
						this.waitForConversionToFinish( system )
						.then( system => resolve( system ) )
						.catch( error => reject( error ) );
					}, millisBetweenChecks );
				}
            })
			.catch( error => reject( error ) );
		});
	}

	/**
	 * This is a TEST conversion to validate that createTable and deleteTable work correctly.
	 */
	private convertDbToVersion1( system: System ): Promise<System> {
		return new Promise<System>( (resolve,reject) => {

			// Set conversion start time so other processes know that a conversion is in progress
			Global.log( 'Converting database to version 1' );
			this.updateSystemDbVersion( system, 0, Date.now() )
			.then( system =>  {

				// Create a test table
				let params: DynamoDB.CreateTableInput = {
					TableName: new TestTable().getTableName(),
					AttributeDefinitions: [ { AttributeName: "id", AttributeType: "S" } ], 
					KeySchema: [ { AttributeName: "id", KeyType: "HASH" } ], 
					ProvisionedThroughput: { ReadCapacityUnits: 1, WriteCapacityUnits: 1 }, 
				};
				new DynamoDBService().createTable( params )
				.then( results => {

					Global.log( 'Test DynamoDbService.createTable PASSED')
					// Delete a test table
					new DynamoDBService().deleteTable( new TestTable().getTableName() )
					.then( results => {

						Global.log( 'Test DynamoDbService.deleteTable PASSED')
						// Set System.dbVersion to 1
						this.updateSystemDbVersion( system, 1, null )
						.then( system => {

							// Conversion is complete, see if we need more conversions
							this.convertDatabaseRecursively( system )
							.then( system => resolve( system ) )
							.catch( error => reject( error ) );
						})
						.catch( error => reject( error ) )
					})
					.catch( error => reject( error ) );
				})
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Move contents of AdCategory table into System table and drop AdCategory table.
	 */
	private convertDbToVersion2( system: System ): Promise<System> {
		return new Promise<System>( (resolve,reject) => {

			// Set conversion start time so other processes know that a conversion is in progress
			Global.log( 'Converting database to version 2' );
			this.updateSystemDbVersion( system, 1, Date.now() )
			.then( system =>  {

// We commented this out because the AdCategoryTable no longer exists				
				// // Get list of all ad categories from AdCategory table
				// new AdCategoryTable().getAll()
				// .then( rows => {

				// 	// Put list of ad categories in System table
				// 	system.adCategories = rows;
				// 	this.put( system )
				// 	.then( system => {

				// 		// Drop the AdCategory table
				// 		new DynamoDBService().deleteTable( new AdCategoryTable().getTableName() )
				// 		.then( results => {

							// Set System.dbVersion to 2
							this.updateSystemDbVersion( system, 2, null )
							.then( system => {
								// Conversion is complete, see if we need more conversions
								this.convertDatabaseRecursively( system )
								.then( system => resolve( system ) )
								.catch( error => reject( error ) );
							})
							.catch( error => reject( error ) )
				// 		})
				// 		.catch( error => reject( error ) );
				// 	})
				// 	.catch( error => reject( error ) );
				// })
				// .catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Move contents of Role table into Company table.
	 */
	private convertDbToVersion3( system: System ): Promise<System> {

		let oldVersion = 2;
		let newVersion = 3;

		return new Promise<System>( (resolve,reject) => {

// We commented this out because the RoleTable no longer exists				
			// Set conversion start time so other processes know that a conversion is in progress
			Global.log( 'Converting database to version '+newVersion );
			this.updateSystemDbVersion( system, oldVersion, Date.now() )
			.then( system =>  {

				// // Get roles to move
				// new RoleTable().scanAll( null, null )
				// .then( rows => {

				// 	// Put roles in their related company records
				// 	Global.log( 'Found '+rows.length+' roles.' );

				// 	// Create a map of role lists keyed by company ID
				// 	let map = new Map<number,Role[]>();
				// 	rows.forEach( role => {
				// 		let companyRoles = map.get( role.companyId );
				// 		if (!companyRoles) {
				// 			companyRoles = [];
				// 		}
				// 		companyRoles.push( role );
				// 		map.set( role.companyId, companyRoles );
				// 	});

				// 	// Add role list to each company
				// 	let promises: Promise<Company>[] = [];
				// 	map.forEach( (roles: Role[], key: number) => {
				// 		promises.push( this.addRolesToCompany( roles ) );
				// 	});
				// 	// rows.forEach( role => promises.push( this.addRoleToCompany( new Role().fromDataItem( role.toDataItem() ) ) ) );
				// 	Promise.all( promises )
				// 	.then( results => {

						// Update the database version
						this.updateSystemDbVersion( system, newVersion, null )
						.then( system => {
							// Conversion is complete, see if we need more conversions
							this.convertDatabaseRecursively( system )
							.then( system => resolve( system ) )
							.catch( error => reject( error ) );
						})
						.catch( error => { this.updateSystemDbVersion( system, oldVersion, null ).then( system => reject( error ) ).catch( error2 => reject( error ) ); } )
				// 	})
				// 	.catch( error => { this.updateSystemDbVersion( system, oldVersion, null ).then( system => reject( error ) ).catch( error2 => reject( error ) ); } )
				// })
				// .catch( error => { this.updateSystemDbVersion( system, oldVersion, null ).then( system => reject( error ) ).catch( error2 => reject( error ) ); } )
			})
			.catch( error => reject( error ) );
		});
	}

    /**
     * Returns list of companies the user can log into.  If user is sysAdmin, returns all companies.
     * @param userId Cognito ID of the user from the sub property.
     */
    // private addRolesToCompany( roles: Role[] ): Promise<Company> {
    // 	return new Promise<Company>( (resolve, reject) => {
	// 		new CompanyTable().get( new Company( roles[0].companyId ) )
	// 		.then( company => {
	// 			Global.log( 'Saving company '+roles[0].companyId+' with added roles: '+JSON.stringify(roles,null,2));
	// 			company.roles = roles;
	// 			new CompanyTable().put( company )
	// 			.then( company => resolve( company ) )
	// 			.catch( error => reject( error ) );
	// 		})
	// 		.catch( error => reject( error ) );
    //     });
    // }

	private updateSystemDbVersion( system: System, newDbVersion: number, newDbConversionStartTime: number ): Promise<System> {
		return new Promise<System>( (resolve,reject) => {
			
			// Save old conversion field values
			let dbVersion: number = system.dbVersion;
			let dbConversionStartTime: number = system.dbConversionStartTime;
			
			// Set new conversion field values
			system.dbVersion = newDbVersion;
			system.dbConversionStartTime = newDbConversionStartTime;

			// Update the db version number and start time ONLY if no other process has updated while we were processing.
			let conditionExpression = null;
			let expressionAttributeValues = null; 
			conditionExpression = "(attribute_not_exists(dbVersion) or (dbVersion = :dbVersion)) and (attribute_not_exists(dbConversionStartTime) or (dbConversionStartTime = :dbConversionStartTime))";
			expressionAttributeValues = {
				":dbVersion": dbVersion, 
				":dbConversionStartTime": dbConversionStartTime
			};
			if (dbVersion != null && dbConversionStartTime != null) {
				conditionExpression = "dbVersion = :dbVersion and dbConversionStartTime = :dbConversionStartTime";
			} else if (dbVersion != null) {
				conditionExpression = "dbVersion = :dbVersion and (attribute_not_exists(dbConversionStartTime) or (dbConversionStartTime = :dbConversionStartTime))";
			} else if (dbConversionStartTime != null) {
				conditionExpression = "(attribute_not_exists(dbVersion) or (dbVersion = :dbVersion)) and dbConversionStartTime = :dbConversionStartTime";
			}
			Global.log( 'Updating system dbVersion to '+newDbVersion+', dbConversionStartTime='+newDbConversionStartTime+', conditionExpression='+conditionExpression+', expressionAttributeValues='+JSON.stringify(expressionAttributeValues,null,2) );
			this.put( system, conditionExpression, expressionAttributeValues )
			.then( system => resolve( system ))
			.catch( error => {
				// Global.log( 'ERROR updateSystemDbVersion dbVersion='+dbVersion+', dbConversionStartTime='+dbConversionStartTime+', conditionExpression='+conditionExpression+', expressionAttributeValues='+JSON.stringify(expressionAttributeValues,null,2)+', error='+JSON.stringify(error,null,2) );
				reject( error );
			});
		});
	}

	/**
	 * Return product information for the given product code.
	 * @param productCode Product code to find.
	 * @returns Product information or error if product code is invalid.
	 */
	public getProduct( productCode: string ): Promise<Product> {
		return new Promise<Product>( (resolve,reject) => {
			// Get list of products
			this.getProducts()
			.then( products => {
				// Look up product by it's code
				let product = Product.getProduct( productCode, products );
				if (product) {
					resolve( product );
				} else {
					reject( new Error( Global.log( 'Error loading product.  Invalid product code '+productCode ) ) );
				}
			})
			.catch( error => reject( new Error( Global.logError( 'Error loading product with product code '+productCode, error ) ) ) );
		});
	}

	/** @returns List of products from the one and only system record. */
	public getProducts(): Promise<Product[]> {
		return new Promise<Product[]>( (resolve,reject) => {
			this.getSystemData()
			.then( system => {
				if (system.products == null || system.products.length == 0) {
					// Add products to system record
					system.products = <Product[]>[];
					let prices = [
						new VolumePrice( 25, 399 ),
						new VolumePrice( 250, 359 ),
						new VolumePrice( 1000, 319 ),
						new VolumePrice( 2500, 279 ),
						new VolumePrice( 999999999, 239 ),
					];
				
					system.products.push( new Product( 'roomGenie', 'Room Genie', '/assets/img/GenieLampSmall.png', 0, false, prices, true ) );
					system.products.push( new Product( 'roomGenie+EchoDot', 'Room Genie Trial with Amazon Echo Dot', '/assets/img/GenieLampSmall.png', 9900, true, prices, true ) );
					system.products.push( new Product( 'analytics', 'VIP Analytics', null, 0, false, prices, true ) );
					system.products.push( new Product( 'connect', 'VIP Connect', null, 0, false, prices, true ) );
					system.products.push( new Product( 'recognize', 'VIP Recognize', null, 0, false, prices, true ) );
					system.products.push( new Product( 'lineTimer', 'VIP Line Timer', null, 0, false, prices, true ) );
					system.products.push( new Product( 'checkIn', 'VIP Check In', null, 0, false, prices, true ) );
					system.products.push( new Product( 'timeClock', 'VIP Timeclock', null, 0, false, prices, true ) );
					system.products.push( new Product( 'alert', 'VIP Alert', null, 0, false, prices, true ) );
					this.put( system )
					.then( () => resolve( system.products ) )
					.catch( error => reject( error ));
				} else {
					resolve( system.products );
				}
			})
			.catch( error => reject( error ));
		});
	}

    /**
     * Perform an atomic updateof the access token in the System record.
	 * 
	 * @param system System object containing the key values of the record to update.
	 * @param accessToken New access token.
	 * @return Updated item.
     */
	 updateAccessToken( system: System, accessToken: string ): Promise<System> {
		return this.updatePropertyValue( system, 'alexaForHospitalityAccessToken', accessToken );
    }

}
