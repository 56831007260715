import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';

// The number of milliseconds in two hours (detections within two hours are considered the same visit)
const twoHoursAsMilliseconds: number = 2 * 60 * 60 * 1000;

/**
 * Details about a face detected by a camera.
 */
export class Detection extends TableDocumentObject {
	
	/**
	 * Properties stored about each identified company.
	 * @param companyId Unique ID of company (partition key = companyId_siteId)
	 * @param siteId ID of site.
	 * @param cameraId ID of camera (sort key is cameraId_faceId)
	 * @param faceId ID of face.
	 * @param date Date of detection.
	 */
	constructor(
		public companyId: number = null,
		public siteId: number = null,
		public cameraId: string = null,
		public faceId: string = null,
		public date: Date = new Date(),
	) { super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): Detection {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		
		// Add key properties made up of multiple object properties
		item['companyId_siteId'] = this.companyId + '_' + this.siteId;
		item['cameraId_faceId'] = this.cameraId + '_' + this.faceId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "cameraId_faceId": this.cameraId + '_' + this.faceId
        };
	}

}

export class DetectionTable extends TableHandler<Detection> {
	
	public constructor() {
		super( 'faceTrackerDetection' );
	}
	
	public fromDataItem( item: any ): Detection {
		return new Detection().fromDataItem( item );
	}

    /**
     * Insert a new detection record in the faceTrackerDetection table if there is not an existing detection in the last 2 hours.
     * @param companyId Company ID.
     * @param siteId Site ID.
     * @param cameraId ID of the camera that detected the face.
     * @param faceId ID of the face that was detected, assigned when calling AWS indexFaces method.
     * @param date Date and time when detection occured.
     * @return Promise<AWS.DynamoDB.PutItemOutput>
     */
    insertIfNewVisit( companyId: number, siteId: number, cameraId: string, faceId: string, date: Date ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
            // See if there is a detection within the last two hours
            this.get( new Detection( companyId, siteId, cameraId, faceId ) )
            .then( detection => {
				Global.log( 'insertIfNewVisit got Detection: '+JSON.stringify(detection, null, 2)+'\ndetection.date instanceof Date='+(detection.date instanceof Date));
                // Assume we are going to insert/update a record in the detection table
                let putDetection = true;
                if (!detection) {
                    // No detection found, insert detection record
                    console.log( 'No detection record found for company '+companyId+', site '+siteId+', camera '+cameraId+', faceId '+faceId+'.  Inserting detection record.');
                    putDetection = true;
                } else if (Date.now() - detection.date.getTime() > twoHoursAsMilliseconds) {
                    // Found detection record but it was more than two hours ago, update detection record
                    console.log( 'Found detection record more than two hours old for company '+companyId+', site '+siteId+', camera '+cameraId+', faceId '+faceId+'.  Updating detection record.');
                    putDetection = true;
                } else {
                    // Found detection record within last two hours, ignore it
                    console.log( 'Found detection record less than two hours old for company '+companyId+', site '+siteId+', camera '+cameraId+', faceId '+faceId+'.  Ignore this detection.');
                    putDetection = false;
                }
                if (putDetection) {
                    return this.put( new Detection( companyId, siteId, cameraId, faceId, date ) );
                } else {
                    // We aren't upserting a detection so just return a resolved promise
                    resolve();
                }
            })
            .catch( reason => {
                reject( new Error( reason ) ); // Pass the error on
            })
        });
    }

}
