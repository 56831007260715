import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {CacheService} from "../../service/cache.service";
import { Company } from "../../service/Company"
import { CompanyTable } from "../../service/CompanyTable"
import { Global } from '../../service/Global';
import { Role } from '../../service/Role';
import { DeleteCompanyCmd } from '../../command/DeleteCompanyCmd';
import { RoleComponent } from '../role/role.component';

/**
 * Company editing component.
 */
@Component({
    selector: 'company-detail',
    templateUrl: './company-detail.html',
	styleUrls: ['./company-detail.css'],
})
export class CompanyDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    private company: Company = new Company();
    private originalCompany: Company;
	private errorMessage: string = null;
	private resolveModal: (company: Company) => void;
	private rejectModal: (error: Error) => void;
	private hideDeleteButton: boolean = true;
	
	@ViewChild('companyModalButton') public companyModalButtonRef: ElementRef; private companyModalButton: HTMLButtonElement;

	@ViewChild('name') public focusRef: ElementRef; private focusElement: HTMLInputElement;

	@ViewChild(RoleComponent) private detailComponent: RoleComponent;

	constructor(
		public router: Router, 
		private changeDetectorRef: ChangeDetectorRef,
        public userService: UserLoginService,
        public cache: CacheService,
        private companyTable: CompanyTable )
    {}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.hideDeleteButton = (!this.cache.currentRole) || this.cache.currentRole.roleId != Role.SYSADMINID;
    }

	ngOnDestroy() {
        // console.log('CompanyDetailComponent.ngOnDestroy()');
	}

	ngAfterViewInit() {
		// console.log('company-detail ngAfterViewInit' );
		this.companyModalButton = this.companyModalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	showModalDialog( company: Company ): Promise<Company> {
		return new Promise<Company>( (resolve,reject) => {
			if (!company) {
				reject( new Error( 'No edit object provided.' ) );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Make a copy of object so we can restore the original values if user cancels.
				this.originalCompany = company;
				this.company = new Company().fromDataItem( company.toDataItem() );
				// Show the dialog
				this.companyModalButton.click();
				// Put keyboard focus on heading field after dialog is displayed
				setTimeout( () => this.focusElement.focus(), 1000 );
			}
		});
	}
	
	/** Close the dialog without saving changes. */
	private onCancel() {
		// Hide the dialog
		this.companyModalButton.click();
		this.resolveModal( null );
	}

    private onSave() {
		// Update the object passed in for editing with the edited values
		this.originalCompany.fromDataItem( this.company.toDataItem() );
		
		if (!this.company.companyId) {
			// We are editing a new company get a new unique company id for it

		}
		this.companyTable.put( this.company )
		.then( () => {
			// console.log("CompanyTable.put succeeded.");
			if (this.cache.currentCompany.companyId == this.company.companyId) {
				// Update the company in the cache
				this.cache.currentCompany = this.company;
			}
			// Hide the dialog
			this.companyModalButton.click();
			this.resolveModal( this.originalCompany );
		})
		.catch( err => {
			// We failed to insert the Company record
			Global.logError("CompanyTable.put failed.", err );
			this.errorMessage = 'Sorry, there was a problem saving the company\'s record.  ' + err.message;
		});
    }

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	insertRole() {
		this.detailComponent.handleChildInsert( new Role(), this.company.roles, this.detailComponent );
	}

	editRole( index: number ) {
		this.detailComponent.handleChildEdit( index, this.company.roles, this.detailComponent );
	}

	private onDelete() {
		this.cache.confirmComponent.showModalDialog( 'WARNING: ALL COMPANY DATA WILL BE DELETED AND CANNOT BE RECOVERED!  Are you sure you want to delete company: ' + this.company.companyId+': '+this.company.name + '?', 'Confirm Delete' )
		.then( confirmed => {
			if (confirmed) {
				new DeleteCompanyCmd().do( this.cache.currentCompany.companyId )
				.then( () => {
					// Hide the dialog
					this.companyModalButton.click();
					this.resolveModal( null );
				})
				.catch( error => this.errorMessage = Global.logError( 'Error deleting company.', error ) );
			}
		})
		.catch( error => this.errorMessage = Global.logError( 'Error deleting company.', error ) );
	}

}