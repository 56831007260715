import { TableHandler } from './TableHandler'
import { Global } from './Global';
import { RestaurantReservationList } from './RestaurantReservationList';

/** Restaurant reservations. */
export class RestaurantReservationTable extends TableHandler<RestaurantReservationList> {

	/** Local secondary index use to query reservation lists for all restaurants by the starting date/time of the seating. */
	private startTimeIndex = 'companyId_propertyId-startTime-index';
	
	public constructor() {
		super( 'bookcliffsoftware-RestaurantReservation' );
	}
	
	public fromDataItem( item: any ): RestaurantReservationList {
		return new RestaurantReservationList().fromDataItem( item );
	}

	public saveUpdatedReservationList( list: RestaurantReservationList ): Promise<RestaurantReservationList> {
		let conditionExpression = null;
		let expressionAttributeValues = null;
		if (list.version == null) {
			// This is a brand new reservation list, save it as version 1
			list.version = 1;
		} else {
			// We are updating an existing reservation list, increment the version number
			// and do a conditional write to prevent data loss from overwriting updates.
			let oldVersion = list.version;
			list.version++;
			conditionExpression = "version = :version";
			expressionAttributeValues = { ":version": oldVersion };
		}

		return this.put( list, conditionExpression, expressionAttributeValues )
	}

	public getDateRange( companyId: number, propertyId: number, startTime: Date, endTime: Date ): Promise<RestaurantReservationList[]> {
    	return new Promise<RestaurantReservationList[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and startTime BETWEEN :startTime AND :endTime";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
				":startTime": startTime.toISOString(),
				":endTime": endTime.toISOString(),
			};
			// console.log('Get HK logs: '+JSON.stringify(expressionAttributeValues,null,2));
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.startTimeIndex )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Returns a list of distinct room numbers that accessed the system between the given start and end date.
	 * @param companyId ID of the company
	 */
	public getActiveRoomNumbers( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<Set<string>> {
		return new Promise<Set<string>>( (resolve, reject) => {
			let roomNumbers: Set<string> = new Set();
			this.getDateRange( companyId, propertyId, startDate, endDate )
			.then( reservationLists => {
				reservationLists.forEach( reservationList => {
					reservationList.tables.forEach( table => {
						table.getReservations().forEach( reservation => roomNumbers.add( reservation.roomNumber ) );
					});
				});
				resolve( roomNumbers );
			})
			.catch( error => reject( error ) );
		});
	}
	

	// public getActivitySignUpsForDateRange( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<RestaurantReservationList[]> {
    // 	return new Promise<RestaurantReservationList[]>( (resolve, reject) => {
	// 		let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and ActivitySignUpDate BETWEEN :startDate AND :endDate";
	// 		let expressionAttributeValues = {
	// 			":companyId_propertyId": companyId + '_' + propertyId,
	// 			":startDate": startDate.toISOString(),
	// 			":endDate": endDate.toISOString(),
	// 		};
	// 		this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.ActivitySignUpDateIndex )
	// 		.then( rows => resolve( rows ) )
	// 		.catch( error => reject( error ) );
	// 	});
	// }

	/**
	 * Returns a list of reservation lists with available capacity for the given list of open periods.
	 * @param activeSeatings List of active open times.
	 * @param numberOfPeople Number of people that a reservation list must be able to accomodate in order to be returned, 0 returns all lists.
	 */
	// public getReservationListsForActiveSeatings( activeSeatings: ActiveSeatings, numberOfPeople: number ): Promise<RestaurantReservationList[]> {
	// 	return new Promise<RestaurantReservationList[]>( (resolve,reject) => {
	// 		// console.log( "Getting reservation lists for activeSeatings "+JSON.stringify( activeSeatings, null, 2 ) );
	// 		let filteredActiveSeatings = new ActiveSeatings();
	// 		let promises: Promise<RestaurantReservationList>[] = [];
	// 		let reservationLists: RestaurantReservationList[] = [];
	// 		for (let i=0; i<activeSeatings.restaurants.length; i++) {
	// 			let restaurant = activeSeatings.restaurants[i];
	// 			let seatingIndex = activeSeatings.seatingIndexes[i];
	// 			let seating = restaurant.seatings[ seatingIndex ];
	// 			for (let dayNumber=0; dayNumber<7; dayNumber++) {
	// 				promises.push( this.getReservationListForDayOfWeek( dayNumber, restaurant, seating ) )
	// 			}					
	// 		}
	// 		Promise.all( promises )
	// 		.then( resultArray => {
	// 			resultArray.forEach( reservationList => {
	// 				if (reservationList && (numberOfPeople == 0 || reservationList.getAvailableTableFor( numberOfPeople, seating ))) {
	// 					let dateTime = reservationList.startDateTime.getTime();
	// 					// Insert signUpList in result array in date/time order
	// 					for (let j=0; j<reservationLists.length; j++) {
	// 						if (dateTime < reservationLists[j].startDateTime.getTime()) {
	// 							// Insert the new sign up list before the one that comes later
	// 							reservationLists.splice( j, 0, reservationList );
	// 							reservationList = null;
	// 							break;
	// 						}
	// 					}
	// 					if (reservationList) {
	// 						// We didn't find any later lists so add the new one at the end
	// 						reservationLists.push( reservationList );
	// 					}
	// 				}
	// 			})
	// 			resolve( reservationLists );
	// 		})
	// 		.catch( error => reject( error ) );
	// 	})
	// }

	// /**
	//  * Returns the number of people that can be added to the sign up list for the given activity schedule and day of week.
	//  * @param dayOfWeek Day of weeek, only used for 'weekly' schedules, one-time schedules check the actual start date.
	//  * @param restaurant Activity whose capacity is being checked.
	//  * @param seating Schedule whose capacity is being checked.
	//  */
	// public getReservationListForDayOfWeek( dayOfWeek: number, restaurant: Restaurant, seating: SiteHours ): Promise<RestaurantReservationList> {
	// 	return new Promise<RestaurantReservationList>( (resolve,reject) => {
	// 		let sevenDaysFromNow = Date.now() + (7 * 24 * 60 * 60 * 1000);
	// 		if (!seating.getDayOfWeekFlag( dayOfWeek )) {
	// 			// This weekly activity is not scheduled on the given day of the week
	// 			// console.log( 'NO sign up list, not scheduled on dayOfWeek '+dayOfWeek+' activity '+activity.siteId+' schedule '+schedule.startDate.toISOString() );
	// 			resolve( null );
	// 		} else {
	// 			// Figure out date from day of week
	// 			let date = seating.startDate;

	// 			// Find the first future date with the given day of week and scheduled start time.
	// 			date = new Date();
	// 			date.setUTCHours( seating.startDate.getUTCHours() );
	// 			date.setUTCMinutes( seating.startDate.getUTCMinutes() );
	// 			date.setUTCSeconds( seating.startDate.getUTCSeconds() );
	// 			date.setUTCMilliseconds( seating.startDate.getUTCMilliseconds() );
	// 			while (date.getTime() < Date.now() || date.getUTCDay() != dayOfWeek) {
	// 				// Increment date/time by 24 hours until we get to the right day of the week in the future
	// 				date = new Date( date.getTime() + 24 * 60 * 60 * 1000 );
	// 			}

	// 			let key = new RestaurantReservationList( restaurant.companyId, restaurant.propertyId, restaurant.siteId, date );
	// 			new RestaurantReservationTable().get( key )
	// 			.then( reservationList => {
	// 				if (!reservationList) {
	// 					reservationList = key;
	// 				}
	// 				reservationList.capacity = seating.capacity;
	// 				// console.log( 'Got sign up list '+JSON.stringify(signUpList,null,2)+' for dayOfWeek '+dayOfWeek+' activity '+activity.siteId+' schedule '+schedule.startDate.toISOString() );
	// 				resolve( reservationList );
	// 			})
	// 			.catch( error => reject( error ) );
	// 		}
	// 	})
	// }

	/** 
	 * Returns a string of day names for the given list of sign up lists which can be passed to a speech synthesizer.
	 * @param reservationLists Array of sign up lists.
	 */
	// public getDayNames( reservationLists: RestaurantReservationList[] ): string {
	// 	let dayNumbers: number[] = [];
	// 	reservationLists.forEach( reservationList => {
	// 		let dayNumber = reservationList.startDateTime.getUTCDay();
	// 		if (dayNumbers.indexOf( dayNumber ) == -1) {
	// 			dayNumbers.push( dayNumber );
	// 		}
	// 	})

	// 	let dayNames = '';
	// 	let dayCount = dayNumbers.length;
	// 	let daysAdded = 0;
	// 	dayNumbers.forEach( dayNumber => {
	// 		if (dayNames.length > 0) {
	// 			if (daysAdded > 0 && daysAdded == (dayCount - 1)) {
	// 				dayNames += ', and ';
	// 			} else {
	// 				dayNames += ', ';
	// 			}
	// 		}
	// 		dayNames += Global.daysNames[dayNumber];
	// 		daysAdded++;
	// 	})
	// 	return dayNames;
	// }

	/** 
	 * Returns a string of times for the given list of sign up lists which can be passed to a speech synthesizer.
	 * @param reservationLists Array of reservation lists.
	 */
	// public getTimeList( reservationLists: RestaurantReservationList[] ): string {
	// 	let timeStrings = '';
	// 	let count = reservationLists.length;
	// 	let added = 0;
	// 	reservationLists.forEach( reservationList => {
	// 		if (timeStrings.length > 0) {
	// 			if (added > 0 && added == (count - 1)) {
	// 				timeStrings += ', and ';
	// 			} else {
	// 				timeStrings += ', ';
	// 			}
	// 		}
	// 		let timeString = Global.getUTCHourMinuteString( reservationList.startDateTime );
	// 		timeStrings += Global.get12HourTimeSpeech( timeString );
	// 		added++;
	// 	})
	// 	return timeStrings;
	// }

	/**
	 * Returns the reservation lists where the given date matches the reservation list date.
	 * @param date Date to check.
	 * @param persons Number of people that need to be accomodated (0 will return all lists for the date)
	 * @param reservationLists Array of reservation lists to check.
	 */
	// public getReservationListsForDate( date: Date, persons: number, reservationLists: RestaurantReservationList[] ): RestaurantReservationList[] {
	// 	let lists: RestaurantReservationList[] = [];
	// 	let dateString = Global.getUTCDateString( date );
	// 	for (let i=0; i<reservationLists.length; i++) {
	// 		let capacity = reservationLists[i].capacity - reservationLists[i].getGuestCount();
	// 		if (dateString === Global.getUTCDateString( reservationLists[i].startDateTime ) && capacity >= persons) {
	// 			lists.push( reservationLists[i] );
	// 		}
	// 	}
	// 	return lists;
	// }

	/**
	 * Returns the sign up lists where the given time matches the activity time (the date is ignored).
	 * @param time Time string with hours and minutes in 24-hour format (ie. 13:45).
	 */
	// public getSignUpListsForTime( timeString: string, numberOfPeople: number, signUpLists: RestaurantReservationList[] ): RestaurantReservationList[] {
	// 	let lists: RestaurantReservationList[] = [];
	// 	for (let i=0; i<signUpLists.length; i++) {
	// 		let capacity = signUpLists[i].capacity - signUpLists[i].getGuestCount();
	// 		if (timeString === Global.getUTCHourMinuteString( signUpLists[i].startDateTime ) && capacity >= numberOfPeople) {
	// 			lists.push( signUpLists[i] );
	// 		}
	// 	}
	// 	return lists;
	// }

}
