import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {CacheService} from "../../service/cache.service";
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from '../confirm/confirm.component';

/**
 * Company editing component.
 */
@Component({
    selector: 'input-text',
    templateUrl: './input-text.html',
	styleUrls: ['./input-text.css'],
})
export class InputTextComponent extends EditorDetail<String> implements OnInit, OnDestroy, AfterViewInit {
	public title: string = '';
	public label: string = '';
	private maxLength: string = '10';
	public placeholder: string = '';
	private isValueRequired = false;

	@ViewChild('inputTextModalButton') public buttonRef: ElementRef;

	@ViewChild('focus') public focusRef: ElementRef;

	constructor(
		private router: Router, 
        private userService: UserLoginService,
		private cache: CacheService,
		private changeDetectorRef: ChangeDetectorRef,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.buttonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initializeDialog() {
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	createNewDefaultRow(): String {
		return new String();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Return a deep copy of the given object. */
	clone( row: String ): String {
		return new String( row );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete "' + this.originalRow + '"?'
	}

	isValid(): boolean {
		this.errorMessage = null;
		if (this.isValueRequired && (this.editedRow === null || this.editedRow === undefined || this.editedRow.trim() === '')) {
			this.errorMessage = 'Please enter a value.'
			return false;
		}
		return true;
	}

	/**
	 * Configure the text input dialog.
	 * @param title Text to display in dialog title.
	 * @param label Text field label.
	 * @param maxLength Max characters to allow.
	 * @param placeholder Placeholder text.
	 * @param isValueRequired True if user must enter a value.
	 */
	configure( title: string, label: string, maxLength: string, placeholder: string, isValueRequired: boolean ): void {
		this.title = title;
		this.label = label;
		this.maxLength = maxLength;
		this.placeholder = placeholder;
		this.isValueRequired = isValueRequired;
	}
	
	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 */
	show( text: string, isInsert: boolean, title: string, label: string, maxLength: string, placeholder: string, isValueRequired: boolean, callback: (action: string, arg: any) => void ): void {
		this.configure( title, label, maxLength, placeholder, isValueRequired );
		super.showModalDialog( text, isInsert, callback );
	}
	
}