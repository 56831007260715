import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserLoginService } from "../../service/user-login.service";
import { LoggedInCallback } from "../../service/cognito.service";
import { CacheService } from "../../service/cache.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { Global } from "../../service/Global";

declare let amazon: any;
declare let OffAmazonPayments: any;

/**
Displays a dashboard for the SmartView product that tracks new and repeat guests.
*/
@Component({
    selector: 'checkout-confirm',
    templateUrl: './checkout-confirm.html',
    styleUrls: ['./checkout-confirm.css'],
})
export class CheckoutConfirmComponent implements OnInit, AfterViewInit {

	private errorMessage = null;

	@ViewChild('terms') private termsRef: ElementRef;
	
	/** Day of the month when customer will be billed in ordinal form. */
	private billingDayOfMonthString: string;

	/** Date when customer will be billed. */
	private firstBillingDateString: string;

	constructor(
		private router: Router,
		private _renderer2: Renderer2,
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
    ngOnDestroy() {
    }

	ngAfterViewInit() {
		// Don't set page title, we don't display title-nav on checkout
		// this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-cart-plus fa-fw"></i> Purchase Confirmation' );
	}

	initialize() {
		this.billingDayOfMonthString = Global.nth( this.cache.purchaseDetails.billingDayOfMonth );
		this.firstBillingDateString = this.cache.purchaseDetails.firstBillingDate.toLocaleDateString( undefined, { month: 'numeric', day: 'numeric', year: 'numeric', timeZone: 'UTC' } );
		let terms = this.cache.purchaseDetails.product.getTermsHtml( this.firstBillingDateString );
		this._renderer2.setProperty( this.termsRef.nativeElement, 'innerHTML', terms );
}

	onClickDocButton() {
		this.router.navigate(['/securehome/doc']);
	}

	onClickButton() {
		this.router.navigate(['/securehome/room-genie-main']);
	}

}
