import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { UserRegistrationService} from "../../service/user-registration.service";
import { UUIDService} from "../../service/uuid.service";
import { S3Service } from '../../service/s3.service';
import { CacheService} from "../../service/cache.service";
import { RekognitionService} from "../../service/rekognition.service";
import { PollyService} from "../../service/polly.service";
import { Global } from '../../service/Global';
import { Employee } from '../../service/Employee';
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';

/**
 * Component for taking an employee photo.
 */
@Component({
    selector: 'employee-photo',
    templateUrl: './employee-photo.html',
	styleUrls: ['./employee-photo.css'],
})
export class EmployeePhotoComponent implements OnInit, OnDestroy {

	@ViewChild(ImageCaptureComponent) private imageCaptureComponent: ImageCaptureComponent;
	
	@ViewChild('employeePhotoModalButton') private modalButtonRef: ElementRef; private modalButton: HTMLButtonElement;

	/** Employee being edited. */
	private editedRow: Employee;

	/** Function to call when modal dialog is closed. */
	private resolveModal: (row: Employee) => void;

	/** Function to call when modal dialog gets error. */
	private rejectModal: (error: Error) => void;
	
    constructor(
        public userRegistration: UserRegistrationService, 
        public userService: UserLoginService,
        public uuidService: UUIDService,
        public s3Service: S3Service,
        public cacheService: CacheService,
        public rekognitionService: RekognitionService,
        public pollyService: PollyService )
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
	}

	ngOnDestroy() {
		this.imageCaptureComponent.turnCameraOff();
	}

	initialize() {
		this.imageCaptureComponent.initialize()
		.catch( error => {
			Global.logError( 'Error initializing image capture.', error );
			alert( 'Problem initializing video.  Please try again.' );
		})
    }
    
    private onCancel() {
		this.closeDialog( false );
    }

	/**
	 * Capture an image from the video stream and put it in S3 in the company/site/camera folder.
	 * @param companyId ID of the company.
	 * @param siteId ID of the site where the image was taken.
	 * @param cameraId ID of the camera that took the image.
	 */
	captureImage() {
		let dataURL = null;
		this.imageCaptureComponent.captureImage()
		.then( capturedDataURL => {
			dataURL = capturedDataURL;
			let base64DataString = dataURL.replace(/^data:image\/\w+;base64,/, "");
			let objectKey = this.editedRow.photo;
			if (!objectKey) {
				// Calculate a new unique name for the employee photo, if they already had a photo, we would reuse the same name
				objectKey = this.cacheService.currentCompany.companyId+'/'+this.uuidService.UUID() + '.jpg'
			}
			this.s3Service.upload( base64DataString, Global.employeePhotoBucketName, objectKey )
			.then( () => {
				if (this.editedRow.photo != objectKey) {
					// Update edited employee photo field
					this.editedRow.photo = objectKey;
				}
				// Go back to the employee detail page and pass the uploaded object key
				let faceCollectionName = Global.getEmployeeFaceCollectionName( this.cacheService.currentCompany.companyId );
				this.rekognitionService.indexFacesInS3ImageFile( Global.employeePhotoBucketName, this.editedRow.photo, faceCollectionName )
				.then( data => {
					// successful response
					if (!data.FaceRecords || data.FaceRecords.length == 0) {
						alert('Sorry, I did not find a face in the picture.  Please stand in front of the camera so your face is clear in the picture and try again.');
						this.imageCaptureComponent.turnCameraOn();
					} else if (data.FaceRecords.length > 1) {
						alert('Sorry, I see more than one face in the picture.  Please make sure you are the only one in the picture and try again.');
						// Delete faces from face collection, since we don't know which one is the employee (don't wait for results since an error won't hurt)
						data.FaceRecords.forEach( face => {
							this.rekognitionService.deleteFaceFromCollection( face.Face.FaceId, faceCollectionName, true );
						});
						this.imageCaptureComponent.turnCameraOn();
					} else if (data.FaceRecords.length == 1) {
						//  We found a face so we can use the picture.
						let face = data.FaceRecords[0].Face;
						// console.log('found 1 face id for employee: '+face.FaceId+', confidence='+face.Confidence );
						if (this.editedRow.faceId) {
							// The employee already had a faceId, delete it from the collection since it won't map to the employee anymore
							this.rekognitionService.deleteFaceFromCollection( this.editedRow.faceId, faceCollectionName, true );
						}                    
						// this.cacheService.tookEmployeePhoto = true;
						this.editedRow.faceId = face.FaceId;
						this.closeDialog( true );
					}
				})
				.catch( err => {
					Global.logError( 'Error indexing faces in employee photo in S3.', err );
					alert( 'Sorry, we had a problem detecting faces in the picture.  Please try again.' );
					this.imageCaptureComponent.turnCameraOn();
				});
			})
			.catch( err => {
				Global.logError( 'Error uploading employee photo to S3.', err );
				alert( 'Sorry, we had a problem uploading the picture.  Please try again.' );
				this.imageCaptureComponent.turnCameraOn();
			});
		})
		.catch( err => {
			Global.logError( 'Error capturing image', err );
			alert( 'Sorry, we had a problem capturing the image.  Please try again.' );
			this.imageCaptureComponent.turnCameraOn();
		});
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	showModalDialog( row: Employee ): Promise<Employee> {
		this.editedRow = row;
		return new Promise<Employee>( (resolve,reject) => {
			if (!row) {
				reject( new Error( 'No edit object provided.' ) );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Show the dialog
				this.modalButton.click();
				this.imageCaptureComponent.turnCameraOn()
			}
		});
	}

	/**
	 * Closes the dialog window and returns the data if the user saved it or null if not.
	 * @param saved True if user saved data, false if user cancelled.
	 */
	private closeDialog( saved: boolean ) {
		this.imageCaptureComponent.turnCameraOff();
		// Hide the dialog
		this.modalButton.click();
		if (saved) {
			this.resolveModal( this.editedRow );
		} else {
			this.resolveModal( null );
		}
	}
	
}