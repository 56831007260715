/** Service that works with the AWS LEX speech processing functions. */
import { Injectable } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as Lex from "aws-sdk/clients/lexruntime";
import { AWSRequestor } from './AWSRequestor';

@Injectable()
export class LexService {

	constructor() {
	}

	postContent( botName: string, userId: string, inputStream: Buffer ): Promise<any> {
		return new Promise( (resolve, reject) => {
			let params = {
				botAlias: '$LATEST',
				botName: botName,
				contentType: 'audio/x-l16; sample-rate=16000',
				userId: userId,
				accept: 'audio/mpeg',
				inputStream: inputStream
			};
			new AWSRequestor().send( new Lex().postContent( params ) )
			.then( data => resolve( data ) )
			.catch( err => reject( err ) );
		})
	}

}