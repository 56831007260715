import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {Router} from "@angular/router";
import { Global } from '../service/Global';
import { version } from '../service/Version';
import { Product } from '../service/Product';

declare let AWS: any;
declare let AWSCognito: any;

@Component({
    selector: 'about',
    template: '<p>Hello and welcome!"</p>'
})
export class AboutComponent {

}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './landinghome.html',
    styleUrls: ['./landinghome.css'],
})
export class HomeLandingComponent {
	private softwareVersion = version;
	private currentYear = new Date().getFullYear();
    constructor() {}
}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './home.html',
    styleUrls: ['./home.css'],
})
export class HomeComponent implements OnInit {

	// @ViewChild('collapse') private collapseRef: ElementRef; private collapseElement: HTMLDivElement;

	private roomGenieAdvertiserProductCode = Product.ROOM_GENIE_ADVERTISER;

    constructor( private router: Router ) {}

    ngOnInit() {
		// Global.log( 'HomeComponent init' );
	}
	
	ngAfterViewInit() {
		// this.collapseElement = this.collapseRef.nativeElement;
	}

	// collapseMenu() {
		// this.collapseElement.collapse('hide');

		// $('#collapseall').click(function () {
		// 	$('.panel-collapse').collapse('hide');
		// 	return false;
		// });
	// }

}


