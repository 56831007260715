import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {UserRegistrationService} from "../../../service/user-registration.service";

export class RegistrationUser {
    name: string;
    email: string;
	password: string;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './registration.html'
})
export class RegisterComponent {
    registrationUser: RegistrationUser;
    router: Router;
	errorMessage: string;
	confirmPassword: string;

    constructor(public userRegistration: UserRegistrationService, router: Router) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new RegistrationUser();
        this.errorMessage = null;
    }

    onRegister() {
		this.errorMessage = null;
		if ((!this.registrationUser.name) || this.registrationUser.name.length == 0) {
			this.errorMessage = 'Please enter your name.'
		} else if ((!this.registrationUser.email) || this.registrationUser.email.length == 0) {
			this.errorMessage = 'Please enter your email address.'
		} else if ((!this.registrationUser.password) || this.registrationUser.password.length == 0) {
			this.errorMessage = 'Please enter a password.'
		} else if (this.registrationUser.password != this.confirmPassword) {
			this.errorMessage = 'The password and confirmation password don\'t match.  Please re-enter them.'
		} else {
			this.userRegistration.registerUser( this.registrationUser.name, this.registrationUser.email, this.registrationUser.password )
			.then( result => {
				//move to the next step
				// console.log("redirecting");
				this.router.navigate(['/home/confirmRegistration', result.user.username]);
			})
			.catch( err => {
				this.errorMessage = err.message;
			});
		}
    }

}