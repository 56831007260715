import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { Global } from './Global';
import { AWSRequestor } from './AWSRequestor';

export class S3Service {

    constructor() {
    }

	/**
	 * Get a file from S3.
	 * @param bucketName Bucket that contains file.
	 * @param objectKey Object key.
	 */
	get( bucketName: string, objectKey: string ): Promise<any> {
		return new Promise<any>( (resolve, reject) => {
			let params = {
				Bucket: bucketName, 
				Key: objectKey,
			};
			new AWSRequestor().send( new S3().getObject( params ) )
			.then( data => resolve( data ) )
			.catch( err => reject( err ) );
		})
	}

	/**
	 * Load an image from S3 and convert it into a data URI.
	 * @param bucketName Bucket that contains image.
	 * @param objectKey S3 object key for image.
	 */
	public getImage( bucketName: string, objectKey: string ): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			// Global.log( 'S3Service.getImage: ' + bucketName + ':' + objectKey );
			this.get( bucketName, objectKey )
			.then( result => {
				// Global.log( 'S3Service.getImage succeeded' );
				// Encode image data from S3 into a data URI to be used as img element src attribute.
				let imageData = '';
				for (let i=0; i<result.Body.length; i++) {
					imageData += String.fromCharCode( result.Body[i] );
				}
				imageData = this.btoa(imageData).replace(/.{76}(?=.)/g,'$&');
				let dataURI = 'data:image/jpeg;base64,' + imageData;
				resolve( dataURI );
			})
			.catch( err => {
				err.message = 'Error getting image from S3.  ' + err.message;
				reject( err );
			});
		});
	}

	private btoa(str) {
		let buffer;
		if (str instanceof Buffer) {
			buffer = str;
		} else {
			buffer = new Buffer(str.toString(), 'binary');
		}
		return buffer.toString('base64');
	}
	
	/**
	 * Update data into an S3 object.
	 * @param base64DataString Data to save.
	 * @param bucketName Name of the bucket.
	 * @param objectKey Key of the S3 object.
	 * @returns Promise that resolved to the object key.
	 */
	upload( base64DataString: string, bucketName: string, objectKey: string ): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			let params = {
				// Body: new Buffer( base64DataString, 'base64' ),
				Body: Global.base64ToArrayBuffer( base64DataString ),
				Bucket: bucketName, 
				Key: objectKey,
			};
			new AWSRequestor().send( new S3().upload( params ) )
			.then( data => resolve( objectKey ) )
			.catch( err => reject( err ) );
		})
	}

	/**
	 * Update data into an S3 object.
	 * @param base64DataString Data to save.
	 * @param bucketName Name of the bucket.
	 * @param objectKey Key of the S3 object.
	 * @returns Promise that resolved to the object key.
	 */
	uploadFile( file: File, bucketName: string, objectKey: string ): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			let params = {
				Body: file,
				Bucket: bucketName, 
				Key: objectKey,
			};
			Global.log('uploadFile '+JSON.stringify(params,null,2));
			new AWSRequestor().send( new S3().upload( params ) )
			.then( data => resolve( objectKey ) )
			.catch( err => reject( err ) );
		})
	}

	/*
s3.listObjectsV2(params, function(err, data) {
  if (err) console.log(err, err.stack); // an error occurred
  else     console.log(data);           // successful response
});
	*/

	/**
	 * Returns a list of all the files in the given bucket.
	 * @param bucketName Name of the bucket.
	 * @param prefix Prefix of the files to include.
	 */
	listFiles( bucketName: string, prefix: string ): Promise<string[]> {
		return new Promise<string[]>( (resolve, reject) => {
			let keys: string[] = [];
			this.listFilesRecursive( bucketName, prefix, keys )
			.then( keys => resolve( keys ))
			.catch( err => reject( err ) );
		});
	}

	/**
	 * Returns a list of all the files in the given bucket.
	 * Calls S3 recursively if necessary since each call can only return 1000 keys.
	 * @param bucketName Name of the bucket.
	 * @param prefix Prefix of the files to include.
	 * @param keys Array of file keys we have already gotten from S3 in previous recursive calls.
	 * @param continuationToken Token to use to get the next batch of file keys.
	 */
	private listFilesRecursive( bucketName: string, prefix: string, keys: string[], continuationToken: string = null ): Promise<string[]> {
		return new Promise<string[]>( (resolve, reject) => {
			let params: any = {
				Bucket: bucketName,
				// Delimiter: 'STRING_VALUE',
				// EncodingType: url,
				// FetchOwner: true || false,
				// MaxKeys: 0,
				Prefix: prefix,
				// RequestPayer: requester,
				// StartAfter: 'STRING_VALUE'
			};
			if (continuationToken) {
				params.ContinuationToken = continuationToken;
			}
			new AWSRequestor().send( new S3().listObjectsV2( params ) )
			.then( data => {
				data.Contents.forEach( file => {
					if (file.Key.length > prefix.length) {
						keys.push( file.Key.substring( prefix.length ) );
					}
				})
				if (data.IsTruncated) {
					this.listFilesRecursive( bucketName, prefix, keys, data.NextContinuationToken )
					.then( data => resolve( keys ))
					.catch( err => reject( err ) );
				} else {
					resolve( keys );
				}
			 })
			.catch( err => reject( err ) );
		})
	}

}