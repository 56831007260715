import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { VolumePrice } from './VolumePrice';

/* Bookcliff Software Products */
export class Product extends TableDocumentObject {

	/** System product codes (use plus sign to denote a subscription plus other stuff) */
	public static ROOM_GENIE = 'roomGenie';
	public static ROOM_GENIE_PLUS_ECHO_DOT = 'roomGenie+EchoDot';
	public static ROOM_GENIE_ADVERTISER = 'roomGenieAdvertiser';
	public static ANALYTICS = 'analytics';
	public static CONNECT = 'connect';
	public static RECOGNIZE = 'recognize';
	public static LINE_TIMER = 'lineTimer';
	public static CHECK_IN = 'checkIn';
	public static TIME_CLOCK = 'timeClock';
	public static ALERT = 'alert';
	public static WHATS_HAPPENING = 'whatsHappening';

	/**
	 * Construct object.
	 * @param productCode Unique code that identifies product. 
	 * @param name Product name.
	 * @param logo Product logo file path (starts with /assets/img/);
	 * @param purchaseCents Number of cents to be billed when purchased.
	 * @param needShippingAddress True if we need a shipping address for this product.
	 * @param monthlyVolumePrices Volume discount table with number of cents to bill monthly based on units billed.
	 * @param firstMonthFree True if first month of product usage is free.
	 * @param terms Terms displayed to the user when checking out.
	 * @param createAccountDescription Prompt displayed on create account page.
	 * @param productHomePage Path to product home page
	 * @param unitDescription Description of billing unit
	 */
	public constructor( 
		public productCode: string = null, 
		public name: string = null, 
		public logo: string = null,
		public purchaseCents: number = 0, 
		public needShippingAddress: boolean = false,
		public monthlyVolumePrices: VolumePrice[] = [],
		public firstMonthFree: boolean = true,
		public terms: string = null,
		public createAccountDescription: string = null,
		public productHomePage: string = null,
		public unitDescription: string = null,
	) { super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): Product {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.monthlyVolumePrices = null;
		if (item['monthlyVolumePrices']) {
			this.monthlyVolumePrices = [];
			item['monthlyVolumePrices'].forEach( childItem => this.monthlyVolumePrices.push( new VolumePrice().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['monthlyVolumePrices'] = null;
		if (this.monthlyVolumePrices) {
			item['monthlyVolumePrices'] = [];
			this.monthlyVolumePrices.forEach( childItem => item['monthlyVolumePrices'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
        return {
            'productCode': this.productCode
        }
	}

	/**
	 * Returns the product with the given productCode from the list of products or null if not found.
	 * @param productCode Product code to find.
	 * @param products List of products to search.
	 */
	public static getProduct( productCode: string, products: Product[] ): Product {
		let product = null;
		if (products) {
			for (let i=0; i<products.length; i++) {
				if (products[i].productCode === productCode) {
					product = products[i];
					break;
				}
			}
		}
		if (product == null) {
			Global.log('getProduct failed on '+productCode+' with products '+JSON.stringify(products,null,2));
		}
		return product;
	}

	/**
	 * Returns the purchase terms html after replacing the following terms:
	 * {{firstBillingDate}} with the given firstBillingDateString
	 * {{monthlyUnitPrice}} with the first montly price in the volume discount table for this product.
	 * {{purchasePrice}} with the purchase price to be paid in the subscription date.
	 * @param firstBillingDateString String representing first billing date (typically one month from subscription date)
	 */
	public getTermsHtml( firstBillingDateString: string ): string {
		let html = this.terms ? this.terms : '';
		html = html.replace( /{{firstBillingDate}}/g, firstBillingDateString );
		html = html.replace( /{{monthlyUnitPrice}}/g, Global.getCurrencyStringForCents( this.monthlyVolumePrices[0].priceCents ) );
		html = html.replace( /{{purchasePrice}}/g, Global.getCurrencyStringForCents( this.purchaseCents ) );
		return html;
	}
}