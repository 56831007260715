import { TableDocumentObject } from './TableHandler'
import { Role } from './Role';
import { Subscription } from './Subscription';

/**
 * Properties stored about each identified company.
 */
export class Company extends TableDocumentObject {

	/**
	 * Properties stored about each identified company.
	 * @param companyId Unique ID of company (partition key)
	 * @param name Name of company.
	 * @param contactEmail Email address of primary contact at company
	 * @param newVisitMinutes Number of minutes since person was last detected before it is considered a new visit.
	 * @param trackGuestsBySite True if there is a collection of guests for each site, false if there is one for the entire company.
	 * @param faceDetectScale Number > 0 and <= 1 how much to scale down an image before detecting faces (to improve performance)
	 * @param motionPercentThreshold % of pixels that must change before it is considered motion.
	 * @param facePercentThreshold % of image that must be a detected face before we will upload for face recognition.
	 * @param lastUsedPropertyId Last property ID used for the company.
	 * @param subscriptions List of products to which company is subscribed and can be billed.
	 * @param roles List of roles that can be assigned to users.
	 */
	constructor(
		public companyId: number = null,
		public name: string = null,
		public contactEmail: string = null,
		public newVisitMinutes: number = 120,
		public trackGuestsBySite = true,
		public faceDetectScale: number = 0.5,
		public faceDetectInitialScale: number = 1.5,
		public faceDetectStepSize: number = 2,
		public faceDetectEdgeDensity: number = 0.3,
		public motionPercentThreshold = 12,
		public facePercentThreshold = 20,
		public lastUsedPropertyId: number = 0,
		public subscriptions: Subscription[] = [],
		public roles: Role[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Company {
		this.copyPropertiesFromObject( item );
		// Copy arrays
		this.subscriptions = [];
		if (item['subscriptions']) {
			item['subscriptions'].forEach(subscription => this.subscriptions.push( new Subscription().fromDataItem( subscription ) ) );
		}
		this.roles = [];
		if (item['roles']) {
			item['roles'].forEach(row => this.roles.push( new Role().fromDataItem( row ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy arrays
		item['subscriptions'] = [];
		if (this.subscriptions) {
			this.subscriptions.forEach( subscription => item['subscriptions'].push( subscription.toDataItem() ) );
		}
		item['roles'] = [];
		if (this.roles) {
			this.roles.forEach( row => item['roles'].push( row.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            'companyId': this.companyId
        }
    }

	/**
	 * Returns the ID of the collection of guest faces.
	 * @param siteId ID of the site.
	 */
	public getGuestFaceCollectionId( siteId: number ) {
		if (this.trackGuestsBySite) {
			return this.companyId + '.' + siteId + '.1';
		} else {
			return this.companyId + '.1';
		}
	}

	public getActiveSubscriptionIndex( productCode: string ): number {
		// console.log('getActiveSubscriptionIndex for '+productCode);
		let index = -1;
		if (this.subscriptions) {
			for (let i=0; i<this.subscriptions.length; i++) {
				let subscription = this.subscriptions[i];
				// console.log( 'checking subscription '+JSON.stringify(subscription,null,2));
				if (subscription.productCode === productCode && subscription.isActive()) {
					index = i;
					break;
				}
			}
		}
		// console.log( 'getActiveSubscriptionIndex( "' + productCode + '" ) returned ' + index );
		return index;
	}

	/**
	 * Returns true if the company has an active subscription to the given product.
	 * @param productCodes Product code to check.
	 */
	public isSubscriptionActive( productCode: string ): boolean {
		return this.getActiveSubscriptionIndex( productCode ) != -1;
	}

	/**
	 * Returns true if the company has an active subscription to any of the given products.
	 * @param productCodes List of product codes.
	 */
	public hasOneOfTheseProducts( productCodes: string[] ) {
		let hasProduct = false;
		// User has permission, see if any of the product subscriptions are active
		if (productCodes) {
			for (let i=0; i<productCodes.length; i++) {
				if (this.isSubscriptionActive( productCodes[i] )) {
					hasProduct = true;
					break;
				}
			}
		}
		return hasProduct;
	}

	/** Return map of names keyed by ID. */
	public static buildNameMap( companies: Company[] ): Map<number,string> {
		// Build map of names keyed by ID
		let map = new Map<number,string>();
		if (companies) {
			companies.forEach( row => {
				map.set( row.companyId, row.name );
			});
		}
		return map;
	}

	public getRoleIndex( roleId: number ): number {
		// console.log('getActiveSubscriptionIndex for '+productCode);
		let index = -1;
		if (this.roles) {
			for (let i=0; i<this.roles.length; i++) {
				let role = this.roles[i];
				// console.log( 'checking subscription '+JSON.stringify(subscription,null,2));
				if (role.roleId === roleId) {
					index = i;
					break;
				}
			}
		}
		return index;
	}

	public getRole( roleId: number ): Role {
		let role = null;
		let index = this.getRoleIndex( roleId );
		if (index != -1) {
			role = this.roles[index];
		}
		return role;
	} 

	public getExistingRole( roleId: number ): Role {
		let role = null;
		let index = this.getRoleIndex( roleId );
		if (index == -1) {
			throw new Error( "Error getting role with ID "+roleId+".  Invalid role ID." );
		}
		return this.roles[index];
	} 
	
	/** @returns List of roles for the given role ID's.  If an ID is invalid, the role at that index will be null. */
	public loadRoles( roleIds: number[] ): Role[] {
		let roles: Role[] = [];
		if (roleIds) {
			roleIds.forEach( roleId => {
				if (roleId == -1) {
					roles.push( new Role().setSysAdmin() );
				} else if (roleId == 0) {
					roles.push( new Role().setCompanyAdmin() );
				} else {
					roles.push( this.getRole( roleId ) )
				}
			});
		}
		return roles;
	}

	/** @returns Role with combined permissions of all the roles with the given role ID's. */
	getPermissions( roleIds: number[] ): Role {
		let roles = this.loadRoles( roleIds )
		let combinedRole = new Role().combineWithRoles( roles );
		// console.log( 'Got user permissions: '+JSON.stringify( combinedRole, null, 2 ) );
		return combinedRole;
	}

}
