import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { PropertyAdvertiserComponent } from "../property-advertiser/property-advertiser.component";
import { AdCategory } from "../../service/AdCategory";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";
import { PropertyAdvertiser } from '../../service/PropertyAdvertiser';
import { SiteName } from "../../service/SiteName";
import { DeletePropertyAdvertiserCmd } from "../../command/DeletePropertyAdvertiserCmd";
import { PutPropertyAdvertiserCmd } from "../../command/PutPropertyAdvertiserCmd";
import { GetPropertyAdvertisersCmd } from "../../command/GetPropertyAdvertisersCmd";
import { GetAdCategoriesCmd } from "../../command/GetAdCategoriesCmd";
import { GetAvailableAdvertisingSiteNamesCmd } from "../../command/GetAvailableAdvertisingSiteNamesCmd";

class YearMonth {
	constructor(
		public year: number,
		public month: number
	) {}

	getKey() {
		return this.year+','+this.month;
	}

	getDisplayString() {
		return Global.monthNames[this.month-1] + ' ' + this.year;
	}

}


@Component({
    selector: 'property-advertisers',
    templateUrl: './property-advertisers.html',
	styleUrls: ['./property-advertisers.css'],
})
export class PropertyAdvertisersComponent extends Editor<PropertyAdvertiser> implements OnInit, AfterViewInit {

	/** Currently selected property. */
	private selectedPropertyId;

	private adCategories: AdCategory[] = [];

	private selectedAdCategoryId;

	// Timezone for the property
	private timeZone;

	private siteNames: SiteName[] = [];

	/** Date/time of the currently selected sign up list. */
	private activityDateTimeMillis: string;

	@ViewChild(PropertyAdvertiserComponent) private detailComponent: PropertyAdvertiserComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Property Advertisers' );
	}

	initialize() {
		// Set selected property to the first one in the list
		this.selectedPropertyId = this.cache.getFirstPropertyId();
		new GetAdCategoriesCmd().do( this.cache.currentCompany.companyId )
		.then( adCategories => {
			// console.log( 'Got activityTypes '+JSON.stringify(activityTypes,null,2));
			this.adCategories = adCategories;
			this.selectedAdCategoryId = this.adCategories.length > 0 ? this.adCategories[0].adCategoryId.toString() : null;
			this.switchProperty( null );
		})
		.catch( error => {
			Global.logError('Error loading ad categories.', error );
			this.errorMessage = 'Sorry, we had a problem loading the ad categories.  Please try again.';
		})
}

	private switchProperty( event ) {
		this.selectedPropertyId = Number.parseInt( this.selectedPropertyId );
		try {
			this.timeZone = this.cache.getTimeZone( this.selectedPropertyId );
			this.detailComponent.timeZone = this.timeZone;
			// let localTime = Global.adjustDateByTimeZone( new Date(), this.timeZone );
			this.switchAdCategory( null );
		} catch( error ) { this.handleError( error, false ); }
	}

	private switchAdCategory( event ) {
		try {
			this.selectedAdCategoryId = Number.parseInt( this.selectedAdCategoryId );
			this.detectChanges();
			this.loadRows();
		} catch( error ) { this.handleError( error, false ); }
	}

	createNewRowToEdit(): PropertyAdvertiser {
		return new PropertyAdvertiser( this.cache.currentCompany.companyId, this.selectedPropertyId, this.selectedAdCategoryId );
	}

	getDetailComponent(): EditorDetail<PropertyAdvertiser> {
		return this.detailComponent;
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	getRowLoader(): Promise<PropertyAdvertiser[]> {
		return new Promise<PropertyAdvertiser[]>( (resolve,reject) => {
			try {
				new GetPropertyAdvertisersCmd().do( this.cache.currentCompany.companyId, this.selectedPropertyId, this.selectedAdCategoryId )
				.then( advertisers => {
					new GetAvailableAdvertisingSiteNamesCmd().do( this.cache.currentCompany.companyId, this.selectedPropertyId, this.selectedAdCategoryId )
					.then( siteNames => {
						this.siteNames = siteNames;

						// Sort advertisers by the type of their ad to make it easy to see
						// how many ads of each type we have
						advertisers.sort( (a, b) => {
							let siteNameA = this.getSiteName( a );
							let siteNameB = this.getSiteName( b );
							let stringA = siteNameA && siteNameA.subcategoryName ? siteNameA.subcategoryName.toLowerCase() : '';
							let stringB = siteNameB && siteNameB.subcategoryName ? siteNameB.subcategoryName.toLowerCase() : '';
							// Add site name to subcategory name before sorting
							stringA += ',' + (siteNameA ? siteNameA.siteName : '');
							stringB += ',' + (siteNameB ? siteNameB.siteName : '');
							return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
						});
	
						this.detailComponent.siteNames = siteNames;
						this.changeDetectorRef.detectChanges();
						resolve( advertisers );
					})
					.catch( error => {
						Global.logError('Error loading data.', error );
						this.errorMessage = 'Sorry, we had a problem loading the available advertising site names.  Please try again.';
						reject( error );
					})
				})
				.catch( error => {
					Global.logError('Error loading data.', error );
					this.errorMessage = 'Sorry, we had a problem loading the property advertisers.  Please try again.';
					reject( error );
				})
			} catch( error ) {
				reject( error );
			}
		});
	}

	prepareRowToInsert( newRow: PropertyAdvertiser ) {
	}

	getRowInserter( row: PropertyAdvertiser ): Promise<PropertyAdvertiser> {
		return new Promise<PropertyAdvertiser>( (resolve,reject) => {
			new PutPropertyAdvertiserCmd().do( row )
			.then( savedRow => resolve( row ) )
			.catch( error => reject( error ) );
		})
	}

	getRowUpdater( index: number, row: PropertyAdvertiser ): Promise<PropertyAdvertiser> {
		return new Promise<PropertyAdvertiser>( (resolve,reject) => {
			new PutPropertyAdvertiserCmd().do( row )
			.then( savedRow => resolve( row ) )
			.catch( error => reject( error ) );
		})
	}

	getRowDeleter( index: number, row: PropertyAdvertiser ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			new DeletePropertyAdvertiserCmd().do( row )
			.then( savedRow => resolve() )
			.catch( error => reject( error ) );
		})
	}

	getSiteName( propertyAdvertiser: PropertyAdvertiser ): SiteName {
		let found: SiteName = null;
		for (let i=0; i<this.siteNames.length; i++) {
			let siteName = this.siteNames[i];
			if (siteName.companyId == propertyAdvertiser.adCompanyId && siteName.propertyId == propertyAdvertiser.adPropertyId && siteName.siteId == propertyAdvertiser.adSiteId) {
				found = siteName;
				break;
			}
		}
		return found;
	}

	getSubcategory( propertyAdvertiser: PropertyAdvertiser ): string {
		let subcategoryName = 'Other';
		let siteName = this.getSiteName( propertyAdvertiser );
		if (siteName && siteName.subcategoryName) {
			subcategoryName = siteName.subcategoryName;
		}
		return subcategoryName;
	}

	getSiteIdAndName( propertyAdvertiser: PropertyAdvertiser ): string {
		let s: string = null;
		let siteName = this.getSiteName( propertyAdvertiser );
		if (siteName != null) {
			// s = siteName.siteId + ' - ' + siteName.siteName;
			s = siteName.siteName;
		} else {
			s = '' + propertyAdvertiser.adSiteId;
		}
		let subcategoryName = 'Other';
		if (siteName && siteName.subcategoryName) {
			subcategoryName = siteName.subcategoryName;
		}
		// s = subcategoryName+':   '+s;
		return s;
	}

	getAddress( propertyAdvertiser: PropertyAdvertiser ): string {
		let s: string = '';
		let siteName = this.getSiteName( propertyAdvertiser );
		if (siteName != null) {
			s = siteName.getAddressString();
		}
		return s;
	}

	getCompanyName( propertyAdvertiser: PropertyAdvertiser ): string {
		// let s = 'Company: ' + propertyAdvertiser.companyId;
		let s = 'Company: ';
		let siteName = this.getSiteName( propertyAdvertiser );
		if (siteName != null) {
			// s += ' - ' + siteName.companyName;
			s += siteName.companyName;
		}
		return s;
	}

	getPropertyName( propertyAdvertiser: PropertyAdvertiser ): string {
		let s = 'Property: ' + propertyAdvertiser.propertyId;
		let siteName = this.getSiteName( propertyAdvertiser );
		if (siteName != null) {
			s += ' - ' + siteName.propertyName;
		}
		return s;
	}

}
