import { Command } from "./Command";
import { Global } from "../service/Global";
import { AdPlayByMonth } from "../service/AdPlayByMonth";

export class GetAdPlaysByMonthForSiteCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number, propertyId: number, siteId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number ): Promise<AdPlayByMonth[]> {
		return new Promise<AdPlayByMonth[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId, "siteId": siteId, "fromYear": fromYear, "fromMonth": fromMonth, "toYear": toYear, "toMonth": toMonth } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: AdPlayByMonth[] = [];
				results.data.forEach( layout => rows.push( new AdPlayByMonth().fromDataItem( layout ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
