import { TableDocumentObject } from './TableHandler'

/**
 * Details of a person's visit.
 */
export class Visit extends TableDocumentObject {

	/**
	 * 
	 * @param companyId ID of company (partition key = companyId_siteId)
	 * @param siteId ID of site (partition key = companyId_siteId)
	 * @param personId ID of the person detected. (sort key = personId_startTime)
	 * @param faceId ID of the face record used to identify the person.
	 * @param startTime Date/time the visit started. (sort key = personId_startTime)
	 * @param endTime Date/time of the visit ended (updated every time the person is detected during the same visit)
	 * @param ageRangeLow Low age range value
	 * @param ageRangeHigh High age range value
	 * @param beard Confidence % that person had beard
	 * @param happy Confidence % that person was happy
	 * @param sad Confidence % that person was sad
	 * @param angry Confidence % that person was angry
	 * @param confused Confidence % that person was confused
	 * @param disgusted Confidence % that person was disgusted
	 * @param surprised Confidence % that person was surprised
	 * @param calm Confidence % that person was calm
	 * @param unknownEmotion Confidence % that person's emotions are unknown
	 * @param eyeglasses Confidence % that person was wearing eyeglasses
	 * @param male Confidence % that person was male
	 * @param female Confidence % that person was female
	 * @param mustache Confidence % that person had a mustache
	 * @param smile Confidence % that person was smiling
	 * @param sunglasses Confidence % that person was wearing sunglasses
	 * @param confidence Confidence level that the bounding box contains a face (and not a different object such as a tree).
	 * @param boxTop Top coordinate of the bounding box as a ratio of overall image height.
	 * @param boxLeft Left coordinate of the bounding box as a ratio of overall image width.
	 * @param boxWidth Width of the bounding box as a ratio of the overall image width.
	 * @param boxHeight Height of the bounding box as a ratio of the overall image height.
	 * @param imageName S3 object key.
	 */
	constructor(
		public companyId: number = null,
		public siteId: number = 0,
		public personId: string = null,
		public faceId: string = null,
		public startTime: Date = new Date(),
		public endTime: Date = new Date(),
		public ageRangeLow: number = 0,
		public ageRangeHigh: number = 0,
		public beard: number = 0,
		public happy: number = 0,
		public sad: number = 0,
		public angry: number = 0,
		public confused: number = 0,
		public disgusted: number = 0,
		public surprised: number = 0,
		public calm: number = 0,
		public unknownEmotion: number = 0,
		public eyeglasses: number = 0,
		public male: number = 0,
		public female: number = 0,
		public mustache: number = 0,
		public smile: number = 0,
		public sunglasses: number = 0,
		public confidence: number = null,
		public boxTop: number = null,
		public boxLeft: number = null,
		public boxWidth: number = null,
		public boxHeight: number = null,
		public imageName: string = null
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Visit {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_siteId'] = this.companyId + '_' + this.siteId;
		item['personId_startTime'] = this.personId + '_' + this.startTime.toISOString();
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "personId_startTime": this.personId + '_' + this.startTime.toISOString()
        };
    }

}
