import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Global } from '../../service/Global';
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';

/** Component for selecting the camera and camera ID to use to capture photos when motion is detected. */
@Component({
    selector: 'camera-settings',
    templateUrl: './camera-settings.html',
	styleUrls: ['./camera-settings.css'],
})
export class CameraSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

	errorMessage: string = null;
	
	@ViewChild('modalButton') public modalButtonRef: ElementRef;
	private modalButton: HTMLButtonElement;

	@ViewChild(ImageCaptureComponent) private imageCaptureComponent: ImageCaptureComponent;

	/** List of cameras on the device. */
	private cameras: any[] = [ { label: 'None', deviceId: 'none' } ];

	/** Camera ID to use when sending photos to the cloud. */
	private cameraId = 1;

	/** Old camera ID used to restore original value if user clicks Cancel after making changes. */
	private oldCameraId: number;

	/** Flag for whether we are testing face detection or not. */
	public testFaceDetection = false;
	
	/** ID of the camera on the device to use (front or rear). */
	private deviceId = 'none';

	/** Old device ID used to restore original value if user clicks Cancel after making changes. */
	private oldDeviceId: string;

	constructor() {}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
        // console.log('CameraSettingsComponent.ngOnDestroy()');
	}

	ngAfterViewInit() {
		// console.log('CameraSettingsComponent.ngAfterViewInit' );
		this.modalButton = this.modalButtonRef.nativeElement;
		this.imageCaptureComponent.initialize()
		.then( supported => {
			if (supported) {
				let cameras = this.imageCaptureComponent.getCameras();
				cameras.forEach( camera => {
					this.cameras.push( camera );
				});
			}
		})
	}

	private resolveModal: Function;
	private rejectModal: Function;

	showModalDialog(): Promise<boolean> {
		return new Promise<boolean>( (resolve,reject) => {
			if (!this.imageCaptureComponent.supportsGetUserMedia()) {
				alert('This device does not support video motion detection.' );
				resolve( false );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Save initial values for fields so we can restore them if user cancels.
				this.oldCameraId = this.cameraId;
				this.oldDeviceId = this.deviceId;
				this.modalButton.click();
				if (this.deviceId != 'none') {
					// Turn camera on after dialog is displayed
					setTimeout( () => 
						this.imageCaptureComponent.setCurrentCameraIndex( this.imageCaptureComponent.getCameraIndex( this.deviceId ) ),
						100
					);
				}
			}
		});
	}
	
	changeSelectedCamera( event )  {
		// console.log('changeSelectedCamera to '+this.deviceId );
		if (this.deviceId === 'none') {
			this.imageCaptureComponent.turnCameraOff();
		} else {
			this.imageCaptureComponent.setCurrentCameraIndex( this.imageCaptureComponent.getCameraIndex( this.deviceId ) );
		}
	}

	getSelectedDeviceId(): string {
		return this.deviceId;
	}

	getCameraIndex(): number {
		return this.imageCaptureComponent.getCameraIndex( this.deviceId );
	}
	
	getCameraId(): number {
		return this.cameraId;
	}

	/**
	 * Close the dialog and if caller specified a function to be called when dialog is closed then call it
	 * @param saved True if record was saved, false if user clicked Cancel to close without saving.
	 */
	closeDialog( saved: boolean ) {
		// Hide the dialog
		this.modalButton.click();
		if (this.deviceId != 'none') {
			this.imageCaptureComponent.turnCameraOff();
		}
		if (!saved) {
			// Restore initial values for fields since we aren't saving changes
			this.deviceId = this.oldDeviceId;
			this.cameraId = this.oldCameraId;
		}
		this.resolveModal( saved );
	}

	/** Close the dialog without saving changes. */
	onCancel() {
		this.closeDialog( false );
	}

	/** Close dialog and save changes. */
    onSave() {
		this.closeDialog( true );
    }

}