import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { SiteHours } from "./SiteHours";
import { Ad } from "./Ad";
import { Property } from "./Property";

/** Details about each site in a company. */
export class Site extends TableDocumentObject {

	/**
	 * @param companyId ID of site (partition key)
	 * @param propertyId ID of the property the site is part of.
	 * @param siteId ID of site (sort key)
	 * @param name Name of the site.
	 * @param address1 Street address of property
	 * @param address2 Additional address info like suite number
	 * @param city City where property is located
	 * @param state State where property is located
	 * @param postalCode Postal code of property
	 * @param country Country where property is located
	 * @param timeZone Time zone the site is in.
	 * @param latitude GPS latitude coordinates from Google maps (right click location, select What's there)
	 * @param longitude GPS longitude coordinates from Google maps (right click location, select What's there)
	 * @param isWhatsHappeningSite True if this site should be included in what's happening advertising
	 * @param synonyms Alternate names customers might use when referring to the site.
	 * @param hours Hours when site is open for business
	 * @param ads List of advertisements for site
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public siteId: number = null,
		public name: string = null,
		public address1: string = null,
		public address2: string = null,
		public city: string = null,
		public state: string = null,
		public postalCode: string = null,
		public country: string = null,
		public timeZone: string = 'America/New_York',
		public latitude: number = null,
		public longitude: number = null,
		public isWhatsHappeningSite: boolean = null,
		public synonyms: string[] = [],
		public hours: SiteHours[] = [],
		public ads: Ad[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Site {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.hours = [];
		if (item['hours']) {
			item['hours'].forEach( childItem => this.hours.push( new SiteHours().fromDataItem( childItem ) ) );
		}
		this.ads = [];
		if (item['ads']) {
			item['ads'].forEach( childItem => this.ads.push( new Ad().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['hours'] = [];
		if (this.hours) {
			this.hours.forEach( childItem => item['hours'].push( childItem.toDataItem() ) );
		}
		item['ads'] = [];
		if (this.ads) {
			this.ads.forEach( childItem => item['ads'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"siteId": this.siteId, 
        };
    }

	public getOpenTimesList() {
		try {
			// console.log( 'getOpenTimesList() for site '+JSON.stringify(this,null,2));
			let string = '';
			let list = this.getHoursAvailableInNextSevenDays();
			// console.log( 'getOpenTimesList() 2');
			for (let i=0; i<list.length; i++) {
				if (i>0) {
					string += ', ';
				}
				string += list[i].name;
			}
			// console.log( 'getOpenTimesList() 3');
			if (string == '') {
				string = 'None';
			}
			// console.log( 'getOpenTimesList() for site '+this.siteId +' ' + this.name+' returned: '+string);
			return string;
		} catch( error ) {
			Global.logError( 'Error in getOpenTimeList', error );
		}
	}

	public getHoursAvailableInNextSevenDays(): SiteHours[] {
		let found: SiteHours[] = [];
		if (this.hours) {
			this.hours.forEach( openTime => {
				if (openTime.isOpenInNextSevenDays()) {
					found.push( openTime );
				}
			});
		}
		return found;
	}

	/**
	 * Returns a speech string describing the hours the site is open for the given date.
	 * @param date Date for which the hours have been requested, null means today.
	 */
	public getHoursResponse( date: Date ): string {
		let speech = '';
		console.log( 'getHoursResponse for '+date.toISOString() );
		if (this.hours && this.hours.length > 0) {
			// See if site is open on the given date
			this.hours.forEach( openTime => {
				// Set date/time to the requested date at the opening time to check if it is open at that date/time
				let startTime = date ? date : new Date();
				startTime.setUTCHours( openTime.startDate.getUTCHours(), openTime.startDate.getUTCMinutes(), 0, 0 );
				console.log( 'isOpenOnDate '+startTime.toISOString()+', openTime='+JSON.stringify(openTime,null,2) );
				if (openTime.isOpenOnDate( startTime, this.timeZone )) {
					// Site is open, calculate the time speech
					console.log( 'isOpenOnDate returned true' );
					let startTimeSpeech = Global.getLocal12HourTimeSpeech( startTime, this.timeZone  );
					let endTime = date;
					endTime.setUTCHours( openTime.endDate.getUTCHours(), openTime.endDate.getUTCMinutes(), 0, 0 );
					let endTimeSpeech = Global.getLocal12HourTimeSpeech( endTime, this.timeZone  );
					if (speech.length == 0) {
						speech = this.name+' is open ';
					} else {
						speech += ' and ';
					}
					speech += 'from '+startTimeSpeech+' to '+endTimeSpeech;
				}
			})
		}

		// Calculate day speech
		let dateSpeech = '';
		if (Global.isToday( date, this.timeZone )) {
			dateSpeech = ' today';
		} else if (Global.isTomorrow( date, this.timeZone )) {
			dateSpeech = ' tomorrow';
		} else {
			dateSpeech = ' on '+Global.getDayOfWeekMonthDaySpeech( Global.getUTCDateString( date ) );
		}
		
		// Add day speech
		if (speech.length == 0) {
			// The site is not open on the requested date
			speech = this.name+' is not open'+dateSpeech;
		} else {
			// The site is open on the requested date
			speech += dateSpeech;
		}
		console.log( 'getHoursResponse returns: '+speech );
		return speech;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		name = name.toLowerCase();
		if (this.name.toLowerCase() == name) {
			valid = true;
		} else {
			valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
		}
		return valid;
	}

	/** Returns Date of 12am GMT on first day of the month for which spending is tracked. */
	public calculateAdMonth( date: Date=null ): Date {
		let adMonth = new Date();
		if (date) {
			adMonth = new Date( date.getTime() );
		}
		adMonth.setDate( 1 );
		adMonth.setUTCHours( 0, 0, 0, 0 );
		return adMonth;
	}

	/** Returns true if site has an ad budget and the latitude and longitude are set and it has at least one active ad. */
	canAdvertise() {
		// return this.latitude != null && this.longitude != null && this.hasActiveAd();
		return this.hasActiveAd();
	}

	/** Returns true if site has at least one active ad. */
	hasActiveAd() {
		let active = false;
		if (this.ads) {
			for (let i=0; i<this.ads.length; i++) {
				if (this.ads[i].isActive()) {
					active = true;
					break;
				}
			}
		}
		return active;
	}

	getLargestAdRadiusMiles() {
		let largest = 0;
		if (this.ads) {
			this.ads.forEach( ad => {
				if (ad.adRadiusMiles > largest) {
					largest = ad.adRadiusMiles;
				}
			});
		}
		return largest;
	}

	/**
	 * Returns the ad that includes the given property for the given category and has a bid > 0.
	 * If there are multiple ads that meet the criteria it returns the one with the higher bid or smaller ad radius.
	 * @param adCategoryId ID of ad category.
	 * @param property Property to run ad.
	 */
	getActiveAd( adCategoryId: number, property: Property ): Ad {
		let activeAd = null;
		// let activeAdProperty = null;
		if (this.ads) {
			for (let i=0; i<this.ads.length; i++) {
				let ad = this.ads[i];
				if (ad.adCategoryId == adCategoryId) {
					// let adProperty = ad.getActiveAdProperty( property );
					// if (adProperty && adProperty.bidCents > 0) {
					if (ad.adRadiusMiles != null && this.latitude != null && this.longitude != null && property.latitude != null && property.longitude != null) {
						// The site and property have coordinates and the ad has a radius, see if the property is within the radius
						let distance = Global.getMilesBetweenCoordinates( this.latitude, this.longitude, property.latitude, property.longitude );
						if (distance <= ad.adRadiusMiles) {
							// Property is within ad radius
							// if (activeAd == null || adProperty.bidCents > activeAdProperty.bidCents || ad.adRadiusMiles < activeAd.adRadiusMiles) {
							// 	// This is the first active ad we found or it has a higher bid or a smaller radius than the previous one
							// 	activeAd = ad;
							// 	activeAdProperty = adProperty;
							// }
							if (activeAd == null || activeAd.adRadiusMiles == null || ad.adRadiusMiles < activeAd.adRadiusMiles) {
								// This is the first active ad we found or it has a smaller radius than the previous one
								activeAd = ad;
							}
						}
					} else {
						// Either the site or property don't have coordinates or the ad doesn't 
						// have a radius so don't check distance between site and property
						if (activeAd == null) {
							// This is the first active ad we found
							activeAd = ad;
						}
					}
					// }
				}
			}
		}
		return activeAd;
	}

	/**
	 * Returns the ad that includes the given property for the given category and has a bid > 0.
	 * If there are multiple ads that meet the criteria it returns the one with the higher bid or smaller ad radius.
	 * @param adCategoryId ID of ad category.
	 * @param property Property to run ad.
	 */
	getActiveAds( adCategoryId: number ): Ad[] {
		let activeAds: Ad[] = [];
		if (this.ads) {
			for (let i=0; i<this.ads.length; i++) {
				let ad = this.ads[i];
				if (ad.adCategoryId == adCategoryId) {
					activeAds.push( ad );
				}
			}
		}
		return activeAds;
	}

	/**
	 * Returns the ad that includes the given property for the given category and has a bid > 0.
	 * If there are multiple ads that meet the criteria it returns the one with the higher bid or smaller ad radius.
	 * @param adCategoryId ID of ad category.
	 * @param property Property to run ad.
	 */
	getAds( adCategoryId: number, subcategoryId: number ): Ad[] {
		let found: Ad[] = [];
		if (this.ads) {
			for (let i=0; i<this.ads.length; i++) {
				let ad = this.ads[i];
				if (ad.adCategoryId == adCategoryId && ad.subcategoryId == subcategoryId) {
					found.push( ad );
				}
			}
		}
		return found;
	}

}

