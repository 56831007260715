import { TableDocumentObject } from './TableHandler'

/** A reservation for a specific time at a table in a seating. */
export class RestaurantReservation extends TableDocumentObject {
	
	/**
	 * @param startTime Time of reservation (may not be null)
	 * @param roomNumber Room number of the guest (default is null).
	 * @param name Name on the reservation
	 * @param persons Number of people who will be participating (default is 1).
	 * @param signUpTime Date and time when guests were added to the list.
	 */
	constructor(
		public startTime: Date = new Date(),
		public endTime: Date = new Date(),
		public roomNumber: string = null,
		public name: string = null,
		public persons: number = null,
		) { super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): RestaurantReservation {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

}

