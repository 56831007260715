import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from "@angular/core";
import {HttpClient } from '@angular/common/http';
import {UserLoginService} from "../../service/user-login.service";
import {UUIDService} from "../../service/uuid.service";
import { S3Service } from '../../service/s3.service';
import { CacheService } from '../../service/cache.service';
import { RekognitionService } from '../../service/rekognition.service';
import { Global } from '../../service/Global';
import { FaceDetector, TestImage } from '../../service/FaceDetector';
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';
import { TestSettingsComponent } from '../test-settings/test-settings.component';
import 'digest-auth-request';
import * as crypto from 'crypto';
import * as onvif from 'onvif';

declare let digestAuthRequest: any;

/**
 * Sends guest images to S3 when motion is detected and a face is detected in the image.
 * The settings that worked best with my Insignia tablet in my kitchen are:
 * faceDetectScale:	0.4
 * faceDetectInitialScale:	1
 * faceDetectStepSize:	2.5
 * faceDetectEdgeDensity:	0.1
 * motionPercentThreshold:	12
 * facePercentThreshold:	20
 */
@Component({
	selector: 'motion-detector',
	templateUrl: './motion-detector.html',
	styleUrls: ['./motion-detector.css'],
})
export class MotionDetectorComponent implements OnInit, OnDestroy {

	/** Percent of pixels that must change before it is considered motion. */
	private motionPercentThreshold = 12;

	private pixelDiffThreshold = 32;	// min for a pixel to be considered significant

	/** Percent of image that must be taken up by detected face before we will upload for face recognition. */
	private facePercentThreshold = 4;

	/** Time between captures when motion is not detected, in ms (captures people walking 3 mph and 2 feet apart) */
	private captureIntervalMillis = 600;

	/** How much to scale down the original image before motion diff */
	private diffScale = .1;

	/** Flag for whether we are currently collecting images to test face detection. */
	private testingFaceDetection = false;

	/** List of image dataURL's saved for testing face detection. */
	private testImages = [];

	/** Map where test results from multiple images are collected.  Keyed by image name. */
	private testResultsMap = new Map<string,any>();
	
	@ViewChild(ImageCaptureComponent) public imageCaptureComponent: ImageCaptureComponent;

	@ViewChild(TestSettingsComponent) public testSettingsComponent: TestSettingsComponent;

	// Video element
	private video: HTMLVideoElement;
	
	private captureContext: CanvasRenderingContext2D;			// context for capture canvas

	// internal canvas for capturing full size images
	@ViewChild('captureCanvas') private captureCanvasRef: ElementRef; private captureCanvas: HTMLCanvasElement;
	
	private canvasContext: CanvasRenderingContext2D;			// context for capture canvas
	
	// internal canvas for diffing downscaled captures
	@ViewChild('diffCanvas') private diffCanvasRef: ElementRef; private diffCanvas: HTMLCanvasElement;

	private diffContext: CanvasRenderingContext2D;			// context for diff canvas

	// receives processed diff images
	@ViewChild('motionCanvas') private motionCanvasRef: ElementRef; private motionCanvas: HTMLCanvasElement;
	
	/** Context for motion canvas. */
	private motionContext: CanvasRenderingContext2D;

	/** Width of captured image that is sent to S3 for processing, may be smaller than camera resolution to save time and money. */
	private captureWidth;

	/** Height of captured image that is sent to S3 for processing, may be smaller than camera resolution to save time and money. */
	private captureHeight;

	private diffWidth; 					// Width of scaled down image used for diff
	private diffHeight; 				// Height of scaled down image used for diff
	private includeMotionBox = false;	// flag to calculate and draw motion bounding box
	private previousImageData;			// Last image captured from video
	private timer: NodeJS.Timer;		// Timer used to check for motion at intervals
	private showMotion = false;			// Show motion canvas
	private detectingMotion = false;	// True if we are currently watching for motion

	/** Camera ID to use when saving images to S3. */
	private cameraId = 1;
	
    constructor( 
		private userService: UserLoginService, 
		private uuidService: UUIDService,
		private s3Service: S3Service,
		private cache: CacheService,
		private faceDetector: FaceDetector,
		private http: HttpClient,
	) 
	{}

    ngOnInit() {
	}

	ngAfterViewInit() {
		this.video = this.imageCaptureComponent.getVideoElement();
		this.captureCanvas = this.captureCanvasRef.nativeElement;
		this.captureContext = this.captureCanvas.getContext('2d');
		this.diffCanvas = this.diffCanvasRef.nativeElement;
		this.diffContext = this.diffCanvas.getContext('2d');
		this.motionCanvas = this.motionCanvasRef.nativeElement;
		this.motionContext = this.motionCanvas.getContext('2d');
	}

	ngOnDestroy() {
		if (this.timer) {
			clearTimeout( this.timer );
		}
		this.imageCaptureComponent.turnCameraOff();
	}

	initialize() {
		this.imageCaptureComponent.initialize( false )
		.then( (mediaDevicesSupported) => {
			if (mediaDevicesSupported) {
				this.captureCanvas.width = this.video.videoWidth;
				this.captureCanvas.height = this.video.videoHeight;
				this.diffWidth = Math.round( this.video.videoWidth * this.diffScale );
				this.diffHeight = Math.round( this.video.videoHeight * this.diffScale );
				this.diffCanvas.width = this.diffWidth;
				this.diffCanvas.height = this.diffHeight;
				this.motionCanvas.width = this.diffWidth;
				this.motionCanvas.height = this.diffHeight;
				// this.timer = setTimeout( () => this.captureImage(), this.captureIntervalTime );
			} else {
				throw new Error('Motion detection not supported on this device.');
			}
		})
		.catch( error => {
			Global.logError('Error starting motion detector.', error );
			if (!this.imageCaptureComponent.supportsGetUserMedia()) {
				alert('Video capture not supported on this device.');
			} else {
				alert('Problem starting video.');
			}
		});
	}

	/**
	 * Set the camera ID used when saving images to S3.
	 * @param newCameraId New camera ID.
	 */
	setCameraId( newCameraId: number ) {
		this.cameraId = newCameraId;
	}

	/** URI to use to get a snapshot from the IP camera. */
	private ipCameraSnapshotUri: string;

	/** Image element used to capture image from IP camera. */
	private ipCamImage: HTMLImageElement;

	// private snapshotCounter = 0;

	public setIpCameraSnapshotUri( uri: string ) {
		this.ipCameraSnapshotUri = uri;
	}

	startDetectingMotion() {
		if (!this.ipCameraSnapshotUri) {
			Global.log( 'Start detecting motion on device camera.');
			this.imageCaptureComponent.turnCameraOn()
			.then( playing => {
				if (this.imageCaptureComponent.supportsGetUserMedia()) {
					this.detectingMotion = true;
					this.setCaptureParameters( this.video.videoWidth, this.video.videoHeight );
					// this.timer = setTimeout( () => this.captureImage(), this.captureIntervalMillis );
					this.timer = setTimeout( () => this.getImageFromCamera(), this.captureIntervalMillis );
				} else {
					throw new Error('Motion detection not supported on this device.');
				}
			})
			.catch( error => {
				Global.logError('Error starting motion detector.', error );
				if (!this.imageCaptureComponent.supportsGetUserMedia()) {
					alert('Video capture not supported on this device.');
				} else {
					alert('Problem starting video.');
				}
			});
		} else {
			Global.log( 'Start detecting motion on network camera.');
			this.detectingMotion = true;
			// this.timer = setTimeout( () => this.captureImage(), this.captureIntervalMillis );
			this.timer = setTimeout( () => this.getImageFromCamera(), this.captureIntervalMillis );
			// setTimeout( () => { 
			// 	let snapshotStartTime = Date.now();
			// 	this.ipCamImage = new Image();
			// 	// this.ipCamImage.crossOrigin = 'anonymous';
			// 	this.ipCamImage.onload = ( event ) => {
			// 		console.log('Image changed in '+(Date.now()-snapshotStartTime)+'ms., size='+this.ipCamImage.naturalWidth+','+this.ipCamImage.naturalHeight);
			// 		this.setCaptureParameters( this.ipCamImage.naturalWidth, this.ipCamImage.naturalHeight );
			// 		this.captureImage();
			// 	};
			// 	// Add date/time to URI (replace colons with dashes) so browser won't use cached version of the picture
			// 	// let uri = this.ipCameraSnapshotUri + '&date=' + new Date().toISOString().replace(/\:/g, '-');
			// 	// let uri = 'http://admin:john1010@10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&counter='+(this.snapshotCounter++);
			// 	// let uri = this.ipCameraSnapshotUri + '&counter='+(this.snapshotCounter++);
			// 	let uri = this.ipCameraSnapshotUri + '&time='+Date.now();
			// 	console.log( 'Setting ipCamImage.src to: '+ uri );
			// 	this.ipCamImage.src = uri;
			// }, this.captureIntervalMillis );
		}
	}

	private getImageFromCamera() {
		if (this.ipCameraSnapshotUri) {
			// Get a new image from the IP camera so we can draw it on the capture canvas
			this.getImageFromIpCamera()
			.then( () => this.captureImage() )
			.catch( error => Global.logError( 'Error getting image from IP camera.', error ) );
		} else {
			// Since we are using live local video, just call capture image to draw the current frame on the capture canvas
			this.captureImage();
		}			
	}

	private getImageFromIpCamera(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {

			this.getSnapshot( 'http://10.0.1.21/onvif-http/snapshot?Profile_1', 'admin', 'john1010' );
				
			// let snapshotStartTime = Date.now();
			// this.ipCamImage = new Image();
			// // this.ipCamImage.crossOrigin = 'anonymous';
			// this.ipCamImage.onload = ( event ) => {
			// 	console.log('Image changed in '+(Date.now()-snapshotStartTime)+'ms., size='+this.ipCamImage.naturalWidth+','+this.ipCamImage.naturalHeight);
			// 	this.setCaptureParameters( this.ipCamImage.naturalWidth, this.ipCamImage.naturalHeight );
			// 	resolve();
			// };
			// // Add date/time to URI (replace colons with dashes) so browser won't use cached version of the picture
			// let uri = this.ipCameraSnapshotUri + '&time='+Date.now();
			// console.log( 'Setting ipCamImage.src to: '+ uri );
			//this.ipCamImage.src = uri;

			// let digest = this.getPasswordDigest( 'john1010', 0 );
			// console.log( 'digest='+JSON.stringify(digest,null,2));
			// this.connectToOnvifCamera( 'admin', 'john1010', '10.0.1.21', 80 );
				
			// let url = 'http://10.0.1.21/onvif-http/snapshot?Profile_1';
			// let username: string = 'admin';
			// let password: string = 'john1010';
			// let headers: Headers = new Headers();
			// // headers.append("Authorization", "Basic " + btoa(username + ":" + password)); 
			// headers.append( 'Authorization', 'Digest username="admin", realm="IP Camera(83121)", nonce="5a6a55315a4459785a54706c4d7a59344f5749335a673d3d", uri="/onvif-http/snapshot?Profile_1", response="743a3ea6a8e60a41d071b047d32b9cb6", qop=auth, nc=00000002, cnonce="a449c384bcc2b03b"' );
			// headers.append("Content-Type", "application/x-www-form-urlencoded");
			// this.http.post(url, {}, {headers: headers})
			// .subscribe( result => {
			// 	console.log( 'Result of camera call is: '+JSON.stringify( result, null, 2 ) );
			// });

		// this.ipCamImage = this.ipCamImageRef.nativeElement;
		// this.ipCamCanvas = this.ipCamCanvasRef.nativeElement;

		// setInterval( () => { 
		// 	this.snapshotStartTime = Date.now();
		// 	this.ipCamImage = new Image( 640, 480 );
		// 	this.ipCamImage.onload = ( event ) => this.ipCamImageChanged( event );
		// 	this.ipCamImage.src = 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&date=' + new Date().toISOString();
		// 	// this.snapshotUri = 'http://admin:john1010@10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&counter='+(this.snapshotCounter++);
		// 	// this.snapshotUri = 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&date=' + new Date().toISOString();
		// }, 3000 );

/*
		// Download to a directory and save with an another filename
		let options = {
			//  url: 'http://admin:john1010@10.0.1.9:80/mjpeg/snap.cgi?chn=0',
			url: 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0',
			//  headers: { "Authorization" : "Basic " + new Buffer("admin:john1010").toString("base64") },
			headers: { 
				// "Authorization": 'Digest username="admin", realm="IPCamera Login", nonce="c9582ddb62b914a3ddacbf8c6b4f8b36", uri="/mjpeg/snap.cgi?chn=0", response="a620f6e1d8aa30d104d9a3719e954241", qop=auth, nc=00000001, cnonce="bf460e7fa86f2f56"',
				'Authorization': 'Digest username="admin", realm="IP Camera(83121)", nonce="5a6a51354e7a41345a5751365a544d324e44526a4e57493d", uri="/onvif-http/snapshot?Profile_1", response="9f2eafba2a3878e39f0e69009f78f712", qop=auth, nc=00000002, cnonce="7ea94f95a20d21c6"',
				// "crossOrigin": 'anonymous',
			},
			//  auth: { user: 'admin', password: 'john1010' },
			dest: '/Me/Temp/test.jpg'        // Save to /path/to/dest/photo.jpg
		};
		console.log('options='+JSON.stringify(options,null,2));
		request(options, (err, res, body) => {
*/		
		});
	}

	private getSnapshot( url: string, username: string, password: string ) {
		// create digest request object
		var req = new digestAuthRequest('GET', url, username, password );
		// make the request
		req.request(function(data) {
			console.log('Data retrieved successfully');
			console.log(data);
			// document.getElementById('result').innerHTML = 'Data retrieved successfully';
			// document.getElementById('data').innerHTML = JSON.stringify(data);
		},function(errorCode) {
			console.log('no dice: '+errorCode);
			// document.getElementById('result').innerHTML = 'Error: '+errorCode;
		});

	}

	private connectToOnvifCamera( username: string, password: string, ipAddress: string, port: number ) {
		// USERNAME = 'admin',
		// PASSWORD = 'john1010';
	
	// var Cam = require('./lib/onvif').Cam;
	// var flow = require('nimble');
	
	// var ip_list = generate_range(IP_RANGE_START, IP_RANGE_END);
	// var port_list = PORT_LIST;
	// ip_list = ['10.0.1.6'];
	// port_list = [80];
	
	// // hide error messages
	// console.error = function() {};
	
	// // try each IP address and each Port
	// ip_list.forEach(function(ip_entry) {
	// 	port_list.forEach(function(port_entry) {
	
		console.log( 'Connecting to camera at: ' + ipAddress + ':' + port);
		let options = {
			hostname: ipAddress,
			username: username,
			password: password,
			port: port,
			timeout : 5000
		};
		let cam_obj = new onvif.Cam( options, err => {
			if (err) {
				console.log( 'new Cam failed: ',err.message );
				return;
			}
			// var cam_obj = this;
			var got_date;
			var got_info;
			var got_live_stream;
			var got_recordings;
			var got_replay_stream;
			var got_snapshot_uri;
	
				// // Use Nimble to execute each ONVIF function in turn
				// // This is used so we can wait on all ONVIF replies before
				// // writing to the console
				// flow.series([
				// 	function(callback) {
			cam_obj.getSystemDateAndTime( (err, date, xml) => {
				if (!err) got_date = date;
				console.log( 'getSystemDateAndTime returned date:\n' + JSON.stringify( date, null, 2 ) );
				//console.log( 'getSystemDateAndTime returned xml:\n' + xml );
				// callback();
			});
			let digest = cam_obj._passwordDigest();
			console.log( 'digest='+JSON.stringify( digest, null, 2 ) );
		});
	}

	// /**
	//  * Generate arguments for digest auth
	//  * @param password Password to include in digest
	//  * @param timeShift Difference between this computer's time and the camera's time.
	//  * @return {{passdigest: *, nonce: (*|String), timestamp: string}}
	//  */
	// private getPasswordDigest( password: string, timeShift: number ) {
	// 	var timestamp = (new Date(Date.now() + (timeShift || 0))).toISOString();
	// 	var nonce = new Buffer(16);
	// 	nonce.writeUIntLE(Math.ceil(Math.random() * 0x100000000), 0, 4);
	// 	nonce.writeUIntLE(Math.ceil(Math.random() * 0x100000000), 4, 4);
	// 	nonce.writeUIntLE(Math.ceil(Math.random() * 0x100000000), 8, 4);
	// 	nonce.writeUIntLE(Math.ceil(Math.random() * 0x100000000), 12, 4);
	// 	var cryptoDigest = crypto.createHash('sha1');
	// 	cryptoDigest.update(Buffer.concat([nonce, new Buffer(timestamp, 'ascii'), new Buffer(password, 'ascii')]));
	// 	var passdigest = cryptoDigest.digest('base64');
	// 	return {
	// 		passdigest: passdigest
	// 		, nonce: new Buffer(nonce).toString('base64')
	// 		, timestamp: timestamp
	// 	};
	// };


	private setCaptureParameters( captureWidth: number, captureHeight: number ) {
		if (captureHeight > 480) {
			// Set capture height to 480 and maintain aspect ration of captured image.
			let actualSize = captureWidth+'x'+captureHeight;
			captureWidth = Math.round( captureWidth * (480 / captureHeight) );
			captureHeight = 480;
			Global.log('Using capture size '+captureWidth+'x'+captureHeight+' instead of actual size '+actualSize );
		}
		this.captureCanvas.width = captureWidth;
		this.captureCanvas.height = captureHeight;
		this.diffWidth = Math.round( captureWidth * this.diffScale );
		this.diffHeight = Math.round( captureHeight * this.diffScale );
		this.diffCanvas.width = this.diffWidth;
		this.diffCanvas.height = this.diffHeight;
		this.motionCanvas.width = this.diffWidth;
		this.motionCanvas.height = this.diffHeight;
		if (this.cache.currentCompany.motionPercentThreshold) {
			this.motionPercentThreshold = this.cache.currentCompany.motionPercentThreshold;
		}
		if (this.cache.currentCompany.facePercentThreshold) {
			this.facePercentThreshold = this.cache.currentCompany.facePercentThreshold;
		}
	}

	stopDetectingMotion() {
		// console.log('Stop detecting motion.');
		if (this.timer) {
			clearTimeout( this.timer );
		}
		if (this.showMotion) {
			this.motionContext.clearRect(0, 0, this.diffWidth, this.diffHeight);
		}
		this.previousImageData = null;
		this.imageCaptureComponent.turnCameraOff();
		this.detectingMotion = false;
	}

	isDetectingMotion() {
		return this.detectingMotion;
	}

	/**
	 * Capture an image from the video stream and put it in S3 in the company/site/camera folder.
	 */
	captureImage() {
		// Get image from camera
		let startTime = new Date();
		let diffPercent = this.checkForMotion();
		// Global.log( 'Detected '+diffPercent+'% of pixels changed, '+ ((diffPercent >= this.motionThresholdPercent) ? 'motion detected.' : 'no motion detected.') );
		let motionTime = Date.now();
		if (this.testingFaceDetection || diffPercent >= this.motionPercentThreshold) {
			// See if we can detect a face before uploading image to S3
			let promise = null;
			if (this.testingFaceDetection) {
				promise = Promise.resolve( [] )
			} else {
				promise = this.faceDetector.detectFaces( this.captureCanvas, this.cache.currentCompany.faceDetectScale, this.cache.currentCompany.faceDetectInitialScale, this.cache.currentCompany.faceDetectStepSize, this.cache.currentCompany.faceDetectEdgeDensity )
			}
			promise.then( faceDetectResult => {
				let detectFaceTime = Date.now();
				// Upload image to S3 into a folder for the user's identified people
				var dataURL = this.captureCanvas.toDataURL('image/jpeg', 1.0); // 1.0=full quality, .92=default, .5=medium, .1=low
				let urlTime = Date.now();

				// Rotate the image if necessary
				// this.captureCanvas.toBlob( (resultBlob: Blob) => {
					let blobTime = Date.now();
					// this.imageCaptureComponent.rotateImageIfNeeded( dataURL, resultBlob )
					// .then( dataURL => {
						let rotateTime = Date.now();
						let base64DataString = dataURL.replace(/^data:image\/\w+;base64,/, "");
						// let objectKey = this.cacheService.currentCompany.companyId+'/'+this.cacheService.currentSite.siteId+'/'+this.cameraId+'/' + this.uuidService.UUID() + '.jpg';
						let objectKey = Global.getGuestImageName( this.cache.currentCompany.companyId, this.cache.properties[0].propertyId, this.cache.currentSite.siteId, this.cameraId );
						let facePercent = 0;
						let rectangles = faceDetectResult.rectangles;
						// console.log('Found face rectangles: '+JSON.stringify(rectangles)+', rectangles.length=='+(rectangles ? rectangles.length : 0 ) );
						if (rectangles && rectangles.length > 0) {
							// console.log('Found face rectangles: '+JSON.stringify(rectangles) );
							let leftRatio = Math.round( ( rectangles[0].x / this.captureCanvas.width ) * 100 );
							// let topRatio = Math.round( ( (this.captureCanvas.height - (rectangles[0].y+rectangles[0].height)) / this.captureCanvas.height ) * 100 );
							let topRatio = Math.round( ( rectangles[0].y / this.captureCanvas.height ) * 100 );
							let widthRatio = rectangles[0].width / this.captureCanvas.width;
							let heightRatio = rectangles[0].height / this.captureCanvas.height;
							facePercent = Math.round( heightRatio * 100);
							Global.log( 'Face detected by browser. ' + facePercent + '%' + (facePercent >= this.facePercentThreshold ? '' : ' (too small to send)') + ', rect='+leftRatio+'%,'+topRatio+'%, '+Math.round(widthRatio*100)+'%,'+Math.round(heightRatio*100)+'%, motion='+diffPercent+'%, image='+objectKey+' in '+(detectFaceTime-motionTime)+'ms.' );
						} else {
							Global.log( 'Ignoring image, no face detected, motion='+diffPercent+'%, faceDetect took '+(detectFaceTime-motionTime)+'ms, image='+objectKey );
						}
						
						// Set delay so we check every capture interval but ensure there is at least some time between check for processing user actions
						let delayMillis = Math.max( 100, this.captureIntervalMillis - (Date.now() - startTime.getTime()) );
						// Global.log('checkForMotion '+(motionTime-startTime.getTime())+'ms, detectFace '+(detectFaceTime-motionTime)+'ms, toDataUrl '+(urlTime-detectFaceTime)+'ms'/*+', toBlob '+(blobTime-urlTime)+'ms, rotate '+(rotateTime-blobTime)+'ms'+', time since last capture '+(Date.now()-startTime.getTime())+'ms, delayMillis='+delayMillis+'ms'*/+', diff '+diffPercent+'%'+', image='+objectKey);
						// this.timer = setTimeout( () => this.captureImage(), delayMillis );
						this.timer = setTimeout( () => this.getImageFromCamera(), delayMillis );
						if (this.testingFaceDetection || facePercent >= this.facePercentThreshold) {
							this.s3Service.upload( base64DataString, Global.visitPhotoBucketName, objectKey )
							.then( () => {
								//Global.log('checkForMotion '+(motionTime-startTime.getTime())+'ms, toDataUrl '+(urlTime-motionTime)+/*'ms, toBlob '+(blobTime-urlTime)+'ms, rotate '+(rotateTime-blobTime)+*/'ms, upload '+(Date.now()-rotateTime)+'ms, time since last capture '+(Date.now()-startTime.getTime())+'ms, delayMillis='+delayMillis);
								Global.log( 'Uploaded visit image in '+(Date.now()-rotateTime)+'ms' );
								if (this.testingFaceDetection) {
									this.faceDetector.addTestImage( new TestImage( Global.visitPhotoBucketName, objectKey, dataURL, true ) );
								}
							})
							.catch( err => {
								Global.logError( 'Error uploading image to Amazon S3.', err );
							});
						}
					// })
					// .catch( err => {
					// 	Global.logError( 'Error rotating image.', err );
					// });
				// });
			})
		} else {
			let delayMillis = Math.max( 1, this.captureIntervalMillis - (Date.now() - startTime.getTime()) );
			// this.timer = setTimeout( () => this.captureImage(), delayMillis );
			this.timer = setTimeout( () => this.getImageFromCamera(), delayMillis );
			// Global.log('No Motion checkForMotion '+(Date.now()-startTime.getTime())+'ms, time since last capture '+(Date.now()-startTime.getTime())+'ms, delayMillis='+delayMillis);
		}
		// console.log( 'captureImage took '+(Date.now()-startTime)+'ms.' );
	}

	/**
	 * Compares current image with previous image and returns % of pixels that are different.
	 */
	private checkForMotion(): number {
		let diffPercent = 0;
		let pixelCount = this.diffWidth * this.diffHeight;
		if (this.ipCameraSnapshotUri) {
			// Get image from IP camera
			this.captureContext.drawImage( this.ipCamImage, 0, 0, this.captureCanvas.width, this.captureCanvas.height );

		} else {
			// Get image from device camera video
			this.captureContext.drawImage( this.video, 0, 0, this.captureCanvas.width, this.captureCanvas.height);
		}
		this.diffContext.globalCompositeOperation = 'copy';
		this.diffContext.drawImage( this.captureCanvas, 0, 0, this.diffWidth, this.diffHeight);
		var diffImageData = this.diffContext.getImageData(0, 0, this.diffWidth, this.diffHeight);

		if (this.previousImageData) {
			var diff = this.processDiff(diffImageData);

			diffPercent = Math.floor( (diff.score / pixelCount) * 100 );
			let motionDetected = diffPercent >= this.motionPercentThreshold;
			if (this.showMotion && motionDetected) {
				this.motionContext.globalCompositeOperation = 'copy';
				this.motionContext.putImageData(diff.imageData, 0, 0);
				if (this.includeMotionBox && diff.motionBox) {
					this.motionContext.globalCompositeOperation = 'source-over';
					this.motionContext.strokeStyle = '#fff';
					this.motionContext.strokeRect(
						diff.motionBox.x.min + 0.5,
						diff.motionBox.y.min + 0.5,
						diff.motionBox.x.max - diff.motionBox.x.min,
						diff.motionBox.y.max - diff.motionBox.y.min
					);
				}
			}
		
		}
		// Save image so we can compare to it next time
		this.previousImageData = diffImageData;
		return diffPercent;
	}

	/**
	 * Compares given image to previous image and calculates the % of pixels that are different.
	 * @param diffImageData Image to compare to previous image.
	 */
	private processDiff(diffImageData: ImageData) {
		var rgba = diffImageData.data;
		let newImageData = new ImageData( this.diffWidth, this.diffHeight );
		var score = 0;
		var motionBox = undefined;
		// var logLine = '';
		for (var i = 0; i < rgba.length; i += 4) {
			var pixelDiff = Math.abs( rgba[i] - this.previousImageData.data[i] )
				+ Math.abs( rgba[i+1] - this.previousImageData.data[i+1] )
				+ Math.abs( rgba[i+2] - this.previousImageData.data[i+2] )
			var normalized = Math.floor( pixelDiff / 3 );
			// logLine += ''+normalized+' ';
			// let bytesInRow = this.diffWidth * 4;
			// if (i % bytesInRow == 0 && i<(bytesInRow * 10)) {
			// 	console.log( logLine);
			// 	logLine = '';
			// }
			newImageData.data[i] = 0;
			newImageData.data[i+1] = normalized;
			newImageData.data[i+2] = 0;
			newImageData.data[i+3] = rgba[i+3];

			if (pixelDiff >= this.pixelDiffThreshold) {
				score++;
				if (this.includeMotionBox) {
					let pixelIndex = i / 4;
					let x = pixelIndex % this.diffWidth;
					let y = Math.floor(pixelIndex / this.diffWidth);
					motionBox = this.calculateMotionBox(motionBox, x, y);
				}
			}
		}

		return {
			score: score,
			motionBox: motionBox,
			imageData: newImageData
		};
	}

	private calculateMotionBox(currentMotionBox, x, y) {
		// init motion box on demand
		var motionBox = currentMotionBox || {
			x: { min: x, max: x },
			y: { min: y, max: y }
		};

		motionBox.x.min = Math.min(motionBox.x.min, x);
		motionBox.x.max = Math.max(motionBox.x.max, x);
		motionBox.y.min = Math.min(motionBox.y.min, y);
		motionBox.y.max = Math.max(motionBox.y.max, y);

		return motionBox;
	}

	public startTestingFaceDetection() {
		console.log( 'startTestingFaceDetection' );
		this.testingFaceDetection = true;
		this.faceDetector.clearTestImages();
	}

	public stopTestingFaceDetection() {
		this.testingFaceDetection = false;
		setTimeout( () => 
		this.testSettingsComponent.showModalDialog( this.faceDetector.getTestImages() )
		.then( saved => {
			if (saved) {
				this.faceDetector.testFaceDetection( this.cache.currentCompany.faceDetectScale );
			}
		})
		.catch( error => {
			Global.logError( 'Error selecting images in TestSettingsComponent', error );
			alert( 'Problem testing face detection settings.  Please try again.' );
		})
		,500);
	}
	
	public isTestingFaceDetection() {
		return this.testingFaceDetection;
	}

}
