import {Inject, Injectable} from "@angular/core";
import {CognitoCallback, CognitoUtil} from "./cognito.service";
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {NewPasswordUser} from "../public/auth/newpassword/newpassword.component";
import * as AWS from "aws-sdk/global";
import { Global } from './Global';

@Injectable()
export class UserRegistrationService {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {

    }

    registerUser( name: string, email: string, password: string ): Promise<any> {
        // console.log("UserRegistrationService: user is " + name + ', ' + email );
        return new Promise( (resolve, reject) => {
            let attributes = [];
            attributes.push( new CognitoUserAttribute( { Name: 'email', Value: email } ) );
            attributes.push( new CognitoUserAttribute( { Name: 'nickname', Value: name } ) );
    
            this.cognitoUtil.getUserPool().signUp( email, password, attributes, null, (err, result) => {
                if (err) {
                    // console.log( 'Registration error: ' + JSON.stringify( err, null, 2 ) );
                    reject( err );
                } else {
                    // console.log("UserRegistrationService: registered user is " + JSON.stringify( result, null, 2 ) );
                    resolve( result);
                }
            });
        });
    }

    confirmRegistration(username: string, confirmationCode: string, callback: (err,result) => void ): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        let cognitoUser = new CognitoUser(userData);
		// console.log( 'UserRegistrationService.confirmRegistration username='+username+', confirmationCode='+confirmationCode+', userData='+JSON.stringify(userData,null,2) );
        cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
            if (err) {
                callback(err, null);
            } else {
				// User successfully confirmed registration
				// console.log( 'UserRegistrationService.confirmRegistration cognitoUser='+JSON.stringify( new CognitoUtil().getCurrentUser(), null, 2 ) );
                callback(null, result);
            }
        });
    }

    resendCode(username: string, callback: (err,result) => void ): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode(function (err, result) {
            callback(err, result);
        });
    }

    newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
        console.log(newPasswordUser);
        // Get these details and call
        //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };

        console.log("UserLoginService: Params set...Authenticating the user");
        let cognitoUser = new CognitoUser(userData);
        console.log("UserLoginService: config is " + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callback.cognitoCallback(null, userAttributes);
                    },
                    onFailure: function (err) {
                        callback.cognitoCallback(err, null);
                    },
					// The following 2 callbacks are required in the Cognito SDK 1.15 but not in 1.19
					mfaRequired: (challengeName: any, challengeParameters: any) => {
						let err = 'Error validating username ('+newPasswordUser.username+') and password.  MFA required';
						Global.log( err );
						callback.cognitoCallback(err, null);
					}, 
					customChallenge: (challengeParameters: any) => {
						let err = 'Error validating username ('+newPasswordUser.username+') and password.  Custom challenge required';
						Global.log( err );
						callback.cognitoCallback(err, null);
					}
                });
            },
            onSuccess: function (result) {
                callback.cognitoCallback(null, result);
            },
            onFailure: function (err) {
				Global.log( err );
                callback.cognitoCallback(err, null);
            },
			// The following 2 callbacks are required in the Cognito SDK 1.15 but not in 1.19
			mfaRequired: (challengeName: any, challengeParameters: any) => {
				let err = 'Error validating username ('+newPasswordUser.username+') and password.  MFA required';
				Global.log( err );
                callback.cognitoCallback(err, null);
			}, 
			customChallenge: (challengeParameters: any) => {
				let err = 'Error validating username ('+newPasswordUser.username+') and password.  Custom challenge required';
				Global.log( err );
                callback.cognitoCallback(err, null);
			}
        });
    }
}