import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { HousekeepingLogEntryComponent } from "../housekeeping-log-entry/housekeeping-log-entry.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { HousekeepingLogEntry } from '../../service/HousekeepingLogEntry';
import { Property } from '../../service/Property';
import { IMyDpOptions } from 'mydatepicker';
import { HousekeepingLogTable } from '../../service/HousekeepingLogTable';

@Component({
    selector: 'housekeeping-log',
    templateUrl: './housekeeping-log.html',
	styleUrls: ['./housekeeping-log.css'],
})
export class HousekeepingLogComponent implements OnInit, AfterViewInit {

	/** List of rows that can be edited. */
	private rows: HousekeepingLogEntry[] = [];

	/** Error message displayed to the user. */
	private errorMessage: string;

	/** Currently selected property. */
	private propertyId;

	/** Minutes to adjust UTC times for property time zone. */
	private timeZone: string = null;

	/** Date to display. */
	private displayDate: Date = new Date();

	// Data model used for date picker, initialized to today.
	private displayDateModel: any = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	private myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
	};

	@ViewChild(HousekeepingLogEntryComponent) private detailComponent: HousekeepingLogEntryComponent;

	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
		private editedTable: HousekeepingLogTable,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-clipboard fa-fw"></i> Housekeeping Log' );
	}

	initialize() {
		// console.log('HK initialize this.cache.properties.length='+this.cache.properties.length);
		this.displayDate = new Date();
		this.displayDateModel = { jsdate: this.displayDate, date: { year: this.displayDate.getUTCFullYear(), month: this.displayDate.getUTCMonth()+1, day: this.displayDate.getUTCDate() } };
		if (this.cache.properties.length > 0) {
			// Set selected property to the first one in the list
			let property = this.cache.properties[0];
			this.propertyId = property.propertyId;
			this.timeZone = property.timeZone;
			// console.log('HK initialize propertyId='+this.propertyId);

			// Adjust display date to display current date in property's time zone
			this.displayDate = Global.adjustDateByTimeZone( new Date(), this.timeZone );
			this.displayDateModel = { jsdate: this.displayDate, date: { year: this.displayDate.getUTCFullYear(), month: this.displayDate.getUTCMonth()+1, day: this.displayDate.getUTCDate() } };
			// console.log( 'Date/time in property\'s time zone: '+this.displayDate.toISOString());
			this.changeDetectorRef.detectChanges();
			this.switchProperty( null );
		}
	}

	private switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		// Get timeZone offset for the property
		this.timeZone = this.cache.getTimeZone( this.propertyId );
		this.loadRows();
	}

	private loadRows() {
		
		let adjustedDate = Global.adjustDateByTimeZone( this.displayDate, this.timeZone );
		let startDate = new Date( this.displayDate.getTime() );
		startDate.setUTCHours( 0, 0, 0, 0 );
		startDate = Global.adjustDateByTimeZone( startDate, this.timeZone, false );
		let endDate = new Date( this.displayDate.getTime() );
		endDate.setUTCHours( 23, 59, 59, 999 );
		endDate = Global.adjustDateByTimeZone( endDate, this.timeZone, false );
		new HousekeepingLogTable().getDateRange( this.cache.currentCompany.companyId, this.propertyId, startDate, endDate )
		.then( rows => {
			// console.log( 'HK loadRows between '+startDate.toISOString()+' and '+endDate.toISOString()+': '+JSON.stringify(rows,null,2));
			this.rows = rows;
		})
		.catch( error => {
			Global.logError('Error loading data.', error );
			this.errorMessage = 'Sorry, we had a problem loading the data.  Please try again.';
		})
	}

	private switchDisplayDate( event ) {
		if (this.cache.properties.length > 0 && event.jsdate) {
			// Properties have been initialized, user must have changed date
			this.displayDate = new Date( event.jsdate.getTime() );
			this.loadRows();
		}
	}

	getDateTimeString( date: Date ): string {
		if (date) {
			let adjustedDate = Global.adjustDateByTimeZone( date, this.timeZone );
			var options = { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
			return adjustedDate.toLocaleTimeString( undefined, options );
		} else {
			return '--:--';
		}
	}

	insert() {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( new HousekeepingLogEntry( this.cache.currentCompany.companyId, this.propertyId ), true, this.timeZone )
		.then( results => {
			if (results.action === 'insert') {
				// User saved changes, update the list
				new HousekeepingLogTable().put( results.row )
				.then( () => {
					this.rows.push( results.row );
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error saving data.', error );
					this.errorMessage = 'Sorry, we had a problem saving the data.  Please try again.';
				});
			}
		})
		.catch( error => {
			Global.logError( 'Error editing new data', error );
			this.errorMessage = error.message;
		});
	}

	edit( index: number ) {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( this.rows[index], false, this.timeZone )
		.then( results => {
			if (results.action === 'update') {
				// User saved changes, update the row
				new HousekeepingLogTable().put( results.row )
				.then( () => {
					if (!results.row.primaryKeyMatches( this.detailComponent.getOriginalRow() )) {
						// User changed the primary key, delete the old row with the old primary key
						new HousekeepingLogTable().delete( this.detailComponent.getOriginalRow() )
						.catch( error => {
							Global.logError('Error deleting data.', error );
							this.errorMessage = 'Sorry, we had a problem deleting the old data.  Please try again.';
						});
					}
					this.rows[index] = results.row;
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error updating data.', error );
					this.errorMessage = 'Sorry, we had a problem updating the data.  Please try again.';
				});
			} else if (results.action === 'delete') {
				// User chose to delete the row
				new HousekeepingLogTable().delete( results.row )
				.then( () => {
					this.rows.splice( index, 1 );
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error deleting data.', error );
					this.errorMessage = 'Sorry, we had a problem deleting the data.  Please try again.';
				});
			}
		})
		.catch( error => {
			Global.logError( 'Error editing data.', error );
			this.errorMessage = error.message;
		});
	}

}
