import * as AWS from "aws-sdk/global";
import * as DynamoDB from "aws-sdk/clients/dynamodb";
import { Global } from './Global';

export class Timeslot {
    constructor(
        public time: string,
        public lineCount: number,
        public seconds: number
    ){}
}


export class DynamoDBService {

    /**
     * Return line time data in an array to show in a graph.
     * @param fifteensPerBlock Number of 15-minute intervals in each value (1, 2, 4, 8, 12, 16, 24, 48, 96)
     */
    getLineTimeChartData( fifteensPerBlock: number ): Promise<Timeslot[]> {
        // console.log( 'getLineTimeChartData' );
        return new Promise( (resolve,reject) => {
            // console.log("DynamoDBService: reading from DDB with creds - " + AWS.config.credentials.accessKeyId );
            var params = {
                TableName: 'faceTrackerLineTime',
                KeyConditionExpression: "companyId_siteId = :companyId_siteId",
                ExpressionAttributeValues: {
                    ":companyId_siteId": '1_2'
                }
            };

			var docClient = new DynamoDB.DocumentClient();
            docClient.query(params, onQuery);

            function onQuery(err, data) {
                if (err) {
                    console.error("DynamoDBService: Unable to query the faceTrackerLineTime table. Error code: '+err.code+' Error JSON:", JSON.stringify(err, null, 2));
                    reject("DynamoDBService: Unable to query the faceTrackerLineTime table. Error JSON: " + JSON.stringify(err, null, 2));
                } else {
                    // build array represending all the 15-minute day parts from midnight to midnight
                    // console.log("DynamoDBService: Query of table faceTrackerLineTime succeeded.");
                    let timeslots: Timeslot[] = [];
                    let minuteArray = ['00','15','30','45'];
                    for (let i=0; i<96/fifteensPerBlock; i++) {
                        let ampm = 'am';
                        let hour: number = Math.floor( (i * fifteensPerBlock) / 4 );
                        if (hour > 11) {
                            ampm = 'pm';
                        }
                        if (hour > 12) {
                            hour = hour - 12;
                        }
                        if (hour == 0) {
                            hour = 12;
                        }
                        let min = minuteArray[ (i * fifteensPerBlock) % 4 ];
                        // console.log('adding timeslot '+timeslots.length+' to array: '+ hour + ':' + min + ampm );
                        timeslots.push( new Timeslot( hour + ':' + min + ampm, 0, 0 ) );

                    }
                    data.Items.forEach(function (item) {
                        // console.log('Found line time for company '+item.companyId+' siteId+'+item.siteId+' date '+item.date);
                        item.timeslots.forEach(element => {
                            // console.log('  timeslot='+element.time+', line='+element.line+', seconds='+element.seconds);

                            // Parse the time value from the line time data
                            let time: string = element.time;
                            let timeparts: string[] = time.split(':');
                            let utcHours = Number.parseInt( timeparts[0] );
                            let utcMinutes = Number.parseInt( timeparts[1] );
                            
                            // Convert hours and minutes from UTC time to local time
                            let utcDate = new Date();
                            utcDate.setUTCHours( utcHours );
                            utcDate.setUTCMinutes( utcMinutes );
                            let hours = utcDate.getHours();
                            let minutes = utcDate.getMinutes();

                            // Convert time, like 15:45, to the correct index in the timeslot array
                            let timeslotIndex = Math.floor( ( ( hours * 4) + Math.floor( minutes / 15 ) ) / fifteensPerBlock );
                            // console.log('utcHours='+utcHours+', utcminutes='+utcMinutes+', hours='+hours+', minutes='+minutes+', timeslotIndex='+timeslotIndex);
                            
                            // Add the line count and line time to the timeslot
                            timeslots[timeslotIndex].lineCount += element.line;
                            timeslots[timeslotIndex].seconds += element.seconds;
                            // console.log( 'time: '+element.time+', lineCount='+element.lineCount+', seconds='+element.seconds);
                            // console.log('  Adding timeslot: '+JSON.stringify(timeslots[timeslotIndex]));
                        });
                    });
                    resolve( timeslots );
                }
            }
        });
    }

	createTable( params: DynamoDB.CreateTableInput ): Promise<DynamoDB.DescribeTableOutput> {
    	return new Promise<DynamoDB.DescribeTableOutput>( (resolve, reject) => {
            new DynamoDB().createTable( params, (err, data: DynamoDB.CreateTableOutput ) => {
                if (err) {
                    reject( err );
                } else {
					this.describeTableUntilCreated( params.TableName )
					.then( results => resolve( results ) )
					.catch( err => reject( err ) );
                }
            });
		});
	}

	describeTableUntilCreated( tableName: string ): Promise<DynamoDB.DescribeTableOutput> {
    	return new Promise( (resolve, reject) => {
			Global.log( 'Creating table '+tableName );
			let millisBetweenChecks = 3000;
			let params: DynamoDB.DescribeTableInput = {
				TableName: tableName,
			}
			new DynamoDB().describeTable( params, (err, results: DynamoDB.DescribeTableOutput ) => {
                if (err) {
					if (err.code == 'ResourceNotFoundException') {
						Global.log( 'Creating table '+tableName+', table not found, keep waiting' );
						setTimeout( () => {
							this.describeTableUntilCreated( tableName )
							.then( results => resolve( results ) )
							.catch( err => reject( err ) );
						}, millisBetweenChecks );
					} else {
						Global.log( 'Error creating table '+tableName+', error '+err.code+': '+err.message );
						reject( err );
					}
                } else {
					if (results.Table.TableStatus == 'ACTIVE') {
						Global.log( 'Table '+tableName+' created successfully.' );
						resolve( results );
					} else {
						Global.log( 'Creating table '+tableName+', TableStatus='+results.Table.TableStatus+', keep waiting' );
						setTimeout( () => {
							this.describeTableUntilCreated( tableName )
							.then( results => resolve( results ) )
							.catch( err => reject( err ) );
						}, millisBetweenChecks );
					}
                }
            });
		});
	}

	describeTable( tableName: string ): Promise<DynamoDB.DescribeTableOutput> {
    	return new Promise( (resolve, reject) => {
			let params: DynamoDB.DescribeTableInput = {
				TableName: tableName,
			}
			new DynamoDB().describeTable( params, (err, data: DynamoDB.DescribeTableOutput ) => {
                if (err) {
                    reject( err );
                } else {
					resolve( data );
                }
            });
		});
	}

	deleteTable( tableName: string ): Promise<DynamoDB.DescribeTableOutput> {
    	return new Promise<DynamoDB.DescribeTableOutput>( (resolve, reject) => {
			let params: DynamoDB.DeleteTableInput = {
				TableName: tableName
			};
            new DynamoDB().deleteTable( params, (err, data: DynamoDB.DeleteTableOutput ) => {
                if (err) {
                    reject( err );
                } else {
					this.describeTableUntilDeleted( params.TableName )
					.then( results => resolve( results ) )
					.catch( err => reject( err ) );
                }
            });
		});
	}

	describeTableUntilDeleted( tableName: string ): Promise<DynamoDB.DescribeTableOutput> {
    	return new Promise( (resolve, reject) => {
			Global.log( 'Deleting table '+tableName );
			let millisBetweenChecks = 3000;
			let params: DynamoDB.DescribeTableInput = {
				TableName: tableName
			}
			new DynamoDB().describeTable( params, (err, results: DynamoDB.DescribeTableOutput ) => {
                if (err) {
					if (err.code == 'ResourceNotFoundException') {
						Global.log( 'Table '+tableName+' deleted successfully.' );
						resolve( null );
					} else {
						Global.log( 'Error deleting table '+tableName+', error '+err.code+': '+err.message );
						reject( err );
					}
                } else {
					// We got a status for the table so it is not deleted yet, keep waiting
					Global.log( 'Deleting table '+tableName+', TableStatus='+results.Table.TableStatus+', keep waiting' );
					setTimeout( () => {
						this.describeTableUntilDeleted( tableName )
						.then( results => resolve( results ) )
						.catch( err => reject( err ) );
					}, millisBetweenChecks );
                }
            });
		});
	}

}


