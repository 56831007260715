import { TableDocumentObject } from './TableHandler'
import { NamedObject } from './NamedObject';

/** 
 * Object that holds the company ID and name and the property ID and name.  Used for select lists.
 */
export class PropertyName extends TableDocumentObject implements NamedObject {

	/**
	 * @param companyId ID of advertising company
	 * @param companyName Name of company
	 * @param propertyId ID of advertising property
	 * @param propertyName Name of property
	 */
	constructor(
		public companyId: number = null,
		public companyName: string = null,
		public propertyId: number = null,
		public propertyName: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): PropertyName {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId": this.companyId,
			"propertyId": this.propertyId,
        };
	}
	
	getDisplayName(): string {
		return this.companyId + ': ' + this.companyName + ' - ' + this.propertyId + ': ' + this.propertyName;
	}

	getKeyString() {
		return this.companyId+'_'+this.propertyId;
	}

}
