import { TableHandler, TableDocumentObject } from './TableHandler'
import { RestaurantLayout } from './RestaurantLayout';

/** Table for restaurant layouts. */
export class RestaurantLayoutTable extends TableHandler<RestaurantLayout> {

	public constructor() {
		super( 'bookcliffsoftware-RestaurantLayout' );
	}
	
	public fromDataItem( item: any ): RestaurantLayout {
		return new RestaurantLayout().fromDataItem( item );
	}

	public getAll( companyId: number, propertyId: number ): Promise<RestaurantLayout[]> {
    	return new Promise<RestaurantLayout[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

}

