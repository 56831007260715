import { Component, ElementRef, AfterViewInit, ViewChild } from "@angular/core";
import { PollyService } from "../../service/polly.service";
import { Global } from "../../service/Global";

/**
 * Provides a function to play synthesized speech.
 */
@Component({
    selector: 'speech-synthesizer',
    templateUrl: './speech-synthesizer.html'
})
export class SpeechSynthesizerComponent {
	// A reference to the audio element from our template
	@ViewChild('audio') public audio: ElementRef;

	private audioElement: HTMLAudioElement; 
	
    constructor(
        private pollyService: PollyService )
    {}

	public ngAfterViewInit() {
		// Get the HTML audio element
		this.audioElement = this.audio.nativeElement;
	}

	pause() {
		this.audioElement.pause();
	}

	playSilence() {
		this.audioElement.src = '/assets/250-milliseconds-of-silence.mp3';
		this.audioElement.play();
	}

    /**
     * Synthesizes the speech for the given text.
     * @param text Words to speak.
     * @return Promise<void> when the speech playback is complete.
     */
    public synthesizeSpeech( text: string, logError=false ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			this.pollyService.synthesizeSpeech( text )
			.then( mp3 => {
				if (mp3 && mp3.ContentType == 'audio/mpeg' && mp3.AudioStream instanceof Buffer) {
					var uInt8Array = new Uint8Array( mp3.AudioStream );
					var arrayBuffer = uInt8Array.buffer;
					var blob = new Blob([arrayBuffer]);
					var url = URL.createObjectURL(blob);
					// console.log( 'before play, audio src='+this.audioElement.src);
					this.audioElement.src = url;
					this.audioElement.addEventListener("ended", () => {
						// Speech finished playing, resolve promise
						resolve();
					});
					this.audioElement.addEventListener("pause", () => {
						// console.log( 'Audio playback event: pause' );
						resolve();
					});
					this.audioElement.addEventListener("error", (e) => {
						Global.log( 'Audio playback event: error' + ' - ' + JSON.stringify(e,null,2) );
						resolve();
					});
					this.audioElement.play();
				} else {
					if (logError) {
						Global.log( 'Problem with audio stream buffer: ' + JSON.stringify( mp3, null, 2 ) );
					}
					reject( new Error( 'Problem with audio buffer.') );
				}
			})
			.catch( err => {
				let error = new Error( 'Error getting mp3 speech file: ' + err.message );
				if (logError) {
					Global.log( error.message + '\n' + err.stack );
				}
				reject( error );
			});
        });
    }

}