import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { PropertyAdvertiser } from "../../service/PropertyAdvertiser";
import { SiteName } from "../../service/SiteName";
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { IMyDpOptions } from 'mydatepicker';

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'property-advertiser',
	templateUrl: './property-advertiser.html',
	styleUrls: [ 'property-advertiser.css' ]
})
export class PropertyAdvertiserComponent extends EditorDetail<PropertyAdvertiser> implements OnInit, OnDestroy {

	editedRow: PropertyAdvertiser = new PropertyAdvertiser();

	originalRow: PropertyAdvertiser;

	siteNames: SiteName[] = [];

	selectedSiteKey: string = null;

	timeZone: string;

	@ViewChild('propertyAdvertiserModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	private myDatePickerOptions: IMyDpOptions = { dateFormat: 'mm/dd/yyyy' };
	
	// Data model used for date picker, initialized to today.
	private startDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };
	
	// Data model used for date picker, initialized to today.
	private endDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
    )
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/** Return a deep copy of the given object. */
	clone( row: PropertyAdvertiser ): PropertyAdvertiser {
		return new PropertyAdvertiser().fromDataItem( row.toDataItem() );
	}

	createNewDefaultRow(): PropertyAdvertiser {
		return new PropertyAdvertiser();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to remove advertiser ' + this.originalRow.adSiteId + ' from the list?';
	}

	initializeDialog() {
		this.selectedSiteKey = this.editedRow.adCompanyId+'_'+this.editedRow.adPropertyId+'_'+this.editedRow.adSiteId;

		// Initialize start date
		this.startDateModel = null;
		if (this.editedRow.startDate) {
			this.startDateModel = { jsdate: this.editedRow.startDate, date: { year: this.editedRow.startDate.getUTCFullYear(), month: this.editedRow.startDate.getUTCMonth()+1, day: this.editedRow.startDate.getUTCDate() } };
		}

		// Initialize end date
		this.endDateModel = null;
		if (this.editedRow.endDate) {
			this.endDateModel = { jsdate: this.editedRow.endDate, date: { year: this.editedRow.endDate.getUTCFullYear(), month: this.editedRow.endDate.getUTCMonth()+1, day: this.editedRow.endDate.getUTCDate() } };
		}
	
	}

	isValid(): boolean {
		let valid = true;
		if (this.selectedSiteKey.endsWith('null')) {
			this.errorMessage = 'Please select a site.';
		} else if (this.startDateModel == null || this.startDateModel.jsdate == null) {
			this.errorMessage = 'Please enter a start date.';
		} else {
			this.editedRow.startDate = this.startDateModel.jsdate;
			this.editedRow.endDate = null;
			if (this.endDateModel && this.endDateModel.jsdate) {
				this.editedRow.endDate = this.endDateModel.jsdate;
				if (this.editedRow.endDate.getTime() < this.editedRow.startDate.getTime()) {
					this.errorMessage = 'Please enter an end date that is later than the start date.';
				}
			}

			// Get ID's from selected site key
			let ids=this.selectedSiteKey.split('_');
			this.editedRow.adCompanyId = Number.parseInt( ids[0] );
			this.editedRow.adPropertyId = Number.parseInt( ids[1] );
			this.editedRow.adSiteId = Number.parseInt( ids[2] );
		}
		return this.errorMessage == null;
	}

	getSiteName( siteName: SiteName ): string {
		return siteName.siteName + ', ' + siteName.getAddressString() + ' (' + siteName.companyName + ')';
	}

}