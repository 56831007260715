import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from "@angular/core";
import {UserLoginService} from "../../service/user-login.service";
import {UUIDService} from "../../service/uuid.service";
import { RekognitionService, MatchingFace } from '../../service/rekognition.service';
import { S3Service } from '../../service/s3.service';
import { PollyService } from '../../service/polly.service';
import { SpeechSynthesizerComponent } from '../speech-synthesizer/speech-synthesizer.component';
import { AudioVisualizerComponent } from '../audio-visualizer/audio-visualizer.component';
import { CacheService } from '../../service/cache.service';
import { Employee } from '../../service/Employee';
import { EmployeeTable } from '../../service/EmployeeTable';
import { ConversationService } from '../../service/conversation.service';
import { WorkedShiftTable, WorkedShift, TimePunch, PunchTypes } from '../../service/WorkedShiftTable';
import { Global } from '../../service/Global';
import { TitleNavComponent } from "../title-nav/title-nav.component"
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';
import { EmployeeComponent } from "../employee/employee.component";

@Component({
	selector: 'timeclock',
	templateUrl: './timeclock.html',
	styleUrls: ['./timeclock.css'],
})
export class TimeclockComponent implements OnInit, OnDestroy {
    private errorMessage: string = null;
    private successMessage: string = null;
    private infoMessage: string = null;
	private recognizedEmployee = null;
	private openShift = null;
	private showRecognizeButton = true;
	private showClockInOptions = false;
	private showClockOutOptions = false;
	private audioSupported: boolean;
	
	/** 
	 * Set to true when explaining options to user, if user clicks a button before speech is done
	 * then this is set to false and the conversation does not start.
	 */
	private promptingUser = false;
	
	@ViewChild(ImageCaptureComponent) private imageCaptureComponent: ImageCaptureComponent;
	
	@ViewChild(SpeechSynthesizerComponent) private speechSynthesizerComponent: SpeechSynthesizerComponent;

	@ViewChild(AudioVisualizerComponent) private audioVisualizerComponent: AudioVisualizerComponent;

	@ViewChild(EmployeeComponent) private detailComponent: EmployeeComponent;
	
    constructor( 
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private uuidService: UUIDService,
		private rekognitionService: RekognitionService,
		private s3Service: S3Service,
		private pollyService: PollyService,
		private cache: CacheService,
		private workedShiftTable: WorkedShiftTable,
		private conversation: ConversationService ) 
	{
        // console.log("TimeclockComponent constructor");
    }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.errorMessage = null;
		this.successMessage = null;
		this.infoMessage = null;
		this.userService.checkLoggedIn( () => this.initialize() );
		//this.speechSynthesizerComponent.synthesizeSpeech( 'There\'s no need to worry, underdog is furry.' );
	}

	ngAfterViewInit() {
		Global.log('TimeclockComponent.ngAfterViewInit window.screen: '+window.screen.width+'x'+window.screen.height );
		// Global.addContentAreaResizeListener();
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-clock-o fa-fw"></i> VIP Timeclock' );
		this.imageCaptureComponent.initialize()
		.then( supported => this.imageCaptureComponent.turnCameraOn() )
		.catch( error => {
			Global.logError( 'Error initializing image capture.', error );
			alert( 'Problem initializing video.  Please try again.' );
		})
	}

	ngOnDestroy() {
	}

	initialize() {
	}

	recognize() {
		this.showRecognizeButton = false;
		
		// Play 1/4 second of silence triggered by the user action so Chrome on Android will let us play other sounds later.
		this.speechSynthesizerComponent.playSilence();
		
		this.errorMessage = null;
		this.successMessage = null;
		this.infoMessage = null;
		this.captureImage();
	}

	editProfile() {
		this.promptingUser = false;
		this.speechSynthesizerComponent.pause();
		this.conversation.stop();
		if (!this.recognizedEmployee) {
			this.recognizedEmployee = new Employee();
		}
		this.detailComponent.allowDelete = false;
		this.detailComponent.showModalDialog( this.recognizedEmployee, false, (action,arg) => {
			if (action == 'update') {
				// User saved changes, update the company in the cache
				this.detailComponent.hideDialog();
				this.recognizedEmployee = arg;
			} else if (action == 'cancel') {
				this.detailComponent.hideDialog();
			} else if (action == 'error') {
				this.detailComponent.hideDialog();
				Global.logError( 'Error editing data', arg );
				this.errorMessage = arg.message;
			}
		});
	}

	/**
	 * Capture an image from the video stream and put it in S3 in the company/site/camera folder.
	 * @param companyId ID of the company.
	 * @param siteId ID of the site where the image was taken.
	 * @param cameraId ID of the camera that took the image.
	 */
	captureImage() {
		this.imageCaptureComponent.captureImage()
		.then( dataURL => {
			let promises = [];
			promises.push( this.conversation.supportsAudio() );
				
			// console.log( 'Captured image,  base 64 encoded length=', dataURL.length, ' bytes' );
			// console.log( 'First 60 bytes : ', dataURL.substr( 0, 60 ) );
			let base64DataString = dataURL.replace(/^data:image\/\w+;base64,/, "");
			let objectKey = this.cache.currentCompany.companyId+'/'+this.cache.currentSite.siteId+'/'+ this.uuidService.UUID() + '.jpg';

			promises.push( this.s3Service.upload( base64DataString, Global.timeclockPhotoBucketName, objectKey ) );
			return Promise.all( promises );
		})			
		.then( ( results ) => {
			// console.log('promises reulults='+JSON.stringify(results,null,2));
			this.audioSupported = results[0];
			let objectKey = results[1];
			return this.rekognitionService.indexFacesInS3ImageFile( Global.timeclockPhotoBucketName, objectKey, Global.getEmployeeFaceCollectionName( this.cache.currentCompany.companyId ) );
		})
		.then( data => {
			// successful response
			if (!data.FaceRecords || data.FaceRecords.length == 0) {
				this.showAndSayError( 'Sorry, I did not find a face in the picture.  Please stand in front of the camera so your face is clear in the picture and try again.' );
				this.hidePhoto();
			} else if (data.FaceRecords.length > 1) {
				this.showAndSayError( 'Sorry, I see more than one face in the picture.  Please make sure you are the only one in the picture and try again.' );
				data.FaceRecords.forEach( face => {
					this.rekognitionService.deleteFaceFromCollection( face.Face.FaceId, Global.getEmployeeFaceCollectionName( this.cache.currentCompany.companyId ), true );
				});
				this.hidePhoto();
			} else if (data.FaceRecords.length == 1) {
				//  We found a face, see if we can figure out which employee it is.
				let face = data.FaceRecords[0].Face;
				// console.log('found 1 face id='+face.faceId+', confidence='+face.confidence );
				this.recognizeFace( face.FaceId )
				.then( result => {
					this.rekognitionService.deleteFaceFromCollection( face.FaceId, Global.getEmployeeFaceCollectionName( this.cache.currentCompany.companyId ), true );
				}).catch( err => {
					this.rekognitionService.deleteFaceFromCollection( face.FaceId, Global.getEmployeeFaceCollectionName( this.cache.currentCompany.companyId ), true );
					this.hidePhoto();
				})
			}
		})
		.catch( err => {
			Global.logError( 'Error indexing faces in timeclock image.', err );
			this.showAndSayError( 'Sorry, we had a problem searching for faces in the picture.  Please try again.' );
			this.hidePhoto();
		});
	}

	/**
	 * Search for a face that matches the given faceId and, if found, look up the employee with the matching faceId.
	 * @param faceId ID of the face for which we are searching for a match.
	 * @return Promise<string> email address of the employee with the matching face or null if matching face or employee not found.
	 */
	private recognizeFace( faceId: string ): Promise<string> {
		this.recognizedEmployee = null;
		return new Promise<string>( (resolve, reject) => {
			let matchingFaces: MatchingFace[] = [];
			this.rekognitionService.searchForMatchingFaces( faceId, Global.getEmployeeFaceCollectionName( this.cache.currentCompany.companyId ), 80 )
			.then( (foundMatchingFaces: MatchingFace[]) => {
				matchingFaces = foundMatchingFaces;
				this.getEmployeeFromFaceIdList( matchingFaces, 0 )
				if (matchingFaces.length == 0) {
					return null;
				} else {
					return this.getEmployeeFromFaceIdList( matchingFaces, 0 );
				}
			})
			.then( (employee: Employee) => {
				if (employee) {
					// We found the employee with the matching face ID
					this.recognizedEmployee = employee;
					// Show the employees name across the bottom of the picture
					this.infoMessage =  employee.firstName + ' ' + employee.lastName; // + ' - ' + employee.email;
					let name = employee.firstName && employee.firstName.trim().length > 0 ? ' '+ employee.firstName : '';
					
					// See if employee is already working a shift
					this.workedShiftTable.getMostRecentOpenShift( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, employee.employeeId )
					.then( shift => {
						this.openShift = shift;
						this.promptingUser = true;
						if (shift) {
							// Employee has an open shift
							this.showClockOutOptions = true;
							this.showAndSayMessage( 'Hello ' + name + '.  What would you like to do?  You can say clock out, edit profile, or that\'s not my name.' )
							.then( data => {
								if (this.promptingUser) {
									this.conversation.start( this.handleConversationCommands.bind( this ) );
								}
							});
						} else {
							// Employee is not working, clocking in is an option
							this.showClockInOptions = true;
							this.showAndSayMessage( 'Hello ' + name + '.  What would you like to do?  You can say clock in, edit profile, or that\'s not my name.' )
							.then( data => {
								if (this.promptingUser) {
									this.conversation.start( this.handleConversationCommands.bind( this ) );
								}
							});
						}
					}).catch( err => {
						Global.logError( 'Error getting open shifts for employee.', err );
						this.showAndSayError( 'Sorry, I had a problem looking up your shifts.  Please try again.' );
						resolve( null );
					})

					// console.log( 'Found person with matching face ID: '+JSON.stringify( foundPerson ) );
					resolve( employee.employeeId );
				} else {
					if ((!matchingFaces) || matchingFaces.length == 0) {
						// We didn't find a matching face ID
						this.showAndSayError( 'Sorry, I did not find an employee face that matches yours.  Please try again.' );
						resolve( null );
					} else {
						let list = '';
						matchingFaces.forEach( matchingFace => {
							if (list.length > 0) {
								list += ', ';
							}
							list += matchingFace.faceId;
						});
						Global.log( 'This should not happen but there is no employee record for the matching face IDs: ' + list + ' at company ' + this.cache.currentCompany.companyId + '.  Create a person face record for both face IDs.' );
						this.showAndSayError( 'Sorry, I could not find your employee record.  Please try again.' );
						resolve( null );
					}
				}
			})
			.catch( err => {
				this.showAndSayError( 'Sorry, we had a problem searching for matching faces.  Please try again.' );
				resolve( null );
			});
		});
	}

	/**
	 * Check each face in the list until one is found that matches an employee.
	 * @param matchingFaces List of face ID's.
	 * @param startingIndex Index to start with.
	 * @returns Found employee or null if no employee found with face ID that matches any of the ID's in the list.
	 */
	private getEmployeeFromFaceIdList( matchingFaces: MatchingFace[], startingIndex: number ): Promise<Employee> {
		return new Promise<Employee>( (resolve,reject) => {
			if ((!matchingFaces) || startingIndex < 0 || startingIndex >= matchingFaces.length) {
				resolve( null );
			} else {
				new EmployeeTable().getByFaceId( this.cache.currentCompany.companyId, matchingFaces[startingIndex].faceId )
				.then( (employee: Employee) => {
					if (employee) {
						resolve( employee );
					} else {
						// No employee found for starting index, try the next index
						this.getEmployeeFromFaceIdList( matchingFaces, startingIndex+1 )
						.then( employee => {
							resolve( employee );
						})
						.catch( error => reject( error ) );
					}
				})
				.catch( error => reject( error ) );
			}
		});
	}

	private handleConversationCommands( params: any ) {
		if (!params) {
			Global.log( 'Error: Missing parameters to conversation listener.');
		} else if ( params.command == 'listen' ) {
			this.audioVisualizerComponent.listen();
		} else if ( params.command == 'clear' ) {
			this.audioVisualizerComponent.clear();
		} else if ( params.command == 'visualizeAudioBuffer' && params.dataArray && params.bufferLength ) {
			this.audioVisualizerComponent.visualizeAudioBuffer( params.dataArray, params.bufferLength );
		} else if ( params.command == 'readyForFulfillment') {
			// console.log( 'User intent is ready for fulfillment: ' + params.data.intentName );
			if ('ClockIn' == params.data.intentName) {
				this.clockIn();
			} else if ('ClockOut' == params.data.intentName) {
				// console.log( 'User asked to clock out' );
				this.clockOut();
			} else if ('EditProfile' == params.data.intentName) {
				// this.wrapUpConversation( 'I don\'t know how to edit profiles yet.  Stay tuned for updates.' );
				this.editProfile();
			} else if ('StartOver' == params.data.intentName) {
				this.backToCamera();
			}
		} else if (params.command == 'elicitIntent') {
			if (this.openShift) {
				// Employee has an open shift
				this.promptUserForResponse( 'I\'m sorry, I didn\'t understand.  You can say clock out, edit profile, or that\'s not my name.' );
			} else {
				// Employee is not working, clocking in is an option
				this.promptUserForResponse( 'I\'m sorry, I didn\'t understand.  You can say clock in, edit profile, or that\'s not my name.' );
			}
		} else if ( params.command == 'failed' ) {
			this.successMessage = null;
			this.showAndSayError( 'Sorry , I\'m having trouble understanding what you want.  Please try again.' )
			.then( () => {
				setTimeout( () => {
					this.errorMessage = null;
					// Force the change detector to run so the UI is updated
					this.changeDetectorRef.detectChanges();
				}, 1500 );
			});
		} else if ( params.command == 'updateAudioMessage' ) {
			this.updateAudioMessage( params.message );
		} else {
			let maxErrorLength = 200
			let err = JSON.stringify( params, null, 2 );
			if (err.length > maxErrorLength) {
				err = err.substr( 0, maxErrorLength );
			}
			Global.log( 'Error: Invalid parameters to conversation listener: ' + err );
		}
	}

	private wrapUpConversation( finalMessage: string ) {
		this.recognizedEmployee = null;
		this.updateAudioMessage( 'Speaking...' );
		this.showAndSayMessage( finalMessage )
		.then( () => {
			this.updateAudioMessage( '' );
			setTimeout( () => {
				this.showAndSayMessage( '' );
				this.hidePhoto();
			}, 1500 );
		});
	}

	private updateAudioMessage( message: string ) {
		this.audioVisualizerComponent.audioMessage = message;
		// Force the change detector to run so the UI is updated
		this.changeDetectorRef.detectChanges();
	}

	private showAndSayMessage( message: string, logError=true ): Promise<void> {
		this.successMessage = message;
		// Force the change detector to run so the UI is updated
		this.changeDetectorRef.detectChanges();
		return this.synthesizeSpeech( message, logError );
	}

	private showAndSayError( message: string, logError=true ): Promise<void> {
		this.errorMessage = message;
		// Force the change detector to run so the UI is updated
		this.changeDetectorRef.detectChanges();
		return this.synthesizeSpeech( message, logError );
	}

	private synthesizeSpeech( message: string, logError=true ): Promise<void> {
		if (this.audioSupported) {
			return this.speechSynthesizerComponent.synthesizeSpeech( message, logError );
		} else {
			// Audio is not supported so don't synthesize speech.
			return Promise.resolve();
		}
	}

	private promptUserForResponse( message: string ): void {
		this.showAndSayMessage( message, false )
		.then( data => {
			this.conversation.startRecording();
		})
		.catch( err => {
			this.showAndSayError( 'Sorry, we were unable to record your voice.  Please try again.', true );
			Global.logError( 'Error in startRecording.', err );
		});
	}

	private hidePhoto() {
		this.imageCaptureComponent.turnCameraOn();
		this.infoMessage = '';
		this.showRecognizeButton = true;
		this.showClockInOptions = false;
		this.showClockOutOptions = false;
	}

	clockIn() {
		this.promptingUser = false;
		this.conversation.stop();
		this.speechSynthesizerComponent.pause();
		// Add new shift start record
		let shiftStartTime = new Date();
		let timePunch = new TimePunch( shiftStartTime, PunchTypes.CLOCK_IN );
		let workedShift = new WorkedShift( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, this.recognizedEmployee.email, shiftStartTime, PunchTypes.CLOCK_IN, [ timePunch ]  );
		this.workedShiftTable.put( workedShift )
		.then( result => {
			this.wrapUpConversation( 'Ok, you are now clocked in.  Have a great day!' )
		}).catch( err => {
			this.showAndSayError( 'Sorry, we were unable clock you in.', true );
			Global.logError( 'Error clocking employee in.', err );
		})
	}

	clockOut() {
		this.promptingUser = false;
		this.conversation.stop();
		this.speechSynthesizerComponent.pause();
		// Update shift record
		let punchTime = new Date();
		this.openShift.addTimePunch( new TimePunch( punchTime, PunchTypes.CLOCK_OUT ) );
		this.workedShiftTable.put( this.openShift )
		.then( result => {
			this.wrapUpConversation( 'Ok, you are now clocked out.  Goodbye!' );
		}).catch( err => {
			this.showAndSayError( 'Sorry, we were unable clock you out.', true );
			Global.logError( 'Error clocking employee out.', err );
		})
	}
	
	backToCamera() {
		this.promptingUser = false;
		this.conversation.stop();
		this.speechSynthesizerComponent.pause();
		this.wrapUpConversation( 'Ok, starting over.' );
	}

}
