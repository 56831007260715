import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { CacheService } from "../../service/cache.service";
import { Property } from "../../service/Property"
import { Global } from '../../service/Global';
import { EditorDetail } from "../../service/EditorDetail";
import { ConfirmComponent } from "../confirm/confirm.component";
import { InputTextComponent } from "../input-text/input-text.component";
import { AdCategory } from "../../service/AdCategory";
import { GetAdCategoriesCmd } from '../../command/GetAdCategoriesCmd';
import { DefaultAd } from "../../service/DefaultAd";
import { DefaultAdComponent } from "../default-ad/default-ad.component";
import { ItemCategoryComponent } from "../item-category/item-category.component";
import { ItemComponent } from "../item/item.component";
import { ItemMenu } from "../../service/ItemMenu"
import { ItemCategory } from "../../service/ItemCategory"

/**
 * Company editing component.
 */
@Component({
    selector: 'property',
    templateUrl: './property.html',
	styleUrls: ['./property.css'],
})
export class PropertyComponent extends EditorDetail<Property> implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('propertyModalButton') private modalButtonRef: ElementRef;

	@ViewChild('focus') private focusRef: ElementRef;

	@ViewChild(InputTextComponent) private inputTextComponent: InputTextComponent;

	@ViewChild(DefaultAdComponent) private defaultAdComponent: DefaultAdComponent;

	@ViewChild(ItemCategoryComponent) private itemCategoryComponent: ItemCategoryComponent;

	@ViewChild(ItemComponent) private itemComponent: ItemComponent;

	/** List of ad categories loaded so we have their names. */
	public adCategories: AdCategory[] = [];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	getTimeZoneNames(): string[] {
		return Global.getTimeZoneNames();
	}

	loadAdCategories() {
		new GetAdCategoriesCmd().do( this.cache.currentCompany.companyId )
		.then( rows => {
			this.adCategories = rows;
		})
		.catch( error => this.handleError( error ) );
	}

	getAdCategoryName( adCategoryId: number ): string {
		let name = '# category name not found #';
		for (let i=0; i<this.adCategories.length; i++) {
			if (this.adCategories[i].adCategoryId == adCategoryId) {
				name = this.adCategories[i].name;
				break;
			}
		}
		return name;
	}

	getSpeechSnippet( speech: string ): string {
		let snippet = '';
		if (speech && speech.length > 0) {
			snippet = speech;
			if (speech.length > 45) {
				snippet = speech.substr(0,42)+'...';
			}
		}
		return snippet;
	}

	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): Property {
		return new Property();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		this.loadAdCategories();
	}

	/** Return a deep copy of the given object. */
	clone( row: Property ): Property {
		return new Property().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		if ((!this.editedRow.name) || this.editedRow.name.trim().length == 0) {
			this.errorMessage = 'Please enter a property name.';
			return false;
		}
		if ((!this.editedRow.timeZone) || this.editedRow.timeZone.length == 0) {
			this.errorMessage = 'Please select a time zone.';
			return false;
		}
		if (this.editedRow.beyondTvDomain != null && this.editedRow.beyondTvDomain.trim().length == 0) {
			this.editedRow.beyondTvDomain = null;
		}
		if (this.editedRow.beyondTvHotelId != null && this.editedRow.beyondTvHotelId.trim().length == 0) {
			this.editedRow.beyondTvHotelId = null;
		}
		return true;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete property ' + this.editedRow.propertyId + ': ' + this.editedRow.name + '?';
	}

	insertEmail() {
		this.inputTextComponent.configure( 'Enter Email Address', 'Email Address:', '100', 'email address', true );
		this.handleChildInsert( new String(), this.editedRow.feedbackEmails, this.inputTextComponent );
	}

	editEmail( index: number ) {
		this.inputTextComponent.configure( 'Enter Email Address', 'Email Address:', '100', 'email address', true );
		this.handleChildEdit( index, this.editedRow.feedbackEmails, this.inputTextComponent );
	}

	insertPhone() {
		this.inputTextComponent.configure( 'Enter Phone Number', 'Phone number:', '20', '1-206-555-0100', true );
		this.handleChildInsert( new String(), this.editedRow.feedbackPhones, this.inputTextComponent );
	}

	editPhone( index: number ) {
		this.inputTextComponent.configure( 'Enter Phone Number', 'Phone number:', '20', '1-206-555-0100', true );
		this.handleChildEdit( index, this.editedRow.feedbackPhones, this.inputTextComponent );
	}

	insertDefaultAd() {
		this.defaultAdComponent.adCategories = this.adCategories;
		if (this.editedRow.defaultAds == null) {
			this.editedRow.defaultAds = [];
		}
		this.handleChildInsert( new DefaultAd(), this.editedRow.defaultAds, this.defaultAdComponent );
	}

	editDefaultAd( index: number ) {
		this.defaultAdComponent.adCategories = this.adCategories;
		this.handleChildEdit( index, this.editedRow.defaultAds, this.defaultAdComponent );
	}

	getRoomFoodCategories(): ItemCategory[] {
		return this.editedRow.roomServiceMenu.itemCategories;
	}

	insertRoomFoodCategory() {
		// Share the text input component and the item component with the item category component 
		// because they all use input text component for synonyms.
		this.itemCategoryComponent.inputTextComponent = this.inputTextComponent;
		this.itemCategoryComponent.itemComponent = this.itemComponent;
		this.handleChildInsert( new ItemCategory(), this.editedRow.roomServiceMenu.itemCategories, this.itemCategoryComponent );
	}

	editRoomFoodCategory( index: number ) {
		// Share the text input component and the item component with the item category component 
		// because they all use input text component for synonyms.
		this.itemCategoryComponent.inputTextComponent = this.inputTextComponent;
		this.itemCategoryComponent.itemComponent = this.itemComponent;
		this.handleChildEdit( index, this.editedRow.roomServiceMenu.itemCategories, this.itemCategoryComponent );
	}

	getRoomItemCategories(): ItemCategory[] {
		return this.editedRow.roomItemMenu.itemCategories;
	}

	insertRoomItemCategory() {
		// Share the text input component and the item component with the item category component 
		// because they all use input text component for synonyms.
		this.itemCategoryComponent.inputTextComponent = this.inputTextComponent;
		this.itemCategoryComponent.itemComponent = this.itemComponent;
		this.handleChildInsert( new ItemCategory(), this.editedRow.roomItemMenu.itemCategories, this.itemCategoryComponent );
	}

	editRoomItemCategory( index: number ) {
		// Share the text input component and the item component with the item category component 
		// because they all use input text component for synonyms.
		this.itemCategoryComponent.inputTextComponent = this.inputTextComponent;
		this.itemCategoryComponent.itemComponent = this.itemComponent;
		this.handleChildEdit( index, this.editedRow.roomItemMenu.itemCategories, this.itemCategoryComponent );
	}

}