import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {CacheService} from "../../service/cache.service";
import { Person } from '../../service/Person';
import { PersonTable } from '../../service/PersonTable';
import { Global } from '../../service/Global';

/**
 * Person editing component.
 */
@Component({
    selector: 'person-detail',
    templateUrl: './person-detail.html',
	styleUrls: ['./person-detail.css'],
})
export class PersonDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    person: Person = new Person();
	errorMessage: string = null;
	
	@ViewChild('personModalButton') public personModalButtonRef: ElementRef; private personModalButton: HTMLButtonElement;

	@ViewChild('cardHeading') public cardHeadingRef: ElementRef; private cardHeading: HTMLInputElement;

	@ViewChild('star0') public star0Ref: ElementRef;
	@ViewChild('star1') public star1Ref: ElementRef;
	@ViewChild('star2') public star2Ref: ElementRef;
	@ViewChild('star3') public star3Ref: ElementRef;
	@ViewChild('star4') public star4Ref: ElementRef;

	private stars: HTMLElement[] = [];

	private onCloseCallback: Function;
	
	constructor(
		public router: Router, 
        public userService: UserLoginService,
        public cacheService: CacheService,
        private personTable: PersonTable )
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
        // console.log('PersonDetailComponent.ngOnDestroy()');
	}

	ngAfterViewInit() {
		console.log('person-detail ngAfterViewInit' );
		this.personModalButton = this.personModalButtonRef.nativeElement;
		this.cardHeading = this.cardHeadingRef.nativeElement;
		this.stars[0] = this.star0Ref.nativeElement;
		this.stars[1] = this.star1Ref.nativeElement;
		this.stars[2] = this.star2Ref.nativeElement;
		this.stars[3] = this.star3Ref.nativeElement;
		this.stars[4] = this.star4Ref.nativeElement;
	}

	showModalDialog( onCloseCallback: ( saved: boolean ) => void ) {
		console.log( 'Editing '+JSON.stringify( this.person, null, 2 ) );
        if (!this.person) {
			// There was no Person in the cache to edit
			alert( 'No person record to edit!  Click Cancel to continue.' );
		} else if (!this.person.personId) {
			alert( 'Cannot save data without an ID!  Click Cancel to continue.' );
		} else {
			this.personModalButton.click();
			this.drawStars();
			// Put keyboard focus on heading field after dialog is displayed
			setTimeout( () => this.cardHeading.focus(), 1000 );
			this.onCloseCallback = onCloseCallback;
		}
    }
	
	onClickStar( index: number ) {
		if ((index+1) != this.person.points) {
			// User clicked a different star rating
			this.person.points = index+1;
		} else {
			// User clicked on the highest star the person has, reduce their points by 1
			this.person.points--;
		}
		this.drawStars();
	}

	drawStars() {
		for (let i=0; i<5; i++) {
			if (this.person && this.person.points && this.person.points > i) {
				// Draw a gold star if person has i points
				if (this.stars[i].classList.contains( 'fa-star-o' )) {
					this.stars[i].classList.remove( 'fa-star-o' );
					this.stars[i].classList.add( 'fa-star' );
					this.stars[i].style.color = '#c49f47'; // Cool gold color
				}
			} else {
				// Draw a hollow star if person has <= i points
				if (this.stars[i].classList.contains( 'fa-star' )) {
					this.stars[i].classList.remove( 'fa-star' );
					this.stars[i].classList.add( 'fa-star-o' );
					this.stars[i].style.color = 'black';
				}
			}
		}
	}

	/**
	 * Set the person object to be edited.
	 * @param person Person to be edited.
	 */
	setPerson( person: Person ) {
		this.person = person;
	}

	/**
	 * Close the dialog and if caller specified a function to be called when dialog is closed then call it
	 * @param saved True if record was saved, false if user clicked Cancel to close without saving.
	 */
	closeDialog( saved: boolean ) {
		// Hide the dialog
		this.personModalButton.click();
		if (this.onCloseCallback) {
			this.onCloseCallback( saved );
		}
	}

	/** Close the dialog without saving changes. */
	onCancel() {
		this.closeDialog( false );
	}

    onSave() {
		if (this.person.personId && this.person.companyId && this.person.collectionId) {
			this.personTable.put( this.person )
			.then( () => {
				console.log("PersonTable.put succeeded.");
				this.closeDialog( true );
			})
			.catch( err => {
				// We failed to insert the Person record
				Global.logError("PersonTable.put failed.", err );
				this.errorMessage = 'Sorry, there was a problem saving the person\'s record.  ' + err.message;
			});
		} else {
			// There was no person ID
			this.errorMessage = 'Cannot save data without an ID!  Click Cancel to continue.';
		}
    }

}