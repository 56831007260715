import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";
import { DefaultAd } from "../../service/DefaultAd";
import { AdCategory } from "../../service/AdCategory";

/**
 * Component for editing a restaurant layout.
 */
@Component({
    selector: 'default-ad',
	templateUrl: './default-ad.html',
	styleUrls: [ 'default-ad.css' ]
})
export class DefaultAdComponent extends EditorDetail<DefaultAd> implements OnInit, OnDestroy {

	@ViewChild('DefaultAdModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	/** Currently selected ad category */
	public selectedAdCategoryId: string = '1';

	/** List of ad categories loaded so we have their names. */
	public adCategories: AdCategory[] = [];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Create a new row with default values. */
	createNewDefaultRow(): DefaultAd {
		return new DefaultAd();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	initializeDialog() {
		this.selectedAdCategoryId = this.editedRow.adCategoryId ? this.editedRow.adCategoryId.toString() : null;
	}

	/** Return a deep copy of the given object. */
	clone( row: DefaultAd ): DefaultAd {
		return new DefaultAd().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete this advertisement?'
	}

	isValid(): boolean {
		this.errorMessage = null;

		if (this.selectedAdCategoryId == null) {
			this.errorMessage = 'Please select a category.'
			return false;
		}
		
		if (!this.editedRow.speech || this.editedRow.speech.length == 0) {
			this.errorMessage = 'Please enter the speech you want Alexa to say for this ad.'
			return false;
		}

		this.editedRow.adCategoryId = Number.parseInt( this.selectedAdCategoryId );
		
		return true;
	}

}