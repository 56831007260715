import { Component, OnInit, ViewChild } from "@angular/core";
import { TitleNavComponent } from "../title-nav/title-nav.component"
import { CacheService } from "../../service/cache.service";

@Component({
    selector: 'securehome',
    templateUrl: './secureHome.html',
    styleUrls: ['./secureHome.css'],
})
export class SecureHomeComponent implements OnInit /*, LoggedInCallback*/ {

	@ViewChild(TitleNavComponent) private titleNavComponent: TitleNavComponent;
	
	constructor(
		private cache: CacheService
	) {}

    ngOnInit() {
		this.cache.titleNavComponent = this.titleNavComponent;
    }

}

