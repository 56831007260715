import { Component } from "@angular/core";
import { Global } from '../../service/Global';
import {IMyDpOptions} from 'mydatepicker';

export class DateTimeModel {
	
	constructor(
		public dateModel: any = null,
		public hours: string = null,
		public minutes: string = null,
		public ampm: string = "a",
	) {}

}

/**
 * Lets user enter a date and time with one minute resolution. */
@Component({
    selector: 'datetime',
	templateUrl: './datetime.html',
	styleUrls: [ 'datetime.css' ]
})
export class DateTimeComponent {
	

	private myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
	};
	
	// Data model used for date picker, initialized to today.
	// private dateModel: any = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	/** Data model used for editing. */
	private model: DateTimeModel = new DateTimeModel();

	private timeZone;

	constructor() {
	}

	/** Returns date from current control value in UTC time. */
	public getDateTime(): Date {
		let date: Date = this.model.dateModel ? this.model.dateModel.jsdate : null; 
		if (date) {
			// Convert hours to 24-hour format
			let hours = this.model.hours ? Number.parseInt( this.model.hours ) : 0;
			let minutes = this.model.minutes ? Number.parseInt( this.model.minutes ) : 0;
			if (this.model.ampm === "a") {
				hours = hours == 12 ? 0 : hours;
			} else {
				hours = hours == 12 ? 12 : hours+12;
			}
			date.setUTCHours( hours, minutes, 0, 0 )
			// Adjust date/time have UTC values instead of values in local time zone for which data was displayed
			date = Global.adjustDateByTimeZone( date, this.timeZone, false );
		}
		return date;
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 * @param timeZone Timezone for which data is to be displayed.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	setDateTime( dateTime: Date, timeZone: string ): void {
		this.timeZone = timeZone;
		this.model.dateModel = null;
		this.model.hours = null;
		this.model.minutes = null;
		this.model.ampm = 'a';
		
		if (dateTime) {
			// Adjust date/time have UTC values equal to values in local time zone for which data is to be displayed
			dateTime = Global.adjustDateByTimeZone( dateTime, this.timeZone );
			this.model.dateModel = { jsdate: dateTime, date: { year: dateTime.getUTCFullYear(), month: dateTime.getUTCMonth()+1, day: dateTime.getUTCDate() } };
			let hours = dateTime.getUTCHours();
			this.model.minutes = dateTime.getUTCMinutes().toString();
			if (hours > 11) {
				this.model.ampm = "p";
				this.model.hours = hours === 12 ? '12' : (hours - 12).toString();
			} else {
				this.model.ampm = "a";
				this.model.hours = hours === 0 ? '12' : hours.toString();
			}
		}
	}

	/** Returns error message to display if any part of the date/time has not been set, otherwise returns null. */
	public getNullValueError( fieldDescription: string ): string {
		if (!this.model.dateModel.jsdate) {
			return 'Please enter a ' + fieldDescription + ' date.';
		} else if (!this.model.hours) {
			return 'Please enter a ' + fieldDescription + ' hour.';
		} else if (!this.model.minutes) {
			return 'Please enter a ' + fieldDescription + ' minute.';
		}
		return null;
	}

}