import { Command } from "./Command";
import { RestaurantLayout } from "../service/RestaurantLayout";

export class DeleteRestaurantLayoutCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: RestaurantLayout ): Promise<any> {
		return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
	}

}
