import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { RestaurantSection } from './RestaurantSection';
import { RestaurantTableReservations } from './RestaurantTableReservations';
import { RestaurantReservationList } from './RestaurantReservationList';
import { RestaurantReservation } from './RestaurantReservation';

/** List of sections containing tables. */
export class RestaurantLayout extends TableDocumentObject {
	
	/**
	 * @param companyId ID of company
	 * @param propertyId ID of property
	 * @param layoutId ID of the layout
	 * @param name Name of the layout such as 'Default' or 'Extra Room Open'
	 * @param sections List of sections in the layout
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public layoutId: number = null,
		public name: string = null,
		public sections: RestaurantSection[] = [],
	) { 
		super(); 
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): RestaurantLayout {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.sections = null;
		if (item['sections']) {
			this.sections = [];
			item['sections'].forEach( childItem => this.sections.push( new RestaurantSection().fromDataItem( childItem ) ) );
		}
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;

		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['sections'] = null;
		if (this.sections) {
			item['sections'] = [];
			this.sections.forEach( childItem => item['sections'].push( childItem.toDataItem() ) );
		}
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"layoutId": this.layoutId, 
        };
	}

	/** Return list of section names separated by commas. */
	public getSectionList() {
		let list = '';
		for (let i=0; i<this.sections.length; i++) {
			if (i>0) {
				list += ', ';
			}
			list += this.sections[i].name;
		}
		return list;
	}

	public createRestaurantReservationList( siteId: number, startDateTime: Date, endDateTime: Date, intervalMinutes: number ): RestaurantReservationList {
		let list = new RestaurantReservationList( this.companyId, this.propertyId, siteId, startDateTime, endDateTime, intervalMinutes );
		// Add the list of tables in the seating layout with no times reserved
		this.sections.forEach( section => {
			section.tables.forEach( table => {
				list.tables.push( new RestaurantTableReservations( section.sectionId, table.tableId, table.seats, [] ) );
			})
		})								
		return list;
	}

}

