import { Command } from "./Command";
import { ActivitySignUpList } from "../service/ActivitySignUpList";

export class GetActivitySignUpListCmd extends Command {

	/**
	 * Get a row.
	 * @param key Key values of row to get.
	 */
	public do( key: ActivitySignUpList ): Promise<any> {
		return new Promise<ActivitySignUpList>( (resolve, reject) => {
			super.send( this.constructor.name, key.companyId, { "key": key.toDataItem() } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let row = results.data ? new ActivitySignUpList().fromDataItem( results.data ) : null;
				resolve( row );
			}).catch( err => reject( err ) );
		});
	}

}
