import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { CacheService } from '../../service/cache.service';
import { PurchaseDetails } from '../../service/PurchaseDetails';
import { Product } from "../../service/Product";
import { Global } from "../../service/Global";

/** Displays instructions for creating a new account. */
@Component({
    selector: 'create-advertiser-account-describe',
    templateUrl: './create-advertiser-account-describe.html',
    styleUrls: ['./create-advertiser-account-describe.css'],
})
export class CreateAdvertiserAccountDescribeComponent {

    constructor(
		private cache: CacheService,
	) {}

	ngOnInit() {
		this.cache.getProduct( Product.ROOM_GENIE_ADVERTISER )
		.then( product => {
			this.cache.purchaseDetails = new PurchaseDetails( product, new Date().getUTCDate(), Global.getNextBillingDate( new Date(), new Date().getUTCDate() ) );
			this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'click subscribe' );
		})
	}

}
