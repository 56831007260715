import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { GoogleChartsService } from "../../service/google-charts.service";
import { CacheService } from "../../service/cache.service";
import { Global } from '../../service/Global';
import { RoomUsage } from '../../service/RoomUsage';
import { GetRoomAndDailyUsageCmd } from '../../command/GetRoomAndDailyUsageCmd';
import { IntentUsage } from '../../service/IntentUsage';
import { DailyUsage } from '../../service/DailyUsage';

/** Displays charts of Room Genie usage for a period. */
@Component({
    selector: 'room-genie-usage',
    templateUrl: './room-genie-usage.html',
    styleUrls: ['./room-genie-usage.css'],
})
export class RoomGenieUsageComponent implements OnInit, AfterViewInit {

	private roomAverage = 0;
	private totalUses = 0;
	private uniqueRoomNumbers: string[] = [];
	private propertyId: number = 0;
	private chartData;
	/** Set to true in ngOnInit and false in ngOnDestroy */
	private componentIsInitialized = false;

	private dailyUsages: DailyUsage[] = [];
	private intentUsages: IntentUsage[] = [];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private googleCharts: GoogleChartsService,
		private cache: CacheService
	) {}

	ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.componentIsInitialized = true;
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngOnDestroy() {
		this.componentIsInitialized = false;
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-line-chart fa-fw"></i> Room Genie Usage' );
	}

    initialize() {
		if (this.cache.properties.length > 0) {
			this.propertyId = this.cache.properties[0].propertyId;
		}
		this.changeDetectorRef.detectChanges();
		this.loadDataAndUpdateView()
		.catch( err => {
			// Failure here usually means user token has expired so return to login
			Global.logError('Error drawing charts.', err );
			alert( 'Sorry, we had a problem loading the page. Please try again.' );
		});
	}

	private switchProperty( event ) {
		this.propertyId = this.propertyId == null ? 0 : Number.parseInt( this.propertyId.toString() );
		console.log( 'Switch to property '+this.propertyId );
		this.loadDataAndUpdateView();
	}

	private loadDataAndUpdateView(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			// Get the usage data between 11:59:59.999 tonight (at the site) and midnight 30 days ago
			let timeZone = this.cache.getTimeZone( this.propertyId );
			let endDate = Global.adjustDateByTimeZone( new Date(), timeZone );
			endDate.setUTCHours( 23, 59, 59, 999 ); // Remove time from date
			let startDate = new Date( (endDate.getTime()+1) - (30 * Global.millisInOneDay ) );
			// console.log('Get RoomUsage data for '+startDate.toISOString()+' thru '+endDate.toISOString() );
			new GetRoomAndDailyUsageCmd().do( this.cache.currentCompany.companyId, this.propertyId, startDate, endDate )
			.then( results => {
				this.dailyUsages = results.dailyUsages;
				this.intentUsages = this.summarizeIntentUsages();
				this.setupChartData( results.roomUsages );
				this.drawVisitChart();
				resolve();
			})
			.catch( err => {
				let errorMessage = 'Error loading room usage data.  Please try again.';
				Global.logError( 'Error loading room usage data.', err );
				reject( new Error( errorMessage ) );
			});
		})
	}
	
	summarizeIntentUsages(): IntentUsage[] {
		this.totalUses = 0;
		let map = new Map<string,IntentUsage>();
		this.dailyUsages.forEach( row => {
			// Combine usages that have the same voice, intent, and slots values
			if (row.intentUsages) {
				row.intentUsages.forEach( intentUsage => {
					this.totalUses += intentUsage.uses;
					let key = intentUsage.voice+','+intentUsage.intent+','+intentUsage.slots;
					let value = map.get( key );
					if (value) {
						value.uses += intentUsage.uses;
					} else {
						map.set( key, new IntentUsage().fromDataItem( intentUsage.toDataItem() ) );
					}
				})						
			}
		});
		// Convert map values to array
		let summaryUsages = Array.from( map.values() );
		// Sort array from highest to lowest
		let sortFunction = (a: IntentUsage, b: IntentUsage): number => {
			return b.uses - a.uses;
		};
		let sortedArray = summaryUsages.sort( sortFunction );
		return sortedArray;
	}

	onResizeVisitChart(event) {
		if (this.chartData) {
			this.drawVisitChart();
		}
	}

	setupChartData( data: RoomUsage[] ) {
		// Clear display data values so we can reload them
		this.roomAverage = 0;
		this.uniqueRoomNumbers = [];
		this.chartData = [];

		// Figure out today's date at the site
		let timeZone = this.cache.getTimeZone( this.propertyId );
		let now = new Date();
		let currentDateOnly = Global.adjustDateByTimeZone( now, timeZone );
		currentDateOnly.setUTCHours( 0, 0, 0, 0 );

		let newGuestCount = 0;
		let repeatGuestCount = 0;
		let dates: Date[] = [];
		for (let i=0; i<30; i++) {
			let indexDate = new Date( currentDateOnly.getTime() - ( (29-i) * Global.millisInOneDay ) );
			// Row of chart data includes date, number of rooms, and number of uses
			this.chartData.push( [ (indexDate.getUTCMonth()+1)+'/'+indexDate.getUTCDate(), 0, 0 ] );
		}

		// Calculate number of uses for each date on the chart
		this.dailyUsages.forEach( dailyUsage => {
			// Figure out which array element the summary date goes in
			let index = 29 - ( ( currentDateOnly.getTime() - dailyUsage.useDate.getTime() ) / Global.millisInOneDay );
			if (dailyUsage.intentUsages) {
				dailyUsage.intentUsages.forEach( intentUsage => {
					this.chartData[index][2] += intentUsage.uses;
				});
			}
		})


		// Calculate number of rooms that used skill for each date on the chart
		data.forEach( roomUsage => {

			// Figure out which array element the summary date goes in
			let index = 29 - ( ( currentDateOnly.getTime() - roomUsage.useDate.getTime() ) / Global.millisInOneDay );
			// console.log( 'date='+roomUsage.useDate.toISOString()+', index = '+index);

			// Update chart row
			this.chartData[index][1] += 1; // Add one room number
			// this.chartData[index][2] += roomUsage.voiceUses;
			// this.chartData[index][3] += roomUsage.webUses;

			// Update summary totals
			if (this.uniqueRoomNumbers.indexOf( roomUsage.roomNumber ) == -1) {
				this.uniqueRoomNumbers.push( roomUsage.roomNumber );
			}
			// if (roomUsage.voiceUses) {
			// 	this.totalUses += roomUsage.voiceUses;
			// }
			// if (roomUsage.webUses) {
			// 	this.totalUses += roomUsage.webUses;
			// }
			this.roomAverage = this.uniqueRoomNumbers.length == 0 ? 0 : Math.round( (this.totalUses*10) / (this.uniqueRoomNumbers.length) ) / 10;
		});
	}

	drawVisitChart() {
		this.googleCharts.loadLibraries()
		.then( () => {
			if (this.componentIsInitialized) {
				let dataTable = this.googleCharts.createDataTable();
				dataTable.addColumn('string', 'Date');
				dataTable.addColumn('number', 'Guest Rooms');
				dataTable.addColumn('number', 'Uses');
				// dataTable.addColumn('number', 'Web Uses');
				dataTable.addRows( this.chartData );
			
				let chartOptions = {
					// curveType: 'function',
					legend: { position: 'top' },
					pointSize: 5,
					backgroundColor: '#f8f8f8',
					hAxis: {
						gridlines: {
							count: -1,
						}
					},
					vAxis: {
						minValue: 0,
						viewWindow: { min: 0 },
					},
				};
			
				var wrapper = this.googleCharts.createChartWrapper({
					chartType: 'LineChart',
					dataTable: dataTable,
					options: chartOptions,
					containerId: 'visitChart'
				});
				// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
				wrapper.draw();
			}
		})
		.catch( err => {
			Global.logError('Error drawing average time line chart.', err );
			throw new Error( 'Error drawing average time line chart.  ' +  err );
		});
	}

}
