import { Injectable } from "@angular/core";

/** 
 * Load web worker script using webpack loader for web workers.
 * Using a web worker lets us handle recording and converting the audio data in a separate thread.
 */
import * as MyWorker from "worker-loader!./worker";

/**
 * Recorder object sets up the onaudioprocess callback and communicates with the web worker to perform audio actions.
 */
@Injectable()
export class RecorderService {

	private audio_context: AudioContext;
	private audio_stream: MediaStream;
	private recording = false;
	private initialized = false;
	private currCallback;
	private start;
	private silenceCallback;
	private visualizationCallback;
	private analyser;

	// Web worker that handles recording the audio and converting data on separate thread.
	private worker: Worker;

	initialize() {
		// console.log('RecorderService.initialize');
		let source: MediaStreamAudioSourceNode = this.audio_context.createMediaStreamSource( this.audio_stream );
		// Initialize web worker
		this.worker = new MyWorker();
		this.worker.onmessage = message => {
			// console.log('got msg from worker: '+JSON.stringify( message.data, null, 2 ) );
 			var blob = message.data;
 			this.currCallback(blob);
		}

		// Create a ScriptProcessorNode with a bufferSize of 4096 and a single input and output channel
		let node = source.context.createScriptProcessor(4096, 1, 1);
		
		this.worker.postMessage({
			command: 'init',
			config: {
				sampleRate: source.context.sampleRate,
			}
		});

		/**
		 * The onaudioprocess event handler of the ScriptProcessorNode interface. It is the EventHandler to be 
		 * called for the audioprocess event that is dispatched to ScriptProcessorNode node types. 
		 * @param {AudioProcessingEvent} audioProcessingEvent - The audio processing event.
		 */
		node.onaudioprocess = audioProcessingEvent => {
			// console.log('initialize e recording='+this.recording);
			if (!this.recording) {
				return;
			}
			// console.log('initialize e - post record msg to worker');
			
			this.worker.postMessage({
				command: 'record',
				buffer: [
					audioProcessingEvent.inputBuffer.getChannelData(0),
				]
			});
			// console.log('initialize f');
			this.startSilenceDetection();
			// console.log('initialize g');
		};

		this.analyser = source.context.createAnalyser();
		this.analyser.minDecibels = -90;
		this.analyser.maxDecibels = -10;
		this.analyser.smoothingTimeConstant = 0.85;

		source.connect(this.analyser);
		this.analyser.connect(node);
		node.connect(source.context.destination);
	}

	/**
	 * Sets the silence and viz callbacks, resets the silence start time, and sets recording to true.
	 * @param {?onSilenceCallback} onSilence - Called when silence is detected.
	 * @param {?visualizerCallback} visualizer - Can be used to visualize the captured buffer.
	 */
	record(onSilence, visualizer) {
		// console.log('recorder.record');
		this.silenceCallback = onSilence;
		this.visualizationCallback = visualizer;
		this.start = Date.now();
		this.clear(); // Clear the previously recorded audio buffer (if any)
		this.recording = true;
	};

	/**
	 * Sets recording to false.
	 */
	stop() {
		this.recording = false;
	};

	/**
	 * Posts "clear" message to the worker.
	 */
	clear() {
		this.worker.postMessage({ command: 'clear' });
	};

	/**
	 * Sets the export callback and posts an "export" message to the worker.
	 * @param {onExportComplete} callback - Called when the export is complete.
	 */
	exportWAV(callback) {
		this.currCallback = callback;
		this.worker.postMessage({
			command: 'export'
		});
	};

	/**
	 * Checks the time domain data to see if the amplitude of the sound waveform is more than
	 * 0.01 or less than -0.01. If it is, "noise" has been detected and it resets the start time.
	 * If the elapsed time reaches 1.5 seconds the silence callback is called.
	 */
	startSilenceDetection() {
		// console.log( 'recorder.startSilenceDetection' );
		this.analyser.fftSize = 2048;
		var bufferLength = this.analyser.fftSize;
		var dataArray = new Uint8Array(bufferLength);

		this.analyser.getByteTimeDomainData(dataArray);

		if (typeof this.visualizationCallback === 'function') {
			this.visualizationCallback( { 'command': 'visualizeAudioBuffer', 'dataArray': dataArray, 'bufferLength': bufferLength } );
		}

		var curr_value_time = (dataArray[0] / 128) - 1.0;

		if (curr_value_time > 0.01 || curr_value_time < -0.01) {
			this.start = Date.now();
		}
		var newtime = Date.now();
		var elapsedTime = newtime - this.start;
		if (elapsedTime > 1500) {
			// console.log('RecorderService detected 1.5 seconds of silence');
			this.silenceCallback();
		}
	};


	/**
	 * Creates an audio context and calls getUserMedia to request the mic (audio).
	 * If the user denies access to the microphone, the returned Promise rejected 
	 * with a PermissionDeniedError
	 * @returns {Promise} 
	 */
	requestDevice() {

		if (typeof this.audio_context === 'undefined') {
			AudioContext = (<any>window).AudioContext || (<any>window).webkitAudioContext;
			this.audio_context = new AudioContext();
		}

		return navigator.mediaDevices.getUserMedia({ audio: true })
		.then( stream => { 
			this.audio_stream = stream;
		});
	};

}