import { Command } from "./Command";

export class ConvertDatabaseCmd extends Command {

	/**
	 * Convert the database to the latest version if needed.
	 */
	do(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			super.send( this.constructor.name, null, null )
			.then( results => {
				resolve();
			})
			.catch( err => reject( err ) );
		});
	}
}
