import { TableDocumentObject } from './TableHandler'

/**
 * An employee record that can be stored in the employee table.
 */
export class Employee extends TableDocumentObject {
	constructor(
		public companyId?: number, // ID of company (partition key)
		public employeeId?: string, // Unique employee ID
		public firstName?: string, // First name
		public firstNameLower?: string, // First name in all lower case for searching
		public lastName?: string, // Last name
		public badgeName: string = null, // Name on employees badge used to provide feedback in Room Genie
		public badgeNameLower: string = null, // Name on employee badge converted to lower case for searching
		public department?: string, // Employee department
		public departmentLower?: string, // Department in all lower case for searching
		public password?: string, // Password
		public photo?: string, // Name of S3 image file with photo of employee's face for facial recognition
		public faceId?: string, // Face ID of employee
		public propertyIds: number[] = [], // List of property ID's where employee works
    ) { super(); }
    
    /**
     * Create object from a data item that came from DynamoDb in the Item property.
     */
    fromDataItem( item: any ): Employee {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
    }

    /**
     * Convert object into data item that can be stored in DynamoDb as the Item property.
     */
    toDataItem(): any {
		let item = new Object();
	
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

 	/** @return Object containing key values used to get a record from the table. */
	 getKey() {
        return {
            "companyId": this.companyId, 
            "employeeId": this.employeeId
        }
	}
	
	hasPropertyId( id: number ) {
		let hasId = false;
		if (this.propertyIds) {
			for (let i=0; i<this.propertyIds.length; i++) {
				if (id == this.propertyIds[i]) {
					hasId = true;
					break;
				}
			}
		}
		return hasId;
	}

}
