import { TableHandler } from './TableHandler'
import { Company } from './Company';
import { GetUserDataResponse } from './GetUserDataResponse';
import { Global } from './Global';
import { PropertyTable } from "./PropertyTable";
import { Role } from "./Role";
import { Subscription } from './Subscription';

export class CompanyTable extends TableHandler<Company> {

    public constructor() {
		super( 'bookcliffsoftware-Company' );
	}
	
	public fromDataItem( item: any ): Company {
		return new Company().fromDataItem( item );
	}

	/**
	 * Mark a product subscription billed setting the prevBillingDate to the nextBillingDate
	 * and calculating a new nextBillingDate.
	 * @param subscription Subscription to mark billed (must be in this company's list of subscription objects).
	 */
	public markSubscriptionBilled( company: Company, subscription: Subscription ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			subscription.prevBillingDate = subscription.nextBillingDate;
			if (subscription.endDate && subscription.endDate.getTime() < Date.now()) {
				// Customer has cancelled subscription, don't bill them anymore
				subscription.nextBillingDate = null;
			} else {
				// Set next billing date
				subscription.nextBillingDate = Global.getNextBillingDate( subscription.nextBillingDate, subscription.billingDay )
			}
			// console.log( 'set nextBillingDate to: '+subscription.nextBillingDate.toISOString() );
			this.put( company )
			.then( () => resolve() )
			.catch( err => reject( err ) );
		});
	}

	/**
	 * Load company, property, and role data for the given user.
	 * @param userId User ID
	 * @param email User's email address
	 * @param companyId Company ID
	 * @param roleIds Role ID's the user has at the company.
	 */
	loadCompanyData( userId: string, email: string, companyId: number, roleIds: number[]  ): Promise<GetUserDataResponse> {
		return new Promise<GetUserDataResponse>( (resolve, reject) => {
			this.get( new Company( companyId ) )
			.then( company => {
				if (company) {
					new PropertyTable().getAll( companyId )
					.then( properties => {
						let roles = company.loadRoles( roleIds );
						// new Role().testCombineWithRoles();
						// new Role().testHasPermission();
						let role = new Role().combineWithRoles( roles );
						resolve( new GetUserDataResponse( [company], properties, role ) );
					})
					.catch( error => reject( new Error( Global.buildErrorMessage( 'Error loading properties for company ' + company.companyId + ' for user ' + userId + ' with email ' + email, error ) ) ) );
				} else {
					reject( new Error( 'No company found with ID '+companyId ) );
				}
			})
			.catch( error => reject( new Error( Global.buildErrorMessage( 'Error getting company with ID ' + companyId + + ' for user ' + userId + ' with email ' + email, error ) ) ) );
		});
	}

	getAll(): Promise<Company[]> {
		return this.scanAll( null, null );
	}

	/** Return map of company names keyed by ID. */
	public getNameMap(): Promise<Map<number,string>> {
    	return new Promise<Map<number,string>>( (resolve, reject) => {
			this.getAll()
			.then( rows => {
				resolve( Company.buildNameMap( rows ) );
			})
			.catch( error => reject( error ) );
		});
	}

}
