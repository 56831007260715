import { Command } from "./Command";
import { Site } from "../service/Site";
import { RoomAndDailyUsage } from "../service/RoomAndDailyUsage";

export class GetRoomAndDailyUsageCmd extends Command {

	/**
	 * Returns a map of distinct room numbers and the number of times that room number was used keyed by date between the given start and end date.
	 * @param companyId ID of the company
	 * @param propertyId ID of the property
	 * @param startDate First date to include
	 * @param endDate Last date to include
	 * @returns Map<Date,Map<string,number>> containing dates, room number, and total uses
	 */
	do( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<RoomAndDailyUsage> {
		return new Promise<RoomAndDailyUsage>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId, "startDate": startDate.toISOString(), "endDate": endDate.toISOString() } )
			.then( results => {
				// Convert generic JSON result object back to real object
				// let rows: RoomUsage[] = [];
				// results.data.forEach( layout => rows.push( new RoomUsage().fromDataItem( layout ) ) );
				// console.log( 'rows='+JSON.stringify(rows,null,2));
				// resolve( rows );
				resolve( new RoomAndDailyUsage().fromDataItem( results.data ) );
			}).catch( err => reject( err ) );
		});
	}

}
