import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/** Activities for which guests may sign up. */
export class ActivitySchedule extends TableDocumentObject {
	
	/**
	 * @param startDate Date and time of first occurrance of activity (default=now)
	 * @param endDate Last date activity will occur (default=null which means activity continues until the startDate of another recurrance)
	 * @param daysOfWeek 1/0 for each day of the week activity occurs when 'weekly' frequency is used (Sunday is first day, default is 1111111)
	 * @param closedDays Specific dates the activity is cancelled like Christmas day.
	 * @param capacity Max number of people that can sign up.
	 */
	constructor(
		public startDate: Date = new Date(),
		public endDate: Date = null,
		public daysOfWeek: string = '1111111',
		public closedDays: Date[] = [],
		public capacity: number = 0,
	) { 
		super(); 
		this.startDate.setUTCHours( 12, 0, 0, 0 );
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): ActivitySchedule {
		this.copyPropertiesFromObject( item );
		// Handle endDate which may be null and therefore not and instanceof Date which breaks the copy
		this.copyDatePropertyFromObject( 'endDate', item );
		// Copy closed days
		this.closedDays = [];
		if (item['closedDays']) {
			item['closedDays'].forEach( childItem => this.closedDays.push( new Date( Date.parse( childItem ) ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Handle endDate which may be null and therefore not and instanceof Date which breaks the copy
		this.copyDatePropertyToObject( 'endDate', item );
		// Copy closed days
		item['closedDays'] = [];
		if (this.closedDays) {
			this.closedDays.forEach( childItem => item['closedDays'].push( (childItem ? childItem.toISOString() : null ) ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

	/**
	 * @returns True if schedule has started and has not ended as of the current time.
	 */
	public isActive(): boolean {
		let active = false;
		let sevenDaysFromNow = Date.now() + Global.millisInOneWeek;
		// Recurring activity is active if start date is < now and there is no end date or the end date > now
		active = this.startDate.getTime() < sevenDaysFromNow && ((!this.endDate) || this.endDate.getTime() > Date.now());
		return active;
	}

	/**
	 * Returns true if the given time matches the start time for the schedule.
	 * @param time Time to check in the 12-hour format 'hh:mm am'.
	 */
	// public isValidTime( time: string ): boolean {
	// 	return time === this.startDate.toISOString().substr( 11, 5 );
	// }

	public getDayOfWeekFlag( dayOfWeek: number ) {
		return this.daysOfWeek[dayOfWeek] != '0';
	}

	public setDayOfWeekFlag( dayOfWeek: number, flag: boolean ) {
		// Convert string into character array
		let array = this.daysOfWeek.split('');
		array[dayOfWeek] = (flag ? '1' : '0');
		// Convert array back into string
		this.daysOfWeek = array.join('');
	}
	
	/**
	 * Returns true if the activity is scheduled on the given date.
	 * @param date Date and time to check.
	 * @param timeZone Timezone where restaurant is located.
	 */
	public isOpenOnDate( date: Date, timeZone: string ): boolean {
		// console.log('isOpenOnDate date='+date.toISOString()+', time zone '+timeZone);
		let open = false;
		// Make sure date/time is between the start date and end date
		if (this.startDate.getTime() <= date.getTime() && (this.endDate == null || date.getTime() < this.endDate.getTime())) {
			open = this.getDayOfWeekFlag( Global.getDayOfWeek( date, timeZone ) );
			console.log('isOpenOnDate getDayOfWeekFlag returned '+open);
			if (open && this.closedDays) {
				// Check to see if the date is in the list of closed days
				console.log('isOpenOnDate checking closedDays'+JSON.stringify(this.closedDays,null,2));
				let dateOnly = Global.getDateOnly( date, timeZone );
				for (let i=0; i<this.closedDays.length; i++) {
					let closedDate = Global.getDateOnly( this.closedDays[i], timeZone );
					console.log('closedDate='+closedDate.toISOString()+', dateOnly='+dateOnly.toISOString()+', date='+date.toISOString());
					if (closedDate.getTime() == dateOnly.getTime()) {
						// console.log('isOpenOnDate closed on '+this.closedDays[i].toISOString());
						open = false;
						break;
					}
				}
			}
		}
		// console.log('isOpenOnDate returned '+open);
		return open;
	}

	/**
	 * Returns the number of weekdays that the activity takes place (only applicable to 'weekly' frequency).
	 */
	// public getNumberOfDays(): number {
	// 	let count = 0;
	// 	for (let i=0; i<7; i++) {
	// 		if (this.getDayOfWeekFlag( i )) {
	// 			count++;
	// 		}
	// 	}
	// 	return count;
	// }

	/** Returns a string summarizing the dates the seating is valid and the times the seating starts and ends. */
	public getScheduleTimeString( timeZone: string ) {
		let s = '';
		if (this.startDate) {
			s = Global.getHM12TimeString( this.startDate, timeZone );
			if (this.endDate) {
				s += ' from '
				+ Global.getDateString( this.startDate, timeZone )
				+ ' thru '
				+ Global.getDateString( this.endDate, timeZone )
			} else {
				s += ' starting '
				+ Global.getDateString( this.startDate, timeZone )
			}
		}
		// console.log( 'getOpenTimesList returns <'+s+'> from startDate '+(this.startDate ? this.startDate.toISOString() : null)+' to endDate '+(this.endDate ? this.endDate.toISOString() : null)+', timeZone='+timeZone );
		return s;
	}

}
