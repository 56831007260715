import { TableHandler, TableHandlerQueryResult } from './TableHandler'
import { Employee } from './Employee';

/**
 * Class manages the employee table in DynamoDB.
 */
export class EmployeeTable extends TableHandler<Employee> {

    /** Local secondary index using personId as the sort key. */
    private secondaryIndexName: string = "companyId-faceId-index";

	public constructor() {
		super( 'bookcliffsoftware-Employee' );
	}
		
	public fromDataItem( item: any ): Employee {
		return new Employee().fromDataItem( item );
	}

    /**
     * Get the record for the given key values.
     * @param companyId ID of the company.
     * @param faceId FaceId of employee..
     */
    getByFaceId( companyId: number, faceId: string ): Promise<Employee> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId = :companyId and faceId = :faceId";
			let expressionAttributeValues = {
				":companyId": companyId,
				":faceId": faceId
			};
			this.query( keyConditionExpression, null, expressionAttributeValues, null, this.secondaryIndexName, null, 1 )
			.then( result => {
				let employee = null;
				if (result.objects.length > 0) {
					employee = result.objects[0];
					// console.log( this.tableName + '.getByFaceId returns ' + JSON.stringify( employee, null, 2 ) );
				}
				resolve( employee );
			})
			.catch( err => {
				reject( err );
			})
        });
	}

	public getByBadgeNameAndDepartment( companyId: number, badgeName: string, department: string ): Promise<Employee[]> {
    	return new Promise<Employee[]>( (resolve, reject) => {
			let filterExpression = "companyId = :companyId and badgeNameLower = :badgeNameLower and departmentLower = :departmentLower";
			let expressionAttributeValues = {
				':companyId': companyId,
				':badgeNameLower': badgeName ? badgeName.toLowerCase() : '',
				':departmentLower': department ? department.toLowerCase() : ''
			};
			this.scanAll( filterExpression, expressionAttributeValues )
			.then( employees => resolve( employees ) )
			.catch( error => reject( error ) );
		});
	}
		
	public getAll( companyId: number ): Promise<Employee[]> {
    	return new Promise<Employee[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId = :companyId";
			let expressionAttributeValues = { ":companyId": companyId };
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

}
