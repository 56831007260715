import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { ItemCategory } from "../../service/ItemCategory";
import { Item } from "../../service/Item";
import { InputTextComponent } from "../input-text/input-text.component";
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";
import { ItemComponent } from '../item/item.component';

/**
 * Component for editing food or item menu category for a property.
 */
@Component({
    selector: 'item-category',
	templateUrl: './item-category.html',
	styleUrls: [ 'item-category.css' ]
})
export class ItemCategoryComponent extends EditorDetail<ItemCategory> implements OnInit, OnDestroy {

	@ViewChild('ItemCategoryModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	@ViewChild(InputTextComponent) public inputTextComponent: InputTextComponent;

	@ViewChild(ItemComponent) public itemComponent: ItemComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Create a new row with default values. */
	createNewDefaultRow(): ItemCategory {
		return new ItemCategory();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	initializeDialog() {
	}

	/** Return a deep copy of the given object. */
	clone( row: ItemCategory ): ItemCategory {
		return new ItemCategory().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete the category: ' + this.originalRow.name + '?'
	}

	isValid(): boolean {
		this.errorMessage = null;
		if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for this category';
			return false;
		}

		return true;
	}

	/** Add a synonym for the category. */
	insertSynonym() {
		this.inputTextComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. shuttle bus', true );
		this.handleChildInsert( new String(), this.editedRow.synonyms, this.inputTextComponent );
	}

	editSynonym( index: number ) {
		this.inputTextComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. shuttle bus', true );
		this.handleChildEdit( index, this.editedRow.synonyms, this.inputTextComponent );
	}

	insertItem() {
		// Share the text input component with the item component 
		// because it also uses the input text component for synonyms.
		this.itemComponent.inputTextComponent = this.inputTextComponent;
		this.handleChildInsert( new Item(), this.editedRow.items, this.itemComponent );
	}

	editItem( index: number ) {
		// Share the text input component with the item component 
		// because it also uses the input text component for synonyms.
		this.itemComponent.inputTextComponent = this.inputTextComponent;
		this.handleChildEdit( index, this.editedRow.items, this.itemComponent );
	}

}