import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { CacheService } from "../../service/cache.service";
import { Topic } from "../../service/Topic";
import { InputTextComponent } from "../input-text/input-text.component";
import { Property } from "../../service/Property"
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";
import { S3Service } from '../../service/s3.service';

/**
 * Detail page of the topic editor.
 */
@Component({
    selector: 'topic',
	templateUrl: './topic.html',
	styleUrls: [ 'topic.css' ]
})
export class TopicComponent extends EditorDetail<Topic> implements OnInit {

	/** ID of the currently selected property. */
	public propertyId;

	/** List of audio files in S3 bucket for this company and property available to be played. */
	public audioFiles: string[] = [];

	/** String to show in selector when no audio file is selected. */
	private readonly NO_AUDIO = 'No audio';

	/** Currently selected audio file. */
	private audioFile: string = this.NO_AUDIO;

	@ViewChild('topicModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	@ViewChild('file') private fileRef: ElementRef; private fileElement: HTMLInputElement;
		
	@ViewChild(InputTextComponent) private detailComponent: InputTextComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private userService: UserLoginService,
		private cache: CacheService,
		private s3Service: S3Service,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
		this.fileElement = this.fileRef.nativeElement;
	}

	initialize() {
		let prefix = this.cache.currentCompany.companyId + '/' + this.propertyId + '/';
		this.s3Service.listFiles( Global.topicAudioBucketName, prefix )
		.then( files => {
			this.audioFiles = files;
			this.audioFiles.splice( 0, 0, this.NO_AUDIO );
			// Global.log( 'audioFiles='+JSON.stringify(files,null,2));
			this.detectChanges();
		 })
		.catch( err => {
			Global.logError( 'Error reading audio file', err );
			this.errorMessage = 'Sorry, we had a problem reading the file.  Please try again.';
		});
    }
	
	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): Topic {
		return new Topic();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		this.audioFile = this.NO_AUDIO;
		if (this.editedRow.audioFile != null) {
			this.audioFile = this.editedRow.audioFile;
		}
	}

	/** Return a deep copy of the given object. */
	clone( row: Topic ): Topic {
		return new Topic().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		this.errorMessage = null;

		if (this.editedRow.name == null || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for the topic.';
		}
		
		if ((this.editedRow.speech == null || this.editedRow.speech.trim() === '') && (this.editedRow.audioFile == null || this.editedRow.audioFile.trim() === '') || this.editedRow.audioFile == this.NO_AUDIO) {
			this.errorMessage = 'Please enter either the text to speak or an audio URL to play or both.';
		}

		if (this.audioFile == this.NO_AUDIO) {
			this.editedRow.audioFile = null;
		} else {
			this.editedRow.audioFile = this.audioFile;
		}

		return this.errorMessage == null;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete the topic: ' + this.editedRow.name + '?';
	}

	insert() {
		this.detailComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. shuttle bus', true );
		this.handleChildInsert( new String(), this.editedRow.synonyms, this.detailComponent );
	}

	edit( index: number ) {
		this.detailComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. shuttle bus', true );
		this.handleChildEdit( index, this.editedRow.synonyms, this.detailComponent );
	}

	/**
	 * Upload the selected file to S3
	 */
	onUpload() {
		let files = this.fileElement.files;
		if (!files.length) {
			this.errorMessage = 'Please choose a file to upload.';
		}
		let file = files[0];
		let objectKey = this.cache.currentCompany.companyId+'/'+this.propertyId+'/'+file.name;
		let startTime = Date.now();
		this.readAudioFile( file )
		.then( objectUrl => {
			// Upload audio file to S3 into a folder where Alexa looks for them
			let base64DataString = objectUrl.replace(/^data:audio\/\w+;base64,/, "");
			this.s3Service.upload( base64DataString, Global.topicAudioBucketName, objectKey )
			.then( () => {
				Global.log( 'Uploaded audio file in '+(Date.now()-startTime)+'ms' );
				this.audioFiles.push( file.name );
				this.detectChanges();
			})
			.catch( err => {
				Global.logError( 'Error uploading audio file', err );
				this.errorMessage = 'Sorry, we had a problem uploading the file.  Please try again.';
			});
		})
		.catch( err => {
			Global.logError( 'Error reading audio file', err );
			this.errorMessage = 'Sorry, we had a problem reading the file.  Please try again.';
		});
	}

	/**
	 * 
	 * @param file Reads the given File object into a data URL for uploading.
	 */
	readAudioFile( file: File ): Promise<string> {
		return new Promise<string>( (resolve,reject) => {
			var fileReader = new FileReader();
			fileReader.onloadend = ( d ) => {
				// Global.log( 'onloadend '+JSON.stringify(d,null,2));
				// Global.log( 'fileReader.result '+JSON.stringify(fileReader.result,null,2));
				resolve( ""+fileReader.result );
			};
			fileReader.readAsDataURL( file );	
		});
	}

}