import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";
import { CacheService } from "../../service/cache.service";
import { Property } from "../../service/Property";
import { UserLoginService } from "../../service/user-login.service";

/** Displays instructions for setting up Room Genie skill on Alexa. */
@Component({
    selector: 'doc',
    templateUrl: './doc.html',
    styleUrls: ['./doc.css'],
})
export class AlexaDocComponent implements OnInit, AfterViewInit {

	constructor( 
		private router: Router,
		private userService: UserLoginService, 
		private changeDetectorRef: ChangeDetectorRef,
		private cache: CacheService,
	) {}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-book fa-fw"></i> Help Information' );
	}

	initialize() {
	}

	scrollTo( id: string ) {
		const element = document.querySelector('#' + id);
		if (element) {
			element.scrollIntoView(); // <-- omit element from the argument
		}
	}

	getCompanyId(): string {
		return this.cache.currentCompany ? this.cache.currentCompany.companyId.toString() : '';
	}

	getProperties(): Property[] {
		return this.cache.properties ? this.cache.properties : <Property[]>[new Property(0,0,null)];
	}	

	getFirstProperty(): Property {
		return this.cache.properties && this.cache.properties[0] ? this.cache.properties[0] : new Property(0,0,null);
	}	

}
