import { TableDocumentObject } from './TableHandler'

/** 
 * Default ad to play when there are no paying advertisers in a category.
 */
export class DefaultAd extends TableDocumentObject {

	/**
	 * @param adCategoryId ID of advertising company
	 * @param speech What Alexa should say.
	 */
	constructor(
		public adCategoryId: number = null,
		public speech: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): DefaultAd {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is reDefaultAdy to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {};
    }

}
