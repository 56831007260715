import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { RestaurantSection } from "../../service/RestaurantSection";
import { RestaurantTableTop } from "../../service/RestaurantTableTop";
import { RestaurantTableTopComponent } from "../restaurant-tabletop/restaurant-tabletop.component";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";

/**
 * Component for editing a restaurant section.
 */
@Component({
    selector: 'restaurant-section',
	templateUrl: './restaurant-section.html',
	styleUrls: [ 'restaurant-section.css' ]
})
export class RestaurantSectionComponent implements OnInit, OnDestroy {
    editedRow: RestaurantSection = new RestaurantSection();
	originalRow: RestaurantSection;
	errorMessage: string = null;
	private resolveModal: (action: string) => void;
	private rejectModal: (error: Error) => void;
	
	/** True if this record will be inserted and the delete icon is not shown. */
	private isInsert: boolean;

	@ViewChild('restaurantSectionModalButton') private modalButtonRef: ElementRef; private modalButton: HTMLButtonElement;
	
	@ViewChild('focus') private focusRef: ElementRef; private focusElement: HTMLInputElement;
		
	@ViewChild(RestaurantTableTopComponent) private detailComponent: RestaurantTableTopComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/**
	 * Displays modal dialog to edit object.
	 * @param row Object to edit.
	 * @return Promise with user action used to dismiss dialog (insert, update, delete, or cancel).
	 */
	public showModalDialog( row: RestaurantSection, isInsert: boolean ): Promise<string> {
		this.errorMessage = null;
		this.isInsert = isInsert;
		// this.timeZone = timeZone;
		return new Promise<string>( (resolve,reject) => {
			if (!row) {
				reject( new Error( 'No edit object provided.' ) );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Make a copy of object so we can restore the original values if user cancels.
				this.originalRow = row;
				this.editedRow = new RestaurantSection().fromDataItem( row.toDataItem() );
				// Show the dialog
				this.modalButton.click();
				this.changeDetectorRef.detectChanges();
				// Put keyboard focus on heading field after dialog is displayed
				setTimeout( () => this.focusElement.focus(), 1000 );
			}
		});
	}

	/** 
	 * Return a copy of the original row that was created when a row is passed in for editing.
	 * Can be used to get the old primary key values of the row to update. 
	 */
	public getOriginalRow(): RestaurantSection {
		return this.originalRow;
	}

	/** 
	 * Return a copy of the original row that was passed in for editing which may have been updated by the user.
	 */
	public getEditedRow(): RestaurantSection {
		return this.editedRow;
	}

	/** Close the dialog without saving changes. */
	private onCancel() {
		// Hide the dialog
		this.modalButton.click();
		this.resolveModal( 'cancel' );
	}

    private onSave() {
        this.errorMessage = null;
		if (this.isValid()) {
			// Hide the dialog
			this.modalButton.click();
			this.resolveModal( this.isInsert ? 'insert' : 'update' );
		}
    }

	private isValid(): boolean {
		this.errorMessage = null;
		if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name.';
			return false;
		}
		return true;
	}

	private onDelete() {
		this.cache.confirmComponent.showModalDialog( 'Are you sure you want to delete the section ' + this.originalRow.name + '?', 'Confirm Delete' )
		.then( confirmed => {
			if (confirmed) {
				// Hide the dialog
				this.modalButton.click();
				this.resolveModal( 'delete' );
			}
		})
		.catch( error => {
			Global.logError( 'Error in confirm delete dialog.', error );
			this.errorMessage = error.message;
		});
	}

	/** Prepare new object for insertion, for example, by setting the ID to a unique value. */
	prepareRowToInsert( newRow: RestaurantTableTop ) {
	}

	insert() {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( new RestaurantTableTop(), true  )
		.then( action => {
			if (action === 'insert') {
				// User saved changes, update the list
				this.prepareRowToInsert( this.detailComponent.getEditedRow() );
				this.editedRow.tables.push( this.detailComponent.getEditedRow() );
				this.changeDetectorRef.detectChanges();
			}
		})
		.catch( error => {
			Global.logError( 'Error editing new data', error );
			this.errorMessage = error.message;
		});
	}

	edit( index: number ) {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( this.editedRow.tables[index], false )
		.then( action => {
			if (action === 'update') {
				// User saved changes, update the row
				this.editedRow.tables[index] = this.detailComponent.getEditedRow();
				this.changeDetectorRef.detectChanges();
			} else if (action === 'delete') {
				// User chose to delete the row
				this.editedRow.tables.splice( index, 1 );
				this.changeDetectorRef.detectChanges();
			}
		})
		.catch( error => {
			Global.logError( 'Error editing data.', error );
			this.errorMessage = error.message;
		});
	}

}