import { Command } from "./Command";
import { UserCompany } from "../service/UserCompany";

export class PutUserCompanyCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: UserCompany ): Promise<UserCompany> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<UserCompany>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new UserCompany().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
