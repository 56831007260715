import { Command } from "./Command";
import { Property } from "../service/Property";

export class DeletePropertyCmd extends Command {

	/**
	 * Delete a row.
	 * @param row Data to delete.
	 */
	public do( row: Property ): Promise<any> {
		return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
	}

}
