import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { RestaurantLayoutComponent } from "../restaurant-layout/restaurant-layout.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { RestaurantLayout } from '../../service/RestaurantLayout';
import { Property } from '../../service/Property';
import { GetRestaurantLayoutListCmd } from '../../command/GetRestaurantLayoutListCmd';
import { PutRestaurantLayoutCmd } from '../../command/PutRestaurantLayoutCmd';
import { DeleteRestaurantLayoutCmd } from '../../command/DeleteRestaurantLayoutCmd';

@Component({
    selector: 'restaurant-layouts',
    templateUrl: './restaurant-layouts.html',
	styleUrls: ['./restaurant-layouts.css'],
})
export class RestaurantLayoutsComponent implements OnInit, AfterViewInit {

	/** List of rows that can be edited. */
	private rows: RestaurantLayout[] = [];

	/** Error message displayed to the user. */
	private errorMessage: string;

	/** List of property user may choose from. */
	private properties: Property[] = [];

	/** Currently selected property. */
	private propertyId;

	/** Date to display. */
	private displayDate: Date = new Date();

	@ViewChild(RestaurantLayoutComponent) private detailComponent: RestaurantLayoutComponent;

	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Restaurant Layouts' );
	}

	initialize() {
		this.properties = this.cache.getProperties();
		if (this.cache.getProperties().length > 0) {
			// Set selected property to the first one in the list
			this.propertyId = this.cache.getProperties()[0].propertyId;

			// Load the rows
			this.loadRows();
		}
	}

	private switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		this.loadRows();
	}

	private loadRows() {
		new GetRestaurantLayoutListCmd().do( this.cache.currentCompany.companyId, this.propertyId )
		.then( rows => {
			this.rows = rows;
		})
		.catch( error => {
			Global.logError('Error loading data.', error );
			this.errorMessage = 'Sorry, we had a problem loading the data.  Please try again.';
		})
	}

	prepareRowToInsert( newRow: RestaurantLayout ) {
		newRow.companyId = this.cache.currentCompany.companyId;
		newRow.propertyId = this.propertyId;
		let highestLayoutId = null;
		this.rows.forEach( row => {
			if ((!highestLayoutId) || row.layoutId > highestLayoutId) {
				highestLayoutId = row.layoutId;
			}
		})
		newRow.layoutId = highestLayoutId + 1;
	}

	insert() {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( new RestaurantLayout( this.cache.currentCompany.companyId, this.propertyId ), true )
		.then( action => {
			if (action === 'insert') {
				// User saved changes, update the list
				this.prepareRowToInsert( this.detailComponent.getEditedRow() );
				// new RestaurantLayoutTable().put( this.detailComponent.getEditedRow() )
				new PutRestaurantLayoutCmd().do( this.detailComponent.getEditedRow() )
				.then( () => {
					this.rows.push( this.detailComponent.getEditedRow() );
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error saving data.', error );
					this.errorMessage = 'Sorry, we had a problem saving the data.  Please try again.';
				});
			}
		})
		.catch( error => {
			Global.logError( 'Error editing new data', error );
			this.errorMessage = error.message;
		});
	}

	edit( index: number ) {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( this.rows[index], false )
		.then( action => {
			if (action === 'update') {
				// User saved changes, update the row
				new PutRestaurantLayoutCmd().do( this.detailComponent.getEditedRow() )
				.then( () => {
					if (!this.detailComponent.getEditedRow().primaryKeyMatches( this.detailComponent.getOriginalRow() )) {
						// User changed the primary key, delete the old row with the old primary key
						new DeleteRestaurantLayoutCmd().do( this.detailComponent.getOriginalRow() )
						.catch( error => {
							Global.logError('Error deleting data.', error );
							this.errorMessage = 'Sorry, we had a problem deleting the old data.  Please try again.';
						});
					}
					this.rows[index] = this.detailComponent.getEditedRow();
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error updating data.', error );
					this.errorMessage = 'Sorry, we had a problem updating the data.  Please try again.';
				});
			} else if (action === 'delete') {
				// User chose to delete the row
				new DeleteRestaurantLayoutCmd().do( this.detailComponent.getOriginalRow() )
				.then( () => {
					this.rows.splice( index, 1 );
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error deleting data.', error );
					this.errorMessage = 'Sorry, we had a problem deleting the data.  Please try again.';
				});
			}
		})
		.catch( error => {
			Global.logError( 'Error editing data.', error );
			this.errorMessage = error.message;
		});
	}

}
