import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import {CacheService} from "../../service/cache.service";
import { EmployeeFeedbackTable, EmployeeFeedback } from "../../service/EmployeeFeedbackTable"
import { Employee } from "../../service/Employee"
import { Property } from "../../service/Property"
import { Global } from '../../service/Global';
import {UUIDService} from "../../service/uuid.service";
import { GetEmployeesCmd } from "../../command/GetEmployeesCmd";

/**
 * Company editing component.
 */
@Component({
    selector: 'feedback-detail',
    templateUrl: './feedback-detail.html',
	styleUrls: ['./feedback-detail.css'],
})
export class EmployeeFeedbackDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    private data: EmployeeFeedback = new EmployeeFeedback();
    private originalData: EmployeeFeedback;
	private errorMessage: string = null;
	private resolveModal: (feedback: EmployeeFeedback) => void;
	private rejectModal: (error: Error) => void;
	private employees: Employee[];
	private isInsert = false;
	
	@ViewChild('employeeFeedbackModalButton') public employeeFeedbackModalButtonRef: ElementRef; private modalButton: HTMLButtonElement;

	@ViewChild('focus') public focusRef: ElementRef; private focusElement: HTMLInputElement;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private cache: CacheService,
		private editedTable: EmployeeFeedbackTable,
		private uuidService: UUIDService,
	)
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.employeeFeedbackModalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param data Company to edit.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	showModalDialog( data: EmployeeFeedback, isInsert: boolean ): Promise<EmployeeFeedback> {
		return new Promise<EmployeeFeedback>( (resolve,reject) => {
			if (!data) {
				reject( new Error( 'No edit object provided.' ) );
			} else {
				this.isInsert = isInsert;
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Make a copy of object so we can restore the original values if user cancels.
				this.originalData = data;
				this.data = new EmployeeFeedback().fromDataItem( data.toDataItem() );
				// Load the employee list if necessary
				if (!this.employees) {
					new GetEmployeesCmd().do( this.cache.currentCompany.companyId )
					.then( rows => this.employees = rows )
					.catch( error => {
						Global.logError( 'Error getting employee list.', error );
						this.errorMessage = 'Sorry, there was a problem loading the list of employees.  Please try again.';
					})
				}
				this.changeDetectorRef.detectChanges();
				// Show the dialog
				this.toggleDialogVisibility();
				// Put keyboard focus on heading field after dialog is displayed
				setTimeout( () => this.focusElement.focus(), 1000 );
			}
		});
	}
	

	/** Make dialog visible if it is not visible and vice versa. */
	private toggleDialogVisibility() {
		this.modalButton.click();
	}

	/** Close the dialog without saving changes. */
	private onCancel() {
		// Hide the dialog
		this.toggleDialogVisibility();
		this.resolveModal( null );
	}

    private onSave() {
		// Update the object passed in for editing with the edited values
		this.originalData.fromDataItem( this.data.toDataItem() );
		if (this.isValid()) {
			this.editedTable.put( this.data )
			.then( () => {
				console.log(this.editedTable.getTableName()+".put succeeded.");
				// Hide the dialog
				this.toggleDialogVisibility();
				this.resolveModal( this.data );
			})
			.catch( err => {
				// We failed to insert the row
				Global.logError( this.editedTable.getTableName()+".put failed.", err );
				this.errorMessage = 'Sorry, there was a problem saving the data.  ' + err.message;
			});
		}
	}
	
	private isValid() {
		this.errorMessage = null;

		if (!this.data.companyId) {
			this.data.companyId = this.cache.currentCompany.companyId;
		}

		if (!this.data.feedbackId) {
			this.data.feedbackId = this.uuidService.UUID();
		}
	
		if (this.data.propertyId == null) {
			this.errorMessage = 'Invalid property ID.';
		} else if ((!this.data.roomNumber) || this.data.roomNumber.trim() === '') {
			this.errorMessage = 'Please enter a room number.';
		// } else if ((!this.data.employeeId) || this.data.employeeId.trim() === '') {
		// 	this.errorMessage = 'Please select an employee.';
		// } else if ((!this.data.firstName) || this.data.firstName.trim() === '') {
		// 	this.errorMessage = 'Please enter a first name.';
		// } else if ((!this.data.lastName) || this.data.lastName.trim() === '') {
		// 	this.errorMessage = 'Please enter a last name.';
		} else if ((!this.data.badgeName) || this.data.badgeName.trim() === '') {
			this.errorMessage = 'Please enter a badge name.';
		} else if ((!this.data.department) || this.data.department.trim() === '') {
			this.errorMessage = 'Please enter a department.';
		}
		return this.errorMessage == null;
	}

	changeSelectedEmployee( event )  {
		// console.log('changeSelectedCamera to '+this.deviceId );
		this.data.firstName = '';
		this.data.lastName = '';
		this.data.department = '';
		if (this.employees) {
			for (let i=0; i<this.employees.length; i++) {
				if (this.data.employeeId === this.employees[i].employeeId) {
					this.data.firstName = this.employees[i].firstName;
					this.data.lastName = this.employees[i].lastName;
					this.data.department = this.employees[i].department;
					this.data.badgeName = this.data.firstName + ' ' + this.data.lastName;
					break;
				}

			}
		}
	}

}