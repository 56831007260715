import { Global } from "./Global";
import { EditorDetail } from "./EditorDetail";
import { TableDocumentObject } from "./TableHandler";

export abstract class Editor<T extends TableDocumentObject> {

	/** List of rows that can be edited. */
	protected rows: T[] = [];

	/** Error message displayed to the user. */
	protected errorMessage: string;

	/** Returns component used to edit the selected row. */
	abstract getDetailComponent(): EditorDetail<T>;

	/** Returns promise of array of rows to edit. */
	abstract getRowLoader(): Promise<T[]>;
	
	/** Creates a new row to be edited. */
	abstract createNewRowToEdit(): T;

	/** Called before new rows is saved. */
	abstract prepareRowToInsert( newRow: T );

	/** Detects changes in model so view is updated. */
	abstract detectChanges();

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	abstract getRowInserter( row: T ): Promise<T>;

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	abstract getRowUpdater( index: number, row: T ): Promise<T>;

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	abstract getRowDeleter( index: number, row: T ): Promise<void>;

	loadRows(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			// console.log('Loading rows');
			this.getRowLoader()
			.then( rows => {
				// console.log('Loading rows returned '+JSON.stringify(rows,null,2));
				this.rows = rows;
				resolve();
			})
			.catch( error => {
				this.handleError( error, false );
				reject( error );
			})
		});
	}

	handleError( error: any, hideDetailDialog: boolean = false ) {
		if (hideDetailDialog) {
			this.getDetailComponent().hideDialog()
		}
		Global.logError( 'Error handling event', error );
		this.errorMessage = "Sorry, there was a problem, please try again.";
		this.detectChanges();
	}

	/**
	 * Shows error message on detail dialog.
	 */
	handleDetailError( error: any ) {
		Global.logError( 'Error handling event', error );
		this.getDetailComponent().errorMessage = "Sorry, there was a problem, please try again.";
		this.detectChanges();
	}

	insert() {
		try {
			this.errorMessage = null;
			let newRow = this.createNewRowToEdit();
			this.getDetailComponent().showModalDialog( newRow, true, (action,arg) => {
				if (action === 'insert') {
					// User saved changes, update the list
					newRow = this.getDetailComponent().getEditedData();
					this.prepareRowToInsert( newRow );
					this.getDetailComponent().errorMessage = null;
					this.rows.push( newRow );
					this.getRowInserter( newRow )
					.then( savedRow => {
						if (!this.getDetailComponent().errorMessage) {
							// Update list in case save modified the row
							this.rows[this.rows.length-1] = savedRow;
							this.getDetailComponent().hideDialog();
							try {
								this.detectChanges();
							} catch( error ) {
								this.handleError( error, false );
							}
						} else {
							// There was an error and the caller wants to keep this dialog open to show the error.
							// Remove the row we added to the list
							this.rows.splice( this.rows.length-1, 1 );
							this.detectChanges();
						}
					})
					.catch( error => {
						// The save failed so remove the row we added to the list
						// console.log('INSERT FAILED!');
						this.rows.splice( this.rows.length-1, 1 );
						this.detectChanges();
						this.handleDetailError( error );
					 });
				} else if (action === 'error') {
					this.handleDetailError( arg );
				} else {
					// User cancelled
					this.getDetailComponent().hideDialog()
				}
			});
		} catch( error ) { this.handleError( error, false ); }
	}

	edit( index: number ) {
		try {
			this.errorMessage = null;
			// console.log('edit('+index+'), rows='+JSON.stringify(this.rows,null,2));
			this.getDetailComponent().showModalDialog( this.rows[index], false, (action, arg) => {
				// console.log('edit action '+action);
				if (action === 'update') {
					// User saved changes, update the row
					// this.rows[index] = this.getDetailComponent().getEditedData();
					this.getRowUpdater( index, this.getDetailComponent().getEditedData() )
					.then( savedRow => {
						if (!this.getDetailComponent().errorMessage) {
							if (savedRow) {
								// Row updater returned a row that may have been modified, update list
								this.rows[index] = savedRow;
							}
							this.getDetailComponent().hideDialog()
							if (!this.getDetailComponent().getEditedData().primaryKeyMatches( this.getDetailComponent().getOriginalData() )) {
								// User changed the primary key, delete the old row with the old primary key
								this.getRowDeleter( index, this.getDetailComponent().getOriginalData() )
								.catch( error => this.handleError( error, false ) );
							}
							try {
								this.detectChanges();
							} catch( error ) {
								this.handleError( error, false );
							}
						}
					})
					.catch( error => {
						// The save failed so remove the row we updated in the list
						// this.rows[index] = this.getDetailComponent().getOriginalData();
						// this.detectChanges();
						this.handleDetailError( error );
					 });
				} else if (action === 'delete') {
					// User chose to delete the row
					this.rows.splice( index, 1 );
					this.getRowDeleter( index, this.getDetailComponent().getOriginalData() )
					.then( () => {
						// console.log('getRowDeleter succeeded');
						if (!this.getDetailComponent().errorMessage) {
							this.getDetailComponent().hideDialog()
							try {
								this.detectChanges();
							} catch( error ) {
								this.handleError( error, false );
							}
						}
					})
					.catch( error => {
						// console.log('getRowDeleter failed '+error);
						// The save failed so add back the row we deleted from the list
						this.rows.splice( index, 0, this.getDetailComponent().getOriginalData() );
						// this.detectChanges();
						this.handleDetailError( error );
					 });
				} else if (action === 'error') {
					this.handleDetailError( arg );
				} else {
					// User cancelled
					this.getDetailComponent().hideDialog()
				}
			});
		} catch( error ) { this.handleError( error, false ); }
	}

}
