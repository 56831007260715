import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { CacheService } from "../../service/cache.service";
import { Role } from '../../service/Role';
import { Global } from '../../service/Global';
import { EditorDetail } from "../../service/EditorDetail";
import { ConfirmComponent } from "../confirm/confirm.component";

/**
 * Company editing component.
 */
@Component({
    selector: 'role-selector',
    templateUrl: './role-selector.html',
	styleUrls: ['./role-selector.css'],
})
export class RoleSelectorComponent extends EditorDetail<Number> implements OnInit, OnDestroy, AfterViewInit {

	/** List of roles from which to select. */
	public roles: Role[];
	
	@ViewChild('RoleSelectorModalButton') public modalButtonRef: ElementRef;

	@ViewChild('focus') public focusRef: ElementRef;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): Number {
		return new Number();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		if (this.editedRow.valueOf() == null && this.roles.length > 0) {
			// If selecting a new role, default to first role in list
			this.editedRow = this.roles[0].roleId;
		}
	}

	/** Return a deep copy of the given object. */
	clone( row: Number ): Number {
		return new Number( row );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		if (this.editedRow.valueOf() == null) {
			this.errorMessage = 'Please select a role.';
			return false;
		}
		// Convert selected role ID from string to number, combo box saves it as a string
		this.editedRow = new Number( this.editedRow );
		return true;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to remove role ' + this.getRole().roleName + ' from this user?';
	}

	/** Find the Role with the selected ID **/
	private getRole(): Role {
		let role: Role = null;
		for (let i=0; i<this.roles.length; i++) {
			if (this.roles[i].roleId == this.editedRow) {
				role = this.roles[i];
				break;
			}
		}
		return role;
    }

}