import { Product } from './Product';

export class PurchaseDetails {

	/**
	 * Details about a product purchase
	 * @param product Product being purchased.
	 * @param billingDayOfMonth Day of the month when customer will be billed (or last day of the month if earlier)
	 * @param firstBillingDate  First billing date.
	 * @param billingTerms Description of how and when billing will occur.
	 */
	constructor(
		public product: Product = null,
		public billingDayOfMonth: number = 1,
		public firstBillingDate: Date = null,
		public billingTerms: string = null,
	) {}

}