import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {DynamoDBService, Timeslot} from "../../service/ddb.service";
import {GoogleChartsService} from "../../service/google-charts.service";
import { Global } from '../../service/Global';
import { CacheService } from "../../service/cache.service";
import { TitleNavComponent } from "../title-nav/title-nav.component"

/*
Displays a Line Timer dashboard that summarizes the KPI's of all the products the user has.

Line Timer

Available charts:
* x=Avg line time divided into 12 ranges, y=number of sites, drill in to see list of sites with line times
* x=daypart, y=avg time
* x=date, y=avg time, drill into daypart
* x=week, y=avg time, drill into dates
* x=month, y=avg time

Options:
* sites to include (default = all)
* date range (default = last 30 days)

*/
@Component({
    selector: 'linetimer',
    templateUrl: './linetimer.html',
    styleUrls: ['./linetimer.css'],
})
export class LineTimerComponent implements OnInit, OnDestroy, AfterViewInit {

    // Data for avg time guage
	private timeGuageChartData;
	private timeGuageChartOptions = {
		width: 120, height: 120,
		max: 100000,
		greenFrom: 0, greenTo: 60000,
		yellowFrom: 60000, yellowTo: 90000,
		redFrom: 90000, redTo: 100000,
		majorTicks: ['0', '20K', '40K', '60K', '80K', '100K' ],
	};
	private countGuageChartData;
	private countGuageChartOptions = {
		width: 120, height: 120,
		max: 100,
		majorTicks: ['0', '20', '40', '60', '80', '100' ],
	};
	
	constructor(
		public router: Router, 
		public userService: UserLoginService, 
		private cache: CacheService,
		public ddb: DynamoDBService, 
		public googleCharts: GoogleChartsService
	) {
		// console.log( 'LineTimerComponent.constructor width='+window.innerWidth+', height='+window.innerHeight );
		// Set the guage size to 40% of the window width or 80% of the height whichever is smaller
		let guageSize = Math.min( Math.round( window.innerWidth * .40 ), Math.round( window.innerHeight * .80 ) );
		this.timeGuageChartOptions.width = guageSize;
		this.timeGuageChartOptions.height = guageSize;
		this.countGuageChartOptions.width = guageSize;
		this.countGuageChartOptions.height = guageSize;
    }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	initialize() {
		this.ddb.getLineTimeChartData( 4 )
		.then(timeslots => {
			// console.log('drawing charts');
			this.getChartData( timeslots );
			this.drawAverageTimeGuageChart();
			this.drawCountGuageChart();
		}).catch( err => {
			// Failure here usually means user token has expired so return to login
			Global.logError('Error drawing charts.', err );
			// this.router.navigate(['/home/login']);
		});
	}

    ngOnDestroy() {
		// Global.removeContentAreaResizeListener();
    }

	public ngAfterViewInit() {
		// Global.addContentAreaResizeListener();
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-dashboard fa-fw"></i> VIP Line Timer' );
	}

	onShowByDayPart() {
		// console.log('LineTimerComponent.onShowByDayPart');
		this.router.navigate(['/securehome/timebydaypart/']);
	}

	onCompareSites() {
		// console.log('LineTimerComponent.onCompareSites');
		this.router.navigate(['/securehome/comparesites/']);
	}

	onCaptureImage() {
		this.router.navigate(['/securehome/sample-camera/']);
	}

	onGetGmailImages() {
		this.router.navigate(['/securehome/gmaillistener/']);
	}

	onResizeGuage(event) {
		// console.log( 'onResizeGuage width='+event.target.innerWidth+', height='+event.target.innerHeight );
		if (this.timeGuageChartData) {
			// Set the guage size to 40% of the window width or height whichever is smaller
			let window = event.target; 
			let guageSize = Math.min( Math.round( window.innerWidth * .40 ), Math.round( window.innerHeight * .80 ) );
			this.timeGuageChartOptions.width = guageSize;
			this.timeGuageChartOptions.height = guageSize;
			this.drawAverageTimeGuageChart();
		}
	}

	onResizeCountGuage(event) {
		// console.log( 'onResizeCountGuage width='+event.target.innerWidth+', height='+event.target.innerHeight );
		if (this.countGuageChartData) {
			// Set the guage size to 40% of the window width or height whichever is smaller
			let guageSize = event.target.innerWidth < event.target.innerHeight ? Math.round( event.target.innerWidth * .40 ) : Math.round( event.target.innerWidth * .40 ); 
			this.countGuageChartOptions.width = guageSize;
			this.countGuageChartOptions.height = guageSize;
			this.drawCountGuageChart();
		}
	}

	/**
	 * Round a number to the given precision.
	 * @param number Number to round.
	 * @param precision Number of digits right of decimal to round (or left of decimal if negative).
	 */
	roundWithPrecision(number, precision) {
		var factor = Math.pow(10, precision);
		var tempNumber = number * factor;
		var roundedTempNumber = Math.round(tempNumber);
		return roundedTempNumber / factor;
	};

	private getChartData( timeslots: Timeslot[] ) {
		// console.log('getChartData: '+JSON.stringify(timeslots));
		this.timeGuageChartData = [];
		this.timeGuageChartData.push( ['Label', 'Value'] );
		this.countGuageChartData = [];
		this.countGuageChartData.push( ['Label', 'Value'] );
		// let valueCount = 0;
		// let valueSum = 0;
		let secondsSum = 0;
		let lineCountSum = 0;
		timeslots.forEach(timeslot => {
			secondsSum += timeslot.seconds;
			lineCountSum += timeslot.lineCount;
			// let value = 0;
			// if (timeslot.lineCount != 0) {
			// 	value = Math.round( ((timeslot.seconds / timeslot.lineCount) * 10) ) / 10;
			// }
			// valueCount++;
			// valueSum += value;
		});
		// this.guageChartData.push( ['Seconds', valueCount == 0 ? 0 : Math.round( valueSum / valueCount ) ] );
		let averageMinutesInLine = lineCountSum == 0 ? 0 : this.roundWithPrecision( (secondsSum / lineCountSum) / 60, 1 );
		this.timeGuageChartData.push( ['Minutes', averageMinutesInLine ] );
		this.countGuageChartData.push( ['People', lineCountSum ] );

		// Set max value on time guage
		if (averageMinutesInLine > 100) {
			this.timeGuageChartOptions.max = 1000;
		} else if (averageMinutesInLine > 50) {
			this.timeGuageChartOptions.max = 100;
		} else if (averageMinutesInLine > 20) {
			this.timeGuageChartOptions.max = 50;
		} else {
			this.timeGuageChartOptions.max = 20;
		}
		this.timeGuageChartOptions.majorTicks = [];
		for (let i=0; i<6; i++) {
			this.timeGuageChartOptions.majorTicks.push( ( ( i * this.timeGuageChartOptions.max) / 5 ).toString() );
		}
		this.timeGuageChartOptions.greenFrom = 0;
		this.timeGuageChartOptions.greenTo = this.timeGuageChartOptions.max * .67;
		this.timeGuageChartOptions.yellowFrom = this.timeGuageChartOptions.greenTo;
		this.timeGuageChartOptions.yellowTo = this.timeGuageChartOptions.max * .85;
		this.timeGuageChartOptions.redFrom = this.timeGuageChartOptions.yellowTo;
		this.timeGuageChartOptions.redTo = this.timeGuageChartOptions.max;

		// Set max value on count guage
		if (lineCountSum > 1000000) {
			this.countGuageChartOptions.max = 10000000;
			this.countGuageChartOptions.majorTicks = ['0', '2M', '4M', '6M', '8M', '10M' ];
		} else if (lineCountSum > 100000) {
			this.countGuageChartOptions.max = 1000000;
			this.countGuageChartOptions.majorTicks = ['0', '200K', '400K', '600K', '800K', '1M' ];
		} else if (lineCountSum > 10000) {
			this.countGuageChartOptions.max = 100000;
			this.countGuageChartOptions.majorTicks = ['0', '20K', '40K', '60K', '80K', '100K' ];
		} else if (lineCountSum > 1000) {
			this.countGuageChartOptions.max = 10000;
			this.countGuageChartOptions.majorTicks = ['0', '2000', '4000', '6000', '8000', '10000' ];
		} else if (lineCountSum > 100) {
			this.countGuageChartOptions.max = 1000;
			this.countGuageChartOptions.majorTicks = ['0', '200', '400', '600', '800', '1000' ];
		} else {
			this.countGuageChartOptions.max = 100;
			this.countGuageChartOptions.majorTicks = ['0', '20', '40', '60', '80', '100' ];
		}
	}

	private drawAverageTimeGuageChart() {
		// Load Google chart libraries and draw chart when loading is complete.
		this.googleCharts.loadLibraries()
		.then( () => {
			var wrapper = this.googleCharts.createChartWrapper({
				chartType: 'Gauge',
				dataTable: this.timeGuageChartData,
				options: this.timeGuageChartOptions,
				containerId: 'guage_div'
			});
			// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
			wrapper.draw();
		})
		.catch( err => {
			Global.logError('Error drawing time guage.', err );
			throw new Error( 'Error drawing time guage.  ' +  err );
		});
	}

	private drawCountGuageChart() {
		// Load Google chart libraries and draw chart when loading is complete.
		this.googleCharts.loadLibraries()
		.then( () => {
			var wrapper = this.googleCharts.createChartWrapper({
				chartType: 'Gauge',
				dataTable: this.countGuageChartData,
				options: this.countGuageChartOptions,
				containerId: 'count_guage_div'
			});
			// console.log('drawing the count guage '+this.countGuageChartOptions.width+'x'+this.countGuageChartOptions.height)
			wrapper.draw();
			})
		.catch( err => {
			Global.logError('Error drawing count guage.', err );
			throw new Error( 'Error drawing count guage.  ' +  err );
		});
	}

}
