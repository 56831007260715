import { TableDocumentObject } from './TableHandler'
import { RestaurantReservation } from "./RestaurantReservation";
import { RestaurantReservationResponse } from './RestaurantReservationResponse';

/** A specific table in a seating with a list of reserved times. */
export class RestaurantTableReservations extends TableDocumentObject {
	
	/**
	 * @param sectionId ID of the section assigned to the reservation.
	 * @param tableId ID of the table assigned to the reservation.
	 * @param reservationTime Time of reservation (may not be null)
	 * @param roomNumber Room number of the guest (default is null).
	 * @param name Name on the reservation
	 * @param persons Number of people who will be participating (default is 1).
	 * @param signUpTime Date and time when guests were added to the list.
	 */
	constructor(
		public sectionId: number = null,
		public tableId: number = null,
		public seats: number = null,
		private reservations: RestaurantReservation[] = []
		) { super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): RestaurantTableReservations {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.reservations = null;
		if (item['reservations']) {
			this.reservations = [];
			item['reservations'].forEach( childItem => this.reservations.push( new RestaurantReservation().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['reservations'] = null;
		if (this.reservations) {
			item['reservations'] = [];
			this.reservations.forEach( childItem => item['reservations'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

	/**
	 * Returns index where reservation can be inserted or -1 if time is not available.
	 * @param startTime Start time
	 * @param endTime End time
	 */
	private getIndexToInsertTime( startTime: Date, endTime: Date, seatingStartTime: Date, seatingEndTime: Date ): number {
		// console.log( 'getIndexForTime startTime='+startTime.toISOString()+', endTime='+endTime.toISOString()+', reservations='+JSON.stringify(this.reservations,null,2));
		let found = -1;
		if (startTime.getTime() >= seatingStartTime.getTime() && startTime.getTime() <= seatingEndTime.getTime()) {
			// Start time is within the seating time range
			if (this.reservations.length == 0) {
				// There are no reservations yet, this one can be added at index 0
				found = 0;
			} else {
				let previousEndTime = seatingStartTime;
				for (let i=0; i<this.reservations.length; i++) {
					let reservation = this.reservations[i];
					// console.log( '    Checking '+previousEndTime.toISOString()+' <= '+startTime.toISOString()+' && '+reservation.startTime.toISOString()+' >= '+endTime.toISOString());
					if ((previousEndTime.getTime() <= startTime.getTime()) && (reservation.startTime.getTime() >= endTime.getTime())) {
						// console.log( '    Found available block before reservation '+i );
						found = i;
						break;
					}
					previousEndTime = new Date( reservation.endTime.getTime() );
				}
				if (found == -1) {
					// See if time is available between last reservation end time and end of seating
					let lastReservation= this.reservations[ this.reservations.length-1 ].endTime;
					if (lastReservation.getTime() <= startTime.getTime() && seatingEndTime.getTime() >= endTime.getTime()) {
						// console.log( '    Found available block before end of seating' );
						found = this.reservations.length;
					}
				}
			}
		}
		// console.log( 'getIndexForTime startTime='+startTime.toISOString()+', endTime='+endTime.toISOString()+' returned '+found/*+', reservations='+JSON.stringify(this.reservations,null,2)*/);
		return found;
	}

	/**
	 * Returns true if the table has enough seats and is available between the given start and end times.
	 * @param persons Number of people.
	 * @param startTime Start time
	 * @param endTime End time
	 */
	public isAvailable( persons: number, startTime: Date, endTime: Date, seatingStartTime: Date, seatingEndTime: Date ): boolean {
		// console.log( 'isAvailable persons='+persons+', startTime='+startTime.toISOString()+', endTime='+endTime.toISOString());
		if (this.seats >= persons) {
			// console.log('Has enough persons');
			let index = this.getIndexToInsertTime( startTime, endTime, seatingStartTime, seatingEndTime );
			// console.log('found index '+index+', '+JSON.stringify(this.reservations[index]));
			// Return true if we found a block that contains the requested reservation time block and it is not reserved
			return index != -1;
		}
		return false;
	}

	/**
	 * Add the given reservation to this table.
	 * @param reservation Reservation to add.
	 */
	public addReservation( reservation: RestaurantReservation, seatingStartTime: Date, seatingEndTime: Date ) {
		let index = this.getIndexToInsertTime( reservation.startTime, reservation.endTime, seatingStartTime, seatingEndTime );
		if (index == -1) {
			throw new Error("Not enough available time for reservation");
		} else {
			this.reservations.splice( index, 0, reservation );
		}
		// console.log( 'addReservation reservation='+JSON.stringify(reservation,null,2)+', reservations='+JSON.stringify(this.reservations,null,2));
	}

	/**
	 * Returns index of the reservation that starts at the given time or -1 if reservation not found.
	 * @param startTime Reservation time.
	 */
	public getReservationIndex( startTime: Date ): number {
		let found = -1;
		for (let i=0; i<this.reservations.length; i++) {
			let time = this.reservations[i];
			if (time.startTime.getTime() == startTime.getTime()) {
				found = i;
				break;
			}
		}
		return found;
	}

	/**
	 * Returns index of the reservation that starts at the given time or -1 if reservation not found.
	 * @param startTime Reservation time.
	 */
	public getExistingReservationIndex( startTime: Date ): number {
		let index = this.getReservationIndex( startTime );
		if (index == -1) {
			throw new Error('Reservation not found for section '+this.sectionId+' table '+this.tableId+' time '+(startTime ? startTime.toISOString() : null)+'.');
		}
		return index;
	}

	/**
	 * Returns the reservation that starts at the given time or null if reservation not found.
	 * @param startTime Reservation time.
	 */
	public getReservation( startTime: Date ): RestaurantReservation {
		let reservation: RestaurantReservation = null;
		let index = this.getReservationIndex( startTime );
		if (index != -1) {
			reservation = this.reservations[index];
		}
		return reservation;
	}

	public removeReservationTime( startTime: Date ): number {
		let reservation: RestaurantReservation = null;
		let index = this.getReservationIndex( startTime );
		if (index != -1) {
			this.removeReservationIndex( index );
		}
		return index;
	}

	/**
	 * Returns true if reservation with given values was removed.  Null values are ignored.
	 * @param roomNumber Room number.
	 * @param partyName Name on reservation.
	 * @param persons Number of people.
	 * @param date Date and time of reservation.
	 */
	public removeReservation( roomNumber: string, partyName: string, persons: number, date: Date ): boolean {
		let removed = false;
		if (roomNumber != null || partyName != null || persons != null || date != null) {
			for (let i=0; i<this.reservations.length; i++) {
				let time = this.reservations[i];
				// console.log( 'checking reservation for removal: '+JSON.stringify(time,null,2));
				if (time.persons != null 
					&& (roomNumber == null || roomNumber === time.roomNumber)
					&& (partyName == null || partyName === time.name)
					&& (persons == null || persons === time.persons)
					&& (date == null || date.getTime() == time.startTime.getTime())) 
				{
					// Remove reservation
					// console.log( 'removing reservation');
					this.removeReservationIndex( i );
					removed = true;
					break;
				}
			}
		}
		return removed;
	}

	public removeReservationIndex( index: number ) {
		this.reservations.splice( index, 1 );
	}

	/**
	 * Response has nearest earlier and later available time of the same length and the requested time.
	 * @param persons Number of people
	 * @param startTime Start time
	 * @param endTime End time
	 */
	private checkReservationFitsAvailableBlock( reservationStartTime: Date, reservationEndTime: Date, blockStartTime: Date, blockEndTime: Date, response: RestaurantReservationResponse ) {
		// console.log('getNearestAvailableTimes persons='+persons+' startTime='+(startTime?startTime.toISOString():null)+', endTime='+(endTime?endTime.toISOString():null)+', reservations='+JSON.stringify(this.reservations,null,2) );
		let millis = reservationEndTime.getTime() - reservationStartTime.getTime();
		let blockMillis = blockEndTime.getTime() - blockStartTime.getTime();
		if (blockMillis >= millis) {
			// Found available block long enough for reservation
			if (blockStartTime.getTime() < reservationStartTime.getTime()) {
				// Block starts before requested time
				response.alternateEarlierTime = new Date( blockEndTime.getTime() - millis );
			} else {
				// Block starts after requested time
				response.alternateLaterTime = new Date( blockStartTime.getTime() );
			}
		}
	}

	/**
	 * Response has nearest earlier and later available time of the same length and the requested time.
	 * @param persons Number of people
	 * @param startTime Start time
	 * @param endTime End time
	 * @param intervalMinutes Number of minutes between valid reservation times.
	 */
	public getNearestAvailableTimes( persons: number, startTime: Date, endTime: Date, seatingStartTime: Date, seatingEndTime: Date, intervalMinutes: number ): RestaurantReservationResponse {
		// console.log('getNearestAvailableTimes persons='+persons+' startTime='+(startTime?startTime.toISOString():null)+', endTime='+(endTime?endTime.toISOString():null)+', timreservationses='+JSON.stringify(this.reservations,null,2) );
		let response = new RestaurantReservationResponse();
		let reservationMillis = endTime.getTime() - startTime.getTime();
		let intervalMillis = intervalMinutes * 60000;

		// Round start time to nearest reservation increment (in case guest requested 9:07 but we reserve on the half hour)
		let roundedStartTime = new Date( Math.floor( startTime.getTime() / intervalMillis ) * intervalMillis );

		if (startTime.getTime() > seatingStartTime.getTime()) {
			// Start time is after seating opens, find earlier available time if any
			let time = roundedStartTime;
			while (time.getTime() >= seatingStartTime.getTime()) {
				if (this.getIndexToInsertTime( time, new Date( time.getTime() + reservationMillis ), seatingStartTime, seatingEndTime ) != -1) {
					response.alternateEarlierTime = time;
					break;		
				}
				time = new Date( time.getTime() - intervalMillis );
			}
		}

		// Find later available time if any
		let time = roundedStartTime;
		// console.log( 'Looking for later time starting with '+time.toISOString());
		while (time.getTime() <= seatingEndTime.getTime()) {
			// console.log( '  Checking '+time.toISOString()+' - '+new Date( time.getTime() + reservationMillis ).toISOString());
			if (this.getIndexToInsertTime( time, new Date( time.getTime() + reservationMillis ), seatingStartTime, seatingEndTime ) != -1) {
				// console.log( '  Found '+time.toISOString());
				response.alternateLaterTime = time;
				break;		
			}
			time = new Date( time.getTime() + intervalMillis );
		}

		if (response.alternateEarlierTime != null && response.alternateLaterTime && response.alternateEarlierTime.getTime() == response.alternateLaterTime.getTime()) {
			// If requested time is available, only report it as the alternate later time, not both
			response.alternateLaterTime = null;
		}
		// console.log('getNearestAvailableTimes returns earlier='+(response.alternateEarlierTime?response.alternateEarlierTime.toISOString():null)+', later='+(response.alternateLaterTime?response.alternateLaterTime.toISOString():null) );
		return response;
	}

	getReservations(): RestaurantReservation[] {
		let reservations: RestaurantReservation[] = [];
		this.reservations.forEach( reservation => {
			if (reservation.persons != null) {
				reservations.push( reservation );
			}
		});
		return reservations;
	}
}

