import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { CacheService } from "../../service/cache.service";
import { Property } from '../../service/Property';
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";

/** Component for editing a property ID in the list of properties where an employee works. */
@Component({
    selector: 'property-selector',
    templateUrl: './property-selector.html',
	styleUrls: ['./property-selector.css'],
})
export class PropertySelectorComponent extends EditorDetail<Number> implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('propertySelectorModalButton') public modalButtonRef: ElementRef;

	@ViewChild('focus') public focusRef: ElementRef;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        public cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): Number {
		return new Number();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		if (this.editedRow.valueOf() == null && this.cache.properties.length > 0) {
			// Default to first one in list
			this.editedRow = new Number( this.cache.properties[0].propertyId );
		}
	}

	/** Return a deep copy of the given object. */
	clone( row: Number ): Number {
		return new Number( row );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		if (this.editedRow.valueOf() == null) {
			this.errorMessage = 'Please select a property.';
			return false;
		}
		// Convert selected ID from string to number, combo box saves it as a string
		this.editedRow = new Number( this.editedRow );
		return true;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to remove property ' + this.cache.getProperty( this.editedRow.valueOf() ).name + ' from this employee?';
	}

}