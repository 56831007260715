import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../../service/user-registration.service";
import { UserLoginService } from "../../service/user-login.service";
import { CacheService } from "../../service/cache.service"
import { Global } from "../../service/Global"
import { SpinnerDialogComponent } from '../spinner-dialog/spinner-dialog.component';
import { Product } from '../../service/Product';

/** Page where user enters new account data. */
@Component({
    selector: 'confirm-email',
    templateUrl: './confirm-email.html',
	styleUrls: ['./confirm-email.css'],
})
export class ConfirmEmailComponent implements AfterViewInit {
  
	@ViewChild('focus') private focusRef: ElementRef; private focusElement: HTMLInputElement;
		
	@ViewChild(SpinnerDialogComponent) private spinnerDialog: SpinnerDialogComponent;
	
	private product: Product;
	confirmationCode: string;
	errorMessage: string;

    constructor(
		private regService: UserRegistrationService,
		private router: Router,
		private cache: CacheService,
        private userService: UserLoginService,
	) {}

	ngOnInit() {
		this.product = this.cache.purchaseDetails.product;
	}

	ngAfterViewInit() {
		this.focusElement = this.focusRef.nativeElement;
		setTimeout( () => this.focusElement.focus(), 1000 );
	}

    onConfirm() {
        this.errorMessage = null;
		this.spinnerDialog.toggleDialog(); // Show spinner
        this.regService.confirmRegistration( this.cache.currentUserEmail, this.confirmationCode, (error,result) => {
			if (error != null) {
				this.spinnerDialog.toggleDialog(); // Hide spinner
				this.errorMessage = error.message;
				Global.logError("Error confirming email.", error );
			} else { //success
				// Sign user in to their new account
				this.userService.authenticate(this.cache.currentUserEmail, this.cache.get('password') )
				.then( () => {
					this.cache.reportSignUpConversionToAdWords();
					this.cache.sendEventToAnalytics( 'CreateAccount', 'confirm email', 'confirm' );
					this.spinnerDialog.toggleDialog(); // Hide spinner
					if (this.cache.purchaseDetails) {
						// User is purchasing a product, go to checkout
						this.router.navigate(['/signuphome/checkout']);
					} else {
						// Go to default secure home page
						this.cache.appComponent.goToDefaultSecureHomePage();
					}
				}).catch( err => {
					this.spinnerDialog.toggleDialog(); // Hide spinner
					this.errorMessage = err.message; //+ ' ' + LoginComponent.loginStartTime.toISOString();
					Global.logError("Authentication error.", err );
					if (this.errorMessage === 'User is not confirmed.') {
						// console.log("redirecting");
						this.router.navigate(['/home/confirmRegistration', this.cache.currentUserEmail]);
					} else if (this.errorMessage === 'User needs to set password.') {
						// console.log("redirecting to set new password");
						this.router.navigate(['/home/newPassword']);
					} else {
						this.errorMessage = this.errorMessage;
					}
				});
			}
		});
    }

	private onResend() {
		this.regService.resendCode( this.cache.currentUserEmail, (error,result) => {
			if (error != null) {
				Global.logError("Error resending confirmation code.", error);
				this.errorMessage = "Something went wrong...please try again";
			} else {
				this.cache.confirmComponent.showModalDialog( 'A new confirmation code has been sent to '+this.cache.currentUserEmail+'.', 'New Code Sent', false )
				.then( confirmed => {
					setTimeout( () => this.focusElement.focus(), 1000 );
				})
				.catch( error => {
					Global.logError( 'Error displaying confirmation dialog.', error );
					this.errorMessage = error.message;
				});
			}
		});
	}

}
