import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { TopicComponent } from "../topic/topic.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Topic } from '../../service/Topic';
import { Property } from '../../service/Property';
import { GetAllTopicsCmd } from '../../command/GetAllTopicsCmd';
import { PutTopicCmd } from '../../command/PutTopicCmd';
import { DeleteTopicCmd } from '../../command/DeleteTopicCmd';
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";

@Component({
    selector: 'topics',
    templateUrl: './topics.html',
	styleUrls: ['./topics.css'],
})
export class TopicsComponent extends Editor<Topic> implements OnInit, AfterViewInit {

	private propertyId;

	@ViewChild(TopicComponent) private detailComponent: TopicComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Topics' );
	}

	initialize() {
		if (this.cache.getProperties().length > 0) {
			this.propertyId = this.cache.getProperties()[0].propertyId;
			this.detailComponent.propertyId = this.propertyId;
			this.changeDetectorRef.detectChanges();
			this.switchProperty( null );
		}
	}

	getProperties(): Property[] {
		return this.cache.properties ? this.cache.properties : <Property[]>[new Property(0,0,null)];
	}	

	switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		this.detailComponent.propertyId = this.propertyId;
		// Get list of rows to edit
		new GetAllTopicsCmd().do( this.cache.currentCompany.companyId, this.propertyId )
		.then( rows => this.rows = rows )
		.catch( error => {
			Global.logError('Error loading data.', error );
			this.errorMessage = 'Sorry, we had a problem loading the data to edit.  Please try again.';
		})
	}

	/** Returns component used to edit the selected row. */
	getDetailComponent(): EditorDetail<Topic> {
		return this.detailComponent;
	}

	/** Returns promise of array of rows to edit. */
	getRowLoader(): Promise<Topic[]> {
		return new GetAllTopicsCmd().do( this.cache.currentCompany.companyId, this.propertyId );
	}
	
	/** Creates a new row to be edited. */
	createNewRowToEdit(): Topic {
		return new Topic( this.cache.currentCompany.companyId, this.propertyId );
	}

	/** Called before new rows is saved. */
	prepareRowToInsert( newRow: Topic ) {
	}

	/** Detects changes in model so view is updated. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	getRowInserter( row: Topic ): Promise<Topic> {
		row.topicId = this.getNextAvailableId();
		return new PutTopicCmd().do( row );
	}

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	getRowUpdater( index: number, row: Topic ): Promise<Topic> {
		return new PutTopicCmd().do( row );
	}

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	getRowDeleter( index: number, row: Topic ): Promise<void> {
		return new DeleteTopicCmd().do( row );
	}

	/** Returns the next available ID which is one higher than the highest ID already used. */
	getNextAvailableId(): number {
		let id = 1;
		this.rows.forEach( row => {
			if (row.topicId >= id) {
				id = row.topicId+1;
			}
		})
		return id;
	}

}
