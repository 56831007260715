import { Command } from "./Command";
import { Company } from "../service/Company";

export class GetCompaniesWithPermissionCmd extends Command {

	/**
	 * For the given user, return the list of companies for which they have the given permission.
	 * @param companyId ID of the company the user is currently logged into.
	 * @param userId User ID
	 * @param email User's email address.
	 * @param permission Permission name.
	 */
	do( companyId: number, userId: string, email: string, permission: string ): Promise<Company[]> {
		return new Promise<Company[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "userId": userId, "email": email, "permission": permission } )
			.then( results => {
				// Convert generic JSON result object back to our objects
				let companies: Company[] = [];
				results.data.forEach( item => companies.push( new Company().fromDataItem( item ) ) );
				resolve( companies );
			}).catch( err => reject( err ) );
		});
	}

}
