import { Component, OnInit, OnDestroy, Input, ElementRef, AfterViewInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
//import 'rxjs/add/operator/map';
import { UserLoginService } from "../../service/user-login.service";
import { Visit } from '../../service/Visit';
import { VisitTable } from '../../service/VisitTable';
import { CacheService } from "../../service/cache.service";
import { S3Service } from "../../service/s3.service";
import { Person } from '../../service/Person';
import { PersonTable } from '../../service/PersonTable';
import { PersonFaceTable, PersonFace } from '../../service/PersonFaceTable';
import { PersonDetailComponent } from "../person-detail/person-detail.component";
import { Global } from '../../service/Global';
import { SQSService } from "../../service/sqs.service";
import { VisitQueueTable, VisitQueue } from '../../service/VisitQueueTable';
import { VisitNotificationQueue } from '../../service/VisitNotificationQueue';
import { VisitList } from '../../service/VisitList';
import { GetRecentVisitsCmd } from '../../command/GetRecentVisitsCmd';
import { TitleNavComponent } from "../title-nav/title-nav.component"

/**
 * Displays pictures of 8 most recent people (unique personId).
 * Draws box around each face and uses color or number to connect faces to attributes.
 * User can tap or click a face to edit attributes (first name, last name, loyalty ID, favorite drink, etc.)
 */
@Component({
    selector: 'connect',
    templateUrl: './connect.html',
    styleUrls: ['./connect.css'],
})
export class ConnectComponent implements OnInit, OnDestroy, AfterViewInit {

	/** Number of images to display on the carousel. */
	private readonly maxCards = 5;

	@ViewChild(PersonDetailComponent) private personDetailComponent: PersonDetailComponent;
	
	@ViewChild('canvas0') public canvas0: ElementRef;
	@ViewChild('canvas1') public canvas1: ElementRef;
	@ViewChild('canvas2') public canvas2: ElementRef;
	@ViewChild('canvas3') public canvas3: ElementRef;
	@ViewChild('canvas4') public canvas4: ElementRef;
	
	@ViewChild('data0') public data0: ElementRef;
	@ViewChild('data1') public data1: ElementRef;
	@ViewChild('data2') public data2: ElementRef;
	@ViewChild('data3') public data3: ElementRef;
	@ViewChild('data4') public data4: ElementRef;
	
	@ViewChild('div0') public div0: ElementRef;
	@ViewChild('div1') public div1: ElementRef;
	@ViewChild('div2') public div2: ElementRef;
	@ViewChild('div3') public div3: ElementRef;
	@ViewChild('div4') public div4: ElementRef;

	private divElements: HTMLDivElement[] = [];
	private canvasElements: HTMLCanvasElement[] = [];
	private dataElements: HTMLDivElement[] = [];
	public cardHeading: string[] = ['-', '-', '-', '-', '-', '-'];
	public cardDetails: string[] = ['-', '-', '-', '-', '-', '-'];
	public cardPoints: number[] = [0,0,0,0,0,0];
	public visitTime: string[] = ['-', '-', '-', '-', '-', '-'];

	/** List of visits in memory for fast scrolling. */
	private visitList: VisitList = new VisitList( this.cache, this.s3Service, this.personTable );

	/** First visit displayed on screen which may not be the currently selected visit. */
	private firstDisplayedIndex: number = 0;

	/** Commands from the user queued so that one finishes before the next one starts. */
	private queuedCommands = [];

	/** True if we are currently processing a command from the user. */
	private processingCommand = false;

	/** Person object used for editing person details. */
	private person: Person = new Person();
	
	/** Index of the card being edited. */
	private editedCardIndex: number;

	/** True if we are processing a user command and want the spinner to show the user that we are working on it. */
	private showSpinner = false;

	constructor(
		private router: Router, 
		private userService: UserLoginService, 
		private visitTable: VisitTable,
		private cache: CacheService,
		private s3Service: S3Service,
		private sqsService: SQSService,
		private personTable: PersonTable,
		private personFaceTable: PersonFaceTable,
		private visitQueueTable: VisitQueueTable,
		private visitNotificationQueue: VisitNotificationQueue,
		private http: HttpClient
	){}

    initialize() {
		// Global.log( 'Connect.component.isLoggedIn' );
		this.loadData()
		.catch( err => {
			// Failure here usually means user token has expired so return to login
			Global.logError('Error initializing Connect page.', err );
			// this.router.navigate(['/home/login']);
			alert('Sorry, there was an error displaying the page.  Please try again later.' );
		});
	}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

    ngOnDestroy() {
		this.visitNotificationQueue.deleteQueue();
    }

	public ngAfterViewInit() {
		//this.cache.currentCompany.deleteAllCompanyData();
		
		// Global.log( 'Connect.component.ngAfterViewInit' );
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-heart fa-fw"></i> VIP Connect' );
		
		this.canvasElements.push( this.canvas0.nativeElement );
		this.canvasElements.push( this.canvas1.nativeElement );
		this.canvasElements.push( this.canvas2.nativeElement );
		this.canvasElements.push( this.canvas3.nativeElement );
		this.canvasElements.push( this.canvas4.nativeElement );
		
		this.dataElements.push( this.data0.nativeElement );
		this.dataElements.push( this.data1.nativeElement );
		this.dataElements.push( this.data2.nativeElement );
		this.dataElements.push( this.data3.nativeElement );
		this.dataElements.push( this.data4.nativeElement );
		
		this.divElements.push( this.div0.nativeElement );
		this.divElements.push( this.div1.nativeElement );
		this.divElements.push( this.div2.nativeElement );
		this.divElements.push( this.div3.nativeElement );
		this.divElements.push( this.div4.nativeElement );

		this.divElements[0].addEventListener("transitionend", (event: TransitionEvent) => this.handleCanvasTransitionEnd( event, 0 ), false);
		this.divElements[1].addEventListener("transitionend", (event: TransitionEvent) => this.handleCanvasTransitionEnd( event, 1 ), false);
		this.divElements[2].addEventListener("transitionend", (event: TransitionEvent) => this.handleCanvasTransitionEnd( event, 2 ), false);
		this.divElements[3].addEventListener("transitionend", (event: TransitionEvent) => this.handleCanvasTransitionEnd( event, 3 ), false);
		this.divElements[4].addEventListener("transitionend", (event: TransitionEvent) => this.handleCanvasTransitionEnd( event, 4 ), false);
	}

	/**
	 * Redraws the image at the end of the transition so that the image is still sharp when moving
	 * from a smaller canvas to a larger canvas instead of just scaling the image up.
	 * @param event Transition event.
	 * @param cardIndex Index of the card that transitioned.
	 */
	private handleCanvasTransitionEnd( event: TransitionEvent, cardIndex: number ) {
		//Global.log( 'TE' + cardIndex + ' '+event.propertyName + ', elementName='+event.srcElement.classList );
		if (event.propertyName == 'width') {
			let visitIndex = this.getVisitIndexForCardIndex( cardIndex );
			this.loadAndDisplayImage( cardIndex, this.visitList.get( visitIndex ) )
			.catch( err => {
				Global.logError( 'Error loading and displaying image.', err );
			});
		}
	}

	/**
	 * Create visit notification queue for real-time updates and display most recent visits.
	 */
	private loadData(): Promise<void> {
		// this.personFaceTable.deleteAllSiteDocs( this.cache.currentCompany.companyId, this.cache.currentSite.siteId );
		// Global.log( 'Connect.component.loadDataAndUpdateView' );
		return new Promise<void>( (resolve, reject) => {
			let startTime = Date.now();

			// Call visits/recent API to create queue and get initial visits
			this.createVisitQueueAndGetInitialVisits( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, this.maxCards )
			.then( data => {
				console.log( 'createQueueAndGetInitialVisits finished in ' + (Date.now()-startTime) + 'ms.' );
				// console.log( 'data.visitNotificationQueueUrl='+data.visitNotificationQueueUrl);
				this.visitNotificationQueue.setQueueUrl( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, data.visitNotificationQueueUrl );
				data.visits.forEach( visitData => {
					let visit = new Visit().fromDataItem( visitData.visit );
					// console.log( 'visit='+JSON.stringify( visit, null, 2 ) );
					let person = visitData.person == null ? null : new Person().fromDataItem( visitData.person );
					// console.log( 'person='+JSON.stringify( person, null, 2 ) );
					let image = visitData.image == 'null' ? null : visitData.image;
					this.visitList.push( visit, person, image );
				});
				// Set the first displayed visit and the selected visit to the oldest visible visit
				this.firstDisplayedIndex = this.visitList.getLength() - this.maxCards;
				if (this.firstDisplayedIndex < 0) {
					this.firstDisplayedIndex = 0;
				}
				// Show pictures and person data from most recent visits
				this.repaintAllCards()
				.then( values => {
					console.log( 'Loaded ' + data.visits.length + ' initial visits in ' + (Date.now()-startTime) + 'ms.' );
					resolve(); 
				})
				.catch( err => reject( err ) );
			})
			.catch( err => {
				Global.logError( 'Error loading visit data.', err );
				alert( 'Error loading visit data.  Please try again later.' );
			});
		})
	}

	/**
	 * Calls visits/recent API to create a visit notification queue and get the initial Visit
	 * records along with the related Person records and S3 images.
	 */
	createVisitQueueAndGetInitialVisits( companyId: number, siteId: number, limit: number ): Promise<any> {
		return new Promise<any>( (resolve, reject) => {
			let startTime = Date.now();
			new GetRecentVisitsCmd().do( companyId, siteId, limit )
			.then( results => {
				console.log( 'API call succeeded in ' + (Date.now()-startTime) + 'ms.: '+JSON.stringify( results.data, null, 2 ) );
				resolve( results );
			}).catch( err => {
				// console.log( 'API call failed in ' + (Date.now()-startTime) + 'ms.: ' + JSON.stringify( err.data, null, 2 ) );
				reject( err );
			});
		});
	}
		
	private getEmptyCardCount() {
		return this.maxCards - (this.visitList.getLength() - this.firstDisplayedIndex);
	}

	private isRoomForNewVisit() {
		return this.visitNotificationQueue.isActive()  && this.getEmptyCardCount() > 0;
	}

	/** True if we are currently checking for new visits.  Used to prevent starting a new check while one is running. */
	private checkingForNewVisits = false;

	private checkForNewVisits(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			if (this.checkingForNewVisits || !this.isRoomForNewVisit()) {
				// We are already checking for visits or there is no room for new visits so do nothing
				console.log( 'NOT checking in checkForNewVisits, already checking');
				resolve();
			} else {
				this.checkingForNewVisits = true;
				if (this.visitList.hasThrownAwayLaterVisits() ) {
					// We've thrown away later visits because the user wanted to load earlier
					// visits and we didn't want to use too much memory, reload them.
					console.log( 'NOT checking in checkForNewVisits, showing later visits');
					this.showLaterVisitsAndCheckForNewVisitsWhenDone( resolve, reject );
				} else if (this.visitNotificationQueue.isCheckingForNotifications()) {
					// We are waiting for notifications of new visits which will trigger another
					// checkForNewVisits call when it completes so we don't have to call it again here.
					console.log( 'checkForNewVisits, already checking for notifications');
					this.checkingForNewVisits = false;
					resolve();
				} else {
					this.visitNotificationQueue.checkForNotifications()
					.then( message => {
						if (message) {
							// A message about a new visit was posted to the queue, show new visits
							console.log('Got visit notification');
							this.showLaterVisitsAndCheckForNewVisitsWhenDone( resolve, reject );
						} else {
							// No message about a new visit was received, check again
							console.log( 'checkForNewVisits, no notification');
							this.checkingForNewVisits = false;
							setTimeout( () => this.checkForNewVisits(), 1 );
							resolve();
						}
					})
					.catch( err => {
						Global.log('Error checking for notifications, check for later visits in case we missed a notification.');
						this.showLaterVisitsAndCheckForNewVisitsWhenDone( resolve, reject, err );
						// this.checkingForNewVisits = false;
						// setTimeout( () => this.checkForNewVisits(), 1 );
						// Global.logError( 'Error checking for notifications. ', err );
						// reject( err );
					});
				}
			}
		});
	}

	private showLaterVisitsAndCheckForNewVisitsWhenDone( resolve, reject, notificationError: Error = null ) {
		this.showLaterVisits()
		.then( () => {
			this.checkingForNewVisits = false;
			setTimeout( () => this.checkForNewVisits(), 1 );
			resolve();
		})
		.catch( err => {
			this.checkingForNewVisits = false;
			setTimeout( () => this.checkForNewVisits(), 1 );
			if (notificationError) {
				Global.logError( 'Error checking for notifications and showing later visits failed with: ' + notificationError.message, err );
			} else {
				Global.logError( 'Error showing later visits. ', err );
			}
			reject( err );
		});
	}

	/**
	 * Get enough new visits to fill up the remaining empty cards if available.
	 * @param visit Last visit showing on cards.
	 */
	private showLaterVisits(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			// console.log( 'showLaterVisits' );
			let emptyCards = this.getEmptyCardCount();
			if (this.visitNotificationQueue.isActive() && emptyCards > 0) {
				let startTime: Date = null;
				if (this.visitList.getLength() > 0) {
					// Look for visits that started after the last visit we have loaded
					startTime = this.visitList.getLatestStartTime();
				} else {
					// Look for visits that started within the last 2 hours
					startTime = new Date( new Date().getTime() - (2 * 60 * 60 * 1000) );
				}
				console.log( 'Show visits after: ' + startTime.toISOString() );
				this.visitTable.getLaterVisits( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, startTime, emptyCards )
				.then( visits => {
					if (visits) {
						console.log( 'Found '+visits.length+' visits.' /*+ '\n'+JSON.stringify( visits, null, 2 )*/ );
						let promises: Promise<any>[] = [];
						let currentCardNumber = this.maxCards-emptyCards;
						visits.forEach( visit => {
							// console.log( 'before push: '+this.getVisitListStatus() );
							this.visitList.push( visit );
							// console.log( 'after push: '+this.getVisitListStatus() );
							promises.push( this.loadAndDisplayImage( this.getCardIndexForCardNumber( currentCardNumber ), visit )
							.then( () => {
								emptyCards--;
								// Set the first displayed visit index in case we dropped some older visits out of the list
								this.firstDisplayedIndex = this.visitList.getLength() - (this.maxCards - emptyCards);
							}));
							currentCardNumber++;
						});
						Promise.all( promises )
						.then( results => {
							resolve();
						})
						.catch( err => reject( err ) );
						// console.log( 'After showLaterVisits added visits, '+this.getVisitListStatus() );
					} else {
						console.log( 'No visits found.' );
						resolve();
					}
				})
				.catch( err => {
					Global.logError( 'Error getting later visits.', err );
					reject( new Error( 'Error getting later visits: ' + err.message ) );
				});
			} else {
				resolve();
			}
		});
	}

	private getVisitListStatus(): string {
		let status = 'firstDisplayedIndex='+this.firstDisplayedIndex;
		status += '\nvisitList.length()='+this.visitList.getLength();
		for (let i=0; i<this.visitList.getLength(); i++) {
			status += '\nvisit['+i+'] start='+this.getVisitTimeString( this.visitList.get( i ) );
		}
		return status;
	}

	private getCardHeadingForCardIndex( cardIndex: number ) {
		let visit = this.getVisitForCardIndex( cardIndex );
		return this.getCardHeading( visit );
	}

	private getCardHeading( visit: Visit ) {
		let heading = '-';
		if (visit) {
			let person = this.visitList.getPersonForVisit( visit );
			if (person) {
				if (person.cardHeading) {
					heading = person.cardHeading;
					// if (person.points && person.points != 0) {
					// 	heading += ' (' + person.points + ')';
					// }
				// } else if (person.points && person.points != 0) {
				// 	heading = '(' + person.points + ')';
				}
			}
		}
		return heading;
	}

	private getCardDetailsForCardIndex( cardIndex: number ) {
		let visit = this.getVisitForCardIndex( cardIndex );
		return this.getCardDetails( visit );
	}

	private getCardDetails( visit: Visit ) {
		let details = '-';
		if (visit) {
			let person = this.visitList.getPersonForVisit( visit );
			if (person && person.cardText && person.cardText.trim().length > 0) {
				details = person.cardText;
			}
		}
		return details;
	}

	private getVisitTimeString( visit: Visit ) {

		let value = '-';
		if (visit) {
			let timeZone = this.cache.getTimeZone( this.cache.currentSite.propertyId );
			value = Global.getMDHM12TimeString( visit.startTime, timeZone );
			// // let siteTime = Global.adjustDateByTimeZone( visit.startTime, timeZone );
			// let siteTime = Global.adjustDateByTimeZone( visit.startTime, timeZone );
			// let ampm = 'am';
			// let hours = siteTime.getUTCHours();
			// if (hours > 11) {
			// 	ampm = 'pm'
			// }
			// if (hours > 12) {
			// 	hours -= 12;
			// }
			// let minutes = siteTime.getUTCMinutes().toString();
			// if (minutes.length == 1) {
			// 	minutes = '0'+minutes;
			// }
			// value = (siteTime.getUTCMonth()+1) + '/' + siteTime.getUTCDate() + ' ' + hours + ':' + minutes + ampm;
		}
		return value;
	}

	/**
	 * Get the card class number, ie. card0 that controls the size and position of the card.
	 * @param cardIndex Index of the card in the array which matches the order they are declared in the HTML.
	 */
	private getCardNumberForCardIndex( cardIndex: number ) {
		let classes = this.divElements[cardIndex].classList.toString();
		let cardNumber = Number.parseInt( classes.substr( classes.length-1, 1 ) );
		// console.log( 'card['+cardIndex+'] has cardNumnber '+cardNumber+', classes='+classes);
		return cardNumber;
	}

	/**
	 * Get the index of the visit displayed at the given card index.
	 * @param cardIndex Index of the card in the array which matches the order they are declared in the HTML.
	 */
	private getVisitIndexForCardIndex( cardIndex: number ) {
		return this.firstDisplayedIndex + this.getCardNumberForCardIndex( cardIndex );
	}

	/**
	 * Get the index of the visit displayed at the given card index.
	 * @param cardIndex Index of the card in the array which matches the order they are declared in the HTML.
	 */
	private getVisitForCardIndex( cardIndex: number ) {
		return this.visitList.get( this.getVisitIndexForCardIndex( cardIndex ) );
	}

	/**
	 * Get the card index index for the card with the given card number.
	 * @param cardNumber The card class number, ie. card0 that controls the size and position of the card.
	 */
	private getCardIndexForCardNumber( cardNumber: number ): number {
		let cardIndex = null;
		for (let i=0; i<this.maxCards; i++) {
			if (this.getCardNumberForCardIndex( i ) == cardNumber) {
				cardIndex = i;
				break;
			}
		}
		// console.log( 'card '+cardIndex+' has card number ' + cardNumber );
		return cardIndex;
	}

	/**
	 * Get the visit index for the card with the given card number.
	 * @param cardNumber The card class number, ie. card0 that controls the size and position of the card.
	 */
	private getVisitIndexForCardNumber( cardNumber: number ) {
		let cardIndex = this.getCardIndexForCardNumber( cardNumber );
		return this.getVisitIndexForCardIndex( cardIndex );
	}

	private getVisitForCardNumber( cardNumber: number ) {
		let visit = null;
		let visitIndex = this.getVisitIndexForCardNumber( 1 );
		if (this.visitList.getLength() > visitIndex && this.visitList.get( visitIndex ) ) {
			visit = this.visitList.get( visitIndex );
		}
		return visit;
	}

	/**
	 * Queue a command to be processed when all commands that have already been issued have been processed.
	 * @param name Name of the command.
	 * @param cardIndex Index of the card the command should affect.
	 */
	private queueCommand( name: string, cardIndex: number ) {
		Global.log( 'queueCommand( name='+name+', cardIndex='+cardIndex );
		this.showSpinner = true;
		this.queuedCommands.push( { name: name, cardIndex: cardIndex } );
		if (!this.processingCommand) {
			this.processOldestQueuedCommand();
		}
	}
	
	/**
	 * Recursively process queued commands until the queue is empty.
	 * Processing yields between commands to allow the browser to update.
	 */
	private processOldestQueuedCommand() {
		if (this.queuedCommands.length > 0 && !this.processingCommand) {
			// Set flag so we don't start processing another move command before this one is complete
			this.processingCommand = true;
			let cmd = this.queuedCommands.shift();
			// Global.log( 'processing command '+cmd.name+' for card index '+cmd.cardIndex );
			try {
				let promise: Promise<void> = null;
				if (cmd.name == 'next') {
					promise = this.processNext();
				} else if (cmd.name == 'prev') {
					promise = this.processPrev();
				} else if (cmd.name == 'done') {
					promise = this.processDone( cmd.cardIndex );
				} else if (cmd.name == 'nextPage') {
					promise = this.processNextPage();
				} else if (cmd.name == 'prevPage') {
					promise = this.processPrevPage();
				} else if (cmd.name == 'edit') {
					promise = this.processEdit( cmd.cardIndex );
				// } else if (cmd.name == 'save') {
				// 	promise = this.processSave();
				// } else if (cmd.name == 'cancel') {
				// 	promise = this.processCancel();
				} else if (cmd.name == 'gotoNewest') {
					promise = this.processGotoNewest();
				} else {
					Global.log( 'Error processing command ' + cmd.name + ' for card index '+cmd.cardIndex+'.  Invalid command.' );
				}
				if (promise) {
					promise.then( () => {
						this.checkForNewVisits()
						.then( () => {
							// console.log( 'After '+cmd.name+', firstDisplayedIndex='+this.firstDisplayedIndex+', visits='+this.visitList.getLength());
							this.processingCommand = false;
							if (this.queuedCommands.length > 0) {
								// Process next command after yielding so the browser can update
								setTimeout( () => { this.processOldestQueuedCommand() }, 20 );
							} else {
								this.showSpinner = false;
							}
						})
						.catch( err => {
							Global.logError( 'Error checking for new visits after processing command ' + cmd.name + ' for card index '+cmd.cardIndex+'.', err );
							this.processingCommand = false;
							this.showSpinner = false;
						});
					})
					.catch( err => {
						Global.logError( 'Error processing command ' + cmd.name + ' for card index '+cmd.cardIndex+'.', err );
						this.processingCommand = false;
						this.showSpinner = false;
					});
				} else {
					this.processingCommand = false;
					this.showSpinner = false;
				}
			} catch( err ) {
				Global.logError( 'Error processing command ' + cmd.name + ' for card index '+cmd.cardIndex+'.', err );
				this.processingCommand = false;
				this.showSpinner = false;
			}
		}
	}
	
	/**
	 * Move cards to different positions.
	 * @param offset Number of positions to move, 1=move each card one position to the right, -2=two positions left
	 */
	private moveCards( startingCardNumber: number, offset: number ) {
		// console.log( 'moveCards startingCardNumber='+startingCardNumber+', offset='+offset);
		// if (startingCardNumber == 0) {
			// We are moving the first card, adjust the first displayed index
			this.firstDisplayedIndex += offset;
		// }

		// Clear out cards that are going off the right side
		if (offset>0) {
			// Clear cards that are going off the right side
			for (let cardNumber=startingCardNumber; cardNumber < startingCardNumber+offset && cardNumber < this.maxCards; cardNumber++) {
				this.loadAndDisplayImage( this.getCardIndexForCardNumber( cardNumber ), null );
			}
		} else {
			// Clear cards that are going off the left side
			let lastCardToClear = this.maxCards + offset;
			for (let cardNumber=this.maxCards-1; cardNumber >= lastCardToClear && cardNumber >= 0; cardNumber--) {
				this.loadAndDisplayImage( this.getCardIndexForCardNumber( cardNumber ), null );
			}
		}
		// Get the indexes of the cards we want to move
		let cardIndexes: number[] = [];
		for (let cardNumber=startingCardNumber; cardNumber<this.maxCards; cardNumber++) {
			cardIndexes.push( this.getCardIndexForCardNumber( cardNumber ) );
		}
		// Move the cards at each index
		cardIndexes.forEach( cardIndex => {
			let oldCardNumber = this.getCardNumberForCardIndex( cardIndex )
			this.divElements[cardIndex].classList.remove( 'card'+oldCardNumber );
			this.dataElements[cardIndex].classList.remove( 'card-data'+oldCardNumber );
			// If offset == maxCards (prevPage and nextPage), for a good visual effect, only move
			// maxCards-1 so the user will see the rolodex spin
			let positionsToMove = offset;
			if (positionsToMove === this.maxCards) {
				positionsToMove--;
			} else if (positionsToMove === -this.maxCards) {
				positionsToMove++;
			}
			let newCardNumber = oldCardNumber - positionsToMove;
			if (newCardNumber < startingCardNumber) {
				if (offset == 1) {
					newCardNumber = this.maxCards - offset;
				} else {
					newCardNumber += this.maxCards;
				}
			} else if (newCardNumber > (this.maxCards - 1)) {
				newCardNumber -= this.maxCards;
			}
			// console.log('moveCards offset='+offset+', positionsToMove='+positionsToMove+', oldNum='+oldCardNumber+', newNum='+newCardNumber);
			// console.log( 'moving card['+cardIndex+'] from card number '+oldCardNumber+' to '+newCardNumber);
			this.divElements[cardIndex].classList.add( 'card'+newCardNumber );
			this.dataElements[cardIndex].classList.add( 'card-data'+newCardNumber );
		});

		if (offset === 1) {
			let cardNumberToCheck = this.maxCards - offset;
			if (cardNumberToCheck > 0) {
				// Change the visit on the last card if next-to-last card is showing a visit
				let previousCardVisitIndex = this.getVisitIndexForCardNumber( cardNumberToCheck-1 );
				if (this.visitList.get( previousCardVisitIndex ) ) {
					// The previous card is showing a visit, show the visits on the blank cards after it
					for (let i=0; i<offset; i++) {
						let visitIndex = previousCardVisitIndex + 1 + i;
						if (this.visitList.getLength() > visitIndex && this.visitList.get( visitIndex ) ) {
							// We have already loaded a visit, display it
							this.loadAndDisplayImage( this.getCardIndexForCardNumber( cardNumberToCheck+i ), this.visitList.get( visitIndex ) );
						} else {
							// We haven't loaded a visit at that index, see if one is available
							this.checkForNewVisits();
						}
					}
				}
			}
		}
	}
		
	onPrev() {
		this.queueCommand( 'prev', 0 );
	}

	onNext() {
		this.queueCommand( 'next', 0 );
	}

	onPrevPage() {
		this.queueCommand( 'prevPage', 0 );
	}
		
	onNextPage() {
		this.queueCommand( 'nextPage', 0 );
	}

	onGotoNewest() {
		this.queueCommand( 'gotoNewest', 0 );
	}
		
	onEdit( cardIndex: number ) {
		this.queueCommand( 'edit', cardIndex );
	}

	onTappedImage( cardIndex: number ) {
		// console.log( 'User tapped image on card '+cardIndex );
		this.queueCommand( 'done', cardIndex );
	}

	// onSave() {
	// 	this.queueCommand( 'save', 0 );
	// }

	// onCancel() {
	// 	this.queueCommand( 'cancel', 0 );
	// }

	processPrev(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			if (this.firstDisplayedIndex > 0) {
				// We have a previous visit already loaded			
				this.moveCards( 0, -1 );
				this.loadAndDisplayImage( this.getCardIndexForCardNumber( 0 ), this.visitList.get( this.firstDisplayedIndex ) );
				resolve();
			} else {
				// There are no earlier visits loaded, check the database for older visits to show
				let startTime = new Date(); 
				if (this.visitList.getLength() > 0) {
					startTime = this.visitList.get( 0 ).startTime;
				}
				this.visitTable.getEarlierVisits( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, startTime, 1 )
				.then( visits => {
					if (visits && visits.length > 0) {
						// We found a visit
						// console.log( 'found visits '+JSON.stringify( visits, null, 2));

						// Insert the new visit at the beginning of the list (list is oldest to newest)
						this.visitList.insertAtBeginning( visits[0] );

						// If we already had visits showing, move them to the left to make room for the new one
						if (this.visitList.getLength() > 1) {
							// Increment firstDisplayedIndex due to the visit we inserted (moveCards will decrement it back to 0)
							this.firstDisplayedIndex = 1;
							this.moveCards( 0, -1 );
						}

						// Show the image and data for the newly loaded visit
						this.loadAndDisplayImage( this.getCardIndexForCardNumber( 0 ), this.visitList.get( 0 ) );
						resolve();
					} else {
						// There are no earlier visits, do nothing
						resolve();
					}
				})
				.catch( err => {
					Global.logError( 'Error getting later visits.', err );
					reject( err );
				});
			}
		});
	}

	private processNext(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			// Allow change if the second card is currently showing a visit that we can move to the first card
			if (this.getVisitForCardNumber( 1 )) {

				// The second card is showing a visit, shift visits
				this.moveCards( 0, 1 );
			}
			resolve();
		});
	}
		
	private processGotoNewest(): Promise<void> {
		let companyId = this.cache.currentCompany.companyId;
		let siteId = this.cache.currentSite.siteId;
		let limit = this.maxCards;
		return new Promise<void>( (resolve, reject) => {
			// Get most recent visits
			new VisitTable().getMostRecentVisits( companyId, siteId, limit )
			.then( foundVisits => {
				// Load the person and image for each visit if they are not already loaded
				// console.log( 'Loaded ' + foundVisits.length + ' visits' );
				// Clear the existing visit list so we only show the most recent visits
				this.visitList.clear();
				
				// See of oldest loaded visit is in the visit list
				if (foundVisits.length > 0) {
					// Load into our visit list in reverse order (oldest to newest)
					let promises: Promise<any>[] = [];
					for (let i=foundVisits.length-1; i>=0; i--) {
						let visit = foundVisits[i];
						// console.log( 'Found visit '+i+': '+JSON.stringify( marshalledVisit, null, 2 ) );
						this.visitList.push( visit );
						promises.push( this.visitList.getPerson( visit.personId ) );
						promises.push( this.visitList.getImage( visit.imageName ) );
					}
					Promise.all( promises )
					.then( values => {
						// Set the first displayed visit and the selected visit to the oldest visible visit
						this.firstDisplayedIndex = this.visitList.getLength() - this.maxCards;
						if (this.firstDisplayedIndex < 0) {
							this.firstDisplayedIndex = 0;
						}
						// Show pictures and person data from most recent visits
						this.repaintAllCards()
						.then( values => {
							resolve(); 
						})
						.catch( err => reject( err ) );
					})
					.catch( err => reject( err ) );
				} else {
					// No visits found that were less than 2 hours old
					this.repaintAllCards()
					.then( values => {
						resolve(); 
					})
					.catch( err => reject( err ) );
					resolve();
				}
			})
			.catch( err => reject( err ) );
		});
	}

	/**
	 * Display pictures and person data on all cards
	 */
	private repaintAllCards(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			// Show pictures and person data from most recent visits
			let promises: Promise<void>[] = [];
			for (let cardNumber=0; cardNumber < this.maxCards; cardNumber++) {
				let visit = null;
				if (this.visitList.getLength() > this.firstDisplayedIndex+cardNumber) {
					visit = this.visitList.get( this.firstDisplayedIndex+cardNumber );
				}
				promises.push ( this.loadAndDisplayImage( this.getCardIndexForCardNumber( cardNumber ), visit ) );
			}
			Promise.all( promises )
			.then( values => {
				this.checkForNewVisits();
				resolve(); 
			})
			.catch( err => reject( err ) );
		});
	}

	processPrevPage(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			if (this.firstDisplayedIndex >= this.maxCards) {
				// We have have another page already loaded
				this.moveCards( 0, -this.maxCards );
				// Show pictures and person data from most recent visits
				this.repaintAllCards()
				.then( values => {
					resolve(); 
				})
				.catch( err => reject( err ) );
			} else {
				// See if there are earlier visits to load for the previous page
				let emptyCardCount = this.maxCards - this.firstDisplayedIndex;				
				let earliestVisitTime = this.visitList.getEarliestStartTime();
				if (earliestVisitTime == null) {
					earliestVisitTime = new Date();
				}
				this.visitTable.getEarlierVisits( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, earliestVisitTime, emptyCardCount )
				.then( visits => {
					if (visits.length > 0 || this.firstDisplayedIndex > 0) {
						// There are more visits to show, show them
						// let oldVisitCount = this.visitList.getLength();
						visits.forEach( visit => {
							this.visitList.insertAtBeginning( visit );
						});
						// Adjust first displayed index to account for inserted visits
						this.firstDisplayedIndex += visits.length;
						this.moveCards( 0, Math.min( this.maxCards, this.firstDisplayedIndex ) * -1 );
						// Show pictures and person data from most recent visits
						this.repaintAllCards()
						.then( values => {
							resolve(); 
						})
						.catch( err => reject( err ) );
					} else {
						// There is not another page to display, do nothing
						resolve();
					}
				})
				.catch( err => reject( err ) );
			}
		});
	}

	processNextPage(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			if (this.visitList.getLength() > this.firstDisplayedIndex + this.maxCards) {
				// We have have another page and at least the first visit is already loaded
				this.moveCards( 0, this.maxCards );
				// Show pictures and person data from most recent visits
				this.repaintAllCards()
				.then( values => {
					resolve(); 
				})
				.catch( err => reject( err ) );
			} else if (this.visitList.hasThrownAwayLaterVisits()) {
				// See if there are enough later visits to go to the next page
				let latestVisitTime = this.visitList.getLatestStartTime();
				this.visitTable.getLaterVisits( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, latestVisitTime, this.maxCards )
				.then( visits => {
					if (visits.length > 0) {
						// There are more visits to show, show them
						let oldVisitCount = this.visitList.getLength();
						visits.forEach( visit => {
							this.visitList.push( visit );
						});
						// Adjust first displayed index by the number of visits that were
						// dropped off the list to keep the list from filling up memory
						let droppedVisitCount = (oldVisitCount + visits.length) - this.visitList.getLength();
						this.firstDisplayedIndex -= droppedVisitCount;
						this.moveCards( 0, this.maxCards );
						// Show pictures and person data from most recent visits
						this.repaintAllCards()
						.then( values => {
							resolve(); 
						})
						.catch( err => reject( err ) );
					} else {
						// There is not another page to display, do nothing
						resolve();
					}
				})
				.catch( err => reject( err ) );
			} else {
				// There is not another page to display, do nothing
				resolve();
			}
		});
	}

	processEdit( cardIndex: number ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			// Global.log( 'User tapped edit '+index );
			let visit = this.getVisitForCardIndex( cardIndex );
			if (visit) {
				this.visitList.getPerson( visit.personId )
				.then( person => {
					// this.cache.set( PersonDetailComponent.editedPersonCacheName, person );
					// this.router.navigate(['/securehome/person-detail']);
					this.person = person;
					this.editedCardIndex = cardIndex;
					console.log( 'Editing '+JSON.stringify( this.person, null, 2 ) );

					// Make a copy of the person object for editing in case they cancel after making changes
					let editedPerson = new Person().fromDataItem( person.toDataItem() );
					this.personDetailComponent.setPerson( editedPerson );
					this.personDetailComponent.showModalDialog( saved => {
						if (saved) {
							person.fromDataItem( editedPerson.toDataItem() );
							// console.log( 'Saved person ' + JSON.stringify( person, null, 2 ) );
							this.loadAndDisplayImage( this.editedCardIndex, this.getVisitForCardIndex( this.editedCardIndex ) )
							// console.log( 'edited person='+JSON.stringify( editedPerson, null, 2));
						}
					});
					
					resolve();
				})
				.catch( err => {
					Global.logError( 'Error editing visitor data.', err );
					err.message = 'Error editing visitor data.  ' + err.message;
					alert( err.message );
					reject( err );
				});
			} else {
				// There is no visit on the card, do nothing
				resolve();
			}
		});
	}

	processDone( cardIndex: number ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			let cardNumber = this.getCardNumberForCardIndex( cardIndex );
			if (cardNumber != 0) {
				// User is bumping a visit other than the first displayed one, move the bumped
				// visit to card 0 so that the visits stay in the order they were bumped off the screen
				this.visitList.moveVisit( this.firstDisplayedIndex + cardNumber, this.firstDisplayedIndex );
			}
			this.moveCards( cardNumber, 1 );
			resolve();
		});
	}

	private loadAndDisplayImage( cardIndex: number, visit: Visit ): Promise<void> {
		return new Promise<void>( (resolve, reject ) => {
			// Global.log( 'loadAndDisplayImage ' + index + ', ' + visit.imageName + ', ' + visit.boxLeft );
			// let startTime = Date.now();
			if (!visit) {
				console.log( 'Drawing blank visit card '+cardIndex);
				this.cardHeading[cardIndex] = this.getCardHeading( visit );
				this.cardDetails[cardIndex] = this.getCardDetails( visit );
				this.cardPoints[cardIndex] = 0;
				this.visitTime[cardIndex] = this.getVisitTimeString( visit );
				this.drawBlankVisitImage( cardIndex );
				resolve();
			} else {
				console.log( 'Drawing visit card '+cardIndex + ': ' + visit.startTime.toISOString() );
				this.visitList.getPerson( visit.personId )
				.then( person => {
					console.log( 'Display person ' + JSON.stringify( person, null, 2 ) );
					this.cardHeading[cardIndex] = this.getCardHeading( visit );
					this.cardDetails[cardIndex] = this.getCardDetails( visit );
					this.cardPoints[cardIndex] = person  && person.points ? person.points : 0;
					this.visitTime[cardIndex] = this.getVisitTimeString( visit );
					this.visitList.getImage( visit.imageName )
					.then( dataURI => {
						this.displayImage( cardIndex, visit, dataURI )
						.then( () => { 
							// console.log('loadAndDisplayImage - finished in '+(Date.now()-startTime)+'ms.');
							resolve() 
						})
						.catch( err => { 
							Global.log( 'Error displaying image: ' + err.message + '\n' + err.stack );
							reject( err ); 
						} );
					})
					.catch( err => {
						Global.log( 'Error getting image: ' + err.message + '\n' + err.stack );
						reject( err );
					});
				})
				.catch( err => {
					Global.log( 'Error getting person: ' + err.message + '\n' + err.stack );
					reject( err );
				});
			}
		});
	}

	private drawBlankVisitImage( cardIndex: number ) {
		// console.log( 'drawBlankVisitImage ' + cardIndex );
		let canvas = this.canvasElements[cardIndex];
		let context2d = canvas.getContext( '2d');
		let width = canvas.parentElement.clientWidth;
		let height = width * .75;

		// Draw image on canvas
		canvas.width = width;
		canvas.height = height;

		// Draw bounding box around face
		context2d.fillStyle = 'rgba(255, 239, 208, 1.0)'; // Fill with pale gold like rolodex background
		context2d.fillRect( 0, 0, width, height );
	}

	private displayImage( cardIndex: number, visit: Visit, dataUri: string ): Promise<void> {
		console.log( 'displayImage ' + cardIndex + ', ' + visit.imageName + ', ' + visit.boxLeft );
		return new Promise<void>( (resolve, reject ) => {
			let startTime = Date.now();
			let canvas = this.canvasElements[cardIndex];
			let context2d = canvas.getContext( '2d');
			let tempImage = new Image();
			tempImage.onload = () => {
				// console.log('displayImage - image loaded in '+(Date.now()-startTime)+'ms.');
				let width = tempImage.width;
				let height = tempImage.height;
				let parentWidth = canvas.parentElement.clientWidth;
				let parentHeight = canvas.parentElement.clientHeight;
				// Global.log( 'onload ' + index + ' ' + width + 'x' + height + ' box=' + visit.boxLeft + ',' + visit.boxTop + ' ' + visit.boxWidth + 'x' + visit.boxHeight+', canvas='+canvas.width+'x'+canvas.height+', parent='+parentWidth+'x'+parentHeight );
				if (width > parentWidth) {
					// Shrink image to fit and maintain aspect ratio
					let percent = parentWidth / width;
					width *= percent;
					height *= percent;
				}
				// console.log( 'Draw card ' + cardIndex + ' image size=' + tempImage.width + 'x'+tempImage.height+' canvas='+width + 'x'+height );
				
				// Draw image on canvas
				canvas.width = width;
				canvas.height = height;
				context2d.drawImage( tempImage, 0, 0, width, height );

				// Draw bounding box around face
				context2d.lineWidth = 1;
				context2d.strokeStyle = 'rgba(255, 255, 0, 1.0)';
				context2d.strokeRect( visit.boxLeft * width, visit.boxTop * height, visit.boxWidth * width, visit.boxHeight * height );
				console.log('displayImage - finished in: '+(Date.now()-startTime)+'ms.');
				resolve();
			};
			tempImage.src = dataUri;
		});
	}


	/** Calls a REST API hosted in an Elastic Beanstalk webapp so we don't have to wait for a Lambda to spin up. */
	// callRestApiToGetRecentVisits(): Promise<void> {
	// 	return new Promise<void>( (resolve, reject) => {
	// 		// Test calling our elastic beanstalk app server
	// 		// let headers = new Headers({});
	// 		// let options = new RequestOptions({ headers: headers });
	// 		// let formData = null;
	// 		console.log('before calling web');
	// 		let observable = this.http.get('http://ebnodesamplea-4i5r8-env.us-west-2.elasticbeanstalk.com/')
	// 			.map((response: Response) => {
	// 				console.log( 'got response in' );
	// 				console.log( response.json() );
	// 			});
	// 		observable.subscribe( data => {
	// 			console.log( 'subscribe got data'+data );
	// 			console.log( JSON.stringify( data ) );
	// 		}, err => {
	// 			console.log( 'subscribe got err'+err );
	// 			console.log( JSON.stringify( err ) );
	// 		});
	// 		console.log('after calling web');
	// 		resolve();
	// 	});
	// }

}
