import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { Ad } from "../../service/Ad";
import { AdCategory } from "../../service/AdCategory";
import { AdProperty } from "../../service/AdProperty";
import { AdSubcategory } from "../../service/AdSubcategory";
import { PropertyName } from "../../service/PropertyName";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";
// import { TimeInputModel } from "../../service/TimeInputModel";
import { IMyDpOptions } from 'mydatepicker';
import { SiteAdPropertyComponent } from '../site-ad-property/site-ad-property.component';
import { GetAvailableAdPropertyNamesCmd } from '../../command/GetAvailableAdPropertyNamesCmd';
import { last } from "@angular/router/src/utils/collection";

/**
 * Component for editing a restaurant layout.
 */
@Component({
    selector: 'site-ad',
	templateUrl: './site-ad.html',
	styleUrls: [ 'site-ad.css' ]
})
export class SiteAdComponent extends EditorDetail<Ad> implements OnInit, OnDestroy {

	@ViewChild('SiteAdModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	@ViewChild(SiteAdPropertyComponent) private siteAdPropertyComponent: SiteAdPropertyComponent;

	/** Time zone where the currently selected property is located. */
	public timeZone: string = null;

	public siteLatitude: number;

	public siteLongitude: number;

	private myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
	};
	
	// Data model used for date picker, initialized to today.
	private startDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };
	
	// Data model used for date picker, initialized to today.
	private endDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	/** ID of the property where this site is located. */
	public propertyId;

	/** Currently selected ad category */
	public selectedAdCategoryId: string = '1';

	/** Currently selected ad subcategory */
	public selectedAdSubcategoryId: string = null;

	/** List of ad categories loaded so we have their names. */
	public adCategories: AdCategory[] = [];

	/** List of company and property names of hotels where ads may be played. */
	private propertyNames: PropertyName[] = null;

	/** The ad radius we used most recently to load the list of properties where ad can play. */
	private lastAdRadiusMiles: number = null;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Create a new row with default values. */
	createNewDefaultRow(): Ad {
		return new Ad();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	initializeDialog() {
		this.propertyNames = [];
		this.selectedAdCategoryId = this.editedRow.adCategoryId ? this.editedRow.adCategoryId.toString() : null;
		this.selectedAdSubcategoryId = this.editedRow.subcategoryId != null ? this.editedRow.subcategoryId.toString() : null;
		// Initialize start date
		this.startDateModel = null;
		if (this.editedRow.startDate) {
			this.startDateModel = { jsdate: this.editedRow.startDate, date: { year: this.editedRow.startDate.getUTCFullYear(), month: this.editedRow.startDate.getUTCMonth()+1, day: this.editedRow.startDate.getUTCDate() } };
		}

		// Initialize end date
		this.endDateModel = null;
		if (this.editedRow.endDate) {
			this.endDateModel = { jsdate: this.editedRow.endDate, date: { year: this.editedRow.endDate.getUTCFullYear(), month: this.editedRow.endDate.getUTCMonth()+1, day: this.editedRow.endDate.getUTCDate() } };
		}

		// Load available ad property names
		this.propertyNames = [];
		this.getAvailableAdPropertyNames()
		.then( propertyNames => {
			this.propertyNames = propertyNames;
			this.detectChanges();
		})
		.catch( error => {
			Global.logError( 'Error loading ad property names.', error );
			this.errorMessage = 'Sorry, there was a problem loading the ad property names.  Please try again.';
		})
	}

	/** Return a deep copy of the given object. */
	clone( row: Ad ): Ad {
		return new Ad().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete this advertisement?'
	}

	isValid(): boolean {
		this.errorMessage = null;

		if (this.selectedAdCategoryId == null) {
			this.errorMessage = 'Please select a category.'
			return false;
		}
		
		// if (!this.editedRow.adRadiusMiles) {
		// 	this.errorMessage = 'Please enter the number of miles from the site where the ad should play.'
		// 	return false;
		// }

		if (!this.editedRow.speech || this.editedRow.speech.length == 0) {
			this.errorMessage = 'Please enter the speech you want Alexa to say for this ad.'
			return false;
		}

		// if (!this.startDateModel || !this.startDateModel.jsdate) {
		// 	this.errorMessage = 'Please enter a start date.'
		// 	return false;
		// }

		this.editedRow.adCategoryId = Number.parseInt( this.selectedAdCategoryId );
		
		this.editedRow.subcategoryId = this.selectedAdSubcategoryId == null ? null : Number.parseInt( this.selectedAdSubcategoryId );

		this.editedRow.startDate = null;
		if (this.startDateModel && this.startDateModel.jsdate) {
			this.editedRow.startDate = this.startDateModel.jsdate;
			this.editedRow.startDate.setUTCHours( 0, 0, 0, 0 );
		}

		this.editedRow.endDate = null;
		if (this.endDateModel && this.endDateModel.jsdate) {
			this.editedRow.endDate = this.endDateModel.jsdate;
			this.editedRow.endDate.setUTCHours( 0, 0, 0, 0 );
		}

		// if (this.editedRow.endDate && this.editedRow.startDate.getTime() >= this.editedRow.endDate.getTime()) {
		// 	this.errorMessage = 'Please enter an end date and time that is later than the start date and time.'
		// 	return false;
		// }

		return true;
	}

	private getDisplayName( adProperty: AdProperty ): string {
		let name = '';
		if (this.propertyNames) {
			for( let i=0; i<this.propertyNames.length; i++) {
				if (this.propertyNames[i].companyId == adProperty.companyId && this.propertyNames[i].propertyId == adProperty.propertyId) {
					name = this.propertyNames[i].getDisplayName();
					break;
				}

			}
		}
		return name;
	}

	private getBidAmount( bidCents: number ): string {
		return Global.getCurrencyStringForCents( bidCents );
	}

	private getAvailableAdPropertyNames(): Promise<PropertyName[]> {
		return new Promise<PropertyName[]>( (resolve,reject) => {
			if (this.editedRow.adRadiusMiles == this.lastAdRadiusMiles) {
				resolve( this.propertyNames );
			} else {
				new GetAvailableAdPropertyNamesCmd().do( this.cache.currentCompany.companyId, this.siteLatitude, this.siteLongitude, this.editedRow.adRadiusMiles )
				.then( propertyNameMap => {
					// Remember that we have loaded the list of all properties within this ads
					// current radius in case the user changes the radius.
					this.lastAdRadiusMiles = this.editedRow.adRadiusMiles;
					this.propertyNames = propertyNameMap;
					resolve( this.propertyNames );
				})
				.catch( error => {
					Global.logError( 'Error loading property names.', error );
					this.errorMessage = 'Sorry, there was a problem loading the property names.  Please try again.';
				});
			}
		});
	}

	insertAdProperty() {
		this.getAvailableAdPropertyNames()
		.then( propertyNames => {
			this.siteAdPropertyComponent.propertyNames = propertyNames;
			this.handleChildInsert( new AdProperty(), this.editedRow.adProperties, this.siteAdPropertyComponent );
		})
		.catch( error => {
			Global.logError( 'Error loading property names.', error );
			this.errorMessage = 'Sorry, there was a problem loading the property names.  Please try again.';
		});
	}

	editAdProperty( index: number ) {
		this.getAvailableAdPropertyNames()
		.then( propertyNames => {
			this.siteAdPropertyComponent.propertyNames = propertyNames;
			this.handleChildEdit( index, this.editedRow.adProperties, this.siteAdPropertyComponent );
		})
		.catch( error => {
			Global.logError( 'Error loading property names.', error );
			this.errorMessage = 'Sorry, there was a problem loading the property names.  Please try again.';
		});
	}

	getAdSubcategories( adCategoryId: number ) : AdSubcategory[] {
		let subcategories: AdSubcategory[] = [ new AdSubcategory( null, 'None' ) ];
		if (this.adCategories) {
			for (let i=0; i<this.adCategories.length; i++) {
				if (this.adCategories[i].adCategoryId == adCategoryId) {
					if (this.adCategories[i].subcategories) {
						this.adCategories[i].subcategories.forEach( subcategory => {
							subcategories.push( subcategory );
						});
					}
					break;
				}
			}
		}
		return subcategories;
	}
}