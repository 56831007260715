import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { ActivitySignUp } from "../../service/ActivitySignUp";
import { Property } from "../../service/Property"
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";

export class ActivitySignUpUserAction {
	constructor(
		public row: ActivitySignUp,
		public action: string
	){};
}

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'activity-signup',
	templateUrl: './activity-signup.html',
	styleUrls: [ 'activity-signup.css' ]
})
export class ActivitySignUpComponent extends EditorDetail<ActivitySignUp> implements OnInit, OnDestroy {

	editedRow: ActivitySignUp = new ActivitySignUp();

	originalRow: ActivitySignUp;

	timeZone: string;

	@ViewChild('activitySignUpModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
    )
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/** Return a deep copy of the given object. */
	clone( row: ActivitySignUp ): ActivitySignUp {
		return new ActivitySignUp().fromDataItem( row.toDataItem() );
	}

	createNewDefaultRow(): ActivitySignUp {
		return new ActivitySignUp();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to remove room ' + this.originalRow.roomNumber + ' from the list?';
	}

	private getDateTimeString( date: Date ): string {
		return Global.getDateTime12String( date, this.timeZone );
	}

	initializeDialog() {
	}

	isValid(): boolean {
		let valid = true;
		if ((!this.editedRow.roomNumber) || this.editedRow.roomNumber.trim() === '') {
			this.errorMessage = 'Please enter the room number.';
			valid = false;
		}
		if ((!this.editedRow.persons) || isNaN( this.editedRow.persons ) || this.editedRow.persons < 1) {
			this.errorMessage = 'Please enter the number of people (greater than 0)';
			valid = false;
		}
		// Set the signup time if needed
		if (!this.editedRow.signUpTime) {
			// This is a new activity, set the sign up time to the current time
			this.editedRow.signUpTime = new Date();
		}
		return valid;
	}

}