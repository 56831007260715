import { TableHandler, TableDocumentObject } from './TableHandler';
import { Global } from './Global';

/**
 * Feedback about an employee.
 */
export class EmployeeFeedback extends TableDocumentObject {

	/**
	 * 
	 * @param companyId ID of company (partition key = companyId_propertyId)
	 * @param propertyId ID of property (partition key = companyId_propertyId)
	 * @param feedbackId Unique ID for the feedback record. (sort key)
	 * @param roomNumber Room number that provided the feedback.
	 * @param badgeName Name on employee's badge
	 * @param department Department employee is in
	 * @param employeeId ID of the employee receiving the feedback. (local secondary index = employeeId_feedbackTime)
	 * @param firstName First name from employee's record
	 * @param lastName Last name from employee's record
	 * @param feedbackTime Date/time the feedback was given. (local secondary index = feedbackTime)
	 * @param feedbackValue Thumbs up = +1, thumbs down = -1
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = 0,
		public feedbackId: string = null,
		public roomNumber: string = null,
		public badgeName: string = null,
		public department: string = null,
		public employeeId: string = null,
		public firstName: string = null,
		public lastName: string = null,
		public feedbackTime: Date = new Date(),
		public feedbackValue: number = 1,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): EmployeeFeedback {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		item['employeeId_feedbackTime'] = this.employeeId + '_' + this.feedbackTime.toISOString();
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_propertyId": this.companyId + '_' + this.propertyId, 
            "feedbackId": this.feedbackId
        };
    }

}

/**
 * A record for each employee feedback.
 */
export class EmployeeFeedbackTable extends TableHandler<EmployeeFeedback> {

	/** Local secondary index use to query feedback by date/time. */
	public readonly feedbackTimeIndex = 'companyId_propertyId-feedbackTime-index';

	/** Local secondary index use to query feedback by date/time. */
	public readonly employeeIdFeedbackTimeIndex = 'companyId_propertyId-employeeId_feedbackTime-index';
	
	public constructor() {
		super( 'bookcliffsoftware-EmployeeFeedback' );
	}
	
	public fromDataItem( item: any ): EmployeeFeedback {
		return new EmployeeFeedback().fromDataItem( item );
	}

    /**
     * Get data for a date range for reporting.
     * @param companyId ID of the company.
	 * @param propertyId ID of the property where employee worked.
     * @param date Earliest date to keep, all older detections will be deleted.
     */
    // getMostRecentEmployeeFeedback( visit: EmployeeFeedback ): Promise<EmployeeFeedback> {
    // 	return new Promise( (resolve, reject) => {
	// 		let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and begins_with( personId_startTime, :personId )";
	// 		let expressionAttributeValues = {
	// 			":companyId_propertyId": visit.companyId + '_' + visit.propertyId,
	// 			":employeeId": visit.employeeId + '_',
	// 		};
	// 		this.query( keyConditionExpression, null, expressionAttributeValues, null, null, null, 1, false )
	// 		.then( result => {
	// 			// console.log( 'Found ' + (rows ? rows.length : 0) + ' visit rows.' );
	// 			if (result.objects.length > 0) {
	// 				resolve( result.objects[0] );
	// 			} else {
	// 				resolve( null );
	// 			}
	// 		})
	// 		.catch( err => {
	// 			reject( err );
	// 		})
    //     });
    // }

    /**
     * Get most recent visits that are less than 2 hours old up to the given max number of visits.
     * @param companyId ID of the company.
	 * @param propertyId ID of the property where employee worked.
	 * @param startTime Earliest date/time to return.
	 * @param endTime Latest date/time to return.
     */
    getDateRange( companyId: number, propertyId: number, startTime: Date, endTime: Date ): Promise<EmployeeFeedback[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and feedbackTime between :startTime and :endTime";
			let expressionAttributeValues = {
				':companyId_propertyId': companyId + '_' + propertyId,
				':startTime': startTime.toISOString(),
				':endTime': endTime.toISOString()
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.feedbackTimeIndex )
			.then( rows => {
				// console.log( 'getDateRange returned ' + rows.length + ' rows.  expression='+JSON.stringify(expressionAttributeValues,null,2) );
				resolve( rows );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	/**
	 * Returns a list of distinct room numbers that accessed the system between the given start and end date.
	 * @param companyId ID of the company
	 */
	public getActiveRoomNumbers( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<Set<string>> {
		return new Promise<Set<string>>( (resolve, reject) => {
			let roomNumbers: Set<string> = new Set();
			this.getDateRange( companyId, propertyId, startDate, endDate )
			.then( entries => {
				entries.forEach( entry => roomNumbers.add( entry.roomNumber ) );
				resolve( roomNumbers );
			})
			.catch( error => reject( error ) );
		});
	}
	
    /**
     * Delete old visit records that are no longer needed since data has been summarized.
     * @param companyId ID of the company.
	 * @param propertyId ID of the property where employee worked.
     * @param date Earliest date to keep, all older detections will be deleted.
     */
    purge( companyId: number, propertyId: number, oldestDateToKeep: Date ): Promise<number> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and feedbackTime < :oldestDateToKeep";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
				":oldestDateToKeep": oldestDateToKeep.toISOString(),
			};
			this.deleteAll( keyConditionExpression, expressionAttributeValues, null, this.feedbackTimeIndex )
			.then( deleteCount => {
				console.log( this.getTableName() + 'Purged ' + deleteCount + ' records from ' + this.getTableName() );
				resolve( deleteCount );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

}
