import { Component } from "@angular/core";

/** Home component for sign-up pages which don't use a navigation bar. */
@Component({
    selector: 'signuphome',
    template: '<router-outlet></router-outlet>'
})
export class SignupHomeComponent {
}

