import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CognitoCallback, CognitoUtil} from "../../../service/cognito.service";
import {UserRegistrationService} from "../../../service/user-registration.service";
import {UserLoginService} from "../../../service/user-login.service";
import { Global } from '../../../service/Global';

@Component({
    selector: 'awscognito-angular2-app',
    template: ''
})
export class LogoutComponent {

    constructor(public router: Router,
				public userService: UserLoginService,
	) {
        this.userService.isAuthenticated( (message: string, loggedIn: boolean) => {
			if (loggedIn) {
				this.userService.logout();
				this.router.navigate(['/home']);
			}
	
			this.router.navigate(['/home']);
			return false;
		});

    }

}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './confirmRegistration.html'
})
export class RegistrationConfirmationComponent implements OnInit, OnDestroy {
    confirmationCode: string;
	email: string;
	password: string;
    errorMessage: string;
    private sub: any;

    constructor(
		public regService: UserRegistrationService,
		public userService: UserLoginService,
		public cognitoUtil: CognitoUtil,
		public router: Router,
		public route: ActivatedRoute,
	) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['username'];
			// this.password = params['password'];
        });

        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onConfirmRegistration() {
        this.errorMessage = null;
        this.regService.confirmRegistration(this.email, this.confirmationCode, (error,result) => {
			if (error != null) {
				this.errorMessage = error.message;
				Global.logError("Error confirming email.", error );
			} else { //success
				// Make user login to their new account
				this.router.navigate(['/home/login']);
			}
		});
    }

}





