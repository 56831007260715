import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import {UserLoginService} from "../../service/user-login.service";
import {UserRegistrationService} from "../../service/user-registration.service";
import {RegistrationUser} from "../../public/auth/register/registration.component";
import {S3Service} from "../../service/s3.service";
import {CacheService} from "../../service/cache.service";
import {PollyService} from "../../service/polly.service";
import { Employee } from "../../service/Employee";
import { Property } from "../../service/Property";
import { SpeechSynthesizerComponent } from '../speech-synthesizer/speech-synthesizer.component';
import { PropertySelectorComponent } from '../property-selector/property-selector.component';
import { EmployeePhotoComponent } from '../employee-photo/employee-photo.component';
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";
import { GetEmployeeCmd } from "../../command/GetEmployeeCmd";

// URL of the employee photo to display on the editing screen, images are stored in S3
const defaultEmployeePhotoUrl: string = '../../../assets/img/user-shadow.jpg';
    
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'employee',
	templateUrl: './employee.html',
	styleUrls: [ 'employee.css' ]
})
export class EmployeeComponent extends EditorDetail<Employee> implements OnInit, OnDestroy {
    // editedRow: Employee = new Employee();
	// originalRow: Employee;
	// rows: Employee[];
	// errorMessage: string = null;
	// private resolveModal: (row: Employee) => void;
	// private rejectModal: (error: Error) => void;
	
	// /** True if this record will be inserted and the delete link is not shown. */
	// protected isInsert: boolean;

	photoUrl: string = defaultEmployeePhotoUrl;

	/** Set to false if you don't want to allow delete even though editing an existing row. */
	public allowDelete = true;

	/** Set to true if isInsert == true or allowDelete == false. */
	public hideDelete = false;

	@ViewChild('employeeModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	@ViewChild(SpeechSynthesizerComponent) private speechSynthesizerComponent: SpeechSynthesizerComponent;

	@ViewChild(PropertySelectorComponent) private propertySelectorComponent: PropertySelectorComponent;

	@ViewChild(EmployeePhotoComponent) private employeePhotoComponent: EmployeePhotoComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer,
        public userService: UserLoginService,
        public s3Service: S3Service,
        public cache: CacheService,
        private pollyService: PollyService,
    )
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
	}
	
	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): Employee {
		return new Employee();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		this.hideDelete = this.isInsert || !this.allowDelete;
		this.allowDelete = true;
		this.showEmployeePhoto();
	}

	/** Return a deep copy of the given object. */
	clone( row: Employee ): Employee {
		return new Employee().fromDataItem( row.toDataItem() );
	}

	isValid(): boolean {
		let valid = true;
		if ((!this.editedRow.employeeId) || this.editedRow.employeeId.trim() === '') {
			this.errorMessage = 'Employee ID is required, please enter a unique ID.';
			valid = false;
		}

		// Set lower case version of fields used for searching
		this.editedRow.firstNameLower = this.editedRow.firstName ? this.editedRow.firstName.toLowerCase() : null;
		this.editedRow.departmentLower = this.editedRow.department ? this.editedRow.department.toLowerCase() : null;
		this.editedRow.badgeNameLower = this.editedRow.badgeName ? this.editedRow.badgeName.toLowerCase() : null;

		return valid;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete employee ' + this.originalRow.employeeId + ': ' + this.originalRow.firstName + ' ' + this.originalRow.lastName + '?';
	}
    
    takePicture() {
        // Put employee data from screen in cache so we can restore it when we come back.
        // this.cache.editedEmployee = this.editedRow;
		// this.router.navigate(['/securehome/employee-photo/']);
		this.employeePhotoComponent.showModalDialog( this.editedRow )
		.then( row => {
			// console.log( 'Photo dialog: returned: '+JSON.stringify(row,null,2));
			if (row) {
				this.showEmployeePhoto();
				// Thank user for taking their photo.
				let name = this.editedRow.firstName && this.editedRow.firstName.trim().length > 0 ? ' '+this.editedRow.firstName : '';
				let text = 'Hello ' + name + '.  Thank you for taking your employee photo.';
				this.speechSynthesizerComponent.synthesizeSpeech( text )
				.then( mp3 => {
					//console.log('Speech is done.');
				});
			}
		})
		.catch( err => {
			Global.logError('Error taking employee picture.', err );
			alert( 'Sorry, there was a problem taking the employee picture.' );
		});
	}

	private getPropertyName( propertyId: number ) {
		if (typeof propertyId == 'string') {
			propertyId = Number.parseInt( propertyId );
		} else if (typeof propertyId == 'object') {
			propertyId = (<Number>propertyId).valueOf();
		}
		let found = this.cache.getProperty( propertyId );
		// console.log( 'gp '+propertyId+':'+(typeof propertyId)+' returned '+JSON.stringify(found,null,2));
		return found ? found.name : '<property name missing>';
	}

	private hasProperty( propertyId: number ) {
		let found = false;
		if (this.editedRow.propertyIds) {
			for (let i=0; i<this.editedRow.propertyIds.length; i++) { 
				if (this.editedRow.propertyIds[i] === propertyId) {
					found = true;
					break;
				}
			}
		}
		return found;
	}

	private addProperty() {
		let defaultId: Number = new Number( null );
		if (this.cache.getFirstPropertyId() != null) {
			defaultId = new Number( this.cache.getFirstPropertyId() );
		}
		this.handleChildInsert( defaultId, this.editedRow.propertyIds, this.propertySelectorComponent );
	}

	private editProperty( index: number ) {
		this.handleChildEdit( index, this.editedRow.propertyIds, this.propertySelectorComponent );
	}


	private showEmployeePhoto() {
        if (!this.editedRow.photo) {
			// console.log( 'No employee photo found, using default' );
            // Use default picture until employee takes a picture
            this.photoUrl = defaultEmployeePhotoUrl;
        } else {
			// console.log( 'Employee photo found, getting S3 file: ' + this.editedRow.photo );
            this.s3Service.getImage( Global.employeePhotoBucketName, this.editedRow.photo )
            .then( result => {
                this.photoUrl = result;
                // this.hideImage = false;
				this.changeDetectorRef.detectChanges();
            })
            .catch( err => {
                Global.logError('Error getting employee photo.', err );
                alert( 'Sorry, there was a problem displaying the employee photo.' );
            });
		}
	}

}