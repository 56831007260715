import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
/**
 * An address including a name and phone number.  Ie. a shipping address.
 */
export class Address extends TableDocumentObject {

	/**
	 * An address including a name and phone number.  Ie. a shipping address.
	 */
	constructor(
		public name: string = null,
		public addressLine1: string = null,
		public addressLine2: string = null,
		public addressLine3: string = null,
		public city: string = null,
		public stateOrRegion: string = null,
		public postalCode: string = null,
		public countryCode: string = null,
		public phone: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Address {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

}
