import * as momentTimezone from 'moment-timezone';
import { CloudWatchLogger } from './CloudWatchLogger';

export class BrowserInfo {
	constructor(
		public browserName: string,
		public fullVersion: string,
		public majorVersion: number,
		public appName: string,
		public userAgent: string,
		public appVersion: string,
		public operatingSystem: string,
	) {}
}

/**
 * Global constants and variables and utility functions.
 */
export class Global {

	/** AWS region we are using. */
	public static region = 'us-east-1';
	
	/** ID of AWS identity pool we are using to authenticate users in the portal web app. */
	public static identityPoolId = 'us-east-1:77972683-0f65-4ab5-af42-e9e652ef1b7d';

	/** ID of AWS user pool we are using to authenticate users in the portal web app. */
	public static userPoolId = 'us-east-1_7RXzNzlHD';

	/** ID of AWS cognito app we are using to authenticate users in the portal web app. */
	public static clientId = '15n49sm2pr69ium6spcq6ejcn6';
	
	/** The ID of the Alexa Room Genie skill client in Cognito "Portal Users" user pool. */
	public static roomGenieAppCognitoClientId = '761lambfm96dm01ab2hjsn4uq0';

	/** Application ID from the Alexa Skill builder page in developer.amazon.com */
	public static roomGenieSkillId = 'amzn1.ask.skill.3d657285-16a4-4f82-aecf-54ff2e6e4c90';

	// ID for Room Genie Private skill in amazon account cneimeister@gmail.com
	public static roomGeniePrivateSkillId = 'amzn1.ask.skill.15f74051-9116-4aa9-b7e9-8b452670aba3';

	// ID for Room Genie for A4H skill in amazon account cneimeister@bookcliffsoftware.com
	public static roomGenieA4HSkillId = 'amzn1.ask.skill.4f011f6f-8fee-45de-b6bb-e9f782e4ead4'
	
	// BeyondTV skill id
	public static beyondTvSkillId = 'amzn1.ask.skill.0dbfb293-266c-4b68-8e8d-75871ba4537c';

	// BeyondTV Test skill id
	public static beyondTvTestSkillId = 'amzn1.ask.skill.c61e261b-8842-404a-8d82-c6d0af009ca0';

	/** Application ID from the Alexa Skill builder page in developer.amazon.com */
	public static roomGenieAlexaEmployeeSkillAppId = 'amzn1.ask.skill.3d657285-16a4-4f82-aecf-54ff2e6e4c90';

	/** S3 bucket where blog articles and images are stored. */
	public static articlesBucketName = 'bookcliffsoftware-articles';
	
	/** S3 bucket where audio files played in response to requesting info from Alexa are stored. */
	public static topicAudioBucketName = 'bookcliffsoftware-audio';
	
	/** S3 bucket where employee photos are permanently stored so we can show them in editor */
	public static employeePhotoBucketName = 'bookcliffsoftware-employee-photo';
	
	/** S3 bucket where visit photos are stored */
	public static visitPhotoBucketName = 'bookcliffsoftware-guest-photo';

	/** S3 bucket where timeclock photos are stored */
	public static timeclockPhotoBucketName = 'bookcliffsoftware-timeclock';

	/** S3 bucket where photos taken by users of the Recognize app are stored (object key = userId/uuid) */
	public static recognizePhotoBucketName = 'bookcliffsoftware-recognize';
	
	/** SNS ARN of the topic where subscription notifications should be sent. */
	public static subscribeTopicArn = 'arn:aws:sns:us-east-1:260182300212:subscribe';

	/** True if code is running in a browser, false if running on a server (like Lambdas). */
	public static isRunningInBrowser = false;

	/** Name of log group where all events are logged. */
	public static logGroupName = 'bookcliffsoftware.com/portal';

	public static logWriter1 = 'AKIAI3N52QWUTJYCBO7Q';

	public static logWriter2 = 'HGthSmemp363PnvFgCMjUgu39hvYr2PKQG9v8Lcq';

	//** Array of weekday names starting with Sunday. */
	public static daysNames = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];

	//** Array of month names starting with January */
	public static monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

	/** Number of milliseconds in one second. */
	public static millisInOneSecond = 1000;

	/** Number of milliseconds in one minute. */
	public static millisInOneMinute = 60 * Global.millisInOneSecond;

	/** Number of milliseconds in one hour. */
	public static millisInOneHour = 60 * Global.millisInOneMinute;

	/** Number of milliseconds in one day. */
	public static millisInOneDay = 24 * Global.millisInOneHour;

	/** Number of milliseconds in one week. */
	public static millisInOneWeek = 7 * Global.millisInOneDay;

	/** 
	 * Number of miles away an advertiser can be and still be included in the What's Happening advertising
	 * for a city or venue.
	 */
	public static readonly AD_RADIUS_MILES = 5;

	/** Timer used to keep resize handler from firing too frequently. */
	private static resizeTimeout: NodeJS.Timer;

		/** 
	 * List of remote loggers that have been used.
	 * Remote loggers may be set to a CloudWatchLogger object when CloudWatch logging is desired.
	 * Different remote loggers may use different log group or stream names.
	 */
	private static remoteLoggers: CloudWatchLogger[] = [];

	/**
	 * Send log events to the given CloudWatch log stream.  Null means don't sent log events to CloudWatch.
	 * @param remoteLogger CloudWatchLogger object that can send log statements to CloudWatch.
	 */
	public static setRemoteLogger( logStreamName: string ) {
		if (logStreamName) {
			this.remoteLoggers.push( new CloudWatchLogger( Global.logGroupName, logStreamName ) );
		} else {
			this.remoteLoggers.push( null );
		}
	}

	/**
	 * Log the given error message.
	 * @param message Message to log.
	 */
	public static log( message: string ): string {
		let remoteLogger: CloudWatchLogger = null;
		if (this.remoteLoggers.length > 0) {
			remoteLogger = this.remoteLoggers[this.remoteLoggers.length-1];
		}
		if (Global.isRunningInBrowser || remoteLogger == null) {
			console.log( message );
		}
		if (remoteLogger != null) {
			remoteLogger.log( message )
			.catch( err => {
				let error = 'Error sending message to remote log.';
				if (err.code && err.message) {
					error += ' ' + err.code + ': ' + err.message;
				} else {
					error += ' ' + JSON.stringify( err );
				}
				console.log( error );
			})
		}
		return message;
	}

	/**
	 * Log a message and an error message and stack trace.
	 * @param message Message to log.
	 * @param error Error that occured.
	 * @return Combination of message and error message that was logged.
	 */
	public static logError( message: string, error: any ): string {
		let msg = Global.buildErrorMessage( message, error );
		Global.log( msg );
		return msg;
	}

	/**
	 * Wait for all log events to finish being sent to CloudWatch before returning from a lambda function.
	 * DO NOT CALL EXCEPT FROM LAMBDAS WHICH TIMEOUT BECAUSE THIS FUNCTION MAY BLOCK FOREVER!!!
	 */
	public static waitForAllLoggingToFinish( clearRemoteLoggers = false ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			Global.setRemoteLogger( null );
			if (Global.isAllLoggingFinished()) {
				if (clearRemoteLoggers) {
					Global.remoteLoggers = [];
				}
				resolve();
			} else {
				setTimeout( () => {
					Global.waitForAllLoggingToFinish( clearRemoteLoggers )
					.then( () => resolve() )
					.catch( error => {
						console.log( Global.buildErrorMessage( 'waitForAllLoggingToFinish error', error ) );
						// this.logEvents.forEach( message => console.log( message ) );
						resolve();
					})
				}, 250);
			}
		});
	}

	private static isAllLoggingFinished() {
		let finished = true;
		for (let i=0; i<Global.remoteLoggers.length; i++) {
			if (Global.remoteLoggers[i] && !Global.remoteLoggers[i].isAllLoggingFinished()) {
				finished = false;
				break;
			}
		}
		return finished;
	}

	
	/**
	 * Returns the name of the collection of employee faces for a company.
	 * @param companyId ID of the company.
	 */
	public static getEmployeeFaceCollectionName( companyId: number ) {
		return companyId + '.employees';
	}

	/** 
	 * Returns face collection name to search for recognized faces.
	 * In the Recognize app, each user has his own collection of known people so the collection name
	 * contains the user's ID.
	 */
	public static getRecognizeFaceCollectionName( userId: string ) {
		return 'user-'+userId;
	}

	/**
	 * Adjusts the given date by the given time zone offset.
	 * This is useful for determining what the date is at the site for any given date/time or for
	 * figuring out what day part it is at the site.
	 * 
	 * For example, the date 2017-08-29T22:10:15.680Z when Denver's DST time zone offset of -06:00
	 * is added becomes 2017-08-29T16:10:15.680Z.
	 * 
	 * @param date Date to convert.
	 * @param timeZone Time zone to adjust to.
	 * @param toLocalTimeZone True if adjusting values from UTC to local time zone.
	 * @return Data adjusted by the given time zone offset.
	 */
	public static adjustDateByTimeZone( date: Date, timeZone: string, toLocalTimeZone = true ): Date {
		let timeZoneOffsetMinutes = Global.getTimeZoneOffsetMinutes( timeZone, date );
		return Global.adjustDateByTimeZoneOffset( date, timeZoneOffsetMinutes, toLocalTimeZone );
	}

	/**
	 * Adjusts the given date by the given time zone offset.
	 * This is useful for determining what the date is at the site for any given date/time or for
	 * figuring out what day part it is at the site.
	 * 
	 * For example, the date 2017-08-29T22:10:15.680Z when Denver's DST time zone offset of -06:00
	 * is added becomes 2017-08-29T16:10:15.680Z.
	 * 
	 * @param date Date to convert.
	 * @param timeZoneOffsetMinutes Number of minutes in the time zone offset to adjust.
	 * @param toLocalTimeZone True if adjusting values from UTC to local time zone.
	 * @return Data adjusted by the given time zone offset.
	 */
	public static adjustDateByTimeZoneOffset( date: Date, timeZoneOffsetMinutes: number, toLocalTimeZone = true ): Date {
		let adjustedDate: Date = date;
		if (!timeZoneOffsetMinutes) {
			timeZoneOffsetMinutes = 0;
		}
		if (date && timeZoneOffsetMinutes != 0) {
			let siteTimeZoneOffsetMillis = timeZoneOffsetMinutes * 60000;
			if (toLocalTimeZone) {
				// Adjust values from UTC to local time zone
				adjustedDate = new Date( date.getTime() - siteTimeZoneOffsetMillis );
			} else {
				// Adjust values from local time zone to UTC
				adjustedDate = new Date( date.getTime() + siteTimeZoneOffsetMillis );
			}
		}
		return adjustedDate;
	}

	/**
	 * Get the current date in the desired time zone.  All the time components will be set to 0.
	 * @param timeZone Desired time zone.
	 */
	public static getCurrentDateOnly( timeZone: string ): Date {
		return this.getDateOnly( new Date(), timeZone );
	}

	/**
	 * Get the given date in the given time zone with all the time components set to 0.
	 * @param timeZone Desired time zone.
	 */
	public static getDateOnly( date: Date, timeZone: string ): Date {
		let result = null;
		if (date) {
			result = new Date( date.getTime() );
			Global.adjustDateByTimeZone( result, timeZone );
			result.setUTCHours( 0, 0, 0, 0 );
		}
		return result;
	}

	/**
	 * Make content area fill the viewport height if it doesn't already and adjust it when viewport is resized.
	 */
	public static addContentAreaResizeListener() {
		Global.setContentAreaHeightToFillViewport();
		window.addEventListener("resize", () => {
			// console.log( 'in resizeThrottler' );
			// ignore resize events as long as an actualResizeHandler execution is in the queue
			if ( !Global.resizeTimeout ) {
				// Clear the old height setting so the resized content area can be smaller if necessary
				document.getElementById( 'myContentArea' ).style.height = null;
				Global.resizeTimeout = setTimeout( () => {
					Global.resizeTimeout = null;
					// console.log( 'handling resize event' );
					Global.setContentAreaHeightToFillViewport();
			   }, 100 ); // The actualResizeHandler will execute at a rate of 10fps
			}
		  }, false);
	}

	/**
	 * Set the content area height to fill the viewport so the background color fills the screen
	 */
	public static setContentAreaHeightToFillViewport() {
		// console.log('ngAfterViewInit window.screen: '+window.screen.width+'x'+window.screen.height );
		// console.log('ngAfterViewInit window.inner: '+window.innerWidth+'x'+window.innerHeight );
		let bodyRect = document.body.getBoundingClientRect();
		let navRect = document.getElementById( 'nav-wrapper' ).getBoundingClientRect();
		let contentAreaHeight = bodyRect.height - navRect.height;
		// console.log( 'contentAreaHeight='+contentAreaHeight+', bodyRect.height='+bodyRect.height+', navRect.height='+navRect.height );
		let myContentArea = document.getElementById( 'myContentArea' );
		// console.log('ngAfterViewInit myContentArea.clientHeight='+myContentArea.clientHeight+ ', contentAreaHeight='+contentAreaHeight );
		if (myContentArea.clientHeight < contentAreaHeight) {
			// console.log( 'setting height to ' + Math.floor( contentAreaHeight ) +'px' );
			myContentArea.style.height = Math.floor( contentAreaHeight ) +'px';
		}
		// console.log('ngAfterViewInit myContentArea2: '+myContentArea.clientHeight );
	}

	public static getBrowserInfo() {
		var nAgt = navigator.userAgent;
		var browserName = navigator.appName;
		var fullVersion = '' + parseFloat(navigator.appVersion);
		var majorVersion = parseInt(navigator.appVersion, 10);
		var nameOffset, verOffset, ix;

		// In Opera, the true version is after "Opera" or after "Version"
		if ((verOffset = nAgt.indexOf("Opera")) != -1) {
			browserName = "Opera";
			fullVersion = nAgt.substring(verOffset + 6);
			if ((verOffset = nAgt.indexOf("Version")) != -1)
				fullVersion = nAgt.substring(verOffset + 8);
		}
		// In MSIE, the true version is after "MSIE" in userAgent
		else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
			browserName = "Microsoft Internet Explorer";
			fullVersion = nAgt.substring(verOffset + 5);
		}
		// In Chrome, the true version is after "Chrome" 
		else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
			browserName = "Chrome";
			fullVersion = nAgt.substring(verOffset + 7);
		}
		// In Safari, the true version is after "Safari" or after "Version" 
		else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
			browserName = "Safari";
			fullVersion = nAgt.substring(verOffset + 7);
			if ((verOffset = nAgt.indexOf("Version")) != -1)
				fullVersion = nAgt.substring(verOffset + 8);
		}
		// In Firefox, the true version is after "Firefox" 
		else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
			browserName = "Firefox";
			fullVersion = nAgt.substring(verOffset + 8);
		}
		// In most other browsers, "name/version" is at the end of userAgent 
		else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
			(verOffset = nAgt.lastIndexOf('/'))) {
			browserName = nAgt.substring(nameOffset, verOffset);
			fullVersion = nAgt.substring(verOffset + 1);
			if (browserName.toLowerCase() == browserName.toUpperCase()) {
				browserName = navigator.appName;
			}
		}
		// trim the fullVersion string at semicolon/space if present
		if ((ix = fullVersion.indexOf(";")) != -1)
			fullVersion = fullVersion.substring(0, ix);
		if ((ix = fullVersion.indexOf(" ")) != -1)
			fullVersion = fullVersion.substring(0, ix);

		majorVersion = parseInt('' + fullVersion, 10);
		if (isNaN(majorVersion)) {
			fullVersion = '' + parseFloat(navigator.appVersion);
			majorVersion = parseInt(navigator.appVersion, 10);
		}

		var appVersion = navigator.appVersion;
		var os = "Unknown OS";
		if (appVersion.indexOf("Win") != -1) os = "Windows";
		else if (appVersion.indexOf("iPad") != -1) os = "iOS";
		else if (appVersion.indexOf("X11") != -1) os = "UNIX";
		else if (appVersion.indexOf("Android") != -1) os = "Android";
		else if (appVersion.indexOf("Linux") != -1) os = "Linux";
		else if (appVersion.indexOf("Macintosh") != -1) os = "MacOS";
		
		let browserInfo = new BrowserInfo(browserName, fullVersion, majorVersion, navigator.appName, navigator.userAgent, appVersion, os );
		// console.log('BrowserInfo: ' + JSON.stringify(browserInfo, null, 2));
		return browserInfo;
	}

	/**
	 * Converts numeric value to string and adds leading zeroes until the string has at least the given number of digits.
	 * @param value Value to convert.
	 * @param digits Minimum number of digits.
	 */
	public static getDigitString( value: number, digits: number ) {
		let s = value.toString();
		while (s.length < digits) {
			s = '0'+s;
		}
		return s;
	}

	/**
	 * Returns date in ISO 8601 format with time zone designator.  E.g. 2011-10-05T14:48:00.000-06:00
	 * @param date 
	 */
	public static getISOStringWithTimeZoneDesignator( date: Date ) {
		let year = this.getDigitString( date.getFullYear(), 4 );
		let month = date.getMonth() + 1;
		let millis = this.getDigitString( date.getMilliseconds(), 3 );
		let offset = date.getTimezoneOffset();
		let timeZoneSign = offset < 0 ? '-' : '+';
		offset = Math.abs( offset );
		let timzoneHours = Math.floor( offset / 60 );
		let timzoneMinutes = offset % 60;
		let timestamp = year
			+ '-' + Global.getDigitString( month, 2 )
			+ '-' + Global.getDigitString( date.getDate(), 2 )
			+ 'T' + Global.getDigitString( date.getHours(), 2 )
			+ ':' + Global.getDigitString( date.getMinutes(), 2 )
			+ ':' + Global.getDigitString( date.getSeconds(), 2 )
			+ ':' + millis
			+ timeZoneSign + Global.getDigitString( timzoneHours, 2 )
			+ ':' + Global.getDigitString( timzoneMinutes, 2 );
		return timestamp;
	}

	/**
	 * Returns the path and name of a guest photo to be stored on S3.
	 * The path is companyId/siteId/cameraId/
	 * The file name includes the cameraId + the current date/time as an ISO string + '-TZO' + time zone offset + '.jpg'
	 * E.g: 1/2/1/1-2011-10-05T14:48:00.000Z-TZO-600.jpg.
	 * @param cameraId ID of the camera that captured image.
	 */
	public static getGuestImageName( companyId: number, propertyId: number, siteId: number, cameraId: number ) {
		// return this.cacheService.currentCompany.companyId+'/'+this.cacheService.currentSite.siteId+'/'+cameraId+'/' + this.uuidService.UUID() + '.jpg';
		let now = new Date();
		// Replace colons in date string with dashes so it is a valid windows filename
		let dateString = now.toISOString().replace(/\:/g, '-');
		let filename = cameraId + '-' + dateString + '-TZO' + now.getTimezoneOffset() + '.jpg';
		return companyId + '/' + propertyId + '/' + siteId + '/' + cameraId + '/' + filename;
	}

	public static getCurrencyStringForCents( cents: number, includeCentsForEvenDollarAmount: boolean = true ): string {
		let amountString = '';
		if (cents != null) {
			amountString = cents.toString();
			// Add leading zeroes until there are at least 3 digits
			while (amountString.length < 3) {
				amountString = "0" + amountString;
			}
			let dollarString = amountString.substring( 0, amountString.length - 2 );
			let centString = amountString.substring( amountString.length - 2, amountString.length );

			if (includeCentsForEvenDollarAmount == false && centString == '00') {
				// Remove trailing .00
				amountString = dollarString;
			} else {
				amountString = dollarString + "." + centString;
			}
		}
		return amountString;
	}

	
	/**
	 * Parse the time zone offset from the name of the guest image file from between the 'TZO' and the '.jpg'
	 * @param imageName Name of the guest image file.
	 */
	public static getTimeZoneOffsetMinutesFromGuestImageName( imageName: string ): number {
		let timeZoneOffsetMinutes = 0;
		if (imageName.indexOf('-TZO') > -1) {
			// Get time zone offset of site that sent the image from the image file name
			let timeZoneOffsetStart = imageName.indexOf('-TZO') + 4;
			let timeZoneOffsetEnd = imageName.indexOf('.', timeZoneOffsetStart );
			let timeZoneOffsetMinutesString = imageName.substring( timeZoneOffsetStart, timeZoneOffsetEnd );
			timeZoneOffsetMinutes = Number.parseInt( timeZoneOffsetMinutesString );
		}
		return timeZoneOffsetMinutes;
	}

	/** Returns time in the time zone with the given offset as h:mm am */
	public static getHM12TimeString( date: Date, timeZone: string ) {
		let value = '';
		if (date) {
			let siteTime = Global.adjustDateByTimeZone( date, timeZone );
			let ampm = 'am';
			let hours = siteTime.getUTCHours();
			if (hours > 11) {
				ampm = 'pm'
			}
			if (hours == 0) {
				hours = 12;
			} else if (hours > 12) {
				hours -= 12;
			}
			let minutes = Global.getDigitString( siteTime.getUTCMinutes(), 2 );
			value = hours + ':' + minutes + ' ' + ampm;
		}
		return value;
	}

	public static getMDHM12TimeString( date: Date, timeZone: string ) {
		let value = '';
		if (date) {
			let siteTime = Global.adjustDateByTimeZone( date, timeZone );
			let ampm = 'am';
			let hours = siteTime.getUTCHours();
			if (hours > 11) {
				ampm = 'pm'
			}
			if (hours == 0) {
				hours = 12;
			} else if (hours > 12) {
				hours -= 12;
			}
			let minutes = Global.getDigitString( siteTime.getUTCMinutes(), 2 );
			value = (siteTime.getUTCMonth()+1) + '/' + siteTime.getUTCDate() + ' ' + hours + ':' + minutes + ampm;
		}
		return value;
	}

	public static getDateTime12String( date: Date, timeZone: string ) {
		let value = '';
		if (date) {
			let siteTime = Global.adjustDateByTimeZone( date, timeZone );
			var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
			value = siteTime.toLocaleString( undefined, options );
		}
		return value;
	}

	public static getDateString( date: Date, timeZone: string ) {
		// console.log( 'getDateString('+date+', '+timeZone);
		let value = '';
		if (date) {
			date = Global.adjustDateByTimeZone( date, timeZone );
			value = date.toLocaleDateString();
		}
		return value;
	}

	public static getDayOfWeek( date: Date, timeZone: string ): number {
		if (!date) {
			throw new Error('Invalid null date value.');
		}
		let localTime = Global.adjustDateByTimeZone( date, timeZone );
		return localTime.getUTCDay();
	}

	public static getDateOnlyMilliseconds( date: Date ) {
		let millis = date.getTime();
		return millis - (millis % Global.millisInOneDay);
	}

	public static getTimeOnlyMilliseconds( date: Date ) {
		return date.getTime() % Global.millisInOneDay;
	}

	/**
	 * Returns an string with hours and minutes in 24-hour format (ie. 13:45) in the UTC time zone for the given date.
	 * @param date Date used.
	 */
	public static getUTCHourMinuteString( date: Date ): string {
		return date.toISOString().substr( 11, 5 );
	}

	/**
	 * Returns an string with hours and minutes in 24-hour format (ie. 13:45) in the time zone with the given offset for the given date.
	 * @param date Date used.
	 */
	public static getHourMinuteString( date: Date, timeZone: string ): string {
		return Global.adjustDateByTimeZone( date, timeZone ).toISOString().substr( 11, 5 );
	}

	/**
	 * Returns the date portion of the ISO date string (ie. 2017-12-25) in the UTC time zone for the given date.
	 * @param date Date used.
	 */
	public static getUTCDateString( date: Date ): string {
		return date ? date.toISOString().substr( 0, 10 ) : '';
	}

	/** Returns the date string for the given time in the format yyyy-mm-dd or null if the given time is null. */
	public static getLocalDateString( localTime: Date ): string {
		let dateString = null;
		if (localTime) {
			dateString = localTime.getFullYear() + '-' + Global.getDigitString( localTime.getMonth()+1, 2 ) + '-' + Global.getDigitString( localTime.getDate(), 2 );
		}
		// console.log( 'Global.getLocalDateString( '+(localTime ? localTime.toISOString() : null) + ') returns '+dateString );
		return dateString;	
	}

	public static parseLocalDateString( localDateString: string ): Date {
		let date: Date = null;
		if (localDateString && localDateString.length > 0) {
			date = new Date( Date.parse( localDateString ) );
			date = new Date( date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() );
		}
		return date;
	}

	/**
	 * Return compact string with UTC date/time in the format yyymmddhhmmssSSS.
	 * @param date Date to convert to string.
	 */
	public static getUTCDateTimeCompactString( date: Date ): string {
		return date.getUTCFullYear().toString()
			+ Global.getDigitString( date.getUTCMonth() + 1, 2 )
			+ Global.getDigitString( date.getUTCDate(), 2 )
			+ Global.getDigitString( date.getUTCHours(), 2 )
			+ Global.getDigitString( date.getUTCMinutes(), 2 )
			+ Global.getDigitString( date.getUTCSeconds(), 2 )
			+ Global.getDigitString( date.getUTCMilliseconds(), 3 );
	}

	/**
	 * Calculate speech string so Alexa says time in 12-hour format like 2:30pm.
	 * @param time Time in 24-hour format hh:mm
	 */
	public static get12HourTimeSpeech( time: string ) {
		let speech = time;
		if (time.indexOf(':')) {
			let timeParts = time.split(':');
			let ampm = 'am';
			let hours = Number.parseInt( timeParts[0] );
			let minutes = Number.parseInt( timeParts[1] );
			if (hours > 11) {
				ampm = 'pm';
			}
			if (hours == 0) {
				hours = 12;
			} else if (hours > 12) {
				hours = hours-12;
			}
			speech = hours + ' ' + (minutes > 0 ? minutes + ' ' : '') + ampm;
		}
		// console.log( 'get12HourTimeSpeech( '+time+' ) returned: '+speech);
		return speech;
	}

	public static getLocal12HourTimeSpeech( date: Date, timeZone: string ) {
		return Global.get12HourTimeSpeech( Global.getHourMinuteString( date, timeZone ) );
	}

	/**
	 * Calculate speech string from date so Alexa says "{dayOfWeek} {month} {day}"
	 * @param day Date
	 */
	public static getDayOfWeekMonthDaySpeech( day: string ) {
		let speech = day;
		if (day.indexOf('-')) {
			let date = new Date( day );
			speech = Global.daysNames[ date.getDay() ];
			speech += ' ' + Global.monthNames[ date.getMonth() ];
			// speech += ' <say-as interpret-as="ordinal">' + date.getDate() + '</say-as>';
			speech += ' ' + Global.nth( date.getDate() );
			// speech = daysOfWeek[ date.getDay() ] + ' <say-as interpret-as="date" format="md">' + day + '</say-as>';
		}
		return speech;
	}

	public static isToday( date: Date, timeZone: string ): boolean {
		let result = true;
		if (date != null) {
			let today = Global.getCurrentDateOnly( timeZone );
			let dateOnly = Global.getDateOnly( date, timeZone );
			if (today.getTime() != dateOnly.getTime()) {
				result = false;
			}
		}
		return result;
	}

	public static isTomorrow( date: Date, timeZone: string ): boolean {
		let result = true;
		if (date != null) {
			let today = Global.getCurrentDateOnly( timeZone );
			let dateOnly = Global.getDateOnly( date, timeZone );
			if (dateOnly.getTime() != (today.getTime()+Global.millisInOneDay)) {
				result = false;
			}
		}
		return result;
	}

	/**
	 * Return the date represented by the string (ISO format).  If the string is null or blank return null.
	 * @param s String to convert.
	 */
	public static getDateFromString( s: string ): Date {
		return s && s.length > 0 ? new Date( Date.parse( s ) ) : null; 
	}

	/**
	 * Return the integer represented by the string.  If the string is null or blank return null.
	 * @param s String to convert.
	 */
	public static getIntegerFromString( s: string ): number {
		return s && s.length > 0 ? Number.parseInt( s ) : null; 
	}

	/**
	 * Get the next billing date after the previous billing date on the given day of the month
	 * If the next month doesn't have the day of month then use the last day of the month.
	 * 
	 * @param previousBillingDate Previous billing date or the current date if no billing has happened.
	 * @param billingDayOfMonth The day of each month when the customer will be billed.
	 */
	public static getNextBillingDate( previousBillingDate: Date, billingDayOfMonth: number ): Date {
		let year = previousBillingDate.getUTCFullYear();
		let month = previousBillingDate.getUTCMonth() + 1;
		if (month > 11) {
			year++;
			month=0;
		}
		let day = billingDayOfMonth;
		let billingDate = new Date( year, month, day );
		while (billingDate.getUTCMonth() != month) {
			// There are fewer days next month than the billing date so back up will we reach the last day of next month.
			day--;
			billingDate = new Date( year, month, day );
		}
		return billingDate;
	}

	/** Get the ordinal value of the given number. */
	public static nth(d: number): string {
		if(d>3 && d<21) return d+'th'; // thanks kennebec
		switch (d % 10) {
			case 1:  return d+"st";
			case 2:  return d+"nd";
			case 3:  return d+"rd";
			default: return d+"th";
		}
	} 
	  
	/**
	 * Get the number of minutes of offset between the given time zone and UTC on the given date/time
	 * @param timeZone Timezone for which we want the offset.
	 * @param date Date/time for which we want the offset (it changes depending on daylight savings time).
	 */
	public static getTimeZoneOffsetMinutes( timeZone: string, date: Date = new Date() ): number {
		let timeZoneOffsetMinutes = 0;
		if (timeZone) {
			timeZoneOffsetMinutes = momentTimezone.tz.zone( timeZone ).utcOffset( date );
			// console.log( 'Got time zone offset minutes '+timeZoneOffsetMinutes+' from time zone: '+timeZone );
		}
		return timeZoneOffsetMinutes;
	}

	private static timeZones: string[] = null;

	/** Return list of time zone names. */
	public static getTimeZoneNames(): string[] {
		if (Global.timeZones == null) {
			Global.timeZones = momentTimezone.tz.names();
		}
		return Global.timeZones;
	}

	/** Returns what the browser reports to be the current time zone. */
	public static getTimeZone(): string {
		return momentTimezone.tz.guess();
	}

	/**
	 * Returns equivalent Zulu time at the given time zone for the given local time.
	 * For example, midnight in Denver is 6am Zulu but midnight in New York is 4am Zulu
	 * so if you are using a browser in Denver and pass in 6am Zulu and the time zone US/Eastern,
	 * this function will return the date with the time adjusted to 4am Zulu.
	 * @param timeZone Destination time zone (local time zone will be determined by browser)
	 * @param localTime Local time to convert to the given time zone.
	 */
	public static getTimeAtTimeZone( timeZone: string, localTime: Date = new Date() ) {
		let localTimeZoneOffsetMinutes = Global.getTimeZoneOffsetMinutes( Global.getTimeZone(), localTime );
		let destinationTimeZoneOffsetMinutes = Global.getTimeZoneOffsetMinutes( timeZone, localTime );
		let timeAtTimeZone = Global.adjustDateByTimeZoneOffset( localTime, localTimeZoneOffsetMinutes - destinationTimeZoneOffsetMinutes, true );
		console.log('localTime='+localTime.toISOString()+', timeZone='+timeZone+', localTimeZoneOffsetMinutes='+localTimeZoneOffsetMinutes+', destinationTimeZoneOffsetMinutes='+destinationTimeZoneOffsetMinutes+', timeAtTimeZone='+timeAtTimeZone.toISOString())
		return timeAtTimeZone;
	}

	/**
	 * Sets the time portion of dateToAdjust to the same time of day as the given date taking
	 * into account possible changes in daylight savings time between the two dates.
	 * Ie. If the base date is January 1 at 3pm (8pm UTC), set the date of May 1st to 3pm (7pm UTC). 
	 * @param date Date to set time.
	 * @returns Date with given date part and seating start time part.
	 */
	public static setTimeToMatch( date: Date, dateToAdjust: Date, timeZone: string ): Date {
		// Calculate reservation list start date/time from given date and seating start time
		let newDate = new Date( dateToAdjust.getTime() );
		newDate.setUTCHours( date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds() );
		// Adjust new date by any different in daylight savings time
		let daylightSavingsTimeChange = Global.getTimeZoneOffsetMinutes( timeZone, newDate ) - Global.getTimeZoneOffsetMinutes( timeZone, date );
		if (daylightSavingsTimeChange != 0) {
			newDate = new Date( newDate.getTime() + (daylightSavingsTimeChange * Global.millisInOneMinute) );
		}
		// console.log( 'Global.setTimeToMatch( date='+date.toISOString()+', dateToAdjust='+dateToAdjust.toISOString()+' ) returns '+newDate.toISOString()+', DST change='+daylightSavingsTimeChange );
		return newDate;
	}

	/**
	 * Build an error message string from a message and an error message and stack trace.
	 * @param message Message to log.
	 * @param error Error that occured.
	 */
	public static buildErrorMessage( message: string, error: any ): string {
		let errorMessage = '';
		if (!message) {
			message = '';
		}
		if (!error) {
			errorMessage = message;
		} else if (error.code ) {
			// Show error code and message (used in AWSError objects)
			errorMessage = message + ': error code ' + error.code + ': ' + error.message + '\n' + error.stack;
		} else if (error instanceof Error) {
			errorMessage = message + ': ' + error.message + '\n' + error.stack;
		} else {
			// This isn't an Error object so we can't log the stack.
			errorMessage = message + ': ' + JSON.stringify( error, null, 2 );
		}
		return errorMessage;
	}

	/**
	 * Build an error object from a message and an error message and stack trace.
	 * @param message Message to log.
	 * @param error Error that occured.
	 */
	public static buildError( message: string, error: any ): Error {
		return new Error( Global.buildErrorMessage( message, error ) );
	}

	public static removeEverythingButDigitsFromString( s: string ): string {
		let newString = null;
		if (s) {
			newString = '';
			for (let i=0; i<s.length; i++) {
				if ('0123456789'.indexOf( s.charAt(i) ) !== -1) {
					newString += s.charAt(i);
				}
			}
		}
		return newString;
	}

	/**
	 * Calculate distance in miles between two points expressed as latitude and longitude degrees
	 * like you can get from Google Maps by right clicking a point and selecting "What's here?"
	 * Uses the Haversine formula.
	 * @param lat1 Latitude of first point
	 * @param lon1 Longitude of first point
	 * @param lat2 Latitude of second point
	 * @param lon2 Longitude of second point
	 */
	public static getMilesBetweenCoordinates(lat1,lon1,lat2,lon2) {
		var R = 3959; // Radius of the earth in miles (use 6371 if you want distance in kilometers)
		var dLat = Global.deg2rad(lat2-lat1);  // deg2rad below
		var dLon = Global.deg2rad(lon2-lon1); 
		var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(Global.deg2rad(lat1)) * Math.cos(Global.deg2rad(lat2)) * 
			Math.sin(dLon/2) * Math.sin(dLon/2); 
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		var d = R * c; // Distance in miles
		return d;
	}
	
	public static deg2rad(deg) {
		return deg * (Math.PI/180)
	}

	/**
	 * Calculate the miles in one degree of longitude at the sites latitude since the distance
	 * between longitude line varies from 69 miles at the equator to 0 at the poles.
	 * 
	 * @param latitude The latitude at which the number of miles per degree of longitude is to be calculated.
	 */
	public static getMilesPerDegreeOfLatitude(): number {
		return 69;
	}

	/**
	 * Calculate the miles in one degree of longitude at the sites latitude since the distance
	 * between longitude line varies from 69 miles at the equator to 0 at the poles.
	 * 
	 * @param latitude The latitude at which the number of miles per degree of longitude is to be calculated.
	 */
	public static getMilesPerDegreeOfLongitude( latitude: number ): number {
		return Global.getMilesBetweenCoordinates( latitude, 1, latitude, 2 );
	}

	/**
	 * Set the companyId and propertyId properties of the given objects.
	 * @param objects List of objects for which ID's will be set.
	 * @param companyId Company ID to set.
	 * @param propertyId Property ID to set.
	 */
	public static setCompanyAndPropertyIds( objects: any[], companyId: number, propertyId: number ) {
		if (objects) {
			objects.forEach( object => {
				object["companyId"] = companyId;
				object["propertyId"] = propertyId;
			});
		}
	}

	/**
	 * Returns the index of the string in the array (case insensitive) or -1 if not found.
	 * @param sought String to search for
	 * @param array Array of strings to search
	 * @returns Index of the string in the array (case insensitive) or -1 if not found.
	 */
	public static findStringInArrayCaseInsensitive( sought: string, array: string[] ): number {
		let found = -1;
		if (sought != null && array != null && array.length > 0) {
			let soughtLowerCase = sought.toLowerCase();
			for (let j=0; j<array.length; j++) {
				if (array[j] != null && array[j].toLowerCase() === soughtLowerCase) {
					found = j;
					break;
				}
			}

		}
		return found;
	}

	/**
	 * @returns String with the address1, city, state, and postalCode.
	 */
	public static getAddressString( address1: string, city: string, state: string, postalCode: string ): string {
		// Get address string for the property
		let address = "";
		if (address1 != null && address1.trim().length > 0) {
			address += address1;
		}
		if (city != null && city.trim().length > 0) {
			if (address.length > 0) {
				address += ', ';
			}
			address += city;
		}
		if (state != null && state.trim().length > 0) {
			if (address.length > 0) {
				address += ', ';
			}
			address += state;
		}
		if (postalCode != null && postalCode.trim().length > 0) {
			if (address.length > 0) {
				address += ' ';
			}
			address += postalCode;
		}
		return address;
	}
	
	/**
	 * Browser compatible way to convert a base 64 string to a buffer of unsigned 8-byte integers
	 * like the node function new Buffer( base64DataString, 'base64' ).
	 * @param base64 String encoded as base 64.
	 */
	public static base64ToArrayBuffer( base64: string ): Uint8Array {
		// let startTime = Date.now();
		let binary_string =  window.atob(base64);
		let len = binary_string.length;
		let bytes = new Uint8Array( len );
		for (var i = 0; i < len; i++)        {
			bytes[i] = binary_string.charCodeAt(i);
		}
		// console.log( 'base64ToArrayBuffer finished in ' + (Date.now()-startTime) + 'ms.' );
		return bytes;
	}

	/**
	 * Get the value of the XML element with the given name.
	 * @param xml XML string
	 * @param elementName Name of the element
	 * @returns Element value
	 */
	public static getXmlElement( xml: string, elementName: string): string {
		let startElement = '<'+elementName+'>';
		let endElement = '</'+elementName+'>';
		let startIndex = xml.indexOf(startElement) + startElement.length;
		let endIndex = xml.indexOf(endElement);
		let element = xml.substring( startIndex, endIndex );
		console.log('-BeyondPay '+elementName+'='+element);
		return element;
	}

}

