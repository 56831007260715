import { Command } from "./Command";
import { RestaurantLayout } from "../service/RestaurantLayout";

export class PutRestaurantLayoutCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: RestaurantLayout ): Promise<RestaurantLayout> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<RestaurantLayout>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new RestaurantLayout().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
