import { Detection, DetectionTable } from './DetectionTable';
import { LineTime, LineTimeSlot, LineTimeTable } from './LineTimeTable';
import { PersonFace, PersonFaceTable } from './PersonFaceTable';
import { Person } from './Person';
import { PersonTable } from './PersonTable';
import { CompanyTable } from './CompanyTable';
import { RekognitionService, MatchingFace } from './rekognition.service';
import { Visit } from './Visit';
import { VisitTable } from './VisitTable';
import { VisitSummary, VisitSummaryTable } from './VisitSummaryTable';
import * as Rekognition from "aws-sdk/clients/rekognition";
import { Global } from './Global';
import { VisitNotificationQueue } from './VisitNotificationQueue';
import { SiteUsage, SiteUsageTable } from './SiteUsageTable';

// The number of milliseconds in two hours (detections within two hours are considered the same visit)
// const twoHoursAsMilliseconds: number = 2 * 60 * 60 * 1000;

/**
 * FaceTracker class provides methods to support detecting faces in images.
 * 
 * Use case: Counter camera detects a face that matches a face detected by the door camera.
 * - Door camera detects a face and stores it in a collection.
 * - System records the face ID, camera ID, and time in the detection table. 
 * - Counter camera detects a face and stores it in the collection.
 * - System records the face ID, camera ID, and time in the detection table.
 * - System finds a match in the collection and, if the matching face is not already
 *   in the match table, saves the two face ID's in the match table.
 *   If the matching face is already in the match table but the new match is much better
 *   than the old match and the times are similar, switch the match to use the new face.
 *   If a face is detected multiple times at a detection point, only record the first one,
 *   within a two-hour window, but use the image with the highest confidence in the face.
 *   Detections more than two hours apart are considered separate visits.
 *   If the system doesn't find a match, then the new face is deleted from the collection
 *   and the number of unmatched counter faces for the time period is incremented.
 * 
 */
export class FaceTracker {
    private detectionTable: DetectionTable;
    private lineTimeTable: LineTimeTable;
    private personFaceTable: PersonFaceTable;
    private personTable: PersonTable;
    private companyTable: CompanyTable;
	private rekognitionService: RekognitionService;
	private visitTable: VisitTable;
	private visitSummaryTable: VisitSummaryTable;

    public constructor() {
        this.rekognitionService = new RekognitionService();
        this.detectionTable = new DetectionTable();
        this.lineTimeTable = new LineTimeTable();
		this.personFaceTable = new PersonFaceTable();
		this.personTable = new PersonTable();
		this.companyTable = new CompanyTable();
		this.visitTable = new VisitTable();
		this.visitSummaryTable = new VisitSummaryTable();
    }

    /**
     * Process an image taken by the camera at the back of the line or near the entrance door.
     * This function assumes a 2-camera system where camera 1 is at the back of the line and camera 2 is at the front.
     * This function assumes that camera images are stored in an S3 bucket with folder structure companyId/siteId/cameraId/imageName.
     * 
     * @param companyId ID of the company the site belongs to.
     * @param siteId ID of the site where the cameras are installed.
     * @param imageName Name of the image in the S3 bucket with folder structure companyId/storeId/cameraId/imageName.
     */
    private recognizePeopleInS3Image( companyId: number, siteId: number, objectKey: string, collectionName: string, newVisitMinutes: number, timeZone: string ): Promise<PersonFace[]> {
        // console.log( 'processBackOfLineImage( companyId='+companyId+', siteId='+siteId+', imageName='+imageName+' )' );
        return new Promise( (resolve, reject) => {
			let personFaces: PersonFace[] = [];
            this.rekognitionService.indexFacesInS3ImageFile( Global.visitPhotoBucketName, objectKey, collectionName, )
            .then( data => {
				console.log('face in image ' + objectKey + ': ' + JSON.stringify( data, null, 2 ) );
                if (data.FaceRecords && data.FaceRecords.length > 0) {
					console.log( 'Face detected by AWS. ' + Math.round( data.FaceRecords[0].Face.BoundingBox.Height * 100 ) + '%, rect='+data.FaceRecords[0].Face.BoundingBox.Left+','+data.FaceRecords[0].Face.BoundingBox.Top+','+data.FaceRecords[0].Face.BoundingBox.Width+','+data.FaceRecords[0].Face.BoundingBox.Height );
					let testFaceDetection = true;
					if (testFaceDetection) {
						console.log('Testing Face Detection' );	
					}
					// Get the visit summary for today so we can update it for each face in the image
					this.getVisitSummaryForToday( companyId, siteId, timeZone )
					.then( visitSummary => {
						// console.log('Got VisitSummary for today.  newCount='+visitSummary.newVisitors.count+', repeatCount='+visitSummary.repeatVisitors.count );
						let promises: Promise<any>[] = [];
						for( let face of data.FaceRecords ) {
							promises.push( new Promise<boolean>( (resolve, reject) => {
								// Find the person with the face or add a new person 
								this.getPersonIdForFaceId( companyId, face.Face.FaceId, objectKey, collectionName, face.Face.Confidence )
								.then( personFace => {
									personFaces.push( personFace );
									this.updateVisitTable( companyId, siteId, personFace, face.FaceDetail, objectKey, visitSummary, timeZone, newVisitMinutes )
									.then( isNewVisit => {
										// console.log( 'updateVisitTable resolved');
										resolve( isNewVisit );
									})
									.catch( err => {
										// console.log( 'updateVisitTable rejected: ' + err.message );
										reject( new Error( 'Error updating visit in table: ' + err.message ) );
									});
								})
								.catch( err => {
									reject( new Error( 'Error finding person for face ID: ' + err.message ) );
								})
							}));
						}
						Promise.all( promises )
						.then( isNewVisitValues => { 
							console.log( 'Promises for processing for all faces in image has resolved');
							// See if any of the faces created a new visit
							let newVisit = (isNewVisitValues.indexOf( true ) != -1);
							// Update visit usage table to show that new/dup visits
							new SiteUsageTable().updateUsage( new SiteUsage( companyId, siteId, Global.getCurrentDateOnly( timeZone ), 1, newVisit? 1 : 0, newVisit ? 0: 1, 0 ) );
					
							// Update the visit summary record that was updated for each face
							this.visitSummaryTable.put( visitSummary )
							.then( () => {
								// console.log('VisitSummaryTable.put succeeded.  newCount='+visitSummary.newVisitors.count+', repeatCount='+visitSummary.repeatVisitors.count );
								resolve( personFaces );
							})
							.catch( err => reject( err ) );
						})
						.catch( reason => {
							reject( new Error( 'Error finding people in image: ' + reason.message ) );
						});
	

					})
					.catch( err => reject( err ) );
						

                } else {
					console.log( 'Promise for processing image with no faces has resolved');
					// Update visit usage table to show that image had no faces
					new SiteUsageTable().updateUsage( new SiteUsage( companyId, siteId, Global.getCurrentDateOnly( timeZone ), 1, 0, 0, 1 ) );
                    resolve( personFaces );
                }
            })
            .catch( reason => {
                reject( new Error( 'Error finding faces in image: ' + reason.message ) );
            });
        });
    }

	/**
	 * Add a new visit or update the visit end time if we detected the same person again during the same visit.
	 * @param companyId ID of the company.
	 * @param siteId ID of the site.
	 * @param personFace Person face record.
	 * @param faceDetail Face detail detected in the image.
	 * @param objectKey S3 object key of the image.
	 * @param visitSummary Visit summary record to update.
	 * @param timeZone Site's time zone.
	 * @param newVisitMinutes Number of minutes between detections to be considered a new visit.
	 * @return True if we added a new visit, false if we detected the same person again during the same visit.
	 */
	private updateVisitTable( companyId: number, siteId: number, personFace: PersonFace, faceDetail: Rekognition.FaceDetail, objectKey: string, visitSummary: VisitSummary, timeZone: string, newVisitMinutes: number ): Promise<boolean> {
    	return new Promise<boolean>( (resolve, reject) => {
			let visit = new Visit( companyId, siteId, personFace.personId, personFace.faceId );
			// Assume that we are not detecting the same person again during the same visit
			let previousVisitEndTime = null;
			if (personFace.faceId != personFace.personId) {
				// We recognize the person, see if we have already recorded a visit for them in this time period.
				this.visitTable.getMostRecentVisit( visit )
				.then( foundVisit => {
					if (foundVisit && (Date.now() - foundVisit.endTime.getTime()) <= (newVisitMinutes * 60000)) {
						// We detected this person again during the same visit, do not update the guest count
						console.log('Detected guest again on same visit. Last detected '+(Date.now() - foundVisit.endTime.getTime())+'ms ago.  Date.now()=' + Date.now() + ' endTime=' + foundVisit.endTime.getTime() );
						visit = foundVisit;
						// Remember the previous visit end time so we know how much to add to the visit length that is already recorded
						previousVisitEndTime = visit.endTime;
						// Update visit end time so we know if future detections are a new visit
						visit.endTime = new Date();
					} else {
						// This is a new visit by a repeat guest, save all the face details form Rekognition along with the visit record
						console.log('Detected repeat guest on new visit. Date.now()=' + Date.now() + ' endTime=' + (foundVisit ? foundVisit.endTime.getTime() : 'not found') );
						this.rekognitionService.setVisitFaceDetails( visit, faceDetail, objectKey );
					}
					this.visitTable.put( visit )
					.then( () => {
						// console.log('VisitTable.put succeeded.' );
						this.updateVisitSummary( visitSummary, visit, previousVisitEndTime, timeZone );
						// console.log( 'updateVisitSummaryTable is resolved.' );
						if (!previousVisitEndTime) {
							// This is a new visit by a repeat guest, send notification to any queues registered for the site
							VisitNotificationQueue.sendNotification( visit );
						}
						resolve( !previousVisitEndTime );
					})
					.catch( err => {
						reject( new Error( 'Error putting visit in table ' + JSON.stringify( visit, null, 2 ) + ':' + err.message ) );
					});
				})
				.catch( err => {
					reject( new Error( 'Error getting most recent visit for ' + JSON.stringify( visit, null, 2 ) + ':' + err.message ) );
				});
			} else {
				// This is a new person, save all the face details form Rekognition along with the visit record
				console.log('Detected new guest.  Add visit.' );
				this.rekognitionService.setVisitFaceDetails( visit, faceDetail, objectKey );
				this.visitTable.put( visit )
				.then( () => {
					// console.log('VisitTable.put 2 succeeded.' );
					this.updateVisitSummary( visitSummary, visit, null, timeZone );
					// console.log( 'updateVisitSummaryTable 2 is resolved.' );
					// This is a new visit by a new guest, send notification to any queues registered for the site
					VisitNotificationQueue.sendNotification( visit );
					resolve( true );
				})
				.catch( err => {
					reject( new Error( 'Error putting visit in table ' + JSON.stringify( visit, null, 2 ) + ':' + err.message ) );
				});
			}
		});
	}

	private getVisitSummaryForToday( companyId: number, siteId: number, timeZone: string ): Promise<VisitSummary> {
    	return new Promise( (resolve, reject) => {
				
			// Get the date in the site's local time zone with the time set to 00:00:00.00
			let dateOnly = Global.adjustDateByTimeZone( new Date(), timeZone );
			dateOnly.setUTCHours( 0, 0, 0, 0 );
			// console.log( 'dateOnly='+dateOnly.toISOString() );
			// Create a new empty visit summary object in case there is not one in the table
			let visitSummary = new VisitSummary( companyId, siteId, dateOnly );
			this.visitSummaryTable.get( visitSummary )
			.then( foundVisitSummary => {
				if (foundVisitSummary) {
					// We found a visit summary for today so use it instead of the new empty one we created.
					visitSummary = foundVisitSummary;
				}
				resolve( visitSummary );
			})
			.catch( err => reject( err ) );
		});
	}

	private updateVisitSummary( visitSummary: VisitSummary, visit: Visit, previousVisitEndTime: Date, timeZone: string ): void {
				
		// Assume this is a new visitor
		let values = visitSummary.newVisitors;
		if (visit.faceId != visit.personId) {
			// This is a repeat visitor
			values = visitSummary.repeatVisitors;
		}

		// Update summary values
		if (previousVisitEndTime) {
			// This visit's values are already included in the summary, we just need to add some
			// time to the visit since we detected the visitor again.
			values.visitMinutes += Math.round( ( visit.endTime.getTime() - previousVisitEndTime.getTime() ) / 60000 );
		} else {
			// This is a visit that is not already included in the summary, add all the values
			values.count++;

			// Calculate the day part of the visit using the site's time zone
			let visitHour = Global.adjustDateByTimeZone( visit.startTime, timeZone ).getUTCHours();
			// console.log( 'visitHour = ' + visitHour );
			values.night += ( visitHour < 6 ? 1 : 0 );
			values.morning += ( visitHour >= 6 && visitHour < 12 ? 1 : 0 );
			values.afternoon += ( visitHour >= 12 && visitHour < 18 ? 1 : 0 );
			values.evening += ( visitHour >= 18 ? 1 : 0 );

			let age = Math.round( ( visit.ageRangeLow + visit.ageRangeHigh ) / 2 );
			values.age0to9 += ( age < 10 ? 1 : 0 );
			values.age10to19 += ( age >= 10 && age < 20 ? 1 : 0 );
			values.age20to29 += ( age >= 20 && age < 30 ? 1 : 0 );
			values.age30to39 += ( age >= 30 && age < 40 ? 1 : 0 );
			values.age40to49 += ( age >= 40 && age < 50 ? 1 : 0 );
			values.age50to59 += ( age >= 50 && age < 60 ? 1 : 0 );
			values.age60to69 += ( age >= 60 && age < 70 ? 1 : 0 );
			values.age70to79 += ( age >= 70 && age < 80 ? 1 : 0 );
			values.ageOver80 += ( age >= 80 ? 1 : 0 );
			let minimumConfidence = 50;
			values.beard += ( visit.beard > minimumConfidence ? 1 : 0 );
			values.happy += ( visit.happy > minimumConfidence ? 1 : 0 );
			values.sad += ( visit.sad > minimumConfidence ? 1 : 0 );
			values.angry += ( visit.angry > minimumConfidence ? 1 : 0 );
			values.confused += ( visit.confused > minimumConfidence ? 1 : 0 );
			values.disgusted += ( visit.disgusted > minimumConfidence ? 1 : 0 );
			values.surprised += ( visit.surprised > minimumConfidence ? 1 : 0 );
			values.calm += ( visit.calm > minimumConfidence ? 1 : 0 );
			values.unknownEmotion += ( visit.unknownEmotion > minimumConfidence ? 1 : 0 );
			values.eyeglasses += ( visit.eyeglasses > minimumConfidence ? 1 : 0 );
			values.male += ( visit.male > minimumConfidence ? 1 : 0 );
			values.female += ( visit.female > minimumConfidence ? 1 : 0 );
			values.mustache += ( visit.mustache > minimumConfidence ? 1 : 0 );
			values.smile += ( visit.smile > minimumConfidence ? 1 : 0 );
			values.sunglasses += ( visit.sunglasses > minimumConfidence ? 1 : 0 );
		}
	}

    /**
     * Process an image taken by the camera at the back of the line or near the entrance door.
     * This function assumes a 2-camera system where camera 1 is at the back of the line and camera 2 is at the front.
     * This function assumes that camera images are stored in an S3 bucket with folder structure companyId/siteId/cameraId/imageName.
     * 
     * @param companyId ID of the company the site belongs to.
	 * @param propertyId ID of the property where the cameras are installed.
     * @param siteId ID of the site where the cameras are installed.
     * @param objectKey Name of the image in the S3 bucket with folder structure companyId/storeId/cameraId/imageName.
	 * @param collectionId ID of collection of guests to use.
     */
    processBackOfLineImage( companyId: number, propertyId: number, siteId: number, objectKey: string, collectionId: string, newVisitMinutes: number, timeZone: string ): Promise<PersonFace[]> {
        // console.log( 'processBackOfLineImage( companyId='+companyId+', siteId='+siteId+', imageName='+imageName+' )' );
        return new Promise( (resolve, reject) => {
            const backOfLineCameraId: number = 1;
            // const frontOfLineCameraId: number = 2;
            // let objectKey = companyId+'/'+siteId+'/'+backOfLineCameraId+'/'+imageName

			this.recognizePeopleInS3Image( companyId, siteId, objectKey, collectionId, newVisitMinutes, timeZone )
            .then( personFaces => {
                // console.log( 'Detected ' + (faces ? faces.length : 0) + ' faces in image ' + imageName + ' from camera at the back of the line.' );
                if (personFaces.length > 0) {
                    // Add a detection record for each face detected
                    let date: Date = new Date();
                    for( let personFace of personFaces ) {
                        this.detectionTable.insertIfNewVisit( companyId, siteId, backOfLineCameraId.toString(), personFace.personId, date );
                    }
                } else {
                    resolve( personFaces );
                }
            })
            .catch( reason => {
                // an error occurred
                reject( new Error( 'Error processing image from camera at back of line: ' + reason.message ) );
            });
        });
    }

    /**
     * Process an image taken by the camera at the front of the line or near the counter where customer orders are taken.
     * This function assumes a 2-camera system where camera 1 is at the back of the line and camera 2 is at the front.
     * This function assumes that camera images are stored in an S3 bucket with folder structure companyId/siteId/cameraId/imageName.
     * 
     * @param companyId ID of the company the site belongs to.
	 * @param propertyId ID of the property where the cameras are installed.
     * @param siteId ID of the site where the cameras are installed.
     * @param objectKey Name of the image in the S3 bucket with folder structure companyId/storeId/cameraId/imageName.
	 * @param collectionId ID of collection of guests to use.
     */
    processFrontOfLineImage( companyId: number, propertyId: number, siteId: number, objectKey: string, collectionId: string, newVisitMinutes: number, timeZone: string ): Promise<string[]> {
        return new Promise( (resolve, reject) => {
            const backOfLineCameraId: number = 1;
            const frontOfLineCameraId: number = 2;
            // Put the image from the camera at the front of the line into the collection where the images
            // from the camera at the back of the line are stored so we can search those images for a matching face.
            // let objectKey = companyId+'/'+siteId+'/'+frontOfLineCameraId+'/'+imageName
            let matchedFaceIds: string[] = [];

			this.recognizePeopleInS3Image( companyId, siteId, objectKey, collectionId, newVisitMinutes, timeZone )
            .then( personFaces => {
                // successful response
                if (personFaces.length > 0) {

					// Record the fact that we detected faces at the front of the line
					let date: Date = new Date();
					let promises: Promise<any>[] = [];
					for( let personFace of personFaces ) {
						promises.push( this.getSecondsInLine( companyId, siteId, backOfLineCameraId, personFace, date, matchedFaceIds, newVisitMinutes ) );
					}
					// When all promises are resolved, resolve this function's promise
					Promise.all( promises )
					.then( values => { 
						//  All the matching faces have been detected
						let secondsInLine: number = 0; // Total number of seconds in line for all faces found at both ends of the line
						values.forEach( value => {
							console.log("Promise.all result: "+JSON.stringify( value ) );                            
							secondsInLine += value;
						});
						this.updateResults( companyId, siteId, matchedFaceIds.length, secondsInLine )
						.then( values => {
							resolve( matchedFaceIds );
						})
						.catch( reason => {
							reject( new Error( 'Error updating results: ' + reason.message ) );
						})
					})
					.catch( reason => {
						reject( new Error( 'Error processing faces detected in front-of-line camera image: ' + reason.message ) );
					});
                } else {
                    // No faces detected in image
                    console.log( 'Detected 0 face(s) in image ' + objectKey );
                    resolve( matchedFaceIds );
                }
            })
            .catch( reason => {
                // console.log( 'Error calling indexFaces: ' + reason );
                reject( new Error( 'Error processing image from camera at front of line: ' + reason ) );
            });
			

        });
    }

	/**
	 * See if the given person's face has been detected on the back line camera during this visit
	 * and, if so, return the number of seconds they were in line.
	 * 
	 * @param companyId Company ID.
	 * @param siteId Site ID.
	 * @param backOfLineCameraId Camera ID.
	 * @param personFace Person face to check.
	 * @param date Date/time person was detected.
	 * @param matchedFaceIds List of face ID's we've found so far that have been seed at both the back and front of the line.
	 * @param newVisitMinutes Number of minutes since we last detected a person before it is considered a new visit.
	 */
	private getSecondsInLine( companyId: number, siteId: number, backOfLineCameraId: number, personFace: PersonFace, date: Date, matchedFaceIds: string[], newVisitMinutes: number ): Promise<number> {
		return new Promise<number>( (resolve, reject) => {
			let newVisitMilliseconds = newVisitMinutes * 60000;
			if (personFace.faceId != personFace.personId) {
				// We found a matching face in the face collection.
				// Get the back-camera face detection record so we know when they got in line
				this.detectionTable.get( new Detection( companyId, siteId, backOfLineCameraId.toString(), personFace.personId ) )
				.then( detection => {
					if (!detection) {
						// We couldn't find a detection record for the face on the back-of-line camera.
						// It probably means someone deleted detection records without deleting faces from collection
						console.log( 'Found matching face ID '+personFace.personId+' but could not find detection record.  No line time recorded.');
						// No back-of-line detection found but Promise was fulfilled.
						resolve( 0 );
					} else if ((Date.now() - detection.date.getTime()) > newVisitMilliseconds) {
						// The face was detected on the back-of-line 
						// camera more than two hours ago so it is not
						// for this visit and we can't record line time.
						console.log( 'Found matching face ID '+personFace.personId+' but last back-of-line detection was from a previous visit. No line time recorded.');
						// Delete the detection record since it's too old to matter, but don't wait for the results
						this.detectionTable.delete( new Detection( companyId, siteId, backOfLineCameraId.toString(), personFace.personId ) );
						// No back-of-line detection found but Promise was fulfilled.
						resolve( 0 );
					} else {
						// We found a face at the front of the line that was also at the back of the line
						// Increment the number of matched faces and add the line time to the total
						matchedFaceIds.push( personFace.faceId );
						let frontOfLineTime = date.getTime();
						let backOfLineTime = detection.date.getTime();
						let secondsInLine = (frontOfLineTime - backOfLineTime) / 1000;
						// Delete the detection record since person made it through line and if they show up
						// at the door camera again it is a new visit, but don't wait for the results
						this.detectionTable.delete( new Detection( companyId, siteId, backOfLineCameraId.toString(), personFace.personId ) );
						console.log("Found face and detection " + ((frontOfLineTime - backOfLineTime) / 1000) + ' seconds apart.  date='+date.toISOString()+'('+date.getTime()+'), detection=' + JSON.stringify(detection)+', ### time='+detection.date.getTime());
						resolve( secondsInLine );
					}
				})
				.catch( reason => {
					reject( new Error( 'Error getting detection record: ' + reason.message ) );
				})
			} else {
				// No matching face found but Promise was fulfilled.
				console.log('Detected new person at front of line.  No line time recorded.');
				resolve( 0 );
			}
		});
	}

    private updateResults( companyId: number, siteId: number, matchedFaceCount: number, secondsInLine: number ): Promise<void> {
		console.log( 'updateResults( '+companyId+', '+siteId+', '+matchedFaceCount+', '+secondsInLine+' )' );
		let date = new Date();
        return this.lineTimeTable.get( new LineTime( companyId, siteId, date ) )
        .then( lineTime => {
            // console.log( 'data from lineTimeTable.get()='+JSON.stringify(data));
            if (lineTime) {
                // Update the document
                // console.log('Result row already exists, update it.');
                this.lineTimeTable.increaseLineCountAndLineTime( companyId, siteId, date, matchedFaceCount, secondsInLine, lineTime );
            } else {
                // Insert a new document
				// console.log('Result row does not exist, insert it.');
				let lineTimeSlot = new LineTimeSlot( this.lineTimeTable.getTimeSlotTime( date ), matchedFaceCount, secondsInLine );
				let lineTime = new LineTime( companyId, siteId, date, 0, [ lineTimeSlot ] );
                this.lineTimeTable.put( new LineTime() );
            }
        });
    }

    public getPersonIdForFaceId( companyId: number, faceId: string, imageName: string, collectionName: string, confidence: number ): Promise<PersonFace> {
        // console.log( 'getPersonIdForNewFaceId( companyId=' + companyId + ', siteId=' + siteId + ', faceId=' + faceId + ', collectionName=' + collectionName + ', confidence=' + confidence );
        return new Promise( (resolve, reject) => {
			let foundMatchingFace = null;
			let foundPersonFace = null;
			let matchingFaces: MatchingFace[] = [];
			let personFaces: PersonFace[] = [];
			let persons: Person[] = [];
			// See if the faceId matches any other faceId's in the collection.
            this.rekognitionService.searchForMatchingFaces( faceId, collectionName, 80 )
            .then( (foundMatchingFaces: MatchingFace[]) => {
				// Try to get PersonFace records for all the matching face ID's
				matchingFaces = foundMatchingFaces;
				return this.getPersonFacesForMatchingFaces( companyId, collectionName, matchingFaces );
			})
			.then( (foundPersonFaces: PersonFace[]) => {
				// Try to get Person records for all the matching face ID's (if there is more than one)
				personFaces = foundPersonFaces;
				return this.getPersonsForPersonFaces( companyId, collectionName, personFaces );
			})
			.then( (foundPersons: Person[]) => {
				// Find first matching face that has both a PersonFace and Person record
				if (matchingFaces.length > 1) {
					persons = foundPersons;

					let list = '';
					persons.forEach( person => list += '\n'+person.personId+', '+person.cardHeading );
					// console.log('getPersonIdForFaceId found '+persons.length+' persons:'+list);
	

					// Find first matching face that has both a PersonFace and Person record
					matchingFaces.forEach( matchingFace => {
						if ((!foundMatchingFace)) {
							personFaces.forEach( personFace => {
								if ((!foundMatchingFace) && personFace.faceId == matchingFace.faceId) {
									persons.forEach( person => {
										if ((!foundMatchingFace) && person.personId == personFace.personId) {
											// Yay! We found a matching face with both a PersonFace and Person record, use it.
											foundMatchingFace = matchingFace;
											foundPersonFace = personFace;
											// Global.log('Using personFace because it has a Person: '+JSON.stringify( foundPersonFace, null, 2 ) );
										}
									})
								}
							})
						}
					});
					
					if (!foundMatchingFace) {
						// Find first matching face that has a visit record
						// This may take some time since we have to do a separate query on the visit table for each face id.
					}

					if (!foundMatchingFace) {
						// Find the person that is matched most often in the list of PersonFaces

						// Make map of match counts keyed by personId
						let map = new Map<string,number>();
						personFaces.forEach( personFace => {
							let count = map.get( personFace.personId );
							if (!count) {
								count = 0;
							}
							map.set( personFace.personId, count + 1 );
						})

						// Find most frequently matched personId
						let highestPersonCount = 0;
						let personWithHighestCount = null;
						map.forEach( (value, key, map) => {
							if (value > highestPersonCount) {
								highestPersonCount = value;
								personWithHighestCount = key;
							}
						});

						if (personWithHighestCount) {
							// Find first matching face that points at the most matched person
							for (let m=0; m<matchingFaces.length && !foundMatchingFace; m++) {
								let matchingFace = matchingFaces[m];
								for (let p=0; p<personFaces.length && !foundMatchingFace; p++) {
									let personFace = personFaces[p];
									if (personFace.faceId === matchingFace.faceId && personFace.personId === personWithHighestCount) {
										foundMatchingFace = matchingFace;
										foundPersonFace = personFace;
										// Global.log('Using personFace because it points to most matched Person: '+JSON.stringify( foundPersonFace, null, 2 ) );
									}
								}
	
							}
						}
						
					}
				}
				if ((!foundMatchingFace) && matchingFaces.length > 0) {
					foundMatchingFace = matchingFaces[0];
				}
				return foundMatchingFace;
			})
			.then( (matchingFace: MatchingFace) => {
				let person: PersonFace = null;
				let matchingFaceIdPersonToAdd: PersonFace = null;
				if (matchingFace) {
					// We found a matching face in the face collection, look up the person ID
					this.getPersonFaceIfNotFound( foundPersonFace, companyId, collectionName, matchingFace.faceId )
                    // this.personFaceTable.get( new PersonFace( companyId, siteId, matchingFace.faceId ) )
                    .then( foundPerson => {
                        if (foundPerson) {
                            // We found the person with the matching face ID
                            // console.log( 'Found person with matching face ID: '+JSON.stringify( foundPerson ) );
                            person = new PersonFace( companyId, collectionName, faceId, foundPerson.personId, matchingFace.faceId, matchingFace.confidence, imageName );
                        } else {
                            // console.log( 'This should not happen but there is no person record for the matching face ID ' + matchingFace.faceId + '.  Create a person face record for both face IDs.' );
                            // Create a person record for the new face ID
                            person = new PersonFace( companyId, collectionName, faceId, faceId, faceId, confidence, imageName );
                            // Create a person record for the matching face ID that uses the new face ID as the person ID
                            matchingFaceIdPersonToAdd = new PersonFace( companyId, collectionName, matchingFace.faceId, faceId, faceId, matchingFace.confidence, imageName );
                        }
                        if (!person) {
                            // Create a person record for the new face ID
                            person = new PersonFace( companyId, collectionName, faceId, faceId, faceId, confidence, imageName );
                        }
                        // Insert the person record for the new face ID
                        // console.log( 'Adding person record for new face ID: '+JSON.stringify( person ) );
                        this.insertPersonFaceAndPurge( person, collectionName )
                        .then( () => {
                            if (matchingFaceIdPersonToAdd) {
                                // Add a person record for the matching face ID that we found that didn't have a Person record
                                Global.log( 'This should not happen but we are adding person record that was missing for the matching face ID: '+JSON.stringify( matchingFaceIdPersonToAdd ) );
                                this.insertPersonFaceAndPurge( matchingFaceIdPersonToAdd, collectionName )
                                .then( () => {
                                    resolve( person );
                                })
                                .catch( reason => { 
                                    reject( new Error( 'Error inserting matching person face record '+ JSON.stringify( matchingFaceIdPersonToAdd ) + ': ' + reason ) ); 
                                });
                            } else {
                                resolve( person );
                            }
                        })
                        .catch( reason => { 
                            reject( new Error( 'Error inserting person face record '+ JSON.stringify( matchingFaceIdPersonToAdd, null, 2 ) + ': ' + reason ) ); 
                        });
                    })
                    .catch( reason => { 
                        reject( new Error( 'Error getting person with companyId='+companyId+', faceId='+matchingFace.faceId+': ' + reason.message ) ); 
                    });
                } else {
                    // We didn't find a matching face ID, add a new person
                    person = new PersonFace( companyId, collectionName, faceId, faceId, faceId, confidence, imageName );
                    this.insertPersonFaceAndPurge( person, collectionName )
                    .then( () => {
                        resolve( person );
                    })
                    .catch( reason => { 
                        reject( new Error( 'Error inserting person face record for new person '+ JSON.stringify( person, null, 2 ) + ': ' + reason ) ); 
                    });
                }
			})
            .catch( reason => { 
                reject( new Error( 'Error searching for matching faces with faceId='+faceId+', collectionName='+collectionName+': ' + reason.message ) ); 
			});
		});
    }

	/**
	 * Return PersonFace records for each of the given matching faces.
	 * @param companyId ID of company containing faces.
	 * @param siteId ID of site containing faces.
	 * @param matchingFaces Matching faces to find.
	 * @returns Promise<PersonFace[]>.
	 */
	private getPersonFacesForMatchingFaces( companyId: number, collectionId: string, matchingFaces: MatchingFace[] ): Promise<PersonFace[]> {
		let personFaceKeys: PersonFace[] = [];
		matchingFaces.forEach( matchingFace => {
			personFaceKeys.push( new PersonFace( companyId, collectionId,  matchingFace.faceId ) );
		});
		return this.personFaceTable.batchGetAll( personFaceKeys );
	}

	private getPersonsForPersonFaces( companyId: number, collectionId: string, personFaces: PersonFace[] ): Promise<Person[]> {
		let personIds: string[] = [];
		let personKeys: Person[] = [];
		let list = '';
		personFaces.forEach( personFace => {
			list += '\n'+'faceId '+personFace.faceId+', personId '+personFace.personId+', image '+personFace.imageName;
			if (personIds.indexOf( personFace.personId ) == -1) {
				personIds.push( personFace.personId );
				personKeys.push( new Person( companyId, collectionId, personFace.personId ) );
			}
		});
		// console.log('getPersonIdForFaceId found '+personFaces.length+' PersonFaces:'+list);
		return this.personTable.batchGetAll( personKeys );
	}

	private getPersonFaceIfNotFound( foundPersonFace: PersonFace, companyId: number, collectionId: string, faceId: string ) {
		return new Promise<PersonFace>( (resolve,reject) => {
			if (foundPersonFace) {
				resolve( foundPersonFace );
			} else {
				this.personFaceTable.get( new PersonFace( companyId, collectionId, faceId ) )
				.then( foundPersonFace => {
					resolve( foundPersonFace );
				})
				.catch( error => reject( error ) );
			}
		});
	}

	insertPersonFaceAndPurge( personFace: PersonFace, collectionName: string ): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            this.personFaceTable.put( personFace )
            .then( () => {
                // Purge lowest confidence face matches to reduce data growth and stay under AWS 1M faces/collection limit.
                // Don't wait for the results, we will try again the next time this person gets a match.
                this.personFaceTable.getLowConfidenceMatches( personFace )
                .then( personFaces => {
                    personFaces.forEach( personFace => {
                        // Delete the PersonFace record to reduce DB costs.
                        this.personFaceTable.delete( personFace )
                        .then( () => {
                            // Delete face from Rekognition collection to help stay under limit of 1M faces/collection.
                            this.rekognitionService.deleteFaceFromCollection( personFace.faceId, collectionName, true );
                        })
                    });
                })
                .catch( err => {
                    reject( new Error( 'Error purging unneded face records. ignoreFace='+personFace.faceId+', collectionName='+collectionName+': ' + err.message ) ); 
                });
                resolve();
            })
            .catch( reason => { 
                reject( new Error( 'Error inserting person face record for new person '+ JSON.stringify( personFace, null, 2 ) + ': ' + reason ) ); 
            });
        })
    }

}