import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService} from "../../service/user-login.service";
import { ActivityTypeComponent } from "../activity-type/activity-type.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { ActivityType } from '../../service/ActivityType';
import { Property } from '../../service/Property';
import { GetAllActivityTypesCmd } from '../../command/GetAllActivityTypesCmd';
import { PutActivityTypeCmd } from '../../command/PutActivityTypeCmd';
import { DeleteActivityTypeCmd } from '../../command/DeleteActivityTypeCmd';
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";

@Component({
    selector: 'activity-types',
    templateUrl: './activity-types.html',
	styleUrls: ['./activity-types.css'],
})
export class ActivityTypesComponent extends Editor<ActivityType> implements OnInit, AfterViewInit {

	private propertyId;

	@ViewChild(ActivityTypeComponent) private detailComponent: ActivityTypeComponent;
	
	constructor(
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Activity Types' );
	}

	initialize() {
		if (this.cache.getProperties().length > 0) {
			this.propertyId = this.cache.getProperties()[0].propertyId;
			this.changeDetectorRef.detectChanges();
			this.switchProperty( null );
		}
	}

	getProperties(): Property[] {
		return this.cache.properties ? this.cache.properties : <Property[]>[new Property(0,0,null)];
	}	

	switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		// Get list of rows to edit
		new GetAllActivityTypesCmd().do( this.cache.currentCompany.companyId, this.propertyId )
		.then( rows => this.rows = rows )
		.catch( error => this.handleError( error ) );
	}

	/** Returns component used to edit the selected row. */
	getDetailComponent(): EditorDetail<ActivityType> {
		return this.detailComponent;
	}

	/** Returns promise of array of rows to edit. */
	getRowLoader(): Promise<ActivityType[]> {
		return new GetAllActivityTypesCmd().do( this.cache.currentCompany.companyId, this.propertyId );
	}
	
	/** Creates a new row to be edited. */
	createNewRowToEdit(): ActivityType {
		return new ActivityType( this.cache.currentCompany.companyId, this.propertyId );
	}

	/** Called before new rows is saved. */
	prepareRowToInsert( newRow: ActivityType ) {
	}

	/** Detects changes in model so view is updated. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	getRowInserter( row: ActivityType ): Promise<ActivityType> {
		row.activityTypeId = this.getNextAvailableId();
		return new PutActivityTypeCmd().do( row );
	}

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	getRowUpdater( index: number, row: ActivityType ): Promise<ActivityType> {
		return new PutActivityTypeCmd().do( row );
	}

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	getRowDeleter( index: number, row: ActivityType ): Promise<void> {
		return new DeleteActivityTypeCmd().do( row );
	}

	/** Returns the next available ID which is one higher than the highest ID already used. */
	getNextAvailableId(): number {
		let id = 1;
		this.rows.forEach( row => {
			if (row.activityTypeId >= id) {
				id = row.activityTypeId+1;
			}
		})
		return id;
	}

}
