import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { Activity } from './Activity';

/**
 * List of activities and the index of the active schedule for each activitiy.
 */
export class ActivitySchedules extends TableDocumentObject {
	constructor(
		public activities: Activity[] = [],
		public scheduleIndexes: number[] = [],
	 ) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): ActivitySchedules {
		this.copyPropertiesFromObject( item );
		// Copy nested activities objects
		this.activities = null;
		if (item['activities']) {
			this.activities = [];
			for (let i=0; i<item['activities'].length; i++) {
				this.activities.push( new Activity().fromDataItem( item['activities'][i] ) );
			}
		}
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		// Copy nested activities objects
		item['activities'] = null;
		if (this.activities) {
			item['activities'] = [];
			for (let i=0; i<this.activities.length; i++) {
				item['activities'].push( this.activities[i].toDataItem() );
			}
		}
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
	}
	
}

