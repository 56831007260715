import { Global } from "../service/Global";
import { Command } from "./Command";
import { Company } from "../service/Company";

export class BeyondPayAuthCmd extends Command {

	/**
	 * Charges the credit card for a company by calling the BeyondPay API.
	 * @param companyId ID of the company that is subscribing.
	 * @param softwareName Name and version number of the software we are charging for.
	 * @param token Token from BeyondPay createToken function.
	 * @param cents Number of cents to charge credit card.
	 * @param daysToDelay Number of days authorization can be used to make a sale
	 */
	do( companyId: number, softwareName: string, token: string, cents: number, daysToDelay: number ): Promise<Company> {
		return new Promise<Company>( (resolve, reject) => {
			var parameters = {
				"softwareName": softwareName,
				"token": token,
				"cents": cents,
				"daysToDelay": daysToDelay,
			};
			super.send( this.constructor.name, companyId, parameters )
			.then( results => resolve( results ) )
			.catch( err => reject( err ) );
		});
	}
}
