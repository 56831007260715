// Load the AWS SDK
import * as Rekognition from "aws-sdk/clients/rekognition";
import { AWSRequestor } from './AWSRequestor';
import { Global } from './Global'
import { Visit } from './Visit'

// Structure to hold a matching faceId and the confidence value that the faces match
export class MatchingFace {

    constructor( 
        public faceId: string,
        public confidence: number
    ) {}

}

/**
 * Class to call AWS Rekognition API's for facial recognition.
 */
export class RekognitionService {

    constructor() {
    }

	// var params = {
	// 	Image: {
	// 	 S3Object: {
	// 	  Bucket: "mybucket", 
	// 	  Name: "myphoto"
	// 	 }
	// 	}
	//    };
	//    rekognition.detectFaces(params, function(err, data) {

    /**
     * Scan the given image stored on S3 for faces.
     * 
	 * @param bucketName Bucket that contains file.
	 * @param objectKey Object key.
     */
    public detectFacesInS3ImageFile( bucketName: string, objectKey: string ): Promise<Rekognition.DetectFacesResponse> {
        return new Promise( (resolve, reject) => {
            let params = {
                Image: {
                    S3Object: {
                        Bucket: bucketName, 
                        Name: objectKey
                    }
                }
			};
			// let startTime = Date.now();
			new AWSRequestor().send( new Rekognition().detectFaces( params ) )
			.then( data => resolve( data ) )
			.catch( err => reject( err ) );
        });        
    }


    /**
     * Scan the given image for faces and add any faces found to the given collection.
     * This function assumes that camera images are stored in an S3 bucket with folder structure companyId/siteId/cameraId/imageName.
     * 
	 * @param bucketName Bucket that contains file.
	 * @param objectKey Object key.
     * @param faceCollectionName Name of the collection to which any found faces will be added.
     */
    public indexFacesInS3ImageFile( bucketName: string, objectKey: string, faceCollectionName: string ): Promise<Rekognition.IndexFacesResponse> {
        return new Promise( (resolve, reject) => {
            let externalImageId = bucketName + '.' + objectKey;
            // console.log( 'before: ' + externalImageId );
            externalImageId = externalImageId.replace( /\//g, '.' );
            // console.log( 'after: ' + externalImageId );
            let params = {
                CollectionId: faceCollectionName, 
                DetectionAttributes: ['ALL'], 
                ExternalImageId: externalImageId, 
                Image: {
                    S3Object: {
                        Bucket: bucketName, 
                        Name: objectKey
                    }
                }
			};
			// let startTime = Date.now();
			new AWSRequestor().send( new Rekognition().indexFaces( params ) )
			.then( data => resolve( data ) )
			.catch( err => {
				if (err.code == 'ResourceNotFoundException') {
					// We may have failed because collection did not exist, try creating it.
					this.createFaceCollection( faceCollectionName )
					.then( values => {
						// We successfully created collection, try indexing faces again
						new AWSRequestor().send( new Rekognition().indexFaces( params ) )
						.then( data => resolve( data ) )
						.catch( err => reject( err ) );
					})
					.catch( error => {
						Global.logError( 'Retry creating face collection failed.', error );
						reject( err );
					})
				} else {
					reject( err );
				}
			});
        });        
    }

    /**
     * Create a collection for storing Rekognition face data.
     * 
     * @param collectionName Name of the collection to which any found faces will be added.
     * @return Name of the created collection.
     */
    public createFaceCollection( collectionName: string ): Promise<string> {
        return new Promise( (resolve, reject) => {
            // console.log( 'createCollection( '+collectionName+' )' );
            let params: Rekognition.CreateCollectionRequest = {
                CollectionId: collectionName, 
            };
            // console.log( 'Calling indexFaces for image: <'+params.Image.S3Object.Bucket+'/'+params.Image.S3Object.Name+'>');
			new AWSRequestor().send( new Rekognition().createCollection( params ) )
			.then( data => resolve( collectionName ) )
			.catch( err => reject( err ) );
        });        
    }

    /**
     * Delete a collection of Rekognition face data.
     * 
     * @param collectionId ID of the collection to delete.
	 * @return True if collection was deleted, false if it did not exist.
     */
    public deleteFaceCollection( collectionId: string ): Promise<boolean> {
        return new Promise<boolean>( (resolve, reject) => {
            Global.log( 'deleteFaceCollection( '+collectionId+' )' );
            let params: Rekognition.DeleteCollectionRequest = {
                CollectionId: collectionId, 
            };
			new AWSRequestor().send( new Rekognition().deleteCollection( params ) )
			.then( data => resolve( true ) )
			.catch( err => {
				if (err.code === 'ResourceNotFoundException') {
					// Ignore this error at caller's request
					resolve( false );
				} else {
					reject( err );
				}
			});
        });        
    }

    /**
     * Searches the given collection for faces that match the given face ID from the collection.
     * Returns array with 0 or more matching faces in order of their similarity to the given face ID.
     * @param faceId Face ID for which a matching face is being sought.  FaceId must come from the given collection.
     * @param faceCollectionName Name of the face collection containing the given faceId and the other faces to search.
     * @param faceMatchThreshold Optional value specifying the minimum confidence in the face match to return.
     * @return Promise for the array of 0 or more matching faces.
     */
    public searchForMatchingFaces( faceId: string, faceCollectionId: string, faceMatchThreshold: number ): Promise<MatchingFace[]> {
        return new Promise( (resolve, reject) => {
            // console.log('searchForMatchingFace( faceId='+faceId+', collection='+faceCollectionId+', threshold='+faceMatchThreshold+' )' );
            let params: Rekognition.SearchFacesRequest = {
                CollectionId: faceCollectionId, 
                FaceId: faceId, 
                FaceMatchThreshold: faceMatchThreshold, 
                MaxFaces: 25
            };
            // console.log('searchForMatchingFace params('+JSON.stringify(params)+')');
			new AWSRequestor().send( new Rekognition().searchFaces( params ) )
			.then( (data: Rekognition.SearchFacesResponse) => {
				let matchingFaces: MatchingFace[] = [];
				if (data && data.FaceMatches) {
					let list = '';
					data.FaceMatches.forEach(face => {
						list += '\nface '+face.Face.FaceId+' similarity='+face.Similarity;
						matchingFaces.push( { 
							faceId: face.Face.FaceId, 
							confidence: face.Similarity 
						} );
					});
					// Global.log( 'rekognition searchFaces found '+data.FaceMatches.length+' faces:'+list);
				}
				// console.log('searchForMatchingFace resolve: ' + JSON.stringify( matchingFace, null, 2 ) );
				resolve( matchingFaces  );
			})
			.catch( err => reject( err ) );
        });        
    }

    /**
     * Deletes the given face ID from the collection.
     * @param faceId Face ID for which a matching face is being sought.  FaceId must come from the given collection.
     * @param collectionId Name of the face collection containing the given faceId and the other faces to search.
     * @param logError True if error should be logged, defaults to false.
     * @return Promise for the value of the matching face ID or undefined if none found.
     */
    public deleteFaceFromCollection( faceId: string, collectionId: string, logError: boolean = false ): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            // console.log('deleteFaceFromCollection( '+faceId+', '+collectionId+' )' );
            let params: Rekognition.DeleteFacesRequest = {
                CollectionId: collectionId, 
                FaceIds: [
                    faceId
                ]
            };
			new AWSRequestor().send( new Rekognition().deleteFaces( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
        });        
    }

	/**
	 * Gets the confidence that an emotion was detected on a face.
	 * @param emotionType Type of emotion.  Valid Values: HAPPY | SAD | ANGRY | CONFUSED | DISGUSTED | SURPRISED | CALM | UNKNOWN
	 * @param emotions Array of Rekognition Emotion objects.
	 * @return Confidence that the given emotion type was detected or 0 if emotion was not detected.
	 */
	public static getEmotionConfidence( emotionType: string, emotions: Rekognition.Emotions ): number {
		let confidence = 0;
		if (emotions) {
			emotions.forEach( emotion => {
				if (emotion.Type == emotionType) {
					confidence = emotion.Confidence;
				}
			})
		}
		return confidence;
	}

	public setVisitFaceDetails( visit: Visit, faceDetail: Rekognition.FaceDetail, imageName: string ) {
		visit.ageRangeLow = faceDetail.AgeRange.Low,
		visit.ageRangeHigh = faceDetail.AgeRange.High,
		visit.beard = faceDetail.Beard.Value ? faceDetail.Beard.Confidence : 0,
		visit.happy = RekognitionService.getEmotionConfidence( 'HAPPY', faceDetail.Emotions ),
		visit.sad = RekognitionService.getEmotionConfidence( 'SAD', faceDetail.Emotions ),
		visit.angry = RekognitionService.getEmotionConfidence( 'ANGRY', faceDetail.Emotions ),
		visit.confused = RekognitionService.getEmotionConfidence( 'CONFUSED', faceDetail.Emotions ),
		visit.disgusted = RekognitionService.getEmotionConfidence( 'DISGUSTED', faceDetail.Emotions ),
		visit.surprised = RekognitionService.getEmotionConfidence( 'SURPRISED', faceDetail.Emotions ),
		visit.calm = RekognitionService.getEmotionConfidence( 'CALM', faceDetail.Emotions ),
		visit.unknownEmotion = RekognitionService.getEmotionConfidence( 'UNKNOWN', faceDetail.Emotions ),
		visit.eyeglasses = faceDetail.Eyeglasses.Value ? faceDetail.Eyeglasses.Confidence : 0,
		visit.male = faceDetail.Gender.Value == 'Male' ? faceDetail.Gender.Confidence : 0,
		visit.female = faceDetail.Gender.Value == 'Female' ? faceDetail.Gender.Confidence : 0,
		visit.mustache = faceDetail.Mustache.Value ? faceDetail.Mustache.Confidence : 0,
		visit.smile = faceDetail.Smile.Value ? faceDetail.Smile.Confidence : 0,
		visit.sunglasses = faceDetail.Sunglasses.Value ? faceDetail.Sunglasses.Confidence : 0

		visit.confidence = faceDetail.Confidence;
		visit.boxTop = faceDetail.BoundingBox.Top;
		visit.boxLeft = faceDetail.BoundingBox.Left;
		visit.boxWidth = faceDetail.BoundingBox.Width;
		visit.boxHeight = faceDetail.BoundingBox.Height;
		visit.imageName = imageName;
	}

}