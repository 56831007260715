import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { CacheService } from "../../service/cache.service";
import { Global } from '../../service/Global';
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { Product } from '../../service/Product';

/** Displays menu page of reports and settings for Room Genie. */
@Component({
    selector: 'room-genie-main',
    templateUrl: './room-genie-main.html',
    styleUrls: ['./room-genie-main.css'],
})
export class RoomGenieMainComponent {

	private roomGenieProductCode = Product.ROOM_GENIE;

	constructor(
		// private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<img class="vertical-align-baseline" src="../../assets/img/GenieLampSmallCropped.png" height="30"/> Room Genie' );
	}

	private initialize() {
	}

}
