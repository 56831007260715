import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {CacheService} from "../../service/cache.service";
import { Ad } from "../../service/Ad";
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { Product } from "../../service/Product";
import { Site } from "../../service/Site";
import { SiteTable } from "../../service/SiteTable";
import { ConfirmComponent } from "../confirm/confirm.component";
import { InputTextComponent } from '../input-text/input-text.component';
import { SiteHours } from "../../service/SiteHours"
import { SiteAdComponent } from '../site-ad/site-ad.component';
import { SiteHoursComponent } from '../site-hours/site-hours.component';
import { AdCategory } from "../../service/AdCategory";
import { GetAdCategoriesCmd } from '../../command/GetAdCategoriesCmd';

/**
 * Company editing component.
 */
@Component({
    selector: 'site',
    templateUrl: './site.html',
	styleUrls: ['./site.css'],
})
export class SiteComponent extends EditorDetail<Site> implements OnInit, OnDestroy, AfterViewInit {

	private whatsHappeningProductCode = Product.WHATS_HAPPENING;
	
	/** List of time zones from which to select. */
	private timeZones: string[] = [];

	/** List of ad categories loaded so we have their names. */
	public adCategories: AdCategory[] = [];

	/** Hold monthly ad budget dollar amount for editing.  Database stores the amount in cents. */
	public monthlyAdBudget: number = 0;

	@ViewChild('siteModalButton') public modalButtonRef: ElementRef;

	@ViewChild('focus') public focusRef: ElementRef; 

	@ViewChild(InputTextComponent) private synonymComponent: InputTextComponent;

	@ViewChild(SiteHoursComponent) private hoursComponent: SiteHoursComponent;

	@ViewChild(SiteAdComponent) private adComponent: SiteAdComponent;

	constructor(
		private router: Router, 
        private userService: UserLoginService,
		private changeDetectorRef: ChangeDetectorRef,
        private cache: CacheService,
		private siteTable: SiteTable,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.timeZones = Global.getTimeZoneNames();
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initializeDialog() {
		this.loadAdCategories();
	}

	loadAdCategories() {
		new GetAdCategoriesCmd().do( this.cache.currentCompany.companyId )
		.then( rows => {
			this.adCategories = rows;
		})
		.catch( error => this.handleError( error ) );
	}

	getAdCategoryName( adCategoryId: number ): string {
		let name = '# category name not found #';
		for (let i=0; i<this.adCategories.length; i++) {
			if (this.adCategories[i].adCategoryId == adCategoryId) {
				name = this.adCategories[i].name;
				break;
			}
		}
		return name;
	}

	getSpeechSnippet( speech: string ): string {
		let snippet = '';
		if (speech && speech.length > 0) {
			snippet = speech;
			if (speech.length > 45) {
				snippet = speech.substr(0,42)+'...';
			}
		}
		return snippet;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	createNewDefaultRow(): Site {
		return new Site();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Return a deep copy of the given object. */
	clone( row: Site ): Site {
		return new Site().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete site ' + this.originalRow.siteId + ' - ' + this.originalRow.name + '?'
	}

	isValid(): boolean {
		this.errorMessage = null;
		if (!this.editedRow.siteId) {
			this.errorMessage = 'Site ID is required and must be unique within the property.'
		} else if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for the site.';
		} else if (this.monthlyAdBudget != 0 && this.monthlyAdBudget < 30) {
			this.errorMessage = 'Please set the maximum monthly ad budget to at least 30 dollars or set it to 0 if you don\'t want to pay for advertising.';
		} else {
			// If latitude and longitude are set and at least one ad with a radius exists,
			// Set the adMinLatitude, adMaxLatitude, adMinLongitude, and adMaxLongitude values
			// so we can find sites that include a hotel's lat/long by querying sites
			// where latitude>=adMinLatitude && latitude<=adMaxLatitude && longitude>=adMinLongitude && longitude<=ad<MaxLongitude
			if (this.editedRow.latitude && this.editedRow.longitude && this.editedRow.ads) {
				let largestRadius = this.editedRow.getLargestAdRadiusMiles();
				if (largestRadius > 0) {
					let latitudeDegreeMiles = Global.getMilesPerDegreeOfLatitude();
					let longitudeDegreeMiles = Global.getMilesPerDegreeOfLongitude( this.editedRow.latitude );
					// Set minimum and maximum latitudes based on largest ad radius
					let minLatitude = this.editedRow.latitude - (largestRadius / latitudeDegreeMiles);
					let maxLatitude = this.editedRow.latitude + (largestRadius / latitudeDegreeMiles);
					// Set minimum and maximum longitudes based on largest ad radius
					let minLongitude = this.editedRow.longitude - (largestRadius / longitudeDegreeMiles);
					let maxLongitude = this.editedRow.longitude + (largestRadius / longitudeDegreeMiles);
					console.log( 
						'largestRadius='+largestRadius
						+', latitude='+this.editedRow.latitude
						+', longitude='+this.editedRow.longitude
						+', minLatitude='+minLatitude
						+', maxLatitude='+maxLatitude
						+', minLongitude='+minLongitude
						+', maxLongitude='+maxLongitude
						)
				}
			}
		}
		return this.errorMessage == null;
	}

	addSynonym() {
		this.synonymComponent.configure("Alternate Name", "Name", '40', 'alternate name', true );
		this.handleChildInsert( new String(), this.editedRow.synonyms, this.synonymComponent );
	}

	editSynonym( index: number ) {
		this.synonymComponent.configure("Alternate Name", "Name", '40', 'alternate name', true );
		this.handleChildEdit( index, this.editedRow.synonyms, this.synonymComponent );
	}

	// deleteSynonym( index: number ) {
	// 	this.editedRow.synonyms.splice( index, 1 );
	// }

	insertHours() {
		this.hoursComponent.propertyId = this.editedRow.propertyId;
		this.hoursComponent.timeZone = this.editedRow.timeZone;
		// Calculate noon in the property's time zone to use as the default start and end date
		let startDate = new Date();
		startDate.setUTCHours( 12, 0 );
		startDate = Global.adjustDateByTimeZone( startDate, this.editedRow.timeZone, false );
		let endDate = new Date( startDate.getTime() );
		// End date defaults to 10 years after start date
		endDate.setUTCFullYear( startDate.getUTCFullYear() + 10 );
		// Closing time defaults to 2 hours later than opening time
		endDate = new Date( endDate.getTime() + (Global.millisInOneHour * 2) );
		if (this.editedRow.hours == null) {
			this.editedRow.hours = [];
		}
		this.handleChildInsert( new SiteHours( null, startDate, endDate ), this.editedRow.hours, this.hoursComponent );
	}

	editHours( index: number ) {
		this.hoursComponent.propertyId = this.editedRow.propertyId;
		this.hoursComponent.timeZone = this.editedRow.timeZone;
		this.handleChildEdit( index, this.editedRow.hours, this.hoursComponent );
	}

	insertAd() {
		this.configureSiteAdComponent();
		this.handleChildInsert( new Ad(), this.editedRow.ads, this.adComponent );
	}

	private configureSiteAdComponent() {
		this.adComponent.propertyId = this.editedRow.propertyId;
		this.adComponent.timeZone = this.editedRow.timeZone;
		this.adComponent.siteLatitude = this.editedRow.latitude;
		this.adComponent.siteLongitude = this.editedRow.longitude;
		this.adComponent.adCategories = this.adCategories;
		if (this.editedRow.ads == null) {
			this.editedRow.ads = [];
		}
	}

	editAd( index: number ) {
		this.configureSiteAdComponent();
		this.handleChildEdit( index, this.editedRow.ads, this.adComponent );
	}

	// public hasProductPermission( productCodes: string[], permission: string ): boolean {
	// 	let hasPermission = this.cache.hasProductPermission( productCodes, permission );
	// 	// console.log('hasProductPermission( '+productCodes+', '+permission+' ) returns '+hasPermission);
	// 	return hasPermission;
	// }

}