import { Command } from "./Command";
import { ActivitySignUpList } from "../service/ActivitySignUpList";
import { ActivitySchedules } from "../service/ActivitySchedules";

export class GetActiveSchedulesCmd extends Command {

	/**
	 * Returns a list of sign up lists with available capacity for the given list of activity schedules.
	 * @param activitySchedules List of active activity schedules.
	 * @param persons Available capacity that a sign up list must have in order to be returned, 0 returns all lists.
	 */
	public do( companyId: number, propertyId: number, activityTypeId: number ): Promise<ActivitySchedules> {
		return new Promise<ActivitySchedules>( (resolve, reject) => {
			this.send( this.constructor.name, companyId, { "propertyId": propertyId, "activityTypeId": activityTypeId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let activitySchedules = new ActivitySchedules().fromDataItem( results.data );
				// console.log('Sign Up List: '+JSON.stringify(rows[rows.length-1],null,2));
				resolve( activitySchedules );
			}).catch( err => reject( err ) );
		});
	}

}
