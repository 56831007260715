import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../service/user-login.service";
import { GoogleChartsService } from "../../service/google-charts.service";
import { VisitSummaryTable, VisitSummary, SummaryValues } from '../../service/VisitSummaryTable';
import { Visit } from '../../service/Visit';
import { VisitTable } from '../../service/VisitTable';
import { EventTable, Event } from '../../service/EventTable';
import { CacheService } from "../../service/cache.service";
import { Global } from '../../service/Global';
import { TitleNavComponent } from "../title-nav/title-nav.component"

/**
Displays a dashboard for the SmartView product that tracks new and repeat guests.
*/
@Component({
    selector: 'analytics',
    templateUrl: './analytics.html',
    styleUrls: ['./analytics.css'],
})
export class AnalyticsComponent implements OnInit, AfterViewInit {

	public guestTotal = 0;
	public newGuestTotal = 0;
	public newGuestPercent = 0;
	public repeatGuestTotal = 0;
	public repeatGuestPercent = 0;
	public visitTotals = new VisitSummary();

	private visitChartData;
	private visitChartOptions = {
		// curveType: 'function',
		legend: { position: 'top' },
		pointSize: 5,
		backgroundColor: '#f8f8f8',
		hAxis: {
			gridlines: {
				count: -1,
			}
		},
		vAxis: {
			minValue: 0,
			viewWindow: { min: 0 },
		},
	};

	private properties =  [
		"morning", "afternoon", "evening", "night",
		"male", "female",
		"age0to9", "age10to19", "age20to29", "age30to39", "age40to49", "age50to59", "age60to69", "age70to79", "ageOver80",
		"happy", "sad", "angry", "confused", "disgusted", "surprised", "calm", "unknown",
		"smile", "eyeglasses", "sunglasses", "mustache", "beard"
	];

	/** ID of the timer used to refresh the dashboard. */
	private timer: NodeJS.Timer;

	/** Dashboard refresh frequency in milliseconds. */
	private readonly refreshFrequencyMillis = 0;

	/** Set to true in ngOnInit and false in ngOnDestroy */
	private componentIsInitialized = false;

	@ViewChild('morning') private morningRef: ElementRef; private morning: HTMLInputElement;
	@ViewChild('afternoon') private afternoonRef: ElementRef; private afternoon: HTMLInputElement;
	@ViewChild('evening') private eveningRef: ElementRef; private evening: HTMLInputElement;
	@ViewChild('night') private nightRef: ElementRef; private night: HTMLInputElement;
	@ViewChild('male') private maleRef: ElementRef; private male: HTMLInputElement;
	@ViewChild('female') private femaleRef: ElementRef; private female: HTMLInputElement;
	@ViewChild('age0to9') private age0to9Ref: ElementRef; private age0to9: HTMLInputElement;
	@ViewChild('age10to19') private age10to19Ref: ElementRef; private age10to19: HTMLInputElement;
	@ViewChild('age20to29') private age20to29Ref: ElementRef; private age20to29: HTMLInputElement;
	@ViewChild('age30to39') private age30to39Ref: ElementRef; private age30to39: HTMLInputElement;
	@ViewChild('age40to49') private age40to49Ref: ElementRef; private age40to49: HTMLInputElement;
	@ViewChild('age50to59') private age50to59Ref: ElementRef; private age50to59: HTMLInputElement;
	@ViewChild('age60to69') private age60to69Ref: ElementRef; private age60to69: HTMLInputElement;
	@ViewChild('age70to79') private age70to79Ref: ElementRef; private age70to79: HTMLInputElement;
	@ViewChild('ageOver80') private ageOver80Ref: ElementRef; private ageOver80: HTMLInputElement;
	@ViewChild('happy') private happyRef: ElementRef; private happy: HTMLInputElement;
	@ViewChild('sad') private sadRef: ElementRef; private sad: HTMLInputElement;
	@ViewChild('angry') private angryRef: ElementRef; private angry: HTMLInputElement;
	@ViewChild('confused') private confusedRef: ElementRef; private confused: HTMLInputElement;
	@ViewChild('disgusted') private disgustedRef: ElementRef; private disgusted: HTMLInputElement;
	@ViewChild('surprised') private surprisedRef: ElementRef; private surprised: HTMLInputElement;
	@ViewChild('calm') private calmRef: ElementRef; private calm: HTMLInputElement;
	@ViewChild('unknown') private unknownRef: ElementRef; private unknown: HTMLInputElement;
	@ViewChild('smile') private smileRef: ElementRef; private smile: HTMLInputElement;
	@ViewChild('eyeglasses') private eyeglassesRef: ElementRef; private eyeglasses: HTMLInputElement;
	@ViewChild('sunglasses') private sunglassesRef: ElementRef; private sunglasses: HTMLInputElement;
	@ViewChild('mustache') private mustacheRef: ElementRef; private mustache: HTMLInputElement;
	@ViewChild('beard') private beardRef: ElementRef; private beard: HTMLInputElement;
	
		constructor(
		private router: Router, 
		private userService: UserLoginService, 
		private googleCharts: GoogleChartsService,
		private visitSummaryTable: VisitSummaryTable,
		private visitTable: VisitTable,
		private eventTable: EventTable,
		private cache: CacheService
	) {}

    initialize() {
		this.loadDataAndUpdateView()
		.catch( err => {
			// Failure here usually means user token has expired so return to login
			Global.logError('Error drawing charts.', err );
			alert( 'Sorry, we had a problem loading the page. Please try again.' );
		});
	}

	private loadDataAndUpdateView(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			// Figure out today's date at the site
			let timeZone = this.cache.getTimeZone( this.cache.currentSite.propertyId );
			let endDate = Global.adjustDateByTimeZone( new Date(), timeZone );
			endDate.setUTCHours( 0, 0, 0, 0 ); // Remove time from date

			// let endDate = new Date();
			let millisIn29Days = 29 * Global.millisInOneDay;
			let startDate = new Date( endDate.getTime() - millisIn29Days );
			// console.log('Refreshing data...');
			this.visitSummaryTable.getSummaryData( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, startDate, endDate )
			.then( visitSummaries => {
				this.eventTable.getEventsForDateRange( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, startDate, endDate )
				.then( events => {
					this.setupChartData( visitSummaries, events );
					this.drawVisitChart();
				})
				.catch( err => {
					let errorMessage = 'Error loading event data. '+err.message;
					Global.logError( 'Error loading event data.', err );
					reject( new Error( errorMessage ) );
				});
				// if (this.componentIsInitialized) {
				// 	// We are still on the page so draw the chart
				// 	// console.log('drawing charts');
				// 	this.setupChartData( visitSummaries );
				// 	this.drawVisitChart();
				// 	// Refresh the view periodically
				// 	if (this.refreshFrequencyMillis > 0) {
				// 		this.timer = setTimeout( () => this.checkUserAuthentication(), this.refreshFrequencyMillis );
				// 	}
				// }
				resolve();
			})
			.catch( err => {
				let errorMessage = 'Error loading data to refresh views: '+err.message;
				Global.logError( 'Error loading visit summary data.', err );
				reject( new Error( errorMessage ) );
			});
		})
	}
	
    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.componentIsInitialized = true;
        this.checkUserAuthentication();
	}
	
	checkUserAuthentication() {
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
    ngOnDestroy() {
		this.componentIsInitialized = false;
        if (this.timer) {
			console.log('Cancelling refresh timer');
			clearTimeout( this.timer );
			this.timer = null;
		}
    }

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-line-chart fa-fw"></i> VIP Analytics' );
		this.properties.forEach( property => {
			this[property] = this[property+'Ref'].nativeElement;
		})
		// this.loadDataAndUpdateView()
		// .catch( err => {
		// 	Global.logError('Error drawing charts.', err );
		// 	alert( 'Sorry, we had a problem loading the page.  Please try again.' );
		// });
	}

	onCheck() {
		if (this.timer) {
			clearTimeout( this.timer );
			this.timer = null;
		}
		this.checkUserAuthentication();
	}

	onGenerateTestData() {
		this.visitSummaryTable.generateTestData( this.cache.currentCompany.companyId, this.cache.currentSite.siteId );
		this.eventTable.generateTestData( this.cache.currentCompany.companyId, this.cache.currentSite.siteId );
	}

	onPurgeVisits() {
		let addTestRecords = false;
		if (addTestRecords) {
			let visit = new Visit( this.cache.currentCompany.companyId, this.cache.currentSite.siteId, 'a_fake_person_id', 'a_fake_face_id', new Date(), new Date() );
			for (let i=0; i<100; i++) {
				visit.startTime = new Date( visit.startTime.getTime() - (i * 3600000) );
				this.visitTable.put( visit );
			}
		}
		this.visitTable.purgeVisits( this.cache.currentCompany.companyId, /* siteId */ 2, new Date() )
		// .then( deleteCount => console.log( 'onPurgeVisits purged ' + deleteCount + ' visits.' ) )
		.catch( err => {
			Global.logError( 'Error purging visits.', err );
		})
	}

	onResizeVisitChart(event) {
		if (this.visitChartData) {
			this.drawVisitChart();
		}
	}

	/**
	 * Round a number to the given precision.
	 * @param number Number to round.
	 * @param precision Number of digits right of decimal to round (or left of decimal if negative).
	 */
	roundWithPrecision(number, precision) {
		var factor = Math.pow(10, precision);
		var tempNumber = number * factor;
		var roundedTempNumber = Math.round(tempNumber);
		return roundedTempNumber / factor;
	};

	getCheckedAttributeCount(): number {
		let count = 0;
		this.properties.forEach( property => {
			if (this[property].getAttribute("aria-pressed")==="true")
				count++;
		})
		// console.log('Found '+count+' checked properties');
		return count;
	}

	setupChartData( summaries: VisitSummary[], events: Event[] ) {
		this.visitChartData = [];

		// Figure out today's date at the site
		let timeZone = this.cache.getTimeZone( this.cache.currentSite.propertyId );
		let currentDateOnly = Global.adjustDateByTimeZone( new Date(), timeZone );
		currentDateOnly.setUTCHours( 0, 0, 0, 0 );
		let millisInOneDay = 24 * 60 * 60 * 1000;

		let newGuestCount = 0;
		let repeatGuestCount = 0;
		let dates: Date[] = [];
		for (let i=0; i<30; i++) {
			let indexDate = new Date( currentDateOnly.getTime() - ( (29-i) * millisInOneDay ) );
			this.visitChartData.push( [ (indexDate.getUTCMonth()+1)+'/'+indexDate.getUTCDate(), 0, null, 0, null, 0, null ] );
		}
		let checkedAttributeCount = this.getCheckedAttributeCount();

		// Copy data from summary records to chart data array and visitTotals object.
		this.guestTotal = 0;
		this.newGuestTotal = 0;
		this.newGuestPercent = 0;
		this.repeatGuestTotal = 0;
		this.repeatGuestPercent = 0;
		this.visitTotals = new VisitSummary();
		summaries.forEach(summary => {

			let includedNewGuests = 0;
			let includedRepeatGuests = 0;
			if (checkedAttributeCount == 0) {
				// Include all attributes
				includedNewGuests += summary.newVisitors.count;
				includedRepeatGuests += summary.repeatVisitors.count;
			} else {
				this.properties.forEach( property => {
					if (this[property].getAttribute("aria-pressed")==="true") {
						// console.log( 'summary '+summary.date.toISOString()+' '+property+' new='+summary.newVisitors[property]+' repeat='+summary.repeatVisitors[property])
						includedNewGuests += summary.newVisitors[property];
						includedRepeatGuests += summary.repeatVisitors[property];
					}
				});
			}			
			this.newGuestTotal += includedNewGuests;
			this.repeatGuestTotal += includedRepeatGuests;
			this.guestTotal += includedNewGuests + includedRepeatGuests;
			
			// Figure out which array element the summary date goes in
			let index = 29 - ( ( currentDateOnly.getTime() - summary.date.getTime() ) / millisInOneDay );
			// console.log( 'today='+currentDateOnly.toISOString()+', summary.date='+summary.date.toISOString()+', index='+index );

			this.visitChartData[index][1] += includedNewGuests;
			this.visitChartData[index][3] += includedRepeatGuests;
			this.visitChartData[index][5] += (this.visitChartData[index][1] + this.visitChartData[index][3]);
			
			this.addSummaryValuesToTotals( summary.newVisitors, this.visitTotals.newVisitors );
			this.addSummaryValuesToTotals( summary.repeatVisitors, this.visitTotals.repeatVisitors );
			
		});

		this.convertValuesToPercentages( this.visitTotals.newVisitors );
		this.convertValuesToPercentages( this.visitTotals.repeatVisitors );
		
		this.repeatGuestPercent = this.repeatGuestTotal > 0 ? Math.round( ( this.repeatGuestTotal / this.guestTotal ) * 100 ) : 0;
		this.newGuestPercent = 100 - this.repeatGuestPercent;

		// Add event annotations to chart data
		events.forEach( event => {
			// Figure out which array element the event goes on
			let index = 29 - Math.round( ( currentDateOnly.getTime() - event.eventDate.getTime() ) / millisInOneDay );
			// console.log( 'today='+currentDateOnly.toISOString()+', eventDate='+event.eventDate.toISOString()+', index='+index );
			if (event.affectsNew && !event.affectsReturning) {
				// Show event on new guest line
				this.visitChartData[index][2] = event.name;
			} else if (event.affectsReturning && !event.affectsNew) {
				// Show event on returning guest line
				this.visitChartData[index][4] = event.name;
			} else {
				// Show event on total guest line
				this.visitChartData[index][6] = event.name;
			}
			
		});

	}

	private convertValuesToPercentages( totals: SummaryValues ) {
		this.properties.forEach( property => {
			totals[property] = this.convertCountToPercent( totals[property], totals.count );
		});

		// Minutes aren't shown as a percentage so recalculate them
		totals.visitMinutes = totals.count == 0 ? 0 : Math.round( totals.visitMinutes / totals.count );
	}

	private convertCountToPercent( count: number, total: number ): number {
		return count == 0 ? 0 : Math.round( ( count / total ) * 100 );
	}

	private addSummaryValuesToTotals( values: SummaryValues, totals: SummaryValues ) {
		this.properties.forEach( property => {
			totals[property] += values[property];
		});
		totals.count += values.count;
		totals.visitMinutes += values.visitMinutes;
	}

	drawVisitChart() {
		this.googleCharts.loadLibraries()
		.then( () => {
			if (this.componentIsInitialized) {
				let dataTable = this.googleCharts.createDataTable();
				dataTable.addColumn('string', 'Date');
				dataTable.addColumn('number', 'New Guests');
				dataTable.addColumn({type: 'string', role: 'annotation'});
				dataTable.addColumn('number', 'Repeat Guests');
				dataTable.addColumn({type: 'string', role: 'annotation'});
				dataTable.addColumn('number', 'Total Guests');
				dataTable.addColumn({type: 'string', role: 'annotation'});
				dataTable.addRows( this.visitChartData );
			
				var wrapper = this.googleCharts.createChartWrapper({
					chartType: 'LineChart',
					dataTable: dataTable,
					options: this.visitChartOptions,
					containerId: 'visitChart'
				});
				// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
				wrapper.draw();
			}
		})
		.catch( err => {
			Global.logError('Error drawing average time line chart.', err );
			throw new Error( 'Error drawing average time line chart.  ' +  err );
		});
	}

}
