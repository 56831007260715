import { Command } from "./Command";
import { Global } from "../service/Global";
import { Product } from "../service/Product";

export class GetProductsCmd extends Command {

	/**
	 * Get row with the key values of the given object.
	 * @param key: Object containing row key values.
	 */
	do(): Promise<Product[]> {
		return new Promise<Product[]>( (resolve, reject) => {
			super.send( this.constructor.name, null, null )
			.then( results => {
				// Global.log('GetProductsCmd results='+JSON.stringify(results,null,2));
				// Convert generic JSON result object back to real object
				let found: Product[] = [];
				if (results.data) {
					results.data.forEach( row => found.push( new Product().fromDataItem( row ) ) );
				}
				resolve( found );
			}).catch( err => reject( err ) );
		});
	}

}
