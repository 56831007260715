import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from "@angular/core";
import {HttpClient } from '@angular/common/http';
import {UserLoginService} from "../../service/user-login.service";
import {UUIDService} from "../../service/uuid.service";
import { S3Service } from '../../service/s3.service';
import { CacheService } from '../../service/cache.service';
import { Global } from '../../service/Global';
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';
// import 'request';

// declare var request: any;

@Component({
	selector: 'sample-camera',
	templateUrl: './sample-camera.html',
	styleUrls: ['./sample-camera.css'],
})
export class SampleCameraComponent implements OnInit, OnDestroy {
    private errorMessage: string = null;

	private hideCameraButton = true;

	@ViewChild(ImageCaptureComponent) private imageCaptureComponent: ImageCaptureComponent;

	// @ViewChild('ipCamImage') private ipCamImageRef: ElementRef; 
	private ipCamImage: HTMLImageElement;

	@ViewChild('ipCamCanvas') private ipCamCanvasRef: ElementRef; private ipCamCanvas: HTMLCanvasElement;

    constructor( 
		private changeDetectorRef: ChangeDetectorRef,
		private uuidService: UUIDService,
		private userService: UserLoginService, 
		private s3Service: S3Service,
		private cacheService: CacheService,
		private http: HttpClient,
	) 
	{}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.errorMessage = null;
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	private snapshotUri;
	private snapshotCounter = 0;
	private snapshotStartTime;
	private motionImage = new Image();
	
	ngAfterViewInit() {
		// Global.log('SampleCameraComponent.ngAfterViewInit player: '+this.player.clientWidth+'x'+this.player.clientHeight+', video: '+this.player.videoWidth+'x'+this.player.videoHeight+', window.screen: '+window.screen.width+'x'+window.screen.height );


		// let headers = new Headers({ 'Authorization': 'Basic ' +  btoa('a@b.com:password') });
		// let options = new RequestOptions({ headers: headers });
		// return this.http.get(Config.Api.GetNavbar, options).map((res: Response) => res.json());

		// let url = 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0';
		// // let url = 'http://admin:john1010@10.0.1.9:80/mjpeg/snap.cgi?chn=0';
		// let username: string = 'admin';
		// let password: string = 'john1010';
		// let headers: Headers = new Headers();
		// headers.append("Authorization", "Basic " + btoa(username + ":" + password)); 
		// headers.append("Content-Type", "application/x-www-form-urlencoded");
		// this.http.post(url, {}, {headers: headers})
		// .subscribe( result => {
		// 	console.log( 'Result of camera call is: '+JSON.stringify( result, null, 2 ) );
		// });

		// this.ipCamImage = this.ipCamImageRef.nativeElement;
		// this.ipCamCanvas = this.ipCamCanvasRef.nativeElement;

		// setInterval( () => { 
		// 	this.snapshotStartTime = Date.now();
		// 	this.ipCamImage = new Image( 640, 480 );
		// 	this.ipCamImage.onload = ( event ) => this.ipCamImageChanged( event );
		// 	this.ipCamImage.src = 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&date=' + new Date().toISOString();
		// 	// this.snapshotUri = 'http://admin:john1010@10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&counter='+(this.snapshotCounter++);
		// 	// this.snapshotUri = 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0' + '&date=' + new Date().toISOString();
		// }, 3000 );

/*
		// Download to a directory and save with an another filename
		let options = {
			//  url: 'http://admin:john1010@10.0.1.9:80/mjpeg/snap.cgi?chn=0',
			url: 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0',
			//  headers: { "Authorization" : "Basic " + new Buffer("admin:john1010").toString("base64") },
			headers: { 
				// "Authorization": 'Digest username="admin", realm="IPCamera Login", nonce="c9582ddb62b914a3ddacbf8c6b4f8b36", uri="/mjpeg/snap.cgi?chn=0", response="a620f6e1d8aa30d104d9a3719e954241", qop=auth, nc=00000001, cnonce="bf460e7fa86f2f56"',
				'Authorization': 'Digest username="admin", realm="IP Camera(83121)", nonce="5a6a51354e7a41345a5751365a544d324e44526a4e57493d", uri="/onvif-http/snapshot?Profile_1", response="9f2eafba2a3878e39f0e69009f78f712", qop=auth, nc=00000002, cnonce="7ea94f95a20d21c6"',
				// "crossOrigin": 'anonymous',
			},
			//  auth: { user: 'admin', password: 'john1010' },
			dest: '/Me/Temp/test.jpg'        // Save to /path/to/dest/photo.jpg
		};
		console.log('options='+JSON.stringify(options,null,2));
		request(options, (err, res, body) => {
			if (err) {
				console.log('err='+JSON.stringify(err,null,2));
			}
			console.log('b statusCode='+res.statusCode+', body.length='+body.length);
			if (body && res.statusCode === 200) {
				console.log('OK='+JSON.stringify(body,null,2));
			} else {
				console.log('d !body='+(!body));
				console.log('e body='+new Buffer(body).toString());
				if (!body) {
					console.log(`Image loading error - empty body. URL: ${options.url}`);
				} else {
					console.log(`Image loading error - ${res.statusCode}. URL: ${options.url}`);
				}
			}
		});
*/
/*		
		let headers = new Headers();
		//headers.append( "Authorization", 'Digest username="admin", realm="IPCamera Login", nonce="c9582ddb62b914a3ddacbf8c6b4f8b36", uri="/mjpeg/snap.cgi?chn=0", response="a620f6e1d8aa30d104d9a3719e954241", qop=auth, nc=00000001, cnonce="bf460e7fa86f2f56"' );
		headers.append('Authorization', 'Basic ' + btoa('admin:john1010') ); 
		let args: RequestOptionsArgs = {
			url: 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0',
			//  headers: { "Authorization" : "Basic " + new Buffer("admin:john1010").toString("base64") },
			// headers: { 
			// 	"Authorization": 'Digest username="admin", realm="IPCamera Login", nonce="c9582ddb62b914a3ddacbf8c6b4f8b36", uri="/mjpeg/snap.cgi?chn=0", response="a620f6e1d8aa30d104d9a3719e954241", qop=auth, nc=00000001, cnonce="bf460e7fa86f2f56"',
			// 	"crossOrigin": 'anonymous',
			// },
			headers: headers,
			//  auth: { user: 'admin', password: 'john1010' },
			//dest: '/Me/Temp/test.jpg'        // Save to /path/to/dest/photo.jpg
		};
		this.http.get( 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0', args )
		.subscribe(
		  // Successful responses call the first callback.
		  data => {
			console.log('Something went right! '+data+', '+JSON.stringify(data,null,2));
		},
		  // Errors will call this callback instead:
		  err => {
			console.log('Something went wrong! '+err+', '+JSON.stringify(err,null,2));
		  }
		);
*/
	// 'use strict'
	
	// const request = require('request')
	// const path = require('path')
	// const fs = require('fs')
	
	// onError(err, done) {
	//   if (done) {
	// 	return done(err)
	//   }
	//   throw err
	// }
	
	// const downloader = (options = {}) => {
	//   if (!options.url) {
	// 	throw new Error('The option url is required')
	//   }
	
	//   if (!options.dest) {
	// 	throw new Error('The option dest is required')
	//   }
	
	//   options = Object.assign({}, options)
	
	//   const done = options.done
	
	//   delete options.done
	//   options.encoding = null
	
	// console.log('options='+JSON.stringify(options,null,2));
	//   request(options, (err, res, body) => {
	// 	if (err) {
	// console.log('err='+JSON.stringify(err,null,2));
	// 	  return onError(err, done)
	// 	}
	// console.log('b statusCode='+res.statusCode+', body.length='+body.length);
	// 	if (body && res.statusCode === 200) {
	// console.log('c');
	// 	  if (!path.extname(options.dest)) {
	// 		options.dest = path.join(options.dest, path.basename(options.url))
	// 	  }
	
	// 	  fs.writeFile(options.dest, body, 'binary', (err) => {
	// 		if (err) {
	// console.log('err2='+JSON.stringify(err,null,2));
	// 		  return onError(err, done)
	// 		}
	
	// 		if (typeof done === 'function') {
	// 		  done(false, options.dest, body)
	// 		}
	// 	  })
	// 	} else {
	// console.log('d !body='+(!body));
	// console.log('e body='+new Buffer(body).toString());
	// 	  if (!body) {
	// 		return onError(new Error(`Image loading error - empty body. URL: ${options.url}`), done)
	// 	  }
	// 	  onError(new Error(`Image loading error - ${res.statusCode}. URL: ${options.url}`), done)
	// 	}
	//   })
	}
	
	// downloader.image = (options = {}) => new Promise((resolve, reject) => {
	//   options.done = (err, dest, body) => {
	// 	if (err) {
	// 	  return reject(err)
	// 	}
	// 	resolve({ filename: dest, image: body })
	//   }
	
	//   downloader(options)
	// })
	
	// module.exports = downloader

	// }

	// private ipCamImageChanged( event ) {
	// 	console.log( 'event='+JSON.stringify( event, null, 2 ) );
	// 	console.log('Image changed in '+(Date.now()-this.snapshotStartTime)+'ms., size='+this.ipCamImage.width+','+this.ipCamImage.height);
	// 	console.log( 'naturalWidth='+this.ipCamImage.naturalWidth+', naturalHeight='+this.ipCamImage.naturalHeight );
	// 	console.log( 'width='+this.ipCamImage.width+', height='+this.ipCamImage.height );
	// 	this.ipCamCanvas.width = this.ipCamImage.width;
	// 	this.ipCamCanvas.height = this.ipCamImage.height;
	// 	let context = this.ipCamCanvas.getContext('2d');
	// 	context.drawImage( this.ipCamImage, 0, 0, this.ipCamCanvas.width, this.ipCamCanvas.height );

	// }

	ngOnDestroy() {
		this.imageCaptureComponent.turnCameraOff();
	}

	initialize() {
		this.imageCaptureComponent.initialize( true )
		.then( userMediaSupported => {
			if (userMediaSupported) {
				this.imageCaptureComponent.turnCameraOn();
			}
		})
	}

	onCaptureBack() {
		this.errorMessage = null;
		this.hideCameraButton = true;
		this.captureImage(1,2,1);
	}

	onCaptureFront() {
		this.errorMessage = null;
		this.hideCameraButton = true;
		this.captureImage( 1,2,2);
	}

	onBackToCamera() {
		this.errorMessage = null;
		this.hideCameraButton = true;
		this.imageCaptureComponent.turnCameraOn();
	}

	/**
	 * Capture an image from the video stream and put it in S3 in the company/site/camera folder.
	 * @param companyId ID of the company.
	 * @param siteId ID of the site where the image was taken.
	 * @param cameraId ID of the camera that took the image.
	 */
	captureImage( companyId: number, siteId: number, cameraId: number ) {
		// Get image from camera
		this.imageCaptureComponent.captureImage()
		.then( dataURL => {
			// Upload image to S3 into a folder for the user's identified people
			// console.log( 'Captured image,  base 64 encoded length=', dataURL.length, ' bytes' );
			// console.log( 'First 60 bytes : ', dataURL.substr( 0, 60 ) );
			let base64DataString = dataURL.replace(/^data:image\/\w+;base64,/, "");
			let objectKey = Global.getGuestImageName( companyId, this.cacheService.properties[0].propertyId, siteId, cameraId );
			this.s3Service.upload( base64DataString, Global.visitPhotoBucketName, objectKey );
		})
		.catch( error => {
			Global.logError('Error capturing image.', error );
			alert('Error capturing image.');
		});
	}

}
