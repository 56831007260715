import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";
import { TimeInputModel } from "../../service/TimeInputModel";
import { IMyDpOptions } from 'mydatepicker';

/**
 * Component for editing dates when a site is closed.
 */
@Component({
    selector: 'closed',
	templateUrl: './closed.html',
	styleUrls: [ 'closed.css' ]
})
export class ClosedComponent extends EditorDetail<Date> implements OnInit, OnDestroy {

	@ViewChild('RestaurantClosedModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	private myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
	};
	
	// Data model used for date picker, initialized to today.
	private dateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Create a new row with default values. */
	createNewDefaultRow(): Date {
		return new Date();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	initializeDialog() {
		this.dateModel = null;
		if (this.editedRow) {
			this.dateModel = { jsdate: this.editedRow, date: { year: this.editedRow.getUTCFullYear(), month: this.editedRow.getUTCMonth()+1, day: this.editedRow.getUTCDate() } };
		}
	}

	/** Return a deep copy of the given object. */
	clone( row: Date ): Date {
		return new Date( row.getTime() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete closed date ' + this.originalRow.toLocaleDateString() + '?';
	}

	isValid(): boolean {
		this.errorMessage = null;
		if (!this.dateModel.jsdate) {
			this.errorMessage = 'Please enter a date.'
			return false;
		}
		this.editedRow = this.dateModel.jsdate;
		return true;
	}

}