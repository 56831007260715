import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import {UserLoginService} from "../../service/user-login.service";
import {UUIDService} from "../../service/uuid.service";
import { RekognitionService, MatchingFace } from '../../service/rekognition.service';
import { S3Service } from '../../service/s3.service';
import { CacheService } from '../../service/cache.service';
import { Person } from '../../service/Person';
import { PersonTable } from '../../service/PersonTable';
import { Global } from '../../service/Global';
import { FaceTracker } from '../../service/FaceTracker';
import { FaceDetector, TestImage } from '../../service/FaceDetector';
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';
import { PersonDetailComponent } from '../person-detail/person-detail.component';
import { TitleNavComponent } from "../title-nav/title-nav.component"
import * as Rekognition from "aws-sdk/clients/rekognition";

@Component({
	selector: 'recognize',
	templateUrl: './recognize.html',
	styleUrls: ['./recognize.css'],
})
export class RecognizeComponent implements OnInit, OnDestroy {
    private errorMessage: string = null;
    private successMessage: string = null;

	/** Max number of faces that can be identified in an image.  If you change this, change the HTML too. */
	private readonly maxFacesPerImage = 4;
	private hideCameraButton = true;
	private hideRecognizeButton = false;
	personTitle: string[] = [];
	personText: string[] = [];

	/** List of people identified in image. */
	private identifiedPersons: Person[] = [];

	@ViewChild(ImageCaptureComponent) private imageCaptureComponent: ImageCaptureComponent;

	@ViewChild(PersonDetailComponent) private personDetailComponent: PersonDetailComponent;

    constructor( 
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private uuidService: UUIDService,
		private rekognitionService: RekognitionService,
		private s3Service: S3Service,
		private cache: CacheService,
		private faceDetector: FaceDetector ) 
	{}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.errorMessage = null;
		this.successMessage = null;
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		// Global.log('RecognizeComponent.ngAfterViewInit player: '+this.player.clientWidth+'x'+this.player.clientHeight+', video: '+this.player.videoWidth+'x'+this.player.videoHeight+', window.screen: '+window.screen.width+'x'+window.screen.height );
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-camera fa-fw"></i> VIP Recognize' );
		this.imageCaptureComponent.initialize()
		.then( supported => this.imageCaptureComponent.turnCameraOn() )
		.catch( error => {
			Global.logError( 'Error initializing image capture.', error );
			alert( 'Problem initializing video.  Please try again.' );
		})
	}

	ngOnDestroy() {
		this.imageCaptureComponent.turnCameraOff();
	}

	initialize() {
	}

	recognize() {
		this.errorMessage = null;
		this.successMessage = null;
		this.hideRecognizeButton = true;
		this.hideCameraButton = true;
		this.captureImage();
	}

	showCamera() {
		this.errorMessage = null;
		this.successMessage = null;
		this.hideRecognizeButton = false;
		this.hideCameraButton = true;
		this.clearPersonData();
		this.imageCaptureComponent.turnCameraOn();
	}

	onEdit( index: number ) {
		// Make a copy of the person object for editing in case they cancel after making changes
		let person = this.identifiedPersons[index];
		let editedPerson = new Person().fromDataItem( person.toDataItem() );
		this.personDetailComponent.setPerson( editedPerson );
		this.personDetailComponent.showModalDialog( saved => {
			if (saved) {
				this.identifiedPersons[index] = editedPerson;
				// console.log( 'edited person='+JSON.stringify( editedPerson, null, 2));
				this.setPersonDataToDisplay(index, this.identifiedPersons[index]);
			}
		});
	}

	/**
	 * Capture an image from the video stream and put it in S3 in the company/site/camera folder.
	 * @param companyId ID of the company.
	 * @param siteId ID of the site where the image was taken.
	 * @param cameraId ID of the camera that took the image.
	 */
	captureImage() {
		// Get image from camera
		let dataURL = null;
		let browserDetectedFaceRectangles = null;
		let startTime = Date.now();
		let createCanvasTime = null;
		this.imageCaptureComponent.captureImage()
		.then( capturedDataURL => {
			dataURL = capturedDataURL;
		// 	return this.faceDetector.detectFaces( this.imageCaptureComponent.getCanvasElement(), this.cache.currentCompany.faceDetectScale, this.cache.currentCompany.faceDetectInitialScale, this.cache.currentCompany.faceDetectStepSize, this.cache.currentCompany.faceDetectEdgeDensity );
		// })
		// .then( rectangles => {
			// console.log( 'detectFaces in '+(Date.now()-createCanvasTime)+'ms., create canvas took '+(createCanvasTime-startTime)+'ms. found '+rectangles.length+' faces.');
			// browserDetectedFaceRectangles = rectangles;
			// Upload image to S3 into a folder for the user's identified people
			// console.log( 'Captured image,  base 64 encoded length=', dataURL.length, ' bytes' );
			// console.log( 'First 60 bytes : ', dataURL.substr( 0, 60 ) );
			let base64DataString = dataURL.replace(/^data:image\/\w+;base64,/, "");
			let objectKey = this.cache.currentUserId+'/'+ this.uuidService.UUID() + '.jpg';
			this.s3Service.upload( base64DataString, Global.recognizePhotoBucketName, objectKey )
			.then( () => {
				// this.faceDetector.addTestImage( new TestImage( Global.recognizePhotoBucketName, objectKey, dataURL, true ) );
				// this.faceDetector.testFaceDetection( this.cache.currentCompany.faceDetectScale );
				this.rekognitionService.indexFacesInS3ImageFile( Global.recognizePhotoBucketName, objectKey, Global.getRecognizeFaceCollectionName( this.cache.currentUserId ) )
				.then( data => {
					// successful response
					if (!data.FaceRecords || data.FaceRecords.length == 0) {
						this.errorMessage = 'No faces found in the picture.  Please try again.';
						this.hideCameraButton = false;
					} else {
						let promises: Promise<any>[] = [];
						for( let face of data.FaceRecords ) {
							promises.push( new Promise<Person>( (resolve, reject) => {
								// Find the person with the face or add a new person 
								let faceId = face.Face.FaceId;
								let companyId = this.cache.currentCompany.companyId;
								let collectionId = Global.getRecognizeFaceCollectionName( this.cache.currentUserId );
								new FaceTracker().getPersonIdForFaceId( companyId, faceId, objectKey, collectionId, face.Face.Confidence )
								.then( personFace => {
									// console.log( 'Found personFace: '+JSON.stringify( personFace, null, 2 ) );
									new PersonTable().get( new Person( companyId, collectionId, personFace.personId ) )
									.then( (person: Person) => {
										// console.log( 'Found person: '+JSON.stringify( person, null, 2 ) );
										if (!person) {
											// We didn't find an existing person record, so create a new one with the person ID
											person = new Person( companyId, collectionId, personFace.personId );
										}
										resolve( person );
									})
									.catch( err => {
										reject( new Error( 'Error getting person record: ' + err.message ) );
									});
								})
								.catch( err => {
									reject( new Error( 'Error finding person for face ID: ' + err.message ) );
								})
							}));
						}
						Promise.all( promises )
						.then( (people: Person[]) => { 
							// console.log( 'Promises for processing for all faces in image has resolved');
							this.clearPersonData();
							this.identifiedPersons = people;
							// Show the data for each person and draw a box around their face
							for(let i=0; i<people.length; i++) {
								let person = people[i];
								this.setPersonDataToDisplay(i, person);
								// Only display data for up to the number of people we have slots for in the HTML
								if (i==this.maxFacesPerImage) {
									break;
								}

								// Draw box around person's face and add person number if more than one person
                                this.drawBoxAroundFace( data.FaceRecords[i].FaceDetail.BoundingBox, people.length > 1, i+1 );
							}
							this.hideCameraButton = false;
						})
						.catch( reason => {
							Global.logError( 'Error finding people in image.', reason );
							this.errorMessage = 'Error finding people in image: ' + reason.message;
							this.hideCameraButton = false;
						});

					}
				})
				.catch( err => {
					Global.logError( 'Error indexing faces in image.', err );
					this.errorMessage = 'Sorry, we had a problem searching for faces in the picture.  Please try again.';
					this.hideCameraButton = false;
				});
			})
			.catch( err => {
				Global.logError( 'Error uploading image to S3.', err );
				this.errorMessage = 'Sorry, we had a problem uploading the picture.  Please try again.';
				this.hideCameraButton = false;
			});
		})
		.catch( err => {
			Global.logError( 'Error capturing image.', err );
			this.errorMessage = 'Sorry, we had a problem capturing the picture.  Please try again.';
			this.hideCameraButton = false;
		});
	}

    private drawBoxAroundFace(box: Rekognition.BoundingBox, showPersonNumber: boolean, personNumber: number) {
        let width = this.imageCaptureComponent.getImageWidth();
        let height = this.imageCaptureComponent.getImageHeight();
        var context = this.imageCaptureComponent.getContext();
        context.lineWidth = 1;
        context.strokeStyle = 'rgba(255, 255, 0, 1.0)';
        context.strokeRect(box.Left * width, box.Top * height, box.Width * width, box.Height * height);
        if (showPersonNumber) {
            // Fill triangle in lower left corner to show person number since there are multiple people
            context.fillStyle = 'rgba(255, 255, 0, 1.0)';
            context.beginPath();
            let x = box.Left * width;
            let y = (box.Top * height) + (box.Height * height);
            context.moveTo(x, y);
            context.lineTo(x, y - 36);
            context.lineTo(x + 36, y);
            context.fill();
            context.font = '18pt Helvetica';
            context.fillStyle = 'rgba( 0, 0, 0, 1.0)';
            context.fillText( personNumber.toString(), (box.Left * width) + 2, ((box.Top * height) + (box.Height * height)) - 2);
        }
    }

    private setPersonDataToDisplay(i: number, person: Person) {
        this.personTitle[i] = 'Unknown';
        this.personText[i] = '';
        if (person.cardHeading && person.cardHeading.trim().length > 0) {
            this.personTitle[i] = person.cardHeading;
        }
		if (this.identifiedPersons.length > 1) {
			// Add index to heading when there are multiple faces in the image
			this.personTitle[i] = (i + 1) + ': ' + this.personTitle[i];
		}
        if (person.cardText && person.cardText.trim().length > 0) {
            this.personText[i] = person.cardText;
        }
    }

	/** Clear the existing person information from the page. */
	private clearPersonData() {
        for (let i = 0; i < this.maxFacesPerImage; i++) {
            this.personTitle[i] = '';
            this.personText[i] = '';
        }
		this.identifiedPersons = [];
    }
}
