import {Injectable} from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as Polly from "aws-sdk/clients/polly";
import { AWSRequestor } from './AWSRequestor';

@Injectable()
export class PollyService {

    constructor() {
    }

	synthesizeSpeech( text: string ): Promise<any> {
		return new Promise( (resolve, reject) => {
			var params = {
				// LexiconNames: [ "example" ], 
				OutputFormat: "mp3", 
				// SampleRate: "8000", 
				Text: text, 
				// TextType: "text", 
				VoiceId: 'Joanna'
			};
			new AWSRequestor().send( new Polly().synthesizeSpeech( params ) )
			.then( data => resolve( data ) )
			.catch( err => reject( err ) );
		})
	}

}