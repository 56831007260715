import { TableDocumentObject } from './TableHandler'
import { ActivitySignUp } from "./ActivitySignUp";

/** List of people signed up for an activity on a specific date and time. */
export class ActivitySignUpList extends TableDocumentObject {

	/**
	 * @param companyId ID of company (partition key)
	 * @param propertyId ID of site where activity occurs (partition key)
	 * @param activityId ID of activity (sort key)
	 * @param activityDateTime Date/time of the activity.
	 * @param capacity Maximum number of people that can sign up.
	 * @param guests List of guests signed up.
	 * @param version Number used to prevent overwriting data when multiple users update at the same time.
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public activityId: number = null,
		public activityDateTime: Date = new Date(),
		public capacity: number = null,
		public guests: ActivitySignUp[] = [],
		public version: number = 1,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): ActivitySignUpList {
		this.copyPropertiesFromObject( item );
		// Copy nested schedules objects
		this.guests = null;
		if (item['guests']) {
			this.guests = [];
			for (let i=0; i<item['guests'].length; i++) {
				let guest = new ActivitySignUp().fromDataItem( item['guests'][i] );
				this.guests.push( guest );
			}
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		item['activityId_activityDateTime'] = this.activityId + '_' + this.activityDateTime.toISOString();
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy nested schedules objects
		item['guests'] = null;
		if (this.guests) {
			item['guests'] = [];
			for (let i=0; i<this.guests.length; i++) {
				item['guests'].push( this.guests[i].toDataItem() );
			}
		}
		
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"activityId_activityDateTime": this.activityId + '_' + this.activityDateTime.toISOString(), 
        };
	}
	
	/**
	 * Returns the number of people already on this sign up list.
	 */
	public getAvailableCapacity(): number {
		return this.capacity - this.getGuestCount();
	}

	/**
	 * Returns the number of people already on this sign up list.
	 */
	public getGuestCount(): number {
		let guestCount = 0;
		this.guests.forEach( guest => {
			guestCount += guest.persons;
		})
		return guestCount;
	}

	/**
	 * Returns the index of the guest information for the given room number or -1 if the room number is not on the list.
	 * @param roomNumber Room number to get.
	 */
	public getGuestInfoIndex( roomNumber: string ): number {
		let index = -1;
		for (let i=0; i<this.guests.length; i++) {
			if (this.guests[i].roomNumber === roomNumber) {
				index = i;
				break;
			}
		}
		return index;
	}

	/**
	 * Returns the guest information for the given room number or null if the room number is not on the list.
	 * @param roomNumber Room number to get.
	 */
	public getGuestInfo( roomNumber: string ): ActivitySignUp {
		let guestInfo: ActivitySignUp = null;
		let index = this.getGuestInfoIndex( roomNumber );
		if (index != -1) {
			guestInfo = this.guests[index];
		}
		return guestInfo;
	}

	/**
	 * Deletes the sign up for the given room number.
	 * @param roomNumber Room number whose sign up is to be deleted.
	 * @returns Sign up information that was deleted or null if room number was not found.
	 */
	public removeGuestInfo( roomNumber: string ): ActivitySignUp {
		let guestInfo: ActivitySignUp = null;
		let index = this.getGuestInfoIndex( roomNumber );
		if (index != -1) {
			guestInfo = this.guests[index];
			this.guests.splice( index, 1 );
		}
		return guestInfo;
	}

}
