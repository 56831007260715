import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/** Activity types for which guests may sign up. */
export class ActivityType extends TableDocumentObject {

	/**
	 * @param companyId ID of event (partition key)
	 * @param propertyId ID of property where activity occurs (partition key)
	 * @param activityTypeId ID of the activity type (sort key)
	 * @param name Name used by Room Genie app to sign up for activity such as 'snorkelling'
	 * @param synonyms: List of synonyms that users might use when referring to this activity type
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public activityTypeId: number = null,
		public name: string = null,
		public synonyms: string[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): ActivityType {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"activityTypeId": this.activityTypeId, 
        };
    }

	getSynonyms(): string {
		let s = '';
		if (this.synonyms) {
			this.synonyms.forEach( synonym => {
				if (s.length > 0) {
					s += ', ';
				}
				s += synonym;
			})
		}
		return s;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		name = name.toLowerCase();
		if (this.name.toLowerCase() == name) {
			valid = true;
		} else {
			valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
		}
		return valid;
	}

	public static findMatchingActivityType( activityTypeName: string, list: ActivityType[] ): ActivityType {
		let found: ActivityType = null;
		activityTypeName = activityTypeName.toLowerCase();
		for (let i=0; i<list.length; i++) {
			if (list[i].hasNameOrSynonym( activityTypeName )) {
				found = list[i];
				break;
			}
		}
		return found;
	}

	/**
	 * Returns an array of the names of the activity types in the given list.
	 * @param list List of activity types.
	 */
	public static getNames( list: ActivityType[] ) {
		let names: string[] = [];
		for (let i=0; i<list.length; i++) {
			names.push( list[i].name );
		}
		return names;
	}

}
