import { Component, OnInit } from "@angular/core";
import { Global } from "../../service/Global";
import { CacheService } from "../../service/cache.service";

@Component({
    selector: 'secure-articles',
    template: '<articles></articles>'
})
export class SecureArticlesComponent implements OnInit {

	constructor( 
		private cache: CacheService,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-book fa-fw"></i> Articles' );
	}

}
