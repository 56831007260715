import { Injectable } from "@angular/core";
import { Router} from "@angular/router";
import { CognitoCallback, CognitoUtil, LoggedInCallback } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as STS from "aws-sdk/clients/sts";
import { CacheService } from "./cache.service";
import { Global } from "./Global";
import { GetUserDataCmd } from "../command/GetUserDataCmd";

interface GetUserAttributesResults {
	userId: string;
	tokenExpiration: Date;
	email: string;
	nickname: string;
}

@Injectable()
export class UserLoginService {

    constructor(
		private router: Router, 
        private cognitoUtil: CognitoUtil, 
        private cache: CacheService
    ) {}

    verifyUserAndPassword(username: string, password: string ): Promise<any> {
        Global.log("UserLoginService.verifyUserAndPassword for " + username );
        return new Promise<any>( (resolve, reject) => {
            let authenticationData = {
                Username: username,
                Password: password,
            };
            let authenticationDetails = new AuthenticationDetails(authenticationData);
            let userData = {
                Username: username,
                Pool: this.cognitoUtil.getUserPool()
            };
            let cognitoUser = new CognitoUser(userData);
            // console.log("UserLoginService: config is " + AWS.config);
			var self = this;
            cognitoUser.authenticateUser(authenticationDetails, {
                newPasswordRequired: (userAttributes, requiredAttributes) => {
					Global.log( `User ' + username + ' needs to set password.` );
                    reject( new Error( `User ' + username + ' needs to set password.` ) );
                },
                onSuccess: (session) => {
					Global.log( 'User and password verified' );
					this.cache.set('lvu',username);
					this.cache.set('lvp',password);
                    resolve( session );
                },
                onFailure: (err) => {
                    Global.logError( 'Error authenticating ' +username+'.  ', err );
					// reject( new Error( 'Error validating username ('+username+').  ' + err.message ) );
					// We can't replace the error message bcause the LoginComponent checks for the original error message.
                    reject( err );
				},
				// The following 2 callbacks are required in the Cognito SDK 1.15 but not in 1.19
				mfaRequired: (challengeName: any, challengeParameters: any) => {
					let err = 'Error validating username ('+username+') and password.  MFA required';
                    Global.log( err );
                    reject( new Error( err ) );
				}, 
				customChallenge: (challengeParameters: any) => {
					let err = 'Error validating username ('+username+') and password.  Custom challenge required';
                    Global.log( err );
                    reject( new Error( err ) );
				}
			});
        })
    }

    authenticate(email: string, password: string ): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            this.verifyUserAndPassword( email, password )
            .then( session => {
				// Clear the credentials of the LogWriter user that are used when nobody was logged in
				AWS.config.accessKeyId = undefined;
				AWS.config.secretAccessKey = undefined;
				AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());
				
                // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
                // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
                // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
                // security credentials. The identity is then injected directly into the credentials object.
                // If the first SDK call we make wants to use our IdentityID, we have a
                // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
                // very innocuous API call that forces this behavior.
                // We can ignore the 400 (Bad request) result of making this call since we don't specify any parameters.
                let sts = new STS();
                sts.getCallerIdentity( null, (err, data) => {
                    // console.log("UserLoginService: Successfully set the AWS credentials");
                    // resolve( session );
					Global.log('User authenticate succeeded');
					// Set up current user information in cache service
					this.setUpCurrentUser()
					.then( () => {
						resolve();
					})
					.catch( err => {
						Global.logError( 'Error setting up current user: ' + email, err );
						this.logout();
						reject( err );
					});
                });

            }).catch( err => {
				Global.logError( 'Error verifying user and password.', err );
                reject( err );
            });
        });
    }

    forgotPassword(username: string, callback: CognitoCallback) {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function () {

            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback(null, null);
            }
        });
    }

    confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
        let userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback(null, null);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    logout() {
		Global.log( 'Log out user ' + this.cache.currentUserId + ' with email ' + this.cache.currentUserEmail );
		let cognitoUser = this.cognitoUtil.getCurrentUser();
		if (cognitoUser) {
			cognitoUser.signOut();
			Global.waitForAllLoggingToFinish( true )
			.then( () => {
				Global.setRemoteLogger( 'guest' );
				this.cognitoUtil.configureAWSLoggingOnly();
				// Clear cached user info
				this.cache.companies = null;
				this.cache.currentCompany = null;
				this.cache.properties = [];
				this.cache.currentRole = null;
				this.cache.currentUserId = null;
				this.cache.currentUserTokenExpiration = null;
				this.cache.currentUserNickname = null;
				this.cache.currentUserEmail = null;

			});
		}
	}

    checkLoggedIn( callbackIfLoggedIn: () => void ) {
		try {
			this.isAuthenticated( (message, loggedIn) => {
				try {
					if (!loggedIn) {
						this.router.navigate(['/home/login']);
					} else if (callbackIfLoggedIn) {
						// console.log( 'user is logged in, currentUserId='+this.cache.currentUserId);
						callbackIfLoggedIn();
						return false;
					}
				} catch( error ) {
					Global.logError( "checkLoggedIn error", error );
					alert( 'Error processing login status, please try again later.' );
				}
			} );
		} catch( error ) {
			Global.logError( "checkLoggedIn error", error );
			alert( 'Error checking login status, please try again later.' );
		}
	}

	private savedLoggedInCallbacks2: ((message: string, loggedIn: boolean) => boolean)[] = null;

    isAuthenticated(callback: (message: string, loggedIn: boolean) => boolean) {
		// console.log( 'isAuthenticated2 stack='+new Error().stack );
        if (callback == null)
            throw("UserLoginService: callback in isAuthenticated() cannot be null");

		if (this.savedLoggedInCallbacks2) {
			// Someone called isAuthenticated again while we are still looking up the current user.
			// Save their callback to be called when we finish.
			// console.log( 'isAuthenticated2 saving callback '+callback.toString() );
			this.savedLoggedInCallbacks2.push( callback );
		} else {
			// Set flag that we are working on this to prevent multiple calls as components are constructed
			// console.log( 'isAuthenticated2 saving callback '+callback.toString() );
			this.savedLoggedInCallbacks2 = [ callback ];

			let cognitoUser = this.cognitoUtil.getCurrentUser();
			if (!cognitoUser) {
				// console.log("UserLoginService: Can't get current user");
				this.callCallbackFunctions2("Can't get current user", false);
			} else {
				// console.log("UserLoginService: Got current user");
				cognitoUser.getSession( (err, session) => {
					if (err) {
						Global.logError("UserLoginService: Can't get user session.", err );
						this.callCallbackFunctions2( err, false );
					} else {
						if (session.isValid) {
							// Set AWS credentials since they may have expired and just been refreshed
							let creds = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());
							// Clear the credentials of the LogWriter user that are used when nobody was logged in
							AWS.config.accessKeyId = undefined;
							AWS.config.secretAccessKey = undefined;
							AWS.config.credentials = creds;
						} else {
							Global.log("UserLoginService:  Session is not valid." );
							console.log( 'SESSION IS NOT VALID, WHY DO WE CHECK IT THEN IGNORE IT?');
						}

						if (this.cache.currentUserEmail /*|| this.settingUpCurrentUser*/) {
							// Current user is already set in cache, we are probably just loading a new component
							// or we are already working on setting up the current user so don't call it again.
							// Call callback function with TRUE if session is valid and there is at least 30 seconds left before token expires
							this.callCallbackFunctions2( null, session.isValid() );
						} else {
							// Set up current user information in cache service
							// this.setUpCurrentUser()
							this.getCognitoUserAttributes( session /*cognitoUser*/ )
							.then( userAttributes => {
								this.setCurrentUserAttributes( userAttributes.userId, userAttributes.nickname, userAttributes.email, userAttributes.tokenExpiration, null )
								.then( () => {
									//console.log( 'IdToken2='+JSON.parse( session.getIdToken() ).exp );
									// Call callback function with TRUE if session is valid and there is at least 30 seconds left before token expires
									this.callCallbackFunctions2( null, session.isValid() );
									// callback.isLoggedIn( null, session.isValid() );
								}).catch( err => {
									Global.logError( 'Error setting current user attributes:', err );
									this.callCallbackFunctions2( err, false );
									// callback.isLoggedIn( err.message, false);
								});
							})
							.catch( err => {
								Global.logError( 'Error getting cognito user attributes.', err );
								this.callCallbackFunctions2( err, false );
							});
						}
					}
				});
			}
		}
    }

	private callCallbackFunctions2( err, data ) {
		let logOut = false;
		for (let i=0; i<this.savedLoggedInCallbacks2.length && !logOut; i++) {
			let savedCallback = this.savedLoggedInCallbacks2[i];
			logOut = savedCallback( err, data );
		}
		this.savedLoggedInCallbacks2 = null;
		if (logOut) {
			// The last callback we called wants to log the user out so do it.
			this.logout();
			this.router.navigate(['/home/login']);
		}
	}

	/**
	 * Gets the user attributes from the ID token in the user session.
	 * @param session Current user session.
	 */
	public getCognitoUserAttributes( session: CognitoUserSession ): Promise<GetUserAttributesResults> {
        return new Promise<GetUserAttributesResults>( (resolve, reject) => {
			// Get user attributes
			// Global.log( 'session='+JSON.stringify(session,null,2)+', typeof(session)='+typeof(session));
			let tokenString = session.getIdToken().getJwtToken();
			let payload = tokenString.split('.')[1];
			// let decodedPayload = JSON.parse(util.base64.decode(payload).toString('utf8'));
			let decodedPayload = atob( payload );
			let token = JSON.parse( decodedPayload );
			// Global.log( 'token='+JSON.stringify(token) );
			let foundEmail: string = token.email;
			let foundUserId: string = token.sub;
			if (foundEmail && foundUserId) {
				let tokenExpiration = new Date( token.exp*1000 );
				// console.log('It is now '+(new Date().toISOString())+', token expires '+tokenExpiration.toISOString() );
				let results = {
					userId: token.sub,
					email: token.email,
					tokenExpiration: tokenExpiration,
					nickname: token.nickname
				}
				resolve( results );
			} else {
				reject( new Error( 'UserLoginService.getCognitoUserAttributes: Failed to find sub and email in user attributes.' ) );
			}
		});
	}

	public getUserAttributes(): Promise<GetUserAttributesResults> {
        return new Promise<GetUserAttributesResults>( (resolve, reject) => {
			// Get user attributes
			let startTime = Date.now();
			let cognitoUser = this.cognitoUtil.getCurrentUser();
			if (cognitoUser != null) {
				cognitoUser.getSession( (err, session) => {
					if (err) {
						err.message = 'UserLoginService.getUserAttributes: Couldn\'t get user session. ' + err.message;
						reject( err );
					} else {
						this.getCognitoUserAttributes( session /*cognitoUser*/ )
						.then( (userAttributes: GetUserAttributesResults) => {
							resolve( userAttributes );
						})
						.catch( err => {
							reject( err );
						});
					}
				});
			}
		});
	}

	/**
	 * Get user information, like the company they belong to, and save it in the cache.
	 * @param email Email address used as user ID.
	 */
    setUpCurrentUser(): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
			// Get user attributes
			this.getUserAttributes()
			.then( (userAttributes: GetUserAttributesResults) => {
				this.setCurrentUserAttributes( userAttributes.userId, userAttributes.nickname, userAttributes.email, userAttributes.tokenExpiration, null )
				.then( () => resolve() )
				.catch( err => reject( err ) );
			})
			.catch( err => reject( err ) );
        });
    }

	/**
	 * Get user information, like the company they belong to, and save it in the cache.
	 * @param email Email address used as user ID.
	 */
    setCurrentUserAttributes( userId: string, nickname: string, email: string, tokenExpiration: Date, selectedCompanyId: number ): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
			let startTime = Date.now();
			new GetUserDataCmd().do( userId, nickname, email, selectedCompanyId )
			.then( data => {
				// console.log('Got user data in '+(Date.now()-startTime)+'.ms '+(JSON.stringify(data,null,2)));
				this.cache.companies = data.companies;
				this.cache.currentCompany = data.companies[0];
				this.cache.properties = data.properties;
				this.cache.currentRole = data.role;
				this.cache.currentUserId = userId;
				this.cache.currentUserTokenExpiration = tokenExpiration;
				this.cache.currentUserNickname = nickname;
				this.cache.currentUserEmail = email;
				if (data.companies.length == 1) {
					// A single company was returned, set the logger to the company's log
					Global.setRemoteLogger( 'company-'+this.cache.currentCompany.companyId+'-browser' );
				}
				resolve();
			})
			.catch( error => reject( new Error( Global.buildErrorMessage( 'Error getting company data for user ' + userId + ' with email ' + email, error ) ) ) );
        });
    }

}
