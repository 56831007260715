import { TableHandler } from './TableHandler'
import { Person } from './Person';

export class PersonTable extends TableHandler<Person> {

    public constructor() {
		super( 'bookcliffsoftware-Person' );
	}
	
	public fromDataItem( item: any ): Person {
		return new Person().fromDataItem( item );
	}

}
