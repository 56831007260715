import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/** Activities for which guests may sign up. */
export class HousekeepingLogEntry extends TableDocumentObject {

	/**
	 * @param companyId ID of event (partition key)
	 * @param propertyId ID of property where HousekeepingLog occurs (partition key)
	 * @param roomNumber Room number where housekeeping took place (sortKey)
	 * @param startTime Time housekeeping started (sortKey)
	 * @param endTime Time housekeeping finished
	 */
	constructor(
		/* NOTE: If you change this list of properties, you must change fromDataItem() and toDateItem(). */
		public companyId: number = null,
		public propertyId: number = null,
		public roomNumber: string = null,
		public startTime: Date = new Date(),
		public endTime: Date = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): HousekeepingLogEntry {
		this.copyPropertiesFromObject( item );
		this.copyDatePropertyFromObject( 'startTime', item );
		this.copyDatePropertyFromObject( 'endTime', item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		item['roomNumber_startTime'] = this.roomNumber + '_' + (this.startTime != null ? this.startTime.toISOString() : '');
		item['startTime_roomNumber'] = (this.startTime != null ? this.startTime.toISOString() : null) + '_' + this.roomNumber;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		this.copyDatePropertyToObject( 'startTime', item );
		this.copyDatePropertyToObject( 'endTime', item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"roomNumber_startTime": this.roomNumber + '_' + (this.startTime != null ? this.startTime.toISOString() : null),
        };
    }

}
