import { Command } from "./Command";
import { Company } from "../service/Company";
import { Property } from "../service/Property";
import { Role } from "../service/Role";
import { GetUserDataResponse } from "../service/GetUserDataResponse";

export class GetUserDataCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 * @param propertyId Property ID
	 */
	do( userId: string, nickname: string, email: string, selectedCompanyId: number ): Promise<GetUserDataResponse> {
		return new Promise<GetUserDataResponse>( (resolve, reject) => {
			super.send( this.constructor.name, null, { "userId": userId, "nickname": nickname, "email": email, "selectedCompanyId": selectedCompanyId } )
			.then( results => {
				// Convert generic JSON result object back to our objects
				// console.log('GetUserDataCmd returned '+JSON.stringify(results.data,null,2));
				let companies: Company[] = [];
				results.data.companies.forEach( item => companies.push( new Company().fromDataItem( item ) ) );
				let properties: Property[] = [];
				results.data.properties.forEach( item => properties.push( new Property().fromDataItem( item ) ) );
				let role = null;
				if (results.data.role) {
					role = new Role().fromDataItem( results.data.role );
				}
				resolve( new GetUserDataResponse( companies, properties, role ) );
			}).catch( err => reject( err ) );
		});
	}

}
