import { TableDocumentObject } from './TableHandler'
import { DailyUsage } from './DailyUsage';
import { RoomUsage } from './RoomUsage';

/**
 * List or room uses and daily uses for reporting.
 */
export class RoomAndDailyUsage extends TableDocumentObject {

	/**
	 * List or room uses and daily uses for reporting.
	 * @param roomUsages List of room uses.
	 * @param dailyUsages List of daily uses.
	 */
	constructor(
		public roomUsages: RoomUsage[] = [],
		public dailyUsages: DailyUsage[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): RoomAndDailyUsage {
		// Copy list of objects
		this.roomUsages = [];
		if (item['roomUsages']) {
			item['roomUsages'].forEach( childItem => this.roomUsages.push( new RoomUsage().fromDataItem( childItem ) ) );
		}
		this.dailyUsages = [];
		if (item['dailyUsages']) {
			item['dailyUsages'].forEach( childItem => this.dailyUsages.push( new DailyUsage().fromDataItem( childItem ) ) );
		}
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Copy list of objects
		item['roomUsages'] = [];
		if (this.roomUsages) {
			this.roomUsages.forEach( childItem => item['roomUsages'].push( childItem.toDataItem() ) );
		}
		item['dailyUsages'] = [];
		if (this.dailyUsages) {
			this.dailyUsages.forEach( childItem => item['dailyUsages'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

}
