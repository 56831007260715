import { Command } from "./Command";
import { Feedback } from "../service/Feedback";

export class GetFeedbackDateRangeCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number, propertyId: number, startTime: Date, endTime: Date ): Promise<Feedback[]> {
		return new Promise<Feedback[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId, "startTime": startTime.toISOString(), "endTime": endTime.toISOString() } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: Feedback[] = [];
				results.data.forEach( layout => rows.push( new Feedback().fromDataItem( layout ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
