import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/** Feedback sent by guests. */
export class Feedback extends TableDocumentObject {

	/**
	 * @param companyId ID of company where feedback was given (partition key)
	 * @param propertyId ID of property where feedback was given (partition key)
	 * @param roomNumber Room number where feedback was given (sortKey)
	 * @param feedbackTime Time feedback was given (sortKey)
	 * @param department Department to which feedback was sent
	 * @param feedback Text of the feedback.
	 */
	constructor(
		/* NOTE: If you change this list of properties, you must change fromDataItem() and toDateItem(). */
		public companyId: number = null,
		public propertyId: number = null,
		public roomNumber: string = null,
		public feedbackTime: Date = new Date(),
		public department: string = null,
		public feedback: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Feedback {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		item['roomNumber_feedbackTime'] = this.roomNumber + '_' + (this.feedbackTime != null ? this.feedbackTime.toISOString() : '');
		item['feedbackTime_roomNumber'] = (this.feedbackTime != null ? this.feedbackTime.toISOString() : null) + '_' + this.roomNumber;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"roomNumber_feedbackTime": this.roomNumber + '_' + (this.feedbackTime != null ? this.feedbackTime.toISOString() : null),
        };
    }

}
