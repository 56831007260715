import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { Feedback } from "../../service/Feedback";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Property } from '../../service/Property';
import { IMyDpOptions } from 'mydatepicker';
import { GetFeedbackDateRangeCmd } from '../../command/GetFeedbackDateRangeCmd';

@Component({
    selector: 'feedback-list',
    templateUrl: './feedback-list.html',
	styleUrls: ['./feedback-list.css'],
})
export class FeedbackListComponent implements OnInit, AfterViewInit {

	/** List of rows that can be edited. */
	private rows: Feedback[] = [];

	/** Error message displayed to the user. */
	private errorMessage: string;

	/** Currently selected property. */
	private propertyId;

	/** Minutes to adjust UTC times for property time zone. */
	private timeZone: string = null;

	/** Date to display. */
	private displayDate: Date = new Date();

	// Data model used for date picker, initialized to today.
	private displayDateModel: any = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	private myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
	};

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-clipboard fa-fw"></i> Guest Feedback' );
	}

	initialize() {
		// console.log('HK initialize this.cache.properties.length='+this.cache.properties.length);
		this.displayDate = new Date();
		this.displayDateModel = { jsdate: this.displayDate, date: { year: this.displayDate.getUTCFullYear(), month: this.displayDate.getUTCMonth()+1, day: this.displayDate.getUTCDate() } };
		if (this.cache.properties.length > 0) {
			// Set selected property to the first one in the list
			let property = this.cache.properties[0];
			this.propertyId = property.propertyId;
			this.timeZone = property.timeZone;
			// console.log('HK initialize propertyId='+this.propertyId);

			// Adjust display date to display current date in property's time zone
			this.displayDate = Global.adjustDateByTimeZone( new Date(), this.timeZone );
			this.displayDateModel = { jsdate: this.displayDate, date: { year: this.displayDate.getUTCFullYear(), month: this.displayDate.getUTCMonth()+1, day: this.displayDate.getUTCDate() } };
			// console.log( 'Date/time in property\'s time zone: '+this.displayDate.toISOString());
			this.switchProperty( null );
		}
	}

	private switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		// Get timeZone offset for the property
		this.timeZone = this.cache.getTimeZone( this.propertyId );
		this.loadRows();
	}

	private loadRows() {
		
		let adjustedDate = Global.adjustDateByTimeZone( this.displayDate, this.timeZone );
		let startDate = new Date( this.displayDate.getTime() );
		startDate.setUTCHours( 0, 0, 0, 0 );
		startDate = Global.adjustDateByTimeZone( startDate, this.timeZone, false );
		let endDate = new Date( this.displayDate.getTime() );
		endDate.setUTCHours( 23, 59, 59, 999 );
		endDate = Global.adjustDateByTimeZone( endDate, this.timeZone, false );
		new GetFeedbackDateRangeCmd().do( this.cache.currentCompany.companyId, this.propertyId, startDate, endDate )
		.then( rows => {
			// console.log( 'loadRows between '+startDate.toISOString()+' and '+endDate.toISOString()+': '+JSON.stringify(rows,null,2));
			this.rows = rows;
			this.changeDetectorRef.detectChanges();
		})
		.catch( error => {
			Global.logError('Error loading data.', error );
			this.errorMessage = 'Sorry, we had a problem loading the data.  Please try again.';
		})
	}

	private switchDisplayDate( event ) {
		if (this.cache.properties.length > 0 && event.jsdate) {
			// Properties have been initialized, user must have changed date
			this.displayDate = new Date( event.jsdate.getTime() );
			this.loadRows();
		}
	}

	getDateTimeString( date: Date ): string {
		if (date) {
			let adjustedDate = Global.adjustDateByTimeZone( date, this.timeZone );
			// console.log( 'date '+date.toISOString()+' adjusted to '+this.timeZone+': '+adjustedDate.toISOString());
			var options = { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
			return adjustedDate.toLocaleTimeString( undefined, options );
		} else {
			return '--:--';
		}
	}

}
