import { Global } from './Global'
import { TableDocumentObject } from './TableHandler'

/** Subcategories for an advertising category.  Ie. The dinner category has subcategories like pizza, italian, etc. */
export class AdSubcategory extends TableDocumentObject {
	
	// Events subcategory ID
	public static readonly EVENTS_SUBCATEGORY_ID = 0;

	/**
	 * @param subcategoryId Unique ID number for this subcategory (default is null).
	 * @param name Name of the subcategory (default is null).
	 * @param synonyms List of synonyms that users might use when referring to this category
	 */
	constructor(
		public subcategoryId: number = null,
		public name: string = null,
		public synonyms: string[] = [],
	) { super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): AdSubcategory {
		this.copyPropertiesFromObject( item );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		if (name) {
			name = name.toLowerCase();
			if (this.name.toLowerCase() == name) {
				valid = true;
			} else if (this.synonyms) {
				valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
			}
		}
		return valid;
	}

}
