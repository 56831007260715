import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { Item } from './Item';
import { ItemCategory } from './ItemCategory';

/** 
 * Menu of items that can be ordered such as room service items or toiletries.
 */
export class ItemMenu extends TableDocumentObject {

	/**
	 * @param name Menu name
	 * @param itemCategories Available item categories
	 */
	constructor(
		public name: string = null,
		public itemCategories: ItemCategory[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): ItemMenu {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.itemCategories = [];
		if (item['itemCategories']) {
			item['itemCategories'].forEach( childItem => this.itemCategories.push( new ItemCategory().fromDataItem( childItem ) ) );
		}
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['itemCategories'] = [];
		if (this.itemCategories) {
			this.itemCategories.forEach( childItem => item['itemCategories'].push( childItem.toDataItem() ) );
		}
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

	public getItemByName( name: string ): Item {
		let found: Item = null;
		name = name.toLowerCase();
		for (let c=0; c<this.itemCategories.length; c++) {
			let category = this.itemCategories[c];
			for (let i=0; i<category.items.length; i++) {
				let item = category.items[i];
				// Global.log('Checking '+name+' against menu item '+JSON.stringify(this.items[i],null,2));
				if (item.hasNameOrSynonym( name )) {
					found = item;
					// Global.log('Found it!');
					break;
				}
			}
		}
		return found;
	}

	public indexOfCategoryName( name: string ): number {
		let found: number = -1;
		name = name.toLowerCase();
		for (let i=0; i<this.itemCategories.length; i++) {
			// Global.log('Checking '+name+' against menu item '+JSON.stringify(this.items[i],null,2));
			if (this.itemCategories[i].hasNameOrSynonym( name )) {
				found = i;
				// Global.log('Found it!');
				break;
			}
		}
		return found;
	}

	/**
	 * Returns an array of the names of the items in the given list so Alexa can say the list.
	 * @param list List of topics.
	 */
	public getCategoryNames() {
		let names: string[] = [];
		this.itemCategories.forEach( category => {
			// Add category to list if there are any items in it
			if (category.items.length > 0) {
				names.push( category.name );
			}
		});
		return names;
	}

	/**
	 * Returns an array of the names of the items in the given list so Alexa can say the list.
	 * @param list List of topics.
	 */
	public getItemNames() {
		let names: string[] = [];
		this.itemCategories.forEach( category => {
			category.items.forEach( item => names.push( item.name ) );
		});
		return names;
	}

	/**
	 * Returns an array of the names of the items in the given list so Alexa can say the list.
	 * @param list List of topics.
	 */
	public getItemNamesInCategory( category: ItemCategory ) {
		let names: string[] = [];
		category.items.forEach( item => {
			names.push( item.name );
		});
		return names;
	}

}
