import { Global } from "../service/Global";
import { Command } from "./Command";
import { RestaurantReservation } from "../service/RestaurantReservation";
import { RestaurantReservationList } from "../service/RestaurantReservationList";
import { RestaurantReservationResponse } from "../service/RestaurantReservationResponse";
import { RestaurantTableTop } from "../service/RestaurantTableTop";

export class MakeRestaurantReservationCmd extends Command {

	/**
	 * Check the availability of the given reservation.
	 * @param Key Row with key field values set.
	 * @param reservationListEndTime Date/time the seating ends
	 * @param layoutId ID of the layout used for the seating
	 * @param intervalMinutes Number of minutes between valid reservation times
	 * @param reservation Reservation to check
	 * @param oldReservation If not null, old reservation being updated.
	 * @param reserveIfAvailable True if table should be reserved if available
	 */
	do( reservationListKey: RestaurantReservationList, reservationListEndTime: Date, layoutId: number, intervalMinutes: number, reservation: RestaurantReservation, oldReservation: RestaurantReservation, reserveIfAvailable: boolean ): Promise<RestaurantReservationResponse> {
		return new Promise<RestaurantReservationResponse>( (resolve, reject) => {
			var parameters = {
				"reservationListKey": reservationListKey.toDataItem(),
				"reservationListEndTime": reservationListEndTime.toISOString(),
				"layoutId": layoutId,
				"intervalMinutes": intervalMinutes,
				"reservation": reservation.toDataItem(),
				"oldReservation": (oldReservation ? oldReservation.toDataItem() : null),
				"reserveIfAvailable": reserveIfAvailable,
			};
			super.send( this.constructor.name, reservationListKey.companyId, parameters )
			.then( results => {
				// console.log( 'RestaurantReservationCheckAvailabilityCmd results='+JSON.stringify(results.data,null,2));
				let response = new RestaurantReservationResponse(
					results.data.isSeatingOpen, 
					results.data.reservationList ? new RestaurantReservationList().fromDataItem( results.data.reservationList ) : null,
					results.data.tableTop ? new RestaurantTableTop( results.data.tableTop.sectionId, results.data.tableTop.tableId, results.data.tableTop.seats ) : null,
					Global.getDateFromString( results.data.startTime ),
					Global.getDateFromString( results.data.endTime ),
					Global.getDateFromString( results.data.alternateEarlierTime ),
					Global.getDateFromString( results.data.alternateLaterTime ),
				)
				resolve( response );
			})
			.catch( err => reject( err ) );
		});
	}
}
