import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";

/** Displays privacy policy. */
@Component({
    selector: 'privacy',
    templateUrl: './privacy.html',
    styleUrls: ['./privacy.css'],
})
export class PrivacyComponent {

}
