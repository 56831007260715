import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { RestaurantTableTop } from "../../service/RestaurantTableTop";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { DateTimeComponent } from "../datetime/datetime.component";
import { ConfirmComponent } from "../confirm/confirm.component";

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'restaurant-tabletop',
	templateUrl: './restaurant-tabletop.html',
	styleUrls: [ 'restaurant-tabletop.css' ]
})
export class RestaurantTableTopComponent implements OnInit, OnDestroy {
    editedRow: RestaurantTableTop = new RestaurantTableTop();

	/** Copy of the original row that was passed in for editing.  Useful to get the original primary key values of the row to update. */
	originalRow: RestaurantTableTop;

	errorMessage: string = null;
	private resolveModal: (action: string) => void;
	private rejectModal: (error: Error) => void;
	
	/** True if this record will be inserted and the delete icon is not shown. */
	private isInsert: boolean;

	@ViewChild('RestaurantTableTopModalButton') private modalButtonRef: ElementRef; private modalButton: HTMLButtonElement;
	
	@ViewChild('focus') private focusRef: ElementRef; private focusElement: HTMLInputElement;
		
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
    )
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/**
	 * Displays modal dialog to edit object.
	 * @param row Object to edit.
	 * @return Promise with user action used to dismiss dialog (insert, update, delete, or cancel).
	 */
	public showModalDialog( row: RestaurantTableTop, isInsert: boolean ): Promise<string> {
		this.errorMessage = null;
		this.isInsert = isInsert;
		return new Promise<string>( (resolve,reject) => {
			if (!row) {
				reject( new Error( 'No edit object provided.' ) );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Make a copy of object so we can restore the original values if user cancels.
				this.originalRow = row;
				this.editedRow = new RestaurantTableTop().fromDataItem( row.toDataItem() );
				// Show the dialog
				this.modalButton.click();
				this.changeDetectorRef.detectChanges();
				// Put keyboard focus on heading field after dialog is displayed
				setTimeout( () => this.focusElement.focus(), 1000 );
			}
		});
	}

	/** 
	 * Return a copy of the original row that was created when a row is passed in for editing.
	 * Can be used to get the old primary key values of the row to update. 
	 */
	public getOriginalRow(): RestaurantTableTop {
		return this.originalRow;
	}

	/** 
	 * Return a copy of the original row that was passed in for editing which may have been updated by the user.
	 */
	public getEditedRow(): RestaurantTableTop {
		return this.editedRow;
	}

	/** Close the dialog without saving changes. */
	private onCancel() {
		// Hide the dialog
		this.modalButton.click();
		this.resolveModal( 'cancel' );
	}

    private onSave() {
        this.errorMessage = null;
		if (this.isValid()) {
			// Hide the dialog
			this.modalButton.click();
			this.resolveModal( this.isInsert ? 'insert' : 'update' );
		}
    }

	private isValid(): boolean {
		this.errorMessage = null;
		if (this.editedRow.tableId == null) {
			this.errorMessage = 'Please enter a table number.';
			return false;
		}
		if (this.editedRow.seats == null) {
			this.errorMessage = 'Please enter the number of seats.';
			return false;
		}
		return true;
	}

	private onDelete() {
		this.cache.confirmComponent.showModalDialog( 'Are you sure you want to delete table ' + this.originalRow.tableId + '?', 'Confirm Delete' )
		.then( confirmed => {
			if (confirmed) {
				// Hide the dialog
				this.modalButton.click();
				this.resolveModal( 'delete' );
			}
		})
		.catch( error => {
			Global.logError( 'Error in confirm delete dialog.', error );
			this.errorMessage = error.message;
		});
	}

}