import { TableDocumentObject } from './TableHandler'

/** Alexa for Hospitality account ID and the related company and property. */
export class AlexaAccount extends TableDocumentObject {

	/**
	 * @param accountId ID of the Alexa for Hospitality account. (key)
	 * @param name Name of the account
	 * @param companyId ID of company the account is for.
	 * @param propertyId ID of property the account is for.
	 */
	constructor(
		public accountId: string = null,
		public name: string = null,
		public companyId: number = null,
		public propertyId: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item AlexaDevice. */
    fromDataItem( item: any ): AlexaAccount {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"accountId": this.accountId 
        };
    }

}
