import {
	Component, Input, ElementRef, AfterViewInit, ViewChild
  } from '@angular/core';
  
  @Component({
	selector: 'audio-visualizer',
//	template: '<canvas #canvas></canvas>',
	templateUrl: './audio-visualizer.html',
	// styles: ['canvas { border: 1px solid #000; }']
	styleUrls: ['audio-visualizer.css']
})
  export class AudioVisualizerComponent implements AfterViewInit {
	// a reference to the canvas element from our template
	@ViewChild('canvas') public canvas: ElementRef;
  
	// setting a width and height for the canvas
	@Input() public width = 400;
	@Input() public height = 40;
  
	private canvasElement: HTMLCanvasElement;
	private context2d: CanvasRenderingContext2D;  
	private listening = true;

	/**
	 * Audio status message.
	 */
	private _audioMessage = '';
	
	get audioMessage() : string {
		return this._audioMessage;
	}

	set audioMessage( message: string ) {
		// console.log( 'set audioMessage to '+message);
		this._audioMessage = message;
	}	

	public ngAfterViewInit() {
		// get the context
		this.canvasElement = this.canvas.nativeElement;
		this.context2d = this.canvasElement.getContext('2d');

		// set the width and height
		this.canvasElement.width = this.width;
		this.canvasElement.height = this.height;
	}

	/**
	 * Clears the canvas element.
	 */
	clear() {
		// console.log( 'audio-visualizer clear' );
		this.context2d.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
		this.listening = false;
	};

	/**
	 * Sets the listening flag to true.
	 */
	listen() {
		// console.log( 'audio-visualizer listen' );
		this.listening = true;
	};

	/**
	 * Clears the canvas and draws the dataArray. 
	 * @param {Uint8Array} dataArray - The time domain audio data to visualize.
	 * @param {number} bufferLength - The FFT length.
	 */
	visualizeAudioBuffer( dataArray: Uint8Array, bufferLength: number ) {
		// console.log('audio-visualizer visualizeAudioBuffer');
		var WIDTH = this.canvasElement.width;
		var HEIGHT = this.canvasElement.height;
		var animationId;
		this.context2d.clearRect(0, 0, WIDTH, HEIGHT);

		// Will be called at about 60 times per second. If listening, draw the dataArray. 
		let draw = () => {
			if (!this.listening) {
				return;
			}

			this.context2d.fillStyle = 'rgb(249,250,252)';
			this.context2d.fillRect(0, 0, WIDTH, HEIGHT);
			this.context2d.lineWidth = 1;
			this.context2d.strokeStyle = 'rgb(0,125,188)';
			this.context2d.strokeStyle = 'black';
			this.context2d.beginPath();

			var sliceWidth = WIDTH * 1.0 / bufferLength;
			var x = 0;

			for (var i = 0; i < bufferLength; i++) {
				var v = dataArray[i] / 128.0;
				var y = v * HEIGHT / 2;
				if (i === 0) {
					this.context2d.moveTo(x, y);
				} else {
					this.context2d.lineTo(x, y);
					// console.log( 'lineTo '+x+','+y);
				}
				x += sliceWidth;
			}

			this.context2d.lineTo(this.canvasElement.width, this.canvasElement.height / 2);
			this.context2d.stroke();
		};

		// Register our draw function with requestAnimationFrame. 
		if (typeof animationId === 'undefined') {
			animationId = requestAnimationFrame(draw);
		}
	};

	
  }