import * as AWS from "aws-sdk/global";
import * as SQS from "aws-sdk/clients/sqs";
import { Global } from './Global'
import { AWSRequestor } from './AWSRequestor'

export class SQSService {

	public static QUEUE_DOES_NOT_EXIST_ERROR_CODE = 'AWS.SimpleQueueService.NonExistentQueue';

	constructor() {
	}

	createQueue( queueName: string ): Promise<string> {
		return new Promise( (resolve, reject) => {
			let params = {
				QueueName: queueName,
				Attributes: {
				  MessageRetentionPeriod: '7200', // 2 hours as number of seconds
				  ReceiveMessageWaitTimeSeconds: '20',
				},
			  };
			new AWSRequestor().send( new SQS().createQueue( params ) )
			.then( data => resolve( data.QueueUrl ) )
			.catch( err => reject( err ) );
		})
	}

	deleteQueue( queueUrl: string ): Promise<boolean> {
		return new Promise<boolean>( (resolve, reject) => {
			let params: SQS.DeleteQueueRequest = {
				QueueUrl: queueUrl
			  };
			new AWSRequestor().send( new SQS().deleteQueue( params ) )
			.then( data => resolve( true ) )
			.catch( err => {
				if (err.code ==='AWS.SimpleQueueService.NonExistentQueue') {
					resolve( false );
				} else {
					reject( err );
				}
			});
		})
	}

	sendMessage( messageBody: string, queueUrl: string ): Promise<void> {
		// console.log( 'SQSService.sendMessage( queueUrl='+queueUrl+', messageBody='+messageBody+' )' );
		return new Promise<void>( (resolve, reject) => {
			let params = {
				MessageBody: messageBody,
				QueueUrl: queueUrl,
				DelaySeconds: 0,
			  };
			new AWSRequestor().send( new SQS().sendMessage( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
		})
	}

	receiveMessage( queueUrl: string ): Promise<SQS.ReceiveMessageResult> {
		// console.log( 'SQSService.receiveMessage( queueUrl='+queueUrl+' )' );
		return new Promise( (resolve, reject) => {
			let params: SQS.ReceiveMessageRequest = {
				QueueUrl: queueUrl,
				MaxNumberOfMessages : 10,
				WaitTimeSeconds: 20
			  };
			new AWSRequestor().send( new SQS().receiveMessage( params ) )
			.then( data => resolve( data ) )
			.catch( err => reject( err ) );
		})
	}

	deleteMessage( queueUrl: string, receiptHandle: string ): Promise<void> {
		// console.log( 'SQSService.deleteMessage( queueUrl='+queueUrl+', receiptHandle='+receiptHandle+' )' );
		return new Promise<void>( (resolve, reject) => {
			let params: SQS.DeleteMessageRequest = {
				QueueUrl: queueUrl,
				ReceiptHandle: receiptHandle
			  };
			new AWSRequestor().send( new SQS().deleteMessage( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
		})
	}

	deleteMessageBatch( queueUrl: string, receivedMessages: SQS.ReceiveMessageResult ): Promise<void> {
		// console.log( 'SQSService.deleteMessageBatch( queueUrl='+queueUrl+', receivedMessages='+JSON.stringify( receivedMessages, null, 2 ) +' )' );
		return new Promise<void>( (resolve, reject) => {
			let params: SQS.DeleteMessageBatchRequest = {
				QueueUrl: queueUrl,
				Entries: []
			};
			for (let i=0; i<receivedMessages.Messages.length; i++) {
				params.Entries.push( { 'Id': i.toString(), 'ReceiptHandle': receivedMessages.Messages[i].ReceiptHandle } );
			}
			new AWSRequestor().send( new SQS().deleteMessageBatch( params ) )
			.then( data => {
				if (data && data.Failed && data.Failed.length > 0) {
					// Not all messages were deleted
					let errMsg = 'Error deleting some messages in SQSService.deleteMessageBatch( queueUrl='+queueUrl+', receivedMessages='+JSON.stringify( receivedMessages, null, 2 ) +' ):\n' + JSON.stringify( data, null, 2 );
					Global.log( errMsg );
					reject( new Error( errMsg ) );
				} else {
					resolve();
				}
			})
			.catch( err => reject( err ) );
		})
	}

}