import { Global } from "../service/Global";
import { Command } from "./Command";

export class GetRecentVisitsCmd extends Command {

	/**
	 * Get the most recent visits for the given site and company.
	 * @param companyId ID of the company.
	 * @param siteId ID of the site.
	 * @param limit Maximum number of visits to return.
	 */
	do( companyId: number, siteId: number, limit: number ): Promise<any> {
		return new Promise<any>( (resolve, reject) => {
			var parameters = {
				"siteId": siteId,
				"limit": limit,
			};
			super.send( this.constructor.name, companyId, parameters )
			.then( results => {
				// console.log('GetRecentVisitCmd results: '+JSON.stringify(results,null,2));
				resolve( results.data );
			})
			.catch( err => reject( err ) );
		});
	}
}
