import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { Activity } from './Activity';
import { ActivitySchedules } from './ActivitySchedules';

/** Activities for which guests may sign up. */
export class ActivityTable extends TableHandler<Activity> {

	/** Local secondary index use to query activities by HousekeepingLogType. */
	private activityTypeIdIndex = 'companyId_propertyId-activityTypeId-index';
	
	public constructor() {
		super( 'bookcliffsoftware-Activity' );
	}
	
	public fromDataItem( item: any ): Activity {
		return new Activity().fromDataItem( item );
	}

	public getAll( companyId: number, propertyId: number ): Promise<Activity[]> {
    	return new Promise<Activity[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

	private getActivitiesByType( companyId: number, propertyId: number, activityTypeId: number ): Promise<Activity[]> {
    	return new Promise<Activity[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and activityTypeId = :activityTypeId";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
				":activityTypeId": activityTypeId,
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.activityTypeIdIndex )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

	public getActiveSchedules( companyId: number, propertyId: number, activityTypeId: number ): Promise<ActivitySchedules> {
		return new Promise<ActivitySchedules>( (resolve,reject) => {
			// Get activities with given activity type
			// console.log('getActiveSchedules( companyId: '+companyId+', propertyId: '+propertyId+', activityTypeId: '+activityTypeId+' )')
			let activeSchedules = new ActivitySchedules();
			let foundActivities: Activity[] = [];
			let foundSchedules: number[] = [];
			new ActivityTable().getActivitiesByType(  companyId, propertyId, activityTypeId )
			.then( activities => {
				// console.log( 'Got activities '+JSON.stringify(activeSchedules,null,2));
				// Find active schedules
				for (let activityIndex=0; activityIndex < activities.length; activityIndex++) {
					let activity = activities[activityIndex];
					// console.log('Found activity: '+JSON.stringify(activity,null,2));
					for (let scheduleIndex=0; scheduleIndex < activity.schedules.length; scheduleIndex++) {
						let schedule = activity.schedules[scheduleIndex];
						// console.log('Found schedule: '+JSON.stringify(schedule,null,2));
						if (schedule.isActive()) {
							// console.log('Schedule is active');
							activeSchedules.activities.push( activity );
							activeSchedules.scheduleIndexes.push( scheduleIndex );
						}
					}
				}
				resolve( activeSchedules );
			})
			.catch( error => reject( error ) );
		});
	}

}

