import { TableHandler, TableDocumentObject } from './TableHandler'

export class SummaryValues {
	constructor(
		public count = 0,
		public visitMinutes = 0, // Number of minutes between first and last detection
		public morning = 0, // Number of visits that took place between 6am and noon
		public afternoon = 0, // Number of visits that took place between noon and 6pm
		public evening = 0, // Number of visits that took place between 6pm and midnight
		public night = 0, // Number of visits that took place between midnight and 6am
		public age0to9 = 0, // Number that were between 0 an 9 years old
		public age10to19 = 0, // Number that were between 10 an 19 years old
		public age20to29 = 0, // Number that were between 20 an 29 years old
		public age30to39 = 0, // Number that were between 30 an 39 years old
		public age40to49 = 0, // Number that were between 40 an 49 years old
		public age50to59 = 0, // Number that were between 50 an 59 years old
		public age60to69 = 0, // Number that were between 60 an 69 years old
		public age70to79 = 0, // Number that were between 70 an 79 years old
		public ageOver80 = 0, // Number that were over 80 years old
		public beard = 0, // Number that had beard
		public happy = 0, // Number that were happy
		public sad = 0, // Number that were sad
		public angry = 0, // Number that were angry
		public confused = 0, // Number that were confused
		public disgusted = 0, // Number that were disgusted
		public surprised = 0, // Number that were surprised
		public calm = 0, // Number that were calm
		public unknownEmotion = 0, // Number that had unknown emotions
		public eyeglasses = 0, // Number that were wearing eyeglasses
		public male = 0, // Number that were male
		public female = 0, // Number that were female
		public mustache = 0, // Number that person had a mustache
		public smile = 0, // Number that were smiling
		public sunglasses = 0, // Number that were wearing sunglasses
	) {};
}

/**
 * Customer demographics data.
 */
export class VisitSummary extends TableDocumentObject {
	constructor(
		public companyId: number = null, // ID of company (partition key = companyId_siteId)
		public siteId = 0, // ID of site (partition key = companyId_siteId)
		public date = new Date(), // Date the detections took place, time portion is always 00:00. (sort key)
		public newVisitors = new SummaryValues(), // New visitor summary data.
		public repeatVisitors = new SummaryValues(), // Repeat visitor summary data.
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): VisitSummary {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		
		// Add key properties made up of multiple object properties
		item['companyId_siteId'] = this.companyId + '_' + this.siteId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "date": this.date.toISOString()
        }
    }

}

export class VisitSummaryTable extends TableHandler<VisitSummary> {

    public constructor() {
		super( 'faceTrackerVisitSummary' );
	}
	
	public fromDataItem( item: any ): VisitSummary {
		return new VisitSummary().fromDataItem( item );
	}

    /**
     * Get data for a date range for reporting.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param startDate First date to include in the date range.
	 * @param endDate Last date to include in the date range.
     */
    getSummaryData( companyId: number, siteId: number, startDate: Date, endDate: Date ): Promise<VisitSummary[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and #date BETWEEN :startDate AND :endDate";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
				":startDate": startDate.toISOString(),
				":endDate": endDate.toISOString()
			};
			let expressionAttributeNames = {
				'#date': 'date'
			};
			this.query( keyConditionExpression, null, expressionAttributeValues, expressionAttributeNames )
			.then( result => {
				resolve( result.objects );
			})
			.catch( err => {
				reject( err );
			})
        });
	}
	
	generateTestData( companyId: number, siteId: number ) {
		console.log('Generating test data for ')
		// Figure out today's date at the site (assume it is in your current time zone)
		let currentDateOnly = new Date( Date.now() + ( new Date().getTimezoneOffset() * 60000 ) );
		currentDateOnly.setUTCHours( 0, 0, 0, 0 );
		let millisInOneDay = 24 * 60 * 60 * 1000;

		for (let i=0; i<30; i++) {
			let indexDate = new Date( currentDateOnly.getTime() - ( (29-i) * millisInOneDay ) );
			console.log('Generating test data for ' + indexDate.toISOString() );
			
			let newVisitors = this.calculateMockSummaryValues( 60 );
			let repeatVisitors = this.calculateMockSummaryValues( 240 );
			let summary = new VisitSummary( companyId, siteId, indexDate, newVisitors, repeatVisitors );

			this.put( summary );
		}
		
	}

	private calculateMockSummaryValues( maxCount: number ): SummaryValues {
		let values = new SummaryValues();
		values.count = Math.round( ( maxCount / 2 ) + ( maxCount / 2 ) * Math.random() );
		values.visitMinutes = Math.round( 60 * Math.random() ) * values.count;
		let max = [ values.count ];
		values.morning = this.calculateMockValue( max );
		values.afternoon = this.calculateMockValue( max );
		values.evening = this.calculateMockValue( max );
		values.night = max[0];
		values.age0to9 = Math.round( ( values.count * .1 ) * Math.random() );
		values.age10to19 = Math.round( ( values.count * .1 ) * Math.random() );
		max = [ values.count - ( values.age0to9 + values.age10to19 ) ];
		values.age20to29 = this.calculateMockValue( max );
		values.age30to39 = this.calculateMockValue( max );
		values.age40to49 = this.calculateMockValue( max );
		values.age50to59 = this.calculateMockValue( max );
		values.age60to69 = this.calculateMockValue( max );
		values.age70to79 = this.calculateMockValue( max );
		values.ageOver80 = max[0];
		values.beard = Math.round( ( values.count * .1 ) * Math.random() );
		values.happy = Math.round( ( values.count * .1 ) * Math.random() );
		values.sad = Math.round( ( values.count * .1 ) * Math.random() );
		values.angry = Math.round( ( values.count * .1 ) * Math.random() );
		values.confused = Math.round( ( values.count * .1 ) * Math.random() );
		values.disgusted = Math.round( ( values.count * .1 ) * Math.random() );
		values.surprised = Math.round( ( values.count * .1 ) * Math.random() );
		values.calm = Math.round( ( values.count * .1 ) * Math.random() );
		values.unknownEmotion = Math.round( ( values.count * .1 ) * Math.random() );
		values.eyeglasses = Math.round( ( values.count * .1 ) * Math.random() );
		max = [ values.count ];
		values.male = this.calculateMockValue( max );
		values.female = max[0];
		values.mustache = Math.round( ( values.count * .1 ) * Math.random() );
		values.smile = Math.round( ( values.count * .1 ) * Math.random() );
		values.sunglasses = Math.round( ( values.count * .1 ) * Math.random() );
		return values;
	}

	private calculateMockValue( max: number[] ): number {
		let value = Math.round( max[0] * Math.random() );
		max[0] -= value;
		return value;
	}

}
