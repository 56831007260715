import { Component, OnInit, AfterViewInit } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { Callback, CognitoUtil, LoggedInCallback} from "../../service/cognito.service";
import { Router} from "@angular/router";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";


export class Attribute {
	constructor(
    	public name: string,
		public value: string
	) {}
}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './myprofile.html'
})
export class MyProfileComponent implements OnInit, AfterViewInit {

    public parameters: Array<Attribute> = [];

    constructor(
		private router: Router, 
		private userService: UserLoginService, 
		private cognitoUtil: CognitoUtil,
		private cache: CacheService
	) {
		this.userService.checkLoggedIn( () => this.initialize() );
    }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
	}

    initialize() {
		let cognitoUser = this.cognitoUtil.getCurrentUser();
		if (cognitoUser != null) {
			cognitoUser.getSession( (err, session) => {
				if (err)
					Global.logError( 'Error getting user session.', err );
				else {
					this.userService.getUserAttributes()
					.then( results => {
						// console.log( 'result='+JSON.stringify(results) );
						this.parameters.push( new Attribute( 'User ID', results.userId ) );
						this.parameters.push( new Attribute( 'Email', results.email ) );
						this.parameters.push( new Attribute( 'Nickname', results.nickname ) );
					})
					.catch( error => {
						Global.logError( 'Error getting user attributes.', error );
						this.router.navigate(['/home/login']);
					});
				}
			});
		}		
	}
	
	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-user fa-fw"></i> User Profile' );
	}

}
