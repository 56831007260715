import { Command } from "./Command";
import { RestaurantReservationList } from "../service/RestaurantReservationList";

export class GetRestaurantReservationListCmd extends Command {

	/**
	 * Get restaurant reservation list.
	 * @param key Object with key values set.
	 * @param endDate Date and time the seating ends.
	 * @param layoutId Restaurant layout in use for seating.
	 * @param intervalMinutes Number of minutes between valid reservation times.
	 */
	do( key: RestaurantReservationList, endDateTime: Date, layoutId: number, intervalMinutes: number ): Promise<RestaurantReservationList> {
		return new Promise<RestaurantReservationList>( (resolve, reject) => {
			this.send( this.constructor.name, key.companyId, { 
				"key": key.toDataItem(), 
				"endDateTime": endDateTime.toISOString(), 
				"layoutId": layoutId,
				"intervalMinutes": intervalMinutes,
			 })
			.then( item => {
				// Convert generic JSON result object back to real object
				let row: RestaurantReservationList = null;
				if (item.data) {
					// console.log( 'GetRestaurantReservationListCmd got '+JSON.stringify(item.data));
					row = new RestaurantReservationList().fromDataItem( item.data );
				}
				resolve( row );
			}).catch( err => reject( err ) );
		});
	}

}
