import { Company } from "./Company";
import { Property } from "./Property";
import { Role } from "./Role";

export class GetUserDataResponse {
	constructor( 
		public companies: Company[], 
		public properties: Property[],
		public role: Role,
	) {}
}

