import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../service/user-login.service";
import { Property } from '../../service/Property';
import { CacheService } from "../../service/cache.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { EmployeeFeedbackDetailComponent } from "../feedback-detail/feedback-detail.component";
import { Global } from "../../service/Global";
import { isFunction } from "util";
import { Company } from "../../service/Company";
import { Product } from "../../service/Product";
import { Address } from "../../service/Address";
import { GetShippingAddressCmd } from "../../command/GetShippingAddressCmd";
import { ValidateBillingAgreementCmd } from "../../command/ValidateBillingAgreementCmd";
import { SubscribeCmd } from "../../command/SubscribeCmd";
import { BeyondPayAuthCmd } from "../../command/BeyondPayAuthCmd";
import { SpinnerDialogComponent } from '../../public/spinner-dialog/spinner-dialog.component';

declare let amazon: any;
declare let OffAmazonPayments: any;
declare let TokenPay: any;

/**
Displays a dashboard for the SmartView product that tracks new and repeat guests.
*/
@Component({
    selector: 'checkout',
    templateUrl: './checkout.html',
    styleUrls: ['./checkout.css'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {

	@ViewChild(SpinnerDialogComponent) private spinnerDialog: SpinnerDialogComponent;
	
	@ViewChild('terms') private termsRef: ElementRef;
	
	/** Seller ID from Amazon Seller Central (replace this with API call to get the seller ID from System table, don't get entire row because it has secret key in it) */
	private readonly sellerId = "A30L3M6KN88HAV";

	/** Client ID for this web site from Login for Amazon */
	private readonly amazonLoginClientId = 'amzn1.application-oa2-client.b50314a9cf05412f97fbd9dd620fd44f';

	/** Amazon pay billing agreement ID obtained from wallet widget. */
	private amazonBillingAgreementId;

	private errorMessage = null;

	/** True if ngOnDestroy has been called. */
	private destroyed = false;

	/** Day of the month when customer will be billed in ordinal form. */
	private billingDayOfMonthString: string;

	/** Date when customer will be billed. */
	private firstBillingDateString: string;

	/** True if user has selected a payment card and consented to future billing. */
	private billingAgreementConsentStatus = false;

	/** Amazon access token returned from login.  Used to retrieve shipping address. */
	private accessToken: string;

	/** Amazon order reference object obtained from selecting an address in the address book. */
	private orderReference: any;

	/** Amazon order reference id obtained from selecting an address in the address book. */
	private orderReferenceId: string;

	/** True if address book should be shown so user can select a shipping address. */
	private showAddressBook = false;

	/** True if wallet, consent, and pay now button should be shown. */
	private showWallet = false;

	/** True if Place Order button should be disabled. */
	private disablePlaceOrderButton = false;

	/** True if user must select a payment method to complete this transaction. */
	private promptForPaymentMethod = true;

	/** Monthly price of product form the first row of the monthly volume table with a price > 0. */
	private monthlyPriceCents = 0;

	private product: Product;

	/** Set to true to show the wait spinner. */
	// private showSpinner = false;

	/** BeyondPay object */
	private tokenpay = TokenPay('tokenpay8795api20201216121240776');

	constructor(
		private router: Router,
		private _renderer2: Renderer2,
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.destroyed = false;
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
    ngOnDestroy() {
		this.destroyed = true;
    }

	ngAfterViewInit() {
		// Don't set page title, we don't display title-nav on checkout
		// this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-cart-plus fa-fw"></i> Checkout' );

		// BeyondPay initialize
		this.tokenpay.initialize({
			dataElement: '#card', 
			errorElement: '#errorMessage', 
			useStyles: false,
			disableZip: false
		});
	}

	initialize() {
		this.promptForPaymentMethod = true;
		if (this.cache.purchaseDetails) {
			this.product = this.cache.purchaseDetails.product;
			// See if we need to prompt for payment method
			if (this.product.purchaseCents == 0) {
				// There is no up-front purchase price so assume we don't need a payment method
				this.promptForPaymentMethod = false;
				if (this.product.monthlyVolumePrices) {
					for (let i=0; i<this.product.monthlyVolumePrices.length; i++) {
						if (this.product.monthlyVolumePrices[i].priceCents > 0) {
							// There is a monthly fee so we need a payment method
							this.promptForPaymentMethod = true;
							this.monthlyPriceCents = this.product.monthlyVolumePrices[i].priceCents;
							break;
						}
					}
				}
			}
			this.billingDayOfMonthString = Global.nth( this.cache.purchaseDetails.billingDayOfMonth );
			// Test the getFirstBillingDate function
			// console.log('first billing date for 12/1: '+this.getFirstBillingDate( new Date( 2017, 11, 1 ) ) );
			// console.log('first billing date for 12/31: '+this.getFirstBillingDate( new Date( 2017, 11, 31 ) ) );
			// console.log('first billing date for 1/1: '+this.getFirstBillingDate( new Date( 2018, 0, 1 ) ) );
			// console.log('first billing date for 1/27: '+this.getFirstBillingDate( new Date( 2018, 0, 27 ) ) );
			// console.log('first billing date for 1/28: '+this.getFirstBillingDate( new Date( 2018, 0, 28 ) ) );
			// console.log('first billing date for 1/29: '+this.getFirstBillingDate( new Date( 2018, 0, 29 ) ) );
			// console.log('first billing date for 1/30: '+this.getFirstBillingDate( new Date( 2018, 0, 30 ) ) );
			// console.log('first billing date for 1/31: '+this.getFirstBillingDate( new Date( 2018, 0, 31 ) ) );

			// Display payment terms for the product
			this.firstBillingDateString = this.cache.purchaseDetails.firstBillingDate.toLocaleDateString( undefined, { month: 'numeric', day: 'numeric', year: 'numeric', timeZone: 'UTC' } );
			let terms = this.cache.purchaseDetails.product.getTermsHtml( this.firstBillingDateString );
			this._renderer2.setProperty( this.termsRef.nativeElement, 'innerHTML', terms );
		}
		this.billingAgreementConsentStatus = false;
		this.disablePlaceOrderButton = false;
		// this.showSpinner = false;
		this.showAddressBook = false;
		this.showWallet = false;
		if (this.promptForPaymentMethod) {
			this.waitForAmazonPaymentsToBeReady()
			.then( (ready) => {
				if (ready) {
					this.createAmazonPayButton();
				}
			})
			.catch( error => {
				Global.logError('Error waiting for Amazon Pay to be ready.', error );
			})
		}
		console.log('this.promptForPaymentMethod=='+this.promptForPaymentMethod);
	}

	/**
	 * Wait for the OffAmazonPayments object to be defined by the Amazon Pay script loaded in index.html.
	 */
	private waitForAmazonPaymentsToBeReady(): Promise<boolean> {
		return new Promise<boolean>( (resolve,reject) => {
			try {
				if (OffAmazonPayments) {
					resolve( true );
				}
			} catch( error ) {
				// Keep waiting unless the window has been destroyed
				if (this.destroyed) {
					resolve( false );
				} else {
					setTimeout( () => this.waitForAmazonPaymentsToBeReady().then( (ready) => resolve(ready) ), 250 );
				}
			}
		});
	}

	private createAmazonPayButton() {
		// console.log('createAmazonPayButton');
		var authRequest;
		let thisObject = this;
		OffAmazonPayments.Button("AmazonPayButton", this.sellerId, {
			type:  "PwA",
			authorization: () => {
				// console.log('IN AUTHORIZATION FUNCTION!');
				this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'clicked Amazon Pay button' );
				amazon.Login.setClientId( this.amazonLoginClientId );
				let loginOptions = {scope: "payments:widget", popup: true};
				if (this.cache.purchaseDetails.product.needShippingAddress) {
					loginOptions.scope += " payments:shipping_address";
				}
				// console.log('amazon.Login.authorize loginOptions='+JSON.stringify(loginOptions));			
				authRequest = amazon.Login.authorize(loginOptions, (response) => {
					if (response.error) {
						this.paymentError( response.error );
					} else {
						this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'logged into Amazon' );
						// console.log('amazon.Login.authorize returned '+JSON.stringify(response,null,2));
						this.accessToken = response.access_token;
						// console.log('this.accessToken='+this.accessToken);
						this.errorMessage = null;
						if (this.cache.purchaseDetails.product.needShippingAddress) {
							this.showAddressBook = true;
							thisObject.createAmazonAddressBookWidget();
						} else {
							this.showWallet = true;
							thisObject.createAmazonWalletWidget();
						}
						// console.log( 'after calling create wallet');
					}
				});
			},
			onError: error => {
				this.paymentError( error );
			}
		});
	}

	private createAmazonAddressBookWidget() {
		let thisObject = this;
		new OffAmazonPayments.Widgets.AddressBook({
			sellerId: this.sellerId,
			onOrderReferenceCreate: (orderReference) => {
				// Here is where you can grab the Order Reference ID.
				this.orderReference = orderReference;
				// Global.log('orderReference='+JSON.stringify(orderReference,null,2));
				this.orderReferenceId = orderReference.getAmazonOrderReferenceId();
				// Global.log('this.orderReferenceId='+JSON.stringify(this.orderReferenceId,null,2));
			},
			onAddressSelect: (orderReference) => {
				// Replace the following code with the action that you want to perform after the address is selected.
				// The amazonOrderReferenceId can be used to retrieve the address 
				// details by calling the GetOrderReferenceDetails operation. 
				// let details = orderReference.GetOrderReferenceDetails( this.orderReferenceId );
				// Global.log('onAddressSelect order reference details='+JSON.stringify(details,null,2));
		
				// If rendering the AddressBook and Wallet widgets on the same page, you do not have
				// to provide any additional logic to load the Wallet widget after the AddressBook widget.
		
				// The Wallet widget will re-render itself on all subsequent onAddressSelect events 
				// without any action from you. We don't recommend that you explicitly refresh it.
			},
			design: { designMode: 'responsive' },
			onReady: (orderReference) => {
				// Enter code here that you want to be executed when the address widget has been rendered. 
				this.showWallet = true;
				thisObject.createAmazonWalletWidget();
			},
			onError: (error) => {
				this.paymentError( error );
			}
		}).bind("addressBookWidgetDiv");	
	}

	private createAmazonWalletWidget() {
		// console.log('createAmazonWalletWidget');
		let thisObject = this;
		new OffAmazonPayments.Widgets.Wallet({
			sellerId: this.sellerId,
			onReady: (billingAgreement) => {
				this.amazonBillingAgreementId = billingAgreement.getAmazonBillingAgreementId();
				// console.log('AMAZON wallet onReady billingAgreement='+JSON.stringify(billingAgreement,null,2));
				// console.log( 'this.amazonBillingAgreementId='+this.amazonBillingAgreementId);
				thisObject.createAmazonRecurringConsentWidget();
			},
			agreementType: 'BillingAgreement',
			design: { designMode: 'responsive' },
			onPaymentSelect: (billingAgreement) => {
				this.errorMessage = null;
				this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'selected payment type' );
				// Replace this code with the action that you want to perform
				// after the payment method is selected.
				// console.log('AMAZON wallet onPaymentSelect billingAgreement='+JSON.stringify(billingAgreement,null,2));
			},
			onError: (error) => {
				this.paymentError( error );
			}
		}).bind("walletWidgetDiv");
	}

	private createAmazonRecurringConsentWidget() {
		// console.log('createAmazonWalletWidget');
		new OffAmazonPayments.Widgets.Consent({
			sellerId: this.sellerId,
			// amazonBillingAgreementId obtained from the Amazon Address Book widget. 
			amazonBillingAgreementId: this.amazonBillingAgreementId, 
			design: { designMode: 'responsive' },
			onReady: (billingAgreementConsentStatus) => {
				// Called after widget renders
				// console.log( "typeof billingAgreementConsentStatus.getConsentStatus === 'function' is :"+(typeof billingAgreementConsentStatus.getConsentStatus === 'function'));
				// console.log( "isFunction( billingAgreementConsentStatus.getConsentStatus ) is "+(isFunction( billingAgreementConsentStatus.getConsentStatus )));
				// Not sure why the following IF is needed but I was getting periodic "not a function" errors seemingly every time I used the checkout screen for a second time.
				if (isFunction( billingAgreementConsentStatus.getConsentStatus )) {
					// getConsentStatus returns true or false based on the checkbox in the buyer consent widget
					this.billingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus() === 'true';
					this.disablePlaceOrderButton = !this.billingAgreementConsentStatus;
					if (!this.changeDetectorRef['destroyed']) {
						this.changeDetectorRef.detectChanges();
					}
					// console.log( 'consent.onReady consentStatus='+this.billingAgreementConsentStatus);
				}
			},
			onConsent: (billingAgreementConsentStatus) => {
				// Called when user checks or unchecks the consent box
				this.errorMessage = null;
				// getConsentStatus returns true or false based on the checkbox in the buyer consent widget
				this.billingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus() === 'true';
				this.disablePlaceOrderButton = !this.billingAgreementConsentStatus;
				this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, this.billingAgreementConsentStatus ? 'checked consent to billing' : 'unchecked consent to billing' );
				if (!this.changeDetectorRef['destroyed']) {
					this.changeDetectorRef.detectChanges();
				}
			},
			onError: (error) => {
				this.paymentError( error );
			}
		}).bind("consentWidgetDiv ");	
	}

	onSubscribe() {
		// console.log('onSubscribe called');
		this.errorMessage = null;
		// Show spinner and disable Place Order button while we process the order
		this.spinnerDialog.toggleDialog();
		this.disablePlaceOrderButton = true;
		// Make sure selected payment instrument is valid and record subscription details
		this.subscribe( 
			this.cache.purchaseDetails.product.productCode, 
			this.cache.purchaseDetails.product.name, 
			this.cache.purchaseDetails.billingDayOfMonth, 
			new Date(),
			null,
			this.cache.purchaseDetails.firstBillingDate, 
			this.amazonBillingAgreementId,
			this.accessToken,
			this.orderReferenceId
		)
		.then( result => {
			console.log( 'subscribe resolved successfuly: '+JSON.stringify( result, null, 2 ) );
			// Hide spinner
			this.spinnerDialog.toggleDialog();
			if (this.promptForPaymentMethod) {
				// Log user out of Amazon Pay
				amazon.Login.logout();
			}
			// Put updated company in cache
			let company = new Company().fromDataItem( result );
			this.cache.currentCompany = company;
			this.router.navigate(['/signuphome/checkout-confirm']);
		}).catch( error => {
			// Hide spinner
			this.spinnerDialog.toggleDialog();
			this.disablePlaceOrderButton = false;
			// Log user out of Amazon Pay
			amazon.Login.logout();
			Global.logError( 'Error authorizing payment', error );
			// Show error
			this.errorMessage = 'Sorry, their was a problem authorizing the payment.  Please try again later.'
		});
	}

	private paymentError( error ) {
		this.errorMessage = 'Sorry, their was a problem collecting the payment information.  Please try again later.'
		Global.logError( 'Error authorizing payment.  ', error );
	}

	/**
	 * Calls my Subscription API to record the subscription and get payment details..
	 */
	subscribe( productCode: string, productName: string, billingDayOfMonth: number, startDate: Date, endDate: Date, nextBillingDate: Date, amazonBillingAgreementId: string, accessToken: string, orderReferenceId: string ): Promise<Company> {
		// console.log('subscribe with accessToken='+accessToken);
		return new Promise<Company>( (resolve, reject) => {
			this.cache.reportRoomGenieSubscribeConversionToAdWords();
			this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'place order' );
			let sellerNote = 'Order for: '+productName;
			let shippingAddress = null;
			if (this.cache.purchaseDetails.product.needShippingAddress) {
				new GetShippingAddressCmd().do( this.cache.currentCompany.companyId, accessToken, orderReferenceId )
				.then( shippingAddress => {
					// console.log( 'GetShippingAddressCmd returned '+JSON.stringify(shippingAddress,null,2));
					this.validateBillingAgreementAndSubscribe( productCode, productName, sellerNote, billingDayOfMonth, startDate, endDate, nextBillingDate, amazonBillingAgreementId, accessToken, orderReferenceId, shippingAddress )
					.then( company => resolve( company ) )
					.catch( error => reject( error ) );
				}).catch( error => reject( error ) );
			} else {
				// No shipping address needed, validate the billing agreement
				this.validateBillingAgreementAndSubscribe( productCode, productName, sellerNote, billingDayOfMonth, startDate, endDate, nextBillingDate, amazonBillingAgreementId, accessToken, orderReferenceId, shippingAddress )
				.then( company => resolve( company ) )
				.catch( error => reject( error ) );
			}
		});
	}
	
	/**
	 * Calls my Subscription API to record the subscription and get payment details..
	 */
	validateBillingAgreementAndSubscribe( productCode: string, productName: string, sellerNote: string, billingDayOfMonth: number, startDate: Date, endDate: Date, nextBillingDate: Date, amazonBillingAgreementId: string, accessToken: string, orderReferenceId: string, shippingAddress: Address ): Promise<Company> {
		// console.log('subscribe with accessToken='+accessToken);
		return new Promise<Company>( (resolve, reject) => {
			if (this.promptForPaymentMethod) {
				new ValidateBillingAgreementCmd().do( this.cache.currentCompany.companyId, amazonBillingAgreementId, sellerNote )
				.then( company => {
					new SubscribeCmd().do( this.cache.currentCompany.companyId, productCode, productName, billingDayOfMonth, startDate, endDate, nextBillingDate, amazonBillingAgreementId, accessToken, orderReferenceId, shippingAddress, null, null )
					.then( company => resolve( company ) )
					.catch( error => reject( error ) );
				}).catch( error => reject( error ) );
			} else {
				new SubscribeCmd().do( this.cache.currentCompany.companyId, productCode, productName, billingDayOfMonth, startDate, endDate, nextBillingDate, amazonBillingAgreementId, accessToken, orderReferenceId, shippingAddress, null, null )
				.then( company => resolve( company ) )
				.catch( error => reject( error ) );
			}
		});
	}

	/**
	 * Calls BeyondPay library to create a token for the customer's credit card data.
	 * @returns Promise<string> The BeyondPay token.
	 */
	createBeyondPayToken(): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			var form = document.getElementById('paymentForm'); 
			this.tokenpay.createToken(function (result) {
					resolve(result.token);
				}, function (error) {
					reject( error );
				}
			);
		});
	}
	
	onSubscribeWithBeyondPay() {
		this.errorMessage = null;

		// Show spinner
		this.spinnerDialog.toggleDialog();

		// Disable Place Order button while we process the order
		this.disablePlaceOrderButton = true;

		this.createBeyondPayToken()
		.then((token) => {
			console.log('createBeyondPayToken returned token: '+token);
			new BeyondPayAuthCmd().do( this.cache.currentCompany.companyId, "Whats Happening 1.0", token, this.monthlyPriceCents, 4 )
			.then( result => {
				// Extract the Token and GatewayTransID from the response that we need to capture the sale
				let beyondGatewayTransId = Global.getXmlElement( result['data'], 'GatewayTransID');
				let beyondToken = Global.getXmlElement( result['data'], 'Token');

				this.disablePlaceOrderButton = false;
				// Add the product subscription to the company
				this.cache.reportRoomGenieSubscribeConversionToAdWords();
				this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'place order' );
				new SubscribeCmd().do( this.cache.currentCompany.companyId, this.cache.purchaseDetails.product.productCode, this.cache.purchaseDetails.product.name, this.cache.purchaseDetails.billingDayOfMonth, new Date(), null, this.cache.purchaseDetails.firstBillingDate, null, null, null, null, beyondToken, beyondGatewayTransId )
				.then( company => {
					// Hide spinner
					this.spinnerDialog.toggleDialog();

					Global.log(`Successfully subscribed to ${this.cache.purchaseDetails.product.name}.`);

					// Put updated company in cache
					this.cache.currentCompany = new Company().fromDataItem( company );

					// Display the checkout confirmation page
					this.router.navigate(['/signuphome/checkout-confirm']);
				})
			});
		})
		.catch( error => {
			// Hide spinner
			this.spinnerDialog.toggleDialog();
			this.disablePlaceOrderButton = false;
			Global.logError( 'Problem with credit card authorization', error );
			// Show error
			this.errorMessage = 'Sorry, their was a problem authorizing the credit card.  Please try again later.'
		});


	}

}
