import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { CacheService } from '../../service/cache.service';
import { Product } from '../../service/Product';

/** Displays instructions for creating a new account. */
@Component({
    selector: 'create-account-describe',
    templateUrl: './create-account-describe.html',
    styleUrls: ['./create-account-describe.css'],
})
export class CreateAccountDescribeComponent {
	private product: Product;

	constructor( 
		private cache: CacheService
	) {}

	ngOnInit() {
		this.product = this.cache.purchaseDetails.product;
	}

}
