import { Command } from "./Command";
import { ActivitySignUpList } from "../service/ActivitySignUpList";
import { ActivitySchedules } from "../service/ActivitySchedules";

export class GetSignUpListsForActiveSchedulesCmd extends Command {

	/**
	 * Returns a list of sign up lists with available capacity for the given list of activity schedules.
	 * @param activitySchedules List of active activity schedules.
	 * @param persons Available capacity that a sign up list must have in order to be returned, 0 returns all lists.
	 * @param timeZone Time zone of the property.
	 */
	public do( companyId: number, activitySchedules: ActivitySchedules, persons: number, timeZone: string  ): Promise<ActivitySignUpList[]> {
		return new Promise<ActivitySignUpList[]>( (resolve, reject) => {
			this.send( this.constructor.name, companyId, { "activeSchedules": activitySchedules.toDataItem(), "persons": persons } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: ActivitySignUpList[] = [];
				results.data.forEach( item => rows.push( new ActivitySignUpList().fromDataItem( item ) ) );
				// console.log('Sign Up List: '+JSON.stringify(rows[rows.length-1],null,2));
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
