import { TableDocumentObject } from './TableHandler'
import { AdSubcategory } from './AdSubcategory';
import { Global } from './Global';

/** 
 * Global list of advertising categories used by advertisers and hotels.
 * Ie. Coffee Shop, Pizza Shop, Breakfast, Lunch, Dinner, Things to Do, etc.
 * Advertisers can create and bid on ads in each category that is appropriate.
 * Hotels can choose which ads they want to play when guests ask about the category.
 */
export class AdCategory extends TableDocumentObject {

	// Things-to-do category ID
	public static readonly THINGS_TO_DO_CATEGORY_ID = 7;

	// Dinner category can have food types associated with it
	public static readonly DINNER_CATEGORY_ID = 1;

	// Lunch category can have food types associated with it
	public static readonly LUNCH_CATEGORY_ID = 2;

	/**
	 * @param adCategoryId ID of the ad category (partition key)
	 * @param name Name used by Room Genie app to get recommandation for category such as "coffee shop"
	 * @param synonyms List of synonyms that users might use when referring to this category
	 * @param subcategories List of subcategories for this category.  Ie. category dinner has subcategories like pizza, italian, etc.
	 */
	constructor(
		public adCategoryId: number = null,
		public name: string = null,
		public synonyms: string[] = [],
		public subcategories: AdSubcategory[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): AdCategory {
		this.copyPropertiesFromObject( item );
		// Copy nested objects
		this.subcategories = null;
		if (item['subcategories']) {
			this.subcategories = [];
			for (let i=0; i<item['subcategories'].length; i++) {
				let subcategory = new AdSubcategory().fromDataItem( item['subcategories'][i] );
				this.subcategories.push( subcategory );
			}
		}
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy nested objects
		item['subcategories'] = null;
		if (this.subcategories) {
			item['subcategories'] = [];
			for (let i=0; i<this.subcategories.length; i++) {
				item['subcategories'].push( this.subcategories[i].toDataItem() );
			}
		}
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"adCategoryId": this.adCategoryId, 
        };
    }

	getSynonyms(): string {
		let s = '';
		if (this.synonyms) {
			this.synonyms.forEach( synonym => {
				if (s.length > 0) {
					s += ', ';
				}
				s += synonym;
			})
		}
		return s;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		if (name) {
			name = name.toLowerCase();
			if (this.name.toLowerCase() == name) {
				valid = true;
			} else {
				valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
			}
		}
		return valid;
	}

	/** @returns Name of the subcategory with the given ID or null if not found. */
	public getSubcategory( subcategoryId: number ): AdSubcategory {
		let found: AdSubcategory = null;
		if (this.subcategories) {
			for (let i=0; i<this.subcategories.length; i++) {
				if (this.subcategories[i].subcategoryId == subcategoryId) {
					found = this.subcategories[i];
				}
			}
		}
		return found;
	}

	/** @returns Subcategory with the given ID or null if not found. */
	public getSubcategoryByName( name: string ): AdSubcategory {
		let found: AdSubcategory = null;
		if (name && this.subcategories) {
			name = name.toLowerCase();
			for (let i=0; i<this.subcategories.length; i++) {
				if (this.subcategories[i].hasNameOrSynonym( name )) {
					found = this.subcategories[i];
					break;
				}
			}
		}
		return found;
	}

}
