import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { EmployeeComponent } from "../employee/employee.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Employee } from '../../service/Employee';
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";
import { GetEmployeesCmd } from "../../command/GetEmployeesCmd";
import { GetEmployeeCmd } from "../../command/GetEmployeeCmd";
import { PutEmployeeCmd } from "../../command/PutEmployeeCmd";
import { DeleteEmployeeCmd } from "../../command/DeleteEmployeeCmd";

export class Attribute {
	constructor(
    	public name: string,
		public value: string
	) {}
}

@Component({
    selector: 'employees',
    templateUrl: './employees.html',
	styleUrls: ['./employees.css'],
})
export class EmployeesComponent extends Editor<Employee> implements OnInit, AfterViewInit {

	@ViewChild(EmployeeComponent) private detailComponent: EmployeeComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Employees' );
	}

	initialize() {
		this.loadRows();
	}

	/** Returns component used to edit the selected row. */
	getDetailComponent(): EditorDetail<Employee> {
		return this.detailComponent;
	}

	/** Returns promise of array of rows to edit. */
	getRowLoader(): Promise<Employee[]> {
		return new GetEmployeesCmd().do( this.cache.currentCompany.companyId );
	}
	
	/** Creates a new row to be edited. */
	createNewRowToEdit(): Employee {
		return new Employee( this.cache.currentCompany.companyId );
	}

	/** Called before new rows is saved. */
	prepareRowToInsert( newRow: Employee ) {
	}

	/** Detects changes in model so view is updated. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	getRowInserter( row: Employee ): Promise<Employee> {
		return this.saveEmployeeIfIdDoesNotExist( row );
	}

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	getRowUpdater( index: number, row: Employee ): Promise<Employee> {
		return new Promise<Employee>( (resolve,reject) => {
			if (this.detailComponent.originalRow.employeeId != row.employeeId) {
				// User changed the employee ID, make sure the new ID does not already exist
				this.saveEmployeeIfIdDoesNotExist( row )
				.then( () => resolve( row ) )
				.catch( error => reject( error ) );
			} else {
				return new PutEmployeeCmd().do( row )
				.then( () => resolve( row ) )
				.catch( error => reject( error ) );
			}
		});
	}

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	getRowDeleter( index: number, row: Employee ): Promise<void> {
		return new DeleteEmployeeCmd().do( row );
	}

	saveEmployeeIfIdDoesNotExist( row: Employee ): Promise<Employee> {
		return new Promise<Employee>( (resolve,reject) => {
			// Make sure employee ID does not already exist
			new GetEmployeeCmd().do( row )
			.then( employee => {
				if (employee) {
					Global.log('Error saving employee with employee ID that already exists: ' + this.detailComponent.editedRow.employeeId );
					this.detailComponent.errorMessage = 'The employee ID already exists, please enter a unique ID.';
					resolve( row );
				} else {
					return new PutEmployeeCmd().do( row )
					.then( savedRow => resolve( savedRow ) )
					.catch( error => reject( error ) );
				}
			})
			.catch( error => reject( error ) );
		});
	}

}
