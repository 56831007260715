import { TableHandler, TableDocumentObject } from './TableHandler'

/** Data about a restaurant table. */
export class RestaurantTableTop extends TableDocumentObject {
	
	/**
	 * @param sectionId ID for table section the table is in
	 * @param tableId Unique ID for table within the section
	 * @param seats Number of seats at the table
	 */
	constructor(
		public sectionId: number = null,
		public tableId: number = null,
		public seats: number = null,
	) { 
		super(); 
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): RestaurantTableTop {
		this.copyPropertiesFromObject( item );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

}

