import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";

/**
 * Modal dialog that shows a spinner.
 */
@Component({
    selector: 'spinner-dialog',
    templateUrl: './spinner-dialog.html',
	styleUrls: ['./spinner-dialog.css'],
})
export class SpinnerDialogComponent implements AfterViewInit {

	@ViewChild('spinnerModalButton') public spinnerModalButtonRef: ElementRef; private spinnerModalButton: HTMLButtonElement;

	ngAfterViewInit() {
		this.spinnerModalButton = this.spinnerModalButtonRef.nativeElement;
	}

    toggleDialog() {
		this.spinnerModalButton.click();
    }

}