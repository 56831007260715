import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";

/** Displays terms and conditions. */
@Component({
    selector: 'terms',
    templateUrl: './terms.html',
    styleUrls: ['./terms.css'],
})
export class TermsComponent {

}
