import * as AWS from "aws-sdk/global";
import * as AWSRequest from 'aws-sdk/lib/request';
import * as AWSService from 'aws-sdk/lib/service';
// import { Service } from 'aws-sdk/lib/service';
import { IdToken } from './IdToken.interface'

export class AWSRequestor {

	/** In browser apps this is set to a class that can refresh the Cognito token. In server apps, like Lambdas, it is left null. */
	public static cognitoUtil: IdToken = null;

	/**
	 * Sends request to AWS and if it fails with a credentials error, attempts to refresh the credentials then retry the request.
	 * @param request Request to send.
	 */
	public send( request ): Promise<any> {
		return new Promise<any>( (resolve,reject) => {
			// console.log( 'Sending ' + request.operation + ' request to ' + request.service.endpoint.host + ' with params '+JSON.stringify( request.params, null, 2 ) );

			// Fake a credentials error to test the credential refresh process
			// let service: AWSService.Service = request.service;
			// service.config.credentials = null;
			request.send( (err, data) => {
				if (err) {
					// Global.logError( 'AWSRequestor error in ' + this.getRequestDescription( request ), err );
					if (AWSRequestor.cognitoUtil && (err.code == 'CredentialsError' || err.code === 'InvalidSignatureException')) {
						// User's credentials may have expired, try to refresh them
						console.log( 'AWSRequestor call refreshCredentials' );
						// console.log( 'BEFORE CREDS: ' + JSON.stringify( AWS.config.credentials, null, 2 ) );
						AWSRequestor.cognitoUtil.refreshCredentials()
						.then( () => {
							// console.log( 'AFTER CREDS: ' + JSON.stringify( AWS.config.credentials, null, 2 ) );
							console.log( 'AWSRequestor refreshCredentials succeeded, retry' );
							// let service = new Service( request.service.config );
							let retryRequest = request.service.makeRequest( request.operation, request.params );
							retryRequest.service.config.credentials = AWS.config.credentials;
							// Retry the AWS call after refreshing user session
							// let retryRequest = new AWSRequest.Request( request.service, request.operation, request.params );
							retryRequest.send( (err, data) => {
								if (err) {
									err.message = 'Error retrying ' + this.getRequestDescription( request ) + '. ' + err.code + ': ' + err.message;
									console.log( err.message );
									reject( err );
								} else {
									console.log( 'AWSRequestor: Eureka!!! Retry after credential refresh succeeded!!!  Hooray for me!!!');
									resolve( data );
								}
							});
						})
						.catch( err2 => {
							err.message = 'Error ' + err2.code + ': ' + err2.message + ' while refreshing credentials for ' + this.getRequestDescription( request ) + '. ' + err.code + ': ' + err.message;
							console.log( err.message );
							reject( err );
						})
					} else {
						err.message = 'Error in ' + this.getRequestDescription( request ) + '. ' + err.code + ': ' + err.message;
						console.log( err.message );
						reject( err );
					}
				} else {
					resolve( data );
				}
			})
		});
	}

	public getRequestDescription( request ): string {
		return request.operation + ' request to ' + request.service.endpoint.host + ' with params '+JSON.stringify( request.params, null, 2 );
	}

}