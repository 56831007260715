import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { Item } from './Item';

/** 
 * A category of items that can be ordered or requested like Appetizers or Toiletries.
 */
export class ItemCategory extends TableDocumentObject {

	/**
	 * @param name Name of the category like Appetizers or Toiletries
	 * @param synonyms List of synonyms that users might use when referring to this category
	 * @param items List of items in the menu category
	 */
	constructor(
		public name: string = null,
		public synonyms: string[] = [],
		public items: Item[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): ItemCategory {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.items = [];
		if (item['items']) {
			item['items'].forEach( childItem => this.items.push( new Item().fromDataItem( childItem ) ) );
		}
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['items'] = [];
		if (this.items) {
			this.items.forEach( childItem => item['items'].push( childItem.toDataItem() ) );
		}
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		name = name.toLowerCase();
		if (this.name.toLowerCase() == name) {
			valid = true;
		} else {
			// Check all the synonyms
			valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
		}
		return valid;
	}

}
