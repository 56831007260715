import { Command } from "./Command";
import { Company } from "../service/Company";

export class DeleteCompanyCmd extends Command {

	/**
	 * Delete a row.
	 * @param row Data to delete.
	 */
	public do( companyId: number ): Promise<any> {
		return this.send( this.constructor.name, companyId, null );
	}

}
