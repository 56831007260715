import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Global } from '../../service/Global';
import * as EXIF from 'exif-js/exif';

@Component({
	selector: 'image-capture',
	templateUrl: './image-capture.html',
	styleUrls: ['./image-capture.css'],
})
export class ImageCaptureComponent implements OnInit, OnDestroy {
	@ViewChild('photoinput') public photoInputRef: ElementRef;
	@ViewChild('canvas') public canvasRef: ElementRef;
	@ViewChild('video') public videoRef: ElementRef;
	private video: HTMLVideoElement;
	private canvas: HTMLCanvasElement;
	private videoTracks: any;
	private hideVideo: boolean = true;
	private hideCanvas: boolean = true;
	public hideSwitchCameraButton = true;

	/** True if camera video should be displayed. */
	private showCamera = true;
	
	private cameras = [];
	private currentCameraIndex = -1;
	
	constructor() {
		// console.log("ImageCaptureComponent constructor");
	}

	ngOnInit() {
		// console.log('ImageCaptureComponent.ngOnInit()');
	}

	ngOnDestroy() {
		// console.log('ImageCaptureComponent.ngOnDestroy()');
		if (this.supportsGetUserMedia()) {
			this.turnCameraOff();
		}
	}

	public ngAfterViewInit() {
		this.video = <HTMLVideoElement>this.videoRef.nativeElement;
		this.canvas = this.canvasRef.nativeElement;
	}

	/** Returns list of cameras found during initialization. */
	getCameras() {
		return this.cameras;
	}

	getCameraIndex( cameraDeviceId: string ): number {
		let index = -1;
		for (let i=0; i<this.cameras.length; i++) {
			if (this.cameras[i].deviceId === cameraDeviceId) {
				index = i;
				break;
			}
		}
		return index;
	}
	
	/** Returns true if the current browser supports the getUserMedia function.   If not, caller should use an input element with a file. */
	supportsGetUserMedia() {
		// console.log( '"mediaDevices" in navigator='+("mediaDevices" in navigator));
		// console.log( '"webkitGetUserMedia" in navigator='+("webkitGetUserMedia" in navigator));
		// console.log( '"mozGetUserMedia" in navigator='+("mozGetUserMedia" in navigator));
		return "mediaDevices" in navigator;
	}

	/** 
	 * Initializes video and gets list of cameras on the device.
	 * @returns promise that resolves to true when video is playing or false if device doesn't support mediaDevices (like ios9).
	 */
	initialize( showCamera = true ): Promise<boolean> {
		// console.log('ImageCaptureComponent.initialize( '+showCamera+' )' );
		this.showCamera = showCamera;
		return new Promise<boolean>( (resolve,reject) => {
			if (this.supportsGetUserMedia()) {
				try {
					Global.log('Device supports mediaDevices.')
					this.queryDeviceForCameras()
					.then( cameras => resolve( true ) )
					.catch( error => {
						Global.logError( 'Problem getting list of device cameras.', error );
						reject( error );
					});
				} catch( error ) {
					Global.logError( 'Error finding out if browser supports mediaDevices.', error );
					reject( error );
				}
			} else {
				// mediaDevices not supported, call captureImage to use input element.
				Global.log('Device does not support mediaDevices.')
				resolve( false );
			}
		});
	}

	private queryDeviceForCameras(): Promise<any[]> {
		return new Promise<any[]>( (resolve, reject) => {
			if (this.supportsGetUserMedia()) {
				try {
					// Global.log('Device supports mediaDevices.')
					navigator.mediaDevices.enumerateDevices()
					.then( devices => {
						this.processDevices(devices);
						resolve( this.cameras );
					 })
					.catch( error => {
						Global.logError( 'Problem getting list of device cameras.', error );
						reject( error );
					});
				} catch( error ) {
					Global.logError( 'Error getting list of device cameras.', error );
					reject( error );
				}
			} else {
				// mediaDevices not supported, call captureImage to use input element.
				// Global.log('Device does not support mediaDevices.')
				resolve( [] );
			}
		});
	};

	processDevices(deviceInfos) {
		this.cameras = [];
		this.currentCameraIndex = -1;
		for (var i = 0; i !== deviceInfos.length; ++i) {
			var deviceInfo = deviceInfos[i];
			// var option = document.createElement('option');
			// option.value = deviceInfo.deviceId;
			// if (deviceInfo.kind === 'audioinput') {
			// 	console.log( 'Found microphone: '+JSON.stringify( deviceInfo, null, 2 ) );
			// 	// option.text = deviceInfo.label || 'microphone ' + (audioSelect.length + 1);
			// 	// audioSelect.appendChild(option);
			// } else 
			if (deviceInfo.kind === 'videoinput') {
				// console.log( 'Found camera: '+JSON.stringify( deviceInfo, null, 2 ) );
				let label = deviceInfo.label ? deviceInfo.label : 'Camera '+(i+1);
				this.cameras.push( { 'label': label, 'deviceId': deviceInfo.deviceId } );
				// Default to first camera found
				this.currentCameraIndex = 0;
				// option.text = deviceInfo.label || 'camera ' + (videoSelect.length + 1);
				// videoSelect.appendChild(option);
			// } else {
			// 	console.log('Found ome other kind of media source/device: '+JSON.stringify( deviceInfo, null, 2 ) );
			}
		}
		if (this.cameras.length > 1) {
			this.hideSwitchCameraButton = false;
		}
	}

/*	
	getUserMediaPolyfill() {
		// Older browsers might not implement mediaDevices at all, so we set an empty object first
		if (navigator.mediaDevices === undefined) {
			navigator.mediaDevices = {};
		}
  
		// Some browsers partially implement mediaDevices. We can't just assign an object
		// with getUserMedia as it would overwrite existing properties.
		// Here, we will just add the getUserMedia property if it's missing.
		if (navigator.mediaDevices.getUserMedia === undefined) {
			navigator.mediaDevices.getUserMedia = function(constraints) {

				// First get ahold of the legacy getUserMedia, if present
				var getUserMedia = navigator["webkitGetUserMedia"] || navigator["mozGetUserMedia"];

				// Some browsers just don't implement it - return a rejected promise with an error
				// to keep a consistent interface
				if (!getUserMedia) {
				return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
				}

				// Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
				return new Promise(function(resolve, reject) {
				getUserMedia.call(navigator, constraints, resolve, reject);
				});
			}
		}

		navigator.mediaDevices.getUserMedia({ audio: true, video: true })
		.then(function(stream) {
			var video = document.querySelector('video');
			// Older browsers may not have srcObject
			if ("srcObject" in video) {
				video.srcObject = stream;
			} else {
				// Avoid using this in new browsers, as it is going away.
				video.src = window.URL.createObjectURL(stream);
			}
			video.onloadedmetadata = function(e) {
				video.play();
			};
		})
		.catch(function(err) {
			console.log(err.name + ": " + err.message);
		});
	}
*/

	/**
	 * Show camera element with video stream.
	 * Returns promise that resolves to true when video is playing or false if mediaDevices not supported (like iOS 9).
	 */
	turnCameraOn(): Promise<boolean> {
		// console.log('Turning camera off' );
		return new Promise<boolean>( (resolve,reject) => {
			if (this.supportsGetUserMedia()) {
				if (this.showCamera) {
					this.hideVideo = false;
				}
				this.hideCanvas = true;
				let constraints = null;
				constraints = { video: true };
				// constraints = { video: true };
				// constraints = { video: { facingMode: "user" } };
				// Try to get 640x480 video with no audio
				// constraints = { audio: false, video: { width: 640, height: 480 } };
				if (this.currentCameraIndex != -1) {
					constraints = {
						video: { optional: [ { sourceId: this.cameras[ this.currentCameraIndex ].deviceId } ] }
					};
				}
				// console.log('Calling: getUserMedia( ' + JSON.stringify( constraints, null, 2 ) + ')' );
				// console.log('hidden on switch button='+((!this.cameras) || (this.cameras.length<1)));
				navigator.mediaDevices.getUserMedia( constraints )
				.then( stream => {
					// streaming takes a moment to start
					let listener = () => {
						this.video.removeEventListener('playing', listener );
						// console.log('VIDEO PLAYING!!!');
						resolve( true );
					};
					this.video.addEventListener('playing', listener );
				
					// Attach the video stream to the video element and autoplay.
					// console.log('getUserMedia(video) was successful');
					// this.video.srcObject = stream;
					if ("srcObject" in this.video) {
						this.video.srcObject = stream;
					} else {
						// Avoid using this in new browsers, as it is going away.
						(<HTMLVideoElement>this.video).src = window.URL.createObjectURL(stream);
					}
					this.videoTracks = stream.getVideoTracks();
				})
				.catch( error => {
					Global.logError( 'Error getting video media with constraints: ' + JSON.stringify( constraints, null, 2 ), error );
					alert( 'Error getting video media.' );
					// console.log('VIDEO ERROR: '+JSON.stringify(error,null,2));
					let message = 'Error getting video media with constraints: ' + JSON.stringify( constraints, null, 2 ) + error.message;
					reject( new Error( message ) );
				});
			} else {
				// mediaDevices not supported, call captureImage to use input element.
				this.hideVideo = true;
				this.hideCanvas = true;
				resolve( false );
			}
		});
	}

	/**
	 * Turn camera off to save battery and resources.
	 */
	turnCameraOff() {
		// console.log('Turning camera off' );
		if (this.supportsGetUserMedia()) {
			if (this.videoTracks) {
				this.videoTracks.forEach(function (track) { track.stop() });
				this.videoTracks = null;
			}
		}
		this.hideVideo = true;
		if (this.showCamera) {
			// Assume we only show the canvas when we are showing the camera
			this.hideCanvas = false;
		}
	}

	switchCameras() {
		if (this.cameras.length > 1) {
			this.currentCameraIndex++;
			if (this.currentCameraIndex == this.cameras.length) {
				this.currentCameraIndex = 0;
			}
			this.turnCameraOff();
			this.turnCameraOn();
		}
	}

	setCurrentCameraIndex( value ) {
		// console.log( 'setCurrentCameraIndex( '+value+' )' );
		let oldValue = this.currentCameraIndex;
		if (value >= 0 && value < this.cameras.length) {
			this.currentCameraIndex = value;
		}
		// The following check doesn't work when the camera is not turned on because the default cameraIndex is 0 not -1
		// if (oldValue != this.currentCameraIndex) {
			// Use a different camera
			this.turnCameraOff();
			this.turnCameraOn();
		// }
	}

	getVideoElement(): HTMLVideoElement {
		return this.video;
	}

	getCanvasElement(): HTMLCanvasElement {
		return this.canvas;
	}
	
	/**
	 * Capture an image from the video stream and display it on the canvas.
	 * @returns Data URL of the image captured from the camera.
	 */
	captureImage(): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			if (this.supportsGetUserMedia()) {
				// console.log( 'image-capture.component.captureImage' );
				// Get image from video stream player and draw it on canvas
				// Global.log('Capturing image from video: ' + this.video.videoWidth + 'x' + this.video.videoHeight );
				this.canvas.width = this.video.videoWidth;
				this.canvas.height = this.video.videoHeight;
				var context = this.canvas.getContext('2d');
				context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);

				this.turnCameraOff();
				// console.log('hideCamera='+this.hideCamera+', hideSnapshot='+this.hideSnapshot);

				// Convert image to a data URL that can be displayed on a canvas or image element.
				var dataURL = this.canvas.toDataURL('image/jpeg', 1.0); // 1.0=full quality, .92=default, .5=medium, .1=low

				// Rotate the image if necessary
				this.canvas.toBlob( (resultBlob: Blob) => {
					this.rotateImageIfNeeded( dataURL, resultBlob )
					.then( newDataUrl => {
						resolve( newDataUrl );
					})
					.catch( error => Global.logError( 'Error rotating captured image.', error ) );
				});
			} else {
				if (this.showCamera) {
					this.hideCanvas = false;
				}
				this.resolveCaptureImage = resolve;
				this.rejectCaptureImage = reject;
				this.takePhotoWithInputElement();
			}
		});
	}

	private resolveCaptureImage: Function;
	private rejectCaptureImage: Function;

	getImageData(): ImageData {
		return this.canvas.getContext('2d').getImageData(0, 0, this.canvas.width, this.canvas.height);
	}

	/** Returns 2D drawing context for the canvas. */
	getContext() {
		return this.canvas.getContext('2d');
	}

	/** Returns the width of the image. */
	getImageWidth() {
		return this.canvas.width;
	}

	/** Returns the height of the image. */
	getImageHeight() {
		return this.canvas.height;
	}

	takePhotoWithInputElement() {
		let photoInput: HTMLButtonElement = this.photoInputRef.nativeElement;
		photoInput.click();
		return false;
	}

	handlePhotoInputChangeEvent( event ) {
		// console.log( 'handlePhotoInputChangeEvent '+JSON.stringify(event,null,2)+', event.target.files.length='+event.target.files.length+', event.target.files[0].type='+event.target.files[0].type );
		event.preventDefault();
		if (event.target.files.length === 0) {
			Global.log( 'No files found on photo picker' );
			this.rejectCaptureImage( new Error( 'No files found on photo input element.' ) );
		} else if (event.target.files[0].type.indexOf("image/") != 0) {
			Global.log( 'File was not an image.' );
			this.rejectCaptureImage( new Error( 'File on photo input element was not an image.' ) );
		} else {
			let dataUrl = URL.createObjectURL( event.target.files[0] );
			// console.log( 'dataUrl='+dataUrl );
			// console.log( 'created dataURL' + dataUrl.substr( 0, 50) );
			this.rotateImageIfNeeded( dataUrl, event.target.files[0] )
			.then( newDataUrl => {
				// URL.revokeObjectURL( img.src );
				URL.revokeObjectURL( dataUrl );
				this.resolveCaptureImage( newDataUrl );
			})
		}
	}

	rotateImageIfNeeded( dataUrl: string, imageBlob: Blob ): Promise<string> {
		return new Promise<string>( (resolve,reject) => {
			var img = new Image();
			img.onload = imgevent => {
				// URL.revokeObjectURL( img.src );

				var width = img.width;
				var height = img.height;
				// Global.log('rotateImage loaded image '+width+'x'+height);
				// let canvas = this.canvas;
				let canvas = document.createElement("canvas");
				canvas.width = width;
				canvas.height = height;
				var ctx = canvas.getContext("2d");

				var fileReader = new FileReader();
				fileReader.onloadend = ( d ) => {
					var exif = EXIF.readFromBinaryFile( fileReader.result );
					// Global.log( 'exif.Orientation='+exif.Orientation+', exif='+JSON.stringify(exif,null,2));
					switch(exif.Orientation){
						case 2:
							// console.log( 'horizontal flip' );
							ctx.translate( width, 0 );
							ctx.scale(-1, 1);
							break;
						case 3:
							// console.log( '180° rotate left' );
							ctx.translate( width, height );
							ctx.rotate(Math.PI);
							break;
						case 4:
							// console.log( 'vertical flip' );
							ctx.translate( 0, height );
							ctx.scale(1, -1);
							break;
						case 5:
							// console.log( 'vertical flip + 90 rotate right' );
							ctx.rotate(0.5 * Math.PI);
							ctx.scale(1, -1);
							break;
						case 6:
							// console.log( '90° rotate right' );
							ctx.rotate(0.5 * Math.PI);
							ctx.translate( 0, -height );
							break;
						case 7:
							// console.log( 'horizontal flip + 90 rotate right' );
							ctx.rotate(0.5 * Math.PI);
							ctx.translate( width, -height);
							ctx.scale(-1, 1);
							break;
						case 8:
							// console.log( '90° rotate left' );
							ctx.rotate(-0.5 * Math.PI);
							ctx.translate(-width, 0);
							break;
					}

					ctx.drawImage(img, 0, 0, width, height);
					// reset current transformation matrix to the identity matrix
					ctx.setTransform(1, 0, 0, 1, 0, 0);
					var newDataUrl = canvas.toDataURL('image/jpeg', 1.0); // 1.0=full quality, .92=default, .5=medium, .1=low
					// Global.log('Rotated dataURL: '+newDataUrl.substr(0,50) );
					// Global.log('newDataUrl===dataUrl: '+(newDataUrl===dataUrl) );
					resolve( newDataUrl );
				};
				fileReader.readAsArrayBuffer( imageBlob );	
			};
			img.src = dataUrl;
		});
	}

}
