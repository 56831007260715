import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { SiteHours } from "../../service/SiteHours";
import { RestaurantLayout } from "../../service/RestaurantLayout";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";
import { TimeInputModel } from "../../service/TimeInputModel";
import { IMyDpOptions } from 'mydatepicker';
import { GetRestaurantLayoutListCmd } from '../../command/GetRestaurantLayoutListCmd';
import { RestaurantLayoutComponent } from "../restaurant-layout/restaurant-layout.component";
import { PutRestaurantLayoutCmd } from '../../command/PutRestaurantLayoutCmd';
import { ClosedComponent } from '../closed/closed.component';

/**
 * Component for editing a restaurant layout.
 */
@Component({
    selector: 'site-hours',
	templateUrl: './site-hours.html',
	styleUrls: [ 'site-hours.css' ]
})
export class SiteHoursComponent extends EditorDetail<SiteHours> implements OnInit, OnDestroy {

	@ViewChild('SiteHoursModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	@ViewChild(RestaurantLayoutComponent) private detailComponent: RestaurantLayoutComponent;

	@ViewChild(ClosedComponent) private closedComponent: ClosedComponent;

	/** Time zone where the currently selected property is located. */
	public timeZone: string = null;

	private myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
	};
	
	// Data model used for date picker, initialized to today.
	private startDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };
	
	// Data model used for date picker, initialized to today.
	private endDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	// Model variables for start time
	private startTimeModel = new TimeInputModel( null, this.timeZone );

	// Model variables for end time
	private endTimeModel = new TimeInputModel( null, this.timeZone );

	/** List of layouts to choose from. */
	private layouts: RestaurantLayout[] = [];

	/** ID of the property where the restaurant, that this seating belongs to, is located. */
	public propertyId;

	/** Model for layout selector. */
	public selectedLayoutId: string = null;

	/** Model variables for day of week checkboxes */
	private isSunday = true;
	private isMonday = true;
	private isTuesday = true;
	private isWednesday = true;
	private isThursday = true;
	private isFriday = true;
	private isSaturday = true;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Create a new row with default values. */
	createNewDefaultRow(): SiteHours {
		return new SiteHours();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	initializeDialog() {
		this.selectedLayoutId = this.editedRow.layoutId ? this.editedRow.layoutId.toString() : null;

		// Initialize start date
		this.startDateModel = null;
		if (this.editedRow.startDate) {
			this.startDateModel = { jsdate: this.editedRow.startDate, date: { year: this.editedRow.startDate.getUTCFullYear(), month: this.editedRow.startDate.getUTCMonth()+1, day: this.editedRow.startDate.getUTCDate() } };
		}

		// Initialize start date
		this.endDateModel = null;
		if (this.editedRow.endDate) {
			this.endDateModel = { jsdate: this.editedRow.endDate, date: { year: this.editedRow.endDate.getUTCFullYear(), month: this.editedRow.endDate.getUTCMonth()+1, day: this.editedRow.endDate.getUTCDate() } };
		}
	
		// Initialize start time
		this.startTimeModel.setTime( this.editedRow.startDate, this.timeZone );

		// Initialize end time
		this.endTimeModel.setTime( this.editedRow.endDate, this.timeZone );

		// Initialize day of week check boxes
		this.isSunday = this.editedRow.daysOfWeek[0] == '1';
		this.isMonday = this.editedRow.daysOfWeek[1] == '1';
		this.isTuesday = this.editedRow.daysOfWeek[2] == '1';
		this.isWednesday = this.editedRow.daysOfWeek[3] == '1';
		this.isThursday = this.editedRow.daysOfWeek[4] == '1';
		this.isFriday = this.editedRow.daysOfWeek[5] == '1';
		this.isSaturday = this.editedRow.daysOfWeek[6] == '1';

		this.loadLayouts();
	}

	loadLayouts() {
		new GetRestaurantLayoutListCmd().do( this.cache.currentCompany.companyId, this.propertyId )
		.then( rows => {
			if (rows) {
				this.layouts = rows;
				this.layouts.splice( 0, 0, new RestaurantLayout( this.cache.currentCompany.companyId, this.propertyId, null, 'None'))
			}
		})
		.catch( error => this.handleError( error ) );
	}

	/** Return a deep copy of the given object. */
	clone( row: SiteHours ): SiteHours {
		return new SiteHours().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete the seating: ' + this.originalRow.name + '?'
	}

	isValid(): boolean {
		this.errorMessage = null;
		if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for this block of open hours.';
			return false;
		}

		if (!this.startDateModel.jsdate) {
			this.errorMessage = 'Please enter a start date.'
			return false;
		}

		if (!this.endDateModel.jsdate) {
			this.errorMessage = 'Please enter an end date.'
			return false;
		}

		if (this.startTimeModel.isNull()) {
			this.errorMessage = 'Please enter a start time.'
			return false;
		}

		if (this.endTimeModel.isNull()) {
			this.errorMessage = 'Please enter an end time.'
			return false;
		}

		if (this.editedRow.turnMinutes != null && Number.parseInt( this.editedRow.turnMinutes.toString() ) < 5) {
			this.errorMessage = 'Please set the turn minutes to 5 minutes or more.'
			return false;
		}

		if (this.editedRow.intervalMinutes != null && this.editedRow.intervalMinutes < 5) {
			this.errorMessage = 'Please set the reservation interval minutes to 5 minutes or more.'
			return false;
		}

		// No layout is needed if the site does not accept reservations
		// if (this.selectedLayoutId == null) {
		// 	this.errorMessage = 'Please select a layout.'
		// 	return false;
		// }
		
		this.editedRow.layoutId = Number.parseInt( this.selectedLayoutId );
		
		this.editedRow.startDate = this.startDateModel.jsdate;
		// let time = this.startTimeComponent.getTime();
		let time = this.startTimeModel.getTime();
		this.editedRow.startDate.setUTCHours( time.getUTCHours(), time.getUTCMinutes(), 0, 0 );
		
		this.editedRow.endDate = this.endDateModel.jsdate;
		// time = this.endTimeComponent.getTime();
		time = this.endTimeModel.getTime();
		this.editedRow.endDate.setUTCHours( time.getUTCHours(), time.getUTCMinutes(), 0, 0 );

		if (this.editedRow.startDate.getTime() >= this.editedRow.endDate.getTime()) {
			this.errorMessage = 'Please enter an end date and time that is later than the start date and time.'
			return false;
		}

		this.editedRow.daysOfWeek = 
			(this.isSunday ? '1' : '0') +
			(this.isMonday ? '1' : '0') +
			(this.isTuesday ? '1' : '0') +
			(this.isWednesday ? '1' : '0') +
			(this.isThursday ? '1' : '0') +
			(this.isFriday ? '1' : '0') +
			(this.isSaturday ? '1' : '0');
		
		return true;
	}

	createLayout() {
		this.errorMessage = null;
		this.detailComponent.showModalDialog( new RestaurantLayout(), true  )
		.then( action => {
			if (action === 'insert') {
				// User saved changes, update the list
				let layout = this.detailComponent.getEditedRow();
				this.prepareLayoutToInsert( layout );
				new PutRestaurantLayoutCmd().do( layout )
				.then( () => {
					this.layouts.push( layout );
					this.changeDetectorRef.detectChanges();
				})
				.catch( error => {
					Global.logError('Error saving data.', error );
					this.errorMessage = 'Sorry, we had a problem saving the data.  Please try again.';
				});
			}
		})
		.catch( error => {
			Global.logError( 'Error editing new data', error );
			this.errorMessage = error.message;
		});
	}

	prepareLayoutToInsert( newRow: RestaurantLayout ) {
		newRow.companyId = this.cache.currentCompany.companyId;
		newRow.propertyId = this.propertyId;
		let highestLayoutId = null;
		this.layouts.forEach( row => {
			if ((!highestLayoutId) || row.layoutId > highestLayoutId) {
				highestLayoutId = row.layoutId;
			}
		})
		newRow.layoutId = highestLayoutId + 1;
	}

	insertClosed() {
		this.handleChildInsert( new Date(), this.editedRow.closedDays, this.closedComponent );
	}

	editClosed( index: number ) {
		this.handleChildEdit( index, this.editedRow.closedDays, this.closedComponent );
	}

}