import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserLoginService } from "../../service/user-login.service";
import { EmployeeFeedbackTable, EmployeeFeedback } from '../../service/EmployeeFeedbackTable';
import { Property } from '../../service/Property';
import { CacheService } from "../../service/cache.service";
import { Global } from '../../service/Global';
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { EmployeeFeedbackDetailComponent } from "../feedback-detail/feedback-detail.component";
import { PurchaseDetails } from '../../service/PurchaseDetails';
import { Product } from '../../service/Product';
import { GetProductsCmd } from '../../command/GetProductsCmd';

declare let amazon: any;
declare let OffAmazonPayments: any;

/**
Displays a dashboard for the SmartView product that tracks new and repeat guests.
*/
@Component({
    selector: 'product-subscribe',
    templateUrl: './product-subscribe.html',
    styleUrls: ['./product-subscribe.css'],
})
export class ProductSubscribeComponent implements OnInit, AfterViewInit {

	private loggedIn = false;
	private errorMessage = null;

	private roomGenieProductCode = Product.ROOM_GENIE;
	private roomGenieMonthlyPrice: string = null;

	private roomGeniePlusEchoDotProductCode = Product.ROOM_GENIE_PLUS_ECHO_DOT;
	private roomGeniePlusEchoDotPrice: string = null;
	
	/** List of all products that are sold. */
	private products: Product[] = [];

	private isRoomGenieActive = false;
	private isAnalyticsActive = false;
	private isRecognizeActive = false;
	private isConnectActive = false;
	private isLineTimerActive = false;
	private isCheckinActive = false;
	private isTimeclockActive = false;
	private isAlertActive = false;

	constructor(
		private router: Router,
		// private route: ActivatedRoute,
		private userService: UserLoginService, 
		private employeeFeedbackTable: EmployeeFeedbackTable,
		private cache: CacheService
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.isAuthenticated( (message: string, loggedIn: boolean) => {
			// if (!isLoggedIn) {
				// this.router.navigate(['/home/login']);
			// }
			this.loggedIn = loggedIn;
			// console.log( 'this.loggedIn='+this.loggedIn );
	
			// Set local variables for products
			this.isRoomGenieActive = this.cache.currentCompany && this.cache.currentCompany.hasOneOfTheseProducts( [Product.ROOM_GENIE,Product.ROOM_GENIE_PLUS_ECHO_DOT] );
			this.isAnalyticsActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.ANALYTICS ) != -1;
			this.isRecognizeActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.RECOGNIZE ) != -1;
			this.isConnectActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.CONNECT ) != -1;
			this.isLineTimerActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.LINE_TIMER ) != -1;
			this.isCheckinActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.CHECK_IN ) != -1;
			this.isTimeclockActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.TIME_CLOCK ) != -1;
			this.isAlertActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.ALERT ) != -1;

			// Load list of products
			new GetProductsCmd().do()
			.then( products => {
				this.products = products;
				this.roomGenieMonthlyPrice = Global.getCurrencyStringForCents( Product.getProduct( Product.ROOM_GENIE, products ).monthlyVolumePrices[0].priceCents, false );
				this.roomGeniePlusEchoDotPrice = Global.getCurrencyStringForCents( Product.getProduct( Product.ROOM_GENIE_PLUS_ECHO_DOT, products ).purchaseCents, false );
			})
			.catch( error => {
				Global.logError( 'Error loading products', error );
				this.errorMessage = 'Sorry, there was a problem loading the product information.  Please try again.';
			});
			return false;
		});
	}
	
    ngOnDestroy() {
    }

	ngAfterViewInit() {
	}

	onSubscribe( productCode ) {
		// console.log('onSubscribe( ' + productCode + ' ) called');
		this.cache.appComponent.subscribeToProduct( Product.getProduct( productCode, this.products ) );
	}

	onUnsubscribe( productCode ) {
		// console.log('onUnsubscribe( ' + productCode + ' ) called');
		if (this.loggedIn) {
			console.log( 'We are logged in so unsubscribe from the product')
			let subscriptionIndex = this.cache.currentCompany.getActiveSubscriptionIndex( productCode );
			let subscription = this.cache.currentCompany.subscriptions[ subscriptionIndex ];
			let billingTerms = 'The service will continue to work until your final billing date of ' + subscription.nextBillingDate.toLocaleDateString() + '.  After the final billing, you will no longer be billed for the service and the service will no longer be provided.  You may subscribe again at any time.';
			this.cache.purchaseDetails = new PurchaseDetails( Product.getProduct( subscription.productCode, this.products ), null, subscription.nextBillingDate, billingTerms );
			this.cache.sendEventToAnalytics( 'Unsubscribe', this.cache.purchaseDetails.product.name, 'click unsubscribe' );
			this.router.navigate(['/securehome/unsubscribe-confirm']);
		} else {
			this.router.navigate(['/home/login']);
		}
	}

	paymentError( productCode: string, error ) {
		let errorMessage = 'Sorry, their was a problem authorizing the payment.  Please try again later.'
		switch (productCode) {
			case Product.ROOM_GENIE: this.errorMessage = errorMessage;
			case Product.ROOM_GENIE_PLUS_ECHO_DOT: this.errorMessage = errorMessage;
		}
		Global.log( 'Error authorizing payment.  ' + error.getErrorCode() + ': ' + error.getErrorMessage() );
	}

}
