import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/**
 * Role class includes a setting for each permission.
 * 'G' means permission granted, 'D' means permission denied, 'N' (or null) means permission not granted.
 * If a permission is denied, the user doesn't have that permission even if it is granted in one of 
 * the user's other roles.
 * Permissions from multiple roles are combined into the user's permission list.
 */
export class Role extends TableDocumentObject {

	public static readonly nonPermissionPropertyNames = ['companyId','roleId','roleName'];

	public static SYSADMINID = -1;
	public static COMPANYADMINID = 0;
	
	public static readonly GRANTED = 'G';
	public static readonly NOT_GRANTED = 'N';
	public static readonly DENIED = 'D';

	/**
	 * Group of permissions that can be assigned to a user.  Users can have multiple roles assigned.
	 * Permission properties have values 'G'=Granted, 'N'=Not Granted, and 'D'=Denied.
	 * A system administrator has all permissions and is denoted with the role ID -1;
	 * A company administrator has all permissions for the company except sysAdmin permission
	 * 
	 * @param companyId ID of the company the role belongs to.
	 * @param roleId Role ID that is unique within the company.
	 * @param roleName Role name
	 */
	public constructor(
		public companyId: number = null,
		public roleId: number = null,
		public roleName: string = null,
		public viewAnalyticsPage = 'N',
		public viewConnectPage = 'N',
		public viewCheckInPage = 'N',
		public viewRoomGeniePage = 'N',
		public viewLineTimerPage = 'N',
		public viewRecognizePage = 'N',
		public viewTimeClockPage = 'N',
		public subscribeProduct = 'N',
		public editCompany = 'N',
		public editProperties = 'N',
		public editSites = 'N',
		public editRoles = 'N',
		public editEmployees = 'N',
		public editActivities = 'N',
		public editActivityTypes = 'N',
		public editActivitySignUp = 'N',
		public editMarketingEvents = 'N',
		public useDeviceCamera = 'N',
		public editHousekeepingLog = 'N',
		public editRestaurantReservations = 'N',
		public editRestaurants = 'N',
		public editRestaurantLayouts = 'N',
		public editTopics = 'N',
		public editPropertyAds = 'N',
		public viewFeedback = 'N',
		public viewAdReports = 'N',
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Role {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			'companyId': this.companyId,
			'roleId': this.roleId,
        }
    }

	private isPermissionProperty( propertyName: string ): boolean {
		return Role.nonPermissionPropertyNames.indexOf( propertyName ) == -1;
	}

	public combineWithRoles( roles: Role[] ): Role {
		// console.log('before combineWithRoles '+roles+': role='+JSON.stringify(this,null,2))
		if (roles) {

			// Add assigned permissions
			roles.forEach( role => {
				if (role.roleId == Role.SYSADMINID) {
					this.roleId = Role.SYSADMINID;
				} else if (role.roleId == Role.COMPANYADMINID && this.roleId != Role.SYSADMINID) {
					this.roleId = Role.COMPANYADMINID;
				} else {
					// Combine permissions from this role
					Object.getOwnPropertyNames( role ).forEach( (propertyName, idx, array) => {
						if (this.isPermissionProperty( propertyName )) {
							// console.log('check grant '+propertyName + '=' + role[propertyName]);
							if (role[propertyName] == Role.GRANTED && this[propertyName] != Role.DENIED) {
								// Permission wasn't already granted or denied so grant it
								// console.log('Granting permission '+propertyName);
								this[propertyName] = Role.GRANTED;
							}
						}
					});
				}
			});
			// console.log('after granting permissions: role='+JSON.stringify(this,null,2))

			// Remove denied permissions
			roles.forEach( role => {
				Object.getOwnPropertyNames( role ).forEach( (propertyName, idx, array) => {
					if (this.isPermissionProperty( propertyName )) {
						// console.log('check deny '+propertyName + '=' + role[propertyName]);
						if (role[propertyName] == Role.DENIED) {
							// Permission is denied
							// console.log('Denying permission '+propertyName);
							this[propertyName] = Role.DENIED;
						}
					}
				});
			});
			// console.log('after denying permissions: role='+JSON.stringify(this,null,2))
		}
		// console.log( 'combined role: '+JSON.stringify(this,null,2));
		return this;
	}

	public hasPermission( permissionName: string ): boolean {
		let hasPermission = false;
		if (this.roleId == Role.SYSADMINID) {
			hasPermission = true;
		} else if (this.roleId == Role.COMPANYADMINID) {
			hasPermission = permissionName != 'sysAdmin';
		} else {
			hasPermission = this[permissionName] != null && this[permissionName] == Role.GRANTED;
		}
		// console.log( 'hasPermission('+permissionName+') returns '+hasPermission);
		return hasPermission;
	}

	public setSysAdmin() {
		this.roleId = Role.SYSADMINID;
		this.roleName = "System Administrator";
		return this;
	}

	public setCompanyAdmin() {
		this.roleId = Role.COMPANYADMINID;
		this.roleName = "Company Administrator";
		return this;
	}

}
