import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { CacheService } from "../../service/cache.service";
import { ActivityType } from "../../service/ActivityType";
import { InputTextComponent } from "../input-text/input-text.component";
import { Global } from '../../service/Global';
import { UUIDService } from '../../service/uuid.service';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";

/**
 * Edit an activity type.
 */
@Component({
    selector: 'activity-type',
	templateUrl: './activity-type.html',
	styleUrls: [ 'activity-type.css' ]
})
export class ActivityTypeComponent extends EditorDetail<ActivityType> implements OnInit {

	@ViewChild('activityTypeModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	@ViewChild(InputTextComponent) private detailComponent: InputTextComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private userService: UserLoginService,
		private cache: CacheService,
		private uuidService: UUIDService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
	}
	
	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): ActivityType {
		return new ActivityType();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
	}

	/** Return a deep copy of the given object. */
	clone( row: ActivityType ): ActivityType {
		return new ActivityType().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		this.errorMessage = null;

		if (this.editedRow.name == null || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for the activity type.';
		}

		return this.errorMessage == null;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete activity type: ' + this.editedRow.name + '?';
	}

	insert() {
		this.detailComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. snorkel boat', true );
		this.handleChildInsert( new String(), this.editedRow.synonyms, this.detailComponent );
	}

	edit( index: number ) {
		this.detailComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. snorkel boat', true );
		this.handleChildEdit( index, this.editedRow.synonyms, this.detailComponent );
	}

}