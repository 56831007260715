import * as DynamoDB from "aws-sdk/clients/dynamodb";
import { AWSRequestor } from './AWSRequestor';
import { Global } from './Global'
import { DynamoDBService } from './ddb.service';

/**
 * Abstract class used to make data objects that can be stored and retrieved form the database by a TableHandler.
 */
export abstract class TableDocumentObject {

	/**
	 * Copies the property values from the item to this object.
	 * @param item Data item from table.
	 * @return Data object.
	 */
	abstract fromDataItem( item: any );

	/**
	 * Get an object that is ready to store in the table.
	 */
	abstract toDataItem(): any;

	/**
	 * Get an object containing the primary key values needed to find a record in the table.
	 */
	abstract getKey(): any;

	public primaryKeyMatches( otherObject: TableDocumentObject ) {
		// console.log( 'primaryKeyMatches '+JSON.stringify( this.getKey() )+' === '+JSON.stringify( otherObject.getKey() )+' returns '+(JSON.stringify( this.getKey() ) === JSON.stringify( otherObject.getKey() )) );
		return JSON.stringify( this.getKey() ) === JSON.stringify( otherObject.getKey() );
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	protected copyPropertiesFromObject( object: any ) {
		try {
			// Set object properties from data item values translating Date properties from ISO strings
			Object.getOwnPropertyNames( this ).forEach( (propertyName, idx, array) => {
				// console.log(val + ' -> ' + item[val]);
				if (this[propertyName] instanceof Date) {
					this.copyDatePropertyFromObject( propertyName, object )
					// this[propertyName] = new Date( Date.parse( object[propertyName] ) );
				} else {
					this[propertyName] = object[propertyName];
				}
			});
			// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
			return this;
		} catch( err ) {
			throw new Error( 'Error copying ' + this.constructor.name + ' properties from object ' + JSON.stringify( object, null, 2 ) + ': ' + err.message );
		}
	}

	/**
	 * Copies the value of a Date property from the given object by parsing the ISO string.
	 * @param propertyName Name of date property.
	 * @param object Source object.
	 */
	protected copyDatePropertyFromObject( propertyName: string, object: any ) {
		// Handle endDate which may be null and therefore not and instanceof Date which breaks the copy
		if (object[propertyName]) {
			this[propertyName] = new Date( Date.parse( object[propertyName] ) );
		} else {
			this[propertyName] = null;
		}
		return this;
	}

	// protected copyArrayPropertyFromObject( listClass: Prototype ) {
	// 	// Copy list of objects
	// 	this[propertyName] = null;
	// 	if (item['seatings']) {
	// 		this[propertyName] = [];
	// 		item['seatings'].forEach( seating => this[propertyName].push( this.costructor.call new SiteHours().fromDataItem( seating ) ) );
	// 	}
	// }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	protected copyPropertiesToObject( object: any ) {
		try {
			// Add object properties translating Date properties to ISO strings
			Object.getOwnPropertyNames( this ).forEach( (propertyName, idx, array) => {
				if (this[propertyName] instanceof Date) {
					this.copyDatePropertyToObject( propertyName, object )
				} else if ((typeof this[propertyName] === 'string' || this[propertyName] instanceof String) && this[propertyName] != null && this[propertyName].length == 0) {
					// Convert blank string to null since DynamoDB can't store blank strings
					console.log( 'Setting '+propertyName+' to null');
					object[propertyName] = null;
				} else {
					object[propertyName] = this[propertyName];
				}
			});
			// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
			return object;
		} catch( err ) {
			throw new Error( 'Error copying properties to object from ' + this.constructor.name + ' object ' + JSON.stringify( this, null, 2 ) + ': ' + err.message );
		}
	}
	
	/**
	 * Copies the value of a Date property to the given object as an ISO string.
	 * @param propertyName Name of date property.
	 * @param object Destination object.
	 */
	protected copyDatePropertyToObject( propertyName: string, object: any ) {
		if (this[propertyName]) {
			// console.log( 'this['+propertyName+']==true, this['+propertyName+']='+this[propertyName]);
			object[propertyName] = this[propertyName].toISOString();
			// console.log( 'object['+propertyName+']='+object[propertyName]);
		} else {
			object[propertyName] = null;
		}
		return object;
	}

}

/**
 * Class used to return query results.
 */
export class TableHandlerQueryResult<T extends TableDocumentObject> {

	/**
	 * @param objects Objects returned from query.  Will never be null but may be empty.
	 * @param lastEvaluatedKey If lastEvaluatedKey is not null there may be more results to retrieve.  Pass it in to another query() call to get more results.
	 */
	constructor(
		public objects: T[],
		public lastEvaluatedKey: DynamoDB.DocumentClient.Key
	) {}

}

/**
 * Class for reading and writing documents in a DynamoDB table.
 */
export abstract class TableHandler<T extends TableDocumentObject> {

	constructor( private tableName: string ) {
	}

	/**
	 * Creates a data object from the data item from the table.
	 * The item parameter will never be null.
	 * @param item Data item from table.
	 * @return Data object.
	 */
	abstract fromDataItem( item: any ): T;

	/** 
	 * Returns the name of the table.
	 * @return Table name.
	 */
	public getTableName(): string {
		return this.tableName;
	}

    /**
     * Get the record for the given key values.
     * @param keyObject Object with values in the properties used in the primary key.
	 * @returns Object with the given key values or null if not found.
     */
    get( keyObject: T ): Promise<T> {
    	return new Promise( (resolve, reject) => {
            var params = {
                Key: keyObject.getKey(),
                TableName: this.tableName
            };
			new AWSRequestor().send( new DynamoDB.DocumentClient().get( params ) )
			.then( data => {
				let object: T = null;
				if (data.Item) {
					object = this.fromDataItem( data.Item );
				}
				// console.log( this.tableName+'.get returns ' + JSON.stringify( workedShift, null, 2 ) );
				resolve( object );
			})
			.catch( err => reject( err ) );
        });
    }

    /**
     * Get the record for the given key values and reject the promise if not found.
     * @param keyObject Object with values in the properties used in the primary key.
     */
    getExisting( keyObject: T ): Promise<T> {
    	return new Promise( (resolve, reject) => {
			this.get( keyObject )
			.then( object => {
				if (object) {
					resolve( object );
				} else {
					reject( new Error( "Data not found with key values " + JSON.stringify( keyObject, null, 2 ) + " in table " + this.tableName + ".") );
				}
				// console.log( this.tableName+'.get returns ' + JSON.stringify( workedShift, null, 2 ) );
			})
			.catch( err => reject( err ) );
        });
    }

	putAll( objects: T[] ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
			let promises: Promise<T>[] = [];
			if (objects) {
				objects.forEach( object => {
					Global.log('putAll: '+JSON.stringify(object,null,2));
					promises.push( this.put( object ) );
				})
			}
			Promise.all( promises )
			.then( results => resolve() )
			.catch( error => reject( error ) );
		});
	}

    /**
     * Put a new document in the table replacing any document with the same primary key.
     * @param object Object to insert.
     * @return Promise<T> The object that was put in the table.
     */
    put( object: T, conditionExpression: string = null, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap = null ): Promise<T> {
    	return new Promise<T>( (resolve, reject) => {
            // console.log( this.tableName+'.put( '+JSON.stringify( object, null, 2 )+' )' );
            let params: DynamoDB.DocumentClient.PutItemInput = {
                Item: object.toDataItem(),
                ReturnConsumedCapacity: "TOTAL",
                TableName: this.tableName
			};
			if (conditionExpression) {
				params.ConditionExpression = conditionExpression;
			}
			if (expressionAttributeValues) {
				params.ExpressionAttributeValues = expressionAttributeValues;
			}
			new AWSRequestor().send( new DynamoDB.DocumentClient().put( params ) )
			.then( data => { 
				// console.log('put returned '+JSON.stringify(data,null,2) ); 
				resolve( object ); 
			})
			.catch( err => reject( err ) );
        });
    }

    /**
     * Delete the record with the key values from the given object.
     * @param keyObject Object containing the key values of the record to delete.
     */
    delete( keyObject: T ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
            // console.log( this.tableName +'.delete( '+JSON.stringify( keyObject, null, 2 ) + ' )' );
            var params = {
                Key: keyObject.getKey(),
                TableName: this.tableName
            };
			new AWSRequestor().send( new DynamoDB.DocumentClient().delete( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
        });
    }

    /**
     * Delete objects from a table recursively until all objects that match the key conditions are deleted.
	 * 
	 * @param keyConditionExpression Expression to filter which key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @param expressionAttribteNames Map of names used in expressions to their real names ie. when the real name is a reserved word.
	 * @param secondaryIndexName Name of the secondary index to use.
	 * @return Number of objects deleted.
     */
    deleteAll( keyConditionExpression: string, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, expressionAttributeNames: DynamoDB.DocumentClient.ExpressionAttributeNameMap = null, secondaryIndexName: string=null ): Promise<number> {
    	return new Promise( (resolve, reject) => {
            var params = this.getQueryParams( keyConditionExpression, null, expressionAttributeValues, expressionAttributeNames, secondaryIndexName, null );
			this.deleteRecursively( params, 0 )
			.then( result => {
				// console.log( 'in deleteAll, deleteRecursively returned delete count ' + result + ' for table ' + this.tableName );
				resolve( result );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	/**
	 * Recursive function that queries table until all results have been retrieved.
	 * @param params Query parameters.
	 * @param objects Array of retrieved objects.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
	 */
	private deleteRecursively( params: DynamoDB.DocumentClient.QueryInput, objectsDeletedSoFar: number ): Promise<number> {
    	return new Promise( (resolve, reject) => {
			new AWSRequestor().send( new DynamoDB.DocumentClient().query( params ) )
			.then( data => {
				if (data.Items && data.Items.length > 0) {
					// Use BatchWriteItem to delete up to 25 records at a time efficiently and in parallel.
					// console.log( 'deleteRecursively: query returned ' + data.Items.length + ' items.');
					this.deleteItemsInBatches( data.Items )
					.then( () => {
						// console.log( 'deleteRecursively returned ' + data.Items.length + ' items from ' + this.tableName + ' with exclusiveStartKey ' + JSON.stringify( params.ExclusiveStartKey, null, 2 ) + ' and lastEvaluatedKey ' + JSON.stringify( data.LastEvaluatedKey, null, 2 ) + '.' );
						objectsDeletedSoFar += data.Items.length;
						if (data.LastEvaluatedKey) {
							params.ExclusiveStartKey = data.LastEvaluatedKey;
							this.deleteRecursively( params, objectsDeletedSoFar )
							.then( deleteCount => {
								objectsDeletedSoFar += deleteCount;
								// console.log( 'deleteRecursively resolved ' + objectsDeletedSoFar + ' with objectsDeletedSoFar=' + objectsDeletedSoFar + ' and deleteCount=' +  deleteCount );
								resolve( objectsDeletedSoFar );
							})
							.catch( err => {
								reject( err );
							})
						} else {
							// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
							resolve( objectsDeletedSoFar );
						}
					})
					.catch( err => reject( err ) );
				} else {
					// No data items were returned from the query call or deleted
					resolve( objectsDeletedSoFar );
				}
			})
			.catch( err => reject( err ) );
        });
	}

    /**
     * Delete objects from a table recursively until all objects that match the filter expression are deleted.
	 * 
	 * @param filterExpression Expression to filter which key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @return Number of objects deleted.
     */
    deleteByScan( filterExpression: string, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap ): Promise<number> {
    	return new Promise( (resolve, reject) => {
			// var params = this.getQueryParams( keyConditionExpression, expressionAttributeValues, expressionAttributeNames, secondaryIndexName, null );
			var params: DynamoDB.DocumentClient.ScanInput = {
				TableName: this.tableName,
				FilterExpression: filterExpression,
				ExpressionAttributeValues: expressionAttributeValues, 
				ReturnConsumedCapacity: "INDEXES",
			};
			this.deleteRecursivelyByScan( params, 0 )
			.then( result => {
				// console.log( 'in deleteAll, deleteRecursively returned delete count ' + result + ' for table ' + this.tableName );
				resolve( result );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	/**
	 * Recursive function that queries table until all results have been retrieved.
	 * @param params Query parameters.
	 * @param objects Array of retrieved objects.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
	 */
	private deleteRecursivelyByScan( params: DynamoDB.DocumentClient.ScanInput, objectsDeletedSoFar: number ): Promise<number> {
    	return new Promise( (resolve, reject) => {
			new AWSRequestor().send( new DynamoDB.DocumentClient().scan( params ) )
			.then( data => {
				if (data.Items && data.Items.length > 0) {
					// Use BatchWriteItem to delete up to 25 records at a time efficiently and in parallel.
					// console.log( 'deleteRecursively: query returned ' + data.Items.length + ' items.');
					this.deleteItemsInBatches( data.Items )
					.then( () => {
						// console.log( 'deleteRecursively returned ' + data.Items.length + ' items from ' + this.tableName + ' with exclusiveStartKey ' + JSON.stringify( params.ExclusiveStartKey, null, 2 ) + ' and lastEvaluatedKey ' + JSON.stringify( data.LastEvaluatedKey, null, 2 ) + '.' );
						objectsDeletedSoFar += data.Items.length;
						if (data.LastEvaluatedKey) {
							params.ExclusiveStartKey = data.LastEvaluatedKey;
							this.deleteRecursivelyByScan( params, objectsDeletedSoFar )
							.then( deleteCount => {
								objectsDeletedSoFar += deleteCount;
								// console.log( 'deleteRecursively resolved ' + objectsDeletedSoFar + ' with objectsDeletedSoFar=' + objectsDeletedSoFar + ' and deleteCount=' +  deleteCount );
								resolve( objectsDeletedSoFar );
							})
							.catch( err => {
								reject( err );
							})
						} else {
							// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
							resolve( objectsDeletedSoFar );
						}
					})
					.catch( err => reject( err ) );
				} else {
					// No data items were returned from the query call or deleted
					resolve( objectsDeletedSoFar );
				}
			})
			.catch( err => reject( err ) );
        });
	}

	/**
	 * Deletes all the items in the given list from the DynamoDb table.
	 * @param items Items queried from a DynamoDb table.
	 */
	private deleteItemsInBatches( items: DynamoDB.DocumentClient.AttributeMap[], index=0 ): Promise<void> {
		// console.log( 'deleteItemsInBatches items.length=' + items.length + ', index=' + index );
		return new Promise<void>( ( resolve, reject ) => {
			// let params: DynamoDB.DocumentClient.BatchWriteItemInput = { RequestItems: {} };
			let requests = [];
			if ((!items) || items.length == 0 || index >= items.length) {
				// There are no items to delete
				Global.log( 'deleteItemsInBatches received 0 items to delete.');
				resolve();
			} else {
				// There are items to delete, delete them in batches of 25
				while (index < items.length && requests.length < 25) {
					let deleteRequest = {
						DeleteRequest: {
							Key: this.fromDataItem( items[index] ).getKey()
						}
					}
					requests.push( deleteRequest );
					index++;
				}

				this.batchWriteRecursively( requests )
				.then( () => {
					// console.log('Deleted batch of ' + requests.length + ' items from ' + this.getTableName() );
					if (index == items.length) {
						// We are finished with all batchWrite requests to delete the items in blocks of 25
						// console.log('Deleted ' + items.length + ' items from ' + this.getTableName() );
						resolve();
					} else {
						this.deleteItemsInBatches( items, index )
						.then( () => resolve() )
						.catch( err => reject( err ) );
					}
				})
				.catch( err => reject( err ) );
			}
		});
	}

	/**
	 * Deletes all the items in the given list from the DynamoDb table.
	 * Items are deleted in batches of up to 25.
	 * If not all items are deleted on the first try, the system retries with exponential backoff.
	 * @param objects Items queried from a DynamoDb table.
	 */
	deleteObjectsInBatches( objects: T[], index=0 ): Promise<void> {
		// console.log( 'deleteObjectsInBatches items.length=' + items.length + ', index=' + index );
		return new Promise<void>( ( resolve, reject ) => {
			// let params: DynamoDB.DocumentClient.BatchWriteItemInput = { RequestItems: {} };
			let requests = [];
			if ((!objects) || objects.length == 0 || index >= objects.length) {
				// There are no items to delete
				Global.log( 'deleteObjectsInBatches received 0 objects to delete.');
				resolve();
			} else {
				// There are items to delete, delete them in batches of 25
				while (index < objects.length && requests.length < 25) {
					let deleteRequest = {
						DeleteRequest: {
							Key: objects[index].getKey()
						}
					}
					requests.push( deleteRequest );
					index++;
				}

				this.batchWriteRecursively( requests )
				.then( () => {
					// console.log('Deleted batch of ' + requests.length + ' objects from ' + this.getTableName() );
					if (index == objects.length) {
						// We are finished with all batchWrite requests to delete the items in blocks of 25
						// console.log('Deleted ' + items.length + ' items from ' + this.getTableName() );
						resolve();
					} else {
						this.deleteObjectsInBatches( objects, index )
						.then( () => resolve() )
						.catch( err => reject( err ) );
					}
				})
				.catch( err => reject( err ) );
			}
		});
	}

	/**
	 * Processes a batch of put or delete requests in parallel.
	 * @param requests Array of up to 25 (or 4MB) or put or delete requests.
	 * @param retryMillis Number of milliseconds before retrying if not all requests are processed on the first try.
	 */
	private batchWriteRecursively( requests: DynamoDB.DocumentClient.WriteRequest[], retryMillis=0 ): Promise<void> {
		return new Promise<void>( ( resolve, reject ) => {
			let params: DynamoDB.DocumentClient.BatchWriteItemInput = { RequestItems: {} };
			params.RequestItems[ this.getTableName() ] = requests;
			new AWSRequestor().send( new DynamoDB.DocumentClient().batchWrite( params ) )
			.then( data => {
				if (data.UnprocessedItems && data.UnprocessedItems[ this.getTableName() ] && data.UnprocessedItems[ this.getTableName() ].length > 0) {
					// Not all of the items were processed, calculate next retry time with exponential backoff
					let nextRetryMillis = retryMillis * 2;
					if (nextRetryMillis == 0) {
						// Set retry seconds to 1 so it can start doubling for exponential backoff
						nextRetryMillis = 500;
					} else if (retryMillis < 60000) {
						// Double the retry delay, up to 1 minute, to improve the chances of completing the processing within our capacity
						retryMillis *= 2;
					} else {
						reject( 'Batch delete failed after retries.  ' + JSON.stringify( requests, null, 2 ) );
					}
					// Retry with exponential backoff
					Global.log( 'batchWriteRecursively failed to process ' + data.UnprocessedItems[ this.getTableName() ].length + ' items out of ' + requests.length + ' items.  Retry in ' + nextRetryMillis + ' milliseconds.  ' + JSON.stringify( data.UnprocessedItems[ this.getTableName() ], null, 2 ) );
					setTimeout( () => {
						this.batchWriteRecursively( data.UnprocessedItems[ this.getTableName() ], nextRetryMillis )
						.then( count => {
							// console.log( 'batchWriteRecursively successfully processed ' + data.UnprocessedItems[ this.getTableName() ].length + ' items on retry.' );
							resolve();
						})
						.catch( err => reject( err ) );
					}, nextRetryMillis );
				} else {
					// console.log( 'batchWriteRecursively successfully processed ' + requests.length + ' items.' );
					resolve();
				}
			})
			.catch( err => reject( err ) );
		});
	}

    /**
     * Get records from a table.  Results are limited to 4MB.
	 * Check the lastEvaluatedKey to see if there are more results to be retrieved.  If it is null,
	 * there are no more results, otherwise call query() again and pass it in as the exclusiveStartKey
	 * to get more results.
	 * @param secondaryIndexName Name of the secondary index to use.
	 * @param keyConditionExpression Expression to filter which key values are included in the results.
	 * @param filterExpression Expression to filter which non-key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @param expressionAttribteNames Map of names used in expressions to their real names ie. when the real name is a reserved word.
     * @param exclusiveStartKey The primary key of the first item that this operation will evaluate. Use the value that was returned for LastEvaluatedKey in the previous operation. The data type for ExclusiveStartKey must be String, Number or Binary. No set data types are allowed.
	 * @param limit Maximum number of objects to return, 0 means unlimited.
	 * @param scanIndexForward True if index should be scanned in ascending order.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
     */
    getQueryParams( keyConditionExpression: string, filterExpression: string, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, expressionAttributeNames: DynamoDB.DocumentClient.ExpressionAttributeNameMap = null, secondaryIndexName: string=null, exclusiveStartKey: DynamoDB.DocumentClient.Key=null, limit=0, scanIndexForward=true ): DynamoDB.DocumentClient.QueryInput {
		var params: DynamoDB.DocumentClient.QueryInput = {
			TableName: this.tableName,
			KeyConditionExpression: keyConditionExpression, 
			ExpressionAttributeValues: expressionAttributeValues, 
			ScanIndexForward: scanIndexForward,
			ReturnConsumedCapacity: "INDEXES",
		};
		if (filterExpression) {
			params.FilterExpression = filterExpression;
		}
		if (expressionAttributeNames) {
			params.ExpressionAttributeNames = expressionAttributeNames;
		}
		if (limit > 0) {
			params.Limit = limit;
		}
		if (secondaryIndexName) {
			params.IndexName = secondaryIndexName;
		}
		if (exclusiveStartKey) {
			params.ExclusiveStartKey = exclusiveStartKey;
		}
		return params;
    }

    /**
     * Get records from a table.  Results are limited to 4MB.
	 * Check the lastEvaluatedKey to see if there are more results to be retrieved.  If it is null,
	 * there are no more results, otherwise call query() again and pass it in as the exclusiveStartKey
	 * to get more results.
	 * @param secondaryIndexName Name of the secondary index to use.
	 * @param keyConditionExpression Expression to filter which key values are included in the results.
	 * @param filterExpression Expression to filter which non-key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @param expressionAttribteNames Map of names used in expressions to their real names ie. when the real name is a reserved word.
     * @param exclusiveStartKey The primary key of the first item that this operation will evaluate. Use the value that was returned for LastEvaluatedKey in the previous operation. The data type for ExclusiveStartKey must be String, Number or Binary. No set data types are allowed.
	 * @param limit Maximum number of objects to return, 0 means unlimited.
	 * @param scanIndexForward True if index should be scanned in ascending order.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
     */
    query( keyConditionExpression: string, filterExpression: string, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, expressionAttributeNames: DynamoDB.DocumentClient.ExpressionAttributeNameMap = null, secondaryIndexName: string=null, exclusiveStartKey: DynamoDB.DocumentClient.Key=null, limit=0, scanIndexForward=true ): Promise<TableHandlerQueryResult<T>> {
    	return new Promise( (resolve, reject) => {
            // console.log( this.tableName + '.getOpenShifts( '+companyId+', '+siteId+', '+email+' )' );
            var params = this.getQueryParams( keyConditionExpression, filterExpression, expressionAttributeValues, expressionAttributeNames, secondaryIndexName, exclusiveStartKey, limit, scanIndexForward );
			let objects: T[] = [];
			new AWSRequestor().send( new DynamoDB.DocumentClient().query( params ) )
			.then( data => {
				if (data.Items) {
					data.Items.forEach( item => {
							objects.push( this.fromDataItem( item ) );
					})
				}
				// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
				resolve( new TableHandlerQueryResult( objects, data.LastEvaluatedKey ) );
			})
			.catch( err => reject( err ) );
        });
    }

    /**
     * Get records from a table into an array.  If not all results are returned on the first call then
	 * additional calls are made until all results are retrieved.
	 * 
	 * CAUTION: This could cause out-of-memory errors if too many results are retrieved.
	 * @param secondaryIndexName Name of the secondary index to use.
	 * @param keyConditionExpression Expression to filter which key values are included in the results.
	 * @param filterExpression Expression to filter which non-key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @param expressionAttribteNames Map of names used in expressions to their real names ie. when the real name is a reserved word.
	 * @param limit Maximum number of objects to return, 0 means unlimited.
	 * @param scanIndexForward True if index should be scanned in ascending order.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
     */
    queryAll( keyConditionExpression: string, filterExpression: string, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, expressionAttributeNames: DynamoDB.DocumentClient.ExpressionAttributeNameMap = null, secondaryIndexName: string=null, limit=0, scanIndexForward=true ): Promise<T[]> {
    	return new Promise( (resolve, reject) => {
            var params = this.getQueryParams( keyConditionExpression, filterExpression, expressionAttributeValues, expressionAttributeNames, secondaryIndexName, null, limit, scanIndexForward );
			let objects: T[] = [];
			this.queryRecursively( params, objects )
			.then( result => {
				// console.log( this.tableName + '.query (for more results) returns ' + JSON.stringify( objects, null, 2 ) );
				resolve( result );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	/**
	 * Recursive function that queries table until all results have been retrieved.
	 * @param params Query parameters.
	 * @param objects Array of retrieved objects.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
	 */
	private queryRecursively( params: DynamoDB.DocumentClient.QueryInput, objects: T[] ): Promise<T[]> {
    	return new Promise( (resolve, reject) => {
			new AWSRequestor().send( new DynamoDB.DocumentClient().query( params ) )
			.then( data => {
				// console.log( this.tableName+'.queryRecursively data: '+JSON.stringify( data ) );
				if (data.Items) {
					data.Items.forEach( item => {
						objects.push( this.fromDataItem( item ) );
					})
				}
				// Use BatchWriteItem to delete up to 25 records at a time efficiently and in parallel.
				// console.log( this.tableName + '.queryTable with exclusiveStartKey ' + JSON.stringify( params.ExclusiveStartKey, null, 2 ) + ' returned ' + data.Items.length + ' items and lastEvaluatedKey ' + JSON.stringify( data.LastEvaluatedKey, null, 2 ) + '.' );
				let limitReached = params.Limit && params.Limit > 0 && params.Limit <= objects.length;
				if (data.LastEvaluatedKey && !limitReached) {
					params.ExclusiveStartKey = data.LastEvaluatedKey;
					this.queryRecursively( params, objects )
					.then( result => {
						// console.log( this.tableName + '.query (for more results) returns ' + JSON.stringify( objects, null, 2 ) );
						resolve( objects );
					})
					.catch( err => {
						reject( err );
					})
				} else {
					// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
					resolve( objects );
				}
			})
			.catch( err => reject( err ) );
        });
	}

    /**
     * Perform an atomic updateof the value of the given property of the given record.
	 * 
	 * @param object Object containing the key values of the record to update.
	 * @param propertyName Name of the property whose value is to be incremented.
	 * @param propertyValue New property value.
	 * @return Updated item.
     */
	 updatePropertyValue( object: T, propertyName: string, propertyValue: string ): Promise<T> {
    	return new Promise( (resolve, reject) => {
			var params: DynamoDB.DocumentClient.UpdateItemInput = {
				TableName: this.getTableName(),
				Key: object.getKey(),
				UpdateExpression: 'SET #propertyName = :val',
				ExpressionAttributeNames: { '#propertyName': propertyName },
				ExpressionAttributeValues: { ':val': propertyValue },
				ReturnValues: 'ALL_NEW',
			};
			new AWSRequestor().send( new DynamoDB.DocumentClient().update( params ) )
			.then( data => resolve( this.fromDataItem( data.Attributes ) ) )
			.catch( error => reject( error ));
        });
    }

    /**
     * Perform an atomic update to increment the value of a field in a record.
	 * This is not idempotent so if it gets called more than once the value will be updated more than once.
	 * If the record does not exist, it will be inserted and the property to be incremented will
	 * be set to the increment amount.
	 * 
	 * @param object Object containing the key values of the record to update.
	 * @param propertyName Name of the property whose value is to be incremented.
	 * @param amount Amount to increment the property value.
	 * @return Updated item.
     */
   incrementValue( object: T, propertyName: string, amount: number=1 ): Promise<T> {
		// console.log( 'incrementValue( '+JSON.stringify(keyObject,null,2)+', '+propertyName+')');
    	return new Promise( (resolve, reject) => {
			var params: DynamoDB.DocumentClient.UpdateItemInput = {
				TableName: this.tableName,
				Key: object.getKey(),
				// The following line works but only sets the key values when the record does not exist
				// UpdateExpression: 'SET #propertyName = if_not_exists(#propertyName, :zero) + :incr',
				UpdateExpression: 'SET #propertyName = #propertyName + :incr',
				ExpressionAttributeNames: { '#propertyName': propertyName },
				// ExpressionAttributeValues: { ':incr': amount, ':zero': 0 },
				ExpressionAttributeValues: { ':incr': amount },
				ReturnValues: 'ALL_NEW',
			};
			new AWSRequestor().send( new DynamoDB.DocumentClient().update( params ) )
			.then( data => resolve( this.fromDataItem( data.Attributes ) ) )
			.catch( error => {
				if (error.code == 'ValidationException') {
					// The record to be incremented did not exist (or at least the property didn't), try inserting it
					let conditionExpression = "attribute_not_exists("+propertyName+")";
					// Assume this is a new object so after updating the property will equal the amount
					object[propertyName] = amount;
					this.put( object, conditionExpression )
					.then( () => resolve( object ) )
					.catch( error => {
						if (error.code == 'ConditionalCheckFailedException') {
							// Another user inserted the record, update it
							new AWSRequestor().send( new DynamoDB.DocumentClient().update( params ) )
							.then( data => resolve( this.fromDataItem( data.Attributes ) ) )
							.catch( err => reject( err ) );
						} else {
							reject( error );
						}
					})
				} else {
					reject( error );
				}
			});
        });
    }

    /**
     * Perform an atomic update to increment the value of a field in a record.
	 * This is not idempotent so if it gets called more than once the value will be updated more than once.
	 * 
	 * @param keyObject Object containing the key values of the record to update.
	 * @param scanIndexForward True if index should be scanned in ascending order.
	 * @return Updated item.
     */
	addToValue( keyObject: T, propertyName: string, amount: number=1 ): Promise<T> {
		// console.log( 'incrementValue( '+JSON.stringify(keyObject,null,2)+', '+propertyName+')');
    	return new Promise( (resolve, reject) => {
			var params: DynamoDB.DocumentClient.UpdateItemInput = {
				TableName: this.tableName,
                Key: keyObject.getKey(),
				UpdateExpression: 'ADD #propertyName :incr',
				ExpressionAttributeNames: { '#propertyName': propertyName },
				ExpressionAttributeValues: { ':incr': amount },
				ReturnValues: 'ALL_NEW',
			};
			new AWSRequestor().send( new DynamoDB.DocumentClient().update( params ) )
			.then( data => {
				// console.log( 'addToValue( '+JSON.stringify(data,null,2)+', '+propertyName+')');
				resolve( this.fromDataItem( data.Attributes ) );
			 })
			.catch( err => reject( err ) );
        });
    }

    /**
     * Get the record for each of the given key values.
     * @param keyObjects Array of objects with values in the properties used in the primary key.
     */
    batchGetAll( keyObjects: T[] ): Promise<T[]> {
    	return new Promise( (resolve, reject) => {
			let objects: T[] = [];
			if (keyObjects.length == 0) {
				resolve( objects );
			} else {
				var params: DynamoDB.BatchGetItemInput = { RequestItems: {} };
				params.RequestItems[this.tableName] = { Keys: [] };
				keyObjects.forEach( keyObject => {
					params.RequestItems[this.tableName].Keys.push( keyObject.getKey() );
				});
				this.batchGetRecursively( params.RequestItems, objects, 0 )
				.then( () => {
					resolve( objects );
				})
				.catch( error => {
					reject( error );
				});
			}
        });
    }

    private batchGetRecursively( requestItems: DynamoDB.BatchGetRequestMap, objects: T[], delayMillis: number ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
			// console.log( 'batchGetRecursively requestItems='+JSON.stringify(requestItems,null,2) );
			setTimeout( () => {
				var params: DynamoDB.BatchGetItemInput = { RequestItems: requestItems };
				new AWSRequestor().send( new DynamoDB.DocumentClient().batchGet( params ) )
				.then( (data: DynamoDB.BatchGetItemOutput) => {
					// console.log( 'batchGet returned '+JSON.stringify( data,null,2) );
					if (data.Responses && data.Responses[this.tableName]) {
						data.Responses[this.tableName].forEach( item => {
							objects.push( this.fromDataItem( item ) );
						})
					}
					if (data.UnprocessedKeys && data.UnprocessedKeys[this.tableName] && data.UnprocessedKeys[this.tableName].Keys && data.UnprocessedKeys[this.tableName].Keys.length > 0) {
						// Not all keys were processed, call function again with exponential backoff delay
						if (delayMillis == 0) {
							delayMillis = 250;
						} else if (delayMillis < 4000) {
							delayMillis *= 2;
						}
						return this.batchGetRecursively( data.UnprocessedKeys, objects, delayMillis )
					} else {
						// console.log( this.tableName+'.get returns ' + JSON.stringify( workedShift, null, 2 ) );
						return undefined;
					}
				})
				.then( () => {
					// console.log( 'batchGetRecursively resolved objects='+JSON.stringify(objects,null,2) );
					resolve();
				})
				.catch( err => reject( err ) );
			}, delayMillis );
        });
    }

	deleteAllDataForCompany( companyId: number ): Promise<number> {
    	return new Promise<number>( (resolve, reject) => {
			let filterExpression = "companyId = :companyId";
			let expressionAttributeValues = { ":companyId": companyId };
			this.deleteByScan( filterExpression, expressionAttributeValues )
			.then( deleteCount => {
				Global.log( 'Deleted ' + deleteCount + ' ' + this.tableName + ' table objects for company '+companyId );
				resolve( deleteCount ) 
			})
			.catch( error => {
				Global.log( 'Error deleting all company data from table '+this.tableName+' for company '+companyId+'. '+error);
				reject( error );
			});
		});
	}

	deleteAllDataForProperty( companyId: number, propertyId: number ): Promise<number> {
    	return new Promise<number>( (resolve, reject) => {
			// let filterExpression = "companyId_propertyId = :companyId_propertyId";
			// let expressionAttributeValues = { ":companyId_propertyId": companyId + '_' + propertyId };
			let filterExpression = "companyId = :companyId and propertyId = :propertyId";
			let expressionAttributeValues = { ":companyId": companyId, ":propertyId": propertyId };
			this.deleteByScan( filterExpression, expressionAttributeValues )
			.then( deleteCount => {
				Global.log( 'Deleted ' + deleteCount + ' ' + this.tableName + ' table objects for property '+propertyId+' at company '+companyId );
				resolve( deleteCount ) 
			})
			.catch( error => {
				Global.log( 'Error deleting all property data from table '+this.tableName+' for property '+propertyId+' at company '+companyId+'. '+error);
				reject( error );
			});
		});
	}

    /**
     * Read object from a table without using any index.
	 * 
	 * @param filterExpression Expression to filter which key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @return Number of objects deleted.
     */
    scan( filterExpression: string = null, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap = null, limit=0, exclusiveStartKey: DynamoDB.DocumentClient.Key = null ): Promise<TableHandlerQueryResult<T>> {
    	return new Promise( (resolve, reject) => {
			// var params = this.getQueryParams( keyConditionExpression, expressionAttributeValues, expressionAttributeNames, secondaryIndexName, null );
			var params: DynamoDB.DocumentClient.ScanInput = {
				TableName: this.tableName,
				ReturnConsumedCapacity: "INDEXES",
			};
			if (filterExpression) {
				params.FilterExpression = filterExpression;
			}
			if (expressionAttributeValues) {
				params.ExpressionAttributeValues = expressionAttributeValues;
			}
			if (exclusiveStartKey) {
				params.ExclusiveStartKey = exclusiveStartKey;
			}
			if (limit) {
				params.Limit = limit;
			}
			let objects: T[] = [];
			// console.log( 'scan params='+JSON.stringify( params, null, 2 ) );
			new AWSRequestor().send( new DynamoDB.DocumentClient().scan( params ) )
			.then( data => {
				if (data.Items) {
					data.Items.forEach( item => {
						objects.push( this.fromDataItem( item ) );
					})
				}
				// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
				resolve( new TableHandlerQueryResult( objects, data.LastEvaluatedKey ) );
			})
			.catch( err => reject( err ) );
        });
    }

    /**
     * Get records from a table into an array.  If not all results are returned on the first call then
	 * additional calls are made until all results are retrieved.
	 * 
	 * CAUTION: This could cause out-of-memory errors if too many results are retrieved.
	 * @param secondaryIndexName Name of the secondary index to use.
	 * @param keyConditionExpression Expression to filter which key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @param expressionAttribteNames Map of names used in expressions to their real names ie. when the real name is a reserved word.
	 * @param limit Maximum number of objects to return, 0 means unlimited.
	 * @param scanIndexForward True if index should be scanned in ascending order.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
     */
    scanAll( filterExpression: string = null, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, limit=0 ): Promise<T[]> {
    	return new Promise( (resolve, reject) => {
			let objects: T[] = [];
			this.scanRecursively( filterExpression, expressionAttributeValues, limit, null, objects )
			.then( result => {
				// console.log( this.tableName + '.query (for more results) returns ' + JSON.stringify( objects, null, 2 ) );
				resolve( result );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	/**
	 * Recursive function that queries table until all results have been retrieved.
	 * @param params Query parameters.
	 * @param objects Array of retrieved objects.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
	 */
	private scanRecursively( filterExpression: string = null, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, limit=0, exclusiveStartKey: DynamoDB.DocumentClient.Key = null, objects: T[] ): Promise<T[]> {
    	return new Promise( (resolve, reject) => {
			this.scan( filterExpression, expressionAttributeValues, limit, exclusiveStartKey )
			.then( results => {
				// console.log( this.tableName+'.queryRecursively data: '+JSON.stringify( data ) );
				results.objects.forEach( object => {
					objects.push( object );
				})
				// Use BatchWriteItem to delete up to 25 records at a time efficiently and in parallel.
				// console.log( this.tableName + '.queryTable with exclusiveStartKey ' + JSON.stringify( params.ExclusiveStartKey, null, 2 ) + ' returned ' + data.Items.length + ' items and lastEvaluatedKey ' + JSON.stringify( data.LastEvaluatedKey, null, 2 ) + '.' );
				let limitReached = limit && limit > 0 && limit <= objects.length;
				if (results.lastEvaluatedKey && !limitReached) {
					this.scanRecursively( filterExpression, expressionAttributeValues, limit, results.lastEvaluatedKey, objects )
					.then( result => {
						// console.log( this.tableName + '.query (for more results) returns ' + JSON.stringify( objects, null, 2 ) );
						resolve( objects );
					})
					.catch( err => {
						reject( err );
					})
				} else {
					// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
					resolve( objects );
				}
			})
			.catch( err => reject( err ) );
        });
	}

    /**
     * Get records from a table into an array.  If not all results are returned on the first call then
	 * additional calls are made until all results are retrieved.
	 * 
	 * CAUTION: This could cause out-of-memory errors if too many results are retrieved.
	 * @param secondaryIndexName Name of the secondary index to use.
	 * @param keyConditionExpression Expression to filter which key values are included in the results.
	 * @param expressionAttributeValues Map names used in the keyConditionExpression to their values.
	 * @param expressionAttribteNames Map of names used in expressions to their real names ie. when the real name is a reserved word.
	 * @param limit Maximum number of objects to return, 0 means unlimited.
	 * @param scanIndexForward True if index should be scanned in ascending order.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
     */
    processScanAll( processRow: ( row: T ) => void, filterExpression: string = null, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, limit=0 ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
			let objects: T[] = [];
			this.processScanRecursively( processRow, filterExpression, expressionAttributeValues, limit, null, objects )
			.then( result => {
				// console.log( this.tableName + '.query (for more results) returns ' + JSON.stringify( objects, null, 2 ) );
				resolve();
			})
			.catch( err => {
				reject( err );
			})
        });
    }

	/**
	 * Recursive function that queries table until all results have been retrieved.
	 * @param params Query parameters.
	 * @param objects Array of retrieved objects.
	 * @return Array of objects read from table.  Array will never be null but will be empty if no records found.
	 */
	private processScanRecursively( processRow: ( row: T ) => void, filterExpression: string = null, expressionAttributeValues: DynamoDB.DocumentClient.ExpressionAttributeValueMap, limit=0, exclusiveStartKey: DynamoDB.DocumentClient.Key = null, objects: T[] ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
			this.scan( filterExpression, expressionAttributeValues, limit, exclusiveStartKey )
			.then( results => {
				// console.log( this.tableName+'.queryRecursively data: '+JSON.stringify( data ) );
				results.objects.forEach( object => {
					processRow( object );
				})
				// Use BatchWriteItem to delete up to 25 records at a time efficiently and in parallel.
				// console.log( this.tableName + '.queryTable with exclusiveStartKey ' + JSON.stringify( params.ExclusiveStartKey, null, 2 ) + ' returned ' + data.Items.length + ' items and lastEvaluatedKey ' + JSON.stringify( data.LastEvaluatedKey, null, 2 ) + '.' );
				let limitReached = limit && limit > 0 && limit <= objects.length;
				if (results.lastEvaluatedKey && !limitReached) {
					this.processScanRecursively( processRow, filterExpression, expressionAttributeValues, limit, results.lastEvaluatedKey, objects )
					.then( result => {
						// console.log( this.tableName + '.query (for more results) returns ' + JSON.stringify( objects, null, 2 ) );
						resolve();
					})
					.catch( err => {
						reject( err );
					})
				} else {
					// console.log( this.tableName + '.query returns ' + JSON.stringify( objects, null, 2 ) );
					resolve();
				}
			})
			.catch( err => reject( err ) );
        });
	}

	doesTableExist(): Promise<boolean> {
		return new Promise<boolean>( (resolve,reject) => {
			new DynamoDBService().describeTable( this.tableName )
			.then( data => resolve( true ) )
			.catch( error => {
				if (error.code === 'ResourceNotFoundException') {
					resolve( false );
				} else {
					reject( error );
				}
			})
		});
	}

	/**
	 * Inserts or updates the given row using a conditional write to make sure we don't overwrite
	 * data between reading the record and writing the record.
	 * If the record does not exist it is inserted.  If the record exists it is passed to the
	 * updateRow function to be updated and then it is save as the next version of the record.
	 * Row must have a "version" property that is used to ensure that writes happen in order.
	 * Fails after 5 retries.
	 * @param newRow Row to insert if it doesn't exist.
	 * @param updateRow Function to update the row if it does exist.
	 */
	public upsertVersionedRow( newRow: T, updateRow: ( row: T ) => T ): Promise<T> {
		return this.upsertVersionedRowRecursive( newRow, updateRow );
	}

	/**
	 * Inserts or updates the given row using a conditional write to make sure we don't overwrite
	 * data between reading the record and writing the record.
	 * If the record does not exist it is inserted.  If the record exists it is passed to the
	 * updateRow function to be updated and then it is save as the next version of the record.
	 * Row must have a "version" property that is used to ensure that writes happen in order.
	 * Fails after 5 retries.
	 * @param newRow Row to insert if it doesn't exist.
	 * @param updateRow Function to update the row if it does exist.
	 * @param retryCount Number of times we have retried the function.
	 */
	private upsertVersionedRowRecursive( newRow: T, updateRow: ( row: T ) => T, retryCount: number = 0 ): Promise<T> {
		return new Promise<T>( (resolve,reject) => {
			// Global.log( 'upsertVersionedRowRecursive( newRow='+JSON.stringify(newRow,null,2)+', retryCount='+retryCount);
			if (retryCount == 5) {
				Global.log( 'Error upserting versioned row due to too many retries. '+JSON.stringify(newRow,null,2)+', retryCount='+retryCount );
				reject( new Error( 'Error upserting versioned row due to too many retries. '+JSON.stringify(newRow,null,2)+', retryCount='+retryCount ) );
			} else {
				// Get the existing daily usage record
				this.get( newRow )
				.then( row => {
					try {
						let conditionExpression = null;
						let expressionAttributeValues = null;
						if (row) {
							// Row already exists, call function to update it
							// Global.log( 'upsertVersionedRowRecursive updateRow row='+JSON.stringify(row,null,2) );
							row = updateRow( row );

							// Increment version number and do a conditional write to prevent overwriting data
							let oldVersion = row["version"];
							row["version"]++;
							conditionExpression = "version = :version";
							expressionAttributeValues = { ":version": oldVersion };
// Force update error by simulating another user changing the version after we read but before we write
// if (retryCount < 2) {
// 	expressionAttributeValues = { ":version": oldVersion-1 };
// }
							// Global.log( 'upsertVersionedRowRecursive after updateRow and set version row='+JSON.stringify(row,null,2) );
						} else {
							// This a new row, save it as version 1 on the condition it does not exist
							// Global.log( 'upsertVersionedRowRecursive newRow' );
							row = newRow;
							row["version"] = 1;
							conditionExpression = "attribute_not_exists(version)";
							// Global.log( 'upsertVersionedRowRecursive after setting version row='+JSON.stringify(row,null,2) );
						}
// // Force error by simulating another user inserting a row that we are about to insert
// this.put( row, conditionExpression, expressionAttributeValues )
// .then( () => {
						// Global.log( 'upsertVersionedRowRecursive put row '+JSON.stringify(row,null,2) );
						this.put( row, conditionExpression, expressionAttributeValues )
						.then( () => resolve( row ) )
						.catch( error => { 
							if (error.code == 'ConditionalCheckFailedException') {
								// Record has been updated by another user since we read it, retry
								Global.log( 'upsertVersionedRowRecursive retry' );
								this.upsertVersionedRowRecursive( newRow, updateRow, retryCount+1 )
								.then( () => resolve( newRow ) )
								.catch( error =>  reject( error ) );
							} else {
								reject( error );
							}
						});
					} catch( error ) {
						// Error while updating row other than it not being the right version, don't retry
						reject( error );
					};
// })
// .catch( error => { Global.logError('ERROR INSERTING TEST ROW', error ); reject( error ); });
				})
				.catch( error =>  reject( error ) );
			}
		});
	}

}
