import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { Property } from './Property';
import { Notifier, TopicSubscription } from './Notifier';

import { ActivitySignUpListTable } from "./ActivitySignUpListTable";
import { ActivityTable } from "./ActivityTable";
import { ActivityTypeTable } from "./ActivityTypeTable";
import { AdPlayTable } from "./AdPlayTable";
import { AdPlayByMonthTable } from "./AdPlayByMonthTable";
import { AlexaDeviceTable } from "./AlexaDeviceTable";
import { DailyUsageTable } from "./DailyUsageTable";
import { EmployeeTable } from "./EmployeeTable";
import { EmployeeFeedbackTable } from "./EmployeeFeedbackTable";
import { FeedbackTable } from "./FeedbackTable";
import { GoogleMaps } from "./GoogleMaps";
import { SiteTable } from "./SiteTable";
import { HousekeepingLogTable } from "./HousekeepingLogTable";
import { PaymentTable } from "./PaymentTable";
import { PersonFaceTable } from "./PersonFaceTable";
import { PersonTable } from "./PersonTable";
import { PropertyAdvertiserTable } from "./PropertyAdvertiserTable";
import { RestaurantLayoutTable } from "./RestaurantLayoutTable";
import { RestaurantReservationTable } from "./RestaurantReservationTable";
import { RoomUsageTable } from "./RoomUsageTable";
import { SiteAdSpendTable } from "./SiteAdSpendTable";
import { SiteUsageTable } from "./SiteUsageTable";
import { SystemTable } from "./SystemTable";
import { TopicTable } from "./TopicTable";
import { WorkedShiftTable } from "./WorkedShiftTable";
import { DetectionTable } from "./DetectionTable";
import { LineTimeTable } from "./LineTimeTable";
import { VisitTable } from "./VisitTable";
import { VisitQueueTable } from "./VisitQueueTable";
import { VisitSummaryTable } from "./VisitSummaryTable";
import { RelatedDataHandler } from './RelatedDataHandler';

/**
 * Contains records for queues created when a user opens a dashboard that needs to be updated when 
 * a new visit starts so we can update the dashboard in real time when a visit starts.
 */
export class PropertyTable extends TableHandler<Property> {

    public constructor() {
		super( 'bookcliffsoftware-Property' );
	}
	
	public fromDataItem( item: any ): Property {
		return new Property().fromDataItem( item );
	}

	public getAll( companyId: number ): Promise<Property[]> {
    	return new Promise<Property[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId = :companyId";
			let expressionAttributeValues = { ":companyId": companyId };
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( sites => resolve( sites ) )
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Update the latitude and longitude values of the property if they have changed.
	 */
	public updateLatitudeAndLongitude( property: Property ): Promise<Property> {
		return new Promise<Property>( (resolve, reject) => {

			let address = Global.getAddressString( property.address1, property.city, property.state, property.postalCode );
			// Global.log( 'Property '+property.propertyId+' '+property.name+' address is '+address );
			new SystemTable().getSystemData()
			.then( system => {
				new GoogleMaps().getLatitudeAndLongitudeOfAddress( address, system )
				.then( results => {
					// Global.log( "Got lat/lon for "+address+": "+JSON.stringify(results,null,2));
					property.latitude = results.latitude;
					property.longitude = results.longitude;
					// Global.log( 'Set property lat/lng to '+property.latitude+','+property.longitude+' for address: '+address );
					resolve( property );
				})
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Overrides generic put to update the email and phone subscriptions to the SNS feedback topic.
	 */
	public put( property: Property ): Promise<Property> {
    	return new Promise<Property>( (resolve, reject) => {
			// Global.log('put property '+JSON.stringify(property,null,2));
			this.updateLatitudeAndLongitude( property )
			.then( property => {
				// Get the old property so we can figure out which subscriptions changed
				super.get( property )
				.then( oldProperty => {
					if (oldProperty) {
						// Global.log('updateProperty '+JSON.stringify(property,null,2));
						this.updateProperty( property )
						.then( savedProperty => resolve( savedProperty ) )
						.catch( error => reject( error ) );
					} else {
						// Global.log('insertProperty '+JSON.stringify(property,null,2));
						this.insertProperty( property )
						.then( savedProperty => resolve( savedProperty ) )
						.catch( error => reject( error ) );
					}
				})
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Insert a new property and the email and phone subscriptions to the SNS feedback topic.
	 */
	private insertProperty( property: Property ): Promise<Property> {
    	return new Promise<Property>( (resolve, reject) => {
			// Create the feedback notification topic
			let topicName = 'feedback_'+property.companyId+'_'+property.propertyId;
			new Notifier().createTopic( topicName )
			.then( topicArn => {
				// Create the subscriptions
				property.feedbackTopicArn = topicArn;
				let promises: Promise<void>[] = [];
				let subscriptions: TopicSubscription[] = [];
				this.addInsertedSubscriptions( property, subscriptions, promises );
				Promise.all( promises )
				.then( results => {
					// Save the property
					// Global.log('Saving property '+JSON.stringify(property,null,2));
					super.put( property )
					.then( savedProperty => {
						// Global.log('Saved property '+JSON.stringify(savedProperty,null,2)); 
						resolve( savedProperty );
					})
					.catch( error => {
						new Notifier().deleteTopic( topicArn );
						reject( error );
					});
				})
				.catch( error => {
					new Notifier().deleteTopic( topicArn );
					reject( error );
				});
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Update a property and the email and phone subscriptions to the SNS feedback topic.
	 */
	public updateProperty( property: Property ): Promise<Property> {
    	return new Promise<Property>( (resolve, reject) => {
			// Global.log('updateProperty '+JSON.stringify(property,null,2));
			super.put( property )
			.then( results => {
				if (property.feedbackTopicArn) {
					// Global.log('updateProperty topic '+property.feedbackTopicArn);
					// Update the subscriptions
					new Notifier().listSubscriptionsByTopic( property.feedbackTopicArn )
					.then( subscriptions => {
						// Global.log( 'Updating SNS subscriptions: '+JSON.stringify(subscriptions,null,2));
						let promises: Promise<void>[] = [];
						this.addInsertedSubscriptions( property, subscriptions, promises );
						this.removeDeletedSubscriptions( property, subscriptions, promises );
						Promise.all( promises )
						.then( results => resolve( property ) )
						.catch( error => reject( error ) );
					})
					.catch( error => reject( error ) );
				} else {
					resolve( property )
				}
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Add property subscriptions that do not already exist in the list of SNS subscriptions.
	 * @param property Property with email and SMS subscriptions.
	 * @param subscriptions List of existing SNS subscriptions.
	 * @param promises List of promises to which SNS subscription changes will be added.
	 */
	private addInsertedSubscriptions( property: Property, subscriptions: TopicSubscription[], promises: Promise<void>[] ) {
		// We no longer add subscriptions for emails because we send the emails directly instead of
		// them being sent as a result of the notification.
		// property.feedbackEmails.forEach( email => {
		// 	Global.log( 'checking email '+email+' for property '+property.propertyId+' subscriptions '+JSON.stringify( subscriptions,null,2));
		// 	if (new Notifier().indexOfEmailSubscriptionInList( email, subscriptions ) == -1) {
		// 		Global.log( 'adding email '+email+' for property '+property.propertyId+' subscriptions '+JSON.stringify( subscriptions,null,2));
		// 		promises.push( new Notifier().addEmailSubscription( email, property.feedbackTopicArn ) );
		// 	}
		// })
		property.feedbackPhones.forEach( phoneNumber => {
			if (new Notifier().indexOfSmsSubscriptionInList( phoneNumber, subscriptions ) == -1) {
				promises.push( new Notifier().addSmsSubscription( phoneNumber, property.feedbackTopicArn ) );
			}
		});
	}

	/**
	 * Remove SNS subscriptions that do not exist in the list of property subscriptions.
	 * Note: Subscriptions that are waiting on confirmation can not be deleted but AWS will delete them
	 * after 3 days if they are still pending.
	 * @param property Property with email and SMS subscriptions.
	 * @param subscriptions List of existing SNS subscriptions.
	 * @param promises List of promises to which SNS subscription changes will be added.
	 */
	private removeDeletedSubscriptions( property: Property, subscriptions: TopicSubscription[], promises: Promise<void>[] ) {
		subscriptions.forEach( subscription => {
			if ('PendingConfirmation' != subscription.SubscriptionArn) {
				// This subscription is confirmed so see if we need to delete it.
				if (subscription.Protocol == Notifier.SMS && property.indexOfFeedbackPhoneNumber( subscription.Endpoint ) == -1) {
					promises.push( new Notifier().deleteSubscription( subscription.SubscriptionArn ) );
				}
				// We no longer delete subscriptions for emails because we send the emails directly instead of
				// them being sent as a result of the notification.
				// if (subscription.Protocol == Notifier.EMAIL && property.indexOfFeedbackEmail( subscription.Endpoint ) == -1) {
				// 	promises.push( new Notifier().deleteSubscription( subscription.SubscriptionArn ) );
				// }
			}
		})
	}

}
