import { Injectable } from "@angular/core";
import { RecorderService } from './recorder.service';
import { Global } from './Global'

/**
 * Represents an audio control that can start and stop recording, 
 * export captured audio, play an audio buffer, and check if audio 
 * is supported.
 */
@Injectable()
export class AudioControlService {

	constructor( public recorder: RecorderService ) {
	}

	/**
	 * This callback type is called `onSilenceCallback`.
	 *
	 * @callback onSilenceCallback
	 */

	/**
	 * Visualize callback: `visualizerCallback`.
	 *
	 * @callback visualizerCallback
	 * @param {Uint8Array} dataArray
	 * @param {number} bufferLength
	 */

	/**
	 * Clears the previous buffer and starts buffering audio.
	 * @param {?onSilenceCallback} onSilence - Called when silence is detected.
	 * @param {?visualizerCallback} visualizer - Can be used to visualize the captured buffer.
	 */
	startRecording(onSilence, visualizer) {
		this.recorder.initialize();
		this.recorder.record(onSilence, visualizer);
	};

	/**
	 * Stops buffering audio.
	 */
	stopRecording() {
		this.recorder.stop();
	};

	/**
	 * On export complete callback: `onExportComplete`.
	 *
	 * @callback onExportComplete
	 * @param {Blob} blob The exported audio as a Blob.
	 */

	/**
	 * Exports the captured audio buffer.
	 * @param {onExportComplete} callback - Called when the export is complete.
	 */
	exportWAV(callback) {
		this.recorder.exportWAV( blob => {
			callback(blob);
		});
	};

	/**
	 * On playback complete callback: `onPlaybackComplete`.
	 *
	 * @callback onPlaybackComplete
	 */

	/**
	 * Plays the audio buffer with an HTML5 audio tag. 
	 * @param {Uint8Array} buffer - The audio buffer to play.
	 * @param {?onPlaybackComplete} callback - Called when audio playback is complete.
	 */
	play(buffer, callback) {
		var myBlob = new Blob([buffer], { type: 'audio/mpeg' });
		var audio = document.createElement('audio');
		var objectUrl = window.URL.createObjectURL(myBlob);
		audio.src = objectUrl;
		audio.addEventListener('ended', function () {
			audio.currentTime = 0;
			if (typeof callback === 'function') {
				callback();
			}
		});
		audio.play();
		this.recorder.clear();
	};

	/**
	 * Checks that getUserMedia is supported and the user has given us access to the mic.
	 * @param {onAudioSupported} callback - Called with the result.
	 */
	supportsAudio(): Promise<boolean> {
		return new Promise<boolean>( (resolve,reject) => {
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				this.recorder.requestDevice()
					.then( stream => { 
						// console.log('Audio is supported.')
						resolve(true); 
					}).catch( error => { 
						Global.logError( 'Error checking for audio support.', error );
						reject( new Error( 'Error checking for audio support: ' + JSON.stringify( error, null, 2 ) ) );
					});
			} else {
				resolve(false);
			}
		});
	};

};