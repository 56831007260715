import { TableHandler, TableDocumentObject } from './TableHandler'
import { AdCategory } from './AdCategory';
import { Global } from './Global';
import { Product } from './Product';
import { AlexaAccount } from './AlexaAccount';
import { SiteKey } from './SiteKey';

/**
 * Properties stored about the system.
 */
export class System extends TableDocumentObject {

	/**
	 * Properties stored about the system.
	 * @param systemId Unique ID of company (partition key)
	 * @param dbVersion Current version of the database
	 * @param dbConversionStartTime Time conversion started as number of milliseconds since January 1, 1970 Universal Coordinated Time (UTC) (or GMT)
	 * @param lastUsedCompanyId Last company ID used.
	 * @param amazonSellerId Amazon seller ID used in collecting payments.
	 * @param amazonPayAccessKey Amaazon Marketplace Web Service access key used in collecting payments.
	 * @param amazonPaySecretKey Amaazon Marketplace Web Service secret key used in collecting payments.
	 * @param products List of products we sell.
	 * @param adCategories List of ad categories used to prompt users to select a category.
	 * @param alexaAccounts List of registered Alexa for Hospitality accounts with their related company and property.
	 * @param siteKeys List of sites participating in What's Happening skill
	 * @param beyondPayUser BeyondPay user id
	 * @param beyondPayPassword BeyondPay password
	 * @param beyondPayPrivateKey BeyondPay private key
	 * @param alexaForHospitalityAccessToken Access token used for A4H V2 API calls.
	 * @param alexaForHospitalityRefreshToken Refresh token used to get access token for A4H V2 API calls.
	 */
	constructor(
		public systemId: number = 1,
		public dbVersion: number = null,
		public dbConversionStartTime: number = null,
		public lastUsedCompanyId: number = 0,
		public amazonSellerId: string = null,
		public amazonPayAccessKey: string = null,
		public amazonPaySecretKey: string = null,
		public googleMapsApiKey: string = null,
		public products: Product[] = [],
		public adCategories: AdCategory[] = [],
		public alexaAccounts: AlexaAccount[] = [],
		public siteKeys: SiteKey[] = [],
		public beyondPayUser: string = null,
		public beyondPayPassword: string = null,
		public beyondPayPrivateKey: string = null,
		public alexaForHospitalityAccessToken: string = null,
		public alexaForHospitalityRefreshToken: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): System {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.products = [];
		if (item['products']) {
			item['products'].forEach( childItem => this.products.push( new Product().fromDataItem( childItem ) ) );
		}
		this.adCategories = [];
		if (item['adCategories']) {
			item['adCategories'].forEach( childItem => this.adCategories.push( new AdCategory().fromDataItem( childItem ) ) );
		}
		this.alexaAccounts = [];
		if (item['alexaAccounts']) {
			item['alexaAccounts'].forEach( childItem => this.alexaAccounts.push( new AlexaAccount().fromDataItem( childItem ) ) );
		}
		this.siteKeys = [];
		if (item['siteKeys']) {
			item['siteKeys'].forEach( childItem => this.siteKeys.push( new SiteKey().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['products'] = null;
		if (this.products) {
			item['products'] = [];
			this.products.forEach( childItem => item['products'].push( childItem.toDataItem() ) );
		}
		item['adCategories'] = null;
		if (this.adCategories) {
			item['adCategories'] = [];
			this.adCategories.forEach( childItem => item['adCategories'].push( childItem.toDataItem() ) );
		}
		item['alexaAccounts'] = null;
		if (this.alexaAccounts) {
			item['alexaAccounts'] = [];
			this.alexaAccounts.forEach( childItem => item['alexaAccounts'].push( childItem.toDataItem() ) );
		}
		item['siteKeys'] = null;
		if (this.siteKeys) {
			item['siteKeys'] = [];
			this.siteKeys.forEach( childItem => item['siteKeys'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            'systemId': this.systemId
        }
    }

	/**
	 * @returns True if we can start a database conversion because the conversion hasn't started 
	 *          or it started more than 5 minutes ago.
	 */
	public hasConversionTimedOut(): boolean {
		return this.dbConversionStartTime == null || (Date.now() - this.dbConversionStartTime) >= ( 5*Global.millisInOneMinute );
	}

	/**
	 * @returns True a conversion has been started within the last 5 minutes.
	 */
	public isConversionRunning(): boolean {
		return this.dbConversionStartTime != null && (Date.now() - this.dbConversionStartTime) < ( 5*Global.millisInOneMinute );
	}

	/**
	 * @param adCategoryId ID of the category to return.
	 * @returns Ad category with the given ID or null if not found.
	 */
	public getAdCategory( adCategoryId: number ): AdCategory {
		let found = null;
		if (this.adCategories) {
			for (let i=0; i<this.adCategories.length; i++) {
				if (this.adCategories[i].adCategoryId == adCategoryId) {
					found = this.adCategories[i];
					break;
				}
			}
		}
		return found;
	}

	public getAdCategoryByName( name: string ): AdCategory {
		let found: AdCategory = null;
		name = name.toLowerCase();
		for (let i=0; i<this.adCategories.length; i++) {
			if (this.adCategories[i].hasNameOrSynonym( name )) {
				found = this.adCategories[i];
				break;
			}
		}
		return found;
	}

	public getAdCategoryBySubcategoryName( name: string ): AdCategory {
		let found: AdCategory = null;
		name = name.toLowerCase();
		for (let i=0; i<this.adCategories.length; i++) {
			let category = this.adCategories[i];
			if (category.subcategories) {
				for (let j=0; j<category.subcategories.length; j++) {
					let subcategory = category.subcategories[j];
					if (subcategory.hasNameOrSynonym( name )) {
						found = category;
						break;
					}
				}
			}
		}
		return found;
	}

	/**
	 * Returns an array of the names of the categories in the given list so Alexa can say the names.
	 * @param list List of ad category names.
	 */
	public getAdCategoryNames() {
		let names: string[] = [];
		for (let i=0; i<this.adCategories.length; i++) {
			names.push( this.adCategories[i].name );
		}
		return names;
	}

	/** Return map of ad category names keyed by the category ID. */
	public getAdCategoryNameMap(): Map<number,string> {
		// Build map of ad category names keyed by adCategoryId
		let map = new Map<number,string>();
		if (this.adCategories) {
			this.adCategories.forEach( adCategory => {
				map.set( adCategory.adCategoryId, adCategory.name );
			});
		}
		return map;
	}

	/** @returns Alexa account with the given ID or null if not found. */
	public getAlexaAccount( accountId: string ): AlexaAccount {
		let found = null;
		if (this.alexaAccounts) {
			for (let i=0; i<this.alexaAccounts.length; i++) {
				if (this.alexaAccounts[i].accountId == accountId) {
					found = this.alexaAccounts[i];
					break;
				}
			}
		}
		// Global.log( 'System.getAlexaAccount( '+accountId+' ) returned '+JSON.stringify(found,null,2));
		return found;
	}

	/**
	 * @param companyId ID of the company.
	 * @param propertyId ID of the property.
	 * @param siteId ID of the site.
	 * @returns Index of the site key with the given company, property, and site ID's or -1 if not found.
	 */
	public getSiteKeyIndex( companyId: number, propertyId: number, siteId: number ): number {
		let found = -1;
		for (let i=0; i<this.siteKeys.length; i++) {
			let key = this.siteKeys[i];
			if (key.companyId == companyId && key.propertyId == propertyId && key.siteId == siteId) {
				found = i;
				break;
			}
		}
		return found;
	}

	/**
	 * @param companyId ID of the company.
	 * @param propertyId ID of the property.
	 * @param siteId ID of the site.
	 * @returns The site key with the given company, property, and site ID's.
	 */
	public getSiteKey( companyId: number, propertyId: number, siteId: number ): SiteKey {
		let index = this.getSiteKeyIndex( companyId, propertyId, siteId );
		return index == -1 ? null : this.siteKeys[index];
	}

	/**
	 * @param name Name to look for.
	 * @param keys Array of site indexes to search.
	 * @returns List of site keys with the given name or empty list if not found.
	 */
	public getSiteKeysByName( name: string, keys: SiteKey[] = null ): SiteKey[] {
		let found = [];
		if (keys == null) {
			// Caller didn't provide a list of keys to search so search all the keys
			keys = this.siteKeys;
		}
		keys.forEach( key => {
			if (key.hasNameOrSynonym( name )) {
				found.push( key );
			}
		});
		return found;
	}

	/**
	 * Returns the list of site keys that are in the given city.  Sites may be venues or cities.
	 * @param name Name to look for.
	 * @param keys List of site keys to filter, if null filter entire list of site keys.
	 * @returns List of site keys with the given name or empty list if not found.
	 */
	public getSiteKeysByCity( cityAndState: string, keys: SiteKey[] = null ): SiteKey[] {
		let foundSites = [];
		if (keys == null) {
			// Caller didn't provide a list of keys to search so search all the keys
			keys = this.siteKeys;
		}
		keys.forEach( key => {
			if (key.matchesCityOrCityAndState( cityAndState )) {
				foundSites.push( key );
			}
		});
		return foundSites;
	}

	/**
	 * @param name Name to look for.
	 * @param keys Array of site indexes to search.
	 * @returns List of site keys with the given name or empty list if not found.
	 */
	public getSiteKeysByNameAndCity( name: string, city: string ): SiteKey[] {
		let found = [];
		let keys = this.getSiteKeysByName( name );
		// Global.log( "got sites for venue "+name+", "+JSON.stringify(keys,null,2));
		if (keys.length > 0) {
			// We found site keys with the given name, see if any of them are in the city the user said
			found = this.getSiteKeysByCity( city, keys );
			if (found.length == 0) {
				// The city name the user said didn't match any of the cites on the keys we found
				// by name so try to find a city key that matches by name in case the user
				// used a synonym for the city name like "Junction" for "Grand Junction".
				let cityKeys = this.getCitySiteKeysByName( city );
				// Global.log( "got sites for city "+city+", "+JSON.stringify(cityKeys,null,2));
				found = this.filterSiteKeysByCitySiteKeys( keys, cityKeys );
			}
		}
		return found;
	}

	/**
	 * Find site keys with the given city name and whose name is the city and state separated by a space.
	 * @param name City Name to look for.
	 * @returns List of site keys with the given name or empty list if not found.
	 */
	private filterSiteKeysByCitySiteKeys( keys: SiteKey[], cityKeys: SiteKey[] ): SiteKey[] {
		// Global.log( "filterSiteKeysByCitySiteKeys keys="+JSON.stringify(keys,null,2)+', cityKeys='+JSON.stringify(cityKeys,null,2));
		let found = [];
		if (keys && cityKeys && keys.length > 0 && cityKeys.length > 0) {
			keys.forEach( key => {
				cityKeys.forEach( cityKey => {
					if (key.matchesCityAndState( cityKey )) {
						found.push( key );
					}
				});
			});
		}
		return found;
	}

	/**
	 * Find site keys with the given city name and whose name is the city and state separated by a
	 * space.  Ie. "Grand Junction Colorado".
	 * @param name City Name to look for.
	 * @returns List of site keys with the given name or empty list if not found.
	 */
	public getStateSiteKeysByName( name: string, keys: SiteKey[] = null ): SiteKey[] {
		let foundSites = [];
		if (keys == null) {
			// Caller didn't provide a list of keys to search so search all the keys
			keys = this.siteKeys;
		}
		keys.forEach( key => {
			if (key.isStateSite() && key.hasNameOrSynonym( name )) {
				foundSites.push( key );
			}
		});
		return foundSites;
	}

	/**
	 * Find site keys with the given city name and whose name is the city and state separated by a
	 * space.  Ie. "Grand Junction Colorado".
	 * @param name City Name to look for.
	 * @returns List of site keys with the given name or empty list if not found.
	 */
	public getCitySiteKeysByName( name: string, keys: SiteKey[] = null ): SiteKey[] {
		let foundSites = [];
		if (keys == null) {
			// Caller didn't provide a list of keys to search so search all the keys
			keys = this.siteKeys;
		}
		keys.forEach( key => {
			// if (key.isCitySite() && key.matchesCityOrCityAndState( cityName )) {
			if (key.isCitySite() && key.hasNameOrSynonym( name )) {
				foundSites.push( key );
			}
		});
		return foundSites;
	}

}
