//import "reflect-metadata";
//import "zone.js";
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";

import {LoginComponent} from "./public/auth/login/login.component";
import {RegisterComponent} from "./public/auth/register/registration.component";
import {ForgotPassword2Component, ForgotPasswordStep1Component} from "./public/auth/forgot/forgotPassword.component";
import {LogoutComponent, RegistrationConfirmationComponent} from "./public/auth/confirm/confirmRegistration.component";
import {ResendCodeComponent} from "./public/auth/resend/resendCode.component";
import {NewPasswordComponent} from "./public/auth/newpassword/newpassword.component";

import {AppComponent} from "./app.component";
import {UserRegistrationService} from "./service/user-registration.service";
import {UserLoginService} from "./service/user-login.service";
import {CognitoUtil} from "./service/cognito.service";
import {AppRoutingModule} from "./app.routes";
import {AboutComponent, HomeComponent, HomeLandingComponent} from "./public/home.component";
import {VipAlertComponent} from "./public/vip-alert.component";
import { ActivitiesComponent } from "./secure/activities/activities.component";
import { ActivityComponent } from "./secure/activity/activity.component";
import { ActivityScheduleComponent } from "./secure/activity-schedule/activity-schedule.component";
import { ActivitySignUpListComponent } from "./secure/activity-signup-list/activity-signup-list.component";
import { ActivitySignUpComponent } from "./secure/activity-signup/activity-signup.component";
import { ActivityTypeComponent } from "./secure/activity-type/activity-type.component";
import { ActivityTypesComponent } from "./secure/activity-types/activity-types.component";
import { AdPlaysComponent } from "./secure/ad-plays/ad-plays.component";
import { PropertyAdvertisersComponent } from "./secure/property-advertisers/property-advertisers.component";
import { PropertyAdvertiserComponent } from "./secure/property-advertiser/property-advertiser.component";
import { ItemCategoryComponent } from "./secure/item-category/item-category.component";
import { ItemComponent } from "./secure/item/item.component";
import { AlexaDocComponent } from "./secure/doc/doc.component";
import { ContactComponent } from "./public/contact/contact.component";
import { InputTextComponent } from "./secure/input-text/input-text.component";
import {MyProfileComponent} from "./secure/profile/myprofile.component";
import {SecureHomeComponent} from "./secure/landing/securehome.component";
import {LineTimerComponent} from "./secure/linetimer/linetimer.component";
import {AnalyticsComponent} from "./secure/analytics/analytics.component";
import {TimeByDaypartChartComponent} from "./secure/timebydaypart/timebydaypart.component";
import {CompareSitesChartComponent} from "./secure/comparesites/comparesites.component";
import {ImageCaptureComponent} from "./secure/imagecapture/image-capture.component";
import {GmailListenerComponent} from "./secure/gmaillistener/gmail-listener.component"
import {TimeclockComponent} from "./secure/timeclock/timeclock.component"
import { TopicComponent } from "./secure/topic/topic.component";
import { TopicsComponent } from "./secure/topics/topics.component";
import { EmployeeComponent } from "./secure/employee/employee.component"
import { EmployeesComponent } from "./secure/employees/employees.component"
import { EmployeePhotoComponent } from "./secure/employee-photo/employee-photo.component"
import {SpeechSynthesizerComponent} from "./secure/speech-synthesizer/speech-synthesizer.component"
import {AudioVisualizerComponent} from "./secure/audio-visualizer/audio-visualizer.component"
import { ConnectComponent } from "./secure/connect/connect.component"
import { PersonDetailComponent } from "./secure/person-detail/person-detail.component"
import { RecognizeComponent } from "./secure/recognize/recognize.component"
import { TitleNavComponent } from "./secure/title-nav/title-nav.component"
import { SampleCameraComponent } from "./secure/sample-camera/sample-camera.component"
import { MotionDetectorComponent } from "./secure/motion-detector/motion-detector.component"
import { CameraSettingsComponent } from "./secure/camera-settings/camera-settings.component"
import { CompanyDetailComponent } from "./secure/company-detail/company-detail.component"
import { CopyPropertyComponent } from "./secure/copy-property/copy-property.component";
import { RoleComponent } from "./secure/role/role.component"
import { SitesComponent } from "./secure/sites/sites.component"
import { SiteComponent } from "./secure/site/site.component"
import { ConfirmComponent } from "./secure/confirm/confirm.component"
import { CheckinMainComponent } from "./secure/checkin-main/checkin-main.component"
import { CheckinCameraComponent } from "./secure/checkin-camera/checkin-camera.component"
import { TestSettingsComponent } from "./secure/test-settings/test-settings.component"
import { EventsComponent } from "./secure/events/events.component"
import { EventComponent } from "./secure/event/event.component"
import { RoomGenieComponent } from "./secure/room-genie/room-genie.component"
import { EmployeeFeedbackDetailComponent } from "./secure/feedback-detail/feedback-detail.component";
import { PropertiesComponent } from "./secure/properties/properties.component"
import { PropertyComponent } from "./secure/property/property.component"
import { PropertySelectorComponent } from "./secure/property-selector/property-selector.component"
import { AnalyticsIntroComponent } from "./public/analytics-intro/analytics-intro.component"
import { ConnectIntroComponent } from "./public/connect-intro/connect-intro.component"
import { RoomGenieHospitalsComponent } from "./public/room-genie-hospitals/room-genie-hospitals.component";
import { RoomGenieIntroComponent } from "./public/room-genie-intro/room-genie-intro.component";
import { RoomGenieUsageComponent } from "./secure/room-genie-usage/room-genie-usage.component";
import { ProductSubscribeComponent } from "./secure/product-subscribe/product-subscribe.component";
import { ProductSubscriptionsComponent } from "./secure/product-subscriptions/product-subscriptions.component";
import { ProductPricingComponent } from "./public/product-pricing/product-pricing.component";
import { PrivacyComponent } from "./public/privacy/privacy.component";
import { WhatsHappeningComponent } from "./public/whats-happening/whats-happening.component";
import { TermsComponent } from "./public/terms/terms.component";
import { FooterComponent } from "./public/footer/footer.component";
import { CheckoutComponent } from "./secure/checkout/checkout.component";
import { CheckoutConfirmComponent } from "./secure/checkout-confirm/checkout-confirm.component";
import { ClosedComponent } from "./secure/closed/closed.component";
import { CompanySelectorComponent } from "./public/company-selector/company-selector.component";
import { ConfirmEmailComponent } from "./public/confirm-email/confirm-email.component";
import { ConfirmEmailDescribeComponent } from "./public/confirm-email-describe/confirm-email-describe.component";
import { CreateAccountComponent } from "./public/create-account/create-account.component";
import { CreateAccountDescribeComponent } from "./public/create-account-describe/create-account-describe.component";
import { CreateAdvertiserAccountComponent } from "./public/create-advertiser-account/create-advertiser-account.component";
import { CreateAdvertiserAccountDescribeComponent } from "./public/create-advertiser-account-describe/create-advertiser-account-describe.component";
import { DefaultAdComponent } from "./secure/default-ad/default-ad.component";
import { RestaurantLayoutsComponent } from "./secure/restaurant-layouts/restaurant-layouts.component";
import { RestaurantLayoutComponent } from "./secure/restaurant-layout/restaurant-layout.component";
import { RestaurantReservationComponent } from "./secure/restaurant-reservation/restaurant-reservation.component";
import { RestaurantReservationsComponent } from "./secure/restaurant-reservations/restaurant-reservations.component";
import { SiteAdComponent } from "./secure/site-ad/site-ad.component";
import { SiteAdPropertyComponent } from "./secure/site-ad-property/site-ad-property.component";
import { SiteHoursComponent } from "./secure/site-hours/site-hours.component";
import { RestaurantSectionComponent } from "./secure/restaurant-section/restaurant-section.component";
import { RestaurantTableTopComponent } from "./secure/restaurant-tabletop/restaurant-tabletop.component";
import { RoleSelectorComponent } from "./secure/role-selector/role-selector.component";
import { RoomGenieMainComponent } from "./secure/room-genie-main/room-genie-main.component";
import { SelectDialogComponent } from './secure/select-dialog/select-dialog.component';
import { SpinnerDialogComponent } from './public/spinner-dialog/spinner-dialog.component';
import { SignupHomeComponent } from './public/signuphome/signuphome.component';
import { TimeInputComponent } from "./secure/time-input/time-input.component";
import { UnsubscribeConfirmComponent } from "./secure/unsubscribe-confirm/unsubscribe-confirm.component";
import { UserComponent } from "./secure/user/user.component";
import { UsersComponent } from "./secure/users/users.component";
import { HousekeepingLogComponent } from "./secure/housekeeping-log/housekeeping-log.component";
import { HousekeepingLogEntryComponent } from "./secure/housekeeping-log-entry/housekeeping-log-entry.component";
import { DateTimeComponent } from "./secure/datetime/datetime.component";
import { FeedbackListComponent } from "./secure/feedback-list/feedback-list.component";
import { RequestInfoComponent } from './public/request-info/request-info.component';
import { RequestRoomGenieVideoComponent } from './public/room-genie-request-video/room-genie-request-video.component';
import { ArticlesComponent } from './public/articles/articles.component';
import { SecureArticlesComponent } from './secure/secure-articles/secure-articles.component';

import { ActivityTable } from './service/ActivityTable';
import { ActivitySignUpListTable } from './service/ActivitySignUpListTable';
import { ActivityTypeTable } from './service/ActivityTypeTable';
import { CompanyTable } from './service/CompanyTable';
import { EmployeeFeedbackTable } from "./service/EmployeeFeedbackTable";
import { EmployeeTable } from "./service/EmployeeTable";
import { EventTable } from "./service/EventTable";
import { HousekeepingLogTable } from "./service/HousekeepingLogTable";
import { PersonFaceTable } from './service/PersonFaceTable';
import { PersonTable } from './service/PersonTable';
import { PropertyTable } from './service/PropertyTable';
import { RestaurantLayoutTable } from './service/RestaurantLayoutTable';
import { RestaurantReservationTable } from './service/RestaurantReservationTable';
import { SiteTable } from './service/SiteTable';
import { SystemTable } from './service/SystemTable';
import { VisitSummaryTable } from './service/VisitSummaryTable';
import { VisitTable } from './service/VisitTable';
import { VisitQueueTable } from './service/VisitQueueTable';
import { WorkedShiftTable } from './service/WorkedShiftTable';
import { UserCompanyTable } from "./service/UserCompanyTable";

import { VisitNotificationQueue } from './service/VisitNotificationQueue';
import { DynamoDBService } from "./service/ddb.service";
import { RekognitionService } from "./service/rekognition.service";
import { S3Service } from "./service/s3.service";
import { SQSService } from "./service/sqs.service";
import { PollyService } from "./service/polly.service";
import { CacheService } from "./service/cache.service";
import { LexService } from "./service/lex.service";
import { AudioControlService } from "./service/audio-control.service";
import { ConversationService } from "./service/conversation.service";
import { RecorderService } from "./service/recorder.service";
import { Global } from "./service/Global";
import {UUIDService} from "./service/uuid.service";
import {GoogleChartsService} from "./service/google-charts.service";
import { FaceDetector } from "./service/FaceDetector";
import { MyDatePickerModule } from 'mydatepicker';
import { PopoverModule } from 'ngx-popover';

@NgModule({
    declarations: [
		ArticlesComponent,
		SecureArticlesComponent,
		ContactComponent,
        NewPasswordComponent,
        LoginComponent,
        LogoutComponent,
        RegistrationConfirmationComponent,
		ResendCodeComponent,
        ForgotPasswordStep1Component,
        ForgotPassword2Component,
        RegisterComponent,
        AboutComponent,
        HomeLandingComponent,
		HomeComponent,
		ActivitiesComponent,
		ActivityComponent,
		ActivityScheduleComponent,
		ActivitySignUpListComponent,
		ActivitySignUpComponent,
		ActivityTypeComponent,
		ActivityTypesComponent,
		AdPlaysComponent,
		AlexaDocComponent,
		AnalyticsIntroComponent,
		CheckoutComponent,
		CheckoutConfirmComponent,
		CompanySelectorComponent,
		ConnectIntroComponent,
		ConfirmEmailComponent,
		ConfirmEmailDescribeComponent,
		CreateAccountComponent,
		CreateAccountDescribeComponent,
		CreateAdvertiserAccountComponent,
		CreateAdvertiserAccountDescribeComponent,
		DateTimeComponent,
		DefaultAdComponent,
		FeedbackListComponent,
		FooterComponent,
		HousekeepingLogComponent,
		HousekeepingLogEntryComponent,
		PrivacyComponent,
		TermsComponent,
		TopicComponent,
		TopicsComponent,
		ProductSubscribeComponent,
		ProductSubscriptionsComponent,
		ProductPricingComponent,
		PropertyAdvertiserComponent,
		PropertyAdvertisersComponent,
		ItemCategoryComponent,
		ItemComponent,
		ClosedComponent,
		RequestInfoComponent,
		RequestRoomGenieVideoComponent,
		RestaurantLayoutComponent,
		RestaurantLayoutsComponent,
		RestaurantReservationComponent,
		RestaurantReservationsComponent,
		SiteAdComponent,
		SiteAdPropertyComponent,
		SiteHoursComponent,
		RestaurantSectionComponent,
		RestaurantTableTopComponent,
		RoleSelectorComponent,
		RoomGenieMainComponent,
		RoomGenieUsageComponent,
		SignupHomeComponent,
		SpinnerDialogComponent,
		TimeInputComponent,
		UnsubscribeConfirmComponent,
		UserComponent,
		UsersComponent,
		RoomGenieHospitalsComponent,
		RoomGenieIntroComponent,
        VipAlertComponent,
        MyProfileComponent,
        SecureHomeComponent,
		LineTimerComponent,
		AnalyticsComponent,
        TimeByDaypartChartComponent,
        CompareSitesChartComponent,
		ImageCaptureComponent,
		InputTextComponent,
        GmailListenerComponent,
        TimeclockComponent,
		EmployeeComponent,
		EmployeesComponent,
		EmployeePhotoComponent,
		SelectDialogComponent,
		SpeechSynthesizerComponent,
		AudioVisualizerComponent,
		ConnectComponent,
		PersonDetailComponent,
		RecognizeComponent,
		CheckinMainComponent,
		CheckinCameraComponent,
		RoleComponent,
		SitesComponent,
		SiteComponent,
		EventsComponent,
		EventComponent,
		ConfirmComponent,
		TitleNavComponent,
		SampleCameraComponent,
		MotionDetectorComponent,
		CameraSettingsComponent,
		TestSettingsComponent,
		CompanyDetailComponent,
		CopyPropertyComponent,
		RoomGenieComponent,
		EmployeeFeedbackDetailComponent,
		PropertiesComponent,
		PropertyComponent,
		PropertySelectorComponent,
		WhatsHappeningComponent,
        AppComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
		AppRoutingModule,
		MyDatePickerModule,
		PopoverModule
    ],
    providers: [
		ActivitySignUpListTable,
		ActivityTable,
		ActivityTypeTable,
		Global,
        CognitoUtil,
        DynamoDBService,
        UserRegistrationService,
        UserLoginService,
        UUIDService,
		GoogleChartsService,
		HousekeepingLogTable,
		RekognitionService,
		RestaurantLayoutTable,
		RestaurantReservationTable,
        S3Service,
		SQSService,
        PollyService,
        CacheService,
		CompanyTable,
		EmployeeTable,
		EmployeeFeedbackTable,
		EventTable,
		PersonFaceTable,
		PersonTable,
		PropertyTable,
		SiteTable,
		SystemTable,
		VisitSummaryTable,
		VisitTable,
		VisitQueueTable,
		WorkedShiftTable,
        UserCompanyTable,
		VisitNotificationQueue,
		FaceDetector,
		LexService,
		AudioControlService,
		ConversationService,
		RecorderService,
	],
    bootstrap: [AppComponent]
})
export class AppModule {
}
