import { TableDocumentObject } from './TableHandler'
import { NamedObject } from './NamedObject';

/** 
 * Properties selected to play ads.
 */
export class AdProperty extends TableDocumentObject implements NamedObject {

	/**
	 * @param companyId ID of advertising company (partition key)
	 * @param propertyId ID of advertising property (partition key)
	 * @param bidCents Amount bid to run ad at property for a month.
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public bidCents: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): AdProperty {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId": this.companyId,
			"propertyId": this.propertyId,
        };
	}
	
	getDisplayName() {
		return this.companyId + ' - ' + this.propertyId;
	}

	getKeyString() {
		return JSON.stringify( this.getKey() );
	}

}
