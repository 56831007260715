import { TableHandler } from './TableHandler'
import { Global } from './Global';
import { HousekeepingLogEntry } from './HousekeepingLogEntry';

/** Times when housekeeping started and finished cleaning a room. */
export class HousekeepingLogTable extends TableHandler<HousekeepingLogEntry> {

	/** Local secondary index use to query activities by HousekeepingLogType. */
	private HousekeepingLogStartTimeIndex = 'companyId_propertyId-startTime_roomNumber-index';
	
		public constructor() {
		super( 'bookcliffsoftware-HousekeepingLog' );
	}
	
	public fromDataItem( item: any ): HousekeepingLogEntry {
		return new HousekeepingLogEntry().fromDataItem( item );
	}

	public getDateRange( companyId: number, propertyId: number, startTime: Date, endTime: Date ): Promise<HousekeepingLogEntry[]> {
    	return new Promise<HousekeepingLogEntry[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and startTime_roomNumber BETWEEN :startTime AND :endTime";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
				":startTime": startTime.toISOString(),
				":endTime": endTime.toISOString(),
			};
			// console.log('Get HK logs: '+JSON.stringify(expressionAttributeValues,null,2));
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.HousekeepingLogStartTimeIndex )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Returns a list of distinct room numbers that accessed the system between the given start and end date.
	 * @param companyId ID of the company
	 */
	public getActiveRoomNumbers( companyId: number, propertyId: number, startDate: Date, endDate: Date ): Promise<Set<string>> {
		return new Promise<Set<string>>( (resolve, reject) => {
			let roomNumbers: Set<string> = new Set();
			this.getDateRange( companyId, propertyId, startDate, endDate )
			.then( entries => {
				entries.forEach( entry => roomNumbers.add( entry.roomNumber ) );
				resolve( roomNumbers );
			})
			.catch( error => reject( error ) );
		});
	}
	
	/**
	 * Get the latest housekeeping log entry for the given room number that started before the current time.
	 * @param companyId Company ID of the room.
	 * @param propertyId Property ID of the room.
	 * @param roomNumber Number of the room.
	 */
	public getLatest( companyId: number, propertyId: number, roomNumber: string ): Promise<HousekeepingLogEntry[]> {
    	return new Promise<HousekeepingLogEntry[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId and begins_with( roomNumber_startTime, :roomNumber )";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
				":roomNumber": roomNumber + '_',
			};
			// console.log('Get HK logs: '+JSON.stringify(expressionAttributeValues,null,2));
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, null, 0, false )
			.then( rows => {
				// Find first row where start time < now
				let foundRows: HousekeepingLogEntry[] = [];
				let now = Date.now();
				for (let i=0; i<rows.length; i++) {
					if (rows[i].startTime.getTime() < now) {
						foundRows.push( rows[i] );
						break;
					}
				}
				resolve( foundRows );
			})
			.catch( error => reject( error ) );
		});
	}

}

