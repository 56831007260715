import {Injectable} from "@angular/core";

// export declare var google:any;
declare var google:any;

/**
 * Service to load the Google chart libraries.
 * The loadLibraries method returns a Promise so callers can draw charts when the promise is
 * fulfilled which means the libraries have finished loading.
 */
@Injectable()
export class GoogleChartsService {
	private loading: boolean = false;
	private loaded: boolean = false;
	private pendingResolveFunctions: any[] = [];
	private pendingRejectFunctions: any[] = [];

    constructor() {
    }

	loadLibraries(): Promise<void> {
		// console.log('GoogleChartsService.loadlibraries');
		let promise = new Promise<void>( (resolve,reject) => {
			try {
				if (this.loading) {
					// We already started the loading process wait for it to complete
					// console.log('GoogleChartsService.loadlibraries - already loading, save resolve and reject functions');
					this.pendingResolveFunctions.push( resolve );
					this.pendingRejectFunctions.push( reject );
				} else if (!this.loaded) {
					// console.log('GoogleChartsService.loadlibraries - loading libraries...');
					if (!google) {
						console.log('google variable not initialized yet, wait 1 second then retry.');
						this.pendingResolveFunctions.push( resolve );
						this.pendingRejectFunctions.push( reject );
						setTimeout( () => this.loadLibraries(), 1000);
					} else {
						this.loading = true;
						google.charts.load('current');
						google.charts.setOnLoadCallback( () => {
							// We are done loading libraries so call the resolve functions of all the promises made during loading
							// console.log('GoogleChartsService.loadlibraries - finished loading libraries');
							this.loading = false;
							this.loaded = true;
							// Resolve this promise since it is now fulfilled
							resolve();
							// Resolve promises from other calls to loadLibraries while libraries were loading
							this.pendingResolveFunctions.forEach(pendingResolveFunction => {
								pendingResolveFunction();
							});
						});
					}
				} else {
					// console.log('GoogleChartsService.loadlibraries - already loaded, resolve immediately');
					resolve();
				}
			} catch( err ) {
				let reason = 'Error loading Google charts library.  ' + (err instanceof Error ? err.message : JSON.stringify( err ) );
				console.log( reason, (err instanceof Error ? err.stack : JSON.stringify( err ) ))
				reject( new Error( reason ) );
				// We failed to load libraries so call the reject functions of all the promises made during loading
				this.pendingRejectFunctions.forEach(pendingRejectFunction => {
					pendingRejectFunction();
				});
			}
		});
		return promise;
	};
	
	createChartWrapper( options: any ) {
		return new google.visualization.ChartWrapper( options );
	}

	addListener( chartWrapper, eventName, callback: Function ) {
		google.visualization.events.addListener( chartWrapper, eventName, callback );
	}

	createDataTable() {
		return new google.visualization.DataTable();
	}

}
