import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Global } from "../../service/Global";
import { CacheService } from "../../service/cache.service";
import { Editor } from '../../service/Editor';
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { RestaurantReservationComponent } from "../restaurant-reservation/restaurant-reservation.component";
import { RestaurantReservation } from '../../service/RestaurantReservation';
import { RestaurantReservationList } from '../../service/RestaurantReservationList';
import { Site } from '../../service/Site';
import { SiteHours } from '../../service/SiteHours';
import { Property } from '../../service/Property';
import { GetSitesCmd } from '../../command/GetSitesCmd';
import { GetRestaurantReservationListCmd } from '../../command/GetRestaurantReservationListCmd';
import { IMyDpOptions } from 'mydatepicker';
import { MakeRestaurantReservationCmd } from "../../command/MakeRestaurantReservationCmd";
import { CancelRestaurantReservationCmd } from "../../command/CancelRestaurantReservationCmd";

class ReservationTime {

	public reservations: RestaurantReservation[] = [];

	constructor( public time: Date ) {}

}

@Component({
    selector: 'restaurant-reservations',
    templateUrl: './restaurant-reservations.html',
	styleUrls: ['./restaurant-reservations.css'],
})
export class RestaurantReservationsComponent extends Editor<RestaurantReservation> implements OnInit, AfterViewInit {

	/** 
	 * When user clicks on one of the times on the page instead of the add button, the selected 
	 * time will be the default for adding a new reservation. 
	 */
	private selectedTime: Date;
	
	/** Reservation list being edited. */
	private reservationList: RestaurantReservationList = null;

	/** List of properties of the current company. */
	private properties: Property[] = [];

	/** Currently selected property ID. */
	private propertyId: number;

	/** List of restaurant sites in the current company. */
	private sites: Site[] = [];

	/** Currently selected restaurant site. */
	private site: Site = null;

	/** Currently selected restaurant site ID. */
	private siteId: number = null;

	// Data model used for date picker.
	private reservationDate: Date = new Date();
	
	// Data model used for date picker.
	private reservationDateModel = { jsdate: new Date( this.reservationDate.getTime() ) };
	
	/** List of eatings at the current restaurant. */
	private hours: SiteHours[] = [];

	/** Currently selected block of open hours. */
	private siteHours: SiteHours = null;

	/** Currently selected open period start time. */
	private openTime: string = null;

	/** Array of times to display that may have reservations. */
	private times: ReservationTime[] = [];

	// Timezone for the property
	private timeZone;

	@ViewChild(RestaurantReservationComponent) private detailComponent: RestaurantReservationComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-calendar-check-o fa-fw"></i> Reservations' );
	}

	initialize() {
		this.properties = this.cache.getProperties();
		if (this.properties.length > 0) {
			// Set selected property to the first one in the list
			this.propertyId = this.properties[0].propertyId;
		}
		this.switchProperty( null );
	}

	private loadRestaurants() {
		this.sites = [];
		this.site = null;
		this.siteId = null;
		this.hours = [];
		new GetSitesCmd().do( this.cache.currentCompany.companyId, this.propertyId )
		.then( rows => {
			// Filter out sites that don't have hours and a layout since they can't accept reservations
			this.sites = [];
			rows.forEach( site => {
				if (site.hours != null) {
					let hasHoursAndLayout = false;
					site.hours.forEach( block => {
						if (block.layoutId != null) {
							hasHoursAndLayout = true;
						}
					});
					if (hasHoursAndLayout) {
						// This site is a restaurant with hours and a layout, add it to the list
						this.sites.push( site );
					}
				}
			});

			if (this.sites.length > 0) {
				this.siteId = this.sites[0].siteId;
			}
			this.switchSite( null );
		})
	}

	private switchProperty( event ) {
		try {
			this.propertyId = this.propertyId == null ? 0 : Number.parseInt( this.propertyId.toString() );
			this.errorMessage = null;
			this.timeZone = this.cache.getTimeZone( this.propertyId );
			this.loadRestaurants();
		} catch( error ) { this.handleError( error, false ); }
	}

	/** Return list of sites that have open/close times and a layout. */
	private restaurants: Site[] = null;

	private switchSite( event ) {
		try {
			this.errorMessage = null;
			// Set the current restaurant from the selected restaurant ID
			this.site = null;
			this.hours = [];
			for (let i=0; i<this.sites.length; i++) {
				if (this.sites[i].siteId == this.siteId) {
					this.site = this.sites[i];
					this.hours = this.site.hours;
					if (this.hours.length > 0) {
						this.siteHours = this.hours[0];
						this.openTime = this.siteHours.startDate.toISOString();
					}
					break;
				}
			}
			this.switchOpenTime( null );
		} catch( error ) { this.handleError( error, false ); }
	}

	private switchReservationDate( event ) {
		try {
			this.errorMessage = null;
			this.reservationDate = event.jsdate ? new Date( event.jsdate.getTime() ) : null;
			this.updateDetailComponentValues();
			this.loadRows();
		} catch( error ) { this.handleError( error, false ); }
	}

	private switchOpenTime( event ) {
		try {
			this.errorMessage = null;
			if (this.site) {
				let selectedTime = new Date( this.openTime );
				for (let i=0; i<this.site.hours.length; i++) {
					let seating = this.site.hours[i];
					if (seating.startDate.getTime() == selectedTime.getTime()) {
						this.siteHours = seating;
						this.updateDetailComponentValues();
					}
				}
				this.loadRows();
			} else {
				this.errorMessage = 'No restaurant selected.';
			}
		} catch( error ) { this.handleError( error, false ); }
	}

	private updateDetailComponentValues() {
		this.detailComponent.timeZone = this.timeZone;
		this.detailComponent.seating = this.siteHours;
		this.detailComponent.reservationDate = this.reservationDate;
	}

	getHoursString( hours: SiteHours ): string {
		return hours.getHoursString( this.timeZone )
	}

	getTimeString( time: ReservationTime ) {
		return Global.getHM12TimeString( time.time, this.timeZone );
	}

	getRowTitle( row: RestaurantReservation ): string {
		return Global.getHM12TimeString( row.startTime, this.timeZone ) + ' - Room ' + row.roomNumber + ' - Persons ' + row.persons
	}

	getRowSubtitle( row: RestaurantReservation ): string {
		return row.name;
	}
	
	getDetailComponent(): RestaurantReservationComponent {
		return this.detailComponent;
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	getRowLoader(): Promise<RestaurantReservation[]> {
		return new Promise<RestaurantReservation[]>( (resolve,reject) => {
			let rows: RestaurantReservation[] = [];
			this.times = [];
			if (this.propertyId && this.site && this.siteHours && this.openTime && this.reservationDate) {
				// console.log('Load reservations for company '+this.site.companyId+' property '+this.site.propertyId+' site '+this.site.siteId );
				let reservationListStartTime = this.siteHours.getReservationListStartDate( Global.setTimeToMatch( this.siteHours.startDate, this.reservationDate, this.timeZone ), this.timeZone );
				let reservationListEndTime = this.siteHours.getReservationListEndDate( reservationListStartTime, this.timeZone );
				if (this.siteHours.isOpenOnDate( reservationListStartTime, this.timeZone )) {
					let key = new RestaurantReservationList( this.cache.currentCompany.companyId, this.propertyId, this.site.siteId, reservationListStartTime );
					new GetRestaurantReservationListCmd().do( key, reservationListEndTime, this.siteHours.layoutId, this.siteHours.intervalMinutes )
					.then( row => {
						this.reservationList = row;
						// console.log('Res list: '+JSON.stringify(row,null,2));

						// Convert reservation list into list of times containing lists of reservations for display
						let startTimeMillis = reservationListStartTime.getTime();
						let endTimeMillis = reservationListEndTime.getTime();
						let incrementMillis = this.siteHours.intervalMinutes * Global.millisInOneMinute;
						// console.log( 'seating start time '+startTime.toISOString()+', end time '+seatingEndTime.toISOString()+', intervalMinutes='+this.seating.intervalMinutes);
						for (let time=startTimeMillis; time<= endTimeMillis; time += incrementMillis) {
							this.times.push( new ReservationTime( new Date( time ) ) );
						}

						// Add individual reservations to time table
						this.reservationList.tables.forEach( table => {
							table.getReservations().forEach( reservation => {
								this.addReservationToTimeTable( reservation );
							})
						})
					})
				} else {
					this.errorMessage = 'The restaurant is closed that day.';
				}
				resolve( rows );
			} else {
				// this.errorMessage = 'Please select restaurant and seating';
				resolve( rows );
			}
		});
	}

	createNewRowToEdit(): RestaurantReservation {
		return new RestaurantReservation( this.selectedTime );
	}

	getRoomNumberDisplayString( roomNumber: string ) {
		return roomNumber == null ? '' : roomNumber + ' - ';
	}

	editRow( reservation: RestaurantReservation ) {
		try {
			this.errorMessage = null;
			// console.log( 'editRow index='+index+', reservation='+JSON.stringify(reservation,null,2));
			this.rows[0] = reservation;
		} catch( error ) { this.handleError( error, false ); }
		this.edit( 0 );
	}

	private addReservationToTimeTable( reservation: RestaurantReservation ) {
		for (let i=0; i<this.times.length; i++) {
			if (i == this.times.length-1 || (this.times[i].time.getTime() <= reservation.startTime.getTime() && this.times[i+1].time.getTime() > reservation.startTime.getTime())) {
				this.times[i].reservations.push( reservation );
				break;
			};
		}
	}

	private removeReservationFromTimeTable( reservation: RestaurantReservation ) {
		let reservationString = JSON.stringify(reservation);
		// console.log( 'removeReservationFromTimeTable reservation='+reservationString);
		for (let i=0; i<this.times.length; i++) {
			if (i == this.times.length-1 || (this.times[i].time.getTime() <= reservation.startTime.getTime() && this.times[i+1].time.getTime() > reservation.startTime.getTime())) {
				for (let j=0; j<this.times[i].reservations.length; j++) {
					let res = this.times[i].reservations[j];
					if (reservationString == JSON.stringify(res)) {
						// console.log( 'removeReservationFromTimeTable deletes '+JSON.stringify(res));
						this.times[i].reservations.splice( j, 1 );
						break;
					}
				}
			};
		}
	}

	prepareRowToInsert( newRow: RestaurantReservation ) {
	}

	insertAtKnownTime( time: Date ) {
		try {
			this.errorMessage = null;
			this.selectedTime = time;
			super.insert();
		} catch( error ) { this.handleError( error, false ); }
	}

	insert() {
		try {
			this.errorMessage = null;
			this.selectedTime = null;
			super.insert();
		} catch( error ) { this.handleError( error, false ); }
	}

	getRowInserter( row: RestaurantReservation ): Promise<RestaurantReservation> {
		// console.log('getRowInserter row='+JSON.stringify(row,null,2));
		return new Promise<RestaurantReservation>( (resolve,reject) => {
			let oldReservation = this.detailComponent.originalRow.persons == null ? null : this.detailComponent.originalRow;
			let key = new RestaurantReservationList( this.reservationList.companyId, this.reservationList.propertyId, this.reservationList.siteId, this.reservationList.startTime );
			// console.log('Before reservation: '+JSON.stringify(this.reservationList,null,2));
			// Global.log( 'MakeRestaurantReservationCmd reservation='+JSON.stringify(row,null,2)+', oldReservation='+JSON.stringify(oldReservation,null,2));
			new MakeRestaurantReservationCmd().do( key, this.reservationList.endTime, this.siteHours.layoutId, this.siteHours.intervalMinutes, row, oldReservation, true )
			.then( response => {
				// console.log( 'getRowInserter response='+JSON.stringify(response,null,2));
				if (response.tableTop) {
					// Reservation has been saved
					this.reservationList = response.reservationList;
					if (oldReservation) {
						this.removeReservationFromTimeTable( oldReservation );
					}
					this.addReservationToTimeTable( row );
					// console.log('After reservation: '+JSON.stringify(this.reservationList,null,2));
				} else if (response.alternateEarlierTime && response.alternateLaterTime) {
					let requestedTime = Global.getHM12TimeString( row.startTime, this.timeZone );
					let earlierTime = Global.getHM12TimeString( response.alternateEarlierTime, this.timeZone );
					let laterTime = Global.getHM12TimeString( response.alternateLaterTime, this.timeZone );
					this.detailComponent.errorMessage = requestedTime+' is not available.  There are tables available at '+earlierTime+' and '+laterTime+'.';
				} else if (response.alternateEarlierTime) {
					let requestedTime = Global.getHM12TimeString( row.startTime, this.timeZone );
					let earlierTime = Global.getHM12TimeString( response.alternateEarlierTime, this.timeZone );
					this.detailComponent.errorMessage = requestedTime+' is not available.  There are tables available earlier at '+earlierTime+'.';
				} else if (response.alternateLaterTime) {
					let requestedTime = Global.getHM12TimeString( row.startTime, this.timeZone );
					let laterTime = Global.getHM12TimeString( response.alternateLaterTime, this.timeZone );
					this.detailComponent.errorMessage = requestedTime+' is not available.  There are tables available later at '+laterTime+'.';
				} else {
					this.detailComponent.errorMessage = 'There are no times available.  Please try a different day.';
				}
				resolve( row );
			})
			.catch( error => {
				Global.logError("Error checking reservation availability.", error );
				this.detailComponent.errorMessage = 'There was a problem making the restaurant reservation.  Please try again.';
				resolve();
			})
		})
	}

	getRowUpdater( index: number, row: RestaurantReservation ): Promise<RestaurantReservation> {
		return this.getRowInserter( row );
	}

	getRowDeleter( index: number, row: RestaurantReservation ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			let key = new RestaurantReservationList( this.reservationList.companyId, this.reservationList.propertyId, this.reservationList.siteId, this.reservationList.startTime );
			new CancelRestaurantReservationCmd().do( key, row )
			.then( deleted => {
				// console.log( 'getRowInserter response='+JSON.stringify(response,null,2));
				if (deleted) {
					this.removeReservationFromTimeTable( row );
				} else {
					this.detailComponent.errorMessage = 'Sorry, there was a problem deleting the reservation, please try again.';
				}
				resolve();
			})
			.catch( error => {
				Global.logError("Error cancelling restaurant reservation", error );
				this.detailComponent.errorMessage = 'There was a problem cancelling the reservation.  Please try again.';
				resolve();
			})
		})
	}

}
