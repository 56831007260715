import { Component } from "@angular/core";
import { Global } from '../../service/Global';
import { TimeInputModel } from '../../service/TimeInputModel';

/**
 * Lets user enter a date and time with one minute resolution. */
@Component({
    selector: 'time-input',
	templateUrl: './time-input.html',
	styleUrls: [ 'time-input.css' ]
})
export class TimeInputComponent {
	
	/** Data model used for editing. */
	private model: TimeInputModel = new TimeInputModel( null, null );

	constructor() {
	}

	/** Returns UTC time. */
	public getTime(): Date {
		return this.model.getTime();
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 * @param timeZone Time zone for which data is to be displayed.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	setTime( time: Date, timeZone: string ): void {
		this.model.setTime( time, timeZone );
	}

	/** Returns true if hour or minute field is null. */
	public isNull(): boolean {
		return this.model.isNull();
	}

}