import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { PropertyComponent } from "../property/property.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";
import { Property } from '../../service/Property';

export class Attribute {
	constructor(
    	public name: string,
		public value: string
	) {}
}

@Component({
    selector: 'properties',
    templateUrl: './properties.html',
	styleUrls: ['./properties.css'],
})
export class PropertiesComponent extends Editor<Property> implements OnInit, AfterViewInit {

	@ViewChild(PropertyComponent) private detailComponent: PropertyComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Edit Properties' );
	}

	initialize() {
		this.loadRows();
	}

	/** Returns component used to edit the selected row. */
	getDetailComponent(): EditorDetail<Property> {
		return this.detailComponent;
	}

	/** Returns promise of array of rows to edit. */
	getRowLoader(): Promise<Property[]> {
		let properties: Property[] = [];
		this.cache.properties.forEach( property => properties.push( property ) );
		properties.sort( (a, b) => {
			let aName = a.name.toLowerCase();
			let bName = b.name.toLowerCase();
			return aName < bName ? -1 : aName > bName ? 1 : 0;
		});
		// console.log("properties sorted by name="+JSON.stringify(properties,null,2));
		return Promise.resolve( properties );
	}
	
	/** Creates a new row to be edited. */
	createNewRowToEdit(): Property {
		return new Property( this.cache.currentCompany.companyId );
	}

	/** Called before new rows is saved. */
	prepareRowToInsert( newRow: Property ) {
		newRow.propertyId = this.cache.getNextAvailablePropertyId();
	}

	/** Detects changes in model so view is updated. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	getRowInserter( row: Property ): Promise<Property> {
		return this.cache.addProperty( row );
	}

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	getRowUpdater( index: number, row: Property ): Promise<Property> {
		// console.log( 'saving updated property: '+JSON.stringify( row, null,2));
		return this.cache.updateProperty( this.getDetailComponent().getOriginalData().propertyId, row );
	}

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	getRowDeleter( index: number, row: Property ): Promise<void> {
		return this.cache.deleteProperty( row.propertyId );
	}

}
