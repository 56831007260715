import { Global } from "../service/Global";
import { Command } from "./Command";
import { Company } from "../service/Company";
import { Address } from "../service/Address";

export class GetShippingAddressCmd extends Command {

	/**
	 * Get the shipping address associated with an Amazon Pay order.
	 * @param companyId ID of the company that is subscribing.
	 * @param accessToken Access token obtained from Amazon log in that lets us retrieve shipping address.
	 * @param orderReferenceId Order reference ID obtained from selecting a shipping address.
	 */
	do( companyId: number, accessToken: string, orderReferenceId: string ): Promise<Address> {
		return new Promise<Address>( (resolve, reject) => {
			var parameters = {
				"accessToken": accessToken,
				"orderReferenceId": orderReferenceId,
			};
			// Global.log('GetShippingAddressCmd.do companyId='+companyId+', parameters='+JSON.stringify(parameters));
			super.send( this.constructor.name, companyId, parameters )
			.then( results => {
				let physicalDestination = results.data.getOrderReferenceDetailsResult.GetOrderReferenceDetailsResponse.GetOrderReferenceDetailsResult.OrderReferenceDetails.Destination.PhysicalDestination;
				let shippingAddress = new Address(
					physicalDestination.Name,
					physicalDestination.AddressLine1,
					physicalDestination.AddressLine2,
					physicalDestination.AddressLine3,
					physicalDestination.City,
					physicalDestination.StateOrRegion,
					physicalDestination.PostalCode,
					physicalDestination.CountryCode,
					physicalDestination.Phone
				)
				resolve( shippingAddress );
			})
			.catch( err => reject( err ) );
		});
	}
}
