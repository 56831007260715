import { TableDocumentObject } from './TableHandler'
import { NamedObject } from './NamedObject';

/** 
 * Object that holds the company ID and name, the property ID and name, and the site ID and name.  Used for select lists.
 */
export class SiteName extends TableDocumentObject implements NamedObject {

	/**
	 * @param companyId ID of company
	 * @param companyName Name of company
	 * @param propertyId ID of property
	 * @param propertyName Name of property
	 * @param siteId ID of site
	 * @param siteName Name of site
	 * @param address1 Street address of property
	 * @param address2 Additional address info like suite number
	 * @param city City where property is located
	 * @param state State where property is located
	 * @param postalCode Postal code of property
	 * @param country Country where property is located
	 * @param subcategoryName Name of the subcategory of the active ad for this advertising site or null if not set.
	 */
	constructor(
		public companyId: number = null,
		public companyName: string = null,
		public propertyId: number = null,
		public propertyName: string = null,
		public siteId: number = null,
		public siteName: string = null,
		public address1: string = null,
		public address2: string = null,
		public city: string = null,
		public state: string = null,
		public postalCode: string = null,
		public country: string = null,
		public subcategoryName: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): SiteName {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId": this.companyId,
			"propertyId": this.propertyId,
			"siteId": this.siteId,
        };
	}
	
	getDisplayName(): string {
		return this.companyId + ': ' + this.companyName + ', ' + this.propertyId + ': ' + this.propertyName + ', ' + this.siteId + ': ' + this.siteName;
	}

	getKeyString() {
		return this.companyId+'_'+this.propertyId+'_'+this.siteId;
	}

	/** @returns Address as a single string with a comma before the city. */
	getAddressString(): string {
		let s = this.address1 ? this.address1 : '';
		s += this.address2 ? ' ' + this.address2 : '';
		s += ', ' + (this.city ? this.city : '');
		s += this.state ? ' ' + this.state : '';
		s += this.postalCode ? ' ' + this.postalCode : '';
		return s;
	}

}
