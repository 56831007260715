import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { HousekeepingLogEntry } from "../../service/HousekeepingLogEntry";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { DateTimeComponent } from "../datetime/datetime.component";
import { ConfirmComponent } from "../confirm/confirm.component";

export class HousekeepingLogEntryUserAction {
	constructor(
		public row: HousekeepingLogEntry,
		public action: string
	){};
}

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'housekeeping-log-entry',
	templateUrl: './housekeeping-log-entry.html',
	styleUrls: [ 'housekeeping-log-entry.css' ]
})
export class HousekeepingLogEntryComponent implements OnInit, OnDestroy {
    editedRow: HousekeepingLogEntry = new HousekeepingLogEntry();

	/** Copy of the original row that was passed in for editing.  Useful to get the original primary key values of the row to update. */
	originalRow: HousekeepingLogEntry;

	errorMessage: string = null;
	private resolveModal: (results: HousekeepingLogEntryUserAction) => void;
	private rejectModal: (error: Error) => void;
	
	@ViewChild('HousekeepingLogEntryModalButton') private modalButtonRef: ElementRef; private modalButton: HTMLButtonElement;
	
	@ViewChild('focus') private focusRef: ElementRef; private focusElement: HTMLInputElement;
		
	@ViewChild('startDate') private startDateComponent: DateTimeComponent;
	
	@ViewChild('endDate') private endDateComponent: DateTimeComponent;
	
	private timeZone: string;

	/** True if this record will be inserted and the delete icon is not shown. */
	private isInsert: boolean;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
    )
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	public showModalDialog( row: HousekeepingLogEntry, isInsert: boolean, timeZone: string ): Promise<HousekeepingLogEntryUserAction> {
		this.errorMessage = null;
		this.isInsert = isInsert;
		this.timeZone = timeZone;
		return new Promise<HousekeepingLogEntryUserAction>( (resolve,reject) => {
			if (!row) {
				reject( new Error( 'No edit object provided.' ) );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Make a copy of object so we can restore the original values if user cancels.
				this.originalRow = row;
				this.editedRow = new HousekeepingLogEntry().fromDataItem( row.toDataItem() );
				this.startDateComponent.setDateTime( this.editedRow.startTime, this.timeZone );
				this.endDateComponent.setDateTime( this.editedRow.endTime, this.timeZone );
				// Show the dialog
				this.modalButton.click();
				this.changeDetectorRef.detectChanges();
				// Put keyboard focus on heading field after dialog is displayed
				setTimeout( () => this.focusElement.focus(), 1000 );
			}
		});
	}

	/** Return to copy of the original row that was created when a row is passed in for editing.  Can be used to get the old primary key values of the row to update. */
	public getOriginalRow(): HousekeepingLogEntry {
		return this.originalRow;
	}

	/** Close the dialog without saving changes. */
	private onCancel() {
		// Hide the dialog
		this.modalButton.click();
		this.resolveModal( new HousekeepingLogEntryUserAction( this.editedRow, 'cancel' ) );
	}

    private onSave() {
        this.errorMessage = null;
		if (this.isValid()) {
			// Hide the dialog
			this.modalButton.click();
			this.resolveModal( new HousekeepingLogEntryUserAction( this.editedRow, (this.isInsert ? 'insert' : 'update') ) );
		}
    }

	private isValid(): boolean {
		this.errorMessage = null;
		if ((!this.editedRow.roomNumber) || this.editedRow.roomNumber.trim() === '') {
			this.errorMessage = 'Please enter the room number.';
			return false;
		}
		this.errorMessage = this.startDateComponent.getNullValueError('start');
		if (this.errorMessage) {
			return false;
		}
		this.editedRow.startTime = this.startDateComponent.getDateTime();
		this.editedRow.endTime = this.endDateComponent.getDateTime();
		return true;
	}

	private onDelete() {
		this.cache.confirmComponent.showModalDialog( 'Are you sure you want to delete the housekeeping entry for room: ' + this.originalRow.roomNumber + '?', 'Confirm Delete' )
		.then( confirmed => {
			if (confirmed) {
				// Hide the dialog
				this.modalButton.click();
				this.resolveModal( new HousekeepingLogEntryUserAction( this.originalRow, 'delete' ) );
			}
		})
		.catch( error => {
			Global.logError( 'Error in edit dialog.', error );
			this.errorMessage = error.message;
		});
	}

}