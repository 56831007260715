import {Injectable} from "@angular/core";
import { Employee } from './Employee';
import { Company } from './Company';
import { Role } from './Role';
import { System } from './System';
import { Product } from './Product';
import { Property } from './Property';
import { Site } from './Site';
import { PurchaseDetails } from './PurchaseDetails';
import { AppComponent } from "../app.component";
import { ConfirmComponent } from '../secure/confirm/confirm.component';
import { TitleNavComponent } from "../secure/title-nav/title-nav.component"
import { Global } from './Global';
import { PutPropertyCmd } from '../command/PutPropertyCmd';
import { DeletePropertyCmd } from '../command/DeletePropertyCmd';
import { GetProductsCmd } from '../command/GetProductsCmd';

/**
 * Session-level, in-memory cache of name/value pairs.
 */
@Injectable()
export class CacheService {
	private map = new Map<string,any>();

	public appComponent: AppComponent;

	/** Navigation component shared by all the pages in the portal. */
	public titleNavComponent: TitleNavComponent = null;
	
	/** ID used by Cognito to identify the current logged-in user or null if nobody is logged in. */
	public currentUserId: string;

	/** Name the user gave when signing up. */
	public currentUserNickname: string;
	
	/** Email address used by Cognito as an alias to identify the current logged-in user or null if nobody is logged in. */
	public currentUserEmail: string;

	/** Date/time when current user's login token expires. */
	public currentUserTokenExpiration: Date = null;

	/** Current user's permissions created by combining permissions from all of the user's roles. */
	public currentRole: Role;

	/** Companies that the user can log into. */
	public companies: Company[] = [];

	/** Company the current user is currently logged into or null if nobody is logged in. */
	public currentCompany: Company;

	/** The currently selected property for the current company. */
	public currentProperty: Property;

	/** Employee record being edited, temporarily savedin cache while switching components to take photo. */
	public editedEmployee: Employee;

	/** Set to true when employee photo has just been taken, otherwise false. */
	public tookEmployeePhoto: boolean;

	/** Site ID selected for VIP Connect */
	/** TODO get this from selector instead of hard-coding. */
	public currentSite = new Site( 1, 1, 2, 'Fruita Store', 'America/Denver' );

	/** Write-thru cache of properties in the current company. */
	public properties: Property[] = [];

	// List of products available to purchase
	private products: Product[] = null;

	/** Purchase details shown on checkout and confirmation page. */
	public purchaseDetails: PurchaseDetails;

	/** Component used to display modal confirmation dialog.  */
	public confirmComponent: ConfirmComponent;

	public set( name: string, value: any ) {
		this.map.set( name, value );
	}

	public get( name: string ): any {
		return this.map.get( name );
	}

	public delete( name: string ): boolean {
		let object = this.get( name );
		return this.map.delete( name );
	}

	/** Get list of properties for the current company. */
	public getProperties(): Property[] {
		if (!this.currentCompany) {
			return <Property[]>[];
		}
		return this.properties;
	}

	/** Returns first property in the list or null if list is empty. */
	public getFirstPropertyId(): number {
		return this.properties.length > 0 ? this.properties[0].propertyId : null;
	}

	/** Returns highest property ID in the list plus 1. */
	public getNextAvailablePropertyId(): number {
		let nextId = 1;
		if (this.properties) {
			this.properties.forEach( property => {
				if (property.propertyId >= nextId) {
					nextId = property.propertyId + 1;
				}
			})
		}
		return nextId;
	}

	/**
	 * Get the property with the given ID.
	 * @param propertyId ID of the property to get.
	 */
	public getPropertyIndex( propertyId: number ): number {
		let index = -1;
		if (this.currentCompany) {
			for (let i=0; i<this.properties.length; i++) {
				if (this.properties[i].propertyId === propertyId) {
					index = i;
					break;
				}
			}
		}
		return index;
	}
	
	/**
	 * Get the property with the given ID.
	 * @param propertyId ID of the property to get.
	 */
	public getProperty( propertyId: number ): Property {
		let index = this.getPropertyIndex( propertyId );
		return index != -1 ? this.properties[index] : null;
	}
	
	/**
	 * Adds the given property to the end of the list and saves it in the database.
	 * @param property Property to insert.
	 */
	addProperty( property: Property ): Promise<Property> {
		return new Promise<Property>( (resolve,reject) => {
			// Global.log('cache.addProperty '+JSON.stringify(property,null,2));
			new PutPropertyCmd().do( property )
			.then( savedProperty => {
				// Global.log('cache.addProperty saved '+JSON.stringify(savedProperty,null,2));
				this.properties.push( savedProperty );
				resolve( savedProperty );
			})
			.catch( error => {
				// Global.log('cache saved property failed');
				reject( error );
			 });
		})
	}

	/**
	 * Updates the property with the given ID to the given values and saves it in the database.
	 * @param oldPropertyId ID of the property to update.
	 * @param updatedProperty Updated property.
	 */
	updateProperty( oldPropertyId: number, updatedProperty: Property ): Promise<Property> {
		return new Promise<Property>( (resolve,reject) => {
			// Global.log('cache.updateProperty '+JSON.stringify(updatedProperty,null,2));
			new PutPropertyCmd().do( updatedProperty )
			.then( savedProperty => {
				// Global.log('cache.updateProperty saved '+JSON.stringify(savedProperty,null,2));
				let index = this.getPropertyIndex( oldPropertyId );
				if (index != -1) {
					this.properties[index] = savedProperty;
				}
				if (oldPropertyId != updatedProperty.propertyId) {
					// The property ID changed, delete the old property row
					new DeletePropertyCmd().do( new Property( this.currentCompany.companyId, oldPropertyId ) )
					.then( () => resolve( savedProperty ) )
					.catch( error => reject( error ) );
				}
				resolve( savedProperty );
			})
			.catch( error => reject( error ) );
		})
	}

	/**
	 * Adds the given property to the end of the list and saves it in the database.
	 * @param property Property to insert.
	 */
	deleteProperty( propertyId: number ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			new DeletePropertyCmd().do( new Property( this.currentCompany.companyId, propertyId ) )
			.then( () => {
				let index = this.getPropertyIndex( propertyId );
				if (index != -1) {
					this.properties.splice( index, 1 );
				}
				// console.log('cache deleted');
				resolve();
			})
			.catch( error => reject( error ) );
		})
	}

	/**
	 * Get the time zone offset minutes for the property with the given ID or 0 if property not found or no time zone set.
	 * @param propertyId ID of the property to get.
	 */
	public getTimeZone( propertyId: number ): string {
		let timeZone = null;
		if (propertyId) {
			let property = this.getProperty( propertyId );
			if (property && property.timeZone && property.timeZone.length > 0) {
				timeZone = property.timeZone;
			}
		}
		return timeZone;
	}

	/** Return true if the current user's role has the given permission. */
	public hasPermission( permission: string ) {
		let hasIt = false;
		if (this.currentRole) {
			hasIt = this.currentRole.hasPermission( permission );
		}
		// console.log('hasPermission('+permission+') returns '+hasIt+', this.cache.currentRole='+JSON.stringify(this.currentRole,null,2))
		return hasIt;
	}

	/**
	 * Return true if the current user has the given permission and is in a company with any of the give products.
	 * @param productCodes List of product codes to check.
	 * @param permission Permission to check.
	 */
	public hasProductPermission( productCodes: string[], permission: string ) {
		let hasPermission = false;
		if (this.hasPermission( permission )) {
			// User has permission, see if any of the product subscriptions are active
			if (this.currentRole.hasPermission('sysAdmin') || this.currentCompany.hasOneOfTheseProducts( productCodes )) {
				hasPermission = true;
			}
		}
		// console.log('cache.hasProductPermission( '+productCodes+', '+permission+' ) returns '+hasPermission+', currentRole='+JSON.stringify(this.currentRole,null,2) );
		return hasPermission;
	}

	/**
	 * Send an event to Google Analytics for tracking.
	 */
	sendEventToAnalytics( category: string, action: string, label: string ) {
		try {
			(<any>window).gtag( 'event', action, { 'event_category': category, 'event_label': label } );
		} catch( error ) {
			Global.logError( 'Error sending event to Google Analytics', error );
		}
	}

	/**
	 * Report conversion to Google AdWords - a user signed up for an account.
	 */
	reportSignUpConversionToAdWords() {
		try {
			(<any>window).gtag_report_signup_conversion();
		} catch( error ) {
			Global.logError( 'Error reporting Sign Up conversion', error );
		}
	}

	/**
	 * Report conversion to Google AdWords - a user requested info for Room Genie.
	 */
	reportRoomGenieInfoRequestConversionToAdWords() {
		try {
			(<any>window).gtag_report_room_genie_request_info_conversion();
		} catch( error ) {
			Global.logError( 'Error reporting Room Genie Info Request conversion', error );
		}
	}

	/**
	 * Report conversion to Google AdWords - a user subscribed to Room Genie.
	 */
	reportRoomGenieSubscribeConversionToAdWords() {
		try {
			(<any>window).gtag_report_room_genie_subscribe_conversion();
		} catch( error ) {
			Global.logError( 'Error reporting Room Genie Subscribe conversion', error );
		}
	}

	getSystemData(): Promise<System> {
		return new Promise<System>( (resolve,reject) => {

		});
	}

	/** Returns list of products available for purchase. */
	getProducts(): Promise<Product[]> {
		return new Promise<Product[]>( (resolve,reject) => {
			if (this.products) {
				resolve( this.products );
			} else {
				// Load list of products
				new GetProductsCmd().do()
				.then( products => {
					this.products = products;
					resolve( this.products );
				})
				.catch( error => reject( error ) );
			}
		});
	}

	getProduct( productCode: string ): Promise<Product> {
		return new Promise<Product>( (resolve,reject) => {
			this.getProducts()
			.then( products => resolve( Product.getProduct( productCode, this.products ) ) )
			.catch( error => reject( error ) );
		});
	}

}
