import { TableDocumentObject } from './TableHandler'
import { Global } from './Global'
import { DefaultAd } from './DefaultAd';
import { ItemMenu } from './ItemMenu';

/** Details about each site in a company. */
export class Property extends TableDocumentObject {

	/**
	 * @param companyId ID of company (partition key)
	 * @param propertyId ID of property (sort key)
	 * @param name Name of the property.
	 * @param address1 Street address of property
	 * @param address2 Additional address info like suite number
	 * @param city City where property is located
	 * @param state State where property is located
	 * @param postalCode Postal code of property
	 * @param country Country where property is located
	 * @param timeZone Timezone property is in.
	 * @param feedbackTopicArn SNS ARN of the topi where feedback notifications are sent.
	 * @param feedbackEmails List of email adresses to notify when feedback is received.
	 * @param feedbackPhones List of phone numbers to notify via SMS when feedback is received.
	 * @param latitude GPS latitude coordinates from Google maps (right click location, select What's there)
	 * @param longitude GPS longitude coordinates from Google maps (right click location, select What's there)
	 * @param defaultAds Ads to play for each ad category when there are no paying advertisers.
	 * @param canOrderFoodToRoom True if guests can order room service
	 * @param roomServiceMenu Room service menu categories containing items
	 * @param canOrderItemsToRoom True if guests can order items, such as forgotten toiletries, to the room
	 * @param roomItemMenu Menu of items guests can order
	 * @param beyondTvDomain Domain for BeyondTV
	 * @param beyondTvHotelId Hotel ID for BeyondTV
	 * @param beyondTvData Cached menu data from BeyondTv, refreshed if more than 24 hours old
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public name: string = null,
		public address1: string = null,
		public address2: string = null,
		public city: string = null,
		public state: string = null,
		public postalCode: string = null,
		public country: string = null,
		public timeZone: string = 'US/Eastern',
		public feedbackTopicArn: string = null,
		public feedbackEmails: string[] = [],
		public feedbackPhones: string[] = [],
		public latitude: number = null,
		public longitude: number = null,
		public defaultAds: DefaultAd[] = [],
		public canOrderFoodToRoom: boolean = false,
		public roomServiceMenu: ItemMenu = new ItemMenu( 'Room Service Menu', [] ),
		public canOrderItemsToRoom: boolean = false,
		public roomItemMenu: ItemMenu = new ItemMenu( 'Room Item Menu', [] ),
		public beyondTvDomain: string = null,
		public beyondTvHotelId: string = null,
		public beyondTvData: any = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Property {
		this.copyPropertiesFromObject( item );
		if (!this.feedbackEmails) {
			this.feedbackEmails = [];
		}
		if (!this.feedbackPhones) {
			this.feedbackPhones = [];
		}
		// Deserialize object attributes
		this.roomServiceMenu = new ItemMenu( 'Room Service Menu', [] );
		if (item['roomServiceMenu']) {
			this.roomServiceMenu = new ItemMenu().fromDataItem( item['roomServiceMenu'] );
		}
		this.roomItemMenu = new ItemMenu( 'Room Item Menu', [] );
		if (item['roomItemMenu']) {
			this.roomItemMenu = new ItemMenu().fromDataItem( item['roomItemMenu'] );
		}
		// Copy list of objects
		this.defaultAds = [];
		if (item['defaultAds']) {
			item['defaultAds'].forEach( childItem => this.defaultAds.push( new DefaultAd().fromDataItem( childItem ) ) );
		}
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Serialize object attributes
		item['roomServiceMenu'] = null;
		if (this.roomServiceMenu) {
			item['roomServiceMenu'] = this.roomServiceMenu.toDataItem();
		}
		item['roomItemMenu'] = null;
		if (this.roomItemMenu) {
			item['roomItemMenu'] = this.roomItemMenu.toDataItem();
		}
		// Copy list of objects
		item['defaultAds'] = [];
		if (this.defaultAds) {
			this.defaultAds.forEach( childItem => item['defaultAds'].push( childItem.toDataItem() ) );
		}
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId": this.companyId,
			"propertyId": this.propertyId, 
        };
    }

	indexOfFeedbackEmail( email: string ): number {
		let index = -1;
		if (email) {
			email = email.toLowerCase();
		}
		for (let i=0; i<this.feedbackEmails.length; i++) {
			if (this.feedbackEmails[i].toLowerCase() == email) {
				index = i;
				break;
			}
		}
		return index;
	}

	indexOfFeedbackPhoneNumber( phoneNumber: string ): number {
		let index = -1;
		if (phoneNumber) {
			phoneNumber = Global.removeEverythingButDigitsFromString( phoneNumber );
		}
		for (let i=0; i<this.feedbackEmails.length; i++) {
			if (Global.removeEverythingButDigitsFromString( this.feedbackPhones[i] ) == phoneNumber) {
				index = i;
				break;
			}
		}
		return index;
	}

	public getDefaultAdToPlay( adCategoryId: number ): string {
		let speech = null;
		if (this.defaultAds) {
			for (let i=0; i<this.defaultAds.length; i++) {
				if (this.defaultAds[i].adCategoryId == adCategoryId) {
					speech = this.defaultAds[i].speech;
				}
			}
		}
		if (!speech) {
			speech = 'Sorry, I don\'t have any recommendations.  You can try asking Alexa instead.';
		}
		return speech;
	}

	/** Return map of names keyed by companyId_propertyId. */
	public static buildNameMap( properties: Property[] ): Map<string,string> {
		let map = new Map<string,string>();
		if (properties) {
			properties.forEach( row => {
				map.set( row.companyId+'_'+row.propertyId, row.name );
			});
		}
		return map;
	}

}
