import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { EventComponent } from "../event/event.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { EventTable, Event } from '../../service/EventTable';

export class Attribute {
	constructor(
    	public name: string,
		public value: string
	) {}
}

@Component({
    selector: 'events',
    templateUrl: './events.html',
	styleUrls: ['./events.css'],
})
export class EventsComponent implements OnInit, AfterViewInit {

	/** List of objects that can be edited. */
	private objects: Event[] = [];
	
	/** Error message displayed to the user. */
	private errorMessage: string;

	@ViewChild(EventComponent) private detailComponent: EventComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
		private objectTable: EventTable,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Marketing Events' );
	}

	initialize() {
		// Get list of objects to edit
		this.objectTable.getEventsForCompany( this.cache.currentCompany.companyId )
		.then( foundObjects => {
			// console.log('getEventsForCompany '+this.cache.currentCompany.companyId+' returned '+JSON.stringify(foundObjects));
			this.objects = foundObjects;
			this.changeDetectorRef.detectChanges();
		})
		.catch( error => {
			Global.logError('Error getting list of items to edit.', error );
			this.errorMessage = 'Sorry, we had a problem getting the list of items to edit.  Please try again.';
		})
	}

	insert() {
		this.detailComponent.showModalDialog( new Event( this.cache.currentCompany.companyId ) )
		.then( object => {
			if (object) {
				// User saved changes, update the company in the cache
				this.objects.push( object );
			}
		})
	}

	edit( index: number ) {
		this.detailComponent.showModalDialog( this.objects[index] )
		.then( object => {
			if (object) {
				// User saved changes, update the company in the cache
				this.objects[index] = object;
			}
		})
		.catch( error => this.errorMessage = error.message );
	}

	delete( index: number ) {
		this.cache.confirmComponent.showModalDialog( 'Are you sure you want to delete event ' + this.objects[index].name + '?', 'Confirm Delete' )
		.then( confirmed => {
			if (confirmed) {
				this.objectTable.delete( this.objects[index] )
				.then( () => this.objects.splice( index, 1 ) )
				.catch( error => {
					Global.logError( 'Error deleting object.', error );
					this.errorMessage = error.message;
				});
			}
		})
		.catch( error => {
			Global.logError( 'Error confirming delete.', error );
			this.errorMessage = error.message;
		});
	}
}
