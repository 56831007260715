import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../service/user-login.service";
import { CacheService } from "../../service/cache.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { Global } from "../../service/Global";
import { Company } from '../../service/Company';
import { UnsubscribeCmd } from '../../command/UnsubscribeCmd';

/**
Displays a dashboard for the SmartView product that tracks new and repeat guests.
*/
@Component({
    selector: 'unsubscribe-confirm',
    templateUrl: './unsubscribe-confirm.html',
    styleUrls: ['./unsubscribe-confirm.css'],
})
export class UnsubscribeConfirmComponent implements OnInit, AfterViewInit {

	/** Error message displayed to user if not null. */
	private errorMessage: string = null;

	constructor(
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
    ngOnDestroy() {
    }

	ngAfterViewInit() {
		// Set page title
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-cart-plus fa-fw"></i> Unsubscribe' );
	}

	initialize() {
	}

	onCancel() {
		this.router.navigate(['/securehome/product-subscriptions']);
	}

	onUnsubscribe() {
		// console.log( 'Unsubscribing from '+this.cache.purchaseDetails.product.name );
		this.cache.sendEventToAnalytics( 'Unsubscribe', this.cache.purchaseDetails.product.name, 'confirm unsubscribe' );
		let startTime = Date.now();
		new UnsubscribeCmd().do( this.cache.currentCompany.companyId, this.cache.purchaseDetails.product.productCode, this.cache.purchaseDetails.product.name )
		.then( company => {
			// console.log( 'Unsubscribe succeeded in ' + (Date.now()-startTime) + 'ms.  Updated company: '+JSON.stringify( company, null, 2 ) );
			// Update the cache with the updated company data
			this.cache.currentCompany = company;
			this.router.navigate(['/securehome/product-subscriptions']);
		})
		.catch( error => {
			// console.log( 'API call failed in ' + (Date.now()-startTime) + 'ms.: ' + JSON.stringify( error, null, 2 ) );
			Global.logError( 'Error unsubscribing product.', error );
			this.errorMessage = 'Sorry, there was a problem unsubscribing, please try again later.';
		});
	}

}
