import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";

/**
 * Company editing component.
 */
@Component({
    selector: 'confirm',
    templateUrl: './confirm.html',
	styleUrls: ['./confirm.css'],
})
export class ConfirmComponent implements OnInit, OnDestroy, AfterViewInit {
    private title = 'Confirm';
	private message = '';
	private yesNoButtons = true;
	private yesButtonLabel = "YES";
	private resolveModal: (confirmed: boolean) => void;
	private rejectModal: (error: Error) => void;

	@ViewChild('confirmModalButton') public confirmModalButtonRef: ElementRef; private confirmModalButton: HTMLButtonElement;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
	) {}

    ngOnInit() {}

	ngOnDestroy() {}

	ngAfterViewInit() {
		this.confirmModalButton = this.confirmModalButtonRef.nativeElement;
	}

	/**
	 * Displays modal confirmation dialog.
	 * @param message Message to show in dialog box.
	 * @param title Dialog title.  Default is 'Confirm'.
	 * @param yesNoButtons True if Yes and No buttons are displayed, false if just Ok button is displayed.
	 * @return True if use selected Yes or false if user selected No.
	 */
	showModalDialog( message: string, title = 'Confirm', yesNoButtons = true ): Promise<boolean> {
		this.title = title;
		this.message = message;
		this.yesNoButtons = yesNoButtons;
		this.yesButtonLabel = yesNoButtons ? "YES" : "OK";
		return new Promise<boolean>( (resolve,reject) => {
			this.resolveModal = resolve;
			this.rejectModal = reject;
			this.confirmModalButton.click();
			this.changeDetectorRef.detectChanges();
		});
	}
	
	/** Close the dialog without saving changes. */
	private onNo() {
		// Hide the dialog
		this.confirmModalButton.click();
		this.resolveModal( false );
	}

    private onYes() {
		// Hide the dialog
		this.confirmModalButton.click();
		this.resolveModal( true );
    }

}