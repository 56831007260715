import { TableDocumentObject } from './TableHandler'
import { Global } from './Global'

/** Ads that play at a property for a given adCategory and month. */
export class PropertyAdvertiser extends TableDocumentObject {

	/**
	 * @param companyId ID of hotel company (partition key)
	 * @param propertyId ID of hotel property (partition key)
	 * @param adCategoryId Ad category ID of ads (partition key)
	 * @param adCompanyId Company ID of advertising site (sort key)
	 * @param adPropertyId Property ID of advertising site (sort key)
	 * @param adSiteId ID of advertising site (sort key)
	 * @param startDate First date ad is played. (sort key)
	 * @param endDate Last date ad is played.
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public adCategoryId: number = null,
		public adCompanyId: number = null,
		public adPropertyId: number = null,
		public adSiteId: number = null,
		public startDate: Date = new Date(),
		public endDate: Date = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): PropertyAdvertiser {
		this.copyPropertiesFromObject( item );
		
		// We only store the date part of the start and end dates and assume they are local time
		this.startDate = Global.parseLocalDateString( item['startDate'] );
		this.endDate = Global.parseLocalDateString( item['endDate'] );
		
		return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId_adCategoryId'] = this.companyId + '_' + this.propertyId + '_' + this.adCategoryId;
		item['advertiserStart'] = this.adCompanyId + '_' + this.adPropertyId + '_' + this.adSiteId + '_' + Global.getLocalDateString( this.startDate );
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );

		// We only store the date part of the start and end dates and assume they are local time
		item['startDate'] = Global.getLocalDateString( this.startDate );
		item['endDate'] = Global.getLocalDateString( this.endDate );

		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId_adCategoryId": this.companyId + '_' + this.propertyId + '_' + this.adCategoryId,
			"advertiserStart": this.adCompanyId + '_' + this.adPropertyId + '_' + this.adSiteId + '_' + Global.getLocalDateString( this.startDate ), 
        };
    }

}
