import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/** 
 * An item that can be ordered or requested like an item on a room service menu or a toothbrush.
 */
export class Item extends TableDocumentObject {

	/**
	 * @param name Name used by Room Genie app to sign up for activity such as 'snorkelling'
	 * @param plural Plural form of the name 
	 * @param synonyms List of synonyms that users might use when referring to this item
	 */
	constructor(
		public name: string = null,
		public plural: string = null,
		public synonyms: string[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Item {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

	getSynonyms(): string {
		let s = '';
		if (this.synonyms) {
			this.synonyms.forEach( synonym => {
				if (s.length > 0) {
					s += ', ';
				}
				s += synonym;
			})
		}
		return s;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		name = name.toLowerCase();
		if (this.name.toLowerCase() == name) {
			valid = true;
		} else {
			// Check all the synonyms
			valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
		}
		return valid;
	}

}
