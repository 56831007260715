import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { TimeRange } from './TimeRange';
import { RestaurantTableReservations } from './RestaurantTableReservations';
import { RestaurantReservationResponse } from './RestaurantReservationResponse';
import { RestaurantReservationList } from './RestaurantReservationList';
import { RestaurantReservationTable } from './RestaurantReservationTable';
import { RestaurantLayoutTable } from './RestaurantLayoutTable';
import { RestaurantLayout } from './RestaurantLayout';

/** Seating arrangement such as "Lunch" or "Dinner". */
export class SiteHours extends TableDocumentObject {
	
	/**
	 * @param name Name of these hours of operation like 'Lunch' or 'Dinner' or 'All Day'
	 * @param startDate Date and opening time of first occurrance of these hours of operation (default=now)
	 * @param endDate Date and closing time the last occurrance of these hours of operation.
	 * @param daysOfWeek 1/0 for each day of the week Restaurant occurs when 'weekly' frequency is used (Sunday is first day, default is 1111111)
	 * @param closedDays Specific dates the site is closed like Christmas day.
	 * @param layout Restaurant layout in use for seating.
	 * @param turnMinutes Average time people will occupy a table during the seating.
	 * @param intervalMinutes Number of minutes between valid reservation times.
	 * @param requiresReservations True if reservations can be made for the seating.
	 */
	constructor(
		public name: string = null,
		public startDate: Date = new Date(),
		public endDate: Date = new Date(),
		public daysOfWeek: string = '1111111',
		public closedDays: Date[] = [],
		public layoutId: number = null,
		public turnMinutes: number = 60,
		public intervalMinutes: number = 30,
		public requiresReservations: boolean = false,
	) { 
		super(); 
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): SiteHours {
		this.copyPropertiesFromObject( item );
		// Copy closed days
		this.closedDays = [];
		if (item['closedDays']) {
			item['closedDays'].forEach( childItem => this.closedDays.push( new Date( Date.parse( childItem ) ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) + '\nfrom data item: ' + JSON.stringify( item, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy closed days
		item['closedDays'] = [];
		if (this.closedDays) {
			this.closedDays.forEach( childItem => item['closedDays'].push( (childItem ? childItem.toISOString() : null ) ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

	/**
	 * Get the start date/time of the nearest seating that starts on or before the given date time.
	 * Does not take into account the list of closed days or the days of the week the restaurant is open.
	 * @param date Date to convert.
	 */
	public getReservationListStartDate( date: Date, timeZone: string ): Date {
		// Calculate reservation list start date/time from given date and seating start time
		let result = Global.setTimeToMatch( this.startDate, date, timeZone );
		if (date.getTime() < result.getTime()) {
			// The given date is before the start date/time, make the start date/time a day earlier
			result = new Date( result.getTime() - Global.millisInOneDay );
		}
		return result;
	}

	/**
	 * Get the end date/time of the nearest seating that starts on or before the given date time.
	 * Does not take into account the list of closed days or the days of the week the restaurant is open.
	 * @param date Date to convert.
	 */
	public getReservationListEndDate( date: Date, timeZone: string ): Date {
		// End date  = start date plus length of seating
		let seatingMillis = Global.getTimeOnlyMilliseconds( this.endDate ) - Global.getTimeOnlyMilliseconds( this.startDate );
		if (seatingMillis <= 0) {
			// Seating crosses midnight
			seatingMillis += Global.millisInOneDay;
		}
		return new Date( this.getReservationListStartDate( date, timeZone ).getTime() + seatingMillis );
	}

	/**
	 * @returns True if this seating starts earlier than 7 days from now and ends later than now.
	 */
	public isOpenInNextSevenDays(): boolean {
		let sevenDaysFromNow = Date.now() + (7 * 24 * 60 * 60 * 1000);
		let isOpen = this.startDate.getTime() < sevenDaysFromNow && (!this.endDate || (this.endDate.getTime() > Date.now() && this.endDate.getTime() > this.startDate.getTime()));
		// console.log( 'isOpenInNextSevenDayt start='+this.startDate.toISOString()+', end='+this.endDate.toISOString()+' returns '+isOpen);
		return isOpen;
	}

	/**
	 * Returns true if the site is open on the given date.
	 * @param date Date and time to check.
	 * @param timeZone Timezone where restaurant is located.
	 */
	public isOpenOnDate( date: Date, timeZone: string ): boolean {
		// console.log('isOpenOnDate date='+date.toISOString()+', time zone '+timeZone);
		let open = false;
		// Make sure date/time is between the seating type start date and end date
		if (this.startDate.getTime() <= date.getTime() && date.getTime() < this.endDate.getTime()) {
			open = this.getDayOfWeekFlag( Global.getDayOfWeek( date, timeZone ) );
			// console.log('isOpenOnDate getDayOfWeekFlag returned '+open);
			if (open && this.closedDays) {
				// Check to see if the date is in the list of closed days
				// console.log('isOpenOnDate checking closedDays'+JSON.stringify(this.closedDays,null,2));
				let dateOnly = Global.getDateOnly( date, timeZone );
				for (let i=0; i<this.closedDays.length; i++) {
					let closedDate = Global.getDateOnly( this.closedDays[i], timeZone );
					// console.log('closedDate='+closedDate.toISOString()+', dateOnly='+dateOnly.toISOString()+', date='+date.toISOString());
					if (closedDate.getTime() == dateOnly.getTime()) {
						// console.log('isOpenOnDate closed on '+this.closedDays[i].toISOString());
						open = false;
						break;
					}
				}
			}
		}
		// console.log('isOpenOnDate returned '+open);
		return open;
	}

	/**
	 * Returns the starting date/time of the reservation list if this seating is open at the given date and time.
	 * Returns null if there is no seating at the given date and time.
	 * @param date Date and time to check.
	 */
	public getReservationListTimeRange( dateTime: Date, timeZone: string ): TimeRange {
		// console.log('getReservationListTimeRange dateTime='+dateTime.toISOString()+', time zone  '+timeZone);
		let range: TimeRange = null;
		let open = this.isOpenOnDate( dateTime, timeZone );
		// console.log('isOpenDate( '+dateTime.toISOString()+', '+timeZone+' ) returned '+open);
		if (open) {
			// See if the seating is open at the given time
			let startTime = this.getReservationListStartDate( dateTime, timeZone );
			let endTime = this.getReservationListEndDate( dateTime, timeZone );
			// console.log('getReservationListTimeRange list start='+startTime.toISOString()+', list end='+endTime.toISOString());
			if (startTime.getTime() <= dateTime.getTime() && endTime.getTime() > dateTime.getTime()) {
				range = new TimeRange( startTime, endTime );
			}
		}
		// console.log('getReservationListTimeRange open='+open+', range='+JSON.stringify(range,null,2));
		return range;
	}

	/**
	 * Returns true if the given time matches the start time for the seating.
	 * @param time Time to check in the 12-hour format 'hh:mm am'.
	 */
	// public isValidTime( time: string ): boolean {
	// 	return time === this.startDate.toISOString().substr( 11, 5 );
	// }

	public getDayOfWeekFlag( dayOfWeek: number ): boolean {
		return this.daysOfWeek[dayOfWeek] != '0';
	}

	public setDayOfWeekFlag( dayOfWeek: number, flag: boolean ) {
		// Convert string into character array
		let array = this.daysOfWeek.split('');
		array[dayOfWeek] = (flag ? '1' : '0');
		// Convert array back into string
		this.daysOfWeek = array.join('');
	}
	
	/** Returns a string summarizing the dates the seating is valid and the times the seating starts and ends. */
	public getOpenTimeString( timeZone: string ) {
		let s = '';
		if (this.startDate && this.endDate) {
			s = Global.getDateString( this.startDate, timeZone )
				+ ' thru '
				+ Global.getDateString( this.endDate, timeZone )
				+ ' from '
				+ Global.getHM12TimeString( this.startDate, timeZone )
				+ ' to '
				+ Global.getHM12TimeString( this.endDate, timeZone );
		}
		// console.log( 'getOpenTimesList returns <'+s+'> from startDate '+(this.startDate ? this.startDate.toISOString() : null)+' to endDate '+(this.endDate ? this.endDate.toISOString() : null)+', timeZone='+timeZone );
		return s;
	}

	/** Returns a string summarizing the dates these hours of operation are valid and the opening and closing time. */
	public getHoursString( timeZone: string ) {
		let s = '';
		if (this.startDate) {
			s = this.name + ' ('
				+ Global.getHM12TimeString( this.startDate, timeZone )
				+ ' - '
				+ Global.getHM12TimeString( this.endDate, timeZone )
				+ ')'
		}
		// console.log( 'getSeatingTimes returns <'+s+'> from startDate '+(this.startDate ? this.startDate.toISOString() : null)+' to endDate '+(this.endDate ? this.endDate.toISOString() : null));
		return s;
	}

	/**
	 * Returns the number of weekdays that the Restaurant takes place (only applicable to 'weekly' frequency).
	 */
	// public getNumberOfDays(): number {
	// 	let count = 0;
	// 	for (let i=0; i<7; i++) {
	// 		if (this.getDayOfWeekFlag( i )) {
	// 			count++;
	// 		}
	// 	}
	// 	return count;
	// }

	/**
	 * Check if the given time is available to reserve.
	 * @param reserveIfAvailable True if time should be reserved if it is available.
	 */
	public checkAvailability( companyId: number, propertyId: number, siteId: number, persons: number, startTime: Date, endTime: Date, timeZone: string, reserveIfAvailable: boolean ): Promise<RestaurantReservationResponse> {
		return new Promise<RestaurantReservationResponse>( (resolve,reject) => {
			let response = new RestaurantReservationResponse();
			// Get reservation list from requested date combined with the starting time of the seating
			let reservationListTimeRange = this.getReservationListTimeRange( startTime, timeZone );
			// console.log('seating.checkAvailability reservationListTimeRange='+JSON.stringify(reservationListTimeRange,null,2));
			if (!reservationListTimeRange) {
				// There is no seating open at the specified date and time
				response.isSeatingOpen = false;
				resolve( response );
			} else {
				let key = new RestaurantReservationList( companyId, propertyId, siteId, reservationListTimeRange.start );
				// console.log( 'get res list key '+JSON.stringify(key,null,2));
				let reservationList = new RestaurantReservationTable().get( key )
				.then( list => {
					let insertList = false;
					if (!list) {
						// There is no reservation list in the database yet, create a new one to be inserted in db
						insertList = true;
						let layout = new RestaurantLayoutTable().get( new RestaurantLayout( companyId, propertyId, this.layoutId ) )
						.then( layout => {
							if (layout) {
								list = layout.createRestaurantReservationList( siteId, reservationListTimeRange.start, reservationListTimeRange.end, this.intervalMinutes );

								// See if table is available
								let listResponse = list.checkAvailability( persons, startTime, endTime, null );
								if (listResponse.tableTop != null) {
									response = listResponse;
								} else {
									response.setAlternateTimesIfBetter( listResponse.alternateEarlierTime, listResponse.alternateLaterTime );
								}
								resolve( response );
							} else {
								reject( new Error('Invalid restaurant layout ID '+this.layoutId ) );
							}
						}).catch( error => reject( error ));
					} else {
						// We found an existing reservation list for the seating, see if there is a table available
						// console.log('list.checkavailability '+persons+', '+startTime.toISOString()+', '+endTime.toISOString());
						let listResponse = list.checkAvailability( persons, startTime, endTime, null );
						if (listResponse.tableTop != null) {
							response = listResponse;
						} else {
							response.setAlternateTimesIfBetter( listResponse.alternateEarlierTime, listResponse.alternateLaterTime );
						}
						resolve( response );
					}
				}).catch( error => reject( error ) );
			}
		});
	}

}

