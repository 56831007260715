import { Command } from "./Command";
import { Employee } from "../service/Employee";

export class GetEmployeeCmd extends Command {

	/**
	 * Get row with the key values of the given object.
	 * @param key: Object containing row key values.
	 */
	do( key: Employee ): Promise<Employee> {
		return new Promise<Employee>( (resolve, reject) => {
			super.send( this.constructor.name, key.companyId, { "key": key.toDataItem() } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let found: Employee = null;
				if (results.data) {
					found = new Employee().fromDataItem( results.data );
				}
				resolve( found );
			}).catch( err => reject( err ) );
		});
	}

}
