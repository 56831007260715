import { Command } from "./Command";
import { UserCompany } from "../service/UserCompany";

export class GetUserCompaniesCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number ): Promise<UserCompany[]> {
		return new Promise<UserCompany[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, null )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: UserCompany[] = [];
				results.data.forEach( row => rows.push( new UserCompany().fromDataItem( row ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
