import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { IntentUsage } from './IntentUsage';

/**
 * Record of number of uses of each intent by date.
 */
export class DailyUsage extends TableDocumentObject {

	/**
	 * Record of the service being used by a specific room number on a specific date.
	 * @param companyId Unique ID of company (partition key)
	 * @param propertyId Property where the device was used.
	 * @param useDate Date device was used (in the property's timezone). (sort key)
	 * @param intentUsages: IntentUsage[] = [] Total number of times the room number accessed the service from a voice-activated device.
	 * @param version Version number of the list to prevent simultaneous updates that lose data.
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public useDate: Date = new Date(),
		public intentUsages: IntentUsage[] = [],
		public version: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): DailyUsage {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.intentUsages = [];
		if (item['intentUsages']) {
			item['intentUsages'].forEach( childItem => this.intentUsages.push( new IntentUsage().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add multi-field keys
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		item['useDate'] = Global.getUTCDateString( this.useDate );
		// Copy list of objects
		item['intentUsages'] = [];
		if (this.intentUsages) {
			this.intentUsages.forEach( childItem => item['intentUsages'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			'companyId_propertyId': this.companyId + '_' + this.propertyId,
			'useDate': Global.getUTCDateString( this.useDate ),
        }
    }

}
