import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { VolumePrice } from './VolumePrice';
import { Address } from './Address';

/** Subscriptions to products. */
export class Subscription extends TableDocumentObject {
	
	/**
	 * @param productCode Code that identified product.
	 * @param productName Name of the product.
	 * @param monthlyVolumePrices Array of prices in cents for different volumes of product.
	 * @param firstMonthFree True if customer is not charged for the first month.
	 * @param billingDay Day of the month when customer will be billed (or last day of month if earlier).
	 * @param startDate Date and time when subscription starts (default=now)
	 * @param endDate Date and time subscription ends (default=null which means the subscription is active)
	 * @param nextBillingDate Next scheduled billing date
	 * @param prevBillingDate Previous billing date or null if not billed yet
	 * @param amazonBillingAgreementId Id retrieved from Amazon wallet when user authorized payment
	 * @param beyondToken Token returned from BeyondPay auth or sale request used for recurring billing.
	 * @param beyondGatewayTransId BeyondPay transaction ID used to capture an authorized sale.
	 */
	constructor(
		public productCode: string = null,
		public productName: string = null,
		public monthlyVolumePrices: VolumePrice[] = [],
		public firstMonthFree: boolean = true,
		public billingDay: number = 1,
		public startDate: Date = new Date(),
		public endDate: Date = null,
		public nextBillingDate: Date = null,
		public prevBillingDate: Date = null,
		public amazonBillingAgreementId: string = null,
		public beyondToken: string = null,
		public beyondGatewayTransId: string = null, 
	) { 
		super(); 
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): Subscription {
		this.copyPropertiesFromObject( item );
		// Handle date properties which may be null and therefore not an instanceof Date which breaks the copy
		this.copyDatePropertyFromObject( 'endDate', item );
		this.copyDatePropertyFromObject( 'nextBillingDate', item );
		this.copyDatePropertyFromObject( 'prevBillingDate', item );
		// Copy list of objects
		this.monthlyVolumePrices = null;
		if (item['monthlyVolumePrices']) {
			this.monthlyVolumePrices = [];
			item['monthlyVolumePrices'].forEach( childItem => this.monthlyVolumePrices.push( new VolumePrice().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Handle date properties which may be null and therefore not an instanceof Date which breaks the copy
		this.copyDatePropertyToObject( 'endDate', item );
		this.copyDatePropertyToObject( 'nextBillingDate', item );
		this.copyDatePropertyToObject( 'prevBillingDate', item );
		// Copy list of objects
		item['monthlyVolumePrices'] = null;
		if (this.monthlyVolumePrices) {
			item['monthlyVolumePrices'] = [];
			this.monthlyVolumePrices.forEach( childItem => item['monthlyVolumePrices'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

	/**
	 * @returns True if schedule has started and has not ended as of the current time.
	 */
	public isActive(): boolean {
		return this.startDate.getTime() < Date.now() && (this.endDate == null || this.endDate.getTime() > Date.now());
	}

	/**
	 * @returns True if schedule has started and has not ended as of the current time.
	 */
	public shouldBeBilled(): boolean {
		return this.nextBillingDate && this.nextBillingDate.getTime() < Date.now();
	}

	/**
	 * Returns the start date of the current billing period.
	 * If there has been a previous bill then use the previous billing date otherwise use the subscription start date.
	 */
	public getBillingPeriodStartDate(): Date {
		let date = this.startDate;
		if (this.prevBillingDate) {
			date = this.prevBillingDate;
		}
		return date;
	}

	/**
	 * Returns the end date of the current billing period which is the day before the next billing date.
	 */
	public getBillingPeriodEndDate(): Date {
		let date: Date = null;
		if (this.nextBillingDate) {
			date = this.nextBillingDate;
			// Make sure time is midnight UTC time, we only use the date portion for billing periods.
			date.setUTCHours( 0, 0, 0, 0 );
			date = new Date( date.getTime() - (24 * 60 * 60 * 1000) );
		}
		return date;
	}

	/**
	 * Calculates the billing amount from the number of units and a volume discount table.
	 * Amount is calculated for each level in the discount table until all the units are used up
	 * We don't calculate the price of all the units from whichever line in the discount table applies
	 * to solve the problem of buying 1001 costs substantially less buying 1000.
	 * For example, if the table has 100 at $2 and 999999 at $1 and the customer buys 150, then
	 * the customer pays $2 for 100 and $1 for the remaining 50 for a total of $250.
	 * @param units Number of units.  Ie. Room Genie uses 1 unit per room number per month.
	 */
	public calculateBillingCents( units: number ): number {
		let billingAmount = 0;
		console.log( 'Calculating billing amount for '+units+' units.');		
		if (units > 0) {
			for (let i=0; i<this.monthlyVolumePrices.length; i++) {
				// Calculate the number of units that can be purchased at this volume price
				// which is the number of units between the previous volume and this volume
				let volumeUnits = (i == 0 ? this.monthlyVolumePrices[i].quantity : (this.monthlyVolumePrices[i].quantity - this.monthlyVolumePrices[i-1].quantity));
				let billedUnits = Math.min( units, volumeUnits);
				let unitPrice = this.monthlyVolumePrices[i].priceCents;
				billingAmount += billedUnits * unitPrice;
				units -= billedUnits;
				console.log( 'Added '+billedUnits+' at $' + Global.getCurrencyStringForCents( unitPrice ) +', new billingAmount $' + Global.getCurrencyStringForCents( billingAmount ) );
				// if (count <= volumeDiscounts[i].qty) {
				// 	unitPrice = volumeDiscounts[i].price;
				// 	break;
				// }
			}
			// billingAmount = count * unitPrice;
			// console.log( 'Calculated unit price ' + unitPrice +' and billingAmount ' + billingAmount );
		}
		// console.log( 'Calculated billing amount for '+units+' units as: $'+Global.getCurrencyStringForCents( billingAmount ));		
		return billingAmount;
	}

}
