import { Command } from "./Command";
import { Topic } from "../service/Topic";

export class GetAllTopicsCmd extends Command {

	/**
	 * Get list of topics for the given property.
	 * @param companyId ID of the company.
	 * @param propertyId ID of the property.
	 */
	public do( companyId: number, propertyId: number ): Promise<Topic[]> {
		return new Promise<Topic[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: Topic[] = [];
				results.data.forEach( item => rows.push( new Topic().fromDataItem( item ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
