import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { CacheService } from "../../service/cache.service";
import { Global } from '../../service/Global';
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { Product } from '../../service/Product';

/** Displays menu page of reports and settings for Checkin. */
@Component({
    selector: 'checkin-main',
    templateUrl: './checkin-main.html',
    styleUrls: ['./checkin-main.css'],
})
export class CheckinMainComponent {

	private checkInProductCode = Product.CHECK_IN;

	constructor(
		// private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-check fa-fw"></i> VIP Check In' );
	}

	private initialize() {
	}

}
