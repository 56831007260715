import { TableDocumentObject } from './TableHandler'
import { AdProperty } from "./AdProperty";
import { Property } from "./Property";
import { Global } from './Global';

/** 
 * Advertisement information.
 */
export class Ad extends TableDocumentObject {

	/**
	 * @param adCategoryId ID of advertising company
	 * @param subcategoryId ID of the subcategory for this ad or null if there is no subcategory
	 * @param adRadiusMiles Maximum number of miles from site where ad should be played.
	 * @param speech What Alexa should say.
	 * @param startDate Date the ad starts playing, null means never play.
	 * @param endDate If startDate is not null, the last date the ad should play, null means keep playing forever.
	 * @param approved True if ad has been approved since it was added or modified.
	 * @param adProperties List of properties where ad can be played.
	 */
	constructor(
		public adCategoryId: number = null,
		public subcategoryId: number = null,
		public adRadiusMiles: number = null,
		public speech: string = null,
		public startDate: Date = null,
		public endDate: Date = null,
		public approved: boolean = false,
		public adProperties: AdProperty[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Ad {
		this.copyPropertiesFromObject( item );
		this.copyDatePropertyFromObject( 'startDate', item );
		this.copyDatePropertyFromObject( 'endDate', item );
		// Copy list of objects
		this.adProperties = [];
		if (item['adProperties']) {
			item['adProperties'].forEach( childItem => this.adProperties.push( new AdProperty().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		// item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		// item['adCompanyId_adPropertyId_adSiteId'] = this.adCompanyId + '_' + this.adPropertyId + '_' + this.adSiteId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		this.copyDatePropertyToObject( 'startDate', item );
		this.copyDatePropertyToObject( 'endDate', item );
		// Copy list of objects
		item['adProperties'] = [];
		if (this.adProperties) {
			this.adProperties.forEach( childItem => item['adProperties'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			// "companyId_propertyId": this.companyId + '_' + this.propertyId,
			// "adCompanyId_adPropertyId_adSiteId": this.adCompanyId + '_' + this.adPropertyId + '_' + this.adSiteId,
        };
	}

	/** Returns true if there is no start date or the start date has passed and no end date or the end date is in the future. */
//	/** Returns true if the adRadiusMiles and the ad has started and not ended and there is at least one property with a bid. */
	isActive(): boolean {
		return ((!this.startDate) || this.startDate.getTime() <= Date.now()) && ((!this.endDate) || this.endDate.getTime() > Date.now());
		// return this.adRadiusMiles > 0 && this.startDate.getTime() <= Date.now() && ((!this.endDate) || this.endDate.getTime() > Date.now());
		// let active = false;
		// if (this.adRadiusMiles > 0 && this.startDate.getTime() <= Date.now() && ((!this.endDate) || this.endDate.getTime() > Date.now()) && this.adProperties && this.adProperties.length > 0) {
			// // See if there is a ad property with a non-zero bid
			// for (let i=0; i<this.adProperties.length; i++) {
			// 	if (this.adProperties[i].bidCents > 0) {
					// active = true;
			// 		break;
			// 	}
			// }
		// }
		// return active;
	}

	/** Returns bid for property if adRadiusMiles > 0 and the ad has started and not ended and the given property has a bid > 0. */
	getActiveAdProperty( property: Property ): AdProperty {
		let adProperty: AdProperty = null;
		if (this.isActive()) {
			// See if there is a ad property with a non-zero bid
			for (let i=0; i<this.adProperties.length; i++) {
				let adProperty = this.adProperties[i];
				if (adProperty.companyId == property.companyId && adProperty.propertyId == property.propertyId && adProperty.bidCents > 0) {
					adProperty = adProperty;
					break;
				}
			}
		}
		return adProperty;
	}

}
