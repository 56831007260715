import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CacheService } from '../../service/cache.service';
import { Global } from '../../service/Global';
import { Product } from '../../service/Product';
import { PurchaseDetails } from '../../service/PurchaseDetails';
import { GetProductsCmd } from '../../command/GetProductsCmd';

/** Displays pres-sales information for Room Genie. */
@Component({
    selector: 'room-genie-intro',
    templateUrl: './room-genie-intro.html',
    styleUrls: ['./room-genie-intro.css'],
})
export class RoomGenieIntroComponent implements OnInit {

	private errorMessage: string;

	@ViewChild('aboutBreakfast') private aboutBreakfastRef: ElementRef;
	@ViewChild('messageToHousekeeping') private messageToHousekeepingRef: ElementRef;
	@ViewChild('whatTimeIsIt') private whatTimeIsItRef: ElementRef;
	@ViewChild('signUp') private signUpRef: ElementRef;
	@ViewChild('reservation') private reservationRef: ElementRef;
	@ViewChild('thumbsUp') private thumbsUpRef: ElementRef;
	@ViewChild('recommendDinner') private recommendDinnerRef: ElementRef;

	@ViewChild('video') private videoRef: ElementRef;
	private video: HTMLVideoElement;

	private roomGenieProductCode = Product.ROOM_GENIE;
	private roomGenieMonthlyPrice: string = null;

	private roomGeniePlusEchoDotProductCode = Product.ROOM_GENIE_PLUS_ECHO_DOT;
	private roomGeniePlusEchoDotPrice: string = null;
	
	/** True if we are logged in and user's company has a room genie subscription. */
	private isRoomGenieActive = false;

	/** List of all products that are sold. */
	private products: Product[] = [];

	constructor( 
		private router: Router,
		private cache: CacheService
	) {}

	ngOnInit() {
		this.video = this.videoRef.nativeElement;
		let listener = () => {
			this.video.removeEventListener('playing', listener );
			this.cache.sendEventToAnalytics( 'Video', 'Room Genie', 'play');
		};
		this.video.addEventListener('playing', listener );
		this.isRoomGenieActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.ROOM_GENIE ) != -1;
		new GetProductsCmd().do()
		.then( products => {
			this.products = products;
			this.roomGenieMonthlyPrice = Global.getCurrencyStringForCents( Product.getProduct( Product.ROOM_GENIE, products ).monthlyVolumePrices[0].priceCents, false );
			this.roomGeniePlusEchoDotPrice = Global.getCurrencyStringForCents( Product.getProduct( Product.ROOM_GENIE_PLUS_ECHO_DOT, products ).purchaseCents, false );
		})
		.catch( error => {
			Global.logError( 'Error loading products', error );
			this.errorMessage = 'Sorry, there was a problem loading the product information.  Please try again.';
		});
	}

	onSubscribe( productCode ) {
		console.log('onSubscribe( ' + productCode + ' ) called');
		let product = Product.getProduct( productCode, this.products );
		if (!product) {
			Global.log('Error, could not find product code '+productCode );
			this.errorMessage = 'Sorry, could not find product code '+productCode;
		} else {
			this.cache.appComponent.subscribeToProduct( Product.getProduct( productCode, this.products ) );
		}
	}

	playAudio( id: string ) {
		let audio: HTMLAudioElement = null;
		switch( id ) {
			case 'aboutBreakfast': audio = this.aboutBreakfastRef.nativeElement; break;
			case 'messageToHousekeeping': audio = this.messageToHousekeepingRef.nativeElement; break;
			case 'whatTimeIsIt': audio = this.whatTimeIsItRef.nativeElement; break;
			case 'signUp': audio = this.signUpRef.nativeElement; break;
			case 'reservation': audio = this.reservationRef.nativeElement; break;
			case 'thumbsUp': audio = this.thumbsUpRef.nativeElement; break;
			case 'recommendDinner': audio = this.recommendDinnerRef.nativeElement; break;
		}
		audio.play();
  }

}
