import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import {UserLoginService} from "../../service/user-login.service";
import {CacheService} from "../../service/cache.service";
import { Activity } from "../../service/Activity";
import { ActivitySchedule } from "../../service/ActivitySchedule";
import { ActivityScheduleComponent } from "../activity-schedule/activity-schedule.component";
import { ActivityTypeComponent } from "../activity-type/activity-type.component";
import { Global } from '../../service/Global';
import { ActivityType } from '../../service/ActivityType';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";
import { PutActivityTypeCmd } from '../../command/PutActivityTypeCmd';

/** Edit activity details. */
@Component({
    selector: 'activity',
	templateUrl: './activity.html',
	styleUrls: [ 'activity.css' ]
})
export class ActivityComponent extends EditorDetail<Activity> implements OnInit {

	/** Currently selected property ID */
	public propertyId: 0;

	/** Time zone where property is found. */
	public timeZone: string = 'US/Eastern';

	/** List of activity types for this property. */
	public activityTypes: ActivityType[] = [];

	@ViewChild('activityModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	@ViewChild(ActivityScheduleComponent) private detailComponent: ActivityScheduleComponent;

	@ViewChild(ActivityTypeComponent) private activityTypeComponent: ActivityTypeComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private userService: UserLoginService,
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): Activity {
		return new Activity();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {
		if (this.editedRow.activityTypeId == null && this.activityTypes != null && this.activityTypes.length > 0) {
			this.editedRow.activityTypeId = this.activityTypes[0].activityTypeId;
			this.detectChanges();
		}
	}

	/** Return a deep copy of the given object. */
	clone( row: Activity ): Activity {
		return new Activity().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		this.errorMessage = null;

		if (this.editedRow.name == null || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for the activity.';
		}

		if (this.editedRow.activityTypeId == null) {
			this.errorMessage = 'Please select an activity type.';
		}

		if (this.errorMessage == null) {
			this.editedRow.activityTypeId = Number.parseInt( this.editedRow.activityTypeId.toString() );
		}

		return this.errorMessage == null;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete activity : ' + this.editedRow.name + '?';
	}

	private getDateTimeString( date: Date ): string {
		return Global.getDateTime12String( date, this.timeZone );
	}

	private getDateString( date: Date ): string {
		return Global.getDateString( date, this.timeZone );
	}

	insert() {
		this.detailComponent.timeZone = this.timeZone;
		this.handleChildInsert( new ActivitySchedule(), this.editedRow.schedules, this.detailComponent );
	}

	edit( index: number ) {
		this.detailComponent.timeZone = this.timeZone;
		this.handleChildEdit( index, this.editedRow.schedules, this.detailComponent );
	}

	addActivityType() {
		// this.handleChildInsert( new ActivityType( this.cache.currentCompany.companyId, this.propertyId ), <ActivityType[]>[], this.activityTypeComponent );
		let newActivityType = new ActivityType( this.cache.currentCompany.companyId, this.propertyId );
		this.activityTypeComponent.showModalDialog( newActivityType, true, (action,arg) => {
			this.activityTypeComponent.hideDialog();
			if (action === 'insert') {
				// User saved changes, update the list
				newActivityType = this.activityTypeComponent.getEditedData();
				newActivityType.activityTypeId = this.getNextAvailableActivityTypeId();
				new PutActivityTypeCmd().do( newActivityType )
				.then( () => {
					// Add the new activity type to the list and select it
					this.activityTypes.push( newActivityType );
					this.editedRow.activityTypeId = newActivityType.activityTypeId;
					this.detectChanges();
				})
				.catch( error => {
					Global.logError('Error saving data.', error );
					this.errorMessage = 'Sorry, we had a problem saving the data.  Please try again.';
				});
			} else if (action == 'error') {
				Global.logError( 'Error editing new data', arg );
				this.errorMessage = arg.message;
			}
		})
	}

	/** Returns the next available ID which is one higher than the highest ID already used. */
	getNextAvailableActivityTypeId(): number {
		let id = 1;
		this.activityTypes.forEach( row => {
			if (row.activityTypeId >= id) {
				id = row.activityTypeId+1;
			}
		})
		return id;
	}

}