import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { CacheService } from "../../service/cache.service";
import { ActivitySchedule } from "../../service/ActivitySchedule"
import { Global } from '../../service/Global';
import { IMyDpOptions } from 'mydatepicker';
import { EditorDetail } from '../../service/EditorDetail';
import { ConfirmComponent } from "../confirm/confirm.component";
import { TimeInputModel } from '../../service/TimeInputModel';
import { ClosedComponent } from '../closed/closed.component';

/**
 * Company editing component.
 */
@Component({
    selector: 'activity-schedule',
    templateUrl: './activity-schedule.html',
	styleUrls: ['./activity-schedule.css'],
})
export class ActivityScheduleComponent extends EditorDetail<ActivitySchedule> implements OnInit, AfterViewInit {

	private myDatePickerOptions: IMyDpOptions = { dateFormat: 'mm/dd/yyyy' };
	
	/** Time zone where property is found. */
	public timeZone: string = 'US/Eastern';

	// Data model used for date picker, initialized to today.
	private startDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };
	
	// Data model used for date picker, initialized to today.
	private endDateModel: any;// = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };

	// Model variables for start time
	private startTimeModel = new TimeInputModel( null, this.timeZone );

	// Model variables for end time
	private endTimeModel = new TimeInputModel( null, this.timeZone );

	
	// Model variables for activity start time
	private selectedHour = '12';
	private selectedMinute = '0';
	private selectedAmPm = 'p';

	/** Model variables for day of week checkboxes */
	private isSunday = true;
	private isMonday = true;
	private isTuesday = true;
	private isWednesday = true;
	private isThursday = true;
	private isFriday = true;
	private isSaturday = true;
	
	@ViewChild('activityScheduleModalButton') public modalButtonRef: ElementRef;

	@ViewChild('focus') public focusRef: ElementRef;

	@ViewChild(ClosedComponent) private closedComponent: ClosedComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	createNewDefaultRow(): ActivitySchedule {
		return new ActivitySchedule();
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	initializeDialog() {

		// Initialize start date
		this.startDateModel = null;
		if (this.editedRow.startDate) {
			this.startDateModel = { jsdate: this.editedRow.startDate, date: { year: this.editedRow.startDate.getUTCFullYear(), month: this.editedRow.startDate.getUTCMonth()+1, day: this.editedRow.startDate.getUTCDate() } };
		}

		// Initialize end date
		this.endDateModel = null;
		if (this.editedRow.endDate) {
			this.endDateModel = { jsdate: this.editedRow.endDate, date: { year: this.editedRow.endDate.getUTCFullYear(), month: this.editedRow.endDate.getUTCMonth()+1, day: this.editedRow.endDate.getUTCDate() } };
		}
	
		// Initialize start time
		this.startTimeModel.setTime( this.editedRow.startDate, this.timeZone );

		this.isSunday = this.editedRow.daysOfWeek[0] == '1';
		this.isMonday = this.editedRow.daysOfWeek[1] == '1';
		this.isTuesday = this.editedRow.daysOfWeek[2] == '1';
		this.isWednesday = this.editedRow.daysOfWeek[3] == '1';
		this.isThursday = this.editedRow.daysOfWeek[4] == '1';
		this.isFriday = this.editedRow.daysOfWeek[5] == '1';
		this.isSaturday = this.editedRow.daysOfWeek[6] == '1';
	}

	/** Return a deep copy of the given object. */
	clone( row: ActivitySchedule ): ActivitySchedule {
		return new ActivitySchedule().fromDataItem( row.toDataItem() );
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		this.errorMessage = null;

		if (this.startDateModel.jsdate == null) {
			this.errorMessage = 'Please enter a start date.';
		}

		if (this.editedRow.capacity == null || this.editedRow.capacity < 1) {
			this.errorMessage = 'Please enter a capacity greater than 0.';
		}

		if (this.errorMessage == null) {

			this.editedRow.startDate = this.startDateModel.jsdate;
			let time = this.startTimeModel.getTime();
			this.editedRow.startDate.setUTCHours( time.getUTCHours(), time.getUTCMinutes(), 0, 0 );
			
			if (this.endDateModel) {
				this.editedRow.endDate = this.endDateModel.jsdate;
				this.editedRow.endDate.setUTCHours( 0, 0, 0, 0 );
				this.editedRow.endDate = Global.adjustDateByTimeZone( this.editedRow.endDate, this.timeZone, false );
			} else {
				this.editedRow.endDate = null;
			}

			this.editedRow.daysOfWeek = 
				(this.isSunday ? '1' : '0') +
				(this.isMonday ? '1' : '0') +
				(this.isTuesday ? '1' : '0') +
				(this.isWednesday ? '1' : '0') +
				(this.isThursday ? '1' : '0') +
				(this.isFriday ? '1' : '0') +
				(this.isSaturday ? '1' : '0');
			// console.log( 'editedRow='+JSON.stringify(this.editedRow,null,2));
			// this.logDateStuff( 'this.editedRow.startDate', this.editedRow.startDate );
			// this.logDateStuff( 'this.editedRow.endDate', this.editedRow.endDate );
			// this.originalRow.fromDataItem( this.editedRow.toDataItem() );
			// this.logDateStuff( 'this.originalRow.startDate', this.originalRow.startDate );
			// this.logDateStuff( 'this.originalRow.endDate', this.originalRow.endDate );
		}

		return this.errorMessage == null;
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Returns string to display to the user when confirming the deletion of a row. */
	getDeleteConfirmation(): string {
		return 'Are you sure you want to delete activity schedule: ' + this.getDateTimeString( this.editedRow.startDate ) + '?';
	}

	private getDateTimeString( date: Date ): string {
		return Global.getDateTime12String( date, this.timeZone );
	}

	private logDateStuff( name: string, date: Date ) {
		let s = name + '='+date;
		s += ', isNull='+(!date);
		if (date) {
			s+= ', typeof ='+(typeof date);
			s += ', instanceof Date='+(date instanceof Date);
		}
		console.log( s );
	}
	
	insertClosed() {
		this.handleChildInsert( new Date(), this.editedRow.closedDays, this.closedComponent );
	}

	editClosed( index: number ) {
		this.handleChildEdit( index, this.editedRow.closedDays, this.closedComponent );
	}

}