import { RestaurantReservationList } from './RestaurantReservationList';
import { RestaurantTableTop } from './RestaurantTableTop';

/** 
 * Response to a request for a reservation that contains either the reservation list and table
 * that is available or the nearest earlier and later available times during the seating. 
 */
export class RestaurantReservationResponse {

	constructor(
		public isSeatingOpen = true,
		public reservationList: RestaurantReservationList = null,
		public tableTop: RestaurantTableTop = null,
		public startTime: Date = null,
		public endTime: Date = null,
		public alternateEarlierTime: Date = null,
		public alternateLaterTime: Date = null,
	) {}

	public setAlternateTimesIfBetter( earlierTime: Date, laterTime: Date ) {
		this.setAlternateEarlierTimeIfBetter( earlierTime );
		this.setAlternateLaterTimeIfBetter( laterTime );
	}

	public setAlternateEarlierTimeIfBetter( date: Date ) {
		if (date && ((!this.alternateEarlierTime) || this.alternateEarlierTime.getTime() < date.getTime())) {
			// Given time is closer to the desired time than the previous time, or previous time not set, so use this one
			this.alternateEarlierTime = date;
		}
	}

	/**
	 * Set the alternate earlier time to the given date if the given date is earlier or the existing time is not set.
	 * @param date Date to check
	 */
	public setAlternateLaterTimeIfBetter( date: Date ) {
		if (date && ((!this.alternateLaterTime) || this.alternateLaterTime.getTime() > date.getTime())) {
			// Given time is closer to the desired time than the previous time, or previous time not set, so use this one
			this.alternateLaterTime = date;
		}
	}

}

