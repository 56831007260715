import { Command } from "./Command";
import { Topic } from "../service/Topic";

export class PutTopicCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: Topic ): Promise<Topic> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<Topic>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new Topic().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
