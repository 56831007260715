import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import {CacheService} from "../../service/cache.service";
import { EventTable, Event } from "../../service/EventTable";
import { Site } from "../../service/Site"
import { SiteTable } from "../../service/SiteTable"
import { Global } from '../../service/Global';
import {UUIDService} from "../../service/uuid.service";
import {IMyDpOptions} from 'mydatepicker';

/**
 * Company editing component.
 */
@Component({
    selector: 'event',
    templateUrl: './event.html',
	styleUrls: ['./event.css'],
})
export class EventComponent implements OnInit, OnDestroy, AfterViewInit {
    private editedObject: Event = new Event();
	private originalObject: Event;
	private sites: Site[] = [];
	private errorMessage: string = null;
	private resolveModal: (event: Event) => void;
	private rejectModal: (error: Error) => void;

	/** True if we have loaded the site list. */
	private loadedSites = false;

	public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
	};
	
	// Data model used for date picker, initialized to today.
	public dateModel: any = { date: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth()+1, day: new Date().getUTCDate() } };
	
	@ViewChild('eventModalButton') public eventModalButtonRef: ElementRef; private eventModalButton: HTMLButtonElement;

	@ViewChild('siteId') public focusRef: ElementRef; private focusElement: HTMLInputElement;

	constructor(
        public cache: CacheService,
		private siteTable: SiteTable,
		private eventTable: EventTable,
		private uuidService: UUIDService,
	)
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.eventModalButton = this.eventModalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param company Company to edit.
	 * @return Promise with edited object if user saves changes or null if user cancels changes.
	 */
	showModalDialog( objectToEdit: Event ): Promise<Event> {
		return new Promise<Event>( (resolve,reject) => {
			if (!objectToEdit) {
				reject( new Error( 'No edit object provided. ' ) );
			} else {
				this.resolveModal = resolve;
				this.rejectModal = reject;
				// Make a copy of object so we can restore the original values if user cancels.
				this.originalObject = objectToEdit;
				this.editedObject = new Event().fromDataItem( objectToEdit.toDataItem() );
				this.dateModel = { jsdate: this.editedObject.eventDate, date: { year: this.editedObject.eventDate.getUTCFullYear(), month: this.editedObject.eventDate.getUTCMonth()+1, day: this.editedObject.eventDate.getUTCDate() } };
				if (!this.loadedSites) {
					this.siteTable.getAll( this.cache.currentCompany.companyId, this.cache.properties[0].propertyId )
					.then( sites => {
						this.sites = sites;
						this.loadedSites = true;
						// Show the dialog
						this.eventModalButton.click();
						// Put keyboard focus on heading field after dialog is displayed
						setTimeout( () => this.focusElement.focus(), 1000 );
					})
					.catch( error => { 
						Global.logError( 'Error getting list of items to edit.', error ); 
						this.errorMessage = 'Sorry, we had a problem getting the list of items to edit.  Please try again.';
					});
				} else {
					// Show the dialog
					this.eventModalButton.click();
					// Put keyboard focus on heading field after dialog is displayed
					setTimeout( () => this.focusElement.focus(), 1000 );
				}
			}
		});
	}
	
	/** Close the dialog without saving changes. */
	private onCancel() {
		// Hide the dialog
		this.eventModalButton.click();
		this.resolveModal( null );
	}

    private onSave() {
		// Update the object passed in for editing with the edited values
		this.originalObject.fromDataItem( this.editedObject.toDataItem() );
		
		if (!this.editedObject.siteId) {
			this.errorMessage = 'Site ID is required and must be unique within the company.'
		} else {
			if (!this.editedObject.eventId) {
				// We are adding a new event, set the ID to a unique identifier
				this.editedObject.eventId = this.uuidService.UUID();
			}
			// console.log( 'dataModel='+JSON.stringify(this.dateModel,null,2));
			// console.log( 'dateModel.jsdate instanceof Date()=' + (this.dateModel.jsdate instanceof Date) );
			this.editedObject.eventDate = this.dateModel.jsdate;
			this.editedObject.eventDate.setUTCHours( 0, 0, 0, 0 );
			// console.log( 'editedObject='+JSON.stringify(this.editedObject,null,2));
			this.originalObject.fromDataItem( this.editedObject.toDataItem() );
			this.eventTable.put( this.editedObject )
			.then( () => {
				// console.log("SiteTable.put succeeded.");
				// Hide the dialog
				this.eventModalButton.click();
				this.resolveModal( this.originalObject );
			})
			.catch( err => {
				// We failed to insert the Company record
				Global.logError( this.siteTable.getTableName() + '.put failed.', err );
				this.errorMessage = 'Sorry, there was a problem saving the changes.  ' + err.message;
			});
		}
    }

}