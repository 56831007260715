import { TableHandler, TableDocumentObject } from './TableHandler'
// Load the AWS SDK
// import * as DynamoDB from "aws-sdk/clients/dynamodb";
// import { AWSRequestor } from './AWSRequestor';

export class LineTimeSlot extends TableDocumentObject {
	constructor(
		public time: string = null,
		public line: number = 0,
		public seconds: number = 0
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): LineTimeSlot {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

}

export class LineTime extends TableDocumentObject {
	
	/**
	 * Properties stored about each identified company.
	 * @param companyId Unique ID of company (partition key = companyId)
	 * @param siteId ID of the site.
	 * @param date Date for which line times are recorded (time portion is ignored).
	 * @param version I don't know.
	 * @param timeslots Time, number of seconds, and number of people that went through line in 15 minute intervals.
	 */
	constructor(
		public companyId: number = null,
		public siteId: number = null,
		public date: Date = null,
		public version: number = 0,
		public timeslots: LineTimeSlot[] = null,
	) 
	{ super(); }

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): LineTime {
		this.copyPropertiesFromObject( item );
		if (item.date.length === 10) {
			// This is one of the old-style records with 
			console.log( 'this.date='+this.date+', item.date='+item.date);
			this.date = new Date( Date.parse( item.date + 'T00:00:00.000Z' ) );
		}
		console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.date.setUTCHours( 0, 0, 0, 0 );
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

    /**
     * Create object to pass as the key values for a record in the table.
     * @param companyId ID of the company.
     * @param siteId ID of the site.
     * @param date Date of the record.
     */
    getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "date": this.date.toISOString().substr(0,10)
        };
    }

}

/**
 * Class with methods to manage the faceTrackerLineTime table in DynamoDB.
 */
export class LineTimeTable extends TableHandler<LineTime> {

    public constructor() {
		super( 'faceTrackerLineTime' );
	}
	
	public fromDataItem( item: any ): LineTime {
		return new LineTime().fromDataItem( item );
	}

    /**
     * Returns a string representing the timeslot time.  Time slots are 15 minutes long so times
     * >= 9am and < 9:15am returns 09:00, times >= 1:15pm and < 1:30pm return 13:15, etc.
     * @param date Date and time from which the timeslot string is calculated.
     */
    getTimeSlotTime( date: Date ): string {
        let hours: string = date.getUTCHours().toString();
        if (hours.length < 2) {
            hours = '0' + hours;
        }
        let minutes: string = (Math.floor(date.getUTCMinutes() / 15) * 15).toString();
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }

    /**
     * Insert a new document in the faceTrackerResults table.  Fails if primary key already exists.
     * @param companyId Company ID.
     * @param siteId Site ID.
     * @param date Timestamp when face was detected at front of line.
     * @param lineCount Number of people detected at both back of line and front of line.
     * @param totalSecondsInLine Total seconds in line wait time for all people.
     * @param oldData Data from lineTimeTable that we are updating so we can do a conditional update to prevent lost updates when multiple updates occur at the same time.
     */
    increaseLineCountAndLineTime( companyId: number, siteId: number, date: Date, lineCount: number, totalSecondsInLine: number, oldLineTime: LineTime ): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            console.log( 'increaseLineCountAndLineTime( companyId='+companyId+', siteId='+siteId+', date='+date.toISOString()+', lineCount='+lineCount+', totalSecondsInLine='+totalSecondsInLine+' )' );
            
            // Find the timeslot to update based on the given time, if this is the first update to the timeslot it won't exist
            let timeString = this.getTimeSlotTime( date );
            let oldVersion: number = Number( oldLineTime.version );
            let timeslotIndex: number = undefined;
            for (let index = 0; index < oldLineTime.timeslots.length; index++) {
                let timeslot = oldLineTime.timeslots[index];
                // console.log( 'timeString='+timeString+', timeslot.time='+timeslot.time.S+', timeslot['+index+']='+JSON.stringify(timeslot) );
                if (timeslot.time == timeString) {
                    timeslotIndex = index;
                    break;
                }
            }
            if (timeslotIndex) {
                // The timeslot exists, update it
                let timeslot = oldLineTime.timeslots[timeslotIndex];
                timeslot.line += lineCount;
                timeslot.seconds += totalSecondsInLine;
            } else {
                // The timeslot doesn't exist, add it to the data
                oldLineTime.timeslots.push( new LineTimeSlot( timeString, lineCount, totalSecondsInLine ) );
            }
            // Update the version number so we can be sure we are updating the right version of the doc
			oldLineTime.version = oldVersion + 1;
			this.put( oldLineTime, "version = :v", { ":v": oldVersion } )
			.then( () => resolve() )
			.catch( err => reject( err ) );
        });
    }

}