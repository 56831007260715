import {Component, OnInit} from "@angular/core";
import { Global } from '../service/Global';

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './vip-alert.html'
})
export class VipAlertComponent {
    constructor() {
        console.log("VipAlertComponent constructor");
    }
    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }
}
