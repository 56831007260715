import { TableDocumentObject } from './TableHandler'

export class IntentUsage extends TableDocumentObject {

	constructor(
		public voice: boolean = true,
		public intent: string = null,
		public slots: string = null,
		public uses: number = 0,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): IntentUsage {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

}