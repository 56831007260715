import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../../service/user-registration.service";
import { CacheService } from "../../service/cache.service"

/** Page where user enters new account data. */
@Component({
    selector: 'create-advertiser-account',
    templateUrl: './create-advertiser-account.html',
	styleUrls: ['./create-advertiser-account.css'],
})
export class CreateAdvertiserAccountComponent implements AfterViewInit {
    name: string;
    email: string;
	password: string;
	errorMessage: string;
	confirmPassword: string;

	@ViewChild('focus') private focusRef: ElementRef; private focusElement: HTMLInputElement;
		
    constructor(
		private userRegistration: UserRegistrationService,
		private router: Router,
		private cache: CacheService,
	) {}

    ngAfterViewInit() {
		this.focusElement = this.focusRef.nativeElement;
		setTimeout( () => this.focusElement.focus(), 1000 );
	}

    onRegister() {
		this.errorMessage = null;
		// if ((!this.name) || this.name.length == 0) {
		// 	this.errorMessage = 'Please enter your name.'
		// } else 
		if ((!this.email) || this.email.length == 0) {
			this.errorMessage = 'Please enter your email address.'
		} else if ((!this.password) || this.password.length == 0) {
			this.errorMessage = 'Please enter a password.'
		} else if (this.password != this.confirmPassword) {
			this.errorMessage = 'The password and confirmation password don\'t match.  Please re-enter them.'
		} else {
			this.userRegistration.registerUser( this.name, this.email, this.password )
			.then( result => {
				//move to the next step
				this.cache.sendEventToAnalytics( 'CreateAccount', 'submit email', 'submit' );
				// console.log("redirecting");
				this.cache.currentUserEmail = this.email;
				this.cache.set("password", this.password );
				this.router.navigate(['/signuphome/confirm-email']);
			})
			.catch( err => {
				this.errorMessage = err.message;
			});
		}
    }

}
