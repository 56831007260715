import { Command } from "./Command";
import { Site } from "../service/Site";

export class GetSitesCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number, propertyId: number ): Promise<Site[]> {
		return new Promise<Site[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: Site[] = [];
				results.data.forEach( layout => rows.push( new Site().fromDataItem( layout ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
