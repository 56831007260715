import { Command } from "./Command";
import { RestaurantLayout } from "../service/RestaurantLayout";

export class GetRestaurantLayoutListCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 * @param propertyId Property ID
	 */
	do( companyId: number, propertyId: number ): Promise<RestaurantLayout[]> {
		return new Promise<RestaurantLayout[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: RestaurantLayout[] = [];
				results.data.forEach( layout => rows.push( new RestaurantLayout().fromDataItem( layout ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
