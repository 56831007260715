import { Command } from "./Command";
import { PropertyName } from "../service/PropertyName";

export class GetAvailableAdPropertyNamesCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number, latitude: number, longitude: number, adRadiusMiles: number ): Promise<PropertyName[]> {
		return new Promise<PropertyName[]>( (resolve, reject) => {
			let parameters = {
				"testParm": 1,
				"latitude": latitude,
				"longitude": longitude,
				"adRadiusMiles": adRadiusMiles
			};
			// console.log( 'Calling GetAvailableAdPropertyNamesCmd for company '+companyId+' with parameters '+JSON.stringify( parameters, null, 2) );
			super.send( this.constructor.name, companyId, parameters )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: PropertyName[] = [];
				results.data.forEach( row => rows.push( new PropertyName().fromDataItem( row ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
