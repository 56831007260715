import { Command } from "./Command";
import { Activity } from "../service/Activity";

export class PutActivityCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: Activity ): Promise<Activity> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<Activity>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new Activity().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
