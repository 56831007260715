import { Global } from './Global';
import { CognitoUtil } from "./cognito.service";

/** API gatweay client factory. */
declare var apigClientFactory: any;

/** Class for calls to the Portal API gateway. */
export class ApiGateway {
	
	/**
	 * Call one of the Portal API's using the currently logged in user's Cognito identity token to authorize the function.
	 * @param URI of the API resource, eg. '/command'
	 * @param body Body of the request.
	 */
	public call( uri: string, body: any ): Promise<any> {
		return new Promise<any>( (resolve, reject) => {
			// console.log( 'Calling Portal API ' + uri + ' with body ' + JSON.stringify( body, null, 2 ) );
			new CognitoUtil().getCurrentIdToken()
			.then( token => {
				this.callPost( uri, body, token )
				.then( result => resolve( result ) )
				.catch( error => reject( error ) );
				// // console.log( 'Got ID token')
				// // Add modeled request parameters here, the key is the parameter name, as it is defined in the API in API Gateway.
				// var params = { /* param0: '',*/ };
	
				// // Add unmodeled query parameters or headers that must be sent with the request here.
				// var additionalParams = { headers: { "Authorization": token }, /*queryParams: { param0: '' }*/ };
	
				// var apigClient = apigClientFactory.newClient();
				// // console.log( 'apigClient='+JSON.stringify(apigClient,null,2));
				// apigClient.post(params, body, additionalParams, uri)
				// .then( result => {
				// 	// console.log( 'API call succeeded in ' + (Date.now()-startTime) + 'ms.: '+JSON.stringify( result.data, null, 2 ) );
				// 	resolve( result );
				// }).catch( error => {
				// 	// console.log( 'API call failed in ' + (Date.now()-startTime) + 'ms.: ' + JSON.stringify( error.data, null, 2 ) );
				// 	reject( error );
				// });
			})
			.catch( error => {
				reject( error );
			});
		});
	}

	private callPost( uri: string, body: any, token: any ): Promise<any> {
		return new Promise<any>( (resolve, reject) => {
			// let startTime = Date.now();
			// Add modeled request parameters here, the key is the parameter name, as it is defined in the API in API Gateway.
			var params = { /* param0: '',*/ };

			// Add unmodeled query parameters or headers that must be sent with the request here.
			var additionalParams = { headers: { "Authorization": token }, /*queryParams: { param0: '' }*/ };

			var apigClient = apigClientFactory.newClient();
			// console.log( 'apigClient='+JSON.stringify(apigClient,null,2));
			apigClient.post(params, body, additionalParams, uri)
			.then( result => {
				// console.log( 'API call succeeded in ' + (Date.now()-startTime) + 'ms.: '+JSON.stringify( result.data, null, 2 ) );
				resolve( result );
			}).catch( error => {
				// console.log( 'API call failed in ' + (Date.now()-startTime) + 'ms.: ' + JSON.stringify( error.data, null, 2 ) );
				reject( error );
			});
		});
	}

}
