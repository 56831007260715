import {Component, OnInit, AfterViewInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import * as S3 from "aws-sdk/clients/s3";
import {UUIDService} from "../../service/uuid.service";
import { Global } from '../../service/Global';

// Declare variable defined in google api library
declare const gapi : any;

// Client ID and API key from the Developer Console
var CLIENT_ID = '751494232759-ughqj46u811verhqdec3qaggd76v36ku.apps.googleusercontent.com';

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

// Google cloud pub/sub topic name used to get push notifications when messages arrive in gmail
let userWatchTopicName = 'projects/graphic-mission-175419/topics/gmail-alarms';

// Keep track of the date/time of the last message we received so we don't process the same messages multiple times
// let latestProcessedMessageTime: Date = new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12, 0, 0 );
let latestProcessedMessageTime: Date = new Date();

// The latest message time found during a single scan of the INBOX.
let latestInboxMessageTime: number = null;

// Flag for whether we are already listening for emails or not
let listeningForMessages = false;

// Images from GMail will be treated as coming from this camera ID
let gmailSourceCameraId: number = 1;

@Component({
    selector: 'gmaillistener',
    templateUrl: './gmail-listener.html',
    // styleUrls: ['./gmail-listener.css'],
})
export class GmailListenerComponent implements OnInit, AfterViewInit  {

	// Images from GMail will be treated as coming from this camera ID
	public sourceCameraId: number = gmailSourceCameraId;
	
	constructor( 
		private router: Router, 
		private userService: UserLoginService, 
		private uuidService: UUIDService ) 
	{
        console.log("GmailListenerComponent constructor");
	}

    initialize() {
		gapi.load( 'client:auth2', this.initClient.bind( this ) );
	}
	
    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngAfterViewInit() {
		// gapi.load( 'client:auth2', this.initClient.bind( this ) );
	}

	/**
	 *  Initializes the API client library and sets up sign-in state
	 *  listeners.
	 */
	private initClient() {
		gapi.client.init({
			discoveryDocs: DISCOVERY_DOCS,
			clientId: CLIENT_ID,
			scope: SCOPES
		}).then( () => {
			// Listen for sign-in state changes.
			gapi.auth2.getAuthInstance().isSignedIn.listen( this.updateSigninStatus.bind( this ) );

			// Handle the initial sign-in state.
			this.updateSigninStatus( gapi.auth2.getAuthInstance().isSignedIn.get() );
		});
	}

	/**
	 *  Called when the signed in status changes, to update the UI
	 *  appropriately. After a sign-in, the API is called.
	 */
	updateSigninStatus(isSignedIn) {
		try {
			if (isSignedIn) {
				this.appendPre('Checking for email periodically.  Last processed message was ' + latestProcessedMessageTime );
				// authorizeButton.style.display = 'none';
				// signoutButton.style.display = 'inline';
				//this.listLabels();
				// Don't start more than one message listener
				if (!listeningForMessages) {
					listeningForMessages = true;
					this.listMessages( 'me' );
				}
				// this.watchForMessage( 'me' );
			} else {
				// authorizeButton.style.display = 'inline';
				// signoutButton.style.display = 'none';
			}
		} catch( error ) {
			console.log( error.mesage, error.stack );
		}
	}

	/**
	 * Get the query to filter out the message that we want to check in the gmail inbox.
	 */
	private getMessageQuery(): string {
		let lastTimeInSeconds = Math.round( latestProcessedMessageTime.getTime() / 1000 );
		// console.log('Unix time in seconds for '+latestProcessedMessageTime+' is: ' + lastTimeInSeconds );
		return 'from:(cneimeister@gmail.com) to:(cneimeister@gmail.com) subject:VIP_Cam after:'+lastTimeInSeconds+' has:attachment';
	}

	/**
	 * Toggle gmail images between camera 1 and 2 on button click.
	 */
	handleSwitchCamera() {
		gmailSourceCameraId = gmailSourceCameraId == 1 ? 2 : 1;
		this.sourceCameraId = gmailSourceCameraId;
	}

	/**
	 *  Sign in the user upon button click.
	 */
	handleAuthClick() {
		gapi.auth2.getAuthInstance().signIn();
	}

	/**
	 *  Sign out the user upon button click.
	 */
	handleSignoutClick() {
		gapi.auth2.getAuthInstance().signOut();
	}

	/**
	 * Append a pre element to the body containing the given message
	 * as its text node. Used to display the results of the API call.
	 *
	 * @param {string} message Text to be placed in pre element.
	 */
	private appendPre(message) {
		var pre = document.getElementById('gmailMessageListContent');
		if (pre) {
			var textContent = document.createTextNode(message + '\n');
			pre.appendChild(textContent);
		}
	}

	/**
	 * Print all Labels in the authorized user's inbox. If no labels
	 * are found an appropriate message is printed.
	 */
	private listLabels() {
		gapi.client.gmail.users.labels.list({
			'userId': 'me'
		}).then( response => {
			var labels = response.result.labels;
			this.appendPre('Labels:');

			if (labels && labels.length > 0) {
				for (let i = 0; i < labels.length; i++) {
					var label = labels[i];
					this.appendPre(label.name)
				}
			} else {
				this.appendPre('No Labels found.');
			}
		});
	}

	/**
	 * Retrieve Messages in user's mailbox matching query.
	 *
	 * @param  {String} userId User's email address. The special value 'me'
	 * can be used to indicate the authenticated user.
	 * @param  {String} query String used to filter the Messages listed.
	 * @param  {Function} callback Function to call when the request is complete.
	 */
	listMessages( userId ) {
		// this.appendPre('Checking for email, last processed message time is ' + latestProcessedMessageTime );
		var getPageOfMessages = (request, result) => {
			// console.log('Checking for email, last processed message time is ' + latestProcessedMessageTime );
			request.execute( resp => {
				// console.log( JSON.stringify( resp ) );
				if (!resp.result) {
					console.log('Gmail API call failed.  Code: ' + resp.code + ' - ' + resp.message );
				}
				let messages = resp.result.messages;
				if (messages && messages.length > 0) {
					for (let i = 0; i < messages.length; i++) {
						let message = messages[i];
						this.processMessage( userId, message.id );
						// this.appendPre( JSON.stringify( message ) );
					}
				}
					
				result = result.concat(resp.messages);
				var nextPageToken = resp.nextPageToken;
				if (nextPageToken) {
					request = gapi.client.gmail.users.messages.list({
						'userId': userId,
						'labelIds': 'INBOX',
						'pageToken': nextPageToken,
						'q': this.getMessageQuery()
					});
					getPageOfMessages(request, result);
				} else {
					// We are finished going through the list of messages, update the latest message
					// time so we don't process the same messages again.
					if (latestInboxMessageTime && latestInboxMessageTime > latestProcessedMessageTime.getTime()) {
						latestProcessedMessageTime.setTime( latestInboxMessageTime );
						// this.appendPre( 'Set latestProcessedMessageTime to '+latestProcessedMessageTime);
					} else {
						// this.appendPre( 'Not setting latestProcessedMessageTime, it is still '+latestProcessedMessageTime);
					}
					// Clear the latest inbox message time in case an email comes in later with an earlier date.
					latestInboxMessageTime = null;
					// Check for new messages again in 5 seconds
					// console.log( 'Setting timer to check again in 10 seconds');
					setTimeout( () => {
						this.listMessages( 'me' );
					}, 10000 );
				}
			});
		};
		var initialRequest = gapi.client.gmail.users.messages.list({
			'userId': userId,
			'labelIds': 'INBOX',
			'q': this.getMessageQuery()
		});
		getPageOfMessages(initialRequest, []);
	}

	/**
	 * Retrieve Messages in user's mailbox matching query.
	 *
	 * @param  {String} userId User's email address. The special value 'me'
	 * can be used to indicate the authenticated user.
	 * @param  {String} query String used to filter the Messages listed.
	 * @param  {Function} callback Function to call when the request is complete.
	 */
	processMessage( userId, messageId ) {
		gapi.client.gmail.users.messages.get({
			'id': messageId,
			'userId': userId,
		})
		.then( response => {
			// console.log( 'Got message: '+JSON.stringify( response, null, 2 ) );
			// console.log( 'Got message: '+JSON.stringify( response.result.payload.headers, null, 2 ) );
			let subject: string = null;
			let messageTime: Date = null;
			response.result.payload.headers.forEach( header => {
				if (header.name == 'Subject') {
					// Get message subject
					subject = header.value;
				}
				if (header.name == 'Date') {
					// Get message date/time which has format: Tue, 01 Aug 2017 13:23:55 -0600"
					messageTime = new Date();
					messageTime.setTime( Date.parse( header.value ) );
				}
			});
			if (subject && messageTime && messageTime.getTime() > latestProcessedMessageTime.getTime()) {
				console.log( 'Processing message from '+ messageTime );
				this.appendPre( 'Processing new email: ' + subject );
				this.getAttachments( userId, response.result );
				if (latestInboxMessageTime == null || messageTime.getTime() > latestInboxMessageTime) {
					latestInboxMessageTime = messageTime.getTime();
				}
			} else {
				// console.log( 'Ignoring email from '+ messageTime+', lastTime='+latestProcessedMessageTime.getTime() );
				// this.appendPre( 'Already processed email: ' + subject );
			}
		},
		error => {
			let msg = 'Error ' + error.result.error.code + ' getting message with id ['+messageId + '] for user ID [' + userId + '].  ' + error.result.error.message;
			console.log( msg );
			// Don't throw error in promise resolve handler, there is no one to catch it!  Notify user if desired.
			alert( msg );
		});
	}

	/**
	 * Get Attachments from a given Message.
	 *
	 * @param  {String} userId User's email address. The special value 'me'
	 * can be used to indicate the authenticated user.
	 * @param  {String} messageId ID of Message with attachments.
	 * @param  {Function} callback Function to call when the request is complete.
	 */
	private getAttachments( userId, message ) {
		var parts = message.payload.parts;
		message.payload.parts.forEach( part => {
			if (part.filename && part.filename.length > 0) {
				var attachId = part.body.attachmentId;
				gapi.client.gmail.users.messages.attachments.get({
					'id': attachId,
					'messageId': message.id,
					'userId': userId
				})
				.then( attachment => {
					if (attachment.status == 200) {
						console.log( 'Got attachment ' + part.filename + ' of type ' + part.mimeType + ', size=' + attachment.result.size );
						this.putImageInS3Bucket( attachment.result.data, 1, 2, gmailSourceCameraId );
					} else {
						console.log( 'Error getting attachment [' + part.filename + '] in message id ' + message.id + '.  ' + attachment.statusText );
					}
				},
				error => {
					let err = new Error( 'Error ' + error.result.error.code + ' getting attachment [' + part.filename + '] in message id ' + message.id + ' for user ID [' + userId + '].  ' + error.result.error.message );
					console.log( err.message );
					// Don't throw error in promise resolve handler, there is no one to catch it!  Show it to the user here if desired.
					alert( err.message );
				});
			}
		});
	}

	private putImageInS3Bucket( base64data: string, companyId: number, siteId: number, cameraId: number ) {
		let imageData=new Buffer( base64data, 'base64' );
		var params = {
			Body: imageData, // <Binary String>
			Bucket: Global.visitPhotoBucketName, 
			Key: Global.getGuestImageName( companyId, 1, siteId, cameraId ),
		};
		// console.log( 'Putting image in S3 bucket: ' + base64data );
		let s3 = new S3();
		s3.upload(params, function(err, data) {
			if (err) {
				console.log('S3 error:', err, err.stack);
				alert( "Upload to cloud failed.  " + err.message );
			} else {
				console.log('S3 put succeeded:  ', data.Location);
			}
 		});
	}

	/**
	 * Tell Gmail to send a notification to a PubSub topic when an email for this user arrives.
	 *
	 * @param  {String} userId User's email address. The special value 'me'
	 * can be used to indicate the authenticated user.
	 * @param  {String} messageId ID of Message with attachments.
	 * @param  {Function} callback Function to call when the request is complete.
	 */
	private watchForMessage( userId ) {
		let parameters = { 
			'userId': userId,
		// };
		// let body = {
			"labelIds": [ 'INBOX' ],
			// "labelFilterAction": '',
			"topicName": userWatchTopicName
		};
		console.log( 'parameters: '+JSON.stringify( parameters, null, 2 ) );
		gapi.client.gmail.users.watch( parameters )
		.then( response => {
			console.log( 'users.watch response: '+JSON.stringify( response, null, 2 ) );
			if (response.status == 200) {
				console.log( ' users.watch succeeded' );
			} else {
				console.log( 'Error ' + response.status + ' setting user [' + userId + '] to publish notifications when emails arrive.  ' + response.statusText );
			}
		}, 
		error => {
			let err = new Error( 'Error ' + error.result.error.code + ' setting user [' + userId + '] to publish notifications when emails arrive.  ' + error.result.error.message );
			console.log( err.message );
			// Don't throw error in promise resolve handler, there is no one to catch it!  Show it to the user here if desired.
			alert( err.message );
		});
	}
	
}
