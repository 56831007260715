import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Global } from '../../service/Global';
import { CacheService } from "../../service/cache.service";
import { Property } from "../../service/Property";
import { S3Service } from '../../service/s3.service';

/** Displays instructions for setting up Room Genie skill on Alexa. */
@Component({
    selector: 'articles',
    templateUrl: './articles.html',
    styleUrls: ['./articles.css'],
})
export class ArticlesComponent implements OnInit, AfterViewInit {

	private errorMessage: string;

	/** Array of JSON objects with the name and title of each article. */
	private articles: any[] = [];

	/** Base path is set to home or securehome depending on whether the user is logged in and we should use the secure navigation bar. */
	private basePath = 'home';

	@ViewChild('contents') private contentsRef: ElementRef;

	@ViewChild('article') private articleRef: ElementRef;

	constructor( 
		private route: ActivatedRoute, 
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private cache: CacheService,
		private renderer: Renderer2,
		private s3Service: S3Service,
	) {}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		if (this.cache.currentUserId) {
			this.basePath = "securehome"
		} else {
			this.basePath = "home"
		}
	}
	
	ngAfterViewInit() {
		// Load the list of articles to display
		this.s3Service.get( Global.articlesBucketName, 'articles.json' )
		.then( data => {
			this.errorMessage = null;
			let articlesJson = data.Body.toString('utf8');
			// Global.log('articles.json='+articlesJson);
			this.articles = JSON.parse( articlesJson );
			// Global.log('this.articles: '+JSON.stringify(this.articles,null,2));

			// If user is not logged in, only show articles without the mustBeLoggedIn flag set to true
			if (!this.cache.currentUserId) {
				let oldArticles = this.articles;
				this.articles = [];
				oldArticles.forEach( article => {
					if (!article.mustBeLoggedIn) {
						this.articles.push( article );
					}
				})
			}

			// Load the article from S3 if article name is on the URL path
			this.route.params.subscribe( params => {
				if (!params.urlLink) {
					// No article specified, default to first article in the list
					let article = this.articles[0];
					this.router.navigate(['/', this.basePath, 'articles', article.name]);
				} else {
					// Display the article name specified in the URL
					let articleName = params.urlLink;

					// Download the article contents from S3
					this.s3Service.get( Global.articlesBucketName, articleName+'.html' )
					.then( data => {
						this.errorMessage = null;
						let html = data.Body.toString('utf8');
						// Display the article contents on the page
						this.renderer.setProperty( this.articleRef.nativeElement, 'innerHTML', html );
					})
					.catch( error => {
						Global.logError("Error loading article", error );
						this.errorMessage = 'Sorry, there was a problem loading the article.';
					});
				}
			});
		})
		.catch( error => {
			Global.logError("Error loading contents", error );
			this.errorMessage = 'Sorry, there was a problem loading the table of contents.';
		});
	}

	initialize() {
	}

	scrollTo( id: string ) {
		const element = document.querySelector('#' + id);
		if (element) {
			element.scrollIntoView(); // <-- omit element from the argument
		}
	}

	getCompanyId(): string {
		return this.cache.currentCompany ? this.cache.currentCompany.companyId.toString() : '';
	}

	getProperties(): Property[] {
		return this.cache.properties ? this.cache.properties : <Property[]>[new Property(0,0,null)];
	}	

	getFirstProperty(): Property {
		return this.cache.properties && this.cache.properties[0] ? this.cache.properties[0] : new Property(0,0,null);
	}	

}
