import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {DynamoDBService, Timeslot} from "../../service/ddb.service";
import {GoogleChartsService} from "../../service/google-charts.service";
import { Global } from '../../service/Global';

/*
Displays line charts showing average time in line and number of people that went thru line by day part.
*/

export class Stuff {
    public type: string;
    public date: string;
}

@Component({
    selector: 'comparesites',
    templateUrl: './comparesites.html',
    // styleUrls: ['.comparesites.css'],
})
export class CompareSitesChartComponent implements OnInit {

	public logdata: Array<Stuff> = [];
	private avgTimeChartData;
	private avgTimeChartOptions = {
		width: 800,
		// height: 200,
		curveType: 'function',
		legend: { position: 'none' },
		pointSize: 5,
		backgroundColor: '#f8f8f8',
		bar: {
			groupWidth: '65%',
		},
		hAxis: {
			minValue: 0,
			gridlines: {
				count: -1,
			}
		},
		vAxis: {
			minValue: 0,
			viewWindow: { min: 0 },
		},
	};

	private countChartData;
	private countChartOptions = {
		width: 800,
		// height: 200,
		curveType: 'function',
		legend: { position: 'none' },
		pointSize: 5,
		backgroundColor: '#f8f8f8',
		bar: {
			groupWidth: '65%',
		},
		hAxis: {
			minValue: 0,
			gridlines: {
				count: -1,
			}
		},
		vAxis: {
			minValue: 0,
			viewWindow: { min: 0 },
		},
	};
	

	
	constructor(public router: Router, public userService: UserLoginService, public ddb: DynamoDBService, public googleCharts: GoogleChartsService ) {
		console.log( 'CompareSitesChartComponent constructor width='+window.innerWidth+', height='+window.innerHeight );
		// Set the line chart size to 90% of the window width
		this.avgTimeChartOptions.width = Math.round( window.innerWidth * 1.00 );
		this.countChartOptions.width = Math.round( window.innerWidth * 1.00 );
    }

    initialize() {
		this.ddb.getLineTimeChartData( 4 )
		.then(timeslots => {
			this.setupChartData( timeslots );
			this.drawAvgTimeGoogleLineChart();
			this.drawCountGoogleLineChart();
		}).catch( err => {
			// Failure here usually means user token has expired so return to login
			this.router.navigate(['/home/login']);
		});
	}
	
    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	onResizeAvgTimeChart(event) {
		console.log( 'onResizeAvgTimeLineChart width='+event.target.innerWidth+', height='+event.target.innerHeight );
		if (this.avgTimeChartData) {
			// console.log( 'onResizeAvgTimeLineChart redrawing' );
			this.avgTimeChartOptions.width = Math.round( event.target.innerWidth * 1.00 );
			this.drawAvgTimeGoogleLineChart();
		}
	}

	onResizeCountChart(event) {
		console.log( 'onResizeCountLineChart width='+event.target.innerWidth+', height='+event.target.innerHeight );
		if (this.countChartData) {
			// console.log( 'onResizeCountLineChart redrawing' );
			this.countChartOptions.width = Math.round( event.target.innerWidth * 1.00 );
			this.drawCountGoogleLineChart();
		}
	}

	setupChartData( timeslots: Timeslot[] ) {
		let valueCount = 0;
		let valueSum = 0;
		let lineCountSum = 0;
		this.avgTimeChartData = [];
		this.avgTimeChartData.push( ['Sites', 'Minutes in Line'] );
		this.countChartData = [];
		this.countChartData.push( ['Time of Day', '# of People Thru Line'] );
		timeslots.forEach(timeslot => {
			let value = 0;
			if (timeslot.lineCount != 0) {
				value = Math.round( ((timeslot.seconds / timeslot.lineCount) * 10) ) / 10;
			}
			lineCountSum += timeslot.lineCount;
			valueCount++;
			valueSum += value;

			// Convert timeslot time string to Date object so line chart uses 'continuous' values
			let timeParts = timeslot.time.split(':');
			let hours = Number.parseInt( timeParts[0] );
			let minutes = Number.parseInt( timeParts[1].substr(0,2) );
			let ampm = timeParts[1].substr(2,1);
			if (ampm == 'p' && hours < 12) {
				hours += 12;
			}
			if (ampm == 'a' && hours == 12) {
				hours = 0;
			}
			let hoursString = hours.toString();
			if (hoursString.length == 1) {
				hoursString = '0'+hoursString;
			}
			let minutesString = minutes.toString();
			if (minutesString.length == 1) {
				minutesString = '0'+minutesString;
			}
			let timeOfDayString = '2000-01-01T'+hoursString+':'+minutesString+':00'
			let timeOfDay = new Date( timeOfDayString );
			
			// console.log( 'timeOfDayString='+timeOfDayString+', timeOfDay='+timeOfDay );
			this.avgTimeChartData.push( [ timeOfDay, value ] );
			this.countChartData.push( [ timeOfDay, timeslot.lineCount ] );

			// Fake the chart data
			let bootstrapSuccess = '#5cb85c';
			let bootstrapWarning = '#ec971f';
			let bootstrapDanger = '#c9302c';
			this.avgTimeChartData = [
				['Sites', 'Minutes in Line', { role: 'style' }],
				['1 - Main St', 2.5, bootstrapSuccess ],
				['2 - East Side', 4.1, bootstrapDanger ],
				['3 - Highway', 3.8, bootstrapWarning ],
				['4 - 4th and Elm', 2.1, bootstrapSuccess ],
				['5 - River Rd', 3.2, bootstrapWarning ],
			];
			let bootstrapActive = '#31b0d5';
			this.countChartData = [
				['Sites', 'Line Count', { role: 'style' }],
				['1 - Main St', 8475, bootstrapActive ],
				['2 - East Side', 9123, bootstrapActive ],
				['3 - Highway', 10210, bootstrapActive ],
				['4 - 4th and Elm', 7449, bootstrapActive ],
				['5 - River Rd', 7902, bootstrapActive ],
			];
		});
	}

	drawAvgTimeGoogleLineChart() {
		this.googleCharts.loadLibraries()
		.then( () => {
			var wrapper = this.googleCharts.createChartWrapper({
				chartType: 'BarChart',
				dataTable: this.avgTimeChartData,
				options: this.avgTimeChartOptions,
				containerId: 'avgTimeChart'
			});
			// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
			wrapper.draw();
		})
		.catch( err => {
			Global.logError('Error drawing compare sites chart.', err );
			throw new Error( 'Error drawing compare sites chart.  ' +  err );
		});
	}

	drawCountGoogleLineChart() {
		this.googleCharts.loadLibraries()
		.then( () => {
			var wrapper = this.googleCharts.createChartWrapper({
				chartType: 'BarChart',
				dataTable: this.countChartData,
				options: this.countChartOptions,
				containerId: 'countChart'
			});
			// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
			wrapper.draw();
		})
		.catch( err => {
			Global.logError('Error drawing count chart.', err );
			throw new Error( 'Error drawing count chart.  ' +  err );
		});
	}

}

