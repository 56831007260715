import { Global } from './Global';
import { CacheService } from "./cache.service";
import { S3Service } from "./s3.service";
import { Visit } from './Visit';
import { Person } from './Person';
import { PersonTable } from './PersonTable';

/** List of visits we've loaded and are keeping in memory to make scrolling quick. */
export class VisitList {

	/** Max number of visits to keep in memory for scrolling. */
	private readonly maxVisits = 10;
	
	/** List of visits we've loaded and are keeping in memory to make scrolling quick. */
	private visits: Visit[] = [];

	/** Person records we've loaded keyed by personId */
	private persons: Map<string,Person> = new Map<string,Person>();
	
	/** Images we've loaded from S3 */
	private images: Map<string,string> = new Map<string,string>();

	/** Start time of the latest visit ever loaded into the list so we know if we've thrown away some of the visits we loaded to preserve memory. */
	private latestVisitLoaded: Date = null;

	constructor(
		private cacheService: CacheService,
		private s3Service: S3Service,
		private personTable: PersonTable,
	){}

	/** Return the number of visits in the list. */
	public getLength() {
		return this.visits.length;
	}

	/** Return the max number of visits that we will hold in memory for fast scrolling. */
	public getMaxVisits() {
		return this.maxVisits;
	}

	/**
	 * Return the visit at the given index.
	 * @param index Index to return.
	 */
	public get( visitIndex: number ): Visit {
		let visit = null;
		if (visitIndex >= 0 && visitIndex < this.visits.length) {
			visit = this.visits[ visitIndex ];
		}
		return visit;
	}

	/**
	 * Return the last visit in the list or null if the list is empty.
	 */
	public getLast(): Visit {
		let visit = null;
		if (this.visits.length > 0) {
			visit = this.visits[ this.visits.length-1 ];
		}
		return visit;
	}

	/**
	 * Returns the earliest start time out of all the visits in the list.
	 */
	public getEarliestStartTime() {
		let earliestStartTime: number = null;
		this.visits.forEach( visit => {
			let visitTime = visit.startTime.getTime();
			if (earliestStartTime == null || earliestStartTime > visitTime) {
				earliestStartTime = visitTime;
			}
		});
		return earliestStartTime == null ? null : new Date( earliestStartTime );
	}

	/**
	 * Returns the latest start time out of all the visits in the list.
	 */
	public getLatestStartTime() {
		let latestStartTime: Date = null;
		this.visits.forEach( visit => {
			let visitTime = visit.startTime.getTime();
			// console.log('checking '+visit.startTime);
			if (latestStartTime == null || latestStartTime.getTime() < visitTime) {
				// console.log( 'setting latestStartTime to '+visit.startTime);
				latestStartTime = visit.startTime;
			}
		});
		return latestStartTime;
	}

	public insertAtBeginning( visit: Visit ) {
		this.visits.splice( 0, 0, visit );
		this.removeExcessVisitsFromEndOfList();
		if (this.latestVisitLoaded == null || visit.startTime.getTime() > this.latestVisitLoaded.getTime()) {
			this.latestVisitLoaded = visit.startTime;
		}
	}

	/**
	 * Add visit to the end of the list.
	 * @param visit Visit to add.
	 */
	public push( visit: Visit, person?: Person, image?: string ) {
		this.visits.push( visit );
		// this.logStatus( 'after push' );
		this.removeExcessVisitsFromBeginningOfList();
		// this.logStatus( 'after remove' );
		if (this.latestVisitLoaded == null || visit.startTime.getTime() > this.latestVisitLoaded.getTime()) {
			this.latestVisitLoaded = visit.startTime;
		}
		if (person) {
			this.persons.set( visit.personId, person );
		}
		if (image) {
			this.images.set( visit.imageName, image );
		}
	}

	/** Return the start time of the latest visit ever loaded into the list so we know if we've thrown away some of the visits we loaded to preserve memory. */
	public getLatestVisitLoaded(): Date {
		return this.latestVisitLoaded;
	}

	/**
	 * Move a visit from one index to another index.
	 * @param fromIndex Index to move from.
	 * @param toIndex Index to move to (after visit has been removed).
	 */
	public moveVisit( fromIndex: number, toIndex: number ) {
		let bumpedVisit = this.visits.splice( fromIndex, 1 )[0];
		// this.logStatus( 'after splice 1' );
		this.visits.splice( toIndex, 0, bumpedVisit );
		// this.logStatus( 'after splice 2' );
	}

	/** If our list of visits is already maxed out, remove the newest one. */
	private removeExcessVisitsFromEndOfList() {
		while (this.visits.length > this.maxVisits) {
			// Throw away the last visit in the list because we only keep a certain number in memory
			console.log( 'removing visit '+(this.visits.length-1));
			this.removeVisitFromList( this.visits.length-1 );
		}
	}
	
	/** If our list of visits is already maxed out, remove the newest one. */
	private removeExcessVisitsFromBeginningOfList() {
		while (this.visits.length > this.maxVisits) {
			// Throw away the last visit in the list because we only keep a certain number in memory
			console.log( 'removing visit 0');
			this.removeVisitFromList( 0 );
		}
	}
	
	public logStatus( message ): void {
		let status = message +'\nvisits.length='+this.visits.length;
		for (let i=0; i<this.visits.length; i++) {
			status += '\nvisit['+i+'] start='+this.visits[i].startTime.toLocaleString('en-US', { timeZone: 'UTC' } );
		}
		console.log( status );
	}

	public clear() {
		this.visits = [];
		this.persons = new Map<string,Person>();
		this.images = new Map<string,string>();
		this.latestVisitLoaded = null;
	}

	private removeVisitFromList( visitIndex: number ) {
		if (visitIndex >= 0 && this.visits.length > visitIndex) {
			let deletedVisit = this.visits[visitIndex];
			// Remove the visit from the array
			this.visits.splice( visitIndex, 1 );
			if (deletedVisit && deletedVisit.personId) {
				// If no other visits use the deleted visit's person ID, remove it also
				let found = false;
				for( let i=0; i<this.visits.length; i++) {
					if (this.visits[i].personId == deletedVisit.personId) {
						found = true;
						break;
					}
				}
				if (!found) {
					this.persons.delete( deletedVisit.personId );
				}
			}
			if (deletedVisit && deletedVisit.imageName) {
				// If no other visits use the deleted visit's image name, remove it also
				let found = false;
				for( let i=0; i<this.visits.length; i++) {
					if (this.visits[i].imageName == deletedVisit.imageName) {
						found = true;
						break;
					}
				}
				if (!found) {
					this.images.delete( deletedVisit.imageName );
				}
			}
		} else {
			throw new Error( 'Invalid visit index ' + visitIndex );
		}
	}

	/**
	 * Returns the index of the given visit or -1 if not found.
	 * @param visit Vist to search for.
	 */
	public getVisitIndex( visit: Visit ): number {
		// console.log( 'getVisitIndex: '+JSON.stringify( visit, null, 2 ) );
		let index = -1;
		if (visit == null) {
			throw new Error( 'Invalid visit parameter.' );
		}
		for (let i=this.visits.length-1; i>=0; i--) {
			let aVisit = this.visits[i];
			// console.log( 'getVisitIndex check: '+JSON.stringify( aVisit, null, 2 ) );
			if (aVisit.personId === visit.personId && aVisit.startTime.getTime() === visit.startTime.getTime()) {
				console.log( 'found visit index '+i);
				index = i;
				break;
			}
		}
		return index;
	}

	/** Get the person information for the given visit. */
	public getPersonForVisit( visit: Visit ) {
		return this.persons.get( visit.personId );
	}

	/**
	 * Get person from the cache.  If not found in the cache, load person from table and save in cache.
	 * If not found in table create a new Person object and save in cache.
	 * @param personId ID of person to get.
	 */
	public getPerson( personId: string ): Promise<Person> {
		return new Promise<Person>( (resolve, reject ) => {
			let person = this.persons.get( personId );
			if (person) {
				// console.log( 'getPerson already had '+personId );
				resolve( person );
			} else {
				let collectionId = this.cacheService.currentCompany.getGuestFaceCollectionId( this.cacheService.currentSite.siteId );
				this.personTable.get( new Person( this.cacheService.currentCompany.companyId, collectionId, personId ) )
				.then( person => {
					if (!person) {
						person = new Person( this.cacheService.currentCompany.companyId, collectionId, personId );
					}
					this.persons.set( personId, person );
					resolve( person );
				})
				.catch( err => reject( err ) );
			}
		})
	}

	/**
	 * Get image from the cache, if not found load it into the cache from S3.
	 * @param imageName Image name.
	 */
	public getImage( imageName: string ): Promise<string> {
		return new Promise<string>( (resolve, reject ) => {
			let dataUri = this.images.get( imageName );
			if (dataUri) {
				// console.log( 'getImage already had '+imageName );
				resolve( dataUri );
			} else {
				this.s3Service.getImage( Global.visitPhotoBucketName, imageName )
				.then( dataUri => {
					this.images.set( imageName, dataUri );
					resolve( dataUri );
				})
				.catch( err => reject( err ) );
			}
		})
	}

	public hasThrownAwayLaterVisits() {
		// console.log( 'lvl='+this.latestVisitLoaded+', lst='+this.getLatestStartTime());
		let latestVisitListStartTime = this.getLatestStartTime();
		return this.latestVisitLoaded != null 
			&& latestVisitListStartTime != null		
			&& this.latestVisitLoaded.getTime() > latestVisitListStartTime.getTime();
	}

}
