// import * as AWS from "aws-sdk/global";
import * as SNS from "aws-sdk/clients/sns";
import { Global } from "./Global";
import { AWSRequestor } from './AWSRequestor';

export class TopicSubscription {
	constructor(
		public SubscriptionArn: string,
		public Owner: string,
		public Protocol: string,
		public Endpoint: string,
		public TopicArn : string
	) {}
}

export class Notifier {

	public static EMAIL = 'email';
	public static SMS = 'sms';

	/**
	 * Sends the given message to the given SNS topic.
	 * @param message Message to send.
	 * @param topicArn ARN of SNS topic where message will be sent.
	 * @returns Message ID if successful else null (on error promise resolves null, does not reject).
	 */
	notify( message: string, topicArn: string ): Promise<string> {
		return new Promise<string>( (resolve,reject) => {
			var params = {
				Message: message,
				TopicArn: topicArn,
	/*
				Message: 'STRING_VALUE', // required
				MessageAttributes: {
				'<String>': {
					DataType: 'STRING_VALUE', // required
					BinaryValue: new Buffer('...') || 'STRING_VALUE', // Strings will be Base-64 encoded on your behalf
					StringValue: 'STRING_VALUE'
				},
				// '<String>': ...
				},
				MessageStructure: 'STRING_VALUE',
				PhoneNumber: 'STRING_VALUE',
				Subject: 'STRING_VALUE',
				TargetArn: 'STRING_VALUE',
				TopicArn: 'STRING_VALUE'
	*/
			};
			if (topicArn) {
				new SNS().publish(params, (error, data) => {
					if (error) {
						Global.logError('Error sending notification '+message+' to topic '+topicArn, error );
						resolve( null );
					} else {
						Global.log('Sent notification '+message+' to topic '+topicArn+', response: '+JSON.stringify(data,null,2));
						resolve( data.MessageId );
					}
				});
			} else {
				Global.log( 'Notify called with no topicArn value.  Ignoring call.' );
				resolve( null );
			}
		});
	}

	addEmailSubscription( email: string, topicArn: string ): Promise<void> {
		return this.addSubscription( Notifier.EMAIL, email, topicArn );
	}

	addSmsSubscription( phoneNumber: string, topicArn: string ): Promise<void> {
		return this.addSubscription( Notifier.SMS, Global.removeEverythingButDigitsFromString( phoneNumber ), topicArn );
	}
	
	addSubscription( protocol: string, endpoint: string, topicArn: string ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			let params = {
				Protocol: protocol,
				TopicArn: topicArn,
				Endpoint: endpoint
			};
			new AWSRequestor().send( new SNS().subscribe( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
		});
	}

	deleteSubscription( subscriptionArn: string ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			let params = {
				SubscriptionArn: subscriptionArn
			};
			new AWSRequestor().send( new SNS().unsubscribe( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
		});
	}

	listSubscriptionsByTopic( topicArn: string ): Promise<TopicSubscription[]> {
		let params: any = {
			TopicArn: topicArn
		};
		let subscriptions: TopicSubscription[] = [];
		return this.listSubscriptionsByTopicRecursive( params, subscriptions )
	}

	listSubscriptionsByTopicRecursive( params: any, subscriptions: TopicSubscription[] ): Promise<TopicSubscription[]> {
		return new Promise<TopicSubscription[]>( (resolve,reject) => {
			new AWSRequestor().send( new SNS().listSubscriptionsByTopic( params ) )
			.then( data => {
				console.log('listSubscriptionsByTopic data='+JSON.stringify(data,null,2));
				if (data.Subscriptions) {
					data.Subscriptions.forEach( sub => {
						subscriptions.push( new TopicSubscription( sub.SubscriptionArn, sub.Owner, sub.Protocol, sub.Endpoint, sub.TopicArn ) );
					});
				}
				if (data.NextToken) {
					params.NextToken = data.NextToken;
					this.listSubscriptionsByTopicRecursive( params, subscriptions )
					.then( subscriptions => resolve( subscriptions ) )
					.catch( err => reject( err ) );
				} else {
					resolve( subscriptions );
				}
			})
			.catch( err => reject( err ) );
		});
	}

	indexOfEmailSubscriptionInList( email: string, subscriptions: TopicSubscription[] ): number {
		if (email) {
			email = email.toLowerCase();
		}
		let found = -1;
		if (subscriptions) {
			for (let i=0; i<subscriptions.length; i++) {
				if (subscriptions[i].Protocol == Notifier.EMAIL && subscriptions[i].Endpoint.toLowerCase() == email) {
					found = i;
					break;
				}
			}
		}
		return found;
	}

	indexOfSmsSubscriptionInList( phoneNumber: string, subscriptions: TopicSubscription[] ): number {
		if (phoneNumber) {
			phoneNumber = Global.removeEverythingButDigitsFromString( phoneNumber );
		}
		let found = -1;
		if (subscriptions) {
			for (let i=0; i<subscriptions.length; i++) {
				if (subscriptions[i].Protocol == Notifier.SMS && Global.removeEverythingButDigitsFromString( subscriptions[i].Endpoint ) == phoneNumber) {
					found = i;
					break;
				}
			}
		}
		return found;
	}

	indexOfSubscriptionInList( protocol: string, endpoint: string, subscriptions: TopicSubscription[] ): number {
		let found = -1;
		if (subscriptions) {
			for (let i=0; i<subscriptions.length; i++) {
				if (subscriptions[i].Protocol == protocol && subscriptions[i].Endpoint == endpoint) {
					found = i;
					break;
				}
			}
		}
		return found;
	}

	/**
	 * Delete a topic and all of its subscriptions.
	 */
	deleteTopic( topicArn: string ): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			let params = {
				TopicArn: topicArn
			};
			new AWSRequestor().send( new SNS().deleteTopic( params ) )
			.then( data => resolve() )
			.catch( err => {
				Global.log( 'Error deleting topic '+topicArn+': '+err);
				reject( err );
			});
		});
	}

	/**
	 * Create a topic with the given name.
	 */
	createTopic( name: string ): Promise<string> {
		return new Promise<string>( (resolve,reject) => {
			let params = {
				Name: name
			};
			new AWSRequestor().send( new SNS().createTopic( params ) )
			.then( data => resolve( data.TopicArn ) )
			.catch( err => reject( err ) );
		});
	}

}


