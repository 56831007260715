import { Global } from './Global';
import { SQSService } from "./sqs.service";
import { VisitQueueTable, VisitQueue } from './VisitQueueTable';
import { Visit } from "./Visit";

/**
 * Manages a visit notification queue which enables dashboards to receive instant notifications of new visits.
 * When a dashboard is opened we create a queue and register it.  When a new visit starts, a message is sent
 * to all registered queues for that company and site so that the dashboards can update instantly.
 */
export class VisitNotificationQueue {

	/** Visit queue we created to get notifications. */
	private visitQueue: VisitQueue;

	/** True if we are currently checking for notifications. */
	private checkingForNotifications = false;
		
	private sqsService: SQSService = new SQSService();

	private visitQueueTable: VisitQueueTable = new VisitQueueTable();
	
	constructor() {}

	public isActive() {
		return this.visitQueue != null;
	}

	public setQueueUrl( companyId: number, siteId: number, queueUrl: string ) {
		this.visitQueue = new VisitQueue( companyId, siteId, queueUrl, new Date() );
	}

	public createQueue( companyId: number, siteId: number ): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			let queueName = 'bookcliffsoftware-visit-'+companyId + '-' + siteId+'-'+new Date().toISOString();
			queueName = queueName.replace( /\:/g, '-' );
			queueName = queueName.replace( /\./g, '-' );
			// console.log( 'creating queue '+queueName );
			let startTime = Date.now();
			this.sqsService.createQueue( queueName )
			.then( newQueueUrl => {
				// console.log( 'Created queue '+newQueueUrl + ' in ' + (Date.now()-startTime) + 'ms.' );
				// Register new queue so when new visit starts we can send a message to the queue
				this.visitQueue = new VisitQueue( companyId, siteId, newQueueUrl, new Date() );
				this.visitQueueTable.put( this.visitQueue )
				.then( () => {
					resolve( newQueueUrl );
				})
				.catch( err => {
					this.deleteQueue();
					err.message = 'Error registering visit notification queue ' + newQueueUrl + '. ' + err.message;
					reject( err );
				});
			})
			.catch( err => {
				err.message = 'Error creating queue ' + queueName + '. ' + err.message;
				reject( err );
			});
		});
	}

	public deleteQueue(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			if (!this.visitQueue) {
				resolve();
			} else {
				// console.log( 'deleting queue '+this.visitNotificationQueueUrl );
				// Set URL member to null so we know to stop checking for new visits
				let tempVisitQueue = this.visitQueue;
				this.visitQueue = null;

				// Un-register queue so when new visit starts we don't send a message to the queue
				this.visitQueueTable.delete( tempVisitQueue )
				.then( () => {

					// Delete the queue, it will never be used again
					// let startTime = Date.now();
					this.sqsService.deleteQueue( tempVisitQueue.queueUrl )
					.then( () => {
						// console.log( 'Deleted queue in ' + (Date.now()-startTime) + 'ms.' );
						resolve();
					})
					.catch( err => {
						err.message = 'Error deleting visit notification queue. ' + err.message;
						reject( err );
					});

				})
				.catch( err => {
					err.message = 'Error un-registering visit notification queue. ' + err.message;
					reject( err );
				});
			}
		});		
	}

	/** Returns true if we are currently checking the queue for notifications. */
	public isCheckingForNotifications(): boolean {
		return this.checkingForNotifications;
	}

	/**
	 * Check for visit notifications messages in the queue and return the message if found or null if none found.
	 */
	public checkForNotifications(): Promise<any> {
		return new Promise( (resolve, reject) => {
			if (!this.checkingForNotifications) {
				console.log( 'Checking for new visits...')
				// let startTime = Date.now();
				this.checkingForNotifications = true;
				// If it's been 15 minutes since we last recorded that we are actively using the queue, update active time
				if (Date.now() - this.visitQueue.lastActiveTime.getTime() > (15 * 60 * 1000) ) {
					this.visitQueue.lastActiveTime = new Date();
					this.visitQueueTable.put( this.visitQueue );
				}
				this.sqsService.receiveMessage( this.visitQueue.queueUrl )
				.then( results => {
					this.checkingForNotifications = false;
					// Global.log( 'New visit check took ' + (Date.now() - startTime) + 'ms. and returned: ' + results.Messages.length + ' messages.' );
					if (results.Messages.length > 0) {
						console.log( 'Found new visits.');
						// startTime = Date.now();
						this.sqsService.deleteMessage( this.visitQueue.queueUrl, results.Messages[0].ReceiptHandle )
						.then( () => {
							// Global.log( 'deleteMessage took ' + (Date.now() - startTime) + 'ms.' );
						})
						.catch( err => {
							err.message = 'Error deleting queue message. ' + err.message;
							reject( err );
						})
						resolve( results.Messages[0] );
					} else {
						resolve( null );
					}
				})
				.catch( err => {
					this.checkingForNotifications = false;
					err.message = 'Error receiving queue message. ' + err.message;
					if (err.code === SQSService.QUEUE_DOES_NOT_EXIST_ERROR_CODE) {
						// The queue may have been deleted due to lack of activity while the device using it
						// was sleeping but since we are obviously still checking it let's recreate it
						// and make sure there is no record in the queue table for the old queue name
						Global.log('Tried to receive message from deleted queue, create a new queue.');
						this.visitQueueTable.delete( this.visitQueue );
						this.createQueue( this.visitQueue.companyId, this.visitQueue.siteId )
						.then( newQueueUrl => {
							Global.log('Created queue: ' + newQueueUrl );
							// We can assume there are no messages in our newly created queue so go ahead
							// and resolve the promise so the app will call checkForNotifications again.
							resolve( null );
						})
						.catch( err2 => {
							Global.log( 'Error recreating queue after discovering it was missing. ' + err2.code + ': ' + err2.message );
							reject( err2 );
						})
					} else {
						reject( err );
					}
				});
			} else {
				console.log( 'NOT checking for new visits because we are already checking.')
				resolve( null );
			}
		});
	}

	/**
	 * Called by lambda function when a new visit starts to send messages to all the queues for the
	 * company and site of the visit so that the dashboards will update immediately.
	 * @param visit New visit.
	 */
	public static sendNotification( visit: Visit ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			let startTime = Date.now();
			new VisitQueueTable().getActiveQueuesForSite( visit.companyId, visit.siteId )
			.then( queues => {
				let promises: Promise<void>[] = [];
				queues.forEach( queue => {
					if ((!queue.lastActiveTime) || Date.now() - queue.lastActiveTime.getTime() > (20 * 60 * 1000) ) {
						// Queue hasn't been accessed in over 20 minutes, assume it is no longer in use and delete it
						let q = new VisitNotificationQueue();
						q.setQueueUrl( queue.companyId, queue.siteId, queue.queueUrl );
						Global.log( 'Deleting inactive queue for company '+queue.companyId+' site '+queue.siteId+' URL '+queue.queueUrl );
						promises.push( q.deleteQueue() );
					} else {
						// Queue is still active, send the notification
						promises.push( VisitNotificationQueue.sendNotificationToQueue( visit, queue.queueUrl ) );
					}
				});
				Promise.all( promises )
				.then( results => {
					console.log( 'Sent messages to ' + promises.length + ' queue(s) in ' + (Date.now()-startTime) + 'ms.' );
					resolve();
				})
				.catch( err => {
					err.message = 'Error sending messages to visit notification queue. ' + err.message;
					reject( err );
				});
			})
			.catch( err => {
				err.message = 'Error un-registering visit notification queue. ' + err.message;
				reject( err );
			});



		});		
	}

	private static sendNotificationToQueue( visit: Visit, queueUrl: string ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			let startTime = Date.now();
			new SQSService().sendMessage( JSON.stringify( visit.toDataItem(), null, 2 ), queueUrl )
			.then( () => {
				console.log( 'Sent message to visit queue in ' + (Date.now()-startTime) + 'ms.' );
				resolve();
			})
			.catch( err => {
				err.message = 'Error sending message to visit notification queue. ' + err.message;
				reject( err );
			})
		});
	}

}