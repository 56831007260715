import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../../service/user-registration.service";
import { CacheService } from "../../service/cache.service"
import { Global } from "../../service/Global"
import { RequestInfoCmd } from "../../command/RequestInfoCmd"
import { Product } from "../../service/Product"

/** Page where user enters new account data. */
@Component({
    selector: 'request-info',
    templateUrl: './request-info.html',
	styleUrls: ['./request-info.css'],
})
export class RequestInfoComponent implements AfterViewInit {
    name: string;
    email: string;
	phone: string;
	errorMessage: string;

	@ViewChild('focus') private focusRef: ElementRef; private focusElement: HTMLInputElement;
		
    constructor(
		private router: Router,
		private cache: CacheService,
	) {}

    ngAfterViewInit() {
		this.focusElement = this.focusRef.nativeElement;
		setTimeout( () => this.focusElement.focus(), 1000 );
	}

    onContactMe() {
		this.errorMessage = null;
		if ((this.email == null || this.email.trim().length == 0) && (this.phone == null || this.phone.trim().length == 0)) {
			this.errorMessage = 'Please enter either the email address or phone number you would like us to use.'
		} else {
			new RequestInfoCmd().do( this.name, this.email, this.phone, Product.ROOM_GENIE )
			.then( messageId => {
				if (messageId) {
					this.cache.reportRoomGenieInfoRequestConversionToAdWords();
					this.cache.sendEventToAnalytics( 'RequestInfo', 'Room Genie', 'submit' );
					this.cache.confirmComponent.showModalDialog( 'Your request has been sent and we will contact you soon.', 'Request Sent', false )
					.then( confirmed => {
						this.router.navigate(['/home/room-genie-intro']);
					})
					.catch( error => {
						Global.logError( 'Error displaying confirmation dialog.', error );
						this.errorMessage = 'Sorry, there was a problem sending your request.  Please try again.';
					});
				} else {
					this.errorMessage = 'Sorry, there was a problem sending your request.  Please try again.';
				}
			});
		}
    }

}
