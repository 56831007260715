import { Component, OnInit, AfterViewInit } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { Router } from "@angular/router";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";

@Component({
    selector: 'product-subscriptions',
    template: '<product-subscribe></product-subscribe>'
})
export class ProductSubscriptionsComponent implements OnInit, AfterViewInit {

    constructor(
		private router: Router, 
		private userService: UserLoginService, 
		// private cognitoUtil: CognitoUtil,
		private cache: CacheService
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( null );
	}

	ngAfterViewInit() {
		// Set page title
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-cart-plus fa-fw"></i> Product Subscriptions' );
	}

}
