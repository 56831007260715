import { Command } from "./Command";
import { Global } from "../service/Global";

export class RequestInfoCmd extends Command {

	/**
	 * Send notification that user is requesting more information.
	 * @param companyId Company ID
	 * @param propertyId Property ID
	 */
	do( name: string, email: string, phone: string, productCode: string ): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			super.send( this.constructor.name, null, { "name": name, "email": email, "phone": phone, "productCode": productCode } )
			.then( results => {
				resolve( results.data );
			}).catch( err => reject( err ) );
		});
	}

}
