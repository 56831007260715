import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';
/**
 * Record of every date that the service is used for a room number.
 */
export class RoomUsage extends TableDocumentObject {

	/**
	 * Record of the service being used by a specific room number on a specific date.
	 * @param companyId Unique ID of company (partition key)
	 * @param propertyId Property where the device was used.
	 * @param roomNumber Room number that used the service.
	 * @param useDate Date device was used. (sort key)
	 * @param voiceUses Total number of times the room number accessed the service from a voice-activated device.
	 * @param webUses Total number of times the room number was used on the web portal (reservation, activity sign up, etc.)
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public roomNumber: string = null,
		public useDate: Date = new Date(),
		public voiceUses: number = 0,
		public webUses: number = 0,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): RoomUsage {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		// Add multi-field keys
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		item['useDate_roomNumber'] = Global.getUTCDateString( this.useDate ) + '_' + this.roomNumber;
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		item['useDate'] = Global.getUTCDateString( this.useDate );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			'companyId_propertyId': this.companyId + '_' + this.propertyId,
			'useDate_roomNumber': Global.getUTCDateString( this.useDate ) + '_' + this.roomNumber,
        }
    }

}
