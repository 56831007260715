import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import { CameraSettingsComponent } from "../camera-settings/camera-settings.component";
import { MotionDetectorComponent } from "../motion-detector/motion-detector.component";
import { CompanyDetailComponent } from "../company-detail/company-detail.component";
import { CopyPropertyComponent } from "../copy-property/copy-property.component";
import { Company } from "../../service/Company";
import { CompanyTable } from "../../service/CompanyTable";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { CognitoUtil } from "../../service/cognito.service";
import { Product } from "../../service/Product";
import { BillCustomersCmd } from "../../command/BillCustomersCmd";
import { ConvertDatabaseCmd } from "../../command/ConvertDatabaseCmd";
import { GenerateTestRoomUsageDataCmd } from "../../command/GenerateTestRoomUsageDataCmd";

/**
 * Displays title bar and navigation menu that only shows products the user can use.
 */
@Component({
	selector: 'title-nav',
	templateUrl: './title-nav.html',
	styleUrls: ['./title-nav.css'],
})
export class TitleNavComponent implements OnInit, OnDestroy {
	pageTitle = '';

	private roomGenieProductCode = Product.ROOM_GENIE;
	
	private testInsertedMenuItem = '<li><a [routerLink]="[\'/securehome/product-subscriptions\']"><i class="fa fa-comment fa-fw"></i> TEST Product Subscriptions</a></li>';
	
	@ViewChild(CameraSettingsComponent) private cameraSettingsComponent: CameraSettingsComponent;
	
	@ViewChild(MotionDetectorComponent) private motionDetectorComponent: MotionDetectorComponent;
	
	@ViewChild(CompanyDetailComponent) private companyDetailComponent: CompanyDetailComponent;
	
	@ViewChild(CopyPropertyComponent) private copyPropertyComponent: CopyPropertyComponent;

	constructor(
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private cache: CacheService,
		private companyTable: CompanyTable,
        private userService: UserLoginService,
	)
	{}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.motionDetectorComponent.initialize() );
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.motionDetectorComponent.stopDetectingMotion();
	}

	setPageTitle( title: string ) {
		this.pageTitle = title;
		this.changeDetectorRef.detectChanges();
	}
	
	editCameraSettings() {
		this.cameraSettingsComponent.showModalDialog()
		.then( saved => {
			// console.log( 'Setting dialog closed with '+saved);
			if (saved) {
				let cameraDevice = this.cameraSettingsComponent.getSelectedDeviceId();
				if (cameraDevice == 'none') {
					// console.log('stopping motion detection');
					this.motionDetectorComponent.stopDetectingMotion();
					// this.useDeviceCamera.style.color = 'black';
				} else {
					// if (this.motionDetectorComponent.isDetectingMotion()) {
						// console.log('switching cameras');
						// We are switching from one camera to another
						this.motionDetectorComponent.stopDetectingMotion();
					// }
					// Get camera index
					// console.log( 'Using camera: '+cameraDevice);
					let cameraIndex = this.cameraSettingsComponent.getCameraIndex();
					this.motionDetectorComponent.imageCaptureComponent.setCurrentCameraIndex( cameraIndex );
					this.motionDetectorComponent.setCameraId( this.cameraSettingsComponent.getCameraId() );
					//this.motionDetectorComponent.setIpCameraSnapshotUri( 'http://10.0.1.9:80/mjpeg/snap.cgi?chn=0' );
					// this.motionDetectorComponent.setIpCameraSnapshotUri( 'http://10.0.1.21/onvif-http/snapshot?Profile_1' );
					this.motionDetectorComponent.startDetectingMotion();
					// let coolGreenColor = '#3faba4';
					// this.useDeviceCamera.style.color = coolGreenColor;
				}
				if (this.cameraSettingsComponent.testFaceDetection) {
					if (cameraDevice != 'none' && !this.motionDetectorComponent.isTestingFaceDetection()) {
						this.motionDetectorComponent.startTestingFaceDetection();
					}
				} else {
					if (this.motionDetectorComponent.isTestingFaceDetection()) {
						this.motionDetectorComponent.stopTestingFaceDetection();
					}
				}
		}
		})
		.catch( error => {
			Global.logError( 'Error in camera settings dialog. ', error );
			alert( 'Problem editing camera settings, please try again.' );
		});
	}

	editCompany() {
		this.companyTable.get( this.cache.currentCompany )
		.then( company => {
			this.companyDetailComponent.showModalDialog( company )
			.then( company => {
				//console.log( 'Company dialog returned: '+JSON.stringify( company ) );
				if (company) {
					// User saved changes, update the company in the cache
					this.cache.currentCompany = company;
				}
			})
		})
		.catch( error => {
			Global.logError( 'Error editing company.', error );
			alert('Problem editing company.  Please try again.');
		})
	}

	copyProperty() {
		try {
			this.copyPropertyComponent.showModalDialog();
		} catch( error ) {
			Global.logError( 'Error copying property.', error );
			alert('Problem copying property.  Please try again.');
		}
	}

	/**
	 * Calls my Subscription API to record the subscription and get payment details..
	 */
	runBilling() {
		Global.log('Billing started');
		let startTime = Date.now();
		new BillCustomersCmd().do()
		.then( () => {
			Global.log( 'Billing complete in ' + (Date.now()-startTime) + 'ms.' );
		}).catch( err => {
			Global.logError( 'Billing failed in ' + (Date.now()-startTime) + 'ms.: ', err );
		});
	}

	/**
	 * Calls my Subscription API to record the subscription and get payment details..
	 */
	convertDatabase() {
		Global.log('Database conversion started');
		let startTime = Date.now();
		new ConvertDatabaseCmd().do()
		.then( () => {
			Global.log( 'Database conversion complete in ' + (Date.now()-startTime) + 'ms.' );
		}).catch( err => {
			Global.logError( 'Database conversion failed in ' + (Date.now()-startTime) + 'ms.: ', err );
		});
	}

	public hasPermission( permission: string ) {
		return this.cache.hasPermission( permission );
	}

	public hasProductPermission( productCodes: string[], permission: string ): boolean {
		let hasPermission = this.cache.hasProductPermission( productCodes, permission );
		// console.log('hasProductPermission( '+productCodes+', '+permission+' ) returns '+hasPermission);
		return hasPermission;
	}

	generateTestRoomUsageData() {
		new GenerateTestRoomUsageDataCmd().do( this.cache.currentCompany.companyId, this.cache.properties[0].propertyId );
	}

}
