import { Global } from "../service/Global";
import { Command } from "./Command";
import { Company } from "../service/Company";

export class UnsubscribeCmd extends Command {

	/**
	 * Unsubscribe from a product so that once any charges currently owed are paid there will be no more charges and no access to the product.
	 * @param companyId ID of the company that is unsubscribing.
	 * @param productCode Product code of the product being unsubscribed.
	 * @param productName Name of the product being unsubscribed.
	 */
	do( companyId: number, productCode: string, productName: string ): Promise<Company> {
		return new Promise<Company>( (resolve, reject) => {
			var parameters = {
				"productCode": productCode,
				"productName": productName,
			};
			super.send( this.constructor.name, companyId, parameters )
			.then( results => {
				resolve( new Company().fromDataItem( results.data ) );
			})
			.catch( err => reject( err ) );
		});
	}
}
