import { Command } from "./Command";
import { Employee } from "../service/Employee";

export class GetEmployeesCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number ): Promise<Employee[]> {
		return new Promise<Employee[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, null )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: Employee[] = [];
				results.data.forEach( layout => rows.push( new Employee().fromDataItem( layout ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
