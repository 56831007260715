import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {DynamoDBService, Timeslot} from "../../service/ddb.service";
import {GoogleChartsService} from "../../service/google-charts.service";
import { Global } from '../../service/Global';

/*
Displays line charts showing average time in line and number of people that went thru line by day part.
*/

export class Stuff {
    public type: string;
    public date: string;
}

@Component({
    selector: 'timebydaypart',
    templateUrl: './timebydaypart.html',
    // styleUrls: ['./timebydaypart.css'],
})
export class TimeByDaypartChartComponent implements OnInit {

    public logdata: Array<Stuff> = [];
	private avgTimeLineChartData;
	private avgTimeLineChartOptions = {
		width: 800,
		// height: 200,
		curveType: 'function',
		legend: { position: 'top' },
		pointSize: 5,
		backgroundColor: '#f8f8f8',
		hAxis: {
			gridlines: {
				count: -1,
			}
		},
		vAxis: {
			minValue: 0,
			viewWindow: { min: 0 },
		},
	};

	private countLineChartData;
	private countLineChartOptions = {
		width: 800,
		// height: 200,
		curveType: 'function',
		legend: { position: 'top' },
		pointSize: 5,
		backgroundColor: '#f8f8f8',
		hAxis: {
			gridlines: {
				count: -1,
			}
		},
		vAxis: {
			minValue: 0,
			viewWindow: { min: 0 },
		},
	};
	

	
	constructor(public router: Router, public userService: UserLoginService, public ddb: DynamoDBService, public googleCharts: GoogleChartsService ) {
		// console.log( 'TimeByDaypartChartComponent constructor width='+window.innerWidth+', height='+window.innerHeight );
		// Set the line chart size to 90% of the window width
		this.avgTimeLineChartOptions.width = Math.round( window.innerWidth * .90 );
    }

    initialize() {
		this.ddb.getLineTimeChartData( 4 )
		.then(timeslots => {
			this.setupChartData( timeslots );
			this.drawAvgTimeGoogleLineChart();
			this.drawCountGoogleLineChart();
		}).catch( err => {
			// Failure here usually means user token has expired so return to login
			this.router.navigate(['/home/login']);
		});
	}
	
    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	onResizeAvgTimeLineChart(event) {
		console.log( 'onResizeAvgTimeLineChart width='+event.target.innerWidth+', height='+event.target.innerHeight );
		if (this.avgTimeLineChartData) {
			// console.log( 'onResizeAvgTimeLineChart redrawing' );
			this.avgTimeLineChartOptions.width = Math.round( event.target.innerWidth * .90 );
			this.drawAvgTimeGoogleLineChart();
		}
	}

	onResizeCountLineChart(event) {
		console.log( 'onResizeCountLineChart width='+event.target.innerWidth+', height='+event.target.innerHeight );
		if (this.countLineChartData) {
			// console.log( 'onResizeCountLineChart redrawing' );
			this.countLineChartOptions.width = Math.round( event.target.innerWidth * .90 );
			this.drawCountGoogleLineChart();
		}
	}

	setupChartData( timeslots: Timeslot[] ) {
		let valueCount = 0;
		let valueSum = 0;
		let lineCountSum = 0;
		this.avgTimeLineChartData = [];
		this.avgTimeLineChartData.push( ['Time of Day', '# of Seconds'] );
		this.countLineChartData = [];
		this.countLineChartData.push( ['Time of Day', '# of People Thru Line'] );
		timeslots.forEach(timeslot => {
			// chartData.labels.push( timeslot.time );
			// let value = Math.round( ((timeslot.seconds / timeslot.lineCount) * 10) / 60 ) / 10;
			let value = 0;
			if (timeslot.lineCount != 0) {
				value = Math.round( ((timeslot.seconds / timeslot.lineCount) * 10) ) / 10;
			}
			lineCountSum += timeslot.lineCount;
			valueCount++;
			valueSum += value;

			// Convert timeslot time string to Date object so line chart uses 'continuous' values
			let timeParts = timeslot.time.split(':');
			let hours = Number.parseInt( timeParts[0] );
			let minutes = Number.parseInt( timeParts[1].substr(0,2) );
			let ampm = timeParts[1].substr(2,1);
			if (ampm == 'p' && hours < 12) {
				hours += 12;
			}
			if (ampm == 'a' && hours == 12) {
				hours = 0;
			}
			let hoursString = hours.toString();
			if (hoursString.length == 1) {
				hoursString = '0'+hoursString;
			}
			let minutesString = minutes.toString();
			if (minutesString.length == 1) {
				minutesString = '0'+minutesString;
			}
			let timeOfDayString = '2000-01-01T'+hoursString+':'+minutesString+':00'
			let timeOfDay = new Date( timeOfDayString );
			
			// console.log( 'timeOfDayString='+timeOfDayString+', timeOfDay='+timeOfDay );
			this.avgTimeLineChartData.push( [ timeOfDay, value ] );
			this.countLineChartData.push( [ timeOfDay, timeslot.lineCount ] );
		});
	}

	drawAvgTimeGoogleLineChart() {
		this.googleCharts.loadLibraries()
		.then( () => {
			var wrapper = this.googleCharts.createChartWrapper({
				chartType: 'AreaChart',
				dataTable: this.avgTimeLineChartData,
				options: this.avgTimeLineChartOptions,
				containerId: 'avgTimeLineChart'
			});
			// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
			wrapper.draw();
		})
		.catch( err => {
			Global.logError('Error drawing average time line chart.', err );
			throw new Error( 'Error drawing average time line chart.  ' +  err );
		});
	}

	drawCountGoogleLineChart() {
		this.googleCharts.loadLibraries()
		.then( () => {
			var wrapper = this.googleCharts.createChartWrapper({
				chartType: 'AreaChart',
				dataTable: this.countLineChartData,
				options: this.countLineChartOptions,
				containerId: 'countLineChart'
			});
			// console.log('drawing the time guage '+this.guageChartOptions.width+'x'+this.guageChartOptions.height)
			wrapper.draw();
		})
		.catch( err => {
			Global.logError('Error drawing average time line chart.', err );
			throw new Error( 'Error drawing average time line chart.  ' +  err );
		});
	}

}

