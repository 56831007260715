import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";

/** Displays pres-sales information for Connect. */
@Component({
    selector: 'connect-intro',
    templateUrl: './connect-intro.html',
    styleUrls: ['./connect-intro.css'],
})
export class ConnectIntroComponent {

}
