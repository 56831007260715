import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { Item } from "../../service/Item";
import { InputTextComponent } from "../input-text/input-text.component";
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";

/**
 * Component for editing food or item menu category for a property.
 */
@Component({
    selector: 'item',
	templateUrl: './item.html',
	styleUrls: [ 'item.css' ]
})
export class ItemComponent extends EditorDetail<Item> implements OnInit, OnDestroy {

	@ViewChild('ItemModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	@ViewChild(InputTextComponent) public inputTextComponent: InputTextComponent;

	/** ID of the property where the restaurant, that this seating belongs to, is located. */
	public propertyId;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	/** Create a new row with default values. */
	createNewDefaultRow(): Item {
		return new Item();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	initializeDialog() {
	}

	/** Return a deep copy of the given object. */
	clone( row: Item ): Item {
		return new Item().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete the item: ' + this.originalRow.name + '?'
	}

	isValid(): boolean {
		this.errorMessage = null;
		if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name for this item';
			return false;
		}

		return true;
	}

	/** Add a synonym for the category. */
	insertSynonym() {
		this.inputTextComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. shuttle bus', true );
		this.handleChildInsert( new String(), this.editedRow.synonyms, this.inputTextComponent );
	}

	editSynonym( index: number ) {
		this.inputTextComponent.configure( 'Enter Synonym', 'Synonym:', '100', 'synonym ie. shuttle bus', true );
		this.handleChildEdit( index, this.editedRow.synonyms, this.inputTextComponent );
	}

}