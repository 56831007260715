import { Global } from "../service/Global";
import { Command } from "./Command";
import { Company } from "../service/Company";
import { Address } from "../service/Address";

export class SubscribeCmd extends Command {

	/**
	 * Subscribe to a product.  Payment method will be used during billing cycle.
	 * @param companyId ID of the company that is subscribing.
	 * @param productCode Product code of the product being subscribed.
	 * @param productName Name of the product being subscribed.
	 * @param billingDay Day of the month when customer should be billed.
	 * @param startDate Date subscription starts.
	 * @param endDate Date subscription ends.
	 * @param nextBillingDate Date of next billing.
	 * @param amazonBillingAgreementId Amazon billing agreement ID which lets us charge the customer.
	 * @param accessToken Access token obtained from Amazon log in that lets us retrieve shipping address.
	 * @param orderReferenceId Order reference ID obtained from selecting a shipping address.
	 * @param shippingAddress Address to ship products to.
	 * @param beyondToken Token returned from BeyondPay auth or sale request used for recurring billing.
	 * @param beyondGatewayTransId BeyondPay transaction ID used to capture an authorized sale.
	 */
	do( companyId: number, productCode: string, productName: string,  billingDay: number, startDate: Date, endDate: Date, nextBillingDate: Date, amazonBillingAgreementId: string, accessToken: string, orderReferenceId: string, shippingAddress: Address, beyondToken: string, beyondGatewayTransId: string ): Promise<Company> {
		return new Promise<Company>( (resolve, reject) => {
			var parameters = {
				"productCode": productCode,
				"productName": productName,
				"billingDay": billingDay.toString(),
				"startDate": startDate.toISOString(),
				"endDate": endDate ? endDate.toISOString() : null,
				"nextBillingDate": nextBillingDate.toISOString(),
				"amazonBillingAgreementId": amazonBillingAgreementId,
				"accessToken": accessToken,
				"orderReferenceId": orderReferenceId,
				"shippingAddress": (shippingAddress ? shippingAddress.toDataItem() : null),
				"beyondToken": beyondToken,
				"beyondGatewayTransId": beyondGatewayTransId,
			};
			// Global.log('SubscribeCmd.do companyId='+companyId+', parameters='+JSON.stringify(parameters));
			super.send( this.constructor.name, companyId, parameters )
			.then( results => resolve( new Company().fromDataItem( results.data ) ) )
			.catch( err => reject( err ) );
		});
	}
}
