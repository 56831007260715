import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/**
 * An entry in a volume discount table.
 * Used to calculate the billing amount from the number of units and a volume discount table.
 * Amount is calculated for each level in the discount table until all the units are used up
 * We don't calculate the price of all the units from whichever line in the discount table applies
 * to solve the problem of buying 1001 costs substantially less buying 1000.
 * For example, if the table has 100 at $2 and 999999 at $1 and the customer buys 150, then
 * the customer pays $2 for 100 and $1 for the remaining 50 for a total of $250.
 */
export class VolumePrice extends TableDocumentObject {
	
	/**
	 * @param quantity Number of units billed at this rate.
	 * @param priceCents Amount to bill in cents for each unit of usage.  Ie. Room Genie charges per room per month. 
	 */
	constructor(
		public quantity: number = 0,
		public priceCents: number = 0,
	) { 
		super(); 
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): VolumePrice {
		this.copyPropertiesFromObject( item );
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

}
