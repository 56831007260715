import { Command } from "./Command";
import { PropertyAdvertiser } from "../service/PropertyAdvertiser";

export class GetPropertyAdvertisersCmd extends Command {

	/**
	 * Returns a map of distinct room numbers and the number of times that room number was used keyed by date between the given start and end date.
	 * @param key Object with key values used to find the item.
	 * @returns Found object or null if not found.
	 */
	do( companyId: number, propertyId: number, adCategoryId: number ): Promise<PropertyAdvertiser[]> {
		return new Promise<PropertyAdvertiser[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId, "adCategoryId": adCategoryId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: PropertyAdvertiser[] = [];
				results.data.forEach( row => rows.push( new PropertyAdvertiser().fromDataItem( row ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
