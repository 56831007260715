import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { Callback, CognitoUtil } from "../../service/cognito.service";
import { Router } from "@angular/router";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { UserComponent } from "../user/user.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { UserCompany } from '../../service/UserCompany';
import { UserRegistrationService } from "../../service/user-registration.service";
import { GetUserCompaniesCmd } from "../../command/GetUserCompaniesCmd";
import { PutUserCompanyCmd } from "../../command/PutUserCompanyCmd";
import { DeleteUserCompanyCmd } from "../../command/DeleteUserCompanyCmd";
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";

@Component({
    selector: 'users',
    templateUrl: './users.html',
	styleUrls: ['./users.css'],
})
export class UsersComponent extends Editor<UserCompany> implements OnInit, AfterViewInit {

	@ViewChild(UserComponent) private detailComponent: UserComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public userRegistration: UserRegistrationService, 
		private router: Router, 
		private userService: UserLoginService, 
		private cognitoUtil: CognitoUtil,
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Users' );
	}

	initialize() {
		// Force user component to reload roles in case someone has added some since component was last used
		this.detailComponent.roles = null;
		this.loadRows();
	}

	/** Returns component used to edit the selected row. */
	getDetailComponent(): EditorDetail<UserCompany> {
		return this.detailComponent;
	}

	/** Returns promise of array of rows to edit. */
	getRowLoader(): Promise<UserCompany[]> {
		return new GetUserCompaniesCmd().do( this.cache.currentCompany.companyId );
	}
	
	/** Creates a new row to be edited. */
	createNewRowToEdit(): UserCompany {
		return new UserCompany( null, this.cache.currentCompany.companyId );
	}

	/** Called before new rows is saved. */
	prepareRowToInsert( newRow: UserCompany ) {
	}

	/** Detects changes in model so view is updated. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	getRowInserter( row: UserCompany ): Promise<UserCompany> {
		return new Promise<UserCompany>( (resolve,reject) => {

			// See if user being added already exists in this company
			let emailCount = 0;
			for (let i=0; i<this.rows.length; i++) {
				if (row.email && this.rows[i].email && row.email.toLowerCase() == this.rows[i].email.toLowerCase()) {
					emailCount++;
				}
			}
			if (emailCount > 1) {
				// User already exists in this company
				this.detailComponent.errorMessage = 'User with email address '+row.email+' already exists in this company.';
				resolve( row );
			} else {
				// Register a new user in Cognito
				this.userRegistration.registerUser( row.name, row.email, this.detailComponent.password )
				.then( result => {
					// Get userId from Congnito sub field
					row.userId = result.userSub;

					// Save new user record
					new PutUserCompanyCmd().do( row )
					.then( () => resolve( row ) )
					.catch( err => reject( err ) );
				})
				.catch( error => {
					if (error.code == 'UsernameExistsException') {
						this.cache.confirmComponent.showModalDialog( 'User with email address '+row.email+' already exists in another company.  Are you sure you want to add them to this company?', 'Confirm Multi-Company User' )
						.then( confirmed => {
							if (confirmed) {
								// Save the original user credentials
								let lvu = this.cache.get('lvu');
								let lvp = this.cache.get('lvp');
								// Verify new user credentials
								this.userService.verifyUserAndPassword( row.email, this.detailComponent.password )
								.then( session => {
									this.userService.getCognitoUserAttributes( session )
									.then( userAttributes => {
										// Get userId from Cognito sub field
										row.userId = userAttributes.userId;

										// Restore original user credentials
										this.userService.verifyUserAndPassword( lvu, lvp )
										.then( session => {
											// Save new user record
											// console.log( 'before PutUserCompanyCmd, currentUserId='+this.cache.currentUserId+', idToken='+JSON.stringify(this.cognitoUtil.getCurrentIdToken(),null,2));
											new PutUserCompanyCmd().do( row )
											.then( () => resolve( row ) )
											.catch( error => {
												this.detailComponent.errorMessage = 'There was a problem saving the new user.  Please try again.';
												resolve( row );
											});
										})
										.catch( error => {
											this.detailComponent.errorMessage = 'There was a problem setting the user credentials.  Please try again.';
											resolve( row );
										});
									})
									.catch( error => {
										this.detailComponent.errorMessage = 'There was a problem getting the user ID.  Please try again.';
										resolve( row );
									});
								})
								.catch( error => {
									this.detailComponent.errorMessage = 'There was a problem verifying the email address and password.  Please try again.';
									resolve( row );
								});
						} else {
								reject( new Error( 'New user not created, already exists in another company.' ) );
							}
						})
						.catch( error => {
							Global.logError( 'Error in confirm delete dialog.', error );
							this.errorMessage = error.message;
						});
					} else {
						reject( error );
					}
				});
			}
		})
	}

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	getRowUpdater( index: number, row: UserCompany ): Promise<UserCompany> {
		return new PutUserCompanyCmd().do( row );
	}

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	getRowDeleter( index: number, row: UserCompany ): Promise<void> {
		return new DeleteUserCompanyCmd().do( row );
	}

}
