import { TableHandler, TableDocumentObject } from './TableHandler'
import { UUIDService } from "./uuid.service";

/** Details about marketing events shown on Analytics graph. */
export class Event extends TableDocumentObject {

	/**
	 * @param companyId ID of event (partition key)
	 * @param siteId ID of site affected by event (partition key)
	 * @param eventId ID of event (sortKey)
	 * @param name Name of the event.
	 * @param eventDate Date of event.
	 */
	constructor(
		public companyId: number = null,
		public siteId: number = null,
		public eventId: string = null,
		public name: string = null,
		public eventDate: Date = new Date(),
		public affectsNew: boolean = true,
		public affectsReturning: boolean = true,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Event {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_siteId'] = this.companyId + '_' + this.siteId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_siteId": this.companyId + '_' + this.siteId,
			"eventId": this.eventId, 
        };
    }

}

/** Details about marketing events shown on Analytics graph. */
export class EventTable extends TableHandler<Event> {

	/** Local secondary index use to query visits ordered by startTime. */
	private eventDateIndex = 'companyId_siteId-eventDate-index';
	
		public constructor() {
		super( 'bookcliffsoftware-Event' );
	}
	
	public fromDataItem( item: any ): Event {
		return new Event().fromDataItem( item );
	}

	public getEventsForCompany( companyId: number ): Promise<Event[]> {
    	return new Promise<Event[]>( (resolve, reject) => {
			let filterExpression = "companyId = :companyId";
			let expressionAttributeValues = { ":companyId": companyId };
			this.scanAll( filterExpression, expressionAttributeValues )
			.then( events => resolve( events ) )
			.catch( error => reject( error ) );
		});
	}

	public getEventsForDateRange( companyId: number, siteId: number, startDate: Date, endDate: Date ): Promise<Event[]> {
    	return new Promise<Event[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId and eventDate BETWEEN :startDate AND :endDate";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
				":startDate": startDate.toISOString(),
				":endDate": endDate.toISOString(),
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.eventDateIndex )
			.then( events => resolve( events ) )
			.catch( error => reject( error ) );
		});
	}

	generateTestData( companyId: number, siteId: number ) {
		this.deleteAll( 'companyId_siteId = :companyId_siteId', { ':companyId_siteId': companyId + '_' + siteId } )
		.then( deleteCount => {
			let millisInOneDay = 24 * 60 * 60 * 1000;
			let uuidService = new UUIDService();
			let eventDate = new Date( Date.now() - (5 * millisInOneDay) );
			eventDate.setUTCHours( 0, 0, 0, 0 );
			this.put( new Event( companyId, siteId, uuidService.UUID(), 'Free hair ribbon day', eventDate, true, false ) );
			eventDate = new Date( eventDate.getTime() - (7 * millisInOneDay) );
			this.put( new Event( companyId, siteId, uuidService.UUID(), 'Free baseball day', eventDate, false, true ) );
			eventDate = new Date( eventDate.getTime() - (7 * millisInOneDay) );
			this.put( new Event( companyId, siteId, uuidService.UUID(), 'Free t-shirt day', eventDate, true, true ) );
		});
	}

}
