import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import * as https from 'https';
import { UserLoginService } from "../../../service/user-login.service";
import { CacheService } from "../../../service/cache.service";
import { Global } from '../../../service/Global';
import { Product } from '../../../service/Product';
import { SpinnerDialogComponent } from '../../spinner-dialog/spinner-dialog.component';

@Component({
    selector: 'login',
    templateUrl: './login.html'
})
export class LoginComponent implements OnInit {

	@ViewChild(SpinnerDialogComponent) private spinnerDialog: SpinnerDialogComponent;
	
    email: string;
    password: string;
	errorMessage: string;
	
	public static loginStartTime: Date;

    constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
        private userService: UserLoginService,
        private cache: CacheService
    ) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
        this.errorMessage = null;
        this.cache.currentUserId = null;
        this.cache.currentUserEmail = null;
        this.cache.currentUserTokenExpiration = null;
        this.cache.currentCompany = null;
		// See if user is already logged in, if so, then redirect to them
		this.userService.isAuthenticated( (message,loggedIn) => {
			// Global.log('loggedIn='+loggedIn);
			if (loggedIn) {
				this.cache.appComponent.goToDefaultSecureHomePage();
			}
			return false;
		});
   }

	onLogin() {
        if (this.email == null) {
            this.errorMessage = "Please enter your email address.";
            return;
        }
        if (this.password == null) {
            this.errorMessage = "Please enter your password.";
            return;
        }
        // Make sure the following line is commented out in production, it is NOT SECURE!!
        // console.log('Attempting to log in: ', this.email, '/', this.password );
		this.errorMessage = null;
		// LoginComponent.loginStartTime = new Date();		
		// this.errorMessage = 'Authenticating at '+LoginComponent.loginStartTime.toISOString();
		// Global.log('User clicked login.' );
		// setTimeout( () => {

		// Show spinner
		this.spinnerDialog.toggleDialog();
        this.userService.authenticate(this.email, this.password )
		.then( () => {
			// Hide spinner
			this.spinnerDialog.toggleDialog();
			if (this.cache.companies.length > 1) {
				// Select the company you want to log into
				this.router.navigate(['/signuphome/company-selector']);
			} else {
				this.cache.appComponent.goToDefaultSecureHomePage();
			}
        }).catch( err => {
			// Hide spinner
			this.spinnerDialog.toggleDialog();
            Global.logError("Authentication error.", err );
            this.errorMessage = 'Sorry, there was a problem logging in.  Please try again.'; // err.message;
            if (this.errorMessage === 'User is not confirmed.') {
                // console.log("redirecting");
                this.router.navigate(['/home/confirmRegistration', this.email]);
            } else if (this.errorMessage === 'User needs to set password.') {
                // console.log("redirecting to set new password");
                this.router.navigate(['/home/newPassword']);
            } else {
				this.errorMessage = this.errorMessage;
			}
        });
	}

}