import { Command } from "./Command";
import { Property } from "../service/Property";

export class CopyPropertyCmd extends Command {

	/**
	 * Copy the given property and all its related configuration data (not transaction data) to a new property ID
	 * @param fromCompanyId Company ID of the property to copy
	 * @param fromPropertyId ID of the property to copy
	 * @param toCompanyId ID of the company to which property is to be copied
	 * @param newPropertyName Name to use for new property, if null, use old property name + ' - Copy'.
	 */
	do( fromCompanyId: number, fromPropertyId: number, toCompanyId: number, newPropertyName: string ): Promise<Property> {
		return new Promise<Property>( (resolve, reject) => {
			super.send( this.constructor.name, fromCompanyId, { "fromPropertyId": fromPropertyId, "toCompanyId": toCompanyId, "newPropertyName": newPropertyName } )
			.then( results => resolve( new Property().fromDataItem( results.data ) ) )
			.catch( err => reject( err ) );
		});
	}

}
