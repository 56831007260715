import * as AWS from "aws-sdk/global";
import * as CloudWatch from "aws-sdk/clients/cloudwatchlogs";
import { AWSRequestor } from './AWSRequestor'

export class CloudWatchLogger {

	/** Sequence token returned by last call to putLogEvents to be passed to next call. */
	private nextSequenceToken: string = null;

	/** True if remote logging has been initialized which means the log group and log stream exist. */
	private remoteLoggingInitialized = false;

	/** True if remote logging is currently being initialized so we don't initialize again. */
	private remoteLoggingInitializing = false;
	
	/** List of events to be sent to the remote logging service. */
	private logEvents = [];

	/** True if we are currently sending log events to the remote logger. */
	private sendingToRemoteLog = false;

	constructor( private logGroupName: string, private logStreamName: string ) {
	}

	public getLogGroupName() {
		return this.logGroupName;
	}

	public getLogStreamName() {
		return this.logStreamName;
	}

	/**
	 * Checks to be sure remote logging is initialized then sends the given message to CloudWatch.
	 * @param message Message to send.
	 */
	public log( message: string ): Promise<void> {
		this.logEvents.push( { timestamp: Date.now(), message: message } );
		return new Promise<void>( (resolve, reject) => {
			if (this.remoteLoggingInitializing) {
				// Remote logger is currently being initialized, just add the message to the list
			} else if (!this.remoteLoggingInitialized) {
				// Remote logging has not been initialized, make sure the log group and log stream exist
				this.remoteLoggingInitializing = true;
				// this.initializeRemoteLogging()
				this.createLogGroupAndStream()
				.then( () => {
					this.remoteLoggingInitializing = false;
					this.remoteLoggingInitialized = true;
					this.sendAllMessagesToRemoteLog()
					.then( () => resolve() )
					.catch( err => reject( err ) );
				})
				.catch( err => { 
					this.remoteLoggingInitializing = false;
					this.remoteLoggingInitialized = true;
					reject( err ); 
				});
			} else {
				// Send message to remote log
				this.sendAllMessagesToRemoteLog()
				.then( () => resolve() )
				.catch( err => reject( err ) );
			}
		});
	}

	/**
	 * Sends a message to CloudWatch.  Creates log group and log stream if they don't exist.
	 * @param message Message to log.
	 */
	private sendAllMessagesToRemoteLog(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			if (!this.sendingToRemoteLog) {
				this.sendingToRemoteLog = true;
				// Make a local copy of the log events so other events can be added to the main list while we are sending
				let localLogEvents = this.logEvents;
				this.logEvents = [];
				// let startTime = Date.now();
				this.putLogEvents( localLogEvents, this.logStreamName, this.logGroupName, this.nextSequenceToken )
				.then( sequenceToken => {
					// console.log( 'Sent events to remote log in '+(Date.now()-startTime)+'ms.');
					this.nextSequenceToken = sequenceToken;
					this.sendingToRemoteLog = false;
					this.sendEventBacklog();
					resolve();
				})
				.catch( err => {
					// console.log('remote log err='+JSON.stringify( err, null, 2 ) );
					if (err.code === 'ResourceNotFoundException' && !this.remoteLoggingInitializing) {
						// Log group or stream may have been deleted, try re-initializing
						// this.initializeRemoteLogging()
						this.createLogGroupAndStream()
						.then( () => {
							// Log group and log stream exist now, try putting log events again
							this.putLogEvents( localLogEvents, this.logStreamName, this.logGroupName, this.nextSequenceToken )
							.then( sequenceToken => {
								this.nextSequenceToken = sequenceToken;
								this.sendingToRemoteLog = false;
								this.sendEventBacklog();
								resolve();
							})
							.catch( err => {
								this.sendingToRemoteLog = false;
								this.sendEventBacklog();
								reject( err );
							});
						})
						.catch( err => {
							this.sendingToRemoteLog = false;
							this.sendEventBacklog();
							reject( err );
						});
					} else {
						this.sendingToRemoteLog = false;
						this.sendEventBacklog();
						reject( err );
					}
				});
			} else {
				// We are currently sending events to the remote logger so do nothing and the
				// messages will be sent when the cnrrent send is complete.
				resolve();
			}
		});
	}

	/**
	 * Initialize remote logging by checking the existence of the log group and log stream and
	 * creating them if they don't exist.
	 */
	private createLogGroupAndStream(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			// See if we need to create the log group
			this.describeLogGroups( this.logGroupName )
			.then( data => {
				if (data.logGroups && data.logGroups.length > 0) {
					// Log group already exists, check for existence of log stream
					this.describeLogStreams( this.logStreamName, this.logGroupName )
					.then( data => {
						// console.log('describeLogStreams returned: '+JSON.stringify(data,null,2));
						if (data.logStreams && data.logStreams.length > 0) {
							// Log stream exists, we are done
							this.nextSequenceToken = data.logStreams[0].uploadSequenceToken;
							resolve();
						} else {
							// Log stream does not exist, create it
							this.createLogStream( this.logStreamName, this.logGroupName )
							.then( () => resolve() )
							.catch( err => reject( err ) );
						}
					})
					.catch( err => reject( err ) );
				} else {
					// Log group does not exist, create it and the log stream
					this.createLogGroup( this.logGroupName, 7 )
					.then( () => {
						// Create log stream
						this.createLogStream( this.logStreamName, this.logGroupName )
						.then( () => resolve() )
						.catch( err => reject( err ) );
					})
					.catch( err => reject( err ) );
				}
			})
			.catch( err => reject( err ) );
		});
	}

	/**
	 * Get information about the log groups whose names start with the given prefix.
	 * @param logGroupNamePrefix Log group name prefix.
	 */
	describeLogGroups( logGroupNamePrefix: string ): Promise<CloudWatch.DescribeLogGroupsResponse> {
		return new Promise<CloudWatch.DescribeLogGroupsResponse>( (resolve, reject) => {
			let params: CloudWatch.DescribeLogGroupsRequest = {
				logGroupNamePrefix: logGroupNamePrefix,
			  };
			new AWSRequestor().send( new CloudWatch().describeLogGroups( params ) )
			.then( data => resolve( data ) )
			.catch( err => { 
				err.message = 'Error describing log group. ' + err.message;
				reject( err ); 
			});
		})
	}

	/**
	 * Get information about the log streams whose names start with the given prefix.
	 * @param logGroupNamePrefix Log group name prefix.
	 */
	describeLogStreams( logStreamNamePrefix: string, logGroupName: string ): Promise<CloudWatch.DescribeLogStreamsResponse> {
		return new Promise<CloudWatch.DescribeLogStreamsResponse>( (resolve, reject) => {
			let params: CloudWatch.DescribeLogStreamsRequest = {
				logGroupName: logGroupName,
				logStreamNamePrefix: logStreamNamePrefix,
			  };
			new AWSRequestor().send( new CloudWatch().describeLogStreams( params ) )
			.then( data => resolve( data ) )
			.catch( err => { 
				err.message = 'Error describing log stream. ' + err.message;
				reject( err ); 
			});
		})
	}

	createLogGroup( logGroupName: string, retentionInDays: number ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			let params: CloudWatch.CreateLogGroupRequest = {
				logGroupName: logGroupName,
			  };
			new AWSRequestor().send( new CloudWatch().createLogGroup( params ) )
			.then( data => { 
				this.putRetentionPolicy( logGroupName, retentionInDays )
				.then( () => resolve() )
				.catch( err => reject( err ) );
			})
			.catch( err => {
				if (err.code == 'ResourceAlreadyExistsException') {
					// Ignore the 'already exists' error
					this.putRetentionPolicy( logGroupName, retentionInDays )
					.then( () => resolve() )
					.catch( err => reject( err ) );
				} else {
					err.message = 'Error creating log group: ' + err.message;
					reject( err ); 
				}
			});
		})
	}

	putRetentionPolicy( logGroupName: string, retentionInDays: number ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			let params: CloudWatch.PutRetentionPolicyRequest = {
				logGroupName: logGroupName,
				retentionInDays: retentionInDays
			  };
			new AWSRequestor().send( new CloudWatch().putRetentionPolicy( params ) )
			.then( data => resolve() )
			.catch( err => reject( err ) );
		})
	}

	createLogStream( logStreamName: string, logGroupName: string ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			let params: CloudWatch.CreateLogStreamRequest = {
				logGroupName: logGroupName,
				logStreamName: logStreamName
			};
			new AWSRequestor().send( new CloudWatch().createLogStream( params ) )
			.then( data => resolve() )
			.catch( err => {
				if (err.code == 'ResourceAlreadyExistsException') {
					// Ignore the 'already exists' error
					resolve();
				} else {
					err.message = 'Error creating log stream: ' + err.message;
					reject( err ); 
				}
			});
		})
	}

	private putLogEvents( logEvents: CloudWatch.InputLogEvent[], logStreamName: string, logGroupName: string, sequenceToken: string ): Promise<string> {
		return new Promise<string>( (resolve, reject) => {
			let params: CloudWatch.PutLogEventsRequest = {
				logEvents: logEvents,
				logGroupName: logGroupName,
				logStreamName: logStreamName,
			};
			if (sequenceToken) {
				params.sequenceToken = sequenceToken;
			}
			// We can't use AWSRequestor here, it can cause recursion since it calls Logger which would bring it back here
			// new CloudWatch().putLogEvents( params, (err: AWS.AWSError, data: CloudWatch.PutLogEventsResponse ) => {
			let request = new CloudWatch().putLogEvents( params );
			request.send( (err: AWS.AWSError, data: CloudWatch.PutLogEventsResponse ) => {
				if (err) {
					if (err.code === 'DataAlreadyAcceptedException') {
						// We already put this data on the log, ignore the error but update the sequenceToken
						// Stack: DataAlreadyAcceptedException: The given batch of log events has already been accepted. The next batch can be sent with sequenceToken: 49556355883619415453973615474902976018709125113130140546
						let searchString = ' sequenceToken: ';
						let tokenIndex = err.message.indexOf( searchString ) + searchString.length;
						let nextToken = err.message.substr( tokenIndex );
						// console.log( 'CloudWatch err.code='+err.code+', err.message='+err.message+', nextToken='+nextToken+'.')
						resolve( nextToken );
					} else if (err.code == 'InvalidSequenceTokenException' ) {
						// We used a bad sequence token, resend the data with the sequence token in the message
						// Stack: InvalidSequenceTokenException: The given sequenceToken is invalid. The next expected sequenceToken is: 49556355883619415453973615294685985237216996688226435970
						let searchString = ' expected sequenceToken is: ';
						let message = err.message;
						let tokenIndex = message.indexOf( searchString ) + searchString.length;
						let nextToken = message.substr( tokenIndex );
						if (nextToken == 'null') {
							nextToken = null;
						}
						// console.log( 'CloudWatch err.code='+err.code+', err.message='+err.message+', params.sequenceToken='+params.sequenceToken+'.')
						params.sequenceToken = nextToken;
						new CloudWatch().putLogEvents( params, (err: AWS.AWSError, data: CloudWatch.PutLogEventsResponse ) => {
							if (err) {
								err.message = 'Error retrying CloudWatchLogger.putLogEvents after invalid sequence token, params=' + JSON.stringify( params, null, 2 ) + ', original error message='+message + '. ' + err.code + ': ' + err.message;
								console.log( err.message );
								reject( err );
							} else {
								// console.log( 'Successfully retried putting CloudWatch log statements after invalid sequenceToken error.');
								resolve( data.nextSequenceToken );
							}
						});
					
					} else  if (AWSRequestor.cognitoUtil && (err.code === 'CredentialsError' || err.code === 'InvalidSignatureException')) {
						// User's credentials may have expired, try to refresh them
						AWSRequestor.cognitoUtil.refreshCredentials()
						.then( () => {
							// Retry the call after refreshing user session
							new CloudWatch().putLogEvents( params, (err: AWS.AWSError, data: CloudWatch.PutLogEventsResponse ) => {
								if (err) {
									err.message = 'Error retrying CloudWatchLogger.putLogEvents, params=' + JSON.stringify( params, null, 2 ) + '. ' + err.code + ': ' + err.message;
									console.log( err.message );
									reject( err );
								} else {
									console.log( 'Eureka (CloudWatch)!!! Retry after refreshing credentials succeeded!!!  Hooray for me!!!');
									resolve( data.nextSequenceToken );
								}
							});
						})
						.catch( err2 => {
							console.log( 'Refresh credentials failed.  Error: '+JSON.stringify(err2,null,2) );
							reject( err );
						})
					} else {
						console.log( 'err.code=CredentialsError: '+(err.code == 'CredentialsError'));
						err.message = 'Error in CloudWatchLogger.putLogEvents, params=' + JSON.stringify( params, null, 2 ) + '. ' + err.code + ': ' + err.message;
						reject( err );
					}
				} else {
					// console.log( "CloudWatch.putLogEvents returned: "+JSON.stringify( data, null, 2 ) );
					resolve( data.nextSequenceToken );
				}
			});
			// new AWSRequestor().send( new CloudWatch().putLogEvents( params ) )
			// .then( data => resolve( data.nextSequenceToken ) )
			// .catch( ( err => reject( err ) );
		})
	}

	/**
	 * Called to log events that were added to the queue while we were sending the last batch.
	 */
	private sendEventBacklog() {
		if (this.logEvents.length > 0) {
			// console.log("SENDING "+this.logEvents.length+" QUEUED UP LOG EVENTS");
			// this.addEventToList("SENDING "+this.logEvents.length+" QUEUED UP LOG EVENTS");
			this.sendAllMessagesToRemoteLog();
		}
	}

	public isAllLoggingFinished() {
		return this.logEvents.length == 0 && !this.sendingToRemoteLog;
	}

	public waitForAllLoggingToFinish(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			if (this.isAllLoggingFinished()) {
				resolve();
			} else {
				setTimeout( () => {
					this.waitForAllLoggingToFinish()
					.then( () => resolve() )
					.catch( error => {
						console.log( 'waitForAllLoggingToFinish error: '+JSON.stringify(error,null,2) );
						this.logEvents.forEach( message => console.log( message ) );
						resolve();
					})
				}, 250);
			}
		});
	}

}