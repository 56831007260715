import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Global } from '../../service/Global';
import { version } from '../../service/Version';

/** Displays generic footer with copyright, version, privacy policy, and terms at the bottom of most pages. */
@Component({
    selector: 'site-footer',
	templateUrl: './footer.html',
	styleUrls: ['./footer.css'],
})
export class FooterComponent implements OnInit, AfterViewInit {
	private softwareVersion = version;
	private currentYear = new Date().getFullYear();

	@ViewChild('footerPushDown') private footerPushDownDivRef: ElementRef; private footerPushDownDiv: HTMLDivElement;

	@ViewChild('footer') private footerDivRef: ElementRef; private footerDiv: HTMLDivElement;

	// @ViewChild('linkedinbutton') private linkedInButtonRef: ElementRef;

	// @ViewChild('linkedinscripts') private linkedInScriptsRef: ElementRef;

	constructor( 
		private _renderer2: Renderer2,
		@Inject(DOCUMENT) private _document,
	) {}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );

		// Add LinkIn Follow button
		// let script = this._renderer2.createElement('script');
		// script.type = "text/javascript";
		// script.src = "//platform.linkedin.com/in.js";
		// script.innerHTML = " lang: en_US"

		// let script2 = this._renderer2.createElement('script');
		// this._renderer2.setAttribute( script2, "type", "IN/FollowCompany" );
		// this._renderer2.setAttribute( script2, "data-id", "18596293" );

		// this._renderer2.appendChild( this.linkedInButtonRef.nativeElement, script );	
		// this._renderer2.appendChild( this.linkedInButtonRef.nativeElement, script2 );	
	}

	ngAfterViewInit() {
		this.footerPushDownDiv = this.footerPushDownDivRef.nativeElement;
		this.footerDiv = this.footerDivRef.nativeElement;

		let docHeight = window.innerHeight;
		let footerRect = this.footerDiv.getBoundingClientRect();
		let footerBottom = footerRect.top + footerRect.height;
		// console.log( 'docHeight='+docHeight+', footerRect.top='+footerRect.top+', footerRect.height='+footerRect.height );
		if (footerBottom < docHeight) {
			this.footerPushDownDiv.style.height = (docHeight - footerBottom) + 'px';
			// this.footerDiv.style.marginTop = (docHeight - footerBottom) + 'px';
		}
		// this._renderer2.setProperty( this.linkedInButtonRef.nativeElement, 'innerHTML', this.linkedInScriptsRef.nativeElement.innerHTML );
		// this.linkedInButtonRef.nativeElement.innerHTML = this.linkedInScriptsRef.nativeElement.innerHTML;

	}

}
