import { Global } from "../service/Global";
import { Command } from "./Command";
import { RestaurantReservation } from "../service/RestaurantReservation";
import { RestaurantReservationList } from "../service/RestaurantReservationList";

export class CancelRestaurantReservationCmd extends Command {

	/**
	 * Check the availability of the given reservation.
	 * @param Key Reservation list row with key field values set.
	 * @param reservation Reservation to cancel.
	 * @returns Promise: True if reservation was deleted, false if not found.
	 */
	do( reservationListKey: RestaurantReservationList, reservation: RestaurantReservation ): Promise<boolean> {
		return new Promise<boolean>( (resolve, reject) => {
			var parameters = {
				"reservationListKey": reservationListKey.toDataItem(),
				"reservation": reservation.toDataItem(),
			};
			console.log( 'CancelRestaurantReservationCmd sends: '+JSON.stringify(parameters,null,2));
			super.send( this.constructor.name, reservationListKey.companyId, parameters )
			.then( results => {
				console.log( 'CancelRestaurantReservationCmd returns: '+results.data);
				resolve( results.data );
			})
			.catch( err => reject( err ) );
		});
	}
}
