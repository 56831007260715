import { Command } from "./Command";
import { PropertyAdvertiser } from "../service/PropertyAdvertiser";

export class PutPropertyAdvertiserCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: PropertyAdvertiser ): Promise<PropertyAdvertiser> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<PropertyAdvertiser>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new PropertyAdvertiser().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
