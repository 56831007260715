import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Global } from '../../service/Global';
import { ImageCaptureComponent } from '../imagecapture/image-capture.component';

/** Component for selecting the images to analyze the results of various face detection settings. */
@Component({
    selector: 'test-settings',
    templateUrl: './test-settings.html',
	styleUrls: ['./test-settings.css'],
})
export class TestSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('testSettingsModalButton') public testSettingsModalButtonRef: ElementRef; private testSettingsModalButton: HTMLButtonElement;
	
	/** Function to call if promise is resolved when modal dialog is closed. */
	private resolveModal: Function;

	/** Function to call if promise is rejected due to an error in modal dialog processing. */
	private rejectModal: Function;

	private errorMessage: string = null;
	
	private images = [];

	constructor() {}

    ngOnInit() {
		// Global.log( this.constructor.name + '.ngOnInit' );
		// document.getElementsByTagName('body')[0].classList.add('modal-open');
    }

	ngOnDestroy() {
		// console.log('TestSettingsComponent.ngOnDestroy()');
		// document.getElementsByTagName('body')[0].classList.remove('modal-open');
	}

	ngAfterViewInit() {
		// console.log('TestSettingsComponent.ngAfterViewInit' );
		this.testSettingsModalButton = this.testSettingsModalButtonRef.nativeElement;
	}

	showModalDialog( images ): Promise<boolean> {
		return new Promise<boolean>( (resolve,reject) => {
			this.resolveModal = resolve;
			this.rejectModal = reject;
			if (!images) {
				images = [];
			}				
			this.images = images;
			if (images.length === 0) {
				this.errorMessage = 'There are no test images to analyze.  Please try again.';
			}
			// console.log( 'images[0]='+images[0].imageName+', images[1]='+images[1].imageName);
			this.testSettingsModalButton.click();
		});
	}
	
	toggleSelectAll() {
		this.images.forEach( image => image.selected = !image.selected );
	}
	
	/** Close the dialog without saving changes. */
	onCancel() {
		// Hide the dialog
		this.testSettingsModalButton.click();
		this.resolveModal( false );
	}

	/** Close dialog and save changes. */
    onAnalyze() {
		// Hide the dialog
		this.testSettingsModalButton.click();
		this.resolveModal( true );
    }

}