import { TableHandler, TableDocumentObject } from './TableHandler'

/**
 * Tracks URL's of SQS queues used to notify dashboards when a new visit has started.
 */
export class VisitQueue extends TableDocumentObject {

	/**
	 * 
	 * @param companyId ID of company (partition key = companyId_siteId)
	 * @param siteId ID of site (partition key = companyId_siteId)
	 * @param queueUrl URL of the queue where notifications of new visits should be sent. (sort key = queueUrl)
	 * @param lastActiveTime Last time someone checked the queue for messages, updated every 15
	 * 			minutes to keep costs down.  Used to get rid of queues that are no longer in use.
	 */
	constructor(
		public companyId: number = null,
		public siteId: number = 0,
		public queueUrl: string = null,
		public lastActiveTime: Date = new Date(),
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): VisitQueue {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_siteId'] = this.companyId + '_' + this.siteId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_siteId": this.companyId + '_' + this.siteId, 
            "queueUrl": this.queueUrl
        };
    }

}

/**
 * Contains records for queues created when a user opens a dashboard that needs to be updated when 
 * a new visit starts so we can update the dashboard in real time when a visit starts.
 */
export class VisitQueueTable extends TableHandler<VisitQueue> {

    public constructor() {
		super( 'faceTrackerVisitQueue' );
	}
	
	public fromDataItem( item: any ): VisitQueue {
		return new VisitQueue().fromDataItem( item );
	}

    /**
     * Get visit records that started after the given date.
     * @param companyId ID of the company.
	 * @param siteId ID of the site where employee worked.
     * @param date Look for visits that started after this date.
	 * @param maxVisitsToGet Max number of visits to return.
     */
    getActiveQueuesForSite( companyId: number, siteId: number ): Promise<VisitQueue[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "companyId_siteId = :companyId_siteId";
			let expressionAttributeValues = {
				":companyId_siteId": companyId + '_' + siteId,
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( queues => {
				resolve( queues );
			})
			.catch( err => {
				reject( err );
			})
        });
	}
	
}
