import { Global } from "./Global";

export class TimeInputModel {

	public hourArray = [];
	public minuteArray = [];
	public hours: string = null;
	public minutes: string = null;
	public ampm: string = 'a';
	
	constructor(
		private time: Date,
		private timeZone: string = null
	) {
		this.setTime( time, timeZone );
		for (let i=1; i<13; i++) {
			this.hourArray.push( i.toString() );
		}
		for (let i=0; i<60; i++) {
			this.minuteArray.push( i < 10 ? '0' + i : i.toString() );
		}
	}

	/**
	 * Sets the model variables to the local hours and minutes of the given time in the time zone with the given offset.
	 * @param time Time to set.
	 */
	setTime( time: Date, timeZone: string = null ): void {
		// console.log('setTime( '+(time ? time.toISOString() : 'null')+', '+timeZone);
		this.time = time;
		this.timeZone = timeZone;

		// Set input field values to match time member
		if (time == null) {
			this.hours = null;
			this.minutes = null;
			this.ampm = 'a';
		} else {
			time = Global.adjustDateByTimeZone( time, timeZone );
			let utcHours = time.getUTCHours();
			let utcMinutes = time.getUTCMinutes();
			this.minutes = utcMinutes < 10 ? '0' + utcMinutes : utcMinutes.toString();
			if (utcHours > 11) {
				this.ampm = "p";
				this.hours = utcHours === 12 ? '12' : '' + (utcHours - 12);
			} else {
				this.ampm = "a";
				this.hours = utcHours === 0 ? '12' : '' + utcHours;
			}
		}
		// console.log('setTime '+(this.time ? this.time.toISOString() : null)+', offset '+this.timeZone+' became local time: '+this.hours+':'+this.minutes+' '+this.ampm );
	}

	isNull(): boolean {
		return this.hours == null || this.minutes == null;
	}

	getTime(): Date {
		// Set time member to match input field values
		if (this.isNull()) {
			this.time = null;
		} else {
			let hour = 12;
			let minute = 0;
			if (this.hours != undefined && this.hours != null) {
				try {
					hour = Number.parseInt( this.hours );
				} catch( error ) {} // Ignore error and leave hour at default value
			}
			if (this.minutes != undefined && this.minutes != null) {
				try {
					minute = Number.parseInt( this.minutes );
				} catch( error ) {} // Ignore error and leave minute at default value
			}
			if (this.ampm && this.ampm === 'p') {
				// Treat time as PM
				if (hour < 12) {
					hour += 12;
				}
			} else {
				// Treat time as AM
				if (hour === 12) {
					hour = 0;
				}
			}

			let adjustedTime = Global.adjustDateByTimeZone( this.time, this.timeZone );
			adjustedTime.setUTCHours( hour, minute, 0, 0 );
			this.time = Global.adjustDateByTimeZone( adjustedTime, this.timeZone, false );
		}

		// console.log('getTime returned '+(this.time ? this.time.toISOString() : null)+' from local time: '+this.hours+':'+this.minutes+' '+this.ampm );
		return this.time;
	}

}