import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../../secure/title-nav/title-nav.component";
import { ConfirmComponent } from "../../secure/confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Editor } from "../../service/Editor";
import { Company } from '../../service/Company';
// import { DeleteCompanyCmd } from "../../command/DeleteCompanyCmd";
// import { GetCompanyListCmd } from "../../command/GetCompanyListCmd";

/**
 * Company selector used when a user with multiple companies logs in, switches companies, or deletes a company.
 */
@Component({
    selector: 'company-selector',
    templateUrl: './company-selector.html',
	styleUrls: ['./company-selector.css'],
})
export class CompanySelectorComponent implements OnInit, AfterViewInit {

	private errorMessage: string = null;

	// private rows: Company[] = [];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) {}

    ngOnInit() {
		this.errorMessage = null;
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		// this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Company Deleter' );
	}

	initialize() {
		// this.loadRows();
	}

	// loadRows(): Promise<Company[]> {
	// 	return new Promise<Company[]>( (resolve,reject) => {
	// 		new GetCompanyListCmd().do( this.cache.currentCompany.companyId )
	// 		.then( rows => {
	// 			this.rows = rows;
	// 			resolve( rows );
	// 		})
	// 		.catch( error => {
	// 			this.errorMessage = Global.logError( 'Error loading company list.', error );
	// 			reject( error );
	// 		});
	// 	});
	// }

	select( index: number ) {
		// this.cache.confirmComponent.showModalDialog( 'Company '+ this.rows[index].companyId + ' selected!  Action = ' + this.cache.companySelectorAction );
		console.log( 'Selected company '+ this.cache.companies[index].companyId );
		this.userService.setCurrentUserAttributes( this.cache.currentUserId, this.cache.currentUserNickname, this.cache.currentUserEmail, this.cache.currentUserTokenExpiration, this.cache.companies[index].companyId )
		.then( () => {
			this.cache.appComponent.goToDefaultSecureHomePage();
		})
		.catch( error => {
			this.errorMessage = 'Sorry, there was a problem selecting the company.  Please try again.';
			Global.logError( 'Error selecting company', error );
		})
	}

}
