import { Global } from './Global';
import { System } from './System';

export class LatitudeAndLongitude {

	constructor(
		public latitude: number = null,
		public longitude: number = null
	) {}

}
/**
 * Encapsulates the Google Maps API. 
 * API documentation is at: https://developers.google.com/maps/documentation/geocoding/start
 * Google Maps API code: https://github.com/googlemaps/google-maps-services-js
 * 
 * API key is stored in System table so hackers can't get it from javascript.
 */
export class GoogleMaps {

	/**
	 * @returns Latitude and longitude of the given address.  Values will be null if address not found.
	 * Call takes 600ms and costs $5/thousand so save results if you can.
	 * @param address Address to geocode.
	 */
	public getLatitudeAndLongitudeOfAddress( address: string, system: System ): Promise<LatitudeAndLongitude> {
		return new Promise<LatitudeAndLongitude>( (resolve,reject) => {
			// Global.log( 'Geocoding address '+address );
			let result = new LatitudeAndLongitude();
			if (address == undefined|| address == null || address.length == 0) {
				resolve( result );
			} else {
				let startTime = Date.now();
				const googleMapsClient = require('@google/maps').createClient({ key: system.googleMapsApiKey, Promise: Promise });
				googleMapsClient.geocode({address: address}).asPromise()
				.then( response => {
					// Global.log( 'Geocoding took '+(Date.now()-startTime)+'ms.');
					// Global.log( 'Geocoding response='+JSON.stringify(response,null,2));
					if (response.status === 200) {
						let results = response.json.results[0];
						if (results) {
							let location = results.geometry.location;
							// Global.log( 'Geocoding location='+JSON.stringify(location,null,2));
							result.latitude = location.lat.toFixed(7);
							result.longitude = location.lng.toFixed(7);
							resolve( result );
						} else {
							// Global.log( 'Geocoding returned no results, address not found.');
							resolve( result );
						}
					} else {
						Global.log( 'Geocoding response='+JSON.stringify(response,null,2));
						reject( new Error( 'Error geocoding address: '+address+'.  Status='+response.status ) );
					}
				})
				.catch( error => {
					Global.log( 'Error geocoding address: '+address+'.  '+JSON.stringify(error,null,2));
					reject( new Error( 'Error geocoding address: '+address+'.  '+error ) );
				});
			}
		});
	}

}
