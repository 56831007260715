import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { RestaurantReservation } from "../../service/RestaurantReservation";
import { SiteHours } from "../../service/SiteHours";
import { ClosedComponent } from "../closed/closed.component";
import { SiteHoursComponent } from "../site-hours/site-hours.component";
import { Global } from '../../service/Global';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";
import { EditorDetail } from "../../service/EditorDetail";
import { IMyDpOptions } from 'mydatepicker';
import { TimeInputModel } from "../../service/TimeInputModel";

/**
 * Component for editing a restaurant layout.
 */
@Component({
    selector: 'restaurant-reservation',
	templateUrl: './restaurant-reservation.html',
	styleUrls: [ 'restaurant-reservation.css' ]
})
export class RestaurantReservationComponent extends EditorDetail<RestaurantReservation> implements OnInit, OnDestroy {

	@ViewChild('RestaurantReservationModalButton') private modalButtonRef: ElementRef; 
	
	@ViewChild('focus') private focusRef: ElementRef; 
		
	/** Time zone offset minutes for the time zone where the currently selected property is located. */
	public timeZone = null;

	// Model variables for reservation start time
	private reservationTime: string = null;

	// List of reservation times to choose from.
	private availableTimes: Date[] = [];

	/** Date and time of the beginning of the seating for which the reservation is being made. */
	public reservationDate: Date;

	/** Seating for which the reservation is being made. */
	public seating: SiteHours;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
	)
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }

	initializeDialog() {
		this.reservationTime = this.editedRow.startTime ? this.editedRow.startTime.toISOString() : null;
		// Set up available reservation times
		let timeRange = this.seating.getReservationListTimeRange( Global.setTimeToMatch( this.seating.startDate, this.reservationDate, this.timeZone ), this.timeZone );
		if (!timeRange) {
			this.errorMessage = "No seating available."
		} else {
			this.availableTimes = [];
			let incrementMillis = this.seating.intervalMinutes * 60000;
			for (let time = timeRange.start; time.getTime() <= timeRange.end.getTime(); time = new Date( time.getTime() + incrementMillis )) {
				this.availableTimes.push( time );
			}
		}
	}

	/** Returns a string representing the time of the reservation. */
	getTimeString( time ) {
		return Global.getHM12TimeString( time, this.timeZone );
	}

	/** Returns a string representing the date of the reservation. */
	getDateString() {
		return Global.getDateString( this.reservationDate, this.timeZone );
	}

	switchSeatingTime( event ) {
		// Do nothing when user selects a different time
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	createNewDefaultRow(): RestaurantReservation {
		return new RestaurantReservation();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Return a deep copy of the given object. */
	clone( row: RestaurantReservation ): RestaurantReservation {
		return new RestaurantReservation().fromDataItem( row.toDataItem() );
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete the reservation for ' + this.originalRow.name + '?'
	}

	isValid(): boolean {
		this.errorMessage = null;
		if ((!this.editedRow.name) || this.editedRow.name.trim() === '') {
			this.errorMessage = 'Please enter a name.';
			return false;
		}

		if (this.reservationTime == null) {
			this.errorMessage = 'Please select a time.';
			return false;
		}

		this.editedRow.startTime = new Date( this.reservationTime );
		this.editedRow.endTime = new Date( this.editedRow.startTime.getTime() + (this.seating.turnMinutes * 60000) );

		return true;
	}

}