import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { RestaurantTableTop } from './RestaurantTableTop';

/** Section of a restaurant containing tables. */
export class RestaurantSection extends TableDocumentObject {
	
	/**
	 * @param sectionId ID of section
	 * @param name Section name
	 * @param tables List of tables in section
	 */
	constructor(
		public sectionId: number = null,
		public name: string = null,
		public tables: RestaurantTableTop[] = [],
	) { 
		super(); 
	}

	/** @return Object created from a data item that came from DynamoDb in the Item property. */
	fromDataItem( item: any ): RestaurantSection {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.tables = null;
		if (item['tables']) {
			this.tables = [];
			item['tables'].forEach( childItem => this.tables.push( new RestaurantTableTop().fromDataItem( childItem ) ) );
		}
		return this;
	}

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['tables'] = null;
		if (this.tables) {
			item['tables'] = [];
			this.tables.forEach( childItem => item['tables'].push( childItem.toDataItem() ) );
		}
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
	getKey(): any {
		return null;
	}

}

