import { TableDocumentObject } from './TableHandler'
import { ActivitySchedule } from './ActivitySchedule';

/** Activities for which guests may sign up. */
export class Activity extends TableDocumentObject {

	/**
	 * @param companyId ID of event (partition key)
	 * @param propertyId ID of property where activity occurs (partition key)
	 * @param activityId ID of activity (sortKey)
	 * @param activityType Name used by Room Genie app to sign up for activity such as 'snorkelling'
	 * @param activityTypeLower Lower-case version of activity type for searching.
	 * @param name Name of the activity such as 'Morning snorkelling'.
	 * @param schedules: List of schedules for the activity
	 */
	constructor(
		/* NOTE: If you change this list of properties, you must change fromDataItem() and toDateItem(). */
		public companyId: number = null,
		public propertyId: number = null,
		public activityId: number = null,
		public activityTypeId: number = null,
		public name: string = null,
		public schedules: ActivitySchedule[] = [],
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Activity {
		this.copyPropertiesFromObject( item );
		// Copy nested schedules objects
		this.schedules = null;
		if (item['schedules']) {
			this.schedules = [];
			for (let i=0; i<item['schedules'].length; i++) {
				let schedule = new ActivitySchedule().fromDataItem( item['schedules'][i] );
				this.schedules.push( schedule );
			}
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy nested schedules objects
		item['schedules'] = null;
		if (this.schedules) {
			item['schedules'] = [];
			for (let i=0; i<this.schedules.length; i++) {
				item['schedules'].push( this.schedules[i].toDataItem() );
			}
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"activityId": this.activityId, 
        };
    }

}
