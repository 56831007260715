import { Global } from './Global';
import { GoogleMaps } from "./GoogleMaps";
import { TableHandler } from './TableHandler'
import { Site } from "./Site";
import { SiteKey } from "./SiteKey";
import { System } from "./System";
import { SystemTable } from "./SystemTable";
import { ActiveSeatings } from './ActiveSeatings';
import { Company } from './Company';
import { CompanyTable } from './CompanyTable';
import { Product } from './Product';

/** Table that holds site data. */
export class SiteTable extends TableHandler<Site> {

    public constructor() {
		super( 'bookcliffsoftware-Site' );
	}
	
	public fromDataItem( item: any ): Site {
		return new Site().fromDataItem( item );
	}

	// public getAll( companyId: number ): Promise<Site[]> {
    // 	return new Promise<Site[]>( (resolve, reject) => {
	// 		let keyConditionExpression = "companyId = :companyId";
	// 		let expressionAttributeValues = { ":companyId": companyId };
	// 		this.queryAll( keyConditionExpression, null, expressionAttributeValues )
	// 		.then( sites => resolve( sites ) )
	// 		.catch( error => reject( error ) );
	// 	});
	// }

	public getAll( companyId: number, propertyId: number ): Promise<Site[]> {
    	return new Promise<Site[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId,
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Update the latitude and longitude values of the property if they have changed.
	 */
	private updateLatitudeAndLongitude( site: Site, system: System ): Promise<Site> {
		return new Promise<Site>( (resolve, reject) => {
			let address = Global.getAddressString( site.address1, site.city, site.state, site.postalCode );
			new GoogleMaps().getLatitudeAndLongitudeOfAddress( address, system )
			.then( results => {
				site.latitude = results.latitude;
				site.longitude = results.longitude;
				resolve( site );
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Overrides generic put to update the latitude and longitude from the address information.
	 */
	public put( site: Site ): Promise<Site> {
    	return new Promise<Site>( (resolve, reject) => {
			new SystemTable().getSystemData()
			.then( system => {
				this.updateLatitudeAndLongitude( site, system )
				.then( site => {

					super.put( site )
					.then( site => {

						// See if this is one of the What's Happening sites so we can update it's location info in the  list if needed
						new CompanyTable().get( new Company( site.companyId ) )
						.then( company => {
							if (company.hasOneOfTheseProducts( [ Product.WHATS_HAPPENING ] )) {
								let index = system.getSiteKeyIndex( site.companyId, site.propertyId, site.siteId );
								let isWhatsHappeningSite = site.isWhatsHappeningSite && site.ads && site.ads.length > 0;
								if (index != -1 || isWhatsHappeningSite) {
									if (index != -1) {
										if (!isWhatsHappeningSite) {
											// Site is no longer a what's happening site, remove it from system site keys
											system.siteKeys.splice( index, 1 );
										} else {
											// Update the system data with the updated location data for the What's Happening site
											system.siteKeys[index].fromSite( site );
										}
									} else {
										// This is a what's happening site that isn't in the system site keys, add it
										system.siteKeys.push( new SiteKey().fromSite( site ) );
									}
									new SystemTable().put( system )
									.then( system => {
										resolve( site );
									})
									.catch( error => reject( error ) );
								} else {
									// Site key does not exist and this is not a what's happening site, just return site
									resolve( site );
								}
							} else {
								// Company does not have permission to use What's Happening, just return site
								resolve( site );
							}
						})
						.catch( error => reject( error ) );
					})
					.catch( error => reject( error ) );
				})
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}

	public delete( key: Site ): Promise<void> {
    	return new Promise<void>( (resolve, reject) => {
			new SystemTable().getSystemData()
			.then( system => {
				super.delete( key )
				.then( site => {
					let index = system.getSiteKeyIndex( key.companyId, key.propertyId, key.siteId );
					if (index != -1) {
						// Remove deleted site from system site keys
						system.siteKeys.splice( index, 1 );
						new SystemTable().put( system )
						.then( system => {
							resolve();
						})
						.catch( error => reject( error ) );
					}
				})
				.catch( error => reject( error ) );
			})
			.catch( error => reject( error ) );
		});
	}

	public getActiveSeatings( companyId: number, propertyId: number ): Promise<ActiveSeatings> {
		return new Promise<ActiveSeatings>( (resolve,reject) => {
			let activeSeatings = new ActiveSeatings();
			let foundseatings: number[] = [];
			this.getAll( companyId, propertyId )
			.then( sites => {
				// Find active open times
				for (let siteIndex=0; siteIndex < sites.length; siteIndex++) {
					let site = sites[siteIndex];
					// console.log('Found site: '+JSON.stringify(site,null,2));
					for (let hoursIndex=0; hoursIndex < site.hours.length; hoursIndex++) {
						let seating = site.hours[hoursIndex];
						// console.log('Found seating: '+JSON.stringify(seating,null,2));
						if (seating.isOpenInNextSevenDays()) {
							activeSeatings.sites.push( site );
							activeSeatings.hoursIndexes.push( hoursIndex );
						}
					}
				}
				resolve( activeSeatings );
			})
			.catch( error => reject( error ) );
		});
	}

	/**
	 * Get site whose name or synonyms match what the given name (case-insensitive).
	 * @param companyId ID of company site is in.
	 * @param propertyId ID of property site is on.
	 * @param name Site name to look for.
	 * @returns Found site or null if no site's name or synonyms match the given name (case insensitive).
	 */
	public findByName( companyId: number, propertyId: number, name: string ): Promise<Site> {
		return new Promise<Site>( (resolve,reject) => {
			// console.log('findByName companyId='+companyId+', propertyId='+propertyId+', name='+name );
			let found: Site = null;
			name = name.toLowerCase();
			this.getAll( companyId, propertyId )
			.then( sites => {
				for (let i=0; i<sites.length; i++) {
					let site = sites[i];
					if (site.hasNameOrSynonym( name )) {
						found = site;
						break;
					}
				}
				resolve( found );
			})
			.catch( error => reject( error ) );
		});
	}

	// /**
	//  * Converts list of sites into list of SiteName objects so company and property names are available.
	//  * @param sites List of sites.
	//  */
	// private getList( keys: Site[] ): Promise<Site[]> {
	// 	return new Promise<Site[]>( (resolve,reject) => {
	// 		let promises: Promise<Site>[] = [];
	// 		let table = new SiteTable();
	// 		keys.forEach( key => promises.push( table.get( new Company( companyId ) ) ) );
	// 		Promise.all( promises )
	// 		.then( companies=> {
	// 			// Put company names in a map keyed by id
	// 			companies.forEach( company => companyNameMap.set( company.companyId, company.name ) );

	// 			// Get property names
	// 			let propertyPromises: Promise<Property>[] = [];
	// 			let propertyTable = new PropertyTable();
	// 			propertyIdSet.forEach( ids => {
	// 				let parts = ids.split(',');
	// 				let companyId = Number.parseInt( parts[0] );
	// 				let propertyId = Number.parseInt( parts[1] );
	// 				propertyPromises.push( propertyTable.get( new Property( companyId, propertyId ) ) );
	// 			});
	// 			Promise.all( propertyPromises )
	// 			.then( properties=> {
	// 				// Put property names in a map keyed by companyId+','+propertyId
	// 				properties.forEach( property => propertyNameMap.set( property.companyId+','+property.propertyId, property.name ) );

	// 				// Create list of site names
	// 				let siteNames: SiteName[] = [];
	// 				sites.forEach( site => {
	// 					let companyName = companyNameMap.get( site.companyId );
	// 					let propertyName = propertyNameMap.get( site.companyId+','+site.propertyId );
	// 					siteNames.push( new SiteName( site.companyId, companyName, site.propertyId, propertyName, site.siteId, site.namesite.address1, site.address2, site.city, site.state, site.postalCode, site.country,  ) );
	// 				});
	// 				resolve( siteNames );
	// 			})
	// 			.catch( error => reject( error ) );
	// 		})
	// 		.catch( error => reject( error ) );
	// 	});
	// }

}
