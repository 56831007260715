import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { Role } from "../../service/Role";
import { Property } from "../../service/Property"
import { Global } from '../../service/Global';
import { EditorDetail } from '../../service/EditorDetail';
import { CacheService } from '../../service/cache.service';
import { ConfirmComponent } from "../confirm/confirm.component";

/** Edit role details */
@Component({
    selector: 'role',
	templateUrl: './role.html',
	styleUrls: [ 'role.css' ]
})
export class RoleComponent extends EditorDetail<Role> implements OnInit {

	@ViewChild('RoleModalButton') private modalButtonRef: ElementRef;
	
	@ViewChild('focus') private focusRef: ElementRef;
		
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService,
		private cache: CacheService,
    )
    { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
    }

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	initialize() {
    }
	
	/** Return a deep copy of the given object. */
	clone( row: Role ): Role {
		return new Role().fromDataItem( row.toDataItem() );
	}

	createNewDefaultRow(): Role {
		return new Role();
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Returns confirmation dialog component to use. */
	getConfirmComponent(): ConfirmComponent {
		return this.cache.confirmComponent;
	}

	getDeleteConfirmation() {
		return 'Are you sure you want to delete role ' + this.originalRow.roleName + '?';
	}

	initializeDialog() {
	}

	isValid(): boolean {
		let valid = true;
		if ((!this.editedRow.roleName) || this.editedRow.roleName.trim() === '') {
			this.errorMessage = 'Please enter a role name.';
			valid = false;
		}
		// console.log( 'role='+JSON.stringify(this.editedRow,null,2));
		return valid;
	}

	hasPermission( permissionName: string ): boolean {
		return this.cache.currentRole ? this.cache.currentRole.hasPermission( permissionName ) : false;
	}

}