import { Command } from "./Command";
import { Topic } from "../service/Topic";

export class DeleteTopicCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: Topic ): Promise<any> {
		return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
	}

}
