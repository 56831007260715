import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { RestaurantTableReservations } from './RestaurantTableReservations';
import { RestaurantReservation } from './RestaurantReservation';
import { RestaurantReservationResponse } from './RestaurantReservationResponse';
import { RestaurantTableTop } from './RestaurantTableTop';

/** List of people signed up for an activity on a specific date and time. */
export class RestaurantReservationList extends TableDocumentObject {

	/**
	 * @param companyId ID of company (partition key)
	 * @param propertyId ID of site where activity occurs (partition key)
	 * @param siteId ID of restaurant (sort key)
	 * @param startTime Date/time of the seating (sort key).
	 * @param endTime Date/time when seating ends.
	 * @param intervalMinutes Number of minutes between valid reservation times.
	 * @param tables List of reservation lists for specific tables.
	 * @param version Version number of the list to prevent simultaneous updates that lose data.
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public siteId: number = null,
		public startTime: Date = new Date(),
		public endTime: Date = new Date(),
		public intervalMinutes: number = 30,
		public tables: RestaurantTableReservations[] = [],
		public version: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): RestaurantReservationList {
		this.copyPropertiesFromObject( item );
		// Copy list of objects
		this.tables = null;
		if (item['tables']) {
			this.tables = [];
			item['tables'].forEach( childItem => this.tables.push( new RestaurantTableReservations().fromDataItem( childItem ) ) );
		}
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		item['siteId_startTime'] = this.siteId + '_' + this.startTime.toISOString();
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// Copy list of objects
		item['tables'] = null;
		if (this.tables) {
			item['tables'] = [];
			this.tables.forEach( childItem => item['tables'].push( childItem.toDataItem() ) );
		}
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"siteId_startTime": this.siteId + '_' + this.startTime.toISOString(), 
        };
	}
	
	public reserveTable( sectionId: number, tableId: number, startTime: Date, endTime: Date, roomNumber: string, partyName: string, persons: number ) {
		let table = this.getTable( sectionId, tableId );
		if (!table) {
			throw new Error( 'Invalid section ID '+sectionId+' or table ID '+tableId);
		}
		let reservation = new RestaurantReservation( startTime, endTime, roomNumber, partyName, persons );
		table.addReservation( reservation, this.startTime, this.endTime );
	}

	/**
	 * Cancels the first reservation found with the given room number.
	 * @param roomNumber Room number.
	 */
	// public cancelReservation( sectionId: number, tableId: number, startTime: Date ) {
	// 	let table = this.getExistingTable( sectionId, tableId );
	// 	let index = table.getExistingReservationIndex( startTime );
	// 	table.removeReservation( index );
	// }

	/**
	 * Returns true if reservation with given values was removed.  Null values are ignored.
	 * @param roomNumber Room number.
	 * @param partyName Name on reservation.
	 * @param persons Number of people.
	 * @param date Date and time of reservation.
	 */
	public removeReservation( roomNumber: string, partyName: string, persons: number, date: Date ): boolean {
		let removed = false;
		for (let i=0; i<this.tables.length&& !removed; i++) {
			let table = this.tables[i];
			if (table.removeReservation( roomNumber, partyName, persons, date )) {
				removed = true;
			}
		}
		return removed;
	}

	/**
	 * Returns the table with the given section and table ID or null if table not found.
	 * @param sectionId Section ID
	 * @param tableId Table ID
	 */
	public getTable( sectionId: number, tableId: number ): RestaurantTableReservations {
		let foundTable: RestaurantTableReservations = null;
		for (let i=0; i<this.tables.length; i++) {
			let table = this.tables[i];
			if (table.sectionId === sectionId && table.tableId === tableId) {
				foundTable = table;
				break;
			}
		}
		return foundTable;
	}

	/**
	 * Returns the table with the given section and table ID or throws error if table not found.
	 * @param sectionId Section ID
	 * @param tableId Table ID
	 */
	public getExistingTable( sectionId: number, tableId: number ): RestaurantTableReservations {
		let table = this.getTable( sectionId, tableId );
		if (!table) {
			throw new Error('Table '+tableId+' in section '+sectionId+' not found in reservation list '+JSON.stringify(this,null,2));
		}
		return table;
	}

	/**
	 * Return the smallest available table that will accomodate the number of people or null if no table is available.
	 * @param persons Number of people to be seated.
	 * @param startTime Start time of requested reservation.
	 * @param endTime End time of requested reservation.
	 * @param oldReservation If not null, old reservation values of reservation is being changed.
	 */
	public checkAvailability( persons: number, startTime: Date, endTime: Date, oldReservation: RestaurantReservation ): RestaurantReservationResponse {
		// console.log('checkAvailability persons='+persons+' startTime='+startTime.toISOString()+', endTime='+endTime.toISOString()+' (seating '+this.startTime.toISOString()+'-'+this.endTime.toISOString()+')' );
		let response: RestaurantReservationResponse = new RestaurantReservationResponse();
		// If there is an old reservation being updated and it is on this list, remove it before checking availability
		let removedOldReservation = false;
		// console.log('oldReservation='+JSON.stringify(oldReservation,null,2)+'\n resList='+(JSON.stringify(this,null,2)));
		if (oldReservation) {
			removedOldReservation = this.removeReservation( oldReservation.roomNumber, oldReservation.name, oldReservation.persons, oldReservation.startTime );
			// console.log('removedOldReservation='+removedOldReservation+' resList='+(JSON.stringify(this,null,2)));
		}
		for (let i=0; i<this.tables.length; i++) {
			let table = this.tables[i];
			// console.log('checking table '+JSON.stringify(table,null,2));
			if (table.isAvailable( persons, startTime, endTime, this.startTime, this.endTime )) {
				if (response.tableTop == null || table.seats < response.tableTop.seats) {
					// We found the first available table or one with less seats than the previously found table
					response.isSeatingOpen = true;
					response.reservationList = this;
					response.tableTop = new RestaurantTableTop( table.sectionId, table.tableId, table.seats );
					response.startTime = startTime;
					response.endTime = endTime;
				}
			} else {
				// Table isn't available, get the nearest earlier and later available time
				let tableResponse = table.getNearestAvailableTimes( persons, startTime, endTime, this.startTime, this.endTime, this.intervalMinutes );
				response.setAlternateTimesIfBetter( tableResponse.alternateEarlierTime, tableResponse.alternateLaterTime );
				// console.log('checkAvailability section '+table.sectionId+' table '+table.tableId+' returned '+JSON.stringify(tableResponse,null,2));
			}
		}
		if (startTime.getTime() < this.startTime.getTime() || startTime.getTime() > this.endTime.getTime()) {
			// The requested time falls within the seating period, check for an available table
			response.isSeatingOpen = false;
		}
		// console.log('checkAvailability returned '+JSON.stringify(response,null,2));
		return response;	
	}

}

