import { Command } from "./Command";
import { Property } from "../service/Property";

export class PutPropertyCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: Property ): Promise<Property> {
		return new Promise<Property>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new Property().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
