import { Command } from "./Command";
import { ActivitySignUpList } from "../service/ActivitySignUpList";
import { ActivitySignUp } from "../service/ActivitySignUp";

export class PutActivitySignUpListCmd extends Command {

	/**
	 * Update an activity sign up list by adding, updating, or deleting a sign up.
	 * @param list Original sign up list to be updated.
	 * @param oldRow The original sign up to be updated or deleted, null if adding new sign up.
	 * @param newRow The new sign up to insert or replace an old sign up, null if deleting a sign up.
	 */
	public do( list: ActivitySignUpList, oldRow: ActivitySignUp, newRow: ActivitySignUp ): Promise<ActivitySignUpList> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<ActivitySignUpList>( (resolve,reject) => {
			this.send( this.constructor.name, list.companyId, { "list": list.toDataItem(), "oldRow": (oldRow == null ? null : oldRow.toDataItem()), "newRow": (newRow == null ? null : newRow.toDataItem()) } )
			.then( results => resolve( new ActivitySignUpList().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
