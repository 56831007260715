import { Command } from "./Command";
import { Global } from "../service/Global";
import { Role } from "../service/Role";

export class GetRoleListCmd extends Command {

	/**
	 * Get list of editable rows.
	 * @param companyId Company ID
	 */
	do( companyId: number ): Promise<Role[]> {
		// Global.log( 'GetRoleListCmd companyId '+companyId);
		return new Promise<Role[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, null )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: Role[] = [];
				results.data.forEach( row => rows.push( new Role().fromDataItem( row ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
