import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { CacheService } from '../../service/cache.service';
import { Global } from '../../service/Global';
import { Product } from '../../service/Product';
import { GetProductsCmd } from '../../command/GetProductsCmd';

/** Displays pres-sales information for Room Genie. */
@Component({
    selector: 'whats-happening-main',
    templateUrl: './whats-happening.html',
    styleUrls: ['./whats-happening.css'],
})
export class WhatsHappeningComponent implements OnInit {

	private errorMessage: string;

	private whatsHappeningProductCode = Product.WHATS_HAPPENING;
	private whatsHappeningMonthlyPrice: string = null;

	/** True if we are logged in and user's company has a What's Happening subscription. */
	private isWhatsHappeningActive = false;

	/** Product information */
	private product: Product;

	constructor( 
		private cache: CacheService
	) {}

	ngOnInit() {
		this.isWhatsHappeningActive = this.cache.currentCompany && this.cache.currentCompany.getActiveSubscriptionIndex( Product.WHATS_HAPPENING ) != -1;
		new GetProductsCmd().do()
		.then( products => {
			this.product = Product.getProduct( this.whatsHappeningProductCode, products );
			this.whatsHappeningMonthlyPrice = Global.getCurrencyStringForCents( this.product.monthlyVolumePrices[0].priceCents, false );
		})
		.catch( error => {
			Global.logError( 'Error loading products', error );
			this.errorMessage = 'Sorry, there was a problem loading the product information.  Please try again.';
		});
	}

	onSubscribe( productCode ) {
		// console.log('onSubscribe( ' + productCode + ' ) called');
		if (!this.product) {
			Global.log('Error, could not find product code '+productCode );
			this.errorMessage = 'Sorry, could not find product code '+productCode;
		} else {
			this.cache.appComponent.subscribeToProduct( this.product );
		}
	}

}
