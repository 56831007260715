import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import { EmployeeFeedbackTable, EmployeeFeedback } from '../../service/EmployeeFeedbackTable';
import { Property } from '../../service/Property';
import { CacheService } from "../../service/cache.service";
import { Global } from '../../service/Global';
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { EmployeeFeedbackDetailComponent } from "../feedback-detail/feedback-detail.component";

/**
Displays a dashboard for the SmartView product that tracks new and repeat guests.
*/
@Component({
    selector: 'room-genie',
    templateUrl: './room-genie.html',
    styleUrls: ['./room-genie.css'],
})
export class RoomGenieComponent implements OnInit, AfterViewInit {

	@ViewChild(EmployeeFeedbackDetailComponent) private employeeFeedbackComponent: EmployeeFeedbackDetailComponent;

	private roomAverage = 0;
	private totalUses = 0;
	private uniqueRoomNumbers: string[] = [];
	private propertyId: number = 0;

	/** Map of summarized feedback keyed by employeeId, firstName, lastName, and department. */
	private feedbackMap = new Map<string,any>();
	
	/** Array of feedback data to display on report sorted by value. */
	private feedbackArray = [];
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private employeeFeedbackTable: EmployeeFeedbackTable,
		private cache: CacheService
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}
	
    ngOnDestroy() {
    }

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-thumbs-up fa-fw"></i> Employee Thumbs Up' );
	}

	private initialize() {
		this.loadDataAndUpdateView()
		.catch( err => {
			Global.logError('Error loading data for Room Genie page.', err );
			alert( 'Sorry, we had a problem loading the page. Please try again.' );
		});
	}

	private loadDataAndUpdateView(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			this.feedbackMap = new Map<string,any>();
			// let endDate = new Date();
			let millisIn30Days = 30 * 24 * 60 * 60 * 1000;
			let startDate = new Date( Date.now() - millisIn30Days );
			// console.log('Refreshing data...');
			if (this.cache.properties.length > 0) {
				this.propertyId = this.cache.properties[0].propertyId;
			}
			this.changeDetectorRef.detectChanges();
			this.loadFeedback( this.propertyId );
		})
	}
	
	private switchProperty( event ) {
		this.propertyId = this.propertyId == null ? 0 : Number.parseInt( this.propertyId.toString() );
		console.log( 'Switch to property '+this.propertyId );
		this.loadFeedback( this.propertyId );
	}

	private loadFeedback( propertyId: number ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			// Clear display data values so we can reload them
			this.roomAverage = 0;
			this.totalUses = 0;
			this.uniqueRoomNumbers = [];
			this.feedbackMap = new Map<string,any>();
			this.feedbackArray = [];
			
			// let endDate = new Date();
			let millisIn30Days = 30 * 24 * 60 * 60 * 1000;
			let startDate = new Date( Date.now() - millisIn30Days );
			// console.log('Refreshing data...');
			this.employeeFeedbackTable.getDateRange( this.cache.currentCompany.companyId, this.propertyId, startDate, new Date() )
			.then( rows => {
				rows.forEach( feedback => {
					if (this.uniqueRoomNumbers.indexOf( feedback.roomNumber ) == -1) {
						this.uniqueRoomNumbers.push( feedback.roomNumber );
					}
					this.totalUses++;
					this.roomAverage = this.uniqueRoomNumbers.length == 0 ? 0 : Math.round( this.totalUses / this.uniqueRoomNumbers.length );
					this.mergeFeedback( feedback );
				})
				// Sort feedback
				this.feedbackMap.forEach( (value,key,map) => {
					// Insert feedback into array sorted by the sum of the positive and negative feedback values
					let feedbackValue = value.thumbsUp + value.thumbsDown;
					let inserted = false;
					for (let i=0; i<this.feedbackArray.length; i++) {
						if (feedbackValue > this.feedbackArray[i].total) {
							this.feedbackArray.splice( i, 0, value );
							inserted = true;
							break;
						}
					}
					if (!inserted) {
						this.feedbackArray.push( value );
					}
				});
				this.changeDetectorRef.detectChanges();
				resolve();
			})
			.catch( err => {
				let errorMessage = 'Error loading employee feedback data.';
				Global.logError( errorMessage, err );
				reject( new Error( errorMessage + '  ' + err.message ) );
			});
		})
	}
	
	private mergeFeedback( feedback: EmployeeFeedback ) {
		// if (feedback.feedbackTime.getDate() == 30) 
			// console.log( 'feedback: '+JSON.stringify(feedback,null,2));
		let key = feedback.employeeId+','+feedback.firstName+','+feedback.lastName+','+feedback.department;
		if (feedback.employeeId == null) {
			key = feedback.badgeName+','+feedback.department;
		}
		let positiveFeedback = feedback.feedbackValue > 0 ? feedback.feedbackValue : 0;
		let negativeFeedback = feedback.feedbackValue < 0 ? feedback.feedbackValue : 0;
		let data = this.feedbackMap.get( key );
		if (data) {
			// Add the feedback to this record
			data.thumbsUp += positiveFeedback;
			data.thumbsDown += negativeFeedback;
			data.total += positiveFeedback + negativeFeedback;
		} else {
			// Create a new record
			let name = feedback.badgeName;
			if (feedback.employeeId != null) {
				name = feedback.firstName + ' ' + feedback.lastName;
			}
			data = { name: name, department: feedback.department, thumbsUp: positiveFeedback, thumbsDown: negativeFeedback, total: positiveFeedback+negativeFeedback };
		}
		this.feedbackMap.set( key, data );
	}

	onGenerateTestData() {
		// this.eventTable.generateTestData( this.cache.currentCompany.companyId, this.cache.currentSite.siteId );
	}

	onPurgeTestData() {
	}

	insert() {
		this.employeeFeedbackComponent.showModalDialog( new EmployeeFeedback( this.cache.currentCompany.companyId, this.propertyId ), true )
		.then( data => {
			if (data) {
				// User saved changes, update display
				this.mergeFeedback( data );
			}
		})
	}

}
