import { Component, OnInit } from "@angular/core";
import { Global } from "../../service/Global";

@Component({
    selector: 'product-subscriptions',
    template: '<product-subscribe></product-subscribe>'
})
export class ProductPricingComponent implements OnInit {

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
	}

}
