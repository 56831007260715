import { TableHandler, TableDocumentObject } from './TableHandler'
import { Global } from './Global';
import { ActivityType } from './ActivityType';

/** Types of activities for which guests may sign up. */
export class ActivityTypeTable extends TableHandler<ActivityType> {

	public constructor() {
		super( 'bookcliffsoftware-ActivityType' );
	}
	
	public fromDataItem( item: any ): ActivityType {
		return new ActivityType().fromDataItem( item );
	}

	public getAll( companyId: number, propertyId: number ): Promise<ActivityType[]> {
    	return new Promise<ActivityType[]>( (resolve, reject) => {
			let keyConditionExpression = "companyId_propertyId = :companyId_propertyId";
			let expressionAttributeValues = {
				":companyId_propertyId": companyId + '_' + propertyId
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( rows => resolve( rows ) )
			.catch( error => reject( error ) );
		});
	}

}

