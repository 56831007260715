import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Global } from "../../service/Global";
import { FooterComponent } from "../footer/footer.component";

@Component({
    selector: 'contact',
    templateUrl: './contact.html',
})
export class ContactComponent implements OnInit, AfterViewInit {

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
	}

	ngAfterViewInit() {
	}

}
