import { TableDocumentObject } from './TableHandler'
import { Global } from './Global';

/** 
 * Topics guests can ask Room Genie about. 
 * Hotel managers can edit the list of topics and the responses.
 * Guests can say Alexa ask Room Genie about the airport shuttle.
 * The topic 'airport shuttle' could be associated with a speech like "it leaves every half hour
 * between 6am and 9pm" or a recorded messaged could be played instead.
 * If both a speech and an audio clip are specified, the speech is spoken and then the audio clip is played.
 */
export class Topic extends TableDocumentObject {

	/**
	 * @param companyId ID of event (partition key)
	 * @param propertyId ID of property where activity occurs (partition key)
	 * @param topicId ID of the topic (sort key)
	 * @param name Name used by Room Genie app to sign up for activity such as 'snorkelling'
	 * @param synonyms List of synonyms that users might use when referring to this activity type
	 * @param speech What Alexa should say before playing the audio clip (if null, just play the audio clip)
	 * @param audioFile File name of audio clip Alexa should play after she says the speech (if null, just say speech) 
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public topicId: number = null,
		public name: string = null,
		public synonyms: string[] = [],
		public speech: string = null,
		public audioFile: string = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Topic {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();

		// Add key properties made up of multiple object properties
		item['companyId_propertyId'] = this.companyId + '_' + this.propertyId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
			"companyId_propertyId": this.companyId + '_' + this.propertyId,
			"topicId": this.topicId, 
        };
    }

	getSynonyms(): string {
		let s = '';
		if (this.synonyms) {
			this.synonyms.forEach( synonym => {
				if (s.length > 0) {
					s += ', ';
				}
				s += synonym;
			})
		}
		return s;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ) {
		let valid = false;
		// Global.log('topic '+this.name.toLowerCase()+' has name or synonym '+name+', name match is '+(this.name.toLowerCase() == name));
		name = name.toLowerCase();
		if (this.name.toLowerCase() == name) {
			valid = true;
		} else {
			// Check all the synonyms
			valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
		}
		return valid;
	}

}
