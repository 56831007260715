import { ApiGateway } from "../service/ApiGateway";
import { RestaurantLayout } from "../service/RestaurantLayout";
import { RestaurantReservationResponse } from "../service/RestaurantReservationResponse";
import { isArray } from "util";

/** 
 * Sends commands to API gateway web service and returns Promise of response.
 * All command bodies must at least have an action property and a companyId property like this:
 *	var body = {
 *		"action": "getRestaurantLayoutList",
 *		"companyId": companyId,
 *		...
 *	};
 */
export class Command {

	/**
	 * Save a row.
	 * @param body Command parameters.  Must include a valid 'action' and 'companyId' property.
	 */
	protected send( action: string, companyId: number, parameter: any ): Promise<any> {
		return new Promise<any>( (resolve, reject) => {
			var body = {
				"action": action,
				"companyId": companyId,
				"parameter": parameter,
			};
			new ApiGateway().call( '/command', body )
			.then( results => resolve( results ) )
			.catch( err => reject( err ) );
		});
	}

}
