import { Global } from './Global';
import { Site } from './Site'
import { TableDocumentObject } from './TableHandler'

/**
 * Site properties used to search for sites by name, city, or coordinates.
 * Includes the name, synonyms, city, state, and coordinates of a site.
 * Used by the What's Happening skill to find sites that paid to advertise.
 * Site keys are stored in the System table.
 */
export class SiteKey extends TableDocumentObject {

	/**
	 * @param companyId ID of site (partition key)
	 * @param propertyId ID of the property the site is part of.
	 * @param siteId ID of site (sort key)
	 * @param name Name of the site.
	 * @param synonyms Alternate names customers might use when referring to the site.
	 * @param city City where property is located
	 * @param state State where property is located
	 * @param latitude GPS latitude coordinates from Google maps (right click location, select What's there)
	 * @param longitude GPS longitude coordinates from Google maps (right click location, select What's there)
	 */
	constructor(
		public companyId: number = null,
		public propertyId: number = null,
		public siteId: number = null,
		public name: string = null,
		public synonyms: string[] = [],
		public city: string = null,
		public state: string = null,
		public latitude: number = null,
		public longitude: number = null,
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): SiteKey {
		this.copyPropertiesFromObject( item );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return null;
    }

	/**
	 * Convert an array of site keys to a generic object.
	 * @param siteKeys Site keys to convert.
	 */
	public static fromDataItemArray( siteKeys: [] ): SiteKey[] {
		let results: SiteKey[] = [];
		if (siteKeys) {
			siteKeys.forEach( siteKey => results.push( new SiteKey().fromDataItem( siteKey ) ) );
		}
		return results;
	}

	/**
	 * Convert an array of site keys to a generic object.
	 * @param siteKeys Site keys to convert.
	 */
	public static toDataItemArray( siteKeys: SiteKey[] ): any {
		let results = [];
		siteKeys.forEach( siteKey => results.push( siteKey.toDataItem() ) );
		return results;
	}

	/**
	 * @returns True if the site name matehces the state name.
	 */
	isStateSite(): boolean {
		let isStateSite = this.state && this.name.toLowerCase() === this.state.toLowerCase();
		// Global.log( 'isCitySite returned '+isCitySite+' for key '+this.name+' in city '+this.city+', '+this.state );
		return isStateSite;
	}

	/**
	 * @returns True if the site has a city and state and the site name is the city and state names separated by a space.
	 */
	isCitySite(): boolean {
		let isCitySite = this.city && this.state && this.name.toLowerCase() === (this.city.toLowerCase() + ' ' + this.state.toLowerCase());
		// Global.log( 'isCitySite returned '+isCitySite+' for key '+this.name+' in city '+this.city+', '+this.state );
		return isCitySite;
	}

	/**
	 * Set this objects properties from the given site.
	 * @param site Site
	 */
	fromSite( site: Site ): SiteKey {
		this.companyId = site.companyId;
		this.propertyId = site.propertyId;
		this.siteId = site.siteId;
		this.name = site.name;
		this.synonyms = site.synonyms;
		this.city = site.city;
		this.state = site.state;
		this.latitude = site.latitude;
		this.longitude = site.longitude;
		return this;
	}

	/**
	 * Returns true if the given name matches the name of this site or any of its synonyms.
	 * @param name Name to check.
	 */
	public hasNameOrSynonym( name: string ): boolean {
		let valid = false;
		name = name.toLowerCase();
		if (this.name.toLowerCase() == name) {
			valid = true;
		} else {
			valid = Global.findStringInArrayCaseInsensitive( name, this.synonyms ) != -1;
		}
		return valid;
	}

	/**
	 * @param name Name to check.
	 * @returns true if the given name matches the city, the state or the city and state.
	 */
	public matchesCityOrCityAndState( name: string ): boolean {
		let valid = false;
		if (name) {
			name = name.toLowerCase();
			if (this.city && this.state) {
				valid = name === (this.city + ' ' + this.state).toLowerCase();
			}
			if (this.city && !valid) {
				valid = name === this.city.toLowerCase();
			}
			// if (this.state && !valid) {
			// 	valid = name === this.state.toLowerCase();
			// }
		}
		return valid;
	}

	public matchesCityAndState( otherKey: SiteKey ): boolean {
		// Global.log('matchesCityAndState this.city='+this.city
		// 	+', this.state='+this.state
		// 	+', otherKey.city='+otherKey.city
		// 	+', otherKey.state='+otherKey.state
		// 	+', this.city.toLowerCase() === otherKey.city.toLowerCase()='+(this.city.toLowerCase() === otherKey.city.toLowerCase())
		// 	+', this.state.toLowerCase() === otherKey.state.toLowerCase()='+(this.state.toLowerCase() === otherKey.state.toLowerCase())
		// );
		return this.city
			&& this.state
			&& otherKey.city
			&& otherKey.state
			&& this.city.toLowerCase() === otherKey.city.toLowerCase()
			&& this.state.toLowerCase() === otherKey.state.toLowerCase();
	}

	public static 
}