import { TableDocumentObject } from './TableHandler'

/**
 * Properties stored about each identified person.
 */
export class Person extends TableDocumentObject {

	/**
	 * Properties stored about each identified person.
	 * @param companyId ID of company where person was detected. (partition key = companyId_collectionId)
	 * @param collectionId ID of collection containing matching faceID.
	 * 			Recognize uses a collection for each user, Analytics uses a collection for each site.
	 * @param personId ID of person, assigned the first time their face is detected. (sort key)
	 * @param cardHeading Heading displayed on person's card when identified.
	 * @param cardText Text displayed on person's card when identified.
	 */
	constructor(
		public companyId: number = null,
		public collectionId: string = null,
		public personId: string = null,
		public cardHeading: string = null,
		public cardText: string = null,
		public points: number = 0
	) { super(); }

    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): Person {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		
		// Add key properties made up of multiple object properties
		item['companyId_collectionId'] = this.companyId + '_' + this.collectionId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            'companyId_collectionId': this.companyId + '_' + this.collectionId, 
            'personId': this.personId
        }
    }

}
