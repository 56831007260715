import { Command } from "./Command";
import { Site } from "../service/Site";
import { RoomUsage } from "../service/RoomUsage";

export class GenerateTestRoomUsageDataCmd extends Command {

	/**
	 * Returns a map of distinct room numbers and the number of times that room number was used keyed by date between the given start and end date.
	 * @param companyId ID of the company
	 * @param propertyId ID of the property
	 */
	do( companyId: number, propertyId: number ): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId } )
			.then( results => {
				resolve();
			}).catch( err => reject( err ) );
		});
	}

}
