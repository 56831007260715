/**
 * First component that's called when the app loads.
 */
import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit} from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";
import { AWSRequestor } from './service/AWSRequestor'
import {UserLoginService} from "./service/user-login.service";
import {CognitoUtil} from "./service/cognito.service";
import { Global } from './service/Global';
import { PurchaseDetails } from './service/PurchaseDetails';
import {CacheService} from './service/cache.service';
import { Product } from './service/Product';
import { version } from './service/Version';
import { ConfirmComponent } from "./secure/confirm/confirm.component";

@Component({
    selector: 'app-root',
    template: '<confirm></confirm><router-outlet></router-outlet>',
    styleUrls: ['./app.css'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild(ConfirmComponent) public confirmComponent: ConfirmComponent;

    constructor(
		private router: Router,
		private userService: UserLoginService, 
		private cognitoUtil: CognitoUtil,
		private cache: CacheService,
	) {
		// console.log("AppComponent: constructor");
		this.cache.appComponent = this;
		Global.isRunningInBrowser = true;
		AWSRequestor.cognitoUtil = cognitoUtil;
		let bookcliffDeviceId = localStorage.getItem('bookcliffDeviceId');
		console.log( 'bookcliffDeviceId='+bookcliffDeviceId );
		if (!bookcliffDeviceId) {
			bookcliffDeviceId = 'Chris Mac';
			localStorage.setItem( 'bookcliffDeviceId', bookcliffDeviceId );
		}

		let now = new Date();
		Global.setRemoteLogger( 'guest' );
		this.cognitoUtil.configureAWSLoggingOnly();
		Global.log('Guest viewed site version '+version+' with browser '+JSON.stringify( Global.getBrowserInfo(), null, 2 ) );

		// Since we are starting a new browser session, make sure user's Cognito session is logged
		// out so other users can't use their credentials by browsing to a secure page.
		this.userService.logout();

		// Send page view to Google Analytics and Google Ads
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				(<any>window).gtag('config', 'UA-117305543-1', {'page_path': event.urlAfterRedirects } ); // Notify Google Analytics
				(<any>window).gtag('config', 'AW-806834546', {'page_path': event.urlAfterRedirects } ); // Notify Google Ads
			}
		});
	  
    }

    ngOnInit() {
        console.log("AppComponent: Checking if the user is logged in");
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
		this.userService.isAuthenticated( (message,loggedIn) => {
			if (loggedIn) {
				// We are just starting the app and the user is already logged in.
				// If token is expired or will expire within 30 seconds then force user to log in again
				// return this.cache.currentUserTokenExpiration.getTime() - Date.now() < 30000;
			}
			return false;
		});
    }

	ngAfterViewInit() { 
		this.cache.confirmComponent = this.confirmComponent;
	}

	ngOnDestroy() {
		// Log user out when they close the browser window so they have to log in when they open it later
		this.userService.logout();
	}

	public goToDefaultSecureHomePage() {
		if (this.cache.currentCompany.hasOneOfTheseProducts( [Product.ROOM_GENIE, Product.ROOM_GENIE_ADVERTISER, Product.WHATS_HAPPENING] )) {
			this.router.navigate(['/securehome/room-genie-main']);
		} else {
			// User doesn't have any products, take them to subscription page
			this.router.navigate(['/securehome/product-subscribe']);
		}
	}

	public subscribeToProduct( product: Product ) {
		// Set up purchase details
		this.cache.purchaseDetails = new PurchaseDetails( product, new Date().getUTCDate(), Global.getNextBillingDate( new Date(), new Date().getUTCDate() ) );
		this.cache.sendEventToAnalytics( 'Subscribe', this.cache.purchaseDetails.product.name, 'click subscribe' );
		if (this.cache.currentUserId) {
			// We are already logged in so go to checkout
			this.router.navigate(['/signuphome/checkout']);
		} else {
			// Create new account
			this.router.navigate(['/signuphome/create-account-describe']);
		}
	}

}

