import { Site } from './Site';

/**
 * List of sites and active hours indexes for each site.
 */
export class ActiveSeatings {
	
	/** List of sites that have a block of open hours with available capacity for reservations. */
	public sites: Site[] = [];

	/** Indexes of the blocks of open hours found in each site with available capacity for reservations. */
	public hoursIndexes: number[] = [];
	
}

