import { Global } from './Global';
import { ConfirmComponent } from "../secure/confirm/confirm.component";

/**
 * Base class for editor list components.
 */
export abstract class EditorDetail<T> {
    editedRow: T = this.createNewDefaultRow();
	originalRow: T;
	errorMessage: string = null;

	/** Called when user takes action including insert, update, delete, cancel, and error. */
	private actionCallback: (action: string, arg: any ) => void;
	
	/** True if this record will be inserted and the delete link is not shown. */
	protected isInsert: boolean;

	protected modalButton: HTMLButtonElement;
	
	protected focusElement: HTMLInputElement;

	
	
	/** Called to get a new row which can be initialized to a copy of another row using row.fromDataItem(...) */
	abstract createNewDefaultRow(): T;

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	abstract detectChanges();

	/** Called when showModalDialog has been called and the originalRow and editedRow have been set. */
	abstract initializeDialog();

	/** Return a deep copy of the given object. */
	abstract clone( row: T ): T;

	/** Returns true if object is in a valid state and can be saved. */
	abstract isValid(): boolean;

	/** Returns confirmation dialog component to use. */
	abstract getConfirmComponent(): ConfirmComponent;

	/** Returns string to display to the user when confirming the deletion of a row. */
	abstract getDeleteConfirmation(): string;



	/**
	 * Displays modal dialog to edit object.
	 * @param row Object to edit.
	 * @return Promise with user action used to dismiss dialog (insert, update, delete, or cancel).
	 */
	public showModalDialog( row: T, isInsert: boolean, callback: (action: string, arg: any) => void ): void {
		this.errorMessage = null;
		this.isInsert = isInsert;
		this.actionCallback = callback;
		if (row == null) {
			this.actionCallback( 'error', new Error( 'No edit object provided.' ) );
		} else {
			// Make a copy of object so we can restore the original values if user cancels.
			this.originalRow = row;
			this.editedRow = this.clone( row );
			this.initializeDialog();
			// Show the dialog
			this.modalButton.click();
			this.detectChanges();
			// Put keyboard focus on heading field after dialog is displayed
			setTimeout( () => this.focusElement ? this.focusElement.focus() : null, 1000 );
		}
	}

	public hideDialog() {
		this.modalButton.click();
	}

	/** 
	 * Return a copy of the original row that was created when a row is passed in for editing.
	 * Can be used to get the old primary key values of the row to update. 
	 */
	public getOriginalData(): T {
		return this.originalRow;
	}

	/** 
	 * Return a copy of the original row that was passed in for editing which may have been updated by the user.
	 */
	public getEditedData(): T {
		return this.editedRow;
	}

	/** Close the dialog without saving changes. */
	private onCancel() {
		try {
			this.errorMessage = null;
			this.actionCallback( 'cancel', null );
		} catch( error ) { this.handleError( error ); }
	}

    private onSave() {
		try {
			this.errorMessage = null;
			if (this.isValid()) {
				// this.hideDialog();
				this.actionCallback( this.isInsert ? 'insert' : 'update', this.editedRow );
			}
		} catch( error ) { this.handleError( error ); }
	}

	private onDelete() {
		try {
			this.errorMessage = null;
			let confirmChildComponent = this.getConfirmComponent();
			if (confirmChildComponent) {
				confirmChildComponent.showModalDialog( this.getDeleteConfirmation(), 'Confirm Delete' )
				.then( confirmed => {
					if (confirmed) {
						// this.hideDialog();
						this.actionCallback( 'delete', this.originalRow );
					}
				})
				.catch( error => {
					Global.logError( 'Error in confirm delete dialog.', error );
					this.errorMessage = error.message;
				});
			} else {
				// Use browser confirm dialog
				if (confirm( this.getDeleteConfirmation() )) {
					// this.hideDialog();
					this.actionCallback( 'delete', this.originalRow );
				}
			}
		} catch( error ) { this.handleError( error ); }
	}

	handleError( error ) {
		Global.logError( 'Error handling event', error );
		this.errorMessage = "Sorry, there was a problem, please try again."
	}

	handleChildInsert( newRow: Object, rowList: Object[], detailComponent: EditorDetail<any>) {
		this.errorMessage = null;
		detailComponent.showModalDialog( newRow, true, (action, arg) => {
			if (action === 'insert') {
				// User saved changes, add to the list
				detailComponent.hideDialog();
				rowList.push( detailComponent.getEditedData() );
				this.detectChanges();
			} else if (action == 'cancel') {
				detailComponent.hideDialog();
			} else if (action == 'error') {
				detailComponent.hideDialog();
				Global.logError( 'Error editing new data', arg );
				this.errorMessage = arg.message;
			}
		})
	}

	handleChildEdit( index: number, rowList: Object[], detailComponent: EditorDetail<any> ) {
		this.errorMessage = null;
		// console.log( 'before edit, rows='+JSON.stringify(rowList,null,2));
		detailComponent.showModalDialog( rowList[index], false, (action, arg) => {
			if (action === 'update') {
				// User saved changes, update the list
				detailComponent.hideDialog();
				rowList[index] = detailComponent.getEditedData();
				// console.log( 'after edit, rows='+JSON.stringify(rowList,null,2));
				this.detectChanges();
			} else if (action === 'delete') {
				// User chose to delete the row
				detailComponent.hideDialog();
				rowList.splice( index, 1 );
				this.detectChanges();
			} else if (action == 'cancel') {
				detailComponent.hideDialog();
			} else if (action == 'error') {
				detailComponent.hideDialog();
				Global.logError( 'Error editing data', arg );
				this.errorMessage = arg.message;
			}
		});
	}

}