import { TableHandler, TableDocumentObject } from './TableHandler'
import {Global } from './Global';

/**
 * Class manages the FaceTrackerPersonFace table in DynamoDB.
 * 
 * A Person has one or more face ID's detected by Rekognition's indexFaces function and matched
 * with the searchFaces function.  If a face in detected but doesn't match any other faces in the
 * collection then a new Person is added to the table with the same ID as the face ID.  Later,
 * when searchFaces finds a match with the same PersonID then the matching face ID is stored in the
 * Person table with the PersonID.
 * 
 * To help manage the size of the AWS face collections, the system can delete the person records
 * for the faceId's with the lowest confidence.  For example, if we got the following 5 pictures
 * of a person:
 * 
 * faceId      matchingFaceId   confidence
 * 1            1               99% (this is the first picture we got of the person, confidence is that a face was detected)
 * 2            1               85% (after the first picture, confidence is how likely faceId and matchingFaceId are the same person)
 * 3            2               95%
 * 4            2               91%
 * 5            4               98%
 * 
 * Nothing matched picture 3 and the only match on picture 1 was 85% so we could delete faceId 1
 * and 3 from the face collection and still have a good chance of matching that person later.
 * The system could be tuned to keep the top N pictures of each person to maximize the chances
 * of a valid match but minimimize the number of faces saved for each unique person.
 * 
 * Every time a new faceId is added to a Person that makes the total number of faceId's for that
 * person exceed the threshold, delete the face ID with no matches (other than the one just added)
 * or the lowest confidence match.
 * 
 * Partition key is: companyId + '_' + collectionId
 * Sort key is: faceId.
 * Secondary local index sort key: personId (used to purge low-confidence face matches for a person)
 * 
 */
export class PersonFace extends TableDocumentObject {

	/**
	 * Create object.
	 * @param companyId ID of company where person was recognized.
	 * @param collectionId ID of collection containing matching faceID.
	 * 			Recognize uses a collection for each user, Analytics uses a collection for each site.
	 * @param faceId ID of face in face collection.
	 * @param personId Person ID
	 * @param matchingFaceId The face ID that was the best match for the face ID passed to searchFaces.
	 * @param confidence Confidence that faceId was the same person as matchingFaceId, or if faceId==matchingFaceId, then the confidence that a face was detected in the image.
	 * @param imageName S3 object key.
	 */
    constructor( 
        public companyId: number = null,
        public collectionId: string = null,
        public faceId: string = null,
        public personId: string = null,
        public matchingFaceId: string = null,
		public confidence: number = 0,
		public imageName: string = null
	)
    {  super(); }

	public fromDataItem( item: any ): PersonFace {
		return this.copyPropertiesFromObject( item );
	}


	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		
		// Add key properties made up of multiple object properties
		item['companyId_collectionId'] = this.companyId + '_' + this.collectionId;
		
		// Add object properties translating Date properties to ISO strings
		this.copyPropertiesToObject( item );
		return item;
	}

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "companyId_collectionId": this.companyId + '_' + this.collectionId, 
			"faceId": this.faceId
        }
    }

}

export class PersonFaceTable extends TableHandler<PersonFace> {

    /** Local secondary index using personId as the sort key. */
    private personIndexName: string = 'companyId_collectionId-personId-index';

    public constructor() {
		super( 'bookcliffsoftware-PersonFace' );
    }

	public fromDataItem( item: any ): PersonFace {
		return new PersonFace().fromDataItem( item );
	}

    /**
     * Get list of low-confidence face matches (other than the one passed as a parameter) so they
     * cam be purged from the face collection and PersonFace table to prevent storing too many face
     * matches for each person which helps us stay within AWS limits of 1,000,000 faces per collection.
     * We keep the best N face matches for future recognitions.  A face is considered better than
     * another face if it has more matches or if it has the same number of matches but higher confidence.
     * 
     * The table is queried with the secondary local index that uses the personId as the sort key.
     * 
     * @param ignoreFace Person face record to ignore because we just added it to the table.
     */
    public getLowConfidenceMatches( ignoreFace: PersonFace ): Promise<PersonFace[]> {
        const maxNumberOfFacesToKeepPerPerson = 5;
        let faces: PersonFace[] = [];
        let matchCounts: number[] = [];
        let numberOfFacesPurged = 0;
        let purgedFaces: PersonFace[] = [];
    	return new Promise( (resolve, reject) => {
            // console.log( 'PersonFaceTable.getLowConfidenceMatches( ignoreFace=' + JSON.stringify( ignoreFace, null, 2 ) + ' )' );
			let keyConditionExpression = "companyId_collectionId = :companyId_collectionId and personId = :personId";
			let expressionAttributeValues = {
				":companyId_collectionId": ignoreFace.companyId + '_' + ignoreFace.collectionId,
				":personId": ignoreFace.personId
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.personIndexName )
			.then( personFaces => {
				if (personFaces.length > maxNumberOfFacesToKeepPerPerson) {

					// Put all faces except the one to ignore in faces array
					personFaces.forEach( item => {
						// let person = this.createFromDataItem( item );
						let person = new PersonFace().fromDataItem( item );
						if (person.faceId != ignoreFace.faceId) {
							faces.push( person );
							matchCounts.push( 0 );
						}
					});

					// Show faces we found in console log
					// let list='';
					// for (let i = 0; i < faces.length; i++) {
					// 	list+= '\nfaceId=' + faces[i].faceId + ', matchingFaceId=' + faces[i].matchingFaceId + ', personId=' + faces[i].personId;
					// }
					// console.log( 'getLowConfidenceMatches found faces: ' + list );
					
					
					// For each face, loop thru faces to see how many times it was matched
					for (let i = 0; i < faces.length; i++) {
						let face = faces[i];
						for (let j = 0; j < faces.length; j++) {
							if (j != i) {
								let checkFace = faces[j];
								if (checkFace.matchingFaceId == face.faceId) {
									matchCounts[i]++;
								}
							}
						}

						// While this face has more matches than the previous face, swap them
						// so that the array will be sorted in order of the face quality
						let index = i;
						while (index > 0 && (matchCounts[index] > matchCounts[index-1] || ( matchCounts[index] == matchCounts[index-1] && faces[index].confidence > faces[index].confidence ))) {
							// Swap this face with previous face
							// console.log( 'Swapping face ' + index + ' with matchCount=' + matchCounts[index] + ' and confidence=' + faces[index].confidence + ' with face ' + (index-1) + ' with matchCount=' + matchCounts[index-1] + ' and confidence=' + faces[index-1].confidence );
							let tempFace = faces[index-1];
							let tempMatchCount = matchCounts[index-1];
							faces[index-1] = faces[index];
							matchCounts[index-1] = matchCounts[index];
							faces[index] = tempFace;
							matchCounts[index] = tempMatchCount;
							index--;
						}

					}
					
					// Show sorted faces in console log
					// list='';
					// for (let i = 0; i < faces.length; i++) {
					// 	list+= '\nface: ' + faces[i].faceId + ', ' + matchCounts[i] + ' matches.';
					// }
					// console.log( 'getLowConfidenceMatches sorted faces: ' + list );
					
					// Purge faces beyond the number we want to keep
					for (let i = maxNumberOfFacesToKeepPerPerson-1; i < faces.length; i++) {
						// console.log( 'Found purgable face: ' + faces[i].faceId + ', ' + matchCounts[i] + ' matches.' );
						purgedFaces.push( faces[i] );
					}
					
				} else {
					// console.log( personFaces.length + ' PersonFace records found for company ' + ignoreFace.companyId + ' collection ' + ignoreFace.collectionId + ' person ' + ignoreFace.personId + '.  No purge needed.' );
				}
				resolve( purgedFaces );
			})
			.catch( err => reject( new Error( 'Error querying person face records during purge: ' + err ) ) );
		});
    }
	
	deleteAllCollectionDocs( companyId: number, collectionId: string ): Promise<number> {
    	return new Promise<number>( (resolve, reject) => {
			Global.log( 'Delete all PersonFace documents for company '+companyId+' collection '+collectionId );
			let keyConditionExpression = "companyId_collectionId = :companyId_collectionId";
			let expressionAttributeValues = {
				":companyId_collectionId": companyId + '_' + collectionId,
			};
			this.deleteAll( keyConditionExpression, expressionAttributeValues )
			.then( deleteCount => {
				Global.log( 'Deleted ' + deleteCount + ' PersonFace documents for company '+companyId+' collection '+collectionId );
				resolve( deleteCount ) 
			})
			.catch( error => reject( error ) );
		});
	}

}
