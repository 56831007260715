// import * as AWS from "aws-sdk/global";
import * as SES from "aws-sdk/clients/ses";
import { Global } from "./Global";
import { AWSRequestor } from './AWSRequestor';

export class Emailer {

	/**
	 * Sends email to the given address.
	 * @param toAddress Email address to which email is sent.
	 * @param subject Email subject line.
	 * @param text Text version of message.
	 * @param html HTML version of message.
	 * @param fromAddress Email address from which email is sent.
	 * @returns Message ID for the email if successful else null (does not reject promise on error, just resolves null).
	 */
	sendEmail( toAddress: string, subject: string, text: string, html: string, fromAddress: string ): Promise<string> {
		return new Promise<string>( (resolve,reject) => {
			var params = {
				Destination: {
					BccAddresses: [], 
					CcAddresses: [], 
					// ToAddresses: ["cneimeister@gmail.com"]
					ToAddresses: [toAddress]
				}, 
				Message: {
					Body: {
						// Html: { Charset: "UTF-8", Data: "This message body contains HTML formatting. It can, for example, contain links like this one: <a class=\"ulink\" href=\"http://docs.aws.amazon.com/ses/latest/DeveloperGuide\" target=\"_blank\">Amazon SES Developer Guide</a>." }, 
						// Text: { Charset: "UTF-8", Data: "This is the message body in text format." }
						Html: { Charset: "UTF-8", Data: html }, 
						Text: { Charset: "UTF-8", Data: text }
					}, 
					// Subject: { Charset: "UTF-8", Data: "Test email" }
					Subject: { Charset: "UTF-8", Data: subject }
				}, 
				// ReplyToAddresses: [], 
				// ReturnPath: "", 
				// ReturnPathArn: "", 
				// Source: "support@bookcliffsoftware.com", 
				Source: fromAddress, 
				// SourceArn: ""
			};
			new AWSRequestor().send( new SES().sendEmail( params ) )
			.then( data => resolve( data.MessageId ) )
			.catch( error => {
				Global.logError( 'Error sending email with params: '+JSON.stringify(params,null,2), error );
				resolve( null );
			});
			// new SES().sendEmail( params, (error, data) => {
			// 	if (error) {
			// 		Global.logError( 'Error sending email with params: '+JSON.stringify(params,null,2), error );
			// 		resolve( null );
			// 	} else {
			// 		Global.log('Email subject ['+subject+'] sent to '+toAddress+', response: '+JSON.stringify(data,null,2));
			// 		resolve( data.MessageId );
			// 	}
			// });	
		});
	}

}


