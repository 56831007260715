import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { SiteComponent } from "../site/site.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Site } from '../../service/Site';
import { Editor } from '../../service/Editor';
import { Property } from '../../service/Property';
import { GetSitesCmd } from '../../command/GetSitesCmd';
import { PutSiteCmd } from '../../command/PutSiteCmd';
import { DeleteSiteCmd } from '../../command/DeleteSiteCmd';

export class Attribute {
	constructor(
    	public name: string,
		public value: string
	) {}
}

@Component({
    selector: 'sites',
    templateUrl: './sites.html',
	styleUrls: ['./sites.css'],
})
export class SitesComponent extends Editor<Site> implements OnInit, AfterViewInit {

	/** List of properties of the current company. */
	private properties: Property[] = [];

	/** Currently selected property. */
	private propertyId;

	/** List of sites that can be edited. */
	// private sites: Site[] = [];

	@ViewChild(SiteComponent) private detailComponent: SiteComponent;
	
	constructor(
		private userService: UserLoginService, 
		private cache: CacheService,
		private changeDetectorRef: ChangeDetectorRef,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Sites' );
	}

	initialize() {
		this.properties = this.cache.getProperties();
		if (this.properties.length > 0) {
			// Set selected property to the first one in the list
			this.propertyId = this.properties[0].propertyId;
		}
		// console.log("this.properties="+JSON.stringify(this.properties,null,2));
		this.changeDetectorRef.detectChanges();
		this.switchProperty( null );

		// Get list of sites
		// this.siteTable.getAll( this.cache.currentCompany.companyId )
		// .then( foundSites => this.sites = foundSites )
		// .catch( error => {
		// 	Global.logError('Error getting sites.', error );
		// 	this.errorMessage = 'Sorry, we had a problem getting the site list.  Please try again.';
		// })
	}

	private switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		// Get timeZone offset for the property
		// this.timeZone = this.cache.getTimeZone( this.propertyId );
		// this.detailComponent.timeZone = this.timeZone;
		this.loadRows();
	}

	getDetailComponent(): SiteComponent {
		return this.detailComponent;
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	getRowLoader(): Promise<Site[]> {
		// console.log( "Getting site list for company "+this.cache.currentCompany.companyId+', and property '+ this.propertyId );
		return new GetSitesCmd().do( this.cache.currentCompany.companyId, this.propertyId );
	}

	createNewRowToEdit(): Site {
		// Calculate next available site ID to use as default
		let nextAvailableSiteId = 1;
		this.rows.forEach( site => {
			if (site.siteId >= nextAvailableSiteId) {
				nextAvailableSiteId = site.siteId+1;
			}
		});
		return new Site( this.cache.currentCompany.companyId, this.propertyId, nextAvailableSiteId, null, null, null, null, null, null, null, this.cache.getTimeZone( this.propertyId ) );
	}

	getRowInserter( row: Site ): Promise<Site> {
		return new PutSiteCmd().do( row )
	}

	getRowUpdater( index: number, row: Site ): Promise<Site> {
		return new PutSiteCmd().do( row )
	}

	getRowDeleter( index: number, row: Site ): Promise<void> {
		return new DeleteSiteCmd().do( row )
	}

	prepareRowToInsert( newRow: Site ) {
		// let highestId = null;
		// this.rows.forEach( row => {
		// 	if ((!highestId) || row.siteId > highestId) {
		// 		highestId = row.siteId;
		// 	}
		// })
		// newRow.siteId = highestId + 1;
	}
	
}
