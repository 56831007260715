import { TableDocumentObject } from './TableHandler'
import { Role } from './Role';

/**
 * Manages the FaceTrackerUserCompany table in DynamoDB.
 */
export class UserCompany extends TableDocumentObject {

	/**
	 * @param userId Cognito ID of the user from the 'sub' property (partition key)
	 * @param companyId ID of company (sort key)
	 * @param name User's name
	 * @param email User's email address
	 * @param roleIds List of role ID's assigned to user (0 = company administrator)
	 */
	constructor(
		public userId: string = null,
		public companyId: number = null,
		public name: string = null,
		public email: string = null,
		public roleIds: number[] = [],
    ) { super(); }
    
    /** @return Object created from a data item that came from DynamoDb in the Item property. */
    fromDataItem( item: any ): UserCompany {
		this.copyPropertiesFromObject( item );
		// console.log( this.constructor.name + '.fromDataItem: ' + JSON.stringify( this, null, 2 ) );
        return this;
    }

	/** @return Data item created from object that is ready to put in DynamoDB table. */
	toDataItem(): any {
		let item = new Object();
		this.copyPropertiesToObject( item );
		// console.log( this.constructor.name + '.toDataItem: ' + JSON.stringify( item, null, 2 ) );
		return item;
    }

	/** @return Object containing key values used to get a record from the table. */
    getKey(): any {
        return {
            "userId": this.userId, 
            "companyId": this.companyId
        };
    }

	/**
	 * Find the user company with the given company ID in the given list of user companies
	 * @param companyId Company ID to find.
	 * @param userCompanies List of user companies to check.
	 * @returns User company from the list that has the given company ID if found, null if company ID was not found.
	 */
	static getUserCompany( companyId: number, userCompanies: UserCompany[] ): UserCompany {
		// Make sure company id from device is one of the valid companies for this user
		let found: UserCompany = null;
		if (userCompanies != null) {
			for (let i=0; i<userCompanies.length; i++) {
				if (userCompanies[i].companyId == companyId) {
					found = userCompanies[i];
					break;
				}
			}
		}
		return found;
	}

	hasSysAdminRole() {
		return this.roleIds.indexOf( Role.SYSADMINID ) != -1;
	}

	hasCompanyAdminRole() {
		return this.roleIds.indexOf( Role.COMPANYADMINID ) != -1;
	}

}
