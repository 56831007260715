import { TableHandler } from './TableHandler'
import { Company } from "./Company";
import { CompanyTable } from "./CompanyTable";
import { Emailer } from "./Emailer";
import { GetUserDataResponse } from './GetUserDataResponse';
import { Global } from "./Global";
import { Notifier } from "./Notifier";
import { Property } from "./Property";
import { PropertyTable } from "./PropertyTable";
import { Role } from "./Role";
import { SystemTable } from "./SystemTable";
import { UserCompany } from './UserCompany'

export class UserCompanyTable extends TableHandler<UserCompany> {

    /** Global secondary index using companyId as the partition key. */
    private secondaryIndexName: string = "companyId-index";

    public constructor() {
		super( 'bookcliffsoftware-UserCompany' );
	}
	
	public fromDataItem( item: any ): UserCompany {
		return new UserCompany().fromDataItem( item );
	}

    /**
     * Get all the companies associated with the given user ID.  This does not return all companies if the user is a sysAdmin
     * @param userId Cognito ID of the user from the sub property.
     */
    getUserCompanies( userId: string ): Promise<UserCompany[]> {
    	return new Promise( (resolve, reject) => {
			let keyConditionExpression = "userId = :userId";
			let expressionAttributeValues = {
				":userId": userId,
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues )
			.then( results => {
				resolve( results );
			})
			.catch( err => {
				reject( err );
			})
        });
    }

    /**
     * Get the record for the given key values.
     * @param companyId ID of the company.
     * @param faceId FaceId of employee..
     */
    getUsers( companyId: number ): Promise<UserCompany[]> {
    	return new Promise( (resolve, reject) => {
			let users: UserCompany[] = [];
			let keyConditionExpression = "companyId = :companyId";
			let expressionAttributeValues = {
				":companyId": companyId
			};
			this.queryAll( keyConditionExpression, null, expressionAttributeValues, null, this.secondaryIndexName )
			.then( rows => resolve( rows ) )
			.catch( err => reject( err ) )
        });
	}

	/**
	 * Get company and permission data for the given user ID.
	 * @param userId User ID
	 * @param nickname User name.
	 * @param email User's email address.
	 */
	getUserData( userId: string, nickname: string, email: string, selectedCompanyId: number ): Promise<GetUserDataResponse> {
		return new Promise<GetUserDataResponse>( (resolve, reject) => {
			console.log('getUserData( userId='+userId+', nickname='+nickname+', email='+email+', selectedCompanyId='+selectedCompanyId );
			// Get the list of companies the user can access
			this.getUserCompanies( userId )
			.then( (userCompanies: UserCompany[]) => {
				if (userCompanies && userCompanies.length > 0) {
					if (this.hasSysAdminRole( userCompanies )) {
						// USER IS A SYTEM ADMINISTRATOR, ignore the presence of a UserCompany record.
						if (selectedCompanyId != null) {
							// User has already authenticated and selected a company, get the selected user company
							this.loadCompanyDataForUser( userId, email, selectedCompanyId, [Role.SYSADMINID] )
							.then( results => resolve( results ) )
							.catch( error => reject( error ) );
						} else {
							// User is sysAdmin but hasn't selected a company yet, return list of all companies so they can choose
							Global.log( 'getting all companies for sysAdmin');
							new CompanyTable().getAll()
							.then( companies => resolve( new GetUserDataResponse( companies, [], null ) ) )
							.catch( error => reject( error ) );
						}
					} else if (userCompanies.length > 1) {
						if (selectedCompanyId != null) {
							// User has already authenticated and selected a company, get the selected user company
							let userCompany = UserCompany.getUserCompany( selectedCompanyId, userCompanies );
							if (!userCompany) {
								reject( 'Error loading data for company '+selectedCompanyId+' for user '+userId+', no user company record found.' );
							} else {
								this.loadCompanyDataForUser( userId, email, selectedCompanyId, userCompany.roleIds )
								.then( results => resolve( results ) )
								.catch( error => reject( error ) );
							}
						} else {
							// User can access multiple companies, return list so they can choose
							Global.log( 'getting multiple companies for user with multiple userCompanies');
							this.getCompanies( userCompanies )
							.then( companies => resolve( new GetUserDataResponse( companies, [], null ) ) )
							.catch( error => reject( error ) );
						}
					} else {
						// The user can only access one company, get the user data for the company
						this.loadCompanyDataForUser( userCompanies[0].userId, userCompanies[0].email, userCompanies[0].companyId, userCompanies[0].roleIds )
						// let companyId = userCompanies[0].companyId;

						// // Set the logger to the company's log
						// Global.setRemoteLogger( 'company-'+companyId+'-commands' );
						// Global.log( 'Loading company data for user '+email);

						// new CompanyTable().loadCompanyData( userId, email, companyId, userCompanies[0].roleIds )
						.then( results => resolve( results ) )
						.catch( error => reject( error ) );
					}
				} else {
					// We didn't find a company for the user, this is probably their first login, create one
					this.createCompanyData( userId, nickname, email )
					.then( response => resolve( response ) )
					.catch( error => reject( error ) );
				}
			}).catch( err => {
				err.message = 'Error getting companies for user ' + userId + ' with email ' + email + '. ' + err.message;
				reject( err );
			});
		});
	}

	/** Load the company and role information for the given user company. */
	private loadCompanyDataForUser( userId: string, email: string, companyId: number, roleIds: number[] ): Promise<GetUserDataResponse> {
		return new Promise<GetUserDataResponse>( (resolve,reject) => {
			// Set the logger to the company's log
			Global.setRemoteLogger( 'company-'+companyId+'-commands' );
			Global.log( 'Loading company data for user '+email);

			new CompanyTable().loadCompanyData( userId, email, companyId, roleIds )
			.then( results => resolve( results ) )
			.catch( error => reject( error ) );
		})
	}

	/** Return true if the list of roles in any of the given userCompany records contain the sysAdmin ID. */
	hasSysAdminRole( userCompanies: UserCompany[] ): boolean {
		let foundSysAdmin = false;
		if (userCompanies && userCompanies.length > 0) {
			for (let i=0; i<userCompanies.length; i++) {
				if (userCompanies[i].hasSysAdminRole()) {
					foundSysAdmin = true;
					break;
				}
			}
		}
		return foundSysAdmin;
	}

	/**
	 * For the given user, return the list of companies for which they have the given permission.
	 * @param userId User ID
	 * @param email User's email address.
	 * @param permission Permission name.
	 */
	getCompaniesWithPermission( userId: string, email: string, permission: string ): Promise<Company[]> {
		return new Promise<Company[]>( (resolve, reject) => {
			// Get the list of companies the user can access
			let foundCompanies: Company[] = [];
			this.getUserCompanies( userId )
			.then( (userCompanies: UserCompany[]) => {
				if (userCompanies && userCompanies.length > 0) {
					if (this.hasSysAdminRole( userCompanies )) {
						// User is a SysAdmin, return list of all companies
						new CompanyTable().getAll()
						.then( companies => resolve( companies ) )
						.catch( error => reject( error ) );
					} else {
						// See which of the user companies the user can edit properties
						let promises: Promise<UserCompany>[] = [];
						userCompanies.forEach( userCompany => {
							// Global.log( 'User '+userId+' checking company '+userCompany.companyId+' for permission '+permission);
							promises.push( this.userCompanyHasPermission( userCompany, permission ) );
						})
						Promise.all( promises )
						.then( results => {
							// Create list of user companies that have permission to edit properties
							let userCompaniesWithPermission: UserCompany[] = [];
							results.forEach( userCompany => {
								if (userCompany) {
									// Global.log( 'User '+userId+' found company '+userCompany.companyId+' with permission '+permission);
									userCompaniesWithPermission.push( userCompany );
								}
							});

							// Load companies from user companies with permission 
							this.getCompanies( userCompaniesWithPermission )
							.then( companies => resolve( companies ) )
							.catch( error => reject( error ) );
						})
						.catch( err => reject( 'Error getting companies where user '+userId + ' with email ' + email + ' can edit properties.  No UserCompany records found.' ) );
					}
				} else {
					// We didn't find a company for the user unexpectedly
					reject( 'Error getting companies where user '+userId + ' with email ' + email + ' can edit properties.  No UserCompany records found.' );
				}
			}).catch( err => {
				err.message = 'Error getting companies for user ' + userId + ' with email ' + email + '. ' + err.message;
				reject( err );
			});
		});
	}

	private userCompanyHasPermission( userCompany: UserCompany, permission: string ): Promise<UserCompany> {
		return new Promise<UserCompany>( (resolve,reject) => {
			new CompanyTable().get( new Company( userCompany.companyId ) )
			.then( company => {
				let roles = company.loadRoles( userCompany.roleIds );
				if (roles) {
					let role = new Role().combineWithRoles( roles );
					if (role.hasPermission( permission )) {
						resolve( userCompany );
					} else {
						resolve( null );
					}
				} else {
					resolve( null );
				}
			}).catch( err => reject( err ) );
		});
	}

    /**
     * Returns list of companies the user can log into.  If user is sysAdmin, returns all companies.
     * @param userId Cognito ID of the user from the sub property.
     */
    getCompanies( userCompanies: UserCompany[] ): Promise<Company[]> {
    	return new Promise<Company[]>( (resolve, reject) => {
			// Load list of all companies for the list of userCompany rows found for the user
			let promises: Promise<Company>[] = [];
			userCompanies.forEach( userCompany => {
				promises.push( new CompanyTable().get( new Company( userCompany.companyId ) ) );
			});
			Promise.all( promises )
			.then( companies => resolve( companies ) )
			.catch( error => reject( error ) );
        });
    }

	/**
	 * Get list of roles either for editing or for assigning to users.
	 * For security, only roles that the currently logged-in user has will be returned.
	 * 
	 * @param userCompany User information for the logged-in user
	 */
	getRoleList( userCompany: UserCompany ): Promise<Role[]> {
		// Global.log( 'GetRoleListExec userCompany '+JSON.stringify(userCompany,null,2));
		return new Promise<Role[]>( (resolve,reject) => {
			new CompanyTable().get( new Company( userCompany.companyId ) )
			.then( company => {
				let roles = company.roles;
				let rolesThatCurrentUserHas: Role[] = [];
				roles.forEach( role => {
					if (userCompany.hasSysAdminRole() || 
						userCompany.hasCompanyAdminRole() || 
						userCompany.roleIds.indexOf( role.roleId ) != -1) 
					{
						// Current user has this role so add it
						rolesThatCurrentUserHas.push( role );
					}
				});
				// Global.log( 'GetRoleListExec resolve '+JSON.stringify(rolesThatCurrentUserHas,null,2));
				resolve( rolesThatCurrentUserHas );
			})
			.catch( error => reject( error ) );
		})
	}

	/**
	 * Create company, property, and role data for the given user who is the company administrator of the new company.
	 * @param userId User ID
	 * @param nickname Name user entered when they signed up
	 * @param email User's email address
	 */
	private createCompanyData( userId: string, nickname: string, email: string ): Promise<GetUserDataResponse> {
		return new Promise<GetUserDataResponse>( (resolve, reject) => {
			Global.log( 'Setting up company for new user: ' + userId + ' with email ' + email );
			// Get the next company ID
			new SystemTable().incrementLastUsedCompanyId()
			.then( system => {
				// Create the company record
				let company = new Company( system.lastUsedCompanyId, null, email );
				new CompanyTable().put( company )
				.then( () => {
					// Create the user-company record and give the user the company administrator role 0
					let userCompany = new UserCompany( userId, company.companyId, nickname, email, [0] );
					new UserCompanyTable().put( userCompany )
					.then( () => {
						let property = new Property( company.companyId, 1, 'Default' );
						new PropertyTable().put( property )
						.then( () => {
							let role = new Role().setCompanyAdmin();
							Global.log( 'Set up company ' + company.companyId + ' for new user: ' + userId + ' with email ' + email );
							let response = new GetUserDataResponse( [company], [property], role );
							let html = '<p>Welcome and thank you for registering with Bookcliff Software!</p>'
								+'<p>You can log in at <a href="https://bookcliffsoftware.com/home/login" target="_blank">https://bookcliffsoftware.com</a>.</p>'
								+'<p>Check out <a href="https://bookcliffsoftware.com/home/room-genie-intro" target="_blank">Room Genie</a>, the guest services app you can talk to!</p>'
								+'<p>We would love to hear from you so if you have any questions or comments about our products, please send us an email at <a href="mailto:support@bookcliffsoftware.com">support@bookcliffsoftware.com</a>.</p>'
								+'<p>Sincerely,<br>Team Bookcliff Software</p>'; 
							let text = 'Welcome and thank you for registering with Bookcliff Software!'
								+'\r\n\r\nYou can log in at https://bookcliffsoftware.com.\r\n\r\nCheck out Room Genie, the guest services app you can talk to!'
								+'\r\n\r\nWe would love to hear from you so if you have any questions or comments about our products, please send us an email at support@bookcliffsoftware.com.'
								+'\r\n\r\nSincerely,\r\nTeam Bookcliff Software'; 
							new Emailer().sendEmail( email, 'Bookcliff Software Registration', text, html, 'support@bookcliffsoftware.com' )
							.then( messageId => {
								this.sendRegistrationNotification( company.companyId, userId, nickname, email )
								.then( messageId => resolve( response ) );
							});
						})
						.catch( error => reject( new Error( Global.buildErrorMessage( 'Error adding property for new company ID ' + system.lastUsedCompanyId + ' for user ' + userId + ' with email ' + email, error ) ) ) );
					})
					.catch( error => reject( new Error( Global.buildErrorMessage( 'Error adding user for new company ID ' + system.lastUsedCompanyId + ' for user ' + userId + ' with email ' + email, error ) ) ) );
				})
				.catch( error => reject( new Error( Global.buildErrorMessage( 'Error adding new company ID ' + system.lastUsedCompanyId + ' for user ' + userId + ' with email ' + email, error ) ) ) );
			})
			.catch( error => reject( new Error( Global.buildErrorMessage( 'Error getting new company ID for user ' + userId + ' with email ' + email, error ) ) ) );
		});
	}

	private sendRegistrationNotification( companyId: number, userId: string, nickname: string, email: string ): Promise<string> {
		let notification = {
			notification: "New user registered",
			companyId: companyId,
			userId: userId,
			userName: nickname,
			email: email
		}
		return new Notifier().notify( JSON.stringify( notification ), Global.subscribeTopicArn );
	}

}
