import { Command } from "./Command";
import { SiteName } from "../service/SiteName";

export class GetAvailableAdvertisingSiteNamesCmd extends Command {

	/**
	 * Get list of advertisers that have paid ads with a radius that includes the given property.
	 * @param companyId Company ID
	 * @param propertyId Property ID
	 */
	do( companyId: number, propertyId: number, adCategoryId: number ): Promise<SiteName[]> {
		return new Promise<SiteName[]>( (resolve, reject) => {
			super.send( this.constructor.name, companyId, { "propertyId": propertyId, "adCategoryId": adCategoryId } )
			.then( results => {
				// Convert generic JSON result object back to real object
				let rows: SiteName[] = [];
				results.data.forEach( layout => rows.push( new SiteName().fromDataItem( layout ) ) );
				resolve( rows );
			}).catch( err => reject( err ) );
		});
	}

}
