import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { AdCategory } from "../../service/AdCategory";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { AdPlayByMonth } from "../../service/AdPlayByMonth";
import { Site } from '../../service/Site';
import { SiteName } from "../../service/SiteName";
import { GetSitesCmd } from "../../command/GetSitesCmd";
import { GetAdCategoriesCmd } from "../../command/GetAdCategoriesCmd";
import { GetAdPlaysByMonthForSiteCmd } from "../../command/GetAdPlaysByMonthForSiteCmd";

class YearMonth {
	constructor(
		public year: number,
		public month: number
	) {}

	getKey() {
		return this.year+','+this.month;
	}

	getDisplayString() {
		return Global.monthNames[this.month-1] + ' ' + this.year;
	}

}


@Component({
    selector: 'ad-plays',
    templateUrl: './ad-plays.html',
	styleUrls: ['./ad-plays.css'],
})
export class AdPlaysComponent implements OnInit, AfterViewInit {

	private errorMessage: string = null;

	private rows: AdPlayByMonth[] = [];

	/** Currently selected property. */
	private selectedPropertyId;

	private adCategories: AdCategory[] = [];

	/** Currently selected ad category. */
	private selectedAdCategoryId;

	/** List of all sites in the currently selected property. */
	private sites: Site[] = [];

	/** Currently selected site. */
	private site: Site = null;

	/** Currently selected site ID in dropdown. */
	private selectedSiteId;

	// Timezone for the property
	private timeZone;

	private adYear: number = 2017;

	private adMonth: number = 1;

	private siteNames: SiteName[] = [];

	private yearMonths: YearMonth[] = [];

	private selectedFromYearMonth: string = null;

	private selectedToYearMonth: string = null;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-chart-pie fa-fw"></i> Advertisements Played' );
	}

	initialize() {
		// Set selected property to the first one in the list
		this.selectedPropertyId = this.cache.getFirstPropertyId();
		new GetAdCategoriesCmd().do( this.cache.currentCompany.companyId )
		.then( adCategories => {
			// console.log( 'Got activityTypes '+JSON.stringify(activityTypes,null,2));
			this.adCategories = adCategories;
			this.selectedAdCategoryId = this.adCategories.length > 0 ? this.adCategories[0].adCategoryId.toString() : null;
			this.switchProperty( null );
		})
		.catch( error => {
			Global.logError('Error loading ad categories.', error );
			this.errorMessage = 'Sorry, we had a problem loading the ad categories.  Please try again.';
		})
	}

	handleError( error: any ) {
		Global.logError( 'Error handling event', error );
		this.errorMessage = "Sorry, there was a problem, please try again.";
		this.detectChanges();
	}

	private switchProperty( event ) {
		this.selectedPropertyId = Number.parseInt( this.selectedPropertyId );
		try {
			this.timeZone = this.cache.getTimeZone( this.selectedPropertyId );
			let localTime = Global.adjustDateByTimeZone( new Date(), this.timeZone );
			this.adYear = localTime.getUTCFullYear();
			this.adMonth = localTime.getUTCMonth() + 1;
			this.selectedFromYearMonth = new YearMonth( this.adYear, this.adMonth ).getKey();
			this.selectedToYearMonth = new YearMonth( this.adYear, this.adMonth ).getKey();
			for (let year=this.adYear-1; year<=this.adYear+1; year++) {
				for (let month=1; month<=12; month++) {
					this.yearMonths.push( new YearMonth( year, month ) );
				}
			}
			this.loadSites();
		} catch( error ) { this.handleError( error ); }
	}

	private loadSites() {
		this.sites = [];
		this.site = null;
		this.selectedSiteId = null;
		new GetSitesCmd().do( this.cache.currentCompany.companyId, this.selectedPropertyId )
		.then( rows => {
			// Filter out sites that don't have hours and a layout since they can't accept reservations
			this.sites = [];
			rows.forEach( site => {
				this.sites.push( site );
			});

			if (this.sites.length > 0) {
				this.selectedSiteId = this.sites[0].siteId;
			}
			this.switchSite( null );
		})
	}

	private switchSite( event ) {
		try {
			this.errorMessage = null;
			// Set the current restaurant from the selected restaurant ID
			this.site = null;
			for (let i=0; i<this.sites.length; i++) {
				if (this.sites[i].siteId == this.selectedSiteId) {
					this.site = this.sites[i];
					break;
				}
			}
			this.switchAdCategory( null );
		} catch( error ) { this.handleError( error ); }
	}

	private switchAdCategory( event ) {
		try {
			this.switchYearMonth( null );
		} catch( error ) { this.handleError( error ); }
	}

	switchYearMonth( event ) {
		try {
			// console.log('switchSignUpList');
			this.errorMessage = null;
			let parts = this.selectedFromYearMonth.split(',');
			this.adYear = Number.parseInt( parts[0] );
			this.adMonth = Number.parseInt( parts[1] );
			this.detectChanges();
			this.loadRows()
			.then( rows => this.detectChanges() )
			.catch( error => this.handleError( error ) );
		} catch( error ) { this.handleError( error ); }
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	loadRows(): Promise<void> {
		return new Promise<void>( (resolve,reject) => {
			try {
				new GetAdPlaysByMonthForSiteCmd().do( this.cache.currentCompany.companyId, this.selectedPropertyId, this.selectedSiteId, this.adYear, this.adMonth, this.adYear, this.adMonth )
				.then( rows => {
					this.rows = rows;
				})
				.catch( error => {
					Global.logError('Error loading data.', error );
					this.errorMessage = 'Sorry, we had a problem loading the ad play data.  Please try again.';
					reject( error );
				})
			} catch( error ) {
				reject( error );
			}
		});
	}

	// getSiteName( advertiser: Advertiser ): SiteName {
	// 	let found: SiteName = null;
	// 	for (let i=0; i<this.siteNames.length; i++) {
	// 		let siteName = this.siteNames[i];
	// 		if (siteName.companyId == advertiser.companyId && siteName.propertyId == advertiser.propertyId && siteName.siteId == advertiser.siteId) {
	// 			found = siteName;
	// 			break;
	// 		}
	// 	}
	// 	return found;
	// }

	// getSiteIdAndName( advertiser: Advertiser ): string {
	// 	let s: string = null;
	// 	let siteName = this.getSiteName( advertiser );
	// 	if (siteName != null) {
	// 		s = siteName.siteId + ' - ' + siteName.siteName;
	// 	} else {
	// 		s = '' + advertiser.siteId;
	// 	}
	// 	return s;
	// }

	// getCompanyName( advertiser: Advertiser ): string {
	// 	let s = 'Company: ' + advertiser.companyId;
	// 	let siteName = this.getSiteName( advertiser );
	// 	if (siteName != null) {
	// 		s += ' - ' + siteName.companyName;
	// 	}
	// 	return s;
	// }

	// getPropertyName( advertiser: Advertiser ): string {
	// 	let s = 'Property: ' + advertiser.propertyId;
	// 	let siteName = this.getSiteName( advertiser );
	// 	if (siteName != null) {
	// 		s += ' - ' + siteName.propertyName;
	// 	}
	// 	return s;
	// }

}
