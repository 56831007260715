import { Command } from "./Command";
import { ActivityType } from "../service/ActivityType";

export class PutActivityTypeCmd extends Command {

	/**
	 * Save a row.
	 * @param row Data to save.
	 */
	public do( row: ActivityType ): Promise<ActivityType> {
		// return this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } );
		return new Promise<ActivityType>( (resolve,reject) => {
			this.send( this.constructor.name, row.companyId, { "row": row.toDataItem() } )
			.then( results => resolve( new ActivityType().fromDataItem( results.data ) ) )
			.catch( error => reject( error ) );
		});
	}

}
