import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserLoginService} from "../../service/user-login.service";
import { TitleNavComponent } from "../title-nav/title-nav.component";
import { ActivityComponent } from "../activity/activity.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { CacheService } from "../../service/cache.service";
import { Global } from "../../service/Global";
import { Activity } from '../../service/Activity';
import { ActivityType } from '../../service/ActivityType';
import { GetAllActivityTypesCmd } from '../../command/GetAllActivityTypesCmd';
import { GetAllActivitiesCmd } from '../../command/GetAllActivitiesCmd';
import { PutActivityCmd } from '../../command/PutActivityCmd';
import { DeleteActivityCmd } from '../../command/DeleteActivityCmd';
import { Editor } from "../../service/Editor";
import { EditorDetail } from "../../service/EditorDetail";

@Component({
    selector: 'activities',
    templateUrl: './activities.html',
	styleUrls: ['./activities.css'],
})
export class ActivitiesComponent extends Editor<Activity> implements OnInit, AfterViewInit {

	/** Currently selected property. */
	private propertyId;

	// Timezone for the property
	private timeZone;

	/** List of activity types for this property. */
	private activityTypes: ActivityType[] = [];

	@ViewChild(ActivityComponent) private detailComponent: ActivityComponent;
	
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserLoginService, 
		private cache: CacheService,
	) { super(); }

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
		this.userService.checkLoggedIn( () => this.initialize() );
	}

	ngAfterViewInit() {
		this.cache.titleNavComponent.setPageTitle( '<i class="fa fa-pencil fa-fw"></i> Activities' );
	}

	initialize() {
		// Set selected property to the first one in the list
		this.propertyId = this.cache.getFirstPropertyId();
		this.switchProperty( null );
	}

	private switchProperty( event ) {
		this.propertyId = Number.parseInt( this.propertyId );
		this.timeZone = this.cache.getTimeZone( this.propertyId );
		new GetAllActivityTypesCmd().do( this.cache.currentCompany.companyId, this.propertyId )
		.then( rows => {
			// console.log( 'Loaded activity types for company '+this.cache.currentCompany.companyId+' property '+this.propertyId+': '+JSON.stringify(rows,null,2));
			if (rows != null) {
				this.activityTypes = rows;
				this.detailComponent.propertyId = this.propertyId;
				this.detailComponent.timeZone = this.timeZone;
				this.detailComponent.activityTypes = this.activityTypes;
				this.loadRows();
			}
		})
		.catch( error => {
			Global.logError( 'Error getting activity types', error );
			this.errorMessage = 'Sorry, there was a problem loading the data.  Please try again.';
		});
	}

	/** Returns component used to edit the selected row. */
	getDetailComponent(): EditorDetail<Activity> {
		return this.detailComponent;
	}

	/** Returns promise of array of rows to edit. */
	getRowLoader(): Promise<Activity[]> {
		return new GetAllActivitiesCmd().do( this.cache.currentCompany.companyId, this.propertyId );
	}
	
	/** Creates a new row to be edited. */
	createNewRowToEdit(): Activity {
		return new Activity( this.cache.currentCompany.companyId, this.propertyId );
	}

	/** Called before new rows is saved. */
	prepareRowToInsert( newRow: Activity ) {
	}

	/** Detects changes in model so view is updated. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Inserts row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param row Row to insert.
	 */
	getRowInserter( row: Activity ): Promise<Activity> {
		row.activityId = this.getNextAvailableId();
		return new PutActivityCmd().do( row );
	}

	/**
	 * Updates row when user clicks save.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to update.
	 * @param row Row to update.
	 */
	getRowUpdater( index: number, row: Activity ): Promise<Activity> {
		return new PutActivityCmd().do( row );
	}

	/**
	 * Deletes row when user clicks delete.
	 * If there is an error and you want to keep the dialog open then set the dialog's 
	 * errorMessage variable resolve the promise and the dialog will not be closed.
	 * Rejecting the promise will close the dialog and display error on the editor list page.
	 * @param index Index of row to delete;
	 * @param row Row to delete.
	 */
	getRowDeleter( index: number, row: Activity ): Promise<void> {
		return new DeleteActivityCmd().do( row );
	}

	/** Returns the next available ID which is one higher than the highest ID already used. */
	getNextAvailableId(): number {
		let id = 1;
		this.rows.forEach( row => {
			if (row.activityId >= id) {
				id = row.activityId+1;
			}
		})
		return id;
	}

	getActivityTypeName( activityTypeId: number ): string {
		let name = '';
		if (this.activityTypes != null) {
			this.activityTypes.forEach( activityType => {
				if (activityType.activityTypeId == activityTypeId) {
					name = activityType.name;
				}
			})
		}
		return name;
	}















	
	// insert() {
	// 	this.detailComponent.showModalDialog( new Activity( this.cache.currentCompany.companyId, this.propertyId ) )
	// 	.then( row => {
	// 		if (row) {
	// 			// User saved changes, update the list
	// 			this.rows.push( row );
	// 		}
	// 	})
	// 	.catch( error => {
	// 		Global.logError( 'Error editing new data', error );
	// 		this.errorMessage = error.message;
	// 	});
	// }

	// edit( index: number ) {
	// 	this.detailComponent.showModalDialog( this.rows[index] )
	// 	.then( row => {
	// 		if (row) {
	// 			// User saved changes, update the company in the cache
	// 			this.rows[index] = row;
	// 		}
	// 	})
	// 	.catch( error => {
	// 		Global.logError( 'Error editing data.', error );
	// 		this.errorMessage = error.message;
	// 	});
	// }

	// delete( index: number ) {
	// 	this.cache.confirmComponent.showModalDialog( 'Are you sure you want to delete activity: ' + this.rows[index].name + '?', 'Confirm Delete' )
	// 	.then( confirmed => {
	// 		if (confirmed) {
	// 			new DeleteActivityCmd().do( this.rows[index] )
	// 			.then( () => this.rows.splice( index, 1 ) )
	// 			.catch( error => {
	// 				Global.logError( 'Error deleting data.', error );
	// 				this.errorMessage = error.message;
	// 			});
	// 		}
	// 	})
	// 	.catch( error => {
	// 		Global.logError( 'Error in edit dialog.', error );
	// 		this.errorMessage = error.message;
	// 	});
	// }

}
