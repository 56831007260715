import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";

/** Displays pres-sales information for Analytics. */
@Component({
    selector: 'analytics-intro',
    templateUrl: './analytics-intro.html',
    styleUrls: ['./analytics-intro.css'],
})
export class AnalyticsIntroComponent {

}
