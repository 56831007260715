import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { CacheService } from "../../service/cache.service";
import { Company } from '../../service/Company';
import { GetCompaniesWithPermissionCmd } from '../../command/GetCompaniesWithPermissionCmd';
import { CopyPropertyCmd } from '../../command/CopyPropertyCmd';
import { Global } from '../../service/Global';

/** Component for editing a property ID in the list of properties where an employee works. */
@Component({
    selector: 'copy-property',
    templateUrl: './copy-property.html',
	styleUrls: ['./copy-property.css'],
})
export class CopyPropertyComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('copyPropertyModalButton') public modalButtonRef: ElementRef;

	@ViewChild('focus') public focusRef: ElementRef;

	errorMessage: string = null;

	protected modalButton: HTMLButtonElement;
	
	protected focusElement: HTMLInputElement;

	selectedPropertyId: any;

	newPropertyName: string;

	companies: Company[] = [];

	selectedCompanyId: any;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
        public cache: CacheService,
	)
    {}

    ngOnInit() {
		Global.log( this.constructor.name + '.ngOnInit' );
    }

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.modalButton = this.modalButtonRef.nativeElement;
		this.focusElement = this.focusRef.nativeElement;
	}

	/**
	 * Displays modal dialog to edit object.
	 * @param row Object to edit.
	 * @return Promise with user action used to dismiss dialog (insert, update, delete, or cancel).
	 */
	public showModalDialog(): void {
		// Get list of companies in which the user is allowed to edit properties 
		new GetCompaniesWithPermissionCmd().do( this.cache.currentCompany.companyId, this.cache.currentUserId, this.cache.currentUserEmail, 'editProperties' )
		.then( companies => {
			this.companies = companies;
			this.errorMessage = null;
			// Default to first property in list
			this.selectedPropertyId = '' + this.cache.properties[0].propertyId;
			this.onSelectProperty( this.selectedPropertyId );
				// Default to current company
			this.selectedCompanyId = '' + this.cache.currentCompany.companyId;
			// Show the dialog
			this.modalButton.click();
			this.detectChanges();
			})
		.catch( error => {
			this.errorMessage = 'Sorry, there was a problem selecting the company.  Please try again.';
			Global.logError( 'Error selecting company', error );
		})
		// Put keyboard focus on heading field after dialog is displayed
		setTimeout( () => this.focusElement ? this.focusElement.focus() : null, 1000 );
	}

	public hideDialog() {
		this.modalButton.click();
	}

	private onSelectProperty( propertyId ) {
		let property = this.cache.getProperty( Number.parseInt( propertyId ) );
		if (property) {
			this.newPropertyName = property.name + ' - Copy';
		}
	}

	/** Close the dialog without saving changes. */
	private onCancel() {
		try {
			this.errorMessage = null;
			this.hideDialog();
		} catch( error ) { this.handleError( error ); }
	}

    private onCopy() {
		try {
			this.errorMessage = null;
			if (this.isValid()) {
				new CopyPropertyCmd().do( this.cache.currentCompany.companyId, this.selectedPropertyId, this.selectedCompanyId, this.newPropertyName )
				.then( property => {
					if (property.companyId == this.cache.currentCompany.companyId) {
						// Property was added to this company, add it to the cached list of properties
						this.cache.properties.push( property );
					}
					this.hideDialog();
				})
				.catch( error => {
					this.errorMessage = 'Sorry, there was a problem selecting the company.  Please try again.';
					Global.logError( 'Error selecting company', error );
				})
			}
		} catch( error ) { this.handleError( error ); }
	}

	handleError( error ) {
		Global.logError( 'Error handling event', error );
		this.errorMessage = "Sorry, there was a problem, please try again."
	}

	/** Called when the screen needs to be updated after changes not detected by the zone. */
	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

	/** Returns true if object is in a valid state and can be saved. */
	isValid(): boolean {
		if (this.selectedPropertyId.valueOf() == null) {
			this.errorMessage = 'Please select a property.';
			return false;
		}
		if (this.selectedCompanyId.valueOf() == null) {
			this.errorMessage = 'Please select a company.';
			return false;
		}
		// Convert selected ID from string to number, combo box saves it as a string
		this.selectedPropertyId = new Number( this.selectedPropertyId );
		this.selectedCompanyId = new Number( this.selectedCompanyId );
		return true;
	}

}