import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";

import {AboutComponent, HomeComponent, HomeLandingComponent} from "./public/home.component";
import { ContactComponent } from "./public/contact/contact.component";
import {VipAlertComponent} from "./public/vip-alert.component";
import {SecureHomeComponent} from "./secure/landing/securehome.component";
import {LineTimerComponent} from "./secure/linetimer/linetimer.component";
import { ActivityTypesComponent } from "./secure/activity-types/activity-types.component";
import { ActivityTypeComponent } from "./secure/activity-type/activity-type.component";
import { AlexaDocComponent } from "./secure/doc/doc.component";
import {AnalyticsComponent} from "./secure/analytics/analytics.component";
import {AnalyticsIntroComponent} from "./public/analytics-intro/analytics-intro.component";
import {TimeByDaypartChartComponent} from "./secure/timebydaypart/timebydaypart.component";
import {CompareSitesChartComponent} from "./secure/comparesites/comparesites.component";
import {MyProfileComponent} from "./secure/profile/myprofile.component";
import {ImageCaptureComponent} from "./secure/imagecapture/image-capture.component";
import {GmailListenerComponent} from "./secure/gmaillistener/gmail-listener.component"
import {TimeclockComponent} from "./secure/timeclock/timeclock.component"
import {EmployeeComponent} from "./secure/employee/employee.component"
import {EmployeePhotoComponent} from "./secure/employee-photo/employee-photo.component"
import {LoginComponent} from "./public/auth/login/login.component";
import {RegisterComponent} from "./public/auth/register/registration.component";
import {ForgotPassword2Component, ForgotPasswordStep1Component} from "./public/auth/forgot/forgotPassword.component";
import {LogoutComponent, RegistrationConfirmationComponent} from "./public/auth/confirm/confirmRegistration.component";
import {ResendCodeComponent} from "./public/auth/resend/resendCode.component";
import {NewPasswordComponent} from "./public/auth/newpassword/newpassword.component";
import { ActivitiesComponent } from "./secure/activities/activities.component";
import { ActivitySignUpListComponent } from "./secure/activity-signup-list/activity-signup-list.component";
import { AdPlaysComponent } from "./secure/ad-plays/ad-plays.component";
import { CompanySelectorComponent } from "./public/company-selector/company-selector.component";
import { ConnectComponent } from "./secure/connect/connect.component"
import { ConnectIntroComponent } from "./public/connect-intro/connect-intro.component"
import { ConfirmEmailComponent } from "./public/confirm-email/confirm-email.component";
import { ConfirmEmailDescribeComponent } from "./public/confirm-email-describe/confirm-email-describe.component";
import { CopyPropertyComponent } from "./secure/copy-property/copy-property.component";
import { CreateAccountComponent } from "./public/create-account/create-account.component";
import { CreateAccountDescribeComponent } from "./public/create-account-describe/create-account-describe.component";
import { CreateAdvertiserAccountComponent } from "./public/create-advertiser-account/create-advertiser-account.component";
import { CreateAdvertiserAccountDescribeComponent } from "./public/create-advertiser-account-describe/create-advertiser-account-describe.component";
import { DefaultAdComponent } from "./secure/default-ad/default-ad.component";
import { EmployeeFeedbackDetailComponent } from "./secure/feedback-detail/feedback-detail.component";
import { EmployeesComponent } from "./secure/employees/employees.component"
import { FeedbackListComponent } from "./secure/feedback-list/feedback-list.component";
import { HousekeepingLogComponent } from "./secure/housekeeping-log/housekeeping-log.component";
import { HousekeepingLogEntryComponent } from "./secure/housekeeping-log-entry/housekeeping-log-entry.component";
import { PersonDetailComponent } from "./secure/person-detail/person-detail.component"
import { PropertyAdvertisersComponent } from "./secure/property-advertisers/property-advertisers.component";
import { PropertyAdvertiserComponent } from "./secure/property-advertiser/property-advertiser.component";
import { ItemCategoryComponent } from "./secure/item-category/item-category.component";
import { ItemComponent } from "./secure/item/item.component";
import { RecognizeComponent } from "./secure/recognize/recognize.component"
import { TitleNavComponent } from "./secure/title-nav/title-nav.component"
import { SampleCameraComponent } from "./secure/sample-camera/sample-camera.component"
import { MotionDetectorComponent } from "./secure/motion-detector/motion-detector.component"
import { CheckinMainComponent } from "./secure/checkin-main/checkin-main.component"
import { CheckinCameraComponent } from "./secure/checkin-camera/checkin-camera.component"
import { PropertiesComponent } from "./secure/properties/properties.component"
import { SignupHomeComponent } from './public/signuphome/signuphome.component';
import { SitesComponent } from "./secure/sites/sites.component"
import { EventsComponent } from "./secure/events/events.component"
import { RoomGenieComponent } from "./secure/room-genie/room-genie.component"
import { RoomGenieHospitalsComponent } from "./public/room-genie-hospitals/room-genie-hospitals.component";
import { RoomGenieIntroComponent } from "./public/room-genie-intro/room-genie-intro.component"
import { RoomGenieMainComponent } from "./secure/room-genie-main/room-genie-main.component";
import { ProductSubscribeComponent } from "./secure/product-subscribe/product-subscribe.component";
import { ProductSubscriptionsComponent } from "./secure/product-subscriptions/product-subscriptions.component";
import { ProductPricingComponent } from "./public/product-pricing/product-pricing.component";
import { PrivacyComponent } from "./public/privacy/privacy.component";
import { WhatsHappeningComponent } from "./public/whats-happening/whats-happening.component";
import { ClosedComponent } from "./secure/closed/closed.component";
import { RestaurantLayoutsComponent } from "./secure/restaurant-layouts/restaurant-layouts.component";
import { RestaurantLayoutComponent } from "./secure/restaurant-layout/restaurant-layout.component";
import { RestaurantReservationComponent } from "./secure/restaurant-reservation/restaurant-reservation.component";
import { RestaurantReservationsComponent } from "./secure/restaurant-reservations/restaurant-reservations.component";
import { SelectDialogComponent } from './secure/select-dialog/select-dialog.component';
import { SiteAdComponent } from "./secure/site-ad/site-ad.component";
import { SiteAdPropertyComponent } from "./secure/site-ad-property/site-ad-property.component";
import { SiteHoursComponent } from "./secure/site-hours/site-hours.component";
import { RestaurantSectionComponent } from "./secure/restaurant-section/restaurant-section.component";
import { RestaurantTableTopComponent } from "./secure/restaurant-tabletop/restaurant-tabletop.component";
import { TermsComponent } from "./public/terms/terms.component";
import { TimeInputComponent } from "./secure/time-input/time-input.component";
import { CheckoutComponent } from "./secure/checkout/checkout.component";
import { CheckoutConfirmComponent } from "./secure/checkout-confirm/checkout-confirm.component";
import { UnsubscribeConfirmComponent } from "./secure/unsubscribe-confirm/unsubscribe-confirm.component";
import { UsersComponent } from "./secure/users/users.component";
import { RoleComponent } from "./secure/role/role.component"
import { RequestInfoComponent } from './public/request-info/request-info.component';
import { RequestRoomGenieVideoComponent } from './public/room-genie-request-video/room-genie-request-video.component';
import { TopicComponent } from "./secure/topic/topic.component";
import { TopicsComponent } from "./secure/topics/topics.component";
import { ArticlesComponent } from './public/articles/articles.component';
import { SecureArticlesComponent } from './secure/secure-articles/secure-articles.component';
import { RoomGenieUsageComponent } from "./secure/room-genie-usage/room-genie-usage.component";

const whatsHappeningRoutes: Routes = [
    {
        path: '',
        redirectTo: '/whats-happening',
        pathMatch: 'full'
    },
    {
        path: 'whats-happening',
        component: HomeComponent,
        children: [
            // {path: '', component: HomeLandingComponent},
            {path: '', component: WhatsHappeningComponent},
            {path: 'about', component: AboutComponent},
            {path: 'contact', component: ContactComponent},
			{path: 'request-info', component: RequestInfoComponent},
        ]
    },
];

const homeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        children: [
            {path: 'about', component: AboutComponent},
            {path: 'analytics-intro', component: AnalyticsIntroComponent},
            {path: 'articles', component: ArticlesComponent},
            {path: 'articles/:urlLink', component: ArticlesComponent},
            {path: 'connect-intro', component: ConnectIntroComponent},
            {path: 'contact', component: ContactComponent},
            {path: 'room-genie-hospitals', component: RoomGenieHospitalsComponent},
            {path: 'room-genie-intro', component: RoomGenieIntroComponent},
            {path: 'vip-alert', component: VipAlertComponent},
            {path: 'login', component: LoginComponent},
			{path: 'privacy', component: PrivacyComponent},
			{path: 'terms', component: TermsComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent},
            {path: 'resendCode', component: ResendCodeComponent},
            {path: 'forgotPassword/:email', component: ForgotPassword2Component},
            {path: 'forgotPassword', component: ForgotPasswordStep1Component},
            {path: 'newPassword', component: NewPasswordComponent},
            {path: 'product-pricing', component: ProductPricingComponent},
			{path: 'request-info', component: RequestInfoComponent},
			{path: 'room-genie-request-video', component: RequestRoomGenieVideoComponent},
            {path: 'whats-happening', component: WhatsHappeningComponent},
            {path: '', component: HomeLandingComponent},
            // {path: '', component: RoomGenieIntroComponent}
        ]
    },
];

const secureHomeRoutes: Routes = [
    {

        path: '',
        redirectTo: '/securehome',
        pathMatch: 'full'
    },
    {
        path: 'securehome', component: SecureHomeComponent, children: [
        {path: 'articles', component: SecureArticlesComponent},
		{path: 'articles/:urlLink', component: SecureArticlesComponent},
        {path: 'logout', component: LogoutComponent},
		{path: 'activities', component: ActivitiesComponent},
		{path: 'activity-signup-list', component: ActivitySignUpListComponent},
		{path: 'activity-types', component: ActivityTypesComponent},
		{path: 'activity-type', component: ActivityTypeComponent},
		{path: 'ad-plays', component: AdPlaysComponent},
		{path: 'doc', component: AlexaDocComponent},
        {path: 'analytics', component: AnalyticsComponent},
        {path: 'checkin-main', component: CheckinMainComponent},
        {path: 'checkin-camera', component: CheckinCameraComponent},
        {path: 'comparesites', component: CompareSitesChartComponent},
        {path: 'connect', component: ConnectComponent},
        {path: 'copy-property', component: CopyPropertyComponent},
        {path: 'default-ad', component: DefaultAdComponent},
        {path: 'employee', component: EmployeeComponent},
        {path: 'employees', component: EmployeesComponent},
        {path: 'employee-photo', component: EmployeePhotoComponent},
		{path: 'events', component: EventsComponent},
        {path: 'feedback-list', component: FeedbackListComponent},
        {path: 'feedback-detail', component: EmployeeFeedbackDetailComponent},
        {path: 'gmaillistener', component: GmailListenerComponent},
        {path: 'housekeeping-log', component: HousekeepingLogComponent},
        {path: 'housekeeping-log-entry', component: HousekeepingLogEntryComponent},
        {path: 'imagecapture', component: ImageCaptureComponent},
        {path: 'item-category', component: ItemCategoryComponent},
        {path: 'item', component: ItemComponent},
        {path: 'linetimer', component: LineTimerComponent},
        {path: 'myprofile', component: MyProfileComponent},
		{path: 'motion-detector', component: MotionDetectorComponent},
        {path: 'person-detail', component: PersonDetailComponent},
        {path: 'product-subscribe', component: ProductSubscribeComponent},
        {path: 'product-subscriptions', component: ProductSubscriptionsComponent},
        {path: 'properties', component: PropertiesComponent},
        {path: 'property-advertiser', component: PropertyAdvertiserComponent},
        {path: 'property-advertisers', component: PropertyAdvertisersComponent},
        {path: 'recognize', component: RecognizeComponent},
        {path: 'closed', component: ClosedComponent},
        {path: 'restaurant-layout', component: RestaurantLayoutComponent},
        {path: 'restaurant-layouts', component: RestaurantLayoutsComponent},
        {path: 'restaurant-reservation', component: RestaurantReservationComponent},
		{path: 'restaurant-reservations', component: RestaurantReservationsComponent},
        {path: 'select-dialog', component: SelectDialogComponent},
        {path: 'site-ad', component: SiteAdComponent},
        {path: 'site-ad-property', component: SiteAdPropertyComponent},
        {path: 'site-hours', component: SiteHoursComponent},
        {path: 'restaurant-section', component: RestaurantSectionComponent},
        {path: 'restaurant-tabletop', component: RestaurantTableTopComponent},
        {path: 'role', component: RoleComponent},
		{path: 'room-genie', component: RoomGenieComponent},
		{path: 'room-genie-main', component: RoomGenieMainComponent},
		{path: 'room-genie-usage', component: RoomGenieUsageComponent},
		{path: 'sample-camera', component: SampleCameraComponent},
        {path: 'sites', component: SitesComponent},
        {path: 'timebydaypart', component: TimeByDaypartChartComponent},
        {path: 'timeclock', component: TimeclockComponent},
        {path: 'time-input', component: TimeInputComponent},
		{path: 'title-nav', component: TitleNavComponent},
        {path: 'topic', component: TopicComponent},
        {path: 'topics', component: TopicsComponent},
        {path: 'unsubscribe-confirm', component: UnsubscribeConfirmComponent},
        {path: 'users', component: UsersComponent},
        {path: '', component: ProductSubscribeComponent}]
    }
];

const signupRoutes: Routes = [
    {
        path: '',
        redirectTo: '/signuphome',
        pathMatch: 'full'
    },
    {
        path: 'signuphome',
        component: SignupHomeComponent,
        children: [
            {path: 'company-selector', component: CompanySelectorComponent },
            {path: 'create-account', component: CreateAccountComponent },
            {path: 'create-account-describe', component: CreateAccountDescribeComponent },
            {path: 'create-advertiser-account', component: CreateAdvertiserAccountComponent },
            {path: 'create-advertiser-account-describe', component: CreateAdvertiserAccountDescribeComponent },
            {path: 'confirm-email', component: ConfirmEmailComponent },
            {path: 'confirm-email-describe', component: ConfirmEmailDescribeComponent },
			{path: 'checkout', component: CheckoutComponent},
			{path: 'checkout-confirm', component: CheckoutConfirmComponent},
			{path: '', component: CreateAccountDescribeComponent}
        ]
    },
];

const routes: Routes = [
    {
        path: '',
        children: [
            ...homeRoutes,
			...secureHomeRoutes,
			...signupRoutes,
			...whatsHappeningRoutes,
            {
                path: '',
                component: HomeComponent
            }
        ]
    },


];

//export const appRoutingProviders: any[] = [];

//export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

// For Angular 7
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
