import { Global } from "../service/Global";
import { Command } from "./Command";
import { Company } from "../service/Company";

export class ValidateBillingAgreementCmd extends Command {

	/**
	 * Sets the Amazon Pay billing details including the seller's note to the customer.
	 * @param companyId ID of the company that is subscribing.
	 * @param amazonBillingAgreementId Amazon billing agreement ID which lets us charge the customer.
	 * @param sellerNote Seller's note that will appear on the customer's receipt.
	 */
	do( companyId: number, amazonBillingAgreementId: string, sellerNote: string ): Promise<Company> {
		return new Promise<Company>( (resolve, reject) => {
			var parameters = {
				"amazonBillingAgreementId": amazonBillingAgreementId,
				"sellerNote": sellerNote,
			};
			// Global.log('ValidateBillingAgreementCmd.do companyId='+companyId+', parameters='+JSON.stringify(parameters));
			super.send( this.constructor.name, companyId, parameters )
			.then( results => resolve( new Company().fromDataItem( results.data ) ) )
			.catch( err => reject( err ) );
		});
	}
}
