import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
	CognitoUserPool,
	CognitoRefreshToken
} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import * as CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider";
import { Global } from './Global';
import { IdToken } from './IdToken.interface'


export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
    callback(): void;
    callbackWithParam(result: any): void;
}

export class CognitoUtil implements IdToken {

	private static cognitoUserPool: CognitoUserPool;

	/** Return singleton instance of user pool. */
    getUserPool(): CognitoUserPool {
		if (!CognitoUtil.cognitoUserPool) {
			CognitoUtil.cognitoUserPool = new CognitoUserPool( { UserPoolId: Global.userPoolId, ClientId: Global.clientId } );
		}
		return CognitoUtil.cognitoUserPool;
    }

    getCurrentUser(): CognitoUser {
        return this.getUserPool().getCurrentUser();
    }

	/**
	 * Sets the AWS credentials to the access key and secret key of the LogWriter IAM user that only has
	 * permission to write to the logs and no other permissions.
	 */
	configureAWSLoggingOnly() {
		// console.log('configureAWSLoggingOnly AWS.config.accessKeyId='+AWS.config.accessKeyId+', AWS.config.secretAccessKey='+AWS.config.secretAccessKey+', AWS.config.region='+AWS.config.region);
		AWS.config.accessKeyId = 'AKIAI3N52QWUTJYCBO7Q';
        AWS.config.secretAccessKey = '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';
		AWS.config.region = 'us-east-1';
		// console.log( 'AWS credential set to LogWriter');
	}

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.
    buildCognitoCreds(idTokenJwt: string) {
        let url = 'cognito-idp.' + Global.region.toLowerCase() + '.amazonaws.com/' + Global.userPoolId;
        let logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        let params = {
            IdentityPoolId: Global.identityPoolId, /* required */
            Logins: logins
        };
        let creds = new AWS.CognitoIdentityCredentials(params);
        // this.setCognitoCreds(creds);
        return creds;
    }

    getCurrentIdToken(): Promise<any> {
        return new Promise( (resolve, reject) => {
			if (this.getCurrentUser() != null) {
				this.getCurrentUser().getSession(function (err, session) {
					if (err) {
						console.log("CognitoUtil: Can't set the credentials:" + err);
						reject( new Error( 'Error getting session for current user.  ' + err ) );
					} else {
						if (session.isValid()) {
							let idToken = session.getIdToken().getJwtToken();
							resolve( idToken );
						} else {
							reject( new Error( 'Error getting token, invalid session.' ) );
						}
					}
				});
			} else {
				// reject( new Error( 'Error getting ID token, no user is logged in.' ) );
				resolve( null );
			}
		});
	}

	/**
	 * Assuming a user has logged in, this function can be called when an AWS call fails because of
	 * missing credentials which may be caused by the credentials expiring.  This function will get
	 * a new user session which will refresh the credentials and the AWS call can be retried.
	 */
    public refreshCredentials(): Promise<void> {
		return new Promise<void>( (resolve, reject) => {
			console.log( 'refreshing credentials' );
			let poolData = { UserPoolId: Global.userPoolId, ClientId: Global.clientId };
			let user = new CognitoUserPool( poolData ).getCurrentUser();
			if (user != null) {
				console.log( 'refreshing credentials - user != null' );
				user.getSession( (err, session) => {
					if (err) {
						console.log( 'refreshing credentials - failed' );
						reject( new Error( "Error refreshing user credentials, getSession error: " + err.message ) );
					} else {
						console.log( 'refreshing credentials - got session' );
						if (session.isValid()) {
							// Set AWS credentials since they may have expired and just been refreshed
							console.log( 'refreshing credentials - session is valid, we should be refreshed' );
							let creds = this.buildCognitoCreds( session.getIdToken().getJwtToken() );
							this.setAWSCredentials( creds );
							// AWS.config.accessKeyId = undefined;
							// AWS.config.secretAccessKey = undefined;
							// AWS.config.credentials = creds;
							// console.log( 'Set AWS.config.credentials to '+JSON.stringify( creds, null, 2 ) );
							// AWS.config.credentials.params.Logins['graph.facebook.com'] = updatedToken;
							// console.log( 'Logins='+AWS.config.credentials.params.Logins['graph.facebook.com'] = updatedToken;)
							resolve();
						} else {
							console.log( 'refreshing credentials - failed, session is not valid' );
							// this.refreshSession( user, session )
							// .then( () => {
							// 	console.log('Eureka!!! The new refreshSession worked!')
							// })
							// .catch( error => {
							// 	console.log( 'Booo!!! The new refreshSession failed'+JSON.stringify(error,null,2 ) );
							// })
							reject( new Error( "Error refreshing user session, session is not valid." ) );
						}
					}
				});
			} else {
				console.log( 'refreshing credentials - failed, user is null' );
				reject( new Error( "Error refreshing user session, no user is logged in." ) );
			}
		});
	}
	
	public setAWSCredentials( creds ) {
		AWS.config.accessKeyId = undefined;
		AWS.config.secretAccessKey = undefined;
		AWS.config.credentials = creds;
	}

}